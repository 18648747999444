import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* unarchiveWorkflow({ payload }: PayloadActionWithGenericPayload & { payload: { isActive?: boolean }}) {
  const { success, data, error } = yield makeRequest.action(payload.action);
  if (success && data) {
    if (payload.onSuccess) {
      payload.onSuccess();
    }
    const message = payload.isActive ? 'Workflow Reactivated' : 'Workflow Unarchived';
    yield put(newMessageEvent({ text: message }));
  } else {
    if (payload.onError) {
      payload.onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default unarchiveWorkflow;
