import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { requestOrgUsers } from 'rdx/modules/users/slice';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* inviteOrgUser({ payload: { action, onSuccess, ...params } }) {
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (success) {
    const userConfirmed = data?.data?.confirmed_at;
    const userExists = data?.included?.[0]?.activated_at;

    const getMessageCopy = () => {
      let copy = `${params.email} succeeded. This request may go to an organization admin for review.`;
      if (userConfirmed && userExists) {
        copy = `${params.email} successfully added to organization.`;
      }
      if (userConfirmed && !userExists) {
        copy = `${params.email} successfully invited.`;
      }
      return copy;
    };

    yield put(newMessageEvent({ text: getMessageCopy() }));
    if (onSuccess) {
      onSuccess();
    }
    const organization_id = yield select(getCurrentOrgSlug);
    yield put(requestOrgUsers({ organization_id, is_active: '1' }));
    yield put(requestOrgUsers({ organization_id, confirmed: '0' }));
    yield put(requestOrgUsers({ organization_id, is_active: '0' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default inviteOrgUser;
