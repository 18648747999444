import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { setCurrentCytometer } from 'rdx/modules/cytometers/slice';

function* unpublishState({ payload }) {
  const { action, params, query, onSuccess } = payload;
  const { success, data, error } = yield* makeRequest.action(action, params, query);
  if (success && data) {
    if (onSuccess) {
      onSuccess();
    }
    const string = action.name.includes('un') ? 'Unpublished' : 'Published';
    yield put(newMessageEvent({ text: `Cytometer ${string}` }));
    yield put(setCurrentCytometer(data.unwrap()));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default unpublishState;
