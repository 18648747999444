import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getUserId } from 'rdx/modules/users/slice';
import { requestGroups } from '../slice';

function* leaveGroup({ payload }) {
  const groupId = payload;
  const userId = yield select(getUserId);
  // TODO (Rane): Api isn't sending this action over at all
  const { success, error } = yield* makeRequest.delete(`/groups/${groupId}/users/${userId}`);
  if (success) {
    yield put(requestGroups());
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default leaveGroup;
