import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getChannelsSaga from 'rdx/modules/chat/sagas/getChannels';
import getChannelDetailSaga from 'rdx/modules/chat/sagas/getChannelDetail';
import getMessagesSaga from 'rdx/modules/chat/sagas/getMessages';
import postChannelSaga from 'rdx/modules/chat/sagas/postChannel';
import hideChannelSaga from 'rdx/modules/chat/sagas/hideChannel'; import { hideChannel, requestChannelDetail, requestChannels, requestMessages, postChannel } from '../slice';

function* watchChatSagas() {
  yield trackRequests(takeEvery, requestChannels.type, getChannelsSaga);
  yield trackRequests(takeEvery, requestChannelDetail.type, getChannelDetailSaga);
  yield trackRequests(takeEvery, postChannel.type, postChannelSaga);
  yield trackRequests(takeEvery, hideChannel.type, hideChannelSaga);
  yield trackRequests(takeEvery, requestMessages.type, getMessagesSaga);
}

export default watchChatSagas;
