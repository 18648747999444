import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestOrganizationLabs } from 'rdx/modules/organization/slice';

function* postLab({ payload }) {
  const { success, data, error } = yield* makeRequest.action(payload.action, payload.values);
  if (success && data) {
    if (payload.onSuccess) {
      payload.onSuccess(data?.unwrap());
    }
    yield put(requestOrganizationLabs());
    yield put(newMessageEvent({ text: 'Lab Added' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default postLab;
