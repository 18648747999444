import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { FluorochromeAttributes } from 'types/fluorochromes';
import { GenericActionPayload } from 'types/redux-types';

const initialState: {
  list: ResourceList<FluorochromeAttributes>,
  search: ResourceList<FluorochromeAttributes>,
} = {
  list: new ResourceList<FluorochromeAttributes>(), 
  search: new ResourceList<FluorochromeAttributes>(),
}

export type RequestFluorochromesQuery = {
  pageSize?: number,
  page?: number,
  search?: string
  fluorescent_protein?: boolean
  conjugatable?: boolean
  functional_dyes?: boolean
}

const requestFluorochromes = createAction<GenericActionPayload<undefined, RequestFluorochromesQuery>>('requestFluorochromes');
const requestSearchFluorochromes = createAction<GenericActionPayload<undefined, RequestFluorochromesQuery>>('requestSearchFluorochromes');

const fluorochromesSlice = createSlice({
  name: 'fluorochromes',
  initialState,
  reducers: {
    setFluorochromes: (state, action: PayloadAction<ResourceList<FluorochromeAttributes>>) => {
      state.list = action.payload
    },
    setSearchFluorochromes: (state, action: PayloadAction<ResourceList<FluorochromeAttributes>>) => {
      state.search = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setFluorochromes,
  setSearchFluorochromes,
} = fluorochromesSlice.actions;

export {
  setFluorochromes,
  setSearchFluorochromes,
  requestFluorochromes,
  requestSearchFluorochromes,
};

export const getFluorochromes = (state: RootState) => state.fluorochromes.list;
export const getSearchFluorochromes = (state: RootState) => state.fluorochromes.search;

export default fluorochromesSlice.reducer;
