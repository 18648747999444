import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import customPropTypes from 'lib/customPropTypes';
import styled, { css } from 'styled-components';
import globals from 'styles/globals';
import Link from 'components/Link';
import { useLoading } from 'hooks/useLoading';
import { multiplyStyleString } from 'lib/utils/styles/doMathOnStyleString';
import titleCase from 'lib/utils/string/titleCase';

import { Lock } from 'components/Icons';
import LoadingCircle from '../Loading/LoadingCircle/index';

export const BtnSize = {
  SMALL: 'small',
  NORMAL: 'normal',
  NARROW: 'narrow',
  JUMBO: 'jumbo',
};

const defaultStyle = {
  fontColor: globals.colors.white,
  fontWeight: 400,
  bgColor: globals.colors.primary,
  borderColor: globals.colors.primary,
  marginLeft: '0px',
  marginRight: '0px',
};

const defaultHoverStyle = {
  bgColor: globals.colors.primaryHover,
  borderColor: globals.colors.primaryHover,
};

const defaultDisabledStyle = {
  bgColor: globals.colors.primaryDisabled,
  borderColor: globals.colors.primaryDisabled,
};

const sizeProps = {
  [BtnSize.SMALL]: {
    height: '40px',
    width: '100px',
  },
  [BtnSize.NORMAL]: {
    height: globals.layout.buttonHeight,
    width: globals.layout.buttonWidth,
  },
  [BtnSize.NARROW]: {
    height: '40px',
    width: '130px',
  },
  [BtnSize.JUMBO]: {
    height: '40px',
    width: '165px',
  },
};

const DeprecatedButton = forwardRef(({
  text,
  children,
  onClick,
  disabled,
  baseStyle,
  hoverStyle,
  disabledStyle,
  size,
  type,
  className,
  style,
  id,
  href,
  target,
  spinnerInnerColor,
  spinnerOuterColor,
  rdxTypes,
  showSpinner,
  locked,
  ...otherProps
}, ref) => {
  /**
   * Spread operator is used to fallback to less specific styles
   * (i.e. hover/disabled styles fall back to base styles when not
   * specified, and base styles fall back to default styles)
   */
  const baseSize = sizeProps[size || BtnSize.NORMAL];
  const computedBaseStyle = { ...defaultStyle, ...baseSize, ...baseStyle, locked };
  const computedDisabledStyle = { ...computedBaseStyle, ...defaultDisabledStyle, ...baseStyle, ...disabledStyle };
  const computedHoverStyle = { ...computedBaseStyle, ...defaultHoverStyle, ...baseStyle, ...hoverStyle };
  const loading = useLoading({ watchRequests: rdxTypes }) || showSpinner;
  const disabledOrBaseStyle = (disabled || loading) ? computedDisabledStyle : computedBaseStyle;

  const handleClick = (e) => {
    if (disabled || loading) {
      e.preventDefault();
      return null;
    }
    return onClick(e);
  };

  const renderInterior = () => {
    if (loading || showSpinner) {
      return (
        <LoadingCircle
          {...{ spinnerInnerColor, spinnerOuterColor }}
          size={multiplyStyleString(baseSize.height, 0.85)}
        />
      );
    }
    if (children) {
      return (
        <ButtonContent computedStyle={disabledOrBaseStyle}>
          {children}
        </ButtonContent>
      );
    }
    return (
      <ButtonText computedStyle={disabledOrBaseStyle}>
        {titleCase(text)}
      </ButtonText>
    );
  };

  const renderButton = () => (
    <ButtonWrapper
      {...{ disabled, type, className, style, ref, loading: `${loading || ''}` }}
      onClick={handleClick}
      computedStyle={disabledOrBaseStyle}
      hoverStyle={computedHoverStyle}
      id={id}
      data-testid={id}
      {...otherProps}
    >
      {renderInterior()}
      {locked && <Lock data-testid="locked-button" color={disabledOrBaseStyle.fontColor} style={{ marginLeft: '0.6em' }} size="1em" />}
    </ButtonWrapper>
  );

  if (href) {
    return (
      <LinkNoUnderline to={href} target={target}>
        {renderButton()}
      </LinkNoUnderline>
    );
  }
  return renderButton();
});

export const ButtonPropTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    // fontSize: PropTypes.string,
    fontColor: PropTypes.string,
    // fontWeight: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    spinnerInnerColor: PropTypes.string,
    spinnerOuterColor: PropTypes.string,
  }),
};

DeprecatedButton.propTypes = {
  text: PropTypes.string,
  children: customPropTypes.children,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  baseStyle: ButtonPropTypes.style,
  hoverStyle: ButtonPropTypes.style,
  style: ButtonPropTypes.style,
  disabledStyle: ButtonPropTypes.style,
  size: PropTypes.oneOf([BtnSize.SMALL, BtnSize.NORMAL, BtnSize.NARROW, BtnSize.JUMBO]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  id: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rdxTypes: PropTypes.arrayOf(PropTypes.string),
  spinnerInnerColor: PropTypes.string,
  spinnerOuterColor: PropTypes.string,
  showSpinner: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  locked: PropTypes.bool,
};

DeprecatedButton.defaultProps = {
  text: '',
  children: undefined,
  onClick: () => { },
  disabled: false,
  baseStyle: {},
  hoverStyle: {},
  style: {},
  disabledStyle: {},
  size: undefined,
  type: 'button',
  className: undefined,
  id: undefined,
  href: undefined,
  target: undefined,
  rdxTypes: [],
  spinnerInnerColor: null,
  spinnerOuterColor: null,
  showSpinner: undefined,
  locked: false,
};

export default DeprecatedButton;

const ButtonText = styled.span`
  color: ${({ computedStyle }) => computedStyle.fontColor};
  font: ${globals.fonts.buttons};
  letter-spacing: 0.4pt;
  white-space: nowrap;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ computedStyle }) => computedStyle.fontColor};
  font: ${globals.fonts.buttons};
  letter-spacing: 0.4pt;
  white-space: nowrap;
`;

const ButtonWrapper = styled.button.attrs(({ type }) => ({
  type,
}))`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ computedStyle }) => computedStyle.height};
  width: ${({ computedStyle }) => computedStyle.width};
  min-width: ${({ computedStyle }) => computedStyle.width};
  background-color: ${({ computedStyle }) => computedStyle.bgColor};
  margin-left: ${({ computedStyle }) => computedStyle.marginLeft};
  margin-right: ${({ computedStyle }) => computedStyle.marginRight};
  border: 1px solid ${({ computedStyle }) => computedStyle.borderColor};
  border-radius: 5px;
  margin-top: ${({ computedStyle }) => computedStyle.marginTop};
  margin-bottom: ${({ computedStyle }) => computedStyle.marginBottom};
  padding: 0px;
  cursor: pointer;
  letter-spacing: 1px;
  pointer-events: ${({ disabled, loading }) => ((disabled || loading) ? 'none' : 'auto')};
  opacity: ${({ disabled, loading }) => ((disabled || loading) ? '0.5' : '1')};
  transition: all 200ms ease;

  &:hover {
    background-color: ${({ hoverStyle }) => hoverStyle.bgColor};
    border-color: ${({ hoverStyle }) => hoverStyle.borderColor};

    ${ButtonText}, ${ButtonContent} {
      color: ${({ hoverStyle }) => hoverStyle.fontColor};
      font-size: ${({ hoverStyle }) => hoverStyle.fontSize};
    }
    ${Lock} {
      fill: ${({ theme }) => theme.colors.warning};
    }
  }

  &:active {
    filter: brightness(0.9);
  }

  ${({ hidden }) => hidden && css`
    opacity: 0;
    pointer-events: none;
  `}
`;

const LinkNoUnderline = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;
