import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction } from 'rdx/modules/auth/slice';
import type { PayloadActionWithGenericPayload } from 'types/redux-types';
import Action from 'lib/jsonApi/Action';
import { OrgWorkflowsParams, WorkflowsQueryParams, setWorkflows } from '../slice';

function* requestOrgWorkflowsSaga({ payload: { params, onSuccess, onError } }: PayloadActionWithGenericPayload<OrgWorkflowsParams & WorkflowsQueryParams>) {
  const action: Action = yield select(getSessionAction('organization_workflows'));

  const { success, data, error } = yield makeRequest.action(action, params);
  if (success && data) {
    if (onSuccess) {
      onSuccess({ data });
    }
    yield put(setWorkflows(data));
  } else {
    if (onError) {
      onError({ error });
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestOrgWorkflowsSaga;
