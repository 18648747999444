import styled from 'styled-components';

import PrimaryButton from '../Button/PrimaryButton';
import type { Pagination } from 'types/json-api-types';

type Props = {
  pagination: Pagination,
  onPageChange: (newPage: number) => void,
  id?: string,
  loading?: boolean,
}

const Pagination = ({ pagination, onPageChange, id, loading }: Props) => {
  const { page, pageCount } = pagination;

  const renderPages = () => {
    const pages = [];
    if (pageCount > 5) {
      if (page > 2) {
        pages.push(
          <PageButton
            id={id ? `${id}-first-page-button` : undefined}
            key="first-page-button"
            active={(page === 1)}
            onClick={() => onPageChange(1)}
            disabled={loading}
          >
            1
          </PageButton>
        );
      }
      if (page !== 1) {
        if (page > 3) {
          pages.push(<p key="before-placeholder">...</p>);
        }
        pages.push(
          <PageButton
            id={id ? `${id}-page-${page - 1}-button` : undefined}
            key={`page-${page - 1}-button`}
            onClick={() => onPageChange(page - 1)}
            disabled={loading}
          >
            {page - 1}
          </PageButton>
        );
      }
      pages.push(
        <PageButton
          active
          id={id ? `${id}-current-page-button` : undefined}
          key="current-page-button"
          onClick={() => onPageChange(1)}
          disabled={loading}
        >
          {page}
        </PageButton>
      );
      if (page < (pageCount - 1)) {
        pages.push(
          <PageButton
            id={id ? `page-${page + 1}-button` : undefined}
            key={`page-${page + 1}-button`}
            onClick={() => onPageChange(page + 1)}
            disabled={loading}
          >
            {page + 1}
          </PageButton>
        );
        if (page < pageCount - 2) {
          pages.push(<p key="after-placeholder">...</p>);
        }
      }
      if (page !== pageCount) {
        pages.push(
          <PageButton
            id={id ? `${id}-last-page-button` : undefined}
            key="last-page-button"
            onClick={() => onPageChange(pageCount)}
            disabled={loading}
          >
            {pageCount}
          </PageButton>
        );
      }
    } else {
      for (let i = 0; i < pageCount; i += 1) {
        pages.push(
          <PageButton
            id={id ? `${id}-page-${i + 1}-button` : undefined}
            key={`page-${i + 1}-button`}
            onClick={() => onPageChange(i + 1)}
            active={(page === (i + 1))}
            disabled={loading}
          >
            {i + 1}
          </PageButton>
        );
      }
    }
    return pages;
  };

  return (
    <PaginationWrapper id={id ? `${id}-pagination-controls` : undefined}>
      <PageButton
        id={id ? `${id}-prev-page-button` : undefined}
        disabled={page === 1 || loading}
        onClick={() => onPageChange(page - 1)}
      >
        {'<'}
      </PageButton>
      {renderPages()}
      <PageButton
        id={id ? `${id}-next-page-button` : undefined}
        disabled={page === pageCount || loading}
        onClick={() => onPageChange(page + 1)}
      >
        {'>'}
      </PageButton>
    </PaginationWrapper>
  );
};

export default Pagination;

const PaginationWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-height: 38px;
`;

const PageButton = styled(PrimaryButton)<{ active?: boolean }>`
  background: ${({ active, theme }) => (active ? theme.colors.primary : 'none')};
  font-weight: bold;
  color: ${({ theme, active }) => (active ? theme.colors.white : 'inherit')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border: none;
  margin: 0px 1px;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.8;
  }
`;
