import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { SearchIcon } from 'components/Icons';

import PrimaryButton, { PrimaryButtonProps } from '../../Button/PrimaryButton';

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
  width?: string;
  height?: string;
  disabled?: boolean;
  className?: string;
  backgroundColor?: string;
  borderColor?: string;
  buttonProps?: PrimaryButtonProps;
  searchIcon?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
  width,
  height,
  disabled,
  backgroundColor,
  borderColor,
  buttonProps,
  className,
  searchIcon,
  ...inputProps
}, ref) => {
  const showButton = !!buttonProps;

  return (
    <ButtonContainer height={height ?? '40px'}>
      <InputContainer width={width ?? '200px'} {...{ disabled, backgroundColor, borderColor, showButton, className }}>
        {searchIcon && (
          <SearchIcon style={{ margin: '0.4em' }} size="1.6em" />
        )}
        <InputElement
          ref={ref}
          disabled={disabled}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (buttonProps?.onClick) {
                buttonProps.onClick(e);
                e.preventDefault();
              }
            }
          }}
          {...inputProps}
        />
      </InputContainer>
      {showButton && (
        <Button
          size={{ height: '40px', width: '60px' }}
          {...buttonProps}
        />
      )}
    </ButtonContainer>
  );
});

Input.displayName = 'Input'

export default Input;

const ButtonContainer = styled.div<{ height: string }>`
  display: flex;
  height: ${({ height }) => height};
  align-items: center;
`;

type InputContainerProps = {
  width: string;
  backgroundColor?: string;
  borderColor?: string;
  disabled?: boolean;
  showButton?: boolean;
};

const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: ${({ width }) => width};
  background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.white};
  border-radius: 2px;
  border: 1px solid ${({ borderColor, theme }) => borderColor ?? theme.colors.grey70};
  cursor: text;

  &:focus-within {
    border-width: 2px;
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  ${({ showButton }) => showButton && css`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
`;

const InputElement = styled.input`
  color: ${({ theme }) => theme.colors.textDark};
  background-color: transparent;
  font-size: 15px;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  border: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.textMed};
    font-style: italic;
  }
`;

const Button = styled(PrimaryButton)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;
