import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { setCurrentCytometer, updateCytometersList } from 'rdx/modules/cytometers/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* patchCytometer({ payload }: PayloadActionWithGenericPayload) {
  const { action, params, onSuccess, message } = payload;
  const { success, data, error } = yield makeRequest.action(action, params);
  if (success && data) {
    if (onSuccess) {
      onSuccess();
    }
    yield put(setCurrentCytometer(data.unwrap()));
    yield put(updateCytometersList(data.unwrap()));
    yield put(newMessageEvent({ text: message ?? 'Cytometer Updated' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default patchCytometer;
