import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const Share = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg {...props} ref={ref}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.5 109.57">
    <path d="M 73.19 26.55 L 73.19 2.5 C 73.246 2.061 73.431 1.365 73.645 1.061 C 73.867 0.747 74.392 0.336 74.717 0.198 C 75.044 0.059 75.71 -0.033 76.094 0.029 C 76.466 0.089 77.09 0.443 77.444 0.716 L 128.656 51.228 L 127.216 52.652 L 77.432 101.896 C 77.086 102.158 76.469 102.509 76.104 102.57 C 75.722 102.634 75.051 102.548 74.72 102.409 C 74.389 102.27 73.855 101.849 73.633 101.527 C 73.421 101.221 73.243 100.532 73.19 100.1 L 73.19 75.853 C 66.463 75.236 45.513 70.515 4 102.15 C 3.615 102.37 2.948 102.64 2.575 102.651 C 2.185 102.663 1.53 102.485 1.221 102.302 C 0.909 102.117 0.43 101.617 0.254 101.26 C 0.085 100.916 0.018 100.186 0.04 99.733 C 0.886 94.938 4.687 76.64 15.677 59.636 C 26.622 42.7 44.528 27.474 73.19 26.55 Z M 83.382 21.014 L 83.437 37.629 L 75.19 37.553 C 46.68 37.308 36.923 45.638 25.571 61.807 C 16.389 74.886 14.387 78.238 12.762 85.53 C 54.183 56.056 75.541 63.25 75.541 63.25 L 82.167 64.05 L 81.965 86.064 L 115.039 50.859 L 83.382 21.014 Z"/>
  </svg>
));

Share.displayName = 'Share';

const ShareIcon = createIcon(Share);

export default ShareIcon;
