import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setWorkflowGroups, setMoreWorkflowGroups } from '../slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* getWorkflowGroups({ payload }: PayloadActionWithGenericPayload) {
  const { action, params } = payload;
  const { success, data, error } = yield makeRequest.action(action, params);
  if (success && data) {
    if (data?.meta?.pagination?.page > 1) {
      yield put(setMoreWorkflowGroups(data));
    } else {
      yield put(setWorkflowGroups(data));
    }
  } else if (error) {
    yield* putErrorActions({ error });
  }
}

export default getWorkflowGroups;
