import { forwardRef } from 'react';

import createIcon from '../createIcon';

const Retry = forwardRef<SVGSVGElement>((props, ref) => (
  <svg viewBox="0 0 100 100" ref={ref} {...props}>
    <g>
      <path d="M85.6,35.6l-11.3,6.7c2.4,4.1,3.7,8.9,3.7,14c0,15.5-12.6,28-28,28s-28-12.6-28-28c0-15.4,12.6-28,28-28V41l30.7-19.2    L50,2.5v12.7c-22.7,0-41.2,18.5-41.2,41.2C8.8,79,27.3,97.5,50,97.5c22.7,0,41.2-18.5,41.2-41.2C91.2,48.8,89.1,41.7,85.6,35.6z" />
    </g>
  </svg>

));

export default createIcon(Retry);
