import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { ConfirmInviteExistingParams, getSession } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Resource from 'lib/jsonApi/Resource';
import { DefaultAttributes } from 'types/json-api-types';

function* confirmInviteExisting({ payload }: PayloadActionWithGenericPayload<ConfirmInviteExistingParams>) {
  const session: Resource<DefaultAttributes> | null = yield select(getSession);
  const action = session?.getAction('invite_existing_confirm');
  const { onSuccess } = payload;
  if (payload.params) {
    const { token: code, password, email } = payload.params;
    const params = { code, password, email };
    const { success, error } = yield makeRequest.action(action, params);
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    } else if (error) {
      yield* putErrorActions({ error });
    }
  }
  return null;
}

export default confirmInviteExisting;
