import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import customPropTypes from 'lib/customPropTypes';
import styled from 'styled-components';
// import globals from 'styles/globals';
import { useTimer } from 'hooks/useTimer';
import { useLoading } from 'hooks/useLoading';
import LoadingSquares from 'components/Loading/LoadingSquares/index';

// Minimum time before triggering loading component
const MIN_TRIGGER_TIME = 100;

// Minimum time to show loading component once activated
const MIN_ACTIVE_TIME = 300;

// Minimum time before retriggering loading component once done
const MIN_RETRIGGER_TIME = 500;

const STATE = {
  INIT: 'INIT',
  AWAITING_MIN_TRIGGER_TIME: 'AWAITING_MIN_TRIGGER_TIME',
  CHECK_LOADING_FROM_MIN_TRIGGER: 'CHECK_LOADING_FROM_MIN_TRIGGER',
  AWAITING_MIN_RETRIGGER_TIME: 'AWAITING_MIN_RETRIGGER_TIME',
  CHECK_LOADING_FROM_MIN_RETRIGGER: 'CHECK_LOADING_FROM_MIN_RETRIGGER',
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  AWAITING_MIN_ACTIVE_TIME: 'AWAITING_MIN_ACTIVE_TIME',
  CHECK_LOADING_FROM_MIN_ACTIVE: 'CHECK_LOADING_FROM_MIN_ACTIVE',
};

const RENDER = {
  NULL: 'NULL',
  SPINNER: 'SPINNER',
  CONTENT: 'CONTENT',
};

const Loading = ({
  children,
  watchRequests,
  forceLoading,
  debug,
}) => {
  const [state, setState] = useState(STATE.INIT);
  const hasActiveRequest = useLoading({ watchRequests, debug });
  const timer = useTimer();
  if (debug && import.meta.env.DEV) {
    console.log(state); // eslint-disable-line no-console
  }

  // ==== Define spaghetti web of state transitions ==== //
  useEffect(() => {
    if (state === STATE.INIT) {
      setState(STATE.AWAITING_MIN_TRIGGER_TIME);
      timer.forceStart(() => { setState(STATE.CHECK_LOADING_FROM_MIN_TRIGGER); }, MIN_TRIGGER_TIME);
    }
  }, [state, timer]);

  useEffect(() => {
    if (state === STATE.CHECK_LOADING_FROM_MIN_TRIGGER) {
      if (hasActiveRequest) {
        setState(STATE.AWAITING_MIN_ACTIVE_TIME);
        timer.forceStart(() => { setState(STATE.CHECK_LOADING_FROM_MIN_ACTIVE); }, MIN_ACTIVE_TIME);
      } else {
        setState(STATE.IDLE);
        timer.stop();
      }
    }
  }, [hasActiveRequest, state, timer]);

  useEffect(() => {
    if (state === STATE.CHECK_LOADING_FROM_MIN_ACTIVE) {
      if (hasActiveRequest) {
        setState(STATE.LOADING);
        timer.stop();
      } else {
        setState(STATE.IDLE);
        timer.stop();
      }
    }
  }, [hasActiveRequest, state, timer]);

  useEffect(() => {
    if (state === STATE.LOADING) {
      if (!hasActiveRequest) {
        setState(STATE.IDLE);
        timer.stop();
      }
    }
  }, [state, hasActiveRequest, timer]);

  useEffect(() => {
    if (state === STATE.IDLE) {
      if (hasActiveRequest) {
        setState(STATE.AWAITING_MIN_RETRIGGER_TIME);
        timer.forceStart(() => { setState(STATE.CHECK_LOADING_FROM_MIN_RETRIGGER); }, MIN_RETRIGGER_TIME);
      }
    }
  }, [state, hasActiveRequest, timer]);

  useEffect(() => {
    if (state === STATE.CHECK_LOADING_FROM_MIN_RETRIGGER) {
      if (hasActiveRequest) {
        setState(STATE.AWAITING_MIN_ACTIVE_TIME);
        timer.forceStart(() => { setState(STATE.CHECK_LOADING_FROM_MIN_ACTIVE); }, MIN_ACTIVE_TIME);
      } else {
        setState(STATE.IDLE);
        timer.stop();
      }
    }
  }, [hasActiveRequest, state, timer]);

  // ==== Use state to figure out what to render ==== //
  let render;
  if (forceLoading) {
    render = RENDER.SPINNER;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (
      state === STATE.INIT
      || state === STATE.AWAITING_MIN_TRIGGER_TIME
      || (state === STATE.CHECK_LOADING_FROM_MIN_TRIGGER)
    ) {
      render = RENDER.NULL;
    } else if (
      state === STATE.LOADING
      || state === STATE.AWAITING_MIN_ACTIVE_TIME
      || (state === STATE.CHECK_LOADING_FROM_MIN_ACTIVE)
    ) {
      render = RENDER.SPINNER;
    } else if (
      state === STATE.IDLE
      || state === STATE.AWAITING_MIN_RETRIGGER_TIME
      || (state === STATE.CHECK_LOADING_FROM_MIN_RETRIGGER)
    ) {
      render = RENDER.CONTENT;
    }
  }

  // ==== Render ==== //
  switch (render) {
  case RENDER.NULL:
    return null;
  case RENDER.SPINNER:
    return (
      <SpinnerContainer data-testid="loading-spinner-container" id="loading-spinner-container">
        <FadeBackground />
        <LoadingSquares />
      </SpinnerContainer>
    );
  case RENDER.CONTENT:
    return children;
  default:
    throw new Error('<Loading /> component is in an invalid state. It\'s probably Rane\'s fault.');
  }
};

Loading.propTypes = {
  children: customPropTypes.children,
  watchRequests: PropTypes.arrayOf(PropTypes.string),
  forceLoading: PropTypes.bool,
  debug: PropTypes.bool,
};

Loading.defaultProps = {
  children: null,
  watchRequests: [],
  forceLoading: false,
  debug: false,
};

export default Loading;

export const SpinnerContainer = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FadeBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
`;
