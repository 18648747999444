import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import { setWorkflowNotes } from '../slice';

function* getWorkflowNotes({ payload: { link } }: PayloadActionWithGenericPayload) {
  const { success, data, error } = yield makeRequest.link(link);
  if (success) {
    yield put(setWorkflowNotes(data));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getWorkflowNotes;
