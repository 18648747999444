import React, { ErrorInfo, ReactNode } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import globals from 'styles/globals';

import { Notifier } from '@airbrake/browser';
import { NavLink } from 'react-router-dom';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import bdrcLogo from 'assets/bdrc-logo-white.svg';

import UtilityBar from 'components/UtilityBar';
import FlashMessages from 'containers/FlashMessages';
import { RootState } from 'index';
import { Dispatch } from 'redux';
import { SystemSettings } from 'types/session';

function airbrakeChanged(a?: SystemSettings, b?: SystemSettings) {
  if (!a || !b) {
    return true;
  }
  return (a.airbrake_project_id !== b.airbrake_project_id)
    || (a.airbrake_project_key !== b.airbrake_project_key)
    || (a.airbrake_environment !== b.airbrake_environment);
}

type Props = {
  systemSettings?: SystemSettings,
  dispatch: Dispatch,
  children: ReactNode,
}

type State = {
  hasError: boolean,
}

class AirbrakeErrorBoundary extends React.Component<Props, State> {
  airbrake: Notifier | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.airbrake = null;
  }

  componentDidMount() {
    this.updateAirbrake();
  }

  componentDidUpdate(prevProps: Props) {
    if (airbrakeChanged(this.props.systemSettings, prevProps.systemSettings)) {
      this.updateAirbrake();
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (this.airbrake) {
      void this.airbrake.notify({
        error,
        params: { info },
      });
    }
    if (error?.message?.includes('dynamically imported module')) {
      this.props.dispatch(newMessageEvent({ text: 'New Application Version Detected, Page will Reload' }));
      setTimeout(() => {
        document.location.reload();
      }, 1000);
    }
  }

  updateAirbrake() {
    const { airbrake_project_id, airbrake_project_key, airbrake_environment } = this.props.systemSettings ?? {};
    if (airbrake_project_id && airbrake_project_key && airbrake_environment) {
      this.airbrake = new Notifier({
        projectId: parseInt(airbrake_project_id),
        projectKey: airbrake_project_key,
        environment: airbrake_environment,
      });
    } else {
      this.airbrake = null;
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (!hasError) {
      return children;
    }
    return (
      <PageContainer>
        <TopBar>
          <HomeLink to="/">
            <BDRCLogo src={bdrcLogo} alt="BD Research Cloud" />
          </HomeLink>
        </TopBar>
        <MessageContainer>
          <MessageHeader>Uh oh! You found a bug!<br /></MessageHeader>
          <MessageBody>
            Something unexpected happened and BD Research Cloud has crashed.<br />
            <br />
            An anonymous automated message has been sent to our developers so we can fix the problem quickly.<br />
            <br />
            Try refreshing the page to continue your work.<br />
            <br />
            Thank you for using BD RC!
          </MessageBody>
        </MessageContainer>
        <UtilityBar />
        <FlashMessages />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state: RootState) => ({ systemSettings: state.auth.session?.attributes?.system_settings });

const ConnectedAirbrakeErrorBoundary = connect(mapStateToProps)(AirbrakeErrorBoundary);

export default ConnectedAirbrakeErrorBoundary;

const { colors } = globals;

const PageContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  height: calc(100vh - 40px);
  width: 100vw;
  flex-direction: column;
`;

const TopBar = styled.div`
  color: ${colors.white};
  background-color: ${colors.charcoal};
  height: 64px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15pt;
`;

const HomeLink = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-left: 5.4%;
  padding: 0px 10px;
  &:hover {
    text-decoration: none;
  }
`;

const BDRCLogo = styled.img`
  height: 23px;
`;

const MessageContainer = styled.div`
  margin-top: 64px;
  padding: 30px;
`;

const MessageHeader = styled.h1`
  font-size: 28px;
  margin-bottom: 10px;
`;

const MessageBody = styled.p`
  font-size: 16px;
`;
