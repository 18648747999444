import styled from 'styled-components';
import { NavBarTop, HomeLink, BDRCLogo, NavBarWrapper } from 'components/NavHeader';
import { BrowserRouter } from 'react-router-dom';
import bdrcLogo from 'assets/bdrc-logo-white.svg';
import { ContentContainer } from 'containers/Main';
import LoadingSquares from 'components/Loading/LoadingSquares/index';
import { SpinnerContainer, FadeBackground } from 'components/Loading';

const Timeout = ({ timedOut }: { timedOut?: boolean }) => (
  <Wrapper>
    <BrowserRouter>
      <NavBarWrapper>
        <NavBarTop isMainNav>
          <HomeLink to="/">
            <BDRCLogo src={bdrcLogo} alt="BD Research Cloud" />
          </HomeLink>
        </NavBarTop>
      </NavBarWrapper>
      {timedOut ? (
        <ContentContainer>
          <div>
            <ErrorHeader>Unable To Connect</ErrorHeader>
            <ErrorBody>Unable to connect to the BD Research Cloud Servers.</ErrorBody>
            <ErrorBody>This page will try to reload by staying open or you can return later</ErrorBody>
          </div>
        </ContentContainer>
      ) : (
        <SpinnerContainer>
          <FadeBackground />
          <LoadingSquares />
        </SpinnerContainer>
      )}
    </BrowserRouter>
  </Wrapper>
);

export default Timeout;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;

const ErrorHeader = styled.h1`
  padding: 0.5rem 0;
`;

const ErrorBody = styled.p`
  padding: 0.25rem 0;
`;
