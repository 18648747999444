import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from '../app/slice';

type TemplatesState = {
  workflowTemplates: ResourceList;
  procedureTemplates: ResourceList;
  customProcedureTemplates: ResourceList;
  bdProcedureTemplates: ResourceList;
};

const initialState: TemplatesState = {
  workflowTemplates: new ResourceList(),
  procedureTemplates: new ResourceList(),
  customProcedureTemplates: new ResourceList(),
  bdProcedureTemplates: new ResourceList(),
};

const saveProcedureAsTemplate = createAction('saveProcedureAsTemplate');
const requestProcedureTemplates = createAction('requestProcedureTemplates');
const requestCustomProcedureTemplates = createAction('requestCustomProcedureTemplates');
const requestBdProcedureTemplates = createAction('requestBdProcedureTemplates');
const saveWorkflowAsTemplate = createAction('saveWorkflowAsTemplate');
const saveWorkflowAsStudy = createAction('saveWorkflowAsStudy');
const requestWorkflowTemplates = createAction('requestWorkflowTemplates');
const deleteWorkflowTemplate = createAction('deleteWorkflowTemplate');
const createWorkflowFromTemplate = createAction('createWorkflowFromTemplate');

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setWorkflowTemplates: (state, action: PayloadAction<ResourceList>) => { state.workflowTemplates = action.payload; },
    setProcedureTemplates: (state, action: PayloadAction<ResourceList>) => { state.procedureTemplates = action.payload; },
    setCustomProcedureTemplates: (state, action: PayloadAction<ResourceList>) => { state.customProcedureTemplates = action.payload; },
    setBdProcedureTemplates: (state, action: PayloadAction<ResourceList>) => { state.bdProcedureTemplates = action.payload; },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const { setWorkflowTemplates, setProcedureTemplates, setCustomProcedureTemplates, setBdProcedureTemplates } = templatesSlice.actions;

// Actions
export {
  saveProcedureAsTemplate,
  requestProcedureTemplates,
  requestCustomProcedureTemplates,
  requestBdProcedureTemplates,
  saveWorkflowAsTemplate,
  saveWorkflowAsStudy,
  requestWorkflowTemplates,
  deleteWorkflowTemplate,
  createWorkflowFromTemplate,
  setWorkflowTemplates,
  setProcedureTemplates,
  setCustomProcedureTemplates,
  setBdProcedureTemplates,
};

// Selectors

export const getWorkflowTemplates = (state: RootState) => state.templates.workflowTemplates;
export const getProcedureTemplates = (state: RootState) => state.templates.procedureTemplates;
export const getCustomProcedureTemplates = (state: RootState) => state.templates.customProcedureTemplates;
export const getBdProcedureTemplates = (state: RootState) => state.templates.bdProcedureTemplates;

// Reducer
export default templatesSlice.reducer;
