import FocusLock from 'react-focus-lock';

import { useModal } from 'hooks/useModal';
import { useSecondaryModal } from 'hooks/useSecondaryModal';

import Modal, { ModalProps } from 'components/Modal';

export type BaseModalProps = Omit<ModalProps, 'open' | 'onClose'> & {
  isSecondary?: boolean,
}

const BaseModal = ({
  dimensions,
  confirm,
  cancel,
  children,
  dangerous,
  styles,
  preventClose,
  forceClick,
  rdxTypes,
  alert,
  id,
  noFooter,
  allowScrollingX,
  allowScrollingY,
  isSecondary,
  fullscreen,
  modalHeader,
  showXIcon,
  noMaxHeight,
}: BaseModalProps) => {
  const { modalIsOpen, closeAndUnsetModal } = useModal();
  const { closeSecondaryModal, secondaryModalIsOpen, setSecondaryModal } = useSecondaryModal();
  return (
    <FocusLock autoFocus={false}>
      <Modal
        {...{ dimensions, confirm, cancel, styles, id, alert, noFooter, allowScrollingX, allowScrollingY, isSecondary, fullscreen, showXIcon, noMaxHeight }}
        open={isSecondary ? secondaryModalIsOpen : modalIsOpen}
        onClose={() => {
          if (isSecondary) {
            closeSecondaryModal(); setTimeout(() => setSecondaryModal(), 500);
          } else {
            closeAndUnsetModal();
          }
        }}
        dangerous={dangerous}
        preventClose={preventClose}
        forceClick={forceClick}
        rdxTypes={rdxTypes}
        modalHeader={modalHeader}
      >
        {children}
      </Modal>
    </FocusLock>
  );
};

export default BaseModal;
