import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Resource from 'lib/jsonApi/Resource';
import { resetStore } from 'rdx/modules/app/slice';
import type { RootState } from 'index';
import { GenericActionPayload } from 'types/redux-types';
import { SessionAttributes } from 'types/session';

interface AuthSliceState {
  session: Resource<SessionAttributes> | null;
  redirectUrl: string | null;
  apiVersion: string | null;
  unusedLicensesModalSeen: boolean;
  inactivityModalIsOpen: boolean;
  inviteStatus: boolean;
  automaticLogoutCallback: (() => void) | null;
}

const initialState: AuthSliceState = {
  session: null,
  redirectUrl: null,
  apiVersion: null,
  unusedLicensesModalSeen: true,
  inactivityModalIsOpen: false,
  inviteStatus: true,
  automaticLogoutCallback: null,
};

export type ConfirmInviteParams = {
  token: string,
  password: string,
  first_name: string,
  last_name: string,
  postal_code: string,
  country_code: string,
}

export type ConfirmInviteExistingParams = {
  email: string,
  password: string,
  token: string,
}

export type RequestLoginParams = {
  email: string,
  password: string,
} | { 
  bd_login_key: string,
}

export type ResetPasswordParams = {
  code: string,
  password: string,
}

const requestSession = createAction('auth/requestSession');
const requestLogin = createAction<GenericActionPayload<RequestLoginParams> & { rememberMe?: boolean }>('auth/requestLogin');
const requestLogout = createAction<GenericActionPayload>('auth/requestLogout');
const createAccount = createAction<GenericActionPayload<{ email: string }>>('auth/createAccount');
const forgotPassword = createAction<GenericActionPayload<{ email: string }>>('auth/forgotPassword');
const resetPassword = createAction<GenericActionPayload<ResetPasswordParams>>('auth/resetPassword');
const confirmInvite = createAction<GenericActionPayload<ConfirmInviteParams>>('auth/confirmInvite');
const confirmInviteExisting = createAction<GenericActionPayload<ConfirmInviteExistingParams>>('auth/confirmInviteExisting');
const requestInviteStatus = createAction<{ token: string }>('auth/requestInviteStatus');

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Resource<SessionAttributes> | null>) => {
      state.session = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string | null>) => {
      state.redirectUrl = action.payload;
    },
    setApiVersion: (state, action: PayloadAction<string | null>) => {
      state.apiVersion = action.payload;
    },
    setUnusedLicensesModalSeen: (state, action: PayloadAction<boolean>) => {
      state.unusedLicensesModalSeen = action.payload;
    },
    setInactivityModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.inactivityModalIsOpen = action.payload;
    },
    setAutomaticLogoutCallback: (state, action: PayloadAction<(() => void) | null>) => {
      state.automaticLogoutCallback = action.payload;
    },
    setInviteStatus: (state, action: PayloadAction<boolean>) => {
      state.inviteStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setSession,
  setRedirectUrl,
  setApiVersion,
  setUnusedLicensesModalSeen,
  setInactivityModalIsOpen,
  setInviteStatus,
  setAutomaticLogoutCallback,
} = authSlice.actions;

export {
  // Reducer Actions
  setSession,
  setRedirectUrl,
  setApiVersion,
  setUnusedLicensesModalSeen,
  setInactivityModalIsOpen,
  setInviteStatus,
  setAutomaticLogoutCallback,

  // Saga Actions
  requestSession,
  requestLogin,
  requestLogout,
  createAccount,
  forgotPassword,
  resetPassword,
  confirmInvite,
  confirmInviteExisting,
  requestInviteStatus,
};

export const getSessionLoaded = (state: RootState) => !!state.auth.session;
// export const getIsFirstLogin = (state: RootState) => {
//   const signInCount = state.auth.session?.attributes?.sign_in_count;
//   if (signInCount && typeof signInCount === 'string') {
//     const intCount = parseInt(signInCount);
//     return (intCount === 1 && state.auth.redirectUrl !== '/');
//   }
//   return false;
// };
export const getSession = (state: RootState) => state.auth.session;
export const getSystemSettings = (state: RootState) => state.auth.session?.attributes?.system_settings;
export const getSignInRequirements = (state: RootState) => state.auth.session?.attributes?.sign_in_requirements ?? [];
export const getSignInWarnings = (state: RootState) => state.auth.session?.attributes?.sign_in_warnings ?? [];
export const getSessionActions = (state: RootState) => state.auth.session?.meta?.actions;
export const getSessionAction = (action: string) => (state: RootState) => state.auth.session?.getAction?.(action);
export const getSessionLinks = (state: RootState) => state.auth.session?.links;
export const getSessionLink = (link: string) => (state: RootState) => state.auth.session?.links[link];
export const getSSOUrl = (state: RootState) => state.auth.session?.links?.bd_sso_url;
export const getRedirectUrl = (state: RootState) => state.auth.redirectUrl;
export const getMessagesSocket = (state: RootState) => state.auth.session?.links?.messages_ws as string | undefined;
export const getMessagesApiUrl = (state: RootState) => state.auth.session?.links?.messages_api_url as string | undefined;
export const getApiVersion = (state: RootState) => state.auth.apiVersion;
export const getUnusedLicensesModalSeen = (state: RootState) => state.auth.unusedLicensesModalSeen;
export const getInactivityModalIsOpen = (state: RootState) => state.auth.inactivityModalIsOpen;
export const getAutomaticLogoutCallback = (state: RootState) => state.auth.automaticLogoutCallback;
export const getInviteStatus = (state: RootState) => state.auth.inviteStatus;

export default authSlice.reducer;
