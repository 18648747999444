import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const FilledCheckmarkIcon = forwardRef((props, ref) => (
  <svg data-testid="checkmark-icon-svg" viewBox="0 0 512 512" ref={ref} {...props}>
    <path d="m369.164062 174.769531c7.8125 7.8125 7.8125 20.476563 0 28.285157l-134.171874 134.175781c-7.8125 7.808593-20.472657 7.808593-28.285157 0l-63.871093-63.875c-7.8125-7.808594-7.8125-20.472657 0-28.28125 7.808593-7.8125 20.472656-7.8125 28.28125 0l49.730468 49.730469 120.03125-120.035157c7.8125-7.808593 20.476563-7.808593 28.285156 0z m 142.835938 81.230469zm-40 0c0-119.394531-96.621094-216-216-216-119.394531 0-216 96.621094-216 216 0 119.394531 96.621094 216 216 216 119.394531 0 216-96.621094 216-216zm0 0" />
  </svg>
));

export default createIcon(FilledCheckmarkIcon);
