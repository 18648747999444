import { ReactNode } from 'react';
import styled from 'styled-components';
import globals from 'styles/globals';

type Props = {
  renderRows: () => ReactNode,
  width?: string,
  header?: ReactNode,
  multiHeader?: Record<string, [string, string | undefined]>
}

const BaseTable = ({ renderRows, width = '100%', header, multiHeader }: Props) => {
  return (
    <TableContainer width={width}>
      {header && (
        <Header>
          <HeaderText>
            {header}
          </HeaderText>
        </Header>
      )}
      {multiHeader && (
        <Header>
          {Object.keys(multiHeader).map((label) => (
            <MultiheaderText key={`header-for-${label}`} width={multiHeader[label][0]} flexDir={multiHeader[label][1]}>
              {label}
            </MultiheaderText>
          ))}
        </Header>
      )}
      {renderRows()}
    </TableContainer>
  );
};

export default BaseTable;

const TableContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: ${({ width }) => width};
  border-radius: 3px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  border-radius: 3px;
  background-color: ${globals.colors.black};
  margin-bottom: 5px;
  padding-left: 20px;

  &:first-child {
    margin-top: 10px;
  }
`;

const HeaderText = styled.h3`
  font-weight: bold;
  text-transform: uppercase;
  color: ${globals.colors.white};
`;

const MultiheaderText = styled(HeaderText)<{ width: string, flexDir?: string }>`
  display: flex;
  padding-right: 20px;
  width: ${({ width }) => width};
  justify-content: ${({ flexDir }) => (flexDir ?? 'flex-start')};
`;
