import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setOrganizationProjects } from '../slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* getOrganizationProjects({ payload }: PayloadActionWithGenericPayload & { payload: { organizationID: string } }) {
  const { link, organizationID, query, onSuccess, onError } = payload;
  const { success, data, error } = yield makeRequest.link(link, { organizationID }, query);
  if (success && data) {
    yield put(setOrganizationProjects(data));
    onSuccess?.();
  } else {
    onError?.();
    yield* putErrorActions({ error });
  }
  return null;
}

export default getOrganizationProjects;
