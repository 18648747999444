import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from 'hooks/reduxHooks';
import { useModal } from 'hooks/useModal/index';

import ConfirmModal from 'containers/Modals/ConfirmModal/index';
import { getCurrentCytometer } from 'rdx/modules/cytometers/slice';

const useUnpublishedCytoDrawerConfirm = ({ onConfirm }: { onConfirm: () => void }) => {
  const history = useHistory();
  const { callModal } = useModal();
  const currentCytometer = useAppSelector(getCurrentCytometer);
  const confirmUnpublishedCytometer = useMemo(() => {
    if (
      !currentCytometer.attributes.published_at
      && currentCytometer.getAction('publish')
      && new URLSearchParams(history.location.search).get('drawer') === 'edit-cytometer'
    ) {
      return true;
    }
    return false;
  }, [currentCytometer, history.location.search]);

  const handleClose = () => {
    if (confirmUnpublishedCytometer) {
      callModal(
        <ConfirmModal
          message="You have not published this cytometer. Other users will not be able to use this cytometer unless published. Do you wish to continue without publishing?"
          yesNo
          onConfirm={() => onConfirm()}
        />,
      );
    } else {
      onConfirm();
    }
  };

  return handleClose;
};

export default useUnpublishedCytoDrawerConfirm;
