import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const RecurringIcon = forwardRef((props, ref) => (
  <svg
    ref={ref}
    viewBox="0 0 600 600"
    {...props}
  >
    <g transform="translate(-50, 15)">
      <path d="m350 28c-138.98 0-252 113.02-252 252s113.02 252 252 252 252-113.02 252-252c0.0625-4.4961-1.6797-8.832-4.8359-12.035-3.1562-3.1992-7.4688-5.0039-11.965-5.0039-4.4961 0-8.8047 1.8047-11.965 5.0039-3.1562 3.2031-4.8984 7.5391-4.8359 12.035 0 120.82-97.582 218.4-218.4 218.4s-218.4-97.582-218.4-218.4 97.582-218.4 218.4-218.4c62.461 0 119.29 25.691 159.25 67.199h-44.977c-4.4961-0.0625-8.8281 1.6758-12.031 4.8359-3.2031 3.1562-5.0078 7.4648-5.0078 11.965 0 4.4961 1.8047 8.8047 5.0078 11.961 3.2031 3.1602 7.5352 4.9023 12.031 4.8359h80.676c4.457 0 8.7305-1.7695 11.879-4.918 3.1523-3.1523 4.9219-7.4258 4.9219-11.879v-80.676c0.097656-4.5781-1.6797-8.9961-4.918-12.234-3.2383-3.2344-7.6562-5.0117-12.23-4.918-4.457 0.09375-8.6953 1.9531-11.777 5.168-3.0859 3.2188-4.7656 7.5273-4.6758 11.984v35.176c-45.902-44.871-109.22-72.102-178.15-72.102zm-7 116.2c-4.457 0.09375-8.6914 1.9531-11.777 5.168-3.0859 3.2148-4.7656 7.5273-4.6719 11.984v131.95c-0.011719 4.4609 1.75 8.7383 4.8984 11.898l69.477 69.652c4.25 4.25 10.449 5.9102 16.254 4.3555 5.8086-1.5586 10.344-6.0938 11.902-11.902 1.5547-5.8047-0.10547-12.004-4.3555-16.254l-64.578-64.402v-125.3c0.097656-4.5781-1.6797-8.9961-4.9141-12.234-3.2383-3.2383-7.6562-5.0117-12.234-4.918z" />
    </g>
  </svg>
));

export default createIcon(RecurringIcon);
