import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';

export interface TextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
  width?: string;
  height?: string;
  heightRange?: {
    min?: string,
    max?: string,
  }
  widthRange?: {
    min?: string,
    max?: string,
  }
  resize?: string,
  disabled?: boolean,
  backgroundColor?: string,
  borderColor?: string,
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  height = '150px',
  width = '300px',
  heightRange = {},
  widthRange = {},
  resize = 'vertical',
  disabled = false,
  backgroundColor,
  borderColor,
  className,
  ...inputProps
}, ref) => (
  <TextAreaElement
    ref={ref}
    {...{ height, width, heightRange, widthRange, resize, disabled, backgroundColor, borderColor, className }}
    {...inputProps}
  />
));

TextArea.displayName = 'TextArea';

export default TextArea;

const TextAreaElement = styled.textarea<{
  height: string,
  width: string,
  heightRange: {
    min?: string,
    max?: string,
  },
  widthRange: {
    min?: string,
    max?: string,
  },
  resize: string,
  backgroundColor?: string,
  borderColor?: string,
}>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  max-height: ${({ heightRange }) => heightRange.max ?? 'unset'};
  min-height: ${({ heightRange }) => heightRange.min ?? 'unset'};
  max-width: ${({ widthRange }) => widthRange.max ?? 'unset'};
  min-width: ${({ widthRange }) => widthRange.min ?? 'unset'};
  resize: ${({ resize }) => resize};
  font-size: 15px;
  color: ${({ theme }) => theme.colors.textDark};
  padding: 12px;
  background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.white};
  border-radius: 2px;
  border: 1px solid ${({ borderColor, theme }) => borderColor ?? theme.colors.grey70};
  outline: none;

  &:focus {
    border-width: 2px;
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}


  &::placeholder {
    color: ${({ theme }) => theme.colors.textMed};
    font-style: italic;
  }
`;
