import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setInventoryReagents } from 'rdx/modules/reagents/slice';

function* getInventoryReagents({ payload }) {
  const { action, params } = payload;
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (error) {
    yield* putErrorActions({ error });
  }
  if (success && data) {
    yield put(setInventoryReagents(data));
  }
  return null;
}

export default getInventoryReagents;
