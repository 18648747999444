import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { castArray, isEqual, minBy, unionWith } from 'lodash';
import { resetStore } from 'rdx/modules/app/slice';
import type { GenericActionPayload } from 'types/redux-types';
import { StepAttributes } from 'types/steps';

interface StepsSliceState {
  list: Resource<StepAttributes>[];
  reports: ResourceList;
  report: Record<string, Resource>;
  notes: ResourceList;
  files: ResourceList;
}

type Position = {
  id: string | number,
  position: number,
};

const initialState: StepsSliceState = {
  list: [],
  reports: new ResourceList(),
  report: {},
  notes: new ResourceList(),
  files: new ResourceList(),
};

function compareID(a: Resource<StepAttributes>, b: Resource<StepAttributes>) {
  return String(a.id) === String(b.id);
}

const requestStep = createAction<GenericActionPayload>('requestStep');
const patchStep = createAction('patchStep');
const postStep = createAction('postStep');
const deleteStep = createAction('deleteStep');
const assignStep = createAction<GenericActionPayload>('assignStep');
const postStepNote = createAction('postStepNote');
const strikeStepNote = createAction('strikeStepNote');
const requestStepReports = createAction('requestStepReports');
const submitReagentLead = createAction<GenericActionPayload>('submitReagentLead');
const requestStepNotes = createAction('requestStepNotes');
const requestStepFiles = createAction('requestStepFiles');
const downloadPanelSummary = createAction<GenericActionPayload>('downloadPanelSummary');

const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setSteps: (state, action: PayloadAction<Resource<StepAttributes>[]>) => {
      state.list = action.payload;
    },
    updateSteps: (state, action: PayloadAction<Resource<StepAttributes>[] | ResourceList<StepAttributes>>) => {
      let unwrapped: Resource<StepAttributes>[];
      if (action.payload instanceof ResourceList) {
        unwrapped = castArray(action.payload.unwrap());
      } else {
        unwrapped = castArray(action.payload);
      }
      state.list = unionWith<Resource<StepAttributes>>(unwrapped, state.list, compareID);
    },
    updateStepAttributes: (state, action: PayloadAction<Resource<StepAttributes>>) => {
      const newSteps = [];
      const attrs = action.payload.attributes;
      if (attrs) {
        const step = state.list.find((s) => s.id === action.payload.id)?.withAttrs({ ...attrs });
        if (step) {
          newSteps.push(step);
        }
      }
      state.list = unionWith(newSteps, state.list, compareID);
    },
    updateStepPositions: (state, action: PayloadAction<Position[]>) => {
      const newSteps = [] as Resource<StepAttributes>[];
      action.payload.forEach(({ id, position }) => {
        const currentStep = state.list.find((step) => String(step.id) === String(id));
        newSteps.push(new Resource<StepAttributes>(currentStep).withAttrs({ position }));
      });
      state.list = unionWith(newSteps, state.list, compareID);
    },
    setStepReports: (state, action: PayloadAction<ResourceList>) => {
      state.reports = action.payload;
    },
    insertStepReport: (state, action: PayloadAction<ResourceList>) => {
      state.reports = state.reports.mergeWith(action.payload);
    },
    setStepReport: (state, action: PayloadAction<{ channelID: string, report: Resource }>) => {
      const { channelID, report } = action.payload;
      state.report = {
        ...state.report,
        [channelID]: report,
      };
    },
    updateReportProgress: (state, action: PayloadAction<{ channelID: string, progress: number, body: string, status: string }>) => {
      const { channelID, progress, body, status } = action.payload;
      const report = state.report[channelID];
      let maxProgress = 0;
      let newBody = body;
      if (report?.attributes) {
        if (report.attributes.progress) {
          maxProgress = Math.max(report.attributes.progress as number, progress);
        }
        if (report.attributes.body) {
          newBody = `${report.attributes.body}\n${body}`;
        }
        const newReport = report.withAttrs({
          progress: maxProgress,
          body: newBody,
          status,
        });
        state.report[channelID] = newReport;
      } else {
        state.report[channelID] = new Resource().withAttrs({
          progress: maxProgress,
          body: newBody,
          status,
        });
      }
    },
    setStepNotes: (state, action: PayloadAction<ResourceList>) => {
      state.notes = action.payload;
    },
    setStepFiles: (state, action: PayloadAction<ResourceList>) => {
      state.files = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setSteps,
  updateSteps,
  updateStepPositions,
  setStepReports,
  setStepReport,
  updateReportProgress,
  insertStepReport,
  updateStepAttributes,
  setStepNotes,
  setStepFiles,
} = stepsSlice.actions;

export {
  // Reducer Actions
  setSteps,
  updateSteps,
  updateStepPositions,
  updateStepAttributes,
  setStepReports,
  setStepReport,
  updateReportProgress,
  insertStepReport,
  setStepNotes,
  setStepFiles,

  // Saga Actions
  requestStep,
  patchStep,
  postStep,
  deleteStep,
  assignStep,
  postStepNote,
  strikeStepNote,
  requestStepReports,
  submitReagentLead,
  requestStepNotes,
  requestStepFiles,
  downloadPanelSummary,
};

export const getSteps = (state: RootState) => state.steps.list;
export const getStepNotes = (state: RootState) => state.steps.notes;
export const getStepFiles = (state: RootState) => state.steps.files;
export const getInProgressReport = (channelID: string) => (state: RootState) => state.steps.report[channelID];
export const getStepReports = (state: RootState) => state.steps.reports;
export const getStepByID = (stepID: number | string) => (state: RootState) => state.steps.list.find((step) => String(step.id) === String(stepID));
export const getStepsByProcedureID = (procedureID: number | string) => (state: RootState) => {
  const procedureSteps = state.steps.list.filter((step) => String(step.attributes.procedure_id) === String(procedureID));
  return procedureSteps.sort((a, b) => Number(a.attributes.position) - Number(b.attributes.position));
};
export const getProcedureStepCountByID = (procedureID: string | number) => (state: RootState) => {
  const procedureSteps = state.steps.list.filter((step) => String(step.attributes.procedure_id) === String(procedureID));
  return procedureSteps.length;
};
export const getProcedureStepIDsByID = (procedureID: string | number) => (state: RootState) => {
  const procedureSteps = state.steps.list.filter((step) => String(step.attributes.procedure_id) === String(procedureID));
  const sortedSteps = procedureSteps.sort((a, b) => Number(a.attributes.position) - Number(b.attributes.position));
  return sortedSteps.map((step) => step.id);
};
export const getProcedureIncompleteStepCountByID = (procedureID: string | number) => (state: RootState) => {
  const procedureSteps = state.steps.list.filter((step) => {
    const isFromProcedure = String(step.attributes.procedure_id) === String(procedureID);
    const isMissingName = !step.attributes.name;
    return (isFromProcedure && isMissingName);
  });
  return procedureSteps.length;
};
export const getAreProcedureDueDatesChronological = (procedureID: string | number) => (state: RootState) => {
  const procedureSteps = state.steps.list.filter((step) => String(step.attributes.procedure_id) === String(procedureID));
  const sortedSteps = procedureSteps.sort((a, b) => Number(a.attributes.position) - Number(b.attributes.position));
  const procedureStepsWithDueDates = sortedSteps.filter((step) => !!step.attributes.due_at);
  const dueDates = procedureStepsWithDueDates.map((step) => step.attributes.due_at);
  const originalOrder = [...dueDates];
  const sortedDueDates = dueDates.sort();
  return isEqual(originalOrder, sortedDueDates);
};
export const getProcedureCompletedTrialsByID = (procedureID: string | number) => (state: RootState) => {
  const procedureSteps = state.steps.list.filter((step) => String(step.attributes.procedure_id) === String(procedureID));
  return minBy(procedureSteps, (o) => o.attributes.trial)?.attributes?.trial;
};
export const getIsStepReady = (stepID: string | number) => (state: RootState) => {
  const currentStep = state.steps.list.find((step) => String(step.id) === String(stepID));
  const previousStep = state.steps.list.find((step) => {
    const isSameProcedure = String(step.attributes.procedure_id) === String(currentStep?.attributes.procedure_id);
    const isPreviousPosition = parseInt(String(step.attributes.position)) === (parseInt(String(currentStep?.attributes.position)) - 1);
    return isSameProcedure && isPreviousPosition;
  });
  const procedures = state.procedures.list.unwrap();
  if (Array.isArray(procedures)) {
    const procedure = procedures.find((p) => String(p.id) === String(currentStep?.attributes.procedure_id));
    const procPosition = procedure?.attributes.position;
    const prevProc = procedures.find((p) => parseInt(String(p.attributes.position)) === (parseInt(String(procPosition)) - 1));
    const currentStepStartedAt = currentStep?.attributes.started_at;
    const currentStepCompletedAt = currentStep?.attributes.completed_at;

    if (procPosition === 1 || procedure?.attributes.started_at) {
      if ((!currentStepStartedAt && !currentStepCompletedAt) && (!previousStep || previousStep.attributes.completed_at)) {
        return true;
      }
    }
    if (prevProc?.attributes?.completed_at && parseInt(String(currentStep?.attributes.position)) === 0) {
      if (!currentStepStartedAt && !currentStepCompletedAt) {
        return true;
      }
    }
  }
  return false;
};

export default stepsSlice.reducer;
