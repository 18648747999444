import { useSelector } from 'react-redux';

import { getSystemSettings } from 'rdx/modules/auth/slice';

const TechSupportEmailLink = () => {
  const { tech_support_email } = useSelector(getSystemSettings);

  const techSupportHref = `mailto:${tech_support_email}?subject=BD Research Cloud Support Question&body=Please help us assist you by providing some of the following information.%0D%0AOperating System and Version:%0D%0A%0D%0ABrowser and Version:%0D%0A%0D%0AWhat seems to be the issue:%0D%0A%0D%0AIf possible please provide steps to reproduce:%0D%0A%0D%0A`;
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={techSupportHref}
    >
      {tech_support_email}
    </a>
  );
};

export default TechSupportEmailLink;
