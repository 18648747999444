import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTooltip } from 'hooks/useTooltip';
import Portal from 'components/Portal';

const PADDING = 7;
const BORDER_WIDTH = 7;

const ToolTip = ({
  message,
  render,
  child,
  displayLeft,
  displayRight,
  displayAbove,
  adjustment,
}) => {
  const element = useRef(child.target);
  const portalElement = useRef();
  const [offsets, setOffsets] = useState(null);
  const { tooltipIsOpen, closeTooltip } = useTooltip();
  const [delayShow, setDelayShow] = useState(false);

  useEffect(() => {
    let timeoutId;
    element.current = child.target;
    setOffsets(calculateOffsets());
    if (!tooltipIsOpen) {
      setDelayShow(false);
    }
    if (tooltipIsOpen) {
      timeoutId = setTimeout(() => setDelayShow(true), 600);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipIsOpen]);

  useEffect(() => {
    const close = () => closeTooltip();
    if (tooltipIsOpen) {
      window.addEventListener('scroll', close, true);
      window.addEventListener('click', close, true);
    }
    return () => {
      window.removeEventListener('scroll', close, true);
      window.removeEventListener('click', close, true);
    };
  }, [closeTooltip, tooltipIsOpen]);

  const calculateOffsets = () => {
    if (element.current?.outerHTML?.includes('option')) return null;
    const childElement = element.current?.getBoundingClientRect();
    const tip = portalElement.current?.getBoundingClientRect();
    if (childElement && tip) {
      const aboveTarget = window.innerHeight / 2 < childElement.top || displayAbove;
      const { width: toolTipWidth, height: toolTipHeight, left: toolTipLeft } = tip;
      const { width: targetWidth, height: targetHeight, left: targetLeft, top: targetTop } = childElement;
      const top = aboveTarget
        ? targetTop - toolTipHeight - BORDER_WIDTH
        : targetTop + targetHeight + BORDER_WIDTH;
      let widthAdjustment = targetWidth / 2;
      if (displayRight) widthAdjustment = targetWidth - adjustment;
      if (displayLeft) widthAdjustment = adjustment;
      const left = (targetLeft + widthAdjustment) - (toolTipWidth / 2);
      const tipOffset = (left + toolTipWidth + 10) - window.innerWidth;
      const normalizedTipOffset = Math.max((left + toolTipWidth + PADDING) - window.innerWidth, 0);
      const actualLeft = Math.max(left - normalizedTipOffset, PADDING);
      const actualAfterOffset = tipOffset >= 0
        ? normalizedTipOffset
        : Math.min(targetLeft + widthAdjustment - (toolTipLeft + (toolTipWidth / 2)), -5);
      return {
        top: `${top}px`,
        left: `${actualLeft}px`,
        up: aboveTarget,
        afterTop: aboveTarget ? '100%' : `-${BORDER_WIDTH * 2}px`,
        afterLeft: `calc(49% + ${actualAfterOffset}px)`,
      };
    }
    return null;
  };
  return (
    <>
      {tooltipIsOpen && (
        <PositionKeeper>
          <InvisibleContent ref={portalElement}>
            <OverflowContainer>
              {render ? render() : message}
            </OverflowContainer>
          </InvisibleContent>
          {delayShow && (
            <Portal>
              <Tooltip {...{ offsets }}>
                <OverflowContainer>
                  {render ? render() : message}
                </OverflowContainer>
              </Tooltip>
            </Portal>
          )}
        </PositionKeeper>
      )}
    </>
  );
};

ToolTip.propTypes = {
  message: PropTypes.string,
  child: PropTypes.object, // eslint-disable-line
  render: PropTypes.func,
  displayRight: PropTypes.bool,
  displayLeft: PropTypes.bool,
  displayAbove: PropTypes.bool,
  adjustment: PropTypes.number,
};

ToolTip.defaultProps = {
  message: 'default tooltip message',
  child: {},
  render: null,
  displayRight: false,
  displayLeft: false,
  displayAbove: false,
  adjustment: 0,
};

export default ToolTip;

const OverflowContainer = styled.div`
  max-width: 400px;
  max-height: 400px;
  overflow: auto;
`;

const Tooltip = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, light }) => `${light ? theme.colors.primary : theme.colors.black}FA`};
  position: fixed;
  pointer-events: none;
  z-index: 10000;
  top: ${({ offsets }) => offsets?.top};
  left: ${({ offsets }) => offsets?.left};
  border-radius: 3px;
  padding: ${PADDING}px;
  justify-content: center;
  white-space: pre-wrap;
  &::after {
    content: '';
    position: absolute;
    top: ${({ offsets }) => offsets?.afterTop};
    left: ${({ offsets }) => offsets?.afterLeft};
    height: 14px;
    width: 14px;
    border-width: ${BORDER_WIDTH}px;
    border-style: solid;
    border-color: ${({ offsets, light, theme }) => (offsets?.up ? `${light ? theme.colors.primary : theme.colors.black}FA transparent transparent transparent` : `transparent transparent ${light ? theme.colors.primary : theme.colors.black}FA transparent`)};
  }
`;

const PositionKeeper = styled.div``;
const InvisibleContent = styled.div`
  justify-content: center;
  white-space: pre-wrap;
  padding: ${PADDING}px;
  visibility: hidden;
`;
