import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const NewPage = forwardRef((props, ref) => (
  <svg viewBox="0 0 50 50" ref={ref} {...props}>
    <path d="M 30 3.5 L 30 6.5 L 41.378906 6.5 L 13.939453 33.939453 L 16.060547 36.060547 L 43.5 8.6210938 L 43.5 20 L 46.5 20 L 46.5 3.5 L 30 3.5 z M 3.5 8.5 L 3.5 46.5 L 41.5 46.5 L 41.5 25 L 38.5 25 L 38.5 43.5 L 6.5 43.5 L 6.5 11.5 L 25 11.5 L 25 8.5 L 3.5 8.5 z" />
  </svg>
));

export default createIcon(NewPage);
