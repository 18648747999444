import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestLicenses, setLicenses, getLicenses } from 'rdx/modules/licenses/slice';
import { getSessionLinks } from 'rdx/modules/auth/slice';

/**

 * @returns {array} Licenses
 */
export function useLicenses() {
  const dispatch = useDispatch();

  const link = useSelector(getSessionLinks).licenses;

  const licenses = useSelector(getLicenses);

  const cleanup = React.useCallback(() => dispatch(setLicenses([])), [dispatch]);

  React.useEffect(() => () => cleanup(), [cleanup]);

  React.useEffect(() => {
    dispatch(requestLicenses({
      link,
    }));
  }, [dispatch, link]);

  return licenses ?? [];
}

export default useLicenses;
