import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import { getCurrentTrial } from 'rdx/modules/trials/slice';
import { getStepByID } from 'rdx/modules/steps/slice';
import { getUser } from 'rdx/modules/users/slice';

const useFullScreenWidth = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const stepId = urlParams.get('stepId');

  const user = useAppSelector(getUser);
  const step = useAppSelector(getStepByID(stepId));
  const currentTrial = useAppSelector(getCurrentTrial);

  const active = useMemo(() =>
    !!currentTrial?.getAction('complete')
    && user?.id
    && (
      String(user?.id) === String(step?.attributes?.assigned_to?.[0])
      || (Array.isArray(step?.attributes?.assigned_to) && step?.attributes?.assigned_to?.[0] === null)
    ),
  [currentTrial, step?.attributes?.assigned_to, user?.id]);

  const isActiveManualFluorPickerStep = step?.step_category?.slug === 'fluorochromes' && active && !step?.getAction('generate_panel');

  return isActiveManualFluorPickerStep || location.pathname.includes('/fcs-viewer') || location.pathname.includes('/chorus');
};

export default useFullScreenWidth;
