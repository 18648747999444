import PropTypes from 'prop-types';

import BaseModal from 'containers/Modals/BaseModal';
import styled from 'styled-components';
import { ErrorOctagon } from 'components/Icons';

const ConfirmModal = ({
  message,
  onConfirm,
  onCancel,
  dangerous,
  alert,
  yesNo,
  rdxTypes,
  forceClick,
  explanation,
  noCancel,
  width,
  height,
  isSecondary,
  render,
  confirmText,
  cancelText,
}) => {
  return (
    <BaseModal
      dimensions={{
        width,
        height,
      }}
      confirm={{
        text: confirmText ? confirmText : yesNo ? 'Yes'  : dangerous ? 'confirm' : 'OK',
        handler: onConfirm,
      }}
      cancel={alert || noCancel ? { render: () => null } : {
        text: cancelText ? cancelText : yesNo ? 'No' : 'Cancel',
        handler: onCancel,
      }}
      dangerous={dangerous}
      alert={alert}
      forceClick={forceClick}
      rdxTypes={rdxTypes}
      isSecondary={isSecondary}
    >
      <h2>{alert ? <Alert size="1.5rem" /> : ''}{message}{render()}</h2>
      {explanation && (
        <Explanation alert={alert}><small>{explanation}</small></Explanation>
      )}
    </BaseModal>
  );
};

ConfirmModal.propTypes = {
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  dangerous: PropTypes.bool,
  forceClick: PropTypes.bool,
  yesNo: PropTypes.bool,
  rdxTypes: PropTypes.arrayOf(PropTypes.string),
  explanation: PropTypes.string,
  alert: PropTypes.bool,
  noCancel: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  isSecondary: PropTypes.bool,
  render: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

ConfirmModal.defaultProps = {
  message: '',
  onConfirm: () => { },
  onCancel: () => { },
  dangerous: false,
  forceClick: true,
  yesNo: false,
  rdxTypes: [],
  explanation: '',
  alert: false,
  noCancel: false,
  width: '600px',
  height: '250px',
  isSecondary: false,
  render: () => {},
  confirmText: '',
  cancelText: '',
};

export default ConfirmModal;

const Explanation = styled.p`
  margin-top: 1rem;
  color: ${({ alert, theme }) => (alert ? theme.colors.danger : theme.colors.fontDark)};
  margin-bottom: 1rem;
`;

const Alert = styled(ErrorOctagon)`
  fill: ${({ theme }) => theme.colors.danger};
  margin: 0 1rem -0.38rem 0;
`;
