import styled, { css } from 'styled-components';
import globals from 'styles/globals';
import Link from 'components/Link';

const { colors, fonts, fontSizes, layout } = globals;

export const Container = styled.div<{ isSignUp?: boolean }>`
  width: 100%;
  max-width: ${layout.loginContainerWidth};
  ${(props) => props.isSignUp && css`
    margin: 15px 0;
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
`;

export const Header = styled.div`
  font: ${fonts.formTitle};
  color: ${colors.fontDark};
`;

export const Subheader = styled.div`
  font: ${fonts.body};
  font-weight: 300;
  color: ${colors.linkGrey};
  padding-top: 5px;
`;

export const Form = styled.form`

`;

export const FormBody = styled.div`
  margin-bottom: 25px;
`;

export const InputGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.div`
  color: ${colors.fontDark};
  font: ${fonts.body};
  font-size: ${fontSizes.small};
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const Errors = ({ errors }: { errors?: string[] }) => (Array.isArray(errors) ? errors : []).map((error) => (
  <Error key={error}>{error}</Error>
));

const Error = styled.div`
  color: ${colors.red};
  font: ${fonts.label};
  margin-top: 5px;
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoText = styled.div`
  margin-left: 30px;
  font-size: ${fontSizes.small};
  color: ${colors.linkGrey};
`;

export const LinkText = styled(Link)`
  color: ${colors.primary};
`;

export const ConfirmationTextContainer = styled.div<{ show?: boolean }>`
  margin-top: 25px;
  width: 100%;
  max-width: ${layout.loginContainerWidth};

  ${({ show }) => !show && css`
    display: none;
  `}
`;

export const ConfirmationText = styled.div`
  font: ${fonts.body};
  color: ${colors.linkGrey};
`;

export const ConfirmationSubText = styled.div`
  font: ${fonts.body};
  color: ${colors.fontLight};
  font-size: 12px;
  margin-top: 10px;
`;
