import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const Icon = forwardRef((props, ref) => (
  <svg viewBox="0 0 600 600" ref={ref} {...props}>
    <circle fill="#B2C7DE" cx="300" cy="300" r="300" />
    <circle fill="#4C7DB3" cx="300" cy="300" r="212.61" />
    <circle fill="#004693" cx="300" cy="300" r="131.33" />
  </svg>
));

export default createIcon(Icon);
