import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { GenericActionPayload, PayloadActionWithGenericPayload } from 'types/redux-types';

export interface RequestPanelFileAction extends PayloadActionWithGenericPayload {
  payload: GenericActionPayload & { fileSaved?: boolean };
}

function* requestPanelsCsvReport({ payload: { action, params, onSuccess, onError, fileSaved } }: RequestPanelFileAction) {
  const { error, success, data } = yield makeRequest.action(action, params);
  if (success && data) {
    if (onSuccess) {
      onSuccess(data);
    }
    if (fileSaved) {
      yield put(newMessageEvent({ text: 'File saved to File Explorer in current workflow' }));
    }
  }
  if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestPanelsCsvReport;
