import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducers';
import rootSaga from './sagas';

const initialState = {};

// middlewares
const sagaMiddleware = createSagaMiddleware();

const configStore = () => {
  const middlewares = [
    sagaMiddleware,
  ];

  const store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
      .concat(middlewares),
    preloadedState: initialState,
    devTools: !!import.meta.env.DEV,
  });

  // Expose store in window, for testing
  if (import.meta.env.DEV) {
    window.store = store;
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configStore;
