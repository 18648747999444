import { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'hooks/useForm';
import { useCountdown } from 'hooks/useCountdown';

import { useLocation, Redirect } from 'react-router-dom';
import FormikInput from 'components/Formik/FormikInput/index';
import { useAppDispatch } from 'hooks/reduxHooks';
import { resetPassword } from 'rdx/modules/auth/slice';
import {
  Container,
  HeaderContainer,
  Header,
  Subheader,
  Form,
  FormBody,
  InputGroup,
  Label,
  Errors,
  LinkText,
  ConfirmationText,
  ConfirmationTextContainer,
} from '../components';
import PrimaryButton from 'components/Button/PrimaryButton';

const ResetPassword = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const countdown = useCountdown(5);

  const code = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('code') ?? '';
  }, [location.search]);

  const { form, fields: { password, password_confirm } } = useForm<{ password: string, password_confirm: string }>({
    password: {
      initialValue: '',
      schema: Yup.string()
        .min(8, 'Password must be at least 8 characters'),
      onChange: (e, data) => {
        if (data?.fields) {
          data.fields.password.setValue(e.target.value);
          data.fields.password_confirm.reset();
        }
      },
    },
    password_confirm: {
      initialValue: '',
      validate: (value, data) => (value !== data?.fields.password.value ? "Passwords don't match" : null),
    },
  }, {
    onSubmit: (values) => {
      const { password } = values
      dispatch(resetPassword({ 
        params: {
          code, 
          password, 
        },
        onSuccess: () => {
          setIsSuccess(true);
        },
      }));
      form.setSubmitting(false);
      setSubmitted(true);
    },
  });

  useEffect(() => {
    if (submitted && !countdown.active) {
      countdown.start();
    }
  }, [submitted]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!code) {
    return <Redirect to="/forgot-password" />;
  }

  if (countdown.value === 0) {
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      <HeaderContainer>
        <Header>
          Reset Password
        </Header>
        <Subheader>
          Enter a new password below
        </Subheader>
      </HeaderContainer>
      <Form {...form.props}>
        <FormBody>
          <InputGroup>
            <Label>Password</Label>
            <FormikInput {...password.props} type="password" autoComplete="new-password" disabled={submitted} />
            <Errors errors={password.visited ? password.errors : []} />
          </InputGroup>
          <InputGroup>
            <Label>Confirm Password</Label>
            <FormikInput {...password_confirm.props} type="password" disabled={!password.isValid || submitted} />
            <Errors errors={password_confirm.touched ? password_confirm.errors : []} />
          </InputGroup>
        </FormBody>
        <PrimaryButton disabled={(!form.isValid || form.isSubmitting || submitted)} text="Reset" type="submit" />
      </Form>
      <ConfirmationTextContainer show={submitted && isSuccess}>
        <Header>
          Success!
        </Header>
        <ConfirmationText>
          Your password has been updated. You will be redirected to the login page in {countdown.value} seconds, or <LinkText to="/login">click here</LinkText> to go back now.
        </ConfirmationText>
      </ConfirmationTextContainer>
    </Container>
  );
};

export default ResetPassword;
