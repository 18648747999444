import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const ProjectIcon = forwardRef((props, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 499.89 393.78"
  >
    <g>
      <path d="M499.88,59.12c0-1.81,0-3.63,0-5.87c-149.05,0-297.75,0-447.09,0c0,1.95,0,3.72,0,5.5c0,109.89,0,219.79,0.03,329.68
        c0,1.78,0.46,3.56,0.71,5.35c148.71,0,297.41,0,446.12,0c0.08-26.76,0.22-53.53,0.23-80.29C499.9,228.7,499.88,143.91,499.88,59.12
        z M144.41,73.8c24.84,0,49.2,0,73.79,0c0,36.07,0,71.86,0,108.35c-10.45-4.61-20.57-8.73-30.34-13.55
        c-4.66-2.29-8.46-2.21-13.04-0.01c-9.84,4.72-19.92,8.91-30.41,13.55C144.41,145.7,144.41,109.92,144.41,73.8z M478.36,371.7
        c-134.72,0-269.47,0-404.55,0c0-99.23,0-198.39,0-297.99c16.29,0,32.2,0,48.62,0c0,46.81,0,93.44,0,141.06
        c2.35-0.87,3.98-1.39,5.53-2.07c16.56-7.31,33.06-14.74,49.7-21.84c2.08-0.89,5.2-0.84,7.3,0.05c16.64,7.11,33.15,14.53,49.7,21.83
        c1.45,0.64,2.97,1.14,5.13,1.95c0-47.31,0-93.93,0-140.86c79.75,0,159,0,238.57,0C478.36,173,478.36,272.15,478.36,371.7z"
      />
      <path d="M457.13,0C304.51,0,152.24,0,0,0c0,117.1,0,233.87,0,350.52c7.11,0,13.84,0,20.94,0c0-109.89,0-219.41,0-329.81
        c145.84,0,290.95,0,436.19,0C457.13,13.45,457.13,6.73,457.13,0z"
      />
    </g>
  </svg>
));

export default createIcon(ProjectIcon);
