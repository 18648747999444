import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setOrgUser } from 'rdx/modules/users/slice';

function* getOrgUser({ payload: { params, link, onSuccess, onError } }) {
  const { success, error, data } = yield* makeRequest.link(link, params);
  if (success) {
    yield put(setOrgUser(data.unwrap()));
    if (onSuccess) {
      onSuccess();
    }
  } else {
    if (onError) {
      return onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default getOrgUser;
