import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import globals from 'styles/globals';

type Props = {
  value: boolean,
  onChange: (newVal: boolean) => void,
  disabled?: boolean,
  className?: string,
  style?: React.CSSProperties
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'value'>

const Checkbox = forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(({
  value,
  onChange,
  disabled,
  className,
  style,
  children,
  ...inputProps
}, ref) => {
  const handleClick = () => {
    onChange(!value);
  };

  return (
    <Container
      onClick={() => { handleClick(); }}
      disabled={disabled}
      className={className}
      style={style}
      ref={ref}
    >
      <HiddenInput
        {...inputProps}
        type="checkbox"
        value={String(value)}
        onChange={onChange}
      />
      <CustomCheckbox checked={value} data-testid="checkbox" />
      <CheckboxContent>
        {children}
      </CheckboxContent>
    </Container>
  );
});

Checkbox.displayName = 'Checkbox'

export default Checkbox;

const { colors } = globals;

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${({ disabled }) => disabled && css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `}
`;

const HiddenInput = styled.input`
  display: none;
`;

const CustomCheckbox = styled.div<{ checked?: boolean }>`
    position: relative;
    top: 0px;
    left: 0px;
    width: 29px;
    height: 29px;
    flex: 0 0 auto;
    background-color: transparent;
    border: solid ${colors.charcoal} 1px;
    transition-timing-function: ease-out;
    transition: background-color 300ms;

    &:after {
      content: "";
      display: block;
      position: absolute;
      opacity: 0;
      left: 23px;
      top: 5px;
      width: 0px;
      height: 0px;
      border: solid ${colors.white};
      border-width: 0 3px 3px 0;
      transform: rotate(40deg);
      transition-timing-function: ease-out;
      transition: all 0ms;
    }

    ${({ checked }) => checked && css`
      background-color: ${colors.primary};
      border: solid ${colors.primary} 1px;

      &:after {
        opacity: 1;
        width: 8px;
        height: 17px;
        left: 10px;
        top: 3px;
        transition: all 300ms;
      }
    `}
`;

const CheckboxContent = styled.div`
  ${({ children }) => React.Children.count(children) > 0 && css`
    display: auto;
    margin-left: 10px;
  `}
`;
