/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getModalIsOpen, callModal, setModal, openModal, closeModal, closeAndUnsetModal } from 'rdx/modules/modals/slice';

export const useModal = () => {
  const dispatch = useAppDispatch();
  const modalIsOpen = useAppSelector(getModalIsOpen);

  const dispatchCallModal = useCallback((component) => dispatch(callModal(component)), [dispatch]);
  const dispatchSetModal = useCallback((component) => dispatch(setModal(component)), [dispatch]);
  const dispatchOpenModal = useCallback(() => dispatch(openModal()), [dispatch]);
  const dispatchCloseModal = useCallback(() => dispatch(closeModal()), [dispatch]);
  const dispatchCloseAndUnsetModal = useCallback(() => dispatch(closeAndUnsetModal()), [dispatch]);

  return {
    callModal: dispatchCallModal,
    setModal: dispatchSetModal,
    openModal: dispatchOpenModal,
    closeModal: dispatchCloseModal,
    closeAndUnsetModal: dispatchCloseAndUnsetModal,
    modalIsOpen,
  };
};
