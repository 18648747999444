import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { ORG_PERMISSIONS } from 'lib/constants';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import getLicense from 'lib/license';
import { resetStore } from 'rdx/modules/app/slice';
import type { ResourceObject, ResourceObjects } from 'types/json-api-types';
import { OrganizationAttributes } from 'types/organizations';
import type { ProjectAttributes } from 'types/projects';
import type { GenericActionPayload } from 'types/redux-types';

type OrganizationState = {
  currentOrganization: Resource<OrganizationAttributes>;
  organizations: ResourceObjects;
  organizationGroups: ResourceList;
  organizationProjects: ResourceList<ProjectAttributes>;
  organizationWorkflows: ResourceList;
  organizationLabs: ResourceList;
  organizationSettings: ResourceList;
};

const initialState: OrganizationState = {
  currentOrganization: new Resource(),
  organizations: [],
  organizationGroups: new ResourceList(),
  organizationProjects: new ResourceList(),
  organizationWorkflows: new ResourceList(),
  organizationLabs: new ResourceList(),
  organizationSettings: new ResourceList(),
};

export type RequestOrganizationProjectsQuery = {
  pageSize?: number,
  page?: number,
  sorting?: string,
  search?: string,
  detail?: boolean,
  orderedSearch?: boolean,
  sorted_project_id?: number,
}

const requestOrganizationGroups = createAction('requestOrganizationGroups');
const requestJoinOrganizationGroup = createAction('requestJoinOrganizationGroup');
const requestOrganizationProjects = createAction<GenericActionPayload<undefined, RequestOrganizationProjectsQuery>>('requestOrganizationProjects');
const requestOrganizationWorkflows = createAction('requestOrganizationWorkflows');
const requestOrganizationLabs = createAction('requestOrganizationLabs');
const requestOrganizationProfile = createAction<GenericActionPayload & {
  organizationID: string,
  onError:({ currentOrgSlug }: { currentOrgSlug?: string }) => void
    }>('requestOrganizationProfile');
const updateOrganizationProfile = createAction('updateOrganizationProfile');
const surrenderOrganization = createAction('surrenderOrganization');
const requestMoreOrganizationGroups = createAction('requestMoreOrganizationGroups');
const createOrganization = createAction('createOrganization');
const postSeed = createAction('postSeed');
const fetchCSV = createAction('fetchCSV');
const attachLicenseToOrganization = createAction('attachLicenseToOrganization');
const requestOrganizationSettings = createAction('requestOrganizationSettings');
const updateOrganizationSettings = createAction('updateOrganizationSettings');
const createTrialLicenseForOrg = createAction<GenericActionPayload<{ pn: string }>>('organization/createTrialLicenseForOrg');

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setCurrentOrganization: (state, action: PayloadAction<Resource<OrganizationAttributes>>) => { state.currentOrganization = action.payload; },
    setOrganizations: (state, action: PayloadAction<ResourceObjects>) => { state.organizations = action.payload; },
    setOrganizationGroups: (state, action: PayloadAction<ResourceList>) => { state.organizationGroups = action.payload; },
    setMoreOrganizationGroups: (state, action: PayloadAction<ResourceList>) => {
      state.organizationGroups = state.organizationGroups.mergeWith(action.payload);
    },
    setOrganizationProjects: (state, action: PayloadAction<ResourceList<ProjectAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.organizationProjects = state.organizationProjects?.mergeWith(action.payload) ?? state.organizationProjects;
        return;
      }
      state.organizationProjects = action.payload;
    },
    setOrganizationWorkflows: (state, action: PayloadAction<ResourceList>) => { state.organizationWorkflows = action.payload; },
    setMoreOrganizationWorkflows: (state, action: PayloadAction<ResourceList>) => {
      state.organizationWorkflows = state.organizationWorkflows.mergeWith(action.payload);
    },
    setOrganizationLabs: (state, action: PayloadAction<ResourceList>) => { state.organizationLabs = action.payload; },
    setOrganizationSettings: (state, action: PayloadAction<ResourceList>) => { state.organizationSettings = action.payload; },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setCurrentOrganization,
  setOrganizations,
  setOrganizationGroups,
  setMoreOrganizationGroups,
  setOrganizationProjects,
  setOrganizationWorkflows,
  setMoreOrganizationWorkflows,
  setOrganizationLabs,
  setOrganizationSettings,
} = organizationSlice.actions;

// Actions
export {
  requestOrganizationGroups,
  requestJoinOrganizationGroup,
  requestOrganizationProjects,
  requestOrganizationWorkflows,
  requestOrganizationLabs,
  requestOrganizationProfile,
  updateOrganizationProfile,
  surrenderOrganization,
  requestMoreOrganizationGroups,
  createOrganization,
  postSeed,
  fetchCSV,
  attachLicenseToOrganization,
  requestOrganizationSettings,
  updateOrganizationSettings,
  setCurrentOrganization,
  setOrganizations,
  setOrganizationGroups,
  setMoreOrganizationGroups,
  setOrganizationProjects,
  setOrganizationWorkflows,
  setMoreOrganizationWorkflows,
  setOrganizationLabs,
  setOrganizationSettings,
  createTrialLicenseForOrg,
};

// Selectors
export const getOrganizations = (state: RootState) => state.organization.organizations;
export const getCurrentOrganization = (state: RootState) => state.organization.currentOrganization;
export const getCurrentOrganizationLicense = (state: RootState) => {
  const orgLicenses = state.organization.currentOrganization.included?.filter((i) => i.type === 'licenses');
  const license = getLicense(orgLicenses);
  return license;
};
export const getCurrentOrgGatedFeatureBlurbs = (state: RootState) => {
  return state.organization.currentOrganization?.attributes?.org_applicable_gated_feature_blurbs;
}
export const getCurrentOrganizationAlphaTesting = (state: RootState) => !!state.organization.currentOrganization?.attributes?.alpha_testing_enabled;
export const getCurrentOrganizationBetaTesting = (state: RootState) => !!state.organization.currentOrganization?.attributes?.beta_testing_enabled;
export const getCurrentOrgUUID = (state: RootState) => state.organization.currentOrganization?.attributes?.uuid;
export const getCurrentOrgSlug = (state: RootState) => state.organization.currentOrganization?.attributes?.slug;
export const getFirstLoginOrganization = (state: RootState) => state.organization.organizations?.find((org) => !org.attributes?.is_sandbox);
// check above
export const getOrganizationGroups = (state: RootState) => state.organization.organizationGroups;
export const getOrganizationProjects = (state: RootState) => state.organization.organizationProjects;
export const getOrganizationWorkflows = (state: RootState) => state.organization.organizationWorkflows;
export const getOrganizationLabs = (state: RootState) => state.organization.organizationLabs;
export const getOrganizationSettings = (state: RootState) => state.organization.organizationSettings;
export const getOrganizationSettingValueByName = (setting: string) => (state: RootState) => {
  const orgSettingsData = state.organization.organizationSettings?.data as ResourceObject[];
  return orgSettingsData.find?.((s) => s.attributes?.key === setting)?.attributes?.data;
};
export const getOrganizationScopes = (state: RootState) => state.organization.currentOrganization?.attributes?.current_user_scope;
export const getIsOrgOwner = (state: RootState) => {
  const currentUserId = state.users.currentUser?.id;
  const orgCreatorId = state.organization.currentOrganization?.attributes?.created_by;
  return currentUserId && orgCreatorId && `${currentUserId}` === `${orgCreatorId}`
};
export const getIsOrgAdmin = (state: RootState) => {
  const currentUserId = state.users.currentUser?.id;
  const orgCreatorId = state.organization.currentOrganization?.attributes?.created_by;
  if (currentUserId && orgCreatorId && `${currentUserId}` === `${orgCreatorId}`) {
    return true;
  }
  const adminPermissionsList = Object.values(ORG_PERMISSIONS).map((i) => i.value);
  const scopes = state.organization.currentOrganization?.attributes?.current_user_scope;
  if (adminPermissionsList.every((p) => scopes?.includes(p))) {
    return true;
  }
  return false;
};
// check above
export const getOrgStorageWarning = (state:RootState) => state.organization.currentOrganization?.attributes?.storage_limit_warning;
export const getOrgStorageAlert = (state: RootState) => state.organization.currentOrganization?.attributes?.storage_limit_alert;

// Reducer
export default organizationSlice.reducer;
