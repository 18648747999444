import React from 'react';
import PropTypes from 'prop-types';
import customPropTypes from 'lib/customPropTypes';
import styled, { keyframes } from 'styled-components';

import { NavLink } from 'react-router-dom';
import background from 'assets/background.jpg';
import bdrcLogo from 'assets/bdrc-logo-white.svg';
import bdrcLogoFooter from 'assets/bdrc-logo-white.png';
import globals from 'styles/globals';

const SplashBackground = ({ showFooter, children }) => (
  <PageContainer>
    <SplashHeader />
    <BodyContainer>
      <BackgroundImageContainer>
        {children}
      </BackgroundImageContainer>
      {showFooter && <SplashFooter />}
    </BodyContainer>
  </PageContainer>
);

SplashBackground.propTypes = {
  showFooter: PropTypes.bool,
  children: customPropTypes.children,
};

SplashBackground.defaultProps = {
  showFooter: false,
  children: undefined,
};

export default SplashBackground;

const SplashHeader = () => (
  <TopBar>
    <HomeLink to="/">
      <BDRCLogo src={bdrcLogo} alt="BD Research Cloud" />
    </HomeLink>
  </TopBar>
);

// Save this until these links are defined later
// const SplashLinks = () => (
//   <LinksBody>
//     <TriangleTop />
//     <LinksContainer>
//       <LinkBlock>
//         <LinkBlockTitle>OFFERINGS</LinkBlockTitle>
//         <LinkWrapper to="#" activeClassName="active" title="Capabilities">
//           Capabilities
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Brands">
//           Brands
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Integrated solutions">
//           Integrated solutions
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Catalogs">
//           Catalogs
//         </LinkWrapper>
//       </LinkBlock>
//
//       <LinkBlock>
//         <LinkBlockTitle>COMPANY</LinkBlockTitle>
//         <LinkWrapper to="#" activeClassName="active" title="Contact us">
//           Contact us
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Careers">
//           Careers
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Sustainability">
//           Sustainability
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Suppliers">
//           Suppliers
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="News">
//           News
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Investors">
//           Investors
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Video gallery">
//           Video gallery
//         </LinkWrapper>
//       </LinkBlock>
//
//       <LinkBlock>
//         <LinkBlockTitle>CLINICAL EXCELLENCE</LinkBlockTitle>
//         <LinkWrapper to="#" activeClassName="active" title="Case studies">
//           Case studies
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Conferences and symposia">
//           Conferences and symposia
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Webcasts">
//           Webcasts
//         </LinkWrapper>
//       </LinkBlock>
//
//       <LinkBlock>
//         <LinkBlockTitle>SUPPORT</LinkBlockTitle>
//         <LinkWrapper to="#" activeClassName="active" title="Technical support">
//           Technical support
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Customer portals">
//           Customer portals
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Alerts and notices">
//           Alerts and notices
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Product security and privacy">
//           Product security and privacy
//         </LinkWrapper>
//         <LinkWrapper to="#" activeClassName="active" title="Electronic Instructions for Use">
//           Electronic Instructions for Use
//         </LinkWrapper>
//       </LinkBlock>
//     </LinksContainer>
//     <TriangleBottom />
//   </LinksBody>
// );

const SplashFooter = () => (
  <Footer>
    <Social>
      <BDFooterLogo src={bdrcLogoFooter} />
    </Social>
    <Disclaimer>
      <FooterText>
        © 2020 BD. All rights reserved. Unless otherwise noted, BD, the BD Logo and all other trademarks are property of
        Becton, Dickinson and Company.
      </FooterText>
    </Disclaimer>
    <FooterSpacer />
  </Footer>
);

const { colors, fonts, fontFamilies } = globals;

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  margin-top: 64px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TopBar = styled.div`
  color: ${colors.white};
  background-color: ${colors.charcoal};
  height: 64px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${fontFamilies.avenirBook};
  font-size: 15pt;
`;

const HomeLink = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-left: 5.4%;
  padding: 0px 10px;
  &:hover {
    text-decoration: none;
  }
`;

const BDRCLogo = styled.img`
  height: 23px;
`;

const BDFooterLogo = styled.img`
  height: 50px;
  margin-left: 1%;
  margin-right: 10px;
`;

const blurIn = keyframes`
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

const BackgroundImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh + 10px);
  padding-bottom: 90px;
  width: 100%;
  background-image: linear-gradient(135deg,#FFC45D 10%,#D52052,#FFBE96 95%);
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(${background});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    animation: 0.5s ${blurIn} ease-out;
  }
`;

// const TriangleTop = styled.div`
//   pointer-events: none;
//   width: 0;
//   height: 0;
//   position: absolute;
//   bottom: 100%;
//   border-bottom: 180px solid ${colors.lightGrey};
//   border-left: 4000px solid transparent;
//   z-index: 3;
// `;
//
// const LinksBody = styled.div`
//   background-color: ${colors.lightGrey};
//   height: 350px;
//   display: flex;
// `;
//
// const TriangleBottom = styled.div`
//   width: 0;
//   height: 0;
//   position: absolute;
//   top: 100%;
//   border-top: 80px solid ${colors.lightGrey};
//   border-left: 4000px solid transparent;
//   z-index: 3;
// `;
//
// const LinksContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   background-color: ${colors.lightGrey};
//   max-width: 750px;
//   flex: 1;
//   margin: auto;
// `;
//
// const LinkBlock = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin: 10px;
// `;
//
// const LinkBlockTitle = styled.div`
//   color: ${colors.fontDark};
//   font-weight: 800;
//   font-family: ${fontFamilies.helvetica};
//   font-size: 10pt;
//   padding-bottom: 5px;
// `;
//
// const LinkWrapper = styled(NavLink)`
//   color: ${colors.linkGrey};
//   font-weight: 350;
//   font-family: ${fontFamilies.helvetica};
//   font-size: 10pt;
//   padding: 5px 0px;
// `;

const Footer = styled.div`
  min-height: 40px;
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
  background-color: ${colors.charcoal};
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const FooterText = styled.div`
  font: ${fonts.sub};
  color: ${colors.white};
  text-align: center;
  font-weight: 650;
  min-width: 650px;
`;

const Social = styled.div`
  flex: 1;
  display: flex;
  margin-left: 20px;
`;

const FooterSpacer = styled.div`
  flex: 1;
`;

const Disclaimer = styled.div`
  flex: 3;
`;
