import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getOrganizations } from 'rdx/modules/organization/slice';
import { deleteOrgUser, getOrgUsersRaw, getUserId } from 'rdx/modules/users/slice';

function* deleteOrgUserSaga({ payload: { action, onSuccess } }) {
  const orgs = yield select(getOrganizations);
  const orgUsers = yield select(getOrgUsersRaw);
  const deletedUserOrgId = action.url.match(/\d+$/)[0];
  const userId = yield select(getUserId);
  const deletedUserId = orgUsers.data.find((u) => parseInt(u.id) === parseInt(deletedUserOrgId))?.attributes?.user_id;
  const { success, error } = yield* makeRequest.action(action);
  if (success) {
    // only do this if current user is no longer part of group:
    if (parseInt(userId) === parseInt(deletedUserId)) {
      const sandboxSlug = orgs.find((o) => o.is_sandbox)?.attributes?.slug;
      yield put(newMessageEvent({ text: 'User Deleted' }));
      onSuccess?.({ routeTo: `/${sandboxSlug}` });
    } else {
      onSuccess?.({ routeTo: '' });
    }
  } else {
    yield* putErrorActions({ error, jsonApiAction: action, rdxAction: deleteOrgUser, onSuccess });
  }
  return null;
}

export default deleteOrgUserSaga;
