import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { POPULATION_CATEGORIES } from 'lib/constants';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { AntigenAttributes } from 'types/antigens';
import { ImmuneSubsetAttributes } from 'types/immuneSubsets';
import type { UnwrappedResourceList } from 'types/json-api-types';
import { AntigenCategory } from 'types/reagents';
import { GenericActionPayload } from 'types/redux-types';
import { SpeciesAttributes } from 'types/species';

export type RequestImmuneSubsetsQuery = {
  pageSize?: number,
  page?: number,
  sorting?: string,
  search?: string,
  specie?: string,
  marker?: string,
  positive_marker?: string,
  negative_marker?: string,
  created_by?: number,
  tag?: string,
  category?: string,
}

export type RequestSearchAntigensQuery = {
  pageSize?: number,
  page?: number,
  sorting?: string,
  search?: string,
  exact?: boolean,
  region?: string,
  'species[]'?: string[],
  category?: AntigenCategory | null,
  'regulatory_statuses[]'?: string | string[],
}

export type CreateImmuneSubsetParams = {
  name: string,
  antigen_densities: {
    density: 'low' | 'mid' | 'high' | 'positive' | 'none' | 'unknown',
    antigen: string,
    specie: string,
    critical?: boolean
  }[],
  species: string[],
  citation_url?: string | null,
  tags?: string[],
}

export type UpdateImmuneSubsetParams = Omit<CreateImmuneSubsetParams, 'name'> & { name?: string };

const searchAntigens = createAction<GenericActionPayload<{ organization_id: string }, RequestSearchAntigensQuery>>('searchAntigens');
const searchViabilityDyes = createAction('searchViabilityDyes');
const requestSpecies = createAction<GenericActionPayload>('requestSpecies');
const requestSessionImmuneSubsets = createAction<GenericActionPayload<{ organization_id: number }, RequestImmuneSubsetsQuery>>('requestSessionImmuneSubsets');
const requestAntigenData = createAction<GenericActionPayload & { append: boolean }>('requestAntigenData');
const requestImmuneSubsets = createAction<GenericActionPayload<{ organization_id: string }, RequestImmuneSubsetsQuery>>('requestImmuneSubsets');
const requestCustomImmuneSubsets = createAction('requestCustomImmuneSubsets');
const requestBDImmuneSubsets = createAction('requestBDImmuneSubsets');
const requestSearchSubsets = createAction('requestSearchSubsets');
const requestImmuneSubsetTags = createAction<GenericActionPayload>('requestImmuneSubsetTags');
const createSubset = createAction<GenericActionPayload<CreateImmuneSubsetParams>>('createSubset');
const updateSubset = createAction<GenericActionPayload<UpdateImmuneSubsetParams>>('updateSubset');
const deleteSubset = createAction<GenericActionPayload>('deleteSubset');

type PopulationCategories = 'custom' | 'bd';

interface SystemSliceState {
  searchAntigens: ResourceList<AntigenAttributes>,
  searchViabilityDyes: ResourceList,
  species: ResourceList<SpeciesAttributes>,
  immuneSubsets: ResourceList<ImmuneSubsetAttributes>,
  antigenData: Record<string, Partial<AntigenAttributes> & { id: string }>,
  customImmuneSubsets: ResourceList,
  bdImmuneSubsets: ResourceList,
  searchSubsets: ResourceList,
  immuneSubsetTags: ResourceList,
  populationExpandedStates: {
    [key in PopulationCategories]: boolean
  }
}

const initialState: SystemSliceState = {
  searchAntigens: new ResourceList(),
  searchViabilityDyes: new ResourceList(),
  species: new ResourceList(),
  immuneSubsets: new ResourceList(),
  antigenData: {},
  customImmuneSubsets: new ResourceList(),
  bdImmuneSubsets: new ResourceList(),
  searchSubsets: new ResourceList(),
  immuneSubsetTags: new ResourceList(),
  populationExpandedStates: {
    [POPULATION_CATEGORIES.CUSTOM]: true,
    [POPULATION_CATEGORIES.BD]: true,
  },
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setSearchAntigens: (state, action: PayloadAction<ResourceList<AntigenAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.searchAntigens = state.searchAntigens.mergeWith(action.payload);
      } else {
        state.searchAntigens = action.payload;
      }
    },
    setSearchViabilityDyes: (state, action: PayloadAction<ResourceList>) => {
      state.searchViabilityDyes = action.payload;
    },
    setSpecies: (state, action: PayloadAction<ResourceList<SpeciesAttributes>>) => {
      state.species = action.payload;
    },
    setImmuneSubsets: (state, action: PayloadAction<ResourceList<ImmuneSubsetAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.immuneSubsets = state.immuneSubsets.mergeWith(action.payload);
      } else {
        state.immuneSubsets = action.payload;
      }
    },
    appendAntigenData: (state, action: PayloadAction<Resource | UnwrappedResourceList>) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((antigen) => {
          const data = {
            id: antigen.id,
            ...antigen.attributes,
          };
          state.antigenData[antigen.id] = data;
        });
      } else {
        const antigen = action.payload;
        const data = {
          id: antigen.id,
          ...antigen.attributes,
        };
        state.antigenData[antigen.id] = data;
      }
    },
    removeAntigenData: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.antigenData = Object.entries(state.antigenData)
        .filter(([ant]) => ant !== id)
        .reduce((acc, [ant, data]) => ({ ...acc, [ant]: data }), {});
    },
    replaceAntigenData: (state, action: PayloadAction<Resource | UnwrappedResourceList>) => {
      state.antigenData = {};
      if (Array.isArray(action.payload)) {
        action.payload.forEach((antigen) => {
          const data = {
            id: antigen.id,
            ...antigen.attributes,
          };
          state.antigenData[antigen.id] = data;
        });
      } else {
        const antigen = action.payload;
        const data = {
          id: antigen.id,
          ...antigen.attributes,
        };
        state.antigenData[antigen.id] = data;
      }
    },
    setCustomImmuneSubsets: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.customImmuneSubsets = state.customImmuneSubsets.mergeWith(action.payload);
      } else {
        state.customImmuneSubsets = action.payload;
      }
    },
    setBDImmuneSubsets: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.bdImmuneSubsets = state.bdImmuneSubsets.mergeWith(action.payload);
      } else {
        state.bdImmuneSubsets = action.payload;
      }
    },
    setSearchSubsets: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.searchSubsets = state.searchSubsets.mergeWith(action.payload);
      } else {
        state.searchSubsets = action.payload;
      }
    },
    setImmuneSubsetTags: (state, action: PayloadAction<ResourceList>) => {
      state.immuneSubsetTags = action.payload;
    },
    setPopulationExpandedStates: (state, action: PayloadAction<{ [key in PopulationCategories]: boolean }>) => {
      state.populationExpandedStates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setSearchAntigens,
  setSearchViabilityDyes,
  setSpecies,
  setImmuneSubsets,
  appendAntigenData,
  removeAntigenData,
  replaceAntigenData,
  setCustomImmuneSubsets,
  setBDImmuneSubsets,
  setSearchSubsets,
  setImmuneSubsetTags,
  setPopulationExpandedStates,
} = systemSlice.actions;

// Selectors
export const getSearchAntigens = (state: RootState) => state.system.searchAntigens;
export const getSearchViabilityDyes = (state: RootState) => state.system.searchViabilityDyes;
export const getAntigenData = (state: RootState) => state.system.antigenData;
export const getSpecies = (state: RootState) => state.system.species;
export const getImmuneSubsets = (state: RootState) => state.system.immuneSubsets;
export const getCustomImmuneSubsets = (state: RootState) => state.system.customImmuneSubsets;
export const getBDImmuneSubsets = (state: RootState) => state.system.bdImmuneSubsets;
export const getSearchSubsets = (state: RootState) => state.system.searchSubsets;
export const getImmuneSubsetTags = (state: RootState) => {
  const unwrappedTags = state.system.immuneSubsetTags.unwrap();
  if (Array.isArray(unwrappedTags)) {
    return unwrappedTags.map(({ attributes }) => attributes.tag);
  }
};
export const getPopulationExpandedStates = (state: RootState) => state.system.populationExpandedStates;

// Actions
export {
  setSearchAntigens,
  setSearchViabilityDyes,
  searchAntigens,
  searchViabilityDyes,
  requestSpecies,
  setSpecies,
  setImmuneSubsets,
  requestSessionImmuneSubsets,
  appendAntigenData,
  removeAntigenData,
  replaceAntigenData,
  requestAntigenData,
  requestImmuneSubsets,
  setCustomImmuneSubsets,
  requestCustomImmuneSubsets,
  requestBDImmuneSubsets,
  setBDImmuneSubsets,
  setSearchSubsets,
  requestSearchSubsets,
  setImmuneSubsetTags,
  requestImmuneSubsetTags,
  setPopulationExpandedStates,
  createSubset,
  updateSubset,
  deleteSubset,
};

export default systemSlice.reducer;
