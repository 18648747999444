import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import globals from 'styles/globals';
import UserAvatar from 'components/UserAvatar';
import { useLoading } from 'hooks/useLoading';
import { v4 as uuidv4 } from 'uuid';
import { XIcon, ChevronUp, ChevronDown } from 'components/Icons';
import Input from 'components/Form/Input/index';
import { getOrganizations } from 'rdx/modules/organization/slice';
import { getOtherOrgUsersRaw, requestOrgUsers } from 'rdx/modules/users/slice';

const watchRequests = [
  getOtherOrgUsersRaw.type,
];

const AddChannel = ({
  handleAddChannel,
  newChannel,
  currentOrg,
  user,
}) => {
  const loading = useLoading({ watchRequests });
  const [newChannelUsers, setNewChannelUsers] = useState([]);
  const [ownerSearch, setOwnerSearch] = useState([]);
  const dispatch = useDispatch();
  const [showOrg, setShowOrg] = useState(currentOrg);
  const organizations = useSelector(getOrganizations);
  const [queryPage, setQueryPage] = useState(1);
  const [nameChannel, setNameChannel] = useState(false);
  const [channelName, setChannelName] = useState('');
  const orgUsers = useSelector(getOtherOrgUsersRaw);
  const otherOrgUsers = useMemo(() => {
    const options = orgUsers.unwrap().map((u) => u.included?.[0]);
    const lastPage = orgUsers?.meta?.pagination?.page === orgUsers?.meta?.pagination?.pageCount;
    if (orgUsers?.data?.length && !lastPage) {
      options.push({
        value: 'load-more',
        display: '',
      });
    }
    return options;
  }, [orgUsers]);
  const addNewChannelUser = (newUser) => {
    if (!newChannelUsers.find((u) => parseInt(u.id) === parseInt(newUser.id))) {
      setNewChannelUsers([...newChannelUsers, newUser]);
    }
  };
  const removeNewChannelUser = (noUser) => {
    const index = newChannelUsers.indexOf(noUser);
    setNewChannelUsers(() => {
      newChannelUsers.splice(index, 1);
      return [...newChannelUsers];
    });
  };
  const handleNewChannel = () => {
    if (!newChannelUsers) return;
    if (newChannelUsers.length > 1) {
      setChannelName(() => {
        let defaultName = 'Chat with';
        newChannelUsers.map((channelUser) => {
          defaultName = `${defaultName} ${channelUser.attributes.first_name},`;
          return null;
        });
        return defaultName.replace(/,\s*$/, '');
      });
      setNameChannel(true);
    } else {
      newChannel({ newChannelUsers }, channelName);
      handleAddChannel();
    }
  };
  const handleShowOrg = (org) => {
    if (showOrg?.id === org?.id) {
      setShowOrg('');
      setOwnerSearch('');
    } else {
      setShowOrg(org);
      setOwnerSearch('');
    }
  };
  const handleNameChannelSubmit = (event) => {
    event.preventDefault();
    newChannel({ newChannelUsers }, channelName);
    handleAddChannel();
    setNameChannel(false);
  };
  useEffect(() => {
    if (showOrg) {
      dispatch(requestOrgUsers({
        organization_id: showOrg?.slug,
        otherOrg: true,
        query: { page: queryPage ?? 1, search: ownerSearch },
      }));
    }
  }, [showOrg, newChannelUsers, dispatch, queryPage, ownerSearch]);

  return (
    <Div {...{ nameChannel }}>
      <Content {...{ nameChannel }}>
        <Title>Add Channel</Title>
        <NewChannelContainer>
          {newChannelUsers.length
            ? newChannelUsers.map((newChannelUser) => (
              <OrgUserInList
                key={`orgUser${uuidv4()}`}
                onClick={() => removeNewChannelUser(newChannelUser)}
              >
                <UserAvatar
                  key={newChannelUser.id}
                  user={newChannelUser}
                  showName
                  isActive
                />
                <XIcon size={8} />
              </OrgUserInList>
            )) : <SelectUsers>Select people to add</SelectUsers>}
          <GoButton
            type="button"
            onClick={() => handleNewChannel()}
            disabled={!newChannelUsers.length}
            data-testid="createChannel"
          >
            Go
          </GoButton>
        </NewChannelContainer>
        <SelectUsersList>
          {organizations.map((organization) => (
            <Org key={uuidv4()}>
              <OrgName key={organization?.attributes?.uuid} onClick={() => handleShowOrg(organization)}>
                {organization?.attributes?.name}
                {(showOrg?.attributes?.uuid === organization?.attributes?.uuid)
                  ? <ChevronUp size={14} />
                  : <ChevronDown size={14} />}
              </OrgName>
              {(showOrg?.attributes?.uuid === organization?.attributes?.uuid) && (
                <SearchContainer>
                  <Input
                    key="search-org-input"
                    placeholder="Search Org for User"
                    onChange={(e) => setOwnerSearch(e.target.value)}
                    width="260px"
                    autoFocus={!nameChannel}
                    value={ownerSearch}
                    style={{ marginBottom: '10px' }}
                  />
                </SearchContainer>
              )}
              {(showOrg?.attributes?.uuid === organization?.attributes?.uuid && otherOrgUsers)
                ? otherOrgUsers.map((orgUser) => {
                  if (loading) {
                    return null;
                  }
                  if (orgUser.value === 'load-more') {
                    return (
                      <OrgUserInList onClick={() => setQueryPage(queryPage + 1)}>
                        Load More
                      </OrgUserInList>
                    );
                  }
                  if (orgUser.id !== user.id) {
                    return (
                      <React.Fragment key={`${orgUser.id}-container`}>
                        {newChannelUsers.find((item) => item.id === orgUser.id)
                          ? null
                          : (
                            <OrgUserInList
                              onClick={() => addNewChannelUser(orgUser)}
                              key={`orgUser${uuidv4()}`}
                              data-testid="addChannelUser"
                            >
                              <UserAvatar
                                key={orgUser.id}
                                user={orgUser}
                                showName
                                isActive
                              /> +
                            </OrgUserInList>
                          )}
                      </React.Fragment>
                    );
                  }
                  return null;
                })
                : null}
            </Org>
          ))}
        </SelectUsersList>
      </Content>
      <NameChannelWrapper {...{ nameChannel }}>
        <NameChannelContent onSubmit={handleNameChannelSubmit}>
          <Title>Please Enter a Channel Name</Title>
          <NameChannel
            placeholder={channelName}
            type="text"
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
          />
          <GoButton
            type="submit"
            data-testid="nameChannelButton"
          >
            OK
          </GoButton>
        </NameChannelContent>
      </NameChannelWrapper>
      <Close type="button" onClick={() => handleAddChannel()}>
        <XIcon size={14} />
      </Close>
    </Div>
  );
};

const Div = styled.div`
  user-select: none;
  overflow-y: ${({ nameChannel }) => (nameChannel ? 'none' : 'auto')};
`;

const Title = styled.div`
  font: 500 16pt Roboto,sans-serif;
  padding: 20px 0 15px 0;
`;

const NameChannelWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  top: 0px;
  left: 0px;
  pointer-events: ${({ nameChannel }) => (nameChannel ? 'auto' : 'none')};
  opacity: ${({ nameChannel }) => (nameChannel ? 1 : 0)};
  transition: opacity 200ms;
`;

const NameChannel = styled.input`
  padding: 5px;
  margin: 0px 5px 15px 5px;
  border-radius: 5px;
  border: 1px solid grey;
`;

const OrgName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 5px 10px;
  font: 400 14pt Roboto,sans-serif;
  cursor: pointer;
`;

const Org = styled.div`
  background-color: ${globals.colors.lightGrey};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  margin-top: 5px;
`;

const NewChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid ${globals.colors.linkGrey};
  border-radius: 5px;
  padding-top: 5px;
  margin-bottom: 15px;
  height: ${({ usersLength }) => `${140 + ((usersLength - 1) * 50)}px`};
`;

const SelectUsersList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const OrgUserInList = styled.button`
  min-height: 45px;
  margin: 0px 5px 5px 5px;
  font: 400 12pt Roboto,sans-serif;
  border-radius: 5px;
  letter-spacing: 0.4pt;
  background: ${globals.colors.primary};
  border: none;
  padding: 10px;
  cursor: pointer;
  outline: inherit;
  background-color: ${globals.colors.lightGrey};
  &:hover {
    background-color: ${globals.colors.white};
  }
  &:active {
    filter: brightness(0.9);
  }
  transition: all 200ms ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0px 15px 0 15px;
  pointer-events: ${({ nameChannel }) => (!nameChannel ? 'auto' : 'none')};
  opacity: ${({ nameChannel }) => (!nameChannel ? 1 : 0)};
`;
const NameChannelContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 525px;
  margin: 0px 15px 0 15px;
`;

const SelectUsers = styled.div`
  padding: 16px 10px 10px 10px;
  margin-bottom: 10px;
  font: 9pt Roboto,sans-serif;
`;

const SearchContainer = styled.div`
  margin-left: 15px;
`;

const GoButton = styled.button`
  margin: 0px 5px 5px 5px;
  color: #ffffff;
  font: 400 9pt Roboto,sans-serif;
  border-radius: 5px;
  letter-spacing: 0.4pt;
  white-space: nowrap;
  background: ${globals.colors.primary};
  border: none;
  padding: 10px;
  cursor: pointer;
  outline: inherit;
  background-color: ${globals.colors.primary};
  &:hover {
    background-color: ${globals.colors.primaryHover};
  }
  &:active {
    filter: brightness(0.9);
  }
  transition: all 200ms ease;
`;

const Close = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${globals.colors.black};
  font: 400 14pt Roboto,sans-serif;
  border-radius: 100px;
  letter-spacing: 0.4pt;
  white-space: nowrap;
  border: none;
  padding: 10px 12px;
  cursor: pointer;
  outline: inherit;
  background-color: ${globals.colors.white};
  &:hover {
    background-color: ${globals.colors.lightGrey};
  }
  &:active {
    filter: brightness(0.9);
  }
  transition: all 200ms ease;
`;

export default AddChannel;
