import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import type { GenericActionPayload } from 'types/redux-types';
import { NotificationEventsAttributes } from 'types/notificationEvents';

interface NotificationEventsSliceState {
  list: ResourceList<NotificationEventsAttributes>;
}

const initialState: NotificationEventsSliceState = {
  list: new ResourceList<NotificationEventsAttributes>(),
};

const requestNotificationEvents = createAction<GenericActionPayload>('notificationEvents/requestNotificationEvents');
const requestMarkNotificationEventsRead = createAction<GenericActionPayload>('notificationEvents/requestMarkNotificationEventsRead');

const notificationEventsSlice = createSlice({
  name: 'notificationEvents',
  initialState,
  reducers: {
    setNotificationEvents: (state, action: PayloadAction<ResourceList<NotificationEventsAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.list = state.list?.mergeWith(action.payload) || state.list;
        return;
      }
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setNotificationEvents,
} = notificationEventsSlice.actions;

export {
  // Reducer Actions
  setNotificationEvents,

  // Saga Actions
  requestNotificationEvents,
  requestMarkNotificationEventsRead,
};

export const getNotificationEvents = (state: RootState) => state.notificationEvents.list;

export default notificationEventsSlice.reducer;
