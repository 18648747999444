import { forwardRef } from 'react';

import { IconProps } from 'components/Icons/createIcon';
import EyeOpen from './EyeOpen';
import EyeClosed from './EyeClosed';

const VisibilityIcon = forwardRef<SVGSVGElement, IconProps & { visible?: boolean }>(({ visible = true, ...props }, ref) => (
  visible ? <EyeOpen ref={ref} {...props} /> : <EyeClosed ref={ref} {...props} />));

export default VisibilityIcon;
