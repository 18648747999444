import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionLinks } from 'rdx/modules/auth/slice';
import { setChannels } from 'rdx/modules/chat/slice';

function* getChannels() {
  const { channels } = yield select(getSessionLinks);
  const { success, data, error } = yield makeRequest.link(channels);
  if (success && data) {
    yield put(setChannels(data.unwrap()));
  }
  if (error) {
    yield* putErrorActions({ error, target: 'messages' });
  }
  return null;
}

export default getChannels;
