import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestProjects } from 'rdx/modules/projects/slice';

function* createProject({ payload: { action, params, projectsLink, onSuccess } }) {
  const { success, error } = yield* makeRequest.action(action, params);
  if (error?.text === 'String is too long. Max of 255 characters') {
    error.text = error.text.replace('String', 'Project Name');
  }
  if (success) {
    yield put(newMessageEvent({ text: 'Project Created' }));
    yield put(requestProjects({ link: projectsLink }));
    if (onSuccess) {
      onSuccess();
    }
  } else if (error) {
    error.text = error?.text.replace('String', 'Project name');
    yield* putErrorActions({ error });
  }
  return null;
}

export default createProject;
