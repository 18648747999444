import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getFluorochromesSaga from 'rdx/modules/fluorochromes/sagas/getFluorochromes';
import { requestFluorochromes, requestSearchFluorochromes, setSearchFluorochromes } from 'rdx/modules/fluorochromes/slice';
import requestSaga from 'rdx/api-utils/requestSaga';

function* watchFluorochromesSagas() {
  yield trackRequests(takeLatest, requestFluorochromes.type, getFluorochromesSaga);
  yield trackRequests(takeLatest, requestSearchFluorochromes.type, (rdxAction) => requestSaga({ rdxAction, putData: setSearchFluorochromes }));
}

export default watchFluorochromesSagas;
