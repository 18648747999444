import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setOrganizationWorkflows, setMoreOrganizationWorkflows } from '../slice';

function* getOrganizationWorkflows({ payload }) {
  const { link, organizationID, query, onSuccess, onError } = payload;
  const { success, data, error } = yield* makeRequest.link(link, { organization_id: organizationID }, query);
  if (success && data) {
    if (data?.meta?.pagination?.page > 1) {
      yield put(setMoreOrganizationWorkflows(data));
    } else {
      yield put(setOrganizationWorkflows(data));
    }
    if (onSuccess) {
      onSuccess();
    }
  } else {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default getOrganizationWorkflows;
