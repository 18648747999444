import { keyframes } from 'styled-components';
import bdColors from './bdColors';

const fontFamilies = {
  header: 'Roboto, sans-serif',
  body: 'Roboto, sans-serif',
} as const;

const globals = {
  colors: {
    grey96: 'hsl(0, 0%, 96%)', // lightGrey
    grey93: 'hsl(0, 0%, 93%)', // mediumGrey
    grey90: 'hsl(0, 0%, 90%)', // darkGrey
    grey70: 'hsl(0, 0%, 70%)', // darkerGrey
    grey50: 'hsl(0, 0%, 50%)', // #808080
    grey40: 'hsl(0, 0%, 40%)', // #666
    grey20: 'hsl(0, 0%, 20%)', // ""black""
    textLight: '#ffffff',
    textMed: '#aeaeae',
    textDark: '#333333',
    warningLight: '#FFB032',
    warningDark: '#C97818',
    primary: '#004593',
    primaryLight: 'hsl(211, 60%, 70%)',
    primaryHover: 'hsl(211, 100%, 20%)',
    primaryDisabled: 'hsl(211, 40%, 65%)',
    secondary: bdColors.alertSuccessLight,
    orange: '#FF5D00',
    charcoal: '#1f232b',
    blue: bdColors.coreBlue,
    white: '#ffffff',
    hoverWhite: '#e0e0e0',
    lightGrey: '#f5f5f5',
    mediumGrey: '#eeeeee',
    darkGrey: '#e2e2e2',
    hoverDarkGrey: '#d2d2d2',
    darkerGrey: '#aeaeae',
    black: '#333333',
    pureBlack: '#000000',
    fontLight: '#aeaeae',
    fontMed: '#a0a0a0',
    fontDark: '#333333',
    linkGrey: '#7D7C7C',
    selectedItem: '#0090B5',
    purple: bdColors.accentPurple,
    green: bdColors.alertSuccessLight,
    red: bdColors.alertDanger,
    success: bdColors.coreBlue,
    warning: bdColors.alertWarning,
    danger: bdColors.alertDanger,
    dangerHover: bdColors.alertDangerDark,
    dangerDisabled: '#f97575',
    errorText: bdColors.alertDanger,
    errorDark: bdColors.alertDangerDark,
    matrixGradient: {
      green: '#b9cee0',
      yellow: '#93bcdd',
      orange: '#895ea0',
      red: bdColors.alertDanger,
    },
  },
  bdColors,
  layout: {
    maxContainerWidth: 'min(98vw)',
    maxInnerWidth: '1200px',
    maxDrawerInnerWidth: '1000px',
    maxSecondaryDrawerInnerWidth: '800px',
    minInnerWidth: '500px',
    navContainerPadding: ' 20px',
    navContainerPaddingWithBackArrow: ' 80px',
    mainContainerPadding: 'max(calc(50vw - 675px), 146px)',
    headerBarTopHeight: '55px',
    headerBarBottomHeight: '53px',
    footerHeight: '40px',
    drawerPadding: '60px',
    drawerMinWidth: '900px',
    drawerMaxWidth: '1200px',
    drawerWidth: '70vw',
    secondaryDrawerWidth: '65vw',
    secondaryDrawerMinWidth: '800px',
    secondaryDrawerMaxWidth: '1100px',
    buttonHeight: '40px',
    buttonWidth: '120px',
    inputPadding: '10px',
    inputWidth: '169px',
    sectionShadow: '0px 3px 4px rgba(0,0,0,0.1)',
    loginContainerWidth: '360px',
    studyReportStepWidth: '200px',
  },
  zIndices: {
    drawer: 100,
    secondaryDrawer: 200,
    modal: 500,
    portal: 900,
  },
  fontSizes: {
    tiny: '8px',
    small: '12px',
    normal: '15px',
    header: '18px',
    larger: '22px',
    body: '15px',
    h1: '24px',
    h2: '18px',
    h3: '16px',
    h4: '15px',
    h5: '15px',
    h6: '15px',
    label: '8px',
  },
  fonts: {
    flag: `600 10px ${fontFamilies.body}`,
    sub: `400 10px ${fontFamilies.body}`,
    body: `400 15px ${fontFamilies.body}`,
    bodyLabel: `400 12px ${fontFamilies.body}`,
    boldBodyLabel: `600 12px ${fontFamilies.body}`,
    label: `400 12px ${fontFamilies.header}`, // for input labels
    buttons: `400 15px ${fontFamilies.header}`, // for tabs/buttons
    navLinks: `500 14px ${fontFamilies.header}`,
    h1: `300 24px ${fontFamilies.header}`,
    h2: `400 18px ${fontFamilies.header}`,
    h3: `400 16px ${fontFamilies.header}`,
    h4: `400 13px ${fontFamilies.header}`,
    h5: `500 15px ${fontFamilies.header}`,
    h6: `500 15px ${fontFamilies.header}`,
    formTitle: `900 24px ${fontFamilies.header}`,
    boldLabel: `600 10px ${fontFamilies.body}`,
  },
  animations: {
    transitionSlow: '300ms',
    transitionFast: '150ms',
    bounceBezier: 'cubic-bezier(0.58, -0.23, 0.45, 1.36)',
    blink: keyframes`
      0% {
        opacity: 0.1;
      }

      12% {
        opacity: 0.9;
      }

      50% {
        opacity: 1;
      }

      88% {
        opacity: 0.9;
      }

      100% {
        opacity: 0.1;
      }
    `,
  },
  fontFamilies,
  distinctColors: [
    '#FFFF00', '#1CE6FF', '#FF34FF', '#FF4A46', '#008941', '#006FA6', '#A30059',
    '#FFDBE5', '#7A4900', '#0000A6', '#63FFAC', '#B79762', '#004D43', '#8FB0FF', '#997D87',
    '#5A0007', '#809693', '#FEFFE6', '#1B4400', '#4FC601', '#3B5DFF', '#4A3B53', '#FF2F80',
    '#61615A', '#BA0900', '#6B7900', '#00C2A0', '#FFAA92', '#FF90C9', '#B903AA', '#D16100',
    '#DDEFFF', '#000035', '#7B4F4B', '#A1C299', '#300018', '#0AA6D8', '#013349', '#00846F',
    '#372101', '#FFB500', '#C2FFED', '#A079BF', '#CC0744', '#C0B9B2', '#C2FF99', '#001E09',
    '#00489C', '#6F0062', '#0CBD66', '#EEC3FF', '#456D75', '#B77B68', '#7A87A1', '#788D66',
    '#885578', '#FAD09F', '#FF8A9A', '#D157A0', '#BEC459', '#456648', '#0086ED', '#886F4C',
    '#34362D', '#B4A8BD', '#00A6AA', '#452C2C', '#636375', '#A3C8C9', '#FF913F', '#938A81',
    '#575329', '#00FECF', '#B05B6F', '#8CD0FF', '#3B9700', '#04F757', '#C8A1A1', '#1E6E00',
    '#7900D7', '#A77500', '#6367A9', '#A05837', '#6B002C', '#772600', '#D790FF', '#9B9700',
    '#549E79', '#FFF69F', '#201625', '#72418F', '#BC23FF', '#99ADC0', '#3A2465', '#922329',
    '#5B4534', '#FDE8DC', '#404E55', '#0089A3', '#CB7E98', '#A4E804', '#324E72', '#6A3A4C',
    '#83AB58', '#001C1E', '#D1F7CE', '#004B28', '#C8D0F6', '#A3A489', '#806C66', '#222800',
    '#BF5650', '#E83000', '#66796D', '#DA007C', '#FF1A59', '#8ADBB4', '#1E0200', '#5B4E51',
    '#C895C5', '#320033', '#FF6832', '#66E1D3', '#CFCDAC', '#D0AC94', '#7ED379', '#012C58',
    '#7A7BFF', '#D68E01', '#353339', '#78AFA1', '#FEB2C6', '#75797C', '#837393', '#943A4D',
    '#B5F4FF', '#D2DCD5', '#9556BD', '#6A714A', '#001325', '#02525F', '#0AA3F7', '#E98176',
    '#DBD5DD', '#5EBCD1', '#3D4F44', '#7E6405', '#02684E', '#962B75', '#8D8546', '#9695C5',
    '#E773CE', '#D86A78', '#3E89BE', '#CA834E', '#518A87', '#5B113C', '#55813B', '#E704C4',
    '#00005F', '#A97399', '#4B8160', '#59738A', '#FF5DA7', '#F7C9BF', '#643127', '#513A01',
    '#6B94AA', '#51A058', '#A45B02', '#1D1702', '#E20027', '#E7AB63', '#4C6001', '#9C6966',
    '#64547B', '#97979E', '#006A66', '#391406', '#F4D749', '#0045D2', '#006C31', '#DDB6D0',
    '#7C6571', '#9FB2A4', '#00D891', '#15A08A', '#BC65E9', '#FFFFFE', '#C6DC99', '#203B3C',
    '#671190', '#6B3A64', '#F5E1FF', '#FFA0F2', '#CCAA35', '#374527', '#8BB400', '#797868',
    '#C6005A', '#3B000A', '#C86240', '#29607C', '#402334', '#7D5A44', '#CCB87C', '#B88183',
    '#AA5199', '#B5D6C3', '#A38469', '#9F94F0', '#A74571', '#B894A6', '#71BB8C', '#00B433',
    '#789EC9', '#6D80BA', '#953F00', '#5EFF03', '#E4FFFC', '#1BE177', '#BCB1E5', '#76912F',
    '#003109', '#0060CD', '#D20096', '#895563', '#29201D', '#5B3213', '#A76F42', '#89412E',
    '#1A3A2A', '#494B5A', '#A88C85', '#F4ABAA', '#A3F3AB', '#00C6C8', '#EA8B66', '#958A9F',
    '#BDC9D2', '#9FA064', '#BE4700', '#658188', '#83A485', '#453C23', '#47675D', '#3A3F00',
    '#061203', '#DFFB71', '#868E7E', '#98D058', '#6C8F7D', '#D7BFC2', '#3C3E6E', '#D83D66',
    '#2F5D9B', '#6C5E46', '#D25B88', '#5B656C', '#00B57F', '#545C46', '#866097', '#365D25',
    '#252F99', '#00CCFF', '#674E60', '#FC009C', '#92896B',
  ],
} as const;

export default globals;
