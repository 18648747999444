import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { NavBarTop, HomeLink, BDRCLogo, NavBarWrapper } from 'components/NavHeader';
import bdrcLogo from 'assets/bdrc-logo-white.svg';

import UserAgreement from 'components/InfoRequirements/UserAgreement';
import RequiredCode from 'components/InfoRequirements/RequiredCode';
import LoadingSquares from 'components/Loading/LoadingSquares/index';
import { getSignInRequirements, getSignInWarnings } from 'rdx/modules/auth/slice';
import { requestChannels } from 'rdx/modules/chat/slice';
import { useAppSelector } from 'hooks/reduxHooks';

const InformationRequirementsModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const signInRequirements = useSelector(getSignInRequirements);
  const signInWarnings = useAppSelector(getSignInWarnings)
  
  const [steps] = useState([...signInRequirements, ...signInWarnings]);

  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = steps?.length;
  const currentRequirement = steps[currentStep];

  useEffect(() => {
    if (!signInRequirements.length && !signInWarnings.length) {
      dispatch(requestChannels());
      history.push('/');
    }
  }, [dispatch, history, signInRequirements, signInWarnings]);

  return (
    <Wrapper>
      <NavBarWrapper>
        <NavBarTop isMainNav>
          <HomeLink to="/">
            <BDRCLogo src={bdrcLogo} alt="BD Research Cloud" />
          </HomeLink>
        </NavBarTop>
      </NavBarWrapper>
      <StepContainer>
        {(() => {
          if (['signup_code', 'postal_code'].includes(currentRequirement)) {
            return (
              <RequiredCode
                requirement={currentRequirement}
                incrementStep={() => setCurrentStep((prev) => prev + 1)}
                currentStep={currentStep + 1}
                totalSteps={totalSteps}
              />
            );
          }
          if (['eula_ver', 'terms_of_use_ver', 'marcom_opt_in_req_at', 'eng_lang_req_at'].includes(currentRequirement)) {
            return (
              <UserAgreement
                requirement={currentRequirement}
                incrementStep={() => setCurrentStep((prev) => prev + 1)}
                currentStep={currentStep + 1}
                totalSteps={totalSteps}
              />
            );
          }
          return <LoadingSquares />;
        })()}

      </StepContainer>
    </Wrapper>
  );
};

export default InformationRequirementsModal;

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

const StepContainer = styled.div`
  max-width: 700px;
  margin: 50px auto 0 auto;
`;
