import { combineReducers } from 'redux';
import activeRequestsReducer from 'rdx/modules/activeRequests/slice';
import appReducer from 'rdx/modules/app/slice';
import cytometersReducer from 'rdx/modules/cytometers/slice';
import authReducer from 'rdx/modules/auth/slice';
import barcodeScansReducer from 'rdx/modules/barcodeScans/slice';
import messageReducers from 'rdx/modules/messages/slice';
import usersReducer from 'rdx/modules/users/slice';
import proceduresReducer from 'rdx/modules/procedures/slice';
import stepsReducer from 'rdx/modules/steps/slice';
import organizationsReducer from 'rdx/modules/organization/slice';
import templatesReducers from 'rdx/modules/templates/slice';
import projectsReducer from 'rdx/modules/projects/slice';
import trialsReducers from 'rdx/modules/trials/slice';
import studiesReducers from 'rdx/modules/studies/slice';
import chatReducers from 'rdx/modules/chat/slice';
import navReducer from 'rdx/modules/nav/slice';
import fcsDataReducer from 'rdx/modules/fcsData/slice';
import tutorialReducer from 'rdx/modules/tutorials/slice';
import labsReducer from 'rdx/modules/labs/slice';
import licensesReducers from 'rdx/modules/licenses/slice';
import formReducer from 'rdx/modules/form/slice';
import gatedFeatureBlurbsReducer from 'rdx/modules/gatedFeatureBlurbs/slice';
import groupsReducer from 'rdx/modules/groups/slice';
import reagentsReducer from 'rdx/modules/reagents/slice';
import panelReducer from './modules/panels/slice';
import panelSharingsReducer from './modules/panelSharings/slice';
import fileExplorerReducer from './modules/fileExplorer/slice';
import fluorochromesReducer from './modules/fluorochromes/slice';
import experimentsReducer from './modules/experiments/slice';
import fileUploadsReducer from './modules/fileUploads/slice';
import flowGptQueriesReducer from './modules/flowGptQueries/slice';
import linkedResourcesReducer from './modules/linkedResources/slice';
import spectrumViewerReducer from './modules/spectrumViewer/slice';
import systemReducer from './modules/system/slice';
import panelDetailsReducer from './modules/panelDetails/slice';
import modalsReducer from './modules/modals/slice';
import tooltipReducer from './modules/tooltip/slice';
import drawersReducer from './modules/drawers/slice';
import websocketReducer from './modules/websocket/slice';
import wishlistsReducer from './modules/wishlists/slice';
import wspDataReducer from './modules/wspData/slice';
import workflowReducer from './modules/workflow/slice';
import systemDocsReducer from './modules/systemDocs/slice';
import chorusExperimentsReducer from './modules/chorusExperiments/slice';
import notificationEventsReducer from './modules/notificationEvents/slice';

export default () => combineReducers({
  activeRequests: activeRequestsReducer,
  app: appReducer,
  auth: authReducer,
  barcodeScans: barcodeScansReducer,
  chat: chatReducers,
  chorusExperiments: chorusExperimentsReducer,
  cytometers: cytometersReducer,
  drawers: drawersReducer,
  experiments: experimentsReducer,
  fcsData: fcsDataReducer,
  fileExplorer: fileExplorerReducer,
  fileUploads: fileUploadsReducer,
  flowGptQueries: flowGptQueriesReducer,
  fluorochromes: fluorochromesReducer,
  form: formReducer,
  gatedFeatureBlurbs: gatedFeatureBlurbsReducer,
  groups: groupsReducer,
  labs: labsReducer,
  licenses: licensesReducers,
  linkedResources: linkedResourcesReducer,
  messages: messageReducers,
  modals: modalsReducer,
  nav: navReducer,
  notificationEvents: notificationEventsReducer,
  organization: organizationsReducer,
  panels: panelReducer,
  panelDetails: panelDetailsReducer,
  panelSharings: panelSharingsReducer,
  procedures: proceduresReducer,
  projects: projectsReducer,
  projectStudies: studiesReducers,
  reagents: reagentsReducer,
  spectrumViewer: spectrumViewerReducer,
  steps: stepsReducer,
  system: systemReducer,
  systemDocs: systemDocsReducer,
  templates: templatesReducers,
  tooltip: tooltipReducer,
  trials: trialsReducers,
  tutorial: tutorialReducer,
  users: usersReducer,
  websocket: websocketReducer,
  wishlists: wishlistsReducer,
  wspData: wspDataReducer,
  workflow: workflowReducer,
});
