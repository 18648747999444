import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { setChatOrgUsers, setOrgUsers, setPendingOrgUsers, setUnconfirmedOrgUsers } from 'rdx/modules/users/slice';

function* getOrgUsers({ payload }) {
  const action = yield select(getSessionAction('organization_users'));
  const { query, params = {}, onSuccess, organization_id, is_active, confirmed, otherOrg } = payload;
  let input = {};
  if (query) {
    input = { ...query, ...params };
  }
  if (organization_id) {
    input.organization_id = organization_id;
  }
  if (is_active) {
    input.is_active = is_active;
  }
  if (confirmed) {
    input.confirmed = confirmed;
  }
  const { success, data, error } = yield* makeRequest.action(action, input);
  if (success && data) {
    onSuccess?.();
    if (otherOrg) {
      yield put(setChatOrgUsers(data));
    } else if (input.confirmed === '0') {
      yield put(setUnconfirmedOrgUsers(data));
    } else if (input.is_active === '0') {
      yield put(setPendingOrgUsers(data));
    } else {
      yield put(setOrgUsers(data));
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getOrgUsers;
