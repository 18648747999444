import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* setDefaultProfile({ payload: { action, profileId, onSuccess } }) {
  const { success, error } = yield* makeRequest.action(action, { cytometerProfileID: profileId });
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
    yield put(newMessageEvent({ text: 'Default Profile Updated' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default setDefaultProfile;
