const bdColors = {
  // Blue colors
  coreBlueDark: '#023970',
  coreBlue: '#004593',
  coreBlueLight: '#315DAF',
  coreBlueLightest: '#96B4FF',

  // Gray colors
  grayDarkest: '#1A1A1A',
  grayDarker: '#333333',
  grayDark: '#808080',
  gray: '#B3B3B3',
  grayLight: '#CCCCCC',
  grayLightest: '#E5E5E5',

  // Alert colors
  alertPrimaryDark: '#007FD1',
  alertPrimary: '#009BFF',
  alertPrimaryLight: '#14ABFF',
  alertSuccessDark: '#367200',
  alertSuccess: '#4CA000',
  alertSuccessLight: '#39B54A',
  alertWarningDark: '#C97818',
  alertWarning: '#F7931E',
  alertWarningLight: '#FFB032',
  alertDangerDark: '#A30013',
  alertDanger: '#D10018',
  alertDangerLight: '#FF0000',

  // Background colors
  midnight: '#132438',
  black: '#000000',
  white: '#FFFFFF',
  offWhite: '#F7F7F7',

  // Accent colors
  accentPink: '#E64398',
  accentPurple: '#8D2A90',
  accentTeal: '#00B8B0',
  accentCyan: '#00BCE8',
  accentGreen: '#81C341',
  accentYellow: '#FFE000',
} as const;

export default bdColors;
