import { put, select } from 'redux-saga/effects';
import { find } from 'lodash';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { getSessionActions } from 'rdx/modules/auth/slice';
import { setMorePendingGroups } from '../slice';

function* getMorePendingGroups({ payload }) {
  const { page } = payload;
  const sessionActions = yield select(getSessionActions);
  const action = find(sessionActions, { name: 'groups' });
  const organization = yield select(getCurrentOrganization);

  const { success, data, error } = yield* makeRequest.action(
    action,
    { organization_id: organization.id, confirmed: 0, page },
  );
  if (success && data) {
    yield put(setMorePendingGroups(data));
  } else if (error) {
    yield* putErrorActions({ error });
  }
}

export default getMorePendingGroups;
