import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from 'hooks/useModal';
import PermissionsRequiredModal from 'containers/Modals/PermissionsRequiredModal';
import { getGroupScopes, getIsGroupOwner } from 'rdx/modules/groups/slice';
import { getOrganizationScopes, getIsOrgOwner } from 'rdx/modules/organization/slice';

export const PERMISSION_TYPES = {
  ORG: 'organization',
  GROUP: 'group',
};

const defaults = {
  onClick: () => console.warn('withPermissions HOC is wrapping a component with no onClick handler'),
  permissions: [],
  permissionType: PERMISSION_TYPES.ORG,
};

/**
 * Wraps any component and intercepts its onClick handler to replaces its click
 * handler based on user scopes. Wrapped components should be given
 * opens a modal to request permissions or contact others with permission
 * @param ChildComponent  Any valid react component
 * @returns `GatedChildComponent` -- Child Component with locked and onClick
 * params based on existence of appropriate user scopes. This component should be provided
 * `permissions` and optional `permissionType` props. For valid permissionTypes
 * `import { PERMISSION_TYPES } from 'hocs/withPermissions';`
 */

// eslint-disable-next-line react/display-name
const withPermissions = (ChildComponent) => ({
  onClick = defaults.onClick,
  permissions = defaults.permissions,
  permissionType = defaults.permissionType,
  groupID = '',
  manual = false,
  ...props
}) => {
  const orgScopes = useSelector(getOrganizationScopes);
  const isOrgOwner = useSelector(getIsOrgOwner);
  const groupScopes = useSelector(getGroupScopes);
  const isGroupOwner = useSelector(getIsGroupOwner);
  const { callModal } = useModal();

  const { locked, permissionClick } = useMemo(() => {
    if (manual) {
      return {
        locked: !manual,
        permissionClick: onClick,
      };
    }
    let scopeType = Object.values(PERMISSION_TYPES).find((l) => l === permissionType);
    const requiredScopes = Array.isArray(permissions) ? [...permissions] : [permissions];
    let userScopes = [];
    let missingPermissions = [...requiredScopes];
    switch (scopeType) {
      case PERMISSION_TYPES.GROUP:
        if (isGroupOwner) {
          return {
            locked: false,
            permissionClick: onClick,
          };
        }
        userScopes = Array.isArray(groupScopes) ? groupScopes : [];
        missingPermissions = requiredScopes.reduce((missing, scope) => {
          if (userScopes.includes(scope)) { return missing; }
          return [...missing, scope];
        }, []);
        break;
      case PERMISSION_TYPES.ORG:
        if (isOrgOwner) {
          return {
            locked: false,
            permissionClick: onClick,
          };
        }
        userScopes = Array.isArray(orgScopes) ? orgScopes : [];
        missingPermissions = requiredScopes.reduce((missing, scope) => {
          if (userScopes.includes(scope)) { return missing; }
          return [...missing, scope];
        }, []);
        break;
      default:
        console.warn(`withPermissions HOC was provided with invalid permissionType: ${permissionType}\nValid options are: ${Object.values(PERMISSION_TYPES).join(' | ')}\ndefaulting to ${PERMISSION_TYPES.ORG}`);
        scopeType = PERMISSION_TYPES.ORG;
        userScopes = Array.isArray(orgScopes) ? orgScopes : [];
        missingPermissions = requiredScopes.reduce((missing, scope) => {
          if (userScopes.includes(scope)) { return missing; }
          return [...missing, scope];
        }, []);
        break;
    }

    const isMissingScopes = !!missingPermissions.length;

    const openPermissionsModal = (e) => {
      if (e?.preventDefault) {
        e.preventDefault();
      }
      if (e?.stopPropagation) {
        e.stopPropagation();
      }
      callModal(
        <PermissionsRequiredModal
          missingPermissions={missingPermissions}
          permissionType={scopeType}
          key={`${props.id || 'default'}-${missingPermissions.join('-')}-${scopeType}-permissions-required-modal`}
          groupID={groupID}
        />
      );
    };

    return {
      locked: isMissingScopes,
      permissionClick: isMissingScopes ? openPermissionsModal : onClick,
    };
  }, [
    callModal,
    groupID,
    groupScopes,
    isGroupOwner,
    isOrgOwner,
    onClick,
    orgScopes,
    permissionType,
    permissions,
    props.id,
    manual,
  ]);

  return (
    <ChildComponent
      locked={locked}
      onClick={permissionClick}
      {...props}
    />
  );
};

/**
 * Any React component
 * @typedef {Object} Component
 * @property {object} props
 */

/**
 * A React component wrapped by `withPermissions`. This component should be provided
 * permissions and optional permissionType props. For valid permissionTypes
 * `import { PERMISSION_TYPES } from 'hocs/withPermissions';`
 * @typedef {Object} PermissionGatedComponent
 * @property {boolean} locked
 * @property {object} onClick
 * @property {string|string[]} permissions
 * @property {string} permissionType
 * @property {object} props
 */

export default withPermissions;
