import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestProjects } from 'rdx/modules/projects/slice';

function* deleteStudy({ payload: { action, params, projectsLink } }) {
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    yield put(newMessageEvent({ text: 'Study Deleted' }));
    yield put(requestProjects({ link: projectsLink }));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteStudy;
