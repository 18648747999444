import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getCurrentGroup } from 'rdx/modules/groups/slice';
import { postProcedure } from 'rdx/modules/procedures/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { CreateWorkflowParams, getWorkflowsRaw } from '../slice';
import { newMessageEvent } from '../../messages/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import ResourceList from 'lib/jsonApi/ResourceList';
import Resource from 'lib/jsonApi/Resource';

function* createCustomWorkflowSaga({ payload }: PayloadActionWithGenericPayload<CreateWorkflowParams>) {
  const { onSuccess, params } = payload;
  const workflows: ResourceList = yield select(getWorkflowsRaw);
  const action = workflows.getAction('create');

  const currentGroup: Resource = yield select(getCurrentGroup);
  const newParams = {...params};
  newParams.group_id = params?.group_id ?? currentGroup.id;
  const groupId = params?.group_id;
  const hasGPS = params?.enable_gps;

  const { success, data, error } = yield makeRequest.action(action, newParams);
  if (success && data) {
    // TODO (Scott): We need a better way of creating a procedure on workflow create
    const currentOrg: Resource = yield select(getCurrentOrganization);
    if (!hasGPS) {
      const procedure = { name: 'New Procedure' };
      const procedureAction = data.getAction('create_procedure');

      yield put(postProcedure({
        action: procedureAction,
        values: procedure,
        no_flag: true,
        onSuccess: () => {
          if (currentOrg.attributes.slug && groupId && data.data.id) {
            onSuccess?.({ routeTo: `/${currentOrg.attributes.slug}/groups/${groupId}/workflows/${data.data.id}/details` });
          }
        },
      }));
    } else {
      if (currentOrg.attributes.slug && groupId && data.data.id) {
        onSuccess?.({ routeTo: `/${currentOrg.attributes.slug}/groups/${groupId}/workflows/${data.data.id}/details` });
      }
    }

    yield put(newMessageEvent({ text: 'Workflow Created' }));
  } else {
    const persist = error.text.includes('maximum number of active workflows');
    yield* putErrorActions({ error, persist });
  }
  return null;
}

export default createCustomWorkflowSaga;
