import { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useAppSelector } from "hooks/reduxHooks";
import { getSystemSettings } from "rdx/modules/auth/slice";
import { getUser } from 'rdx/modules/users/slice';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';

const useInitializeMixpanel = () => {
  const systemSettings = useAppSelector(getSystemSettings);
  const currentOrgSlug = useAppSelector(getCurrentOrgSlug);
  const user = useAppSelector(getUser);
  const [mixpanelLoaded, setMixpanelLoaded] = useState(false);

  useEffect(() => {
    if (!systemSettings?.mixpanel_token || !window.ONETRUST_PERFORMANCE) {
      return;
    }
    if (systemSettings.mixpanel_token) {
      mixpanel.init(systemSettings.mixpanel_token, {
        debug: false,
        track_pageview: false,
        loaded: () => {
          setMixpanelLoaded(true);
        },
      });
    }
  }, [systemSettings?.mixpanel_token]);

  useEffect(() => {
    if (!mixpanelLoaded) {
      return;
    }
    if (user?.attributes?.email && currentOrgSlug) {
      mixpanel.identify(`${user?.attributes?.email}-${currentOrgSlug}`);
    }
  }, [currentOrgSlug, mixpanelLoaded, user?.attributes?.email])

  return mixpanelLoaded ? mixpanel : null;
}

export { useInitializeMixpanel };