import React, { useState, useEffect, useMemo } from 'react';
import { jwtDecode } from 'lib/utils/jwtDecode';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { useCountdown } from 'hooks/useCountdown';
import ActionForm from 'components/ActionForm';
import {
  Header,
  Subheader,
  LinkText,
  ConfirmationText,
  ConfirmationTextContainer,
} from 'containers/Auth/components';
import ConfirmExistingForm from 'containers/Auth/ConfirmInvite/ConfirmExistingForm';
import { countries } from 'countries-list';
import { confirmInvite, getInviteStatus, getSession, requestInviteStatus, setInviteStatus } from 'rdx/modules/auth/slice';
import { useMixpanel } from 'hooks/useMixpanel';

const confirmPasswordField = {
  name: 'password_confirm',
  type: 'password',
  value: '',
  required: true,
};

const ConfirmInvite = () => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const location = useLocation();
  const session = useSelector(getSession);
  const inviteStatus = useSelector(getInviteStatus);
  const action = React.useMemo(() => session?.getAction?.('invite_confirm')?.withField(confirmPasswordField) ?? null, [session]);
  const [submitted, setSubmitted] = useState(false);
  const countdown = useCountdown(5);

  const token = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('token') ?? '';
  }, [location.search]);

  useEffect(() => {
    if (token) {
      dispatch(requestInviteStatus({ token }));
    }
    return () => {
      dispatch(setInviteStatus(true));
    };
  }, [dispatch, token]);

  const onSubmitSuccess = () => {
    mixpanel?.track('Completed Sign Up', { email: jwtDecode(token).email })
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && !countdown.active) {
      countdown.start();
    }
  }, [submitted]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!token || countdown.value === 0 || !inviteStatus) {
    return <Redirect to="/login" />;
  }

  // Update confirm invite action field name for display purposes.
  const countryCodeField = action?.fields.find(({ name }) => name === 'country_code');
  if (countryCodeField) {
    countryCodeField.name = 'country';
  }
  const postalCodeField = action?.fields.find(({ name }) => name === 'postal_code');
  if (postalCodeField) {
    postalCodeField.name = 'work_postal_code';
  }

  const countryOptions = Object.entries(countries).map(([countryCode, { name }]) => ({
    value: countryCode,
    display: name,
  }));
  const sortedCountries = countryOptions.sort((a, b) => a.display.localeCompare(b.display)).sort((a, b) => {
    if (a.value === 'US') {
      return -1;
    } if (b.value === 'US') {
      return 1;
    } if (a > b) {
      return -1;
    } return 1;
  });

  if (!action) {
    return null;
  }

  return (
    <>
      <HideMe show={!submitted}>
        <FormsContainer>
          <Column>
            <SHeader>
              Create an account
            </SHeader>
            <SSubheader>
              Create a new account for {jwtDecode(token).email}
            </SSubheader>
            <ActionForm
              wrap
              action={action}
              onSubmit={(values) => {
                const { password, first_name, last_name, work_postal_code, country } = values;
                if (typeof password === 'string' && typeof first_name === 'string' && typeof last_name === 'string' && typeof work_postal_code === 'string' && typeof country === 'string') {
                  dispatch(confirmInvite({
                    params: {
                      token,
                      password,
                      first_name,
                      last_name,
                      postal_code: work_postal_code,
                      country_code: country,
                    },
                    onSuccess: onSubmitSuccess,
                  }));
                }
              }}
              isInvite
              options={{
                first_name: { style: { flex: '1', marginRight: '20px' } },
                last_name: { style: { flex: '1' } },
                country: {
                  initialValue: 'US',
                  inputProps: { filter: true },
                  options: sortedCountries,
                  style: {
                    zIndex: '999999',
                    flex: '100%',
                  },
                },
                work_postal_code: { style: { flex: '100%' } },
                password: {
                  style: { flex: '1', marginRight: '20px' },
                  inputProps: { placeholder: 'Enter password' },
                },
                password_confirm: {
                  initialValue: '',
                  validate: (value, { fields }) => (value !== fields.password.value ? "Passwords don't match" : null),
                  style: { flex: '1' },
                  inputProps: { placeholder: 'Confirm password' },
                },
              }}
            />
          </Column>
          <Divider />
          <Column>
            <SHeader>
              Log in with existing account
            </SHeader>
            <SSubheader>
              If you have already created an account, you can log in instead of creating a new one.
            </SSubheader>
            <ConfirmExistingForm
              onSubmitSuccess={onSubmitSuccess}
              token={token}
            />
          </Column>
        </FormsContainer>
      </HideMe>
      <ConfirmationTextContainer show={submitted}>
        <Header>
          Success!
        </Header>
        <ConfirmationText>
          Your password has been updated. You will be redirected to the login page in {countdown.value} seconds, or <LinkText to="/login">click here</LinkText> to go back now.
        </ConfirmationText>
      </ConfirmationTextContainer>
    </>
  );
};

export default ConfirmInvite;

const SHeader = styled(Header)`
  padding-top: 20px;
  white-space: nowrap;
`;

const SSubheader = styled(Subheader)`
  margin: 15px 0 25px 0;
`;

const Column = styled.div`
  width: 350px;
`;

const Divider = styled.div`
  border: 1px solid lightgrey;
  height: 475px;
  margin: auto;
`;

const HideMe = styled.div<{ show?: boolean }>`
  height: 100%;
  width: 100%;
  ${({ show }) => !show && css`
    display: none;
  `}
`;

const FormsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 800px;
`;
