import React from 'react';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { useModal } from 'hooks/useModal';
import globals from 'styles/globals';
import { TechSupportIcon } from 'components/Icons';
import TechSupportModal from 'containers/Modals/TechSupportModal';

const TechSupport = ({ login = false }) => {
  const { callModal } = useModal();

  const openTechSupportModal = () => {
    callModal(<TechSupportModal key={nanoid()} />);
  };

  if (login) {
    return (
      <SupportLink>
        <TechSupportLink onClick={openTechSupportModal}>Technical Support</TechSupportLink>
      </SupportLink>
    );
  }

  return (
    <SupportButton>
      <TechSupportIcon onClick={openTechSupportModal} />
    </SupportButton>
  );
};

export default TechSupport;

const { fontSizes, layout } = globals;

const TechSupportLink = styled.div`
  font-size: ${fontSizes.small};
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`;

const SupportLink = styled.div`
`;

const SupportButton = styled.div`
  position: fixed;
  bottom: -4px;
  left: ${layout.mainContainerPadding};
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 8pt;
  text-transform: uppercase;
  cursor: pointer;
  transform-origin: 50% 50%;
  transform: scale(1.0);
  transition-timing-function: ease-out;
  transition: transform 200ms;
  user-select: none;
  margin-left: 55px;
  height: 52px;
  &:hover {
    font-weight: bold;
  }
`;
