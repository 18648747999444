import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setTermsOfUse } from 'rdx/modules/systemDocs/slice';
import { getSessionLinks } from 'rdx/modules/auth/slice';

function* getTerms() {
  const sessionLinks = yield select(getSessionLinks);
  const link = sessionLinks.terms_of_use;

  const { success, data, error } = yield* makeRequest.link(link);
  if (success && data) {
    yield put(setTermsOfUse(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getTerms;
