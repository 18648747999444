import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getWebsocketId } from 'rdx/modules/websocket/slice';
import downloadUrl from 'lib/utils/downloadUrl';

function* downloadPanelSummary({ payload: { action, message } }) {
  const websocketId = yield select(getWebsocketId);
  const params = {};
  if (websocketId) {
    params.socket_id = websocketId;
  }
  const { success, data, error } = yield* makeRequest.action(action, params);
  const presignedUrl = data?.data?.attributes?.presigned_url;
  if (success && presignedUrl) {
    downloadUrl(presignedUrl);
    yield put(newMessageEvent({ text: 'Download ready' }));
  } else if (success) {
    yield put(newMessageEvent({ text: message || 'Preparing panel summary' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default downloadPanelSummary;
