import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import globals from 'styles/globals';
import { ArrowLeftLongIcon } from 'components/Icons';
import { usePrevious } from 'hooks/usePrevious/index';

const MessageInput = ({ sendMessage, currentChannel, programmaticMessage }) => {
  const prevProgMes = usePrevious(programmaticMessage);
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage(message);
    setMessage('');
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (programmaticMessage && prevProgMes !== programmaticMessage) {
      setMessage(programmaticMessage);
    }
  }, [prevProgMes, programmaticMessage]);

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={!currentChannel}
        onKeyDown={(e) => onEnterPress(e)}
        data-testid="chatInput"
      />
      <SendButton
        type="submit"
        disabled={!message}
        {...{ currentChannel }}
      >
        <SendIcon>
          <ArrowLeftLongIcon
            color={globals.colors.white}
            size={14}
          />
        </SendIcon>
      </SendButton>
    </Form>
  );
};

const Input = styled.textarea`
  width: 320px;
  &:focus{
    outline: none;
  }
  border: none;
  resize: none;
`;
const SendIcon = styled.div`
  transform: rotate(180deg);
  padding: 5px;
  display: flex;
  justify-content: center;
`;
const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border:2px solid #ccc;
  border-radius: 5px;
  margin: 10px 10px 14px 10px;
`;
const SendButton = styled.button`
  color: #ffffff;
  font: 400 8pt Roboto,sans-serif;
  border-radius: 5px;
  letter-spacing: 0.4pt;
  white-space: nowrap;
  background: ${globals.colors.primary};
  border: none;
  margin: 5px;
  padding: 5px 5px;
  cursor: ${({ currentChannel }) => (currentChannel ? 'pointer' : 'default')};
  outline: inherit;
  background-color: ${({ currentChannel }) => (currentChannel ? globals.colors.primary : globals.colors.fontLight)};
  &:hover {
    background-color: ${({ currentChannel }) => (currentChannel ? globals.colors.primaryHover : globals.colors.fontLight)};
  }
  &:active {
    filter: brightness(0.9);
  }
  transition: all 200ms ease;
`;

export default MessageInput;
