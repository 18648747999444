import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* shareFile(rdxAction) {
  const { action, params, onSuccess, isEdit } = rdxAction.payload;
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    onSuccess?.();
    yield put(newMessageEvent({ text: isEdit ? 'Shared file expiration updated' : 'File Shared' }));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default shareFile;
