import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* unlinkCytometer({ payload }) {
  const { action, onSuccess } = payload;
  // returns 204, no data
  const { success, error } = yield* makeRequest.action(action);
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
    yield put(newMessageEvent({ text: 'Cytometer Unlinked' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default unlinkCytometer;
