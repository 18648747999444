import React, { useMemo, useRef, useEffect, useCallback, Suspense } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { useModal } from 'hooks/useModal';
import { useSocketMessages } from 'hooks/useSocketMessages';

import UnusedLicensesModal from 'containers/Modals/UnusedLicensesModal';
import UnmatchedPostalCodeModal from 'containers/Modals/UnmatchedPostalCodeModal';
import Loading from 'components/Loading';
import BreadCrumb from 'components/BreadCrumb';
import UtilityBar from 'components/UtilityBar';
import downloadUrl from 'lib/utils/downloadUrl';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import useFullScreenWidth from 'hooks/useFullScreenWidth/index';
import { getWebsocketId } from 'rdx/modules/websocket/slice';
import { requestOrganizationProfile, getCurrentOrganization, getCurrentOrganizationBetaTesting, getIsOrgOwner, getIsOrgAdmin } from 'rdx/modules/organization/slice';
import { getSessionAction, getSessionActions, getSessionLinks, getSignInRequirements, getUnusedLicensesModalSeen, requestLogout, requestSession, setUnusedLicensesModalSeen, getSession, getSignInWarnings } from 'rdx/modules/auth/slice';
import { getUser } from 'rdx/modules/users/slice';
import { MessageEvent, newMessageEvent } from 'rdx/modules/messages/slice';
import { getIsMobile } from 'rdx/modules/app/slice';
import MainNavBar from './MainNavBar';
import { Routes, RouteSessionMap } from './constants';
import globals from '../../styles/globals';
import { useMixpanel } from 'hooks/useMixpanel';
import { getFluorochromes, requestFluorochromes, setFluorochromes } from 'rdx/modules/fluorochromes/slice';
import { UnusedLicensesAttributes } from 'types/licenses';
import { FluorochromeAttributes } from 'types/fluorochromes';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { Tab } from 'components/Tabs';

type SocketMessageResponse = {
  data?: {
    type?: string,
    attributes?: {
      presigned_url?: string,
      org_slug?: string,
      save_file?: string,
      project_id?: string,
      file_id?: number,
      socket_id?: string
    }
  }
}

const { layout, colors } = globals;

const Cytometers = React.lazy(() => import('containers/Cytometers'));
const CytometerRouter = React.lazy(() => import('containers/Cytometers/CytometerRouter'));
const Workflows = React.lazy(() => import('containers/Workflows'));
const ExperimentRouter = React.lazy(() => import('containers/Experiments/ExperimentRouter'));
const Experiments = React.lazy(() => import('containers/Experiments'));
const FileExplorer = React.lazy(() => import('containers/FileExplorer'));
const FilePage = React.lazy(() => import('containers/FileExplorer/FilePage'));
const Groups = React.lazy(() => import('containers/Groups'));
const Panels = React.lazy(() => import('containers/Panels'));
const PanelRouter = React.lazy(() => import('containers/Panels/PanelRouter'));
const Populations = React.lazy(() => import('containers/Populations'));
const Reagents = React.lazy(() => import('containers/Reagents'));
const StudyReport = React.lazy(() => import('containers/ProjectsAndStudies/StudyReport'));
const Users = React.lazy(() => import('containers/Users'));
const WorkflowRouter = React.lazy(() => import('containers/WorkflowRouter'));
const FcsViewer = React.lazy(() => import('components/FcsViewer'));
const Home = React.lazy(() => import('containers/Home'));
const ChorusExperiments = React.lazy(() => import('containers/ChorusExperiments'));

const Main = () => {
  const { callModal } = useModal();
  const mixpanel = useMixpanel();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const user = useAppSelector(getUser);
  const currentOrganization = useAppSelector(getCurrentOrganization);
  const isOrgOwner = useAppSelector(getIsOrgOwner);
  const isOrgAdmin = useAppSelector(getIsOrgAdmin);
  const betaTestingEnabled = useAppSelector(getCurrentOrganizationBetaTesting);
  const sessionLinks = useAppSelector(getSessionLinks);
  const sessionActions = useAppSelector(getSessionActions);
  const websocketId = useAppSelector(getWebsocketId);
  const unusedLicensesModalSeen = useAppSelector(getUnusedLicensesModalSeen);
  const orgProfileAction = useAppSelector(getSessionAction('organization_profile'));
  const signInRequirements = useAppSelector(getSignInRequirements);
  const signInWarnings = useAppSelector(getSignInWarnings);
  const session = useAppSelector(getSession);
  const appSetToMobile = useAppSelector(getIsMobile);
  const fluorochromes = useAppSelector(getFluorochromes).data as Resource<FluorochromeAttributes>[]

  const fullScreen = useFullScreenWidth();

  const orgSlug = currentOrganization?.attributes?.slug;

  useEffect(() => {
    mixpanel?.people?.set({
      "Permissions": isOrgOwner ? "All" : currentOrganization?.attributes?.current_user_scope?.join(', '),
      "Org Owner": isOrgOwner,
      "Org Admin": isOrgAdmin,
      "License Types": currentOrganization?.attributes?.org_license_types?.join(', '),
      $email: user?.attributes.email,
      $first_name: user?.attributes?.first_name, 
      $last_name: user?.attributes?.last_name,
    });
  }, [currentOrganization?.attributes?.current_user_scope, currentOrganization?.attributes?.org_license_types, isOrgAdmin, isOrgOwner, mixpanel?.people, user?.attributes.email, user?.attributes?.first_name, user?.attributes?.last_name]);

  const getWebsocketMatch = useCallback((response?: SocketMessageResponse) => websocketId && websocketId === response?.data?.attributes?.socket_id, [websocketId]);

  const downloadPresignedUrl = useCallback((presignedUrl?: string, message?: string) => {
    downloadUrl(presignedUrl);
    dispatch(newMessageEvent({ text: message ?? 'Download ready.' }));
  }, [dispatch]);


  const handleSocketMessage = useCallback((response?: SocketMessageResponse) => {
    const isMatch = getWebsocketMatch(response);

    switch (response?.data?.type) {
      case 'cyto_snapshot_config_pdf_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url, 'Cytometer Configuration export ready.');
        }
        break;
      case 'experiment_diva_messages': {
        const responseAttrs = response?.data?.attributes;
        if (isMatch) {
          if (responseAttrs?.org_slug === orgSlug && responseAttrs?.save_file && responseAttrs?.project_id) {
            dispatch(newMessageEvent({
              text: 'Zip file saved to project files',
              link: {
                href: `/${responseAttrs.org_slug}/file-explorer?projectID=${responseAttrs.project_id}&direct=true&path=Experiments`,
                text: 'View in File Explorer',
              },
              persist: true,
            }))
          }
          if (!responseAttrs?.save_file && responseAttrs?.presigned_url) {
            downloadPresignedUrl(responseAttrs.presigned_url, 'Experiment export ready.');
          }
        }
        break;
      }
      case 'experiment_pdf_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url, 'Experiment PDF ready.');
        }
        break;
      case 'files_moved_messages':
        dispatch(newMessageEvent({ text: 'Files successfully moved.' }));
        break;
      case 'files_zipped_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url);
        }
        break;
      case 'panel_shopping_list_csv_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url, 'Shopping list CSV ready.');
        }
        break;
      case 'panel_shopping_list_pdf_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url, 'Shopping list PDF ready.');
        }
        break;
      case 'wsp_built_messages':
        dispatch(newMessageEvent({ text: 'Workspace file successfully generated.' }));
        break;
      case 'titration_built_messages': {
        let link = '';
        const fileId = response?.data?.attributes?.file_id;
        if (fileId) {
          link = `file-explorer/${fileId}`;
        }
        const params: MessageEvent = {
          text: 'Titration file successfully generated.',
          persist: true,
        };
        if (link) {
          params.link = {
            href: link,
            text: 'View Image',
            state: { viewImage: true },
          };
        }
        dispatch(newMessageEvent(params));
        break;
      }
      case 'panel_summary_pdf_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url, 'Panel Summary PDF ready.');
        }
        break;
      case 'panel_summary_csv_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url, 'Panel Summary CSV ready.');
        }
        break;
      case 'panel_staining_sheet_pdf_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url, 'Panel Staining Sheet PDF ready.');
        }
        break;
      case 'panel_staining_sheet_csv_messages':
        if (isMatch) {
          downloadPresignedUrl(response?.data?.attributes?.presigned_url, 'Panel Staining Sheet CSV ready.');
        }
        break;
      default:
        break;
    }
  }, [dispatch, downloadPresignedUrl, getWebsocketMatch, orgSlug]);

  useSocketMessages(handleSocketMessage);

  const urlSlug = useMemo(() => location.pathname.split('/')[1], [location.pathname]);
  useEffect(() => {
    if (urlSlug && orgProfileAction) {
      dispatch(requestOrganizationProfile({
        organizationID: urlSlug,
        onError: (data) => {
          if (data && 'currentOrgSlug' in data && data.currentOrgSlug) {
            history.push(`/${data.currentOrgSlug}`);
          } else {
            dispatch(requestLogout({ onSuccess: () => {
              mixpanel?.track('Logout')
              mixpanel?.reset();
              history.push('/')
            } }));
          }
        },
      }));
    }
  }, [dispatch, history, mixpanel, orgProfileAction, urlSlug]);

  useEffect(() => {
    if (currentOrganization) {
      const unusedLicenses = currentOrganization.getRel<UnusedLicensesAttributes>('unused_licenses');
      const attachAction = currentOrganization.getAction('attach_license');
      const showModal = Array.isArray(unusedLicenses) && !!unusedLicenses.length && !!attachAction
      if (!unusedLicensesModalSeen && showModal) {
        dispatch(setUnusedLicensesModalSeen(true));
        callModal(<UnusedLicensesModal key={uuid()} />);
      }
    }
  }, [callModal, currentOrganization, dispatch, unusedLicensesModalSeen]);

  useEffect(() => {
    if (session?.attributes?.is_invalid_postal_code) {
      callModal(<UnmatchedPostalCodeModal key={uuid()} />);
    }
  }, [callModal, dispatch, session]);

  useEffect(() => {
    if (!fluorochromes.length) {
      dispatch(requestFluorochromes({
        link: sessionLinks?.fluorochromes,
        query: {
          pageSize: 200,
        },
        onSuccess: (res) => {
          setFluorochromes(res as ResourceList<FluorochromeAttributes>)
        },
      }));
    }
  }, [fluorochromes, dispatch, sessionLinks?.fluorochromes])

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const handleScrollEvent = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    // send drawer scroll event
    const eventOptions = { bubbles: true };
    const event = new Event('bdrc-main-container-scroll', eventOptions);
    e.target.dispatchEvent(event);
  };

  const sessionRoutes = useMemo(() => {
    const keys: Record<string, string> = {};
    sessionActions?.forEach((action) => { keys[action.name] = action.name; });
    if (sessionLinks) {
      Object.keys(sessionLinks).forEach((link) => { keys[link] = link; });
    }
    if (!orgSlug) { return []; }
    const entries = Object.entries(Routes);
    return entries.reduce<typeof Routes[keyof typeof Routes][]>((routes, entry) => {
      const key = entry[0] as keyof typeof Routes
      const value = entry[1];
      if (RouteSessionMap[key] === '*' || keys[RouteSessionMap[key]]) {
        return [...routes, value];
      }
      return routes;
    }, []);
  }, [sessionActions, sessionLinks, orgSlug]);

  const defaultTabs = useMemo(() => {
    const tabs: Record<string, Tab> = {
      HOME: { text: 'Home', id: `${Routes.HOME}`, link: `/${orgSlug}/${Routes.HOME}` },
      PANELS: { text: 'Panels', id: `${Routes.PANELS}`, link: `/${orgSlug}/${Routes.PANELS}` },
      EXPERIMENTS: { text: 'Experiments', id: `${Routes.EXPERIMENTS}`, link: `/${orgSlug}/${Routes.EXPERIMENTS}` },
      WORKFLOWS: { text: 'Workflows', id: `${Routes.WORKFLOWS}`, link: `/${orgSlug}/${Routes.WORKFLOWS}?tab=active` },
      FILE_EXPLORER: { text: 'Data', id: `${Routes.FILE_EXPLORER}`, link: `/${orgSlug}/${Routes.FILE_EXPLORER}` },
      CYTOMETERS: { text: 'Cytometers', id: `${Routes.CYTOMETERS}`, link: `/${orgSlug}/${Routes.CYTOMETERS}?tab=custom` },
      REAGENTS: { text: 'Reagents', id: `${Routes.REAGENTS}`, link: `/${orgSlug}/${Routes.REAGENTS}?tab=inventory` },
      POPULATIONS: { text: 'Populations', id: `${Routes.POPULATIONS}`, link: `/${orgSlug}/${Routes.POPULATIONS}` },
      GROUPS: { text: 'Groups & Projects', id: `${Routes.GROUPS}`, link: `/${orgSlug}/${Routes.GROUPS}?tab=joined` },
      USERS: { text: 'Users', id: `${Routes.USERS}`, link: `/${orgSlug}/${Routes.USERS}?tab=active` },
    };

    if (betaTestingEnabled) {
      tabs.FCS_VIEWER = { text: 'FCS Viewer', id: `${Routes.FCS_VIEWER}`, link: `/${orgSlug}/${Routes.FCS_VIEWER}` };
    }
    return Object.values(tabs).filter((tab) => sessionRoutes.includes(tab.id as typeof Routes[keyof typeof Routes]));
  }, [betaTestingEnabled, orgSlug, sessionRoutes]);

  const getRouteWithTab = (route: string) => {
    switch (route) {
      case Routes.WORKFLOWS:
        return `${route}?tab=active`;
      case Routes.GROUPS:
        return `${route}?tab=joined`;
      case Routes.USERS:
        return `${route}?tab=active`;
      case Routes.REAGENTS:
        return `${route}?tab=inventory`;
      case Routes.CYTOMETERS:
        return `${route}?tab=custom`;
      case Routes.EXPERIMENTS:
        return `${route}?tab=active`;
      case Routes.POPULATIONS:
        return `${route}?tab=populations`;
      case Routes.PANELS:
        return `${route}?tab=panels`;
      default:
        return `${Routes.HOME}`;
    }
  };

  const { defaultRoute, filteredRoutes } = React.useMemo(() => {
    const routes: Record<string, JSX.Element> = {
      [Routes.FILE_EXPLORER]: <Route path="/:orgSlug/file-explorer" component={FileExplorer} key={Routes.FILE_EXPLORER} />,
      [Routes.GROUPS]: <Route path="/:orgSlug/groups" component={Groups} key={Routes.GROUPS} />,
      [Routes.USERS]: <Route path="/:orgSlug/users" component={Users} key={Routes.USERS} />,
      [Routes.CYTOMETERS]: <Route path="/:orgSlug/cytometers" component={Cytometers} key={Routes.CYTOMETERS} />,
      [Routes.POPULATIONS]: <Route path="/:orgSlug/populations" component={Populations} key={Routes.POPULATIONS} />,
      [Routes.REAGENTS]: <Route path="/:orgSlug/reagents" component={Reagents} key={Routes.REAGENTS} />,
      [Routes.EXPERIMENTS]: <Route path="/:orgSlug/experiments" component={Experiments} key={Routes.EXPERIMENTS} />,
      [Routes.PANELS]: <Route path="/:orgSlug/panels" component={Panels} key={Routes.PANELS} />,
      [Routes.HOME]: <Route path="/:orgSlug/home" component={Home} key={Routes.HOME} />,
      [Routes.WORKFLOWS]: <Route path="/:orgSlug/workflows" component={Workflows} key={Routes.WORKFLOWS} />,
    };

    if (betaTestingEnabled) {
      routes[Routes.FCS_VIEWER] = <Route path="/:orgSlug/fcs-viewer" component={FcsViewer} key={Routes.FCS_VIEWER} />;
      routes[Routes.CHORUS_EXPERIMENTS] = <Route path="/:orgSlug/chorus-experiments" component={ChorusExperiments} key={Routes.CHORUS_EXPERIMENTS} />;
    }

    const filtered = sessionRoutes.map((routeKey) => routes[routeKey]);
    const pinnedRoute = sessionRoutes.find((route) => route === user?.attributes?.settings?.pinned_tab)
    const defaultR = getRouteWithTab(pinnedRoute ?? '');
    return {
      defaultRoute: defaultR,
      filteredRoutes: filtered,
    };
  }, [betaTestingEnabled, sessionRoutes, user?.attributes?.settings?.pinned_tab]);

  if (signInRequirements?.length || signInWarnings?.length) {
    return <Redirect to="/inforeq" />;
  }

  if (appSetToMobile) {
    return <Redirect to="/mobile-scanner" />;
  }

  return (
    <Container>
      <MainWrapper>
        <MainNavBar defaultTabs={defaultTabs} />
        <Loading watchRequests={[requestSession.type]}>
          <ContentContainer ref={scrollRef} onScroll={handleScrollEvent} useFullScreen={fullScreen}>
            <BreadCrumb orgSlug={orgSlug} />
            <Suspense fallback={<Loading forceLoading />}>
              {sessionRoutes.length > 0 && (
                <Switch>
                  <Redirect exact from="/" to={orgSlug ? `/${orgSlug}/${defaultRoute}` : '/login'} />
                  <Redirect exact from="/:orgSlug" to={`/:orgSlug/${defaultRoute}`} />
                  <Route path="/:orgSlug/file-explorer/:fileId" component={FilePage} />
                  <Route path="/:orgSlug/groups/:groupId/workflows/:id" component={WorkflowRouter} />
                  <Route path="/:orgSlug/experiments/:id" component={ExperimentRouter} />
                  <Route path="/:orgSlug/cytometers/:id" component={CytometerRouter} />
                  <Route path="/:orgSlug/panels/:id" component={PanelRouter} />
                  <Route path="/:orgSlug/groups/:groupId/projects/:projectId/studies/:id/report" component={StudyReport} />
                  <Route path={`/:orgSlug/${Routes.WORKFLOWS}`} component={Workflows} />
                  {filteredRoutes}
                  <Redirect to="/not-found" />
                </Switch>
              )}
            </Suspense>
          </ContentContainer>
        </Loading>
      </MainWrapper>
      <UtilityBar />
    </Container>
  );
};

export default Main;

const Container = styled.div`
  height: 100%;
  background-color: ${colors.lightGrey};
  overflow: hidden;
`;

const MainWrapper = styled.div`
  height: calc(100vh - 40px);
  background-color: ${colors.lightGrey};
  overflow: hidden;
`;

export const ContentContainer = styled.div<{ useFullScreen?: boolean }>`
  position: relative;
  height: calc(100% - calc(${layout.headerBarTopHeight} + ${layout.headerBarBottomHeight}));
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ useFullScreen }) => (useFullScreen ? css`
    padding: 60px 0 0 0;
  ` : css`
    padding: 60px ${layout.mainContainerPadding} 0px ${layout.mainContainerPadding};
  `)}
  overflow-x: hidden;
  overflow-y: auto;
`;
