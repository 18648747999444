const setAuthToken = (authToken) => {
  if (localStorage.getItem('rememberMe')) {
    localStorage.setItem('authToken', authToken);
  }
  sessionStorage.setItem('authToken', authToken);
};

const getAuthToken = () => localStorage.getItem('authToken') || sessionStorage.getItem('authToken');

const removeAuthToken = () => {
  if (localStorage.getItem('rememberMe') || localStorage.getItem('authToken')) {
    localStorage.removeItem('rememberMe');
    localStorage.removeItem('authToken');
  }
  sessionStorage.removeItem('authToken');
};

export {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
};
