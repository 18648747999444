import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import getGroupUsers from './getGroupUsers';

function* confirmGroupUser({ payload: { action, params, group_id } }) {
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    yield getGroupUsers({ payload: { group_id, is_active: '1' } });
    yield getGroupUsers({ payload: { group_id, is_active: '0' } });
    yield getGroupUsers({ payload: { group_id, confirmed: '0' } });
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default confirmGroupUser;
