import { put, select } from 'redux-saga/effects';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { requestReagents } from 'rdx/modules/reagents/slice';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { getSessionAction } from 'rdx/modules/auth/slice';

function* deleteReagent({ payload }) {
  const { action, values, import_type } = payload;
  const { success, error, data } = yield* makeRequest.action(action, values);
  if (error) {
    yield* putErrorActions({ error });
  }
  if (success && data) {
    const reagentAction = yield select(getSessionAction('reagents'));
    const slug = yield select(getCurrentOrgSlug);
    yield put(requestReagents({
      action: reagentAction,
      params: { organization_id: slug, import_type: import_type || 'custom' },
    }));
    yield put(newMessageEvent({ text: 'Reagent Deleted' }));
  }
  return null;
}

export default deleteReagent;
