import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setCurrentChannel, requestChannels } from '../slice';

function* postChannel({ payload }) {
  const { action, values } = payload;
  const { success, data, error } = yield makeRequest.action(action, values);
  if (success && data) {
    yield put(requestChannels());
    const resource = data.unwrap();
    yield put(setCurrentChannel(resource));
  }
  if (error) {
    yield* putErrorActions({ error, target: 'messages' });
  }
  return null;
}

export default postChannel;
