import * as Yup from 'yup';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { subtractFromStyleString } from 'lib/utils/styles/doMathOnStyleString';
import globals from 'styles/globals';

import Errors from 'components/Form/Errors';
import { BtnSize } from 'components/DeprecatedButton/index';
import DeprecatedPrimaryButton from 'components/DeprecatedButton/DeprecatedPrimaryButton/index';
import DeprecatedDangerButton from 'components/DeprecatedButton/DeprecatedDangerButton/index';
import FormikInput from 'components/Formik/FormikInput/index';
import { revokeConsent, updateUser } from 'rdx/modules/users/slice';
import Link from 'components/Link';
import { useSecondaryModal } from 'hooks/useSecondaryModal';
import BaseModal from 'containers/Modals/BaseModal';

const { layout, colors, fonts, fontSizes } = globals;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(1, 'Must be at Least 1 character')
    .max(40, 'Max 40 characters')
    .required('First Name is required'),
  last_name: Yup.string()
    .min(1, 'Must be at Least 1 character')
    .max(40, 'Max 40 characters')
    .required('Last Name is required'),
  profile_image_url: Yup.string().url(({ value }) => `"${value}" is not a valid url`).nullable(),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
  // TODO: add some zip code validation when we know if we need international support
  postal_code: Yup.string().required('Postal Code is required'),
});

const ProfileTab = ({ user, doSubmit, revokeAction, onCancel }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { callSecondaryModal } = useSecondaryModal();

  const handleRevoke = () => {
    dispatch(revokeConsent({
      action: revokeAction,
      onSuccess: () => {
        onCancel();
        history.push('/login');
      },
      onError: onCancel,
    }));
  };

  const openPasswordChangeModal = () => {
    callSecondaryModal(
      <BaseModal
        isSecondary
        dimensions={{
          height: 'fit-content',
        }}
        showXIcon
      >
        <p>To change your password, navigate to <Link to="https://cloud.flowjo.com/change-password" target="_blank" rel="noreferrer">FlowJo Portal</Link></p>
      </BaseModal>
    )
  }

  return (
    <SummaryContainer>
      <Formik
        validationSchema={ProfileSchema}
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          profile_image_url: user.profile_image_url,
          phone: user.phone,
          tech_support_sign_in: user.settings.tech_support_sign_in,
          postal_code: user.postal_code,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          doSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Form name="user-profile">
            <FormRow>
              <Title>About Me</Title>
            </FormRow>
            <FormRow>
              <InputWrapper>
                <Label>EMAIL</Label>
                {user.email}
                <Link onClick={() => openPasswordChangeModal()} style={{ width: "fit-content", fontSize: '13px', marginTop: '5px' }} >Change Password</Link>
              </InputWrapper>
              <InputWrapper>
                <Label>Country Code</Label>
                {user.country_code}
              </InputWrapper>
            </FormRow>
            <FormRow>
              <InputWrapper>
                <Label>NAME</Label>
                {user.first_name} {user.last_name}
              </InputWrapper>
            </FormRow>
            <FormRow>
              <InputWrapper>
                <Label>PROFILE IMAGE URL</Label>
                <Field name="profile_image_url">
                  {({ field }) => (
                    <FormikInput {...field} borderless backgroundColor={colors.white} type="text" placeholder="" disabled={formikProps.isSubmitting} />
                  )}
                </Field>
                <ErrorStuff>{formikProps.errors.profile_image_url && formikProps.errors.profile_image_url}</ErrorStuff>
              </InputWrapper>
            </FormRow>
            <FormRow>
              <InputWrapper>
                <Label>PHONE</Label>
                <Field name="phone">
                  {({ field }) => (
                    <FormikInput {...field} borderless backgroundColor={colors.white} width="95%" type="text" placeholder="" disabled={formikProps.isSubmitting} data-testid="profile-phone-input" />
                  )}
                </Field>
                <ErrorStuff>{formikProps.errors.phone && formikProps.errors.phone}</ErrorStuff>
              </InputWrapper>
              <InputWrapper>
                <Label>WORK POSTAL CODE *</Label>
                <Field name="postal_code">
                  {({ field }) => (
                    <FormikInput {...field} borderless backgroundColor={colors.white} type="text" placeholder="" disabled={formikProps.isSubmitting} data-testid="profile-postal-code-input" />
                  )}
                </Field>
                <Errors
                  errors={formikProps.touched.postal_code
                    && formikProps?.errors?.postal_code ? [formikProps?.errors?.postal_code] : null}
                  heightAdjust="10px"
                  textHeightAdjust="-8px"
                />
              </InputWrapper>
            </FormRow>
            <ButtonContainer>
              <DeprecatedDangerButton
                size={BtnSize.JUMBO}
                text="Revoke Agreements"
                onClick={() => handleRevoke()}
                data-testid="user-profile-revoke-agreements-button"
                style={{ marginRight: '10px' }}
              />
              <DeprecatedPrimaryButton
                size={BtnSize.NARROW}
                text="Update"
                type="submit"
                disabled={formikProps.isSubmitting || !formikProps.isValid}
                rdxTypes={[updateUser.type]}
                data-testid="user-profile-submit-button"
              />
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </SummaryContainer>
  );
};

export default ProfileTab;

const drawerMinus = subtractFromStyleString(layout.drawerPadding, 30);

const SummaryContainer = styled.div`
  flex: 1;
  padding: ${drawerMinus} ${layout.drawerPadding};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  flex: 1;
`;

const Label = styled.p`
  font: ${fonts.flag};
  padding: 5px 0;
  font-weight: bolder;
  color: ${colors.fontDark};
`;

const FormRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const InputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const ErrorStuff = styled.div`
  color: ${colors.errorText};
  font: ${fonts.body};
  font-size:${fontSizes.small};
  position: absolute;
  bottom: -7px;
`;

const ButtonContainer = styled(FormRow)`
  justify-content: flex-end;
  margin: 15px 0 20px 0px;
`;
