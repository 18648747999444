import { forwardRef } from 'react';
import createIcon from '../createIcon';

const FillHorizontal = forwardRef((props, ref) => (
  <svg viewBox="0 0 130 130" ref={ref} {...props}>
    <path
      d="
        M 10,31 H 110
        v -6 l 20,12 l -20,12 v -6
        H 10 v -12
        M 10,59 H 110
        v -6 l 20,12 l -20,12 v -6
        H 10 v -12
        M 10,87 H 110
        v -6 l 20,12 l -20,12 v -6
        H 10 v -12
      "
    />
  </svg>
));

export default createIcon(FillHorizontal);
