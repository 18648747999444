import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import ChannelList from 'containers/Chat/ChannelList';
import MessageInput from 'containers/Chat/MessageInput';
import Messages from 'containers/Chat/Messages';
import AddChannel from 'containers/Chat/AddChannel';
import ResourceList from 'lib/jsonApi/ResourceList';
import { FreeFocusInside } from 'react-focus-lock';
import { useSocketMessages } from 'hooks/useSocketMessages';
import { useTimer } from 'hooks/useTimer';
import { ChatIcon } from 'components/Icons';
import globals from 'styles/globals';
import { getCurrentOrganization, getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { getUser, requestOrgUsers } from 'rdx/modules/users/slice';
import { getChannels, getMessages, getCurrentChannel, getOpenChatFor, requestChannels, receiveMessage, setCurrentChannel, postChannel, hideChannel, requestMessages, openChatFor } from 'rdx/modules/chat/slice';

const { layout } = globals;

const Chat = () => {
  const dispatch = useDispatch();
  const currentOrgSlug = useSelector(getCurrentOrgSlug);
  const channels = useSelector(getChannels);
  const user = useSelector(getUser);
  const messages = useSelector(getMessages);
  const currentOrg = useSelector(getCurrentOrganization, (a, b) => a?.id === b?.id);
  const currentChannel = useSelector(getCurrentChannel, (a, b) => a?.id === b?.id);
  const openChatForState = useSelector(getOpenChatFor);
  const [showChat, setShowChat] = useState(false);
  const [showMinChat, setShowMinChat] = useState(false);
  const [renderChat, setRenderChat] = useState(true);
  const [checkedUnread, setCheckedUnread] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [addChannel, setAddChannel] = useState(false);
  const [unreadMessagesFlag, setUnreadMessagesFlag] = useState([]);
  const [programmaticMessage, setProgrammaticMessage] = useState('');
  const postAction = useMemo(() => channels?.getAction?.('create') || null, [channels]);
  const hideAction = useMemo(() => currentChannel?.getAction?.('hide') || null, [currentChannel]);
  const timer = useTimer();

  const unreadMessages = useMemo(() => {
    if (!messages) { return false; }
    return Object.keys(messages)
      .filter((k) => k !== 'newChannels')
      .reduce((acc, cur) => {
        if (messages[cur].unread > 0) {
          return true;
        }
        return acc;
      }, false);
  }, [messages]);

  useEffect(() => {
    if (!checkedUnread && channels?.meta) {
      const unreadChannels = [];
      channels.map((channel) => {
        if ((channel.attributes.read_at < channel.attributes.last_message_at)
          || (channel.attributes.read_at === null && channel.attributes.last_message_at !== null)
        ) {
          unreadChannels.push(channel.id);
        }
        return null;
      });
      if (unreadChannels.length !== unreadMessages.length) {
        setUnreadMessagesFlag(unreadChannels);
      }
      setCheckedUnread(true);
    }
  }, [checkedUnread, channels, unreadMessages.length]);

  const updateHandler = useCallback((response) => {
    if (response.data.type === 'channel_messages') {
      const list = new ResourceList(response);
      const listData = list.unwrap();
      const { category } = listData.getRel('channel');
      if (category === 'dm' || category === 'custom') {
        const sender = listData.getRel('user');
        const selfMessage = sender?.uuid === user?.uuid;
        dispatch(receiveMessage({ list, selfMessage, currentChannelUUID: currentChannel?.uuid || '' }));
      }
    }
  }, [currentChannel, dispatch, user]);

  const { connected, emitMessage, error } = useSocketMessages(updateHandler);

  const sendMessage = useCallback(
    async (inputMessage) => {
      const channelUUID = currentChannel?.attributes?.uuid;
      const currentPath = `/channels/${channelUUID}/messages`;
      const message = {
        method: 'POST',
        path: currentPath,
        payload: {
          body: inputMessage,
        },
      };
      await emitMessage(message);
      if (programmaticMessage) {
        setProgrammaticMessage('');
      }
    }, [currentChannel, emitMessage, programmaticMessage]
  );

  const newChannel = useCallback(
    async ({ newChannelUsers }, name) => {
      if (!newChannelUsers) return null;
      await dispatch(postChannel({
        action: postAction,
        values: {
          category: newChannelUsers.length === 1 ? 'dm' : 'custom',
          user_uuids: newChannelUsers.map((u) => u.uuid) || [],
          name: name || '',
        },
      }));
      return null;
    }, [dispatch, postAction]
  );

  const changeChannel = useCallback(
    (channel) => {
      if (unreadMessagesFlag.includes(channel.id)) {
        setUnreadMessagesFlag(unreadMessagesFlag.filter((flag) => flag !== channel.id));
      }
      dispatch(setCurrentChannel(channel));
    }, [dispatch, unreadMessagesFlag],
  );

  const handleAddChannel = useCallback(
    () => {
      if (addChannel) {
        setAddChannel(!addChannel);
        setTimeout(() => setFadeOut(!fadeOut), 200);
      } else {
        setFadeOut(!fadeOut);
        setTimeout(() => setAddChannel(!addChannel), 200);
      }
    }, [addChannel, fadeOut]
  );

  const handleHideChannel = useCallback(
    async () => {
      await dispatch(hideChannel({
        action: hideAction,
      }));
      return null;
    }, [dispatch, hideAction]
  );

  const handleOpenClose = useCallback(() => {
    setCheckedUnread(false);
    dispatch(requestChannels());
    if (showChat) {
      dispatch(setCurrentChannel(false));
    }
    setShowChat(!showChat);
    if (showMinChat) {
      setTimeout(() => setShowMinChat(!showMinChat), 150);
    } else {
      setShowMinChat(!showMinChat);
    }
  }, [dispatch, showChat, showMinChat]);

  useEffect(() => {
    if (!connected) {
      timer.start(() => {
        setRenderChat(false);
      }, 20000);
    } else {
      timer.stop();
      setRenderChat(true);
    }
  }, [connected, timer]);

  useEffect(() => {
    if (currentChannel && !error) {
      dispatch(requestMessages({ link: currentChannel.links.messages, channelUUID: currentChannel.uuid }));
    }
  }, [currentChannel, dispatch, error]);

  const currentChannelUsers = useMemo(() => {
    let userString = ': ';
    if (currentChannel?.relationships?.users?.data?.length) {
      currentChannel.relationships.users.data.map((channelUser) => (
        currentChannel.included.map((allUser) => {
          if (channelUser.id === allUser.id && channelUser.id !== user.id) {
            if (userString === ': ') {
              userString = `${userString} ${allUser.first_name} ${allUser.last_name}`;
            } else {
              userString = `${userString}, ${allUser.first_name} ${allUser.last_name}`;
            }
          }
          return null;
        })
      ));
    }
    return userString;
  }, [currentChannel, user.id]);

  useEffect(() => {
    if (showChat) {
      dispatch(requestOrgUsers({ organization_id: currentOrgSlug }));
    }
  }, [currentOrg, currentOrgSlug, dispatch, showChat]);

  /**
   * listens for openChatFor user(s) and will:
   * 1.) open chat if it is closed
   * 2.) select an existing channel for the user or users if exists
   * 3.) create a channel if it doesn't
   * 4.) set that channel as active
   * 5.) set message input with auto message if exists
   */
  useEffect(() => {
    if (openChatForState) {
      const includingUser = [...openChatForState.userUUIDs, user?.uuid];
      const existingChannel = channels?.find((channel) => isEqual(channel.user_uuids, includingUser));
      if (existingChannel) {
        changeChannel(existingChannel);
      } else {
        newChannel({ newChannelUsers: openChatForState.userUUIDs.map((uuid) => ({ uuid })) }, openChatForState.name);
      }
      if (!showChat) {
        handleOpenClose();
      }
      if (openChatForState.programmaticMessage) {
        setProgrammaticMessage(openChatForState.programmaticMessage);
      }
      dispatch(openChatFor(null));
    }
  }, [changeChannel, channels, dispatch, handleOpenClose, newChannel, openChatForState, showChat, user]);

  if (!renderChat) {
    console.warn('No websocket connection, chat not rendered');
    return null;
  }

  return (
    <ChatWrapper>
      <FreeFocusInside>
        <ChatWindow
          {...{ showChat }}
          data-testid="chat"
        >
          {showMinChat
            ? (
              <>
                {addChannel
                  ? (
                    <AddChannelWrapper>
                      <AddChannel
                        handleAddChannel={handleAddChannel}
                        newChannel={newChannel}
                        currentOrg={currentOrg}
                        user={user}
                      />
                    </AddChannelWrapper>
                  )
                  : null}
                <OpenChat {...{ fadeOut }}>
                  <TitleBar onClick={() => handleOpenClose()}>
                    <ChatTitle data-testid="chat-title">
                      {currentChannel
                        ? (currentChannel.name || 'Direct Message') + currentChannelUsers
                        : ''}
                    </ChatTitle>
                    <Collapse data-testid="collapse-chat-icon">_</Collapse>
                  </TitleBar>
                  <ChatContents>
                    <ChannelListSidebar>
                      <SChannelList>
                        <ChannelList
                          changeChannel={changeChannel}
                          user={user}
                          currentChannel={currentChannel}
                          channels={channels}
                          handleAddChannel={handleAddChannel}
                          messages={messages}
                          unreadMessagesFlag={unreadMessagesFlag}
                          handleHideChannel={handleHideChannel}
                        />
                      </SChannelList>
                      <AddChannelButton
                        onClick={() => handleAddChannel()}
                        data-testid="addChannel"
                      >
                        + Add Channel
                      </AddChannelButton>
                    </ChannelListSidebar>
                    <MessageColumn>
                      {currentChannel ? (
                        <Messages
                          key={currentChannel.uuid}
                          currentChannel={currentChannel}
                          errorState={error}
                        />
                      ) : (
                        <NoChannelsMessage>
                          <p><em><strong>select or create a channel</strong></em></p>
                          <p><em><strong>to view messages</strong></em></p>
                        </NoChannelsMessage>
                      )}
                      <MessageInput
                        sendMessage={sendMessage}
                        currentChannel={currentChannel}
                        programmaticMessage={programmaticMessage}
                      />
                    </MessageColumn>
                  </ChatContents>
                </OpenChat>
              </>
            ) : (
              <MinimizedChat
                {...{ showChat }}
                onClick={handleOpenClose}
              >
                <MinimizedChatTitle>COLLABORATE</MinimizedChatTitle>
                <ChatIcon color="white" size="sm" style={{ transform: 'translateX(-15px)' }} />
                <UnreadMessage show={unreadMessagesFlag.length || unreadMessages} />
              </MinimizedChat>
            )}
        </ChatWindow>
        <Overlay
          {...{ showChat }}
          onClick={handleOpenClose}
        />
      </FreeFocusInside>
    </ChatWrapper>
  );
};

export default Chat;

const MinimizedChat = styled.button`
  z-index: 1999;
  position: absolute;
  bottom: -3px;
  right: -3px;
  opacity: ${({ showChat }) => (showChat ? 0 : 1)};
  width: 165px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  pointer-events: ${({ showChat }) => (showChat ? 'none' : 'auto')};
  transition: all 200ms ease-out;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.charcoal};
`;

const OpenChat = styled.div`
  opacity: ${({ fadeOut }) => (fadeOut ? 0 : 1)};
  transition: all 200ms ease-out;
`;

const ChatWindow = styled.div`
  height: ${({ showChat }) => (showChat ? '540px' : '40px')};
  width: ${({ showChat }) => (showChat ? '600px' : '160px')};
  border-radius: ${({ showChat }) => (showChat ? '0px' : '0px')};
  background-color: ${({ showChat, theme }) => (showChat ? theme.colors.white : theme.colors.charcoal)};
  box-shadow: ${({ showChat }) => (showChat ? '0px 0px 5px grey' : 'none')};
  overflow: hidden;
  bottom: ${({ showChat }) => (showChat ? '40px' : '-6px')};
  right: ${({ showChat }) => (showChat ? '20px' : '0px')};
  pointer-events: auto;
  transition: all 200ms ease-out;
`;

const ChatWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: calc(${layout.mainContainerPadding} - 146px);
  pointer-events: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 10;
  max-width: ${layout.maxContainerWidth};
`;

const AddChannelWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  min-width: 200px;
  background-color: ${({ theme }) => theme.colors.charcoal};
  border: 1px solid black;
  cursor: pointer;
`;

const Collapse = styled.span`
  padding-right: 10px;
  color: white;
  font-size: 2rem;
  cursor: pointer;
`;

const ChatTitle = styled.span`
  padding-left: 20px;
  color: white;
  font-size: 9pt;
`;

const MinimizedChatTitle = styled.span`
  color: white;
  font-size: 8pt;
  padding-top: 2px;
  width: 80px;
  text-align: center;
  &:hover {
    text-shadow:0px 0px 1px white;
  }
`;

const ChatContents = styled.div`
  display: flex;
  height: 500px;
  width: 600px;
  background-color: whitesmoke;
`;

const ChannelListSidebar = styled.div`
  display: flex;
  flex-direction: column;
`;
const SChannelList = styled.div`
  height: 500px;
  width: 200px;
  background-color: whitesmoke;
  overflow: auto;
`;

const AddChannelButton = styled.button`
  margin: 10px 10px 20px 10px;
  color: #ffffff;
  font: 400 9pt Roboto,sans-serif;
  border-radius: 5px;
  letter-spacing: 0.4pt;
  white-space: nowrap;
  background: ${({ theme }) => theme.colors.primary};
  border: none;
  padding: 10px;
  cursor: pointer;
  outline: inherit;
  background-color: ${({ theme }) => theme.colors.primary};
  &:hover {
    background-color: $${({ theme }) => theme.colors.primaryHover};
  }
  &:active {
    filter: brightness(0.9);
  }
  transition: all 200ms ease;
`;

const MessageColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  width: 400px;
  background-color: white;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
  pointer-events: ${({ showChat }) => (showChat ? 'auto' : 'none')};
  opacity: ${({ showChat }) => (showChat ? 0.2 : 0)};
  transition: opacity 200ms;
`;

const UnreadMessage = styled.div`
  position: absolute;
  top: 24px;
  left: 0px;
  display: ${({ show }) => (show ? 'auto' : 'none')};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.danger};
  box-shadow: inset 0px 0px 3px 1px ${({ theme }) => theme.colors.charcoal};
  animation: ${({ theme }) => theme.animations.blink} 2s linear infinite;
`;

const NoChannelsMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.fontLight};
`;
