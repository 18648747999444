import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from 'hooks/useModal';
import SwitchTable from 'components/Formik/SwitchTable';
import Loading from 'components/Loading';
import { ORG_SETTINGS } from 'containers/Modals/OrgProfileModal/SettingsTab/constants';
import { humanizeString } from 'lib/utils/string';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import { requestOrganizationSettings, getOrganizationSettings, getIsOrgOwner, updateOrganizationSettings } from 'rdx/modules/organization/slice';
import { newMessageEvent } from 'rdx/modules/messages/slice';

const watchRequests = [requestOrganizationSettings.type, updateOrganizationSettings.type];

const SettingsTab = () => {
  const dispatch = useDispatch();
  const { closeAndUnsetModal } = useModal();

  const [settingsValues, setSettingsValues] = useState();

  const isOrgOwner = useSelector(getIsOrgOwner);
  const settings = useSelector(getOrganizationSettings);

  const initialSettingsValues = useMemo(() => {
    const initialValues = {};
    Object.values(ORG_SETTINGS).forEach((setting) => {
      const existingSetting = settings?.data?.find((s) => s.attributes.key === setting.name);
      initialValues[setting.name] = existingSetting?.attributes?.data || setting.default;
    });
    return initialValues;
  }, [settings?.data]);

  const settingsSwitches = useMemo(() => {
    const switchList = {};
    Object.values(ORG_SETTINGS).forEach((setting) => {
      switchList[setting.label] = {
        id: setting.name,
        text: humanizeString(setting.label),
        disabled: !isOrgOwner,
        tooltip: !isOrgOwner ? 'Only org owners can update settings' : setting.tooltip,
      };
    });
    return Object.values(switchList);
  }, [isOrgOwner]);

  useEffect(() => {
    dispatch(requestOrganizationSettings());
  }, [dispatch]);

  useEffect(() => {
    setSettingsValues(initialSettingsValues);
  }, [initialSettingsValues]);

  const handleChange = (newValues) => {
    setSettingsValues(newValues);
  };

  const handleSubmit = () => {
    const settingsEntries = Object.entries(settingsValues);
    settingsEntries.forEach(([key, value], i) => {
      const actionName = Object.values(ORG_SETTINGS).find((s) => s.name === key)?.action;
      const action = settings.getAction(actionName);

      if (action) {
        dispatch(updateOrganizationSettings({
          action,
          params: {
            data: value,
          },
          onSuccess: () => {
            if (i === settingsEntries.length - 1) {
              dispatch(newMessageEvent({ text: 'Organization settings updated. Reloading to apply new settings.' }));
              closeAndUnsetModal();
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          },
        }));
      }
    });
  };

  return (
    <Loading watchRequests={watchRequests}>
      <TableContainer>
        <SwitchTable
          switches={settingsSwitches}
          values={settingsValues}
          onChange={handleChange}
        />
        <ButtonContainer>
          <PrimaryButton
            data-testid="org-profile-settings-submit"
            text="Update"
            disabled={!settingsValues || !isOrgOwner}
            onClick={handleSubmit}
          />
        </ButtonContainer>
      </TableContainer>
    </Loading>
  );
};

export default SettingsTab;

const TableContainer = styled.div`
  padding: 35px 50px 0 50px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 35px;
`;
