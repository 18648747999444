import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'rdx/modules/app/slice';
import type { RootState } from 'index';

interface FileUploadPayload {
  fileId: string;
  fileName: string;
  target: string;
}

interface FileUpload {
  fileName: string;
  target: string;
  progress: number;
  error: string;
  status: 'PENDING' | 'IN_PROGRESS' | 'COMPLETE' | 'ERROR';
}

const initialState: {
  fileUploads: Record<string, FileUpload> | Record<string, never>,
  flowInstances: Record<string, any>,
} = {
  fileUploads: {},
  flowInstances: {},
};

const fileUploadsSlice = createSlice({
  name: 'fileUploads',
  initialState,
  reducers: {
    addFileUpload: (state, action: PayloadAction<FileUploadPayload>) => {
      const { fileId, fileName, target } = action.payload;
      state.fileUploads[fileId] = {
        fileName,
        target,
        progress: 0,
        error: '',
        status: 'PENDING',
      };
    },
    removeFileUpload: (state, action: PayloadAction<{ fileId: string }>) => {
      const { fileId } = action.payload;
      if (state.fileUploads[fileId]) {
        delete state.fileUploads[fileId];
      }
    },
    setFileUploadProgress: (state, action: PayloadAction<{ fileId: string, progress: number }>) => {
      const { fileId, progress } = action.payload;
      if (state.fileUploads[fileId]) {
        state.fileUploads[fileId] = { ...state.fileUploads[fileId], progress, status: 'IN_PROGRESS' };
      }
    },
    setFileUploadComplete: (state, action: PayloadAction<{ fileId: string }>) => {
      const { fileId } = action.payload;
      if (state.fileUploads[fileId]) {
        state.fileUploads[fileId] = { ...state.fileUploads[fileId], progress: 1, status: 'COMPLETE' };
      }
    },
    setFileUploadError: (state, action: PayloadAction<{ fileId: string, error: string }>) => {
      const { fileId, error } = action.payload;
      if (state.fileUploads[fileId]) {
        state.fileUploads[fileId] = { ...state.fileUploads[fileId], error, status: 'ERROR' };
      }
    },
    addFlowInstance: (state, action: PayloadAction<{ target: string, flow: any }>) => {
      const { target, flow } = action.payload;
      if (!state.flowInstances[target]) {
        state.flowInstances[target] = flow;
      }
    },
    clearFlowInstance: (state, action: PayloadAction<{ target: string }>) => {
      const { target } = action.payload;
      if (state.flowInstances[target]) {
        delete state.flowInstances[target];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  addFileUpload,
  removeFileUpload,
  setFileUploadProgress,
  setFileUploadComplete,
  setFileUploadError,
  addFlowInstance,
  clearFlowInstance,
} = fileUploadsSlice.actions;

export {
  addFileUpload,
  removeFileUpload,
  setFileUploadProgress,
  setFileUploadComplete,
  setFileUploadError,
  addFlowInstance,
  clearFlowInstance,
};

export const getFileUploads = (state: RootState) => state.fileUploads.fileUploads;
export const getFlowInstances = (state: RootState) => state.fileUploads.flowInstances;
export const getFlowInstanceForTarget = (target: string) => (state: RootState) => state.fileUploads.flowInstances[target];

export default fileUploadsSlice.reducer;
