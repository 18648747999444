import { put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { addActiveRequest, getActiveRequests, removeActiveRequest } from 'rdx/modules/activeRequests/slice';
import type { RootState } from 'index';
import type { PayloadActionWithGenericPayload, Saga } from 'types/redux-types';

const composeRequestTracker = <T extends PayloadActionWithGenericPayload>(saga: Saga<T>) => function* trackRequestsGenerator(rdxAction: T): Generator<unknown, void, RootState['activeRequests']> {
  const addAction = addActiveRequest(rdxAction);
  const removeAction = removeActiveRequest(rdxAction);
  yield put(addAction);
  yield* saga(rdxAction);
  const requests = yield select(getActiveRequests);
  const request = requests.find((r: { type: string }) => r.type === rdxAction.type);
  if (request) yield put(removeAction);
};

const tracker = <T extends PayloadActionWithGenericPayload>(
  effect: typeof takeLatest | typeof takeEvery,
  type: string,
  saga: Saga<T>,
) => effect(type, composeRequestTracker<T>(saga));

export default tracker;
