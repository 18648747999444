import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import requestSpectrumViewerDataSaga from 'rdx/modules/spectrumViewer/sagas/requestSpectrumViewerData';
import requestSpectrumViewerImagingDataSaga from 'rdx/modules/spectrumViewer/sagas/requestSpectrumViewerImagingData';
import requestGeneratePanelSaga from 'rdx/modules/spectrumViewer/sagas/requestGeneratePanel';
import requestPanelsCsvReportSaga from 'rdx/modules/spectrumViewer/sagas/requestPanelsCsvReport';
import requestPanelsJsonReportSaga from 'rdx/modules/spectrumViewer/sagas/requestPanelsJsonReport';
import requestSaga from 'rdx/api-utils/requestSaga';
import { requestSpectrumViewerData, requestSpectrumViewerImagingData, requestGeneratePanel, requestPanelsCsvReport, requestPanelsJsonReport, requestPanelAssistance, requestCytometerMeta, setCytometerMeta, requestHotspotData } from '../slice';
import requestHotspotDataSaga from './requestHotspotData';

function* watchSpectrumViewerSagas() {
  yield trackRequests(takeEvery, requestSpectrumViewerData.type, requestSpectrumViewerDataSaga);
  yield trackRequests(takeEvery, requestSpectrumViewerImagingData.type, requestSpectrumViewerImagingDataSaga);
  yield trackRequests(takeEvery, requestGeneratePanel.type, requestGeneratePanelSaga);
  yield trackRequests(takeEvery, requestPanelsCsvReport.type, requestPanelsCsvReportSaga);
  yield trackRequests(takeEvery, requestPanelsJsonReport.type, requestPanelsJsonReportSaga);
  yield trackRequests(takeEvery, requestPanelAssistance.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, requestCytometerMeta.type, (rdxAction) => requestSaga({ rdxAction, putData: setCytometerMeta }));
  yield trackRequests(takeEvery,requestHotspotData.type, requestHotspotDataSaga);
}

export default watchSpectrumViewerSagas;
