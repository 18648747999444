import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { useSessionTimeout } from 'hooks/useSessionTimeout';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { getAuthToken } from 'lib/utils/authHelpers';
import { requestLogout, setRedirectUrl } from 'rdx/modules/auth/slice';
import { jwtDecode } from 'lib/utils/jwtDecode';
import { useMixpanel } from 'hooks/useMixpanel';

const AuthGuard = ({ children }: React.PropsWithChildren) => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const location = useLocation();
  const history = useHistory();
  const authToken = getAuthToken();
  const authExpirationTime = useMemo(() => (authToken ? jwtDecode(authToken).exp : null), [authToken]);

  // Displays a countdown modal and logs a user out on session expiration.
  useSessionTimeout();

  // If the session timeout fails because the app was left unfocused
  // ie: the user locks the screen or is viewing some other tab/window
  // visibilityLogout() ensures the UI still updates to show the login
  // screen after the jwt session was invalidated server side
  const visibilityLogout = useCallback(() => {
    const now = Math.round(Date.now() / 1000);
    const secondsLeft = (authExpirationTime - 10) - now;
    if (secondsLeft <= 0) {
      dispatch(requestLogout({ onSuccess: () => {
        mixpanel?.track('Logout')
        mixpanel?.reset();
        history.push('/login')
      } }));
    }
  }, [authExpirationTime, dispatch, history, mixpanel]);

  usePageVisibility(visibilityLogout);

  if (!authToken) {
    dispatch(setRedirectUrl(location?.pathname + location.search));
    return <Redirect to="/login" />;
  }
  return children;
};

export default AuthGuard;
