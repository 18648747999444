import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'hooks/usePrevious';
import { useSecondaryDrawer } from 'hooks/useSecondaryDrawer';
import { formChanged, formSecondaryTouched, formTouched, getFormChanged, getFormSecondaryTouched, getFormTouched } from 'rdx/modules/form/slice';
import type { FormOptions } from 'hooks/useForm/types';
import { useFormBase } from './useFormBase';
import { FormFields } from 'hooks/useForm/helpers';

export function useForm<FormValues>(fieldsArgs: FormFields<FormValues>, options: FormOptions<FormValues>) {
  const { secondaryDrawerIsOpen } = useSecondaryDrawer();
  const { form, fields } = useFormBase<FormValues>(fieldsArgs, options);
  const dispatch = useDispatch();
  const newTouched = useRef(false);
  const newChanged = useRef(false);
  const newSecondaryTouched = useRef(false);
  const formTouchedState = useSelector(getFormTouched);
  const formSecondaryTouchedState = useSelector(getFormSecondaryTouched);
  const formIsTouched = secondaryDrawerIsOpen ? formTouchedState : form?.touched;
  const formChangedState = useSelector(getFormChanged);
  const formIsChanged = form?.changed;
  const formSecondaryIsTouched = secondaryDrawerIsOpen ? form?.touched : formSecondaryTouchedState;
  newTouched.current = !!usePrevious<typeof formIsTouched>(formIsTouched);
  newSecondaryTouched.current = !!usePrevious<typeof formSecondaryIsTouched>(formSecondaryIsTouched);
  const formSubmitted = secondaryDrawerIsOpen ? false : form?.submitted;
  const formSecondarySubmitted = secondaryDrawerIsOpen ? form?.submitted : false;
  const prevSubmitted = usePrevious(formSubmitted);
  const prevSecondarySubmitted = usePrevious(formSecondarySubmitted);
  const submitted = formSubmitted === true && prevSubmitted === false;
  const secondarySubmitted = formSecondarySubmitted === true && prevSecondarySubmitted === false;
  useEffect(() => {
    if (secondaryDrawerIsOpen) {
      if (formIsChanged && newChanged.current !== formIsChanged && !formSubmitted) {
        dispatch(formChanged(true));
        newChanged.current = true;
      }
      if (formSecondaryIsTouched && newSecondaryTouched.current !== formSecondaryIsTouched) {
        dispatch(formSecondaryTouched(true));
        newSecondaryTouched.current = true;
      }
      if (submitted && secondarySubmitted && formSecondaryTouchedState) {
        dispatch(formSecondaryTouched(false));
      }
    } else {
      if (formIsChanged && newChanged.current !== formIsChanged && !formSubmitted) {
        dispatch(formChanged(true));
        newChanged.current = true;
      }
      if (formIsTouched && newTouched.current !== formIsTouched && !formSubmitted) {
        dispatch(formTouched(true));
        newTouched.current = true;
      }
      if (submitted && formTouchedState && formChangedState) {
        dispatch(formTouched(false));
        dispatch(formChanged(false));
      }
    }
  }, [formIsTouched, newTouched, formTouchedState, formSubmitted, formSecondaryIsTouched, newSecondaryTouched, formSecondaryTouchedState, formSecondarySubmitted, formIsChanged, formChangedState, newChanged, secondaryDrawerIsOpen, submitted, secondarySubmitted, dispatch]);
  return { form, fields };
}
