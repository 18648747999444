import PropTypes from 'prop-types';
// import customPropTypes from 'lib/customPropTypes';
import styled, { css } from 'styled-components';
import globals from 'styles/globals';

import Switch from 'components/Form/Switch';
import ZebraTable from 'components/ZebraTable';
import ToolTip from 'components/ToolTip';
import { useTooltip } from 'hooks/useTooltip';

const SwitchTable = ({ width, switches, values, onChange }) => {
  const { callTooltip, closeTooltip } = useTooltip();
  const handleChange = (id, newValue) => {
    // Clone values to not modify original object
    const newValues = { ...values };
    newValues[id] = newValue;
    onChange(newValues);
  };

  const renderRows = () => switches.map(({ id, text = '', disabled, tooltip = '' }) => ({
    left: tooltip.length ? (
      <SwitchText
        disabled={disabled}
        onMouseOver={(e) => callTooltip(<ToolTip message={tooltip} child={e} />)}
        onFocus={(e) => callTooltip(<ToolTip message={tooltip} child={e} />)}
        onBlur={closeTooltip}
        onMouseLeave={closeTooltip}
      >
        {text}
      </SwitchText>
    ) : (
      <SwitchText disabled={disabled}>{text}</SwitchText>
    ),
    right: disabled || tooltip.length ? (
      <div
        onMouseOver={(e) => callTooltip(<ToolTip message={tooltip.length ? tooltip : 'You have not been granted this permission\nand therefore cannot grant it to others.'} child={e} />)}
        onFocus={(e) => callTooltip(<ToolTip message={tooltip.length ? tooltip : 'You have not been granted this permission\nand therefore cannot grant it to others.'} child={e} />)}
        onBlur={closeTooltip}
        onMouseLeave={closeTooltip}
      >
        <Switch
          disabled={disabled}
          textPosition="right"
          text={values[id] ? 'on' : 'off'}
          value={values[id]}
          onChange={(newValue) => handleChange(id, newValue)}
          name={id}
          data-testid="switch"
          id={`switch-value-${values[id]}`}
        />
      </div>
    ) : (
      <Switch
        disabled={disabled}
        textPosition="right"
        text={values[id] ? 'on' : 'off'}
        value={values[id]}
        onChange={(newValue) => handleChange(id, newValue)}
        name={id}
        data-testid="switch"
        id={`switch-value-${values[id]}`}
      />
    ),
    key: id,
  }));

  return (
    <ZebraTable
      width={width}
      rows={renderRows()}
    />
  );
};

SwitchTable.propTypes = {
  width: PropTypes.string,
  switches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
  })),
  /**
   * Table of boolean switch values keyed by switch.id
   * example: { toggleA: true, toggleB: false }
   */
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
};

SwitchTable.defaultProps = {
  width: '100%',
  switches: [],
  values: {},
  onChange: () => {},
};

export default SwitchTable;

export const SwitchText = styled.label`
  font-size: ${globals.fontSizes.normal};
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
`;
