import { takeEvery, takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getGroupsSaga from 'rdx/modules/groups/sagas/getGroups';
import createGroupSaga from 'rdx/modules/groups/sagas/createGroup';
import updateGroupSaga from 'rdx/modules/groups/sagas/updateGroup';
import changeGroupOwnerSaga from 'rdx/modules/groups/sagas/changeGroupOwner';
import leaveGroupSaga from 'rdx/modules/groups/sagas/leaveGroup';
import getPendingGroupsSaga from 'rdx/modules/groups/sagas/getPendingGroups';
import getMoreGroupsSaga from 'rdx/modules/groups/sagas/getMoreGroups';
import getMorePendingGroupsSaga from 'rdx/modules/groups/sagas/getMorePendingGroups';
import getWorkflowGroupsSaga from 'rdx/modules/groups/sagas/getWorkflowGroups';
import getGroupByIdSaga from 'rdx/modules/groups/sagas/getGroupById';
import deleteGroupSaga from 'rdx/modules/groups/sagas/deleteGroup';
import requestSmartGroupsSaga from 'rdx/modules/groups/sagas/requestSmartGroups';
import smartGroupsActionSaga from 'rdx/modules/groups/sagas/smartGroupsAction';
import { requestGroups,
  createGroup, updateGroup, changeGroupOwner, leaveGroup, requestPendingGroups, requestMoreGroups, requestMorePendingGroups, requestWorkflowGroups, requestCurrentGroupById, deleteGroup, requestSmartGroups, smartGroupsActionRequest } from '../slice';

function* watchGroupsSagas() {
  yield trackRequests(takeEvery, requestGroups.type, getGroupsSaga);
  yield trackRequests(takeEvery, createGroup.type, createGroupSaga);
  yield trackRequests(takeEvery, updateGroup.type, updateGroupSaga);
  yield trackRequests(takeEvery, changeGroupOwner.type, changeGroupOwnerSaga);
  yield trackRequests(takeEvery, leaveGroup.type, leaveGroupSaga);
  yield trackRequests(takeEvery, requestPendingGroups.type, getPendingGroupsSaga);
  yield trackRequests(takeEvery, requestMoreGroups.type, getMoreGroupsSaga);
  yield trackRequests(takeEvery, requestMorePendingGroups.type, getMorePendingGroupsSaga);
  yield trackRequests(takeLatest, requestWorkflowGroups.type, getWorkflowGroupsSaga);
  yield trackRequests(takeLatest, requestCurrentGroupById.type, getGroupByIdSaga);
  yield trackRequests(takeLatest, deleteGroup.type, deleteGroupSaga);
  yield trackRequests(takeLatest, requestSmartGroups.type, requestSmartGroupsSaga);
  yield trackRequests(takeEvery, smartGroupsActionRequest.type, smartGroupsActionSaga);
}

export default watchGroupsSagas;
