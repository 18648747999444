import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BaseModal from 'containers/Modals/BaseModal';
import NavHeader from 'components/NavHeader';
import { useModal } from 'hooks/useModal';
import ActionForm from 'components/ActionForm';
import { useLoading } from 'hooks/useLoading';
import { setCurrentOrganization, setOrganizations, createOrganization } from 'rdx/modules/organization/slice';
import { requestLicenseQuoteURL, requestLicenses, setLicenseQuoteURL, setLicenses } from 'rdx/modules/licenses/slice';
import { getSessionAction, requestSession } from 'rdx/modules/auth/slice';
import SelectLicenseTable from './SelectLicenseTable';
import { useMixpanel } from 'hooks/useMixpanel';

const watchRequests = [
  createOrganization.type,
  setOrganizations.type,
  setCurrentOrganization.type,
  requestLicenses.type,
  setLicenses.type,
  requestLicenseQuoteURL.type,
  setLicenseQuoteURL.type,
];

const STEPS = [
  {
    id: 'select-license',
    text: 'Select a License',
  },
  {
    id: 'organization-attributes',
    text: 'Organization Info',
  },
];

const CreateOrgModal = () => {
  const [activeTab, setActiveTab] = React.useState(STEPS[0].id);
  const [license, setLicense] = React.useState(null);
  const { closeModal } = useModal();
  const mixpanel = useMixpanel();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const createOrgAction = useSelector(getSessionAction('create_organization'));

  const countryOptions = useMemo(() => createOrgAction?.field('country_code').options.map(({ value, label }) => ({
    value,
    display: label,
  }))?.sort((a, b) => a?.display?.localeCompare(b.display))?.sort((a, b) => {
    if (a.value === 'US') {
      return -1;
    } if (b.value === 'US') {
      return 1;
    } if (a > b) {
      return -1;
    } return 1;
  }) || [], [createOrgAction]);

  const onSubmit = React.useCallback((values) => {
    const params = { ...values, license };
    dispatch(createOrganization({
      action: createOrgAction,
      params,
      onSuccess: ({ data }) => {
        mixpanel?.track('Created an organization');
        closeModal();
        dispatch(requestSession());
        history.push(`/${data.data.attributes.slug}/home`);
      },
    }));
  }, [license, dispatch, createOrgAction, mixpanel, closeModal, history]);

  const loading = useLoading({ watchRequests });

  const dimensions = React.useMemo(() => (activeTab === STEPS[1].id
    ? ({
      width: '600px',
      height: '680px',
    }) : ({
      width: '1100px',
      height: 'fit-content',
    })), [activeTab]);

  const actionFormOptions = useMemo(() => ({
    cancelButton: {
      text: 'back',
    },
    ...createOrgAction.fields
      .reduce((acc, field) => ({
        ...acc,
        [field.name]: ({
          style: { width: ['name', 'address', 'address2', 'country_code'].includes(field.name) ? '100%' : '30%' },
          inputProps: {
            filter: field.name === 'country_code' ? true : undefined,
            id: field.name === 'country_code' ? 'country_code_dropdown' : field.name,
            ...(field.name === 'country_code' ? { initialValue: countryOptions.some((o) => o.value === 'US') ? 'US' : '' } : {}),
          },
          ...(field.name === 'country_code' ? { options: countryOptions } : {}),
        }),
      }), {}),
    hiddenFields: ['license'],
  }), [countryOptions, createOrgAction.fields]);

  return (
    <BaseModal
      dimensions={dimensions}
      noMaxHeight
      styles={{
        padding: '0',
        backgroundColor: theme.colors.lightGrey,
      }}
      noFooter
      allowScrollingX
      allowScrollingY
    >
      <ModalWithHeaderWrapper>
        <NavHeader
          title={STEPS.find((s) => s.id === activeTab).text}
          isModal
        />
        <Wrapper activeTab={activeTab}>
          {activeTab === STEPS[0].id && (
            <SelectLicenseTable
              setLicense={setLicense}
              setActiveTab={setActiveTab}
            />
          )}
          {activeTab === STEPS[1].id && (
            <ActionForm
              action={createOrgAction}
              wrap
              loading={!!loading}
              onSubmit={onSubmit}
              onCancel={() => setActiveTab(STEPS[0].id)}
              options={actionFormOptions}
            />
          )}
        </Wrapper>
      </ModalWithHeaderWrapper>
    </BaseModal>
  );
};

export default CreateOrgModal;

const Wrapper = styled.div`
  max-height: ${({ activeTab }) => (activeTab === STEPS[0].id ? 'auto' : 'auto')};
  overflow: auto;
  padding: 1rem;
`;

const ModalWithHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  max-width: 100%;
`;
