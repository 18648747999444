import PropTypes from 'prop-types';

import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import Action from './jsonApi/Action';

const defaultError = (propName, componentName) => new Error(
  `Invalid prop \`${propName}\` supplied to`
  + ` \`${componentName}\`. Validation failed.`
);

// eslint-disable-next-line consistent-return
const reactPortal = (props, propName, componentName) => {
  if (props[propName].$$typeof !== Symbol.for('react.portal')) {
    return defaultError(propName, componentName);
  }
};

export default {
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    rowCount: PropTypes.number,
    pageCount: PropTypes.number,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    reactPortal,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.node,
      reactPortal,
    ])),
  ]),
  stringOrNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reactPortal,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      current: PropTypes.any,
    }),
  ]),
  message: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  activeRequests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    payload: PropTypes.object,
  })),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    key: PropTypes.string,
    hash: PropTypes.string,
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    length: PropTypes.number,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      key: PropTypes.string,
      hash: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    profile_image_url: PropTypes.string,
  }),
  resourceOfType: (type) => (props, propName, componentName) => {
    const prop = props[propName];
    if (prop instanceof Resource && prop.type === type) {
      return null;
    }
    return defaultError(propName, componentName);
  },
  resourceList: (props, propName, componentName) => {
    if (props[propName] instanceof ResourceList || props[propName] === null || props[propName] === undefined) {
      return null;
    }
    return defaultError(propName, componentName);
  },
  action: (props, propName, componentName) => {
    if (props[propName] instanceof Action || props[propName] === null) {
      return null;
    }
    return defaultError(propName, componentName);
  },
  resource: (props, propName, componentName) => {
    if (props[propName] instanceof Resource || props[propName] === null) {
      return null;
    }
    return defaultError(propName, componentName);
  },
  resourceListOfType: (type) => (props, propName, componentName) => {
    const prop = props[propName];
    if (prop instanceof ResourceList && prop.type === type) {
      return null;
    }
    return defaultError(propName, componentName);
  },
  component: (props, propName, componentName) => {
    if (!props[propName] || typeof (props[propName].render) !== 'function') {
      return new Error(`Invalid prop \`${propName}\` supplied to`
        + ` \`${componentName}\`. ${propName}.render must be a function!`);
    }
    return null;
  },
  styledCSSLiteral: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
  ]),
};

// https://github.com/facebook/react/issues/3163#issuecomment-470307646
export function nullable(subRequirement) {
  const check = (required, props, key, ...rest) => {
    if (props[key] === null) {
      return null;
    }
    const sub = required ? subRequirement.isRequired : subRequirement;
    return sub(props, key, ...rest);
  };
  const fn = check.bind(null, false);
  fn.isRequired = check.bind(null, true);
  return fn;
}
