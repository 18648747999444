import { useState, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import type Action from 'lib/jsonApi/Action';
import { useAppDispatch } from 'hooks/reduxHooks';
import type { Link } from 'types/json-api-types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { GenericActionPayload } from 'types/redux-types';

type UseDebouncedSearchArgs<Params, Query> = {
  debounceSpeed?: number;
  request?: ActionCreatorWithPayload<GenericActionPayload<Params, Query>>;
  link?: Link;
  action?: Action;
  query?: Query;
  params?: Params;
  onSuccess?: (args?: any) => void;
  onError?: (args?: any) => void;
};

export const useDebouncedSearch = <Params, Query>({ debounceSpeed, request, link, action, query, params, onSuccess, onError, ...rest }: UseDebouncedSearchArgs<Params, Query>) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const ourQ = useRef<Query | null>(query ?? null);
  const ourP = useRef<Params | null>(params ?? null);

  const determineIsNew = <T extends Record<string, any>,>(prev: T, curr: T) => {
    let isNew = false;
    if ((Object.keys(prev).length === 0 && Object.keys(curr).length > 0)
    || (Object.keys(curr).length === 0 && Object.keys(prev).length > 0)
    ) {
      return true;
    }
    Object.keys(prev).forEach((key1) => {
      Object.keys(curr).forEach((key2) => {
        if ((curr[key1] === undefined && key1 !== 'page')
        || (prev[key2] === undefined && key2 !== 'page')
        || (curr[key1] !== prev[key1] && key1 !== 'page')
        || (curr[key2] !== prev[key2] && key2 !== 'page')
        || (key1 === 'page' && (curr[key1] > 1 || prev[key1] > 1) && curr[key1] !== prev[key1])
        || (key2 === 'page' && (curr[key2] > 1 || prev[key2] > 1) && curr[key2] !== prev[key2])
        ) {
          isNew = true;
        }
      });
    });
    return isNew;
  };
  if (query && ourQ.current) {
    const isNew = determineIsNew(ourQ.current, query);
    if (isNew) {
      ourQ.current = query;
    }
  }
  if (params && ourP.current) {
    const isNew = determineIsNew(ourP.current, params);
    if (isNew) {
      ourP.current = params;
    }
  }
  const internalRequestResource = debounce(
    (search) => {
      if (request) {
        const requestParams = {
          query: { ...ourQ.current, ...(!!search && search) },
          params: { ...ourP.current, ...(!!search && search) },
          onSuccess: (res: any) => {
            const { data } = res ?? {};
            setLoading(false);
            if (onSuccess) {
              onSuccess({ data });
            }
          },
          onError: (res: any) => {
            const { error } = res ?? {};
            setLoading(false);
            if (onError) {
              onError({ error });
            }
          },
          ...rest,
        }

        if (link) {
          dispatch(request({ ...requestParams, link }));
        } else if (action) {
          dispatch(request({ ...requestParams, action }));
        } else {
          dispatch(request({ ...requestParams }));
        }

      }
    },
    debounceSpeed ?? 500,
  );
  const requestResource = useCallback(
    (search?: string) => {
      setLoading(true);
      internalRequestResource.cancel();
      internalRequestResource({
        ...search && { search },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, dispatch, link, onError, onSuccess, ourP.current, ourQ.current, request],
  );
  return { loading, requestResource };
};
