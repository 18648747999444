import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setTutorial } from 'rdx/modules/tutorials/slice';

function* getTutorials({ payload: { action, params = {} } }) {
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (success && data) {
    yield put(setTutorial(data));
  }
  if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getTutorials;
