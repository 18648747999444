import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import MainNavBar from 'containers/Main/MainNavBar';
import { Container, DocumentContainer, LoginLink, Title } from 'components/SystemDocument';
import MarkdownHtml from 'components/MarkdownHtml';
import LoadingCircle from 'components/Loading/LoadingCircle';
import { getAuthToken } from 'lib/utils/authHelpers';

const THIRDPARTY_S3_LINK = 'https://bdrc-public-aws-flowjo-prod01.s3.us-west-2.amazonaws.com/static/thirdparty.txt';
// const THIRDPARTY_S3_PUBLIC_LINK = 'https://au-bd-rc-public.s3.amazonaws.com/static/thirdparty.txt';

const ThirdParty = () => {
  const history = useHistory();
  const authToken = getAuthToken();
  const [thirdPartyDocument, setThirdPartyDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchThirdParty = async () => {
    const response = await fetch(THIRDPARTY_S3_LINK);
    const text = await response.text();
    setIsLoading(false);
    setThirdPartyDocument(text);
  };

  useEffect(() => {
    fetchThirdParty();
  }, []);

  return (
    <>
      {authToken && <MainNavBar />}
      <Container>
        <DocumentContainer>
          <Title>Third Party Dependencies</Title>
          {isLoading ? (
            <LoadingCircle />
          ) : (
            <MarkdownHtml dangerouslySetInnerHTML={{ __html: thirdPartyDocument }} />
          )}
        </DocumentContainer>
        <LoginLink
          onClick={() => history.push(authToken ? '/' : '/login')}
        >
          Back to {authToken ? 'Home' : 'Login'}
        </LoginLink>
      </Container>
    </>
  );
};

export default ThirdParty;
