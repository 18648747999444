import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import type ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';

const requestChorusExperiments = createAction('requestChorusExperiments');

interface ChorusExperimentsState {
  chorusExperiments: ResourceList | null,
}

const initialState: ChorusExperimentsState = {
  chorusExperiments: null,
};

export const chorusExperimentsSlice = createSlice({
  name: 'chorusExperiments',
  initialState,
  reducers: {
    setChorusExperiments: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.chorusExperiments = state.chorusExperiments?.mergeWith(action.payload) || state.chorusExperiments;
      } else {
        state.chorusExperiments = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setChorusExperiments,
} = chorusExperimentsSlice.actions;

// Selectors
export const getChorusExperiments = (state: RootState) => state.chorusExperiments.chorusExperiments;

// Actions
export {
  setChorusExperiments,
  requestChorusExperiments,
};

export default chorusExperimentsSlice.reducer;
