import { Entries, FormFields } from 'hooks/useForm/helpers';
import type { FormState } from 'hooks/useForm/types';

export const FIELD_DISPATCH_TYPES = {
  INIT_ALL: 'init-all',
  RESET: 'reset',
  SET_VALUE: 'set-value',
  SET_ERRORS: 'set-errors',
  SET_TOUCHED: 'set-touched',
  SET_VISITED: 'set-visited',
  SET_CHANGED: 'set-changed',
} as const;

type FieldInitAll<FormValues> = { type: typeof FIELD_DISPATCH_TYPES.INIT_ALL, payload: { fields: FormFields<FormValues> } };
type FieldReset<FormValues> = { type: typeof FIELD_DISPATCH_TYPES.RESET, payload: { field: keyof FormValues, initialValue: FormValues[keyof FormValues] } };
type FieldSetValue<FormValues> = { type: typeof FIELD_DISPATCH_TYPES.SET_VALUE, payload: { field: keyof FormValues, value: FormValues[keyof FormValues] } };
type FieldSetErrors<FormValues> = { type: typeof FIELD_DISPATCH_TYPES.SET_ERRORS, payload: { field: keyof FormValues, errors: string[] } };
type FieldSetTouched<FormValues> = { type: typeof FIELD_DISPATCH_TYPES.SET_TOUCHED, payload: { field: keyof FormValues, touched: boolean } };
type FieldSetVisited<FormValues> = { type: typeof FIELD_DISPATCH_TYPES.SET_VISITED, payload: { field: keyof FormValues, visited: boolean } };
type FieldSetChanged<FormValues> = { type: typeof FIELD_DISPATCH_TYPES.SET_CHANGED, payload: { field: keyof FormValues, value: FormValues[keyof FormValues] } };
export type FieldActions<FormValues> = FieldInitAll<FormValues> | FieldReset<FormValues> | FieldSetValue<FormValues> | FieldSetErrors<FormValues> | FieldSetTouched<FormValues> | FieldSetVisited<FormValues> | FieldSetChanged<FormValues>;

export function fieldReducer<FormValues>(state: FormState<FormValues>, { type, payload }: FieldActions<FormValues>) {
  if (type === FIELD_DISPATCH_TYPES.INIT_ALL) {
    return initializeFields<FormValues>(payload.fields);
  }
  const field = payload?.field;
  if (!field) return state;
  switch (type) {
    case FIELD_DISPATCH_TYPES.RESET:
      return {
        ...state,
        [field]: fieldDefaultState<FormValues[keyof FormValues]>(payload.initialValue),
      };
    case FIELD_DISPATCH_TYPES.SET_VALUE:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          value: payload.value,
          changed: payload.value !== state[field].value,
        },
      };
    case FIELD_DISPATCH_TYPES.SET_ERRORS:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          errors: payload.errors,
        },
      };
    case FIELD_DISPATCH_TYPES.SET_TOUCHED:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          touched: payload.touched,
        },
      };
    case FIELD_DISPATCH_TYPES.SET_VISITED:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          visited: payload.visited,
        },
      };
    case FIELD_DISPATCH_TYPES.SET_CHANGED:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          changed: payload.value !== state[field].value,
        },
      };
    default:
      return state;
  }
}

export function initializeFields<FormValues>(fields: FormFields<FormValues>) {
  const state: FormState<FormValues> = {} as FormState<FormValues>;
  const entries = Object.entries(fields) as Entries<typeof fields>;
  entries.forEach(([field, { initialValue }]) => {
    const initVal = initialValue;
    state[field] = fieldDefaultState<typeof initVal>(initVal);
  });
  return state;
}

function fieldDefaultState<Value>(initialValue: Value) {
  return {
    value: initialValue,
    errors: [],
    touched: false,
    visited: false,
    changed: false,
  };
}
