import { useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import globals from 'styles/globals';
import { useDispatch } from 'react-redux';

import { useModal } from 'hooks/useModal';
import ConfirmModal from 'containers/Modals/ConfirmModal';
import { Switch, Route, Redirect, NavLink, useLocation } from 'react-router-dom';
import bdrcLogo from 'assets/bd_research_cloud.png';
import { getAuthToken } from 'lib/utils/authHelpers';
import { getRedirectUrl, getSession, requestLogout } from 'rdx/modules/auth/slice';
import { useAppSelector } from 'hooks/reduxHooks';
import { getIsMobile } from 'rdx/modules/app/slice';
import Login from './Login';
import SignUp from './SignUp';
import ConfirmInvite from './ConfirmInvite';
import ForgotPass from './ForgotPass';
import ResetPassword from './ResetPassword';
import BDSSO from './BDSSO';
import { useMixpanel } from 'hooks/useMixpanel';

const Auth = () => {
  const location = useLocation();
  const session = useAppSelector(getSession);
  const redirectUrl = useAppSelector(getRedirectUrl);
  const isMobile = useAppSelector(getIsMobile);
  const authToken = getAuthToken();
  const mixpanel = useMixpanel();

  const dispatch = useDispatch();
  const cookiesEnabled = navigator.cookieEnabled;
  const { callModal, closeModal } = useModal();
  const signupAction = session?.getAction?.('signup');
  const { bd_sso_url } = session?.links ?? {};
  const bd_login_key = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('bd_login_key');
  }, [location.search]);

  useEffect(() => {
    if (!cookiesEnabled) {
      setTimeout(() => {
        const kName = 'cookie-warning';
        callModal(<ConfirmModal
          key={kName}
          message="Cookies Disabled"
          onConfirm={() => closeModal()}
          noCancel
          width="400px"
          explanation="Oops! Looks like your cookies are disabled. In order to have the best experience possible, please allow cookies for bdresearchcloud.com in your browser settings."
        />);
      }, 200);
    }
  }, []); // eslint-disable-line

  if (authToken) {
    // if invite url and logged in
    if (location?.pathname === '/invite' && location?.search.includes('?token=')) {
      dispatch(requestLogout({ onSuccess : () => {
        mixpanel?.track('Logout')
        mixpanel?.reset();
      }}));
      return null;
    }
    return <Redirect to={redirectUrl ?? '/'} />;
  }

  if (bd_sso_url && bd_login_key && location.pathname === '/') {
    return <BDSSO bd_login_key={bd_login_key} />;
  }

  return (
    <Wrapper>
      <MainContainer isMobile={isMobile}>
        <HomeLink to="/">
          <BDRCLogo isMobile={isMobile} src={bdrcLogo} alt="BD Research Cloud" />
        </HomeLink>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/sign-up">
            {signupAction ? (
              <SignUp />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/invite">
            <ConfirmInvite />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPass />
          </Route>
          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>
        </Switch>
        <Disclaimer>
          For Research Use Only. Not for use in diagnostic or therapeutic procedures.
        </Disclaimer>
      </MainContainer>
    </Wrapper>
  );
};

export default Auth;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MainContainer = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) => (isMobile ? css`
    max-width: 100%;
    padding: 20px;
  ` : css`
    display: flex;
    flex-direction: column;
    padding: 80px 70px 40px 70px;
    width: ${globals.layout.loginContainerWidth};
    min-width: fit-content;
    z-index: 1;
    transition: all 1s ease;
    & > * {
      flex: 1;
    }
  `)}
`;

const HomeLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  &:hover {
    text-decoration: none;
  }
`;

const BDRCLogo = styled.img<{ isMobile?: boolean }>`
  margin-top: 30px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '300px')};
`;

const Disclaimer = styled.div`
  width: 100%;
  max-width: ${globals.layout.loginContainerWidth};
  margin: 18px auto 0 auto;
  font-size: 8pt;
  color: ${globals.colors.darkerGrey};
`;
