import { forwardRef } from 'react';

import createIcon, { IconProps } from '../createIcon';

const DownloadIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg ref={ref} {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M16.41 12.5l-1.41-1.41-2 2v-11.090h-2v11.090l-2-2-1.41 1.41 4.41 4.41 4.41-4.41z"></path>
    <path d="M20 15v4c0 0.552-0.448 1-1 1v0h-14c-0.552 0-1-0.448-1-1v0-4h-2v4c0 1.657 1.343 3 3 3v0h14c1.657 0 3-1.343 3-3v0-4z"></path>
  </svg>
));

DownloadIcon.displayName = 'DownloadIcon';

const CreatedIcon = createIcon(DownloadIcon);

export default CreatedIcon;
