import React from 'react';
import { useEventListener } from 'hooks/useEventListener';

export const useClientDimensions = () => {
  const [dimensions, setDimensions] = React.useState({
    width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
    height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  });

  const updateSize = React.useCallback(() => {
    setDimensions({
      width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
      height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    });
  }, []);

  useEventListener('resize', updateSize);

  return dimensions;
};

export default useClientDimensions;
