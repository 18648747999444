import React from 'react';
// import PropTypes from 'prop-types';
// import customPropTypes from 'lib/customPropTypes';
import styled from 'styled-components';
import globals from 'styles/globals';

import SplashBackground from 'containers/SplashBackground';

const UnsupportedBrowser = () => (
  <SplashBackground>
    <MainContainer>
      <HeaderContainer>
        <Header>Unsupported Browser Detected</Header>
        <SubHeader>It looks like you are using an unsupported broswer.</SubHeader>
        <SubHeader>BD Research Cloud currently supports the latest versions of: </SubHeader>
        <SupportedContainer>
          <BulletItem><Link rel="noopener noreferrer" target="_blank" href="https://www.google.com/chrome/">Google Chrome</Link></BulletItem>
          <BulletItem><Link rel="noopener noreferrer" target="_blank" href="https://support.apple.com/downloads/safari">Apple Safari</Link></BulletItem>
          <BulletItem><Link rel="noopener noreferrer" target="_blank" href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</Link></BulletItem>
        </SupportedContainer>
      </HeaderContainer>
    </MainContainer>
  </SplashBackground>
);

UnsupportedBrowser.propTypes = {};

UnsupportedBrowser.defaultProps = {};

export default UnsupportedBrowser;

const { colors, fonts } = globals;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 70px;
  position: relative;
  background-color: ${colors.white};
  width: 600px;
  height: 320px;
  border-radius: 3px;
  box-shadow: 0.2em 0.2em 0.6em 0.2em rgba(0,0,0,0.3);
  z-index: 1;
  transition: all 1s ease;
`;

const SupportedContainer = styled.ul``;

const BulletItem = styled.li`
  font: ${fonts.body};
  font-weight: 300;
  color: ${colors.linkGrey};
  padding-top: 10px;
`;

const Link = styled.a`
  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Header = styled.div`
  font: ${fonts.formTitle};
  color: ${colors.fontDark};
`;

const SubHeader = styled.div`
  font: ${fonts.body};
  font-weight: 300;
  color: ${colors.linkGrey};
  padding-top: 5px;
  padding-left: 5px;
`;
