import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import postProcedureSaga from 'rdx/modules/procedures/sagas/postProcedure';
import patchProcedureSaga from 'rdx/modules/procedures/sagas/patchProcedure';
import getProcedures from 'rdx/modules/procedures/sagas/getProcedures';

import requestSaga from 'rdx/api-utils/requestSaga';
import { deleteProcedure, patchProcedure, postProcedure, repositionSteps, requestProcedureDetail, requestProcedures, setProcedureDetail } from 'rdx/modules/procedures/slice';

function* watchProceduresSagas() {
  yield trackRequests(takeEvery, requestProcedures.type, getProcedures);
  yield trackRequests(takeEvery, patchProcedure.type, patchProcedureSaga);
  yield trackRequests(takeEvery, deleteProcedure.type, (rdxAction) => requestSaga({ rdxAction, message: 'Procedure Deleted' }));
  yield trackRequests(takeEvery, postProcedure.type, postProcedureSaga);
  yield trackRequests(takeEvery, repositionSteps.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, requestProcedureDetail.type, (rdxAction) => requestSaga({ rdxAction, putData: setProcedureDetail }));
}

export default watchProceduresSagas;
