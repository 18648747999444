import React from 'react';

const MessagesContext = React.createContext<{
  connected: boolean,
  socketId: string | null,
  emitMessage:((message: any) => Promise<void>) | null,
  error: any,
    }>({
      connected: false,
      socketId: null,
      emitMessage: null,
      error: null,
    });

MessagesContext.displayName = 'MessagesContext';

export default MessagesContext;
