import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import type { RootState } from 'index';
import { GenericActionPayload } from 'types/redux-types';
import type { AntigenCategory, ReagentAttributes } from 'types/reagents';
import { NormalizedSpectralMatrix } from '../spectrumViewer/slice';

interface ReagentsSliceState {
  reagents: ResourceList<ReagentAttributes>;
  reagent: ResourceList;
  inventoryReagents: ResourceList;
  inventoryReagent: ResourceList | null;
  reagentEvents: ResourceList;
  spectralData: ResourceList | null;
  normalizedSpectralMatrix: NormalizedSpectralMatrix;
}

const initialNormalizedSpectralMatrix: NormalizedSpectralMatrix = {};

export type AddReagentToWishlistParams = {
  category: AntigenCategory,
  catalog_number: string,
  antigen: string,
  fluorochrome: string,
  species?: string[],
  antibody?: string,
  brand?: string,
  size?: string,
};

const initialState: ReagentsSliceState = {
  reagents: new ResourceList(),
  reagent: new ResourceList(),
  inventoryReagents: new ResourceList(),
  inventoryReagent: new ResourceList(),
  reagentEvents: new ResourceList(),
  spectralData: new ResourceList(),
  normalizedSpectralMatrix: initialNormalizedSpectralMatrix,
};

type CreateReagentParams = {
  variant?: string | null,
  alt_names?: string[],
  brand: string,
  brand_note?: string | null,
  species: string[],
  host_specie?: string | null,
  isotype?: string | null,
  clone?: string | null,
  fluorochromes: string[],
  antigen: string,
  size?: string | null,
  description?: string | null,
  confirm?: boolean | null,
}

type PatchReagentParams = CreateReagentParams & {
  variant: string,
}

export type RequestInventoryReagentsQuery = {
  pageSize?: number,
  page?: number,
  sorting?: string,
  'search[]'?: string[],
  group_id?: number,
}

export type RequestReagentsQuery = {
  pageSize?: number,
  page?: number,
  sorting?: string,
  'search[]'?: string[],
  import_type?: string,
  ids?: string,
  organization_id?: string,
}

const requestReagents = createAction<GenericActionPayload<RequestReagentsQuery>>('reagents/requestReagents');
const requestReagent = createAction('reagents/requestReagent');
const requestInventoryReagents = createAction<GenericActionPayload<RequestInventoryReagentsQuery>>('reagents/requestInventoryReagents');
const requestInventoryReagent = createAction('reagents/requestInventoryReagent');
const postReagent = createAction<GenericActionPayload<CreateReagentParams> & { import_type?: 'bd' | 'custom' }>('reagents/postReagent');
const patchReagent = createAction<GenericActionPayload<PatchReagentParams> & { import_type?: 'bd' | 'custom' }>('reagents/patchReagent');
const deleteReagent = createAction<GenericActionPayload & { import_type?: 'bd' | 'custom' }>('reagents/deleteReagent');
const postInventoryReagent = createAction('reagents/postInventoryReagent');
const patchInventoryReagent = createAction('reagents/patchInventoryReagent');
const deleteInventoryReagent = createAction('reagents/deleteInventoryReagent');
const requestSpectralData = createAction('reagents/requestSpectralData');
const requestReagentEvents = createAction('reagents/requestReagentEvents');
const exportReagents = createAction('reagents/exportReagents');

const addReagentToWishlist = createAction<GenericActionPayload<AddReagentToWishlistParams>>('reagents/addReagentToWishlist');

// Reducers
const reagentsSlice = createSlice({
  name: 'reagents',
  initialState,
  reducers: {
    setReagents: (state, action: PayloadAction<ResourceList<ReagentAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.reagents = state.reagents.mergeWith(action.payload);
      } else {
        state.reagents = action.payload;
      }
    },
    setReagent: (state, action: PayloadAction<ResourceList>) => {
      state.reagent = action.payload;
    },
    setInventoryReagents: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.inventoryReagents = state.inventoryReagents.mergeWith(action.payload);
      } else {
        state.inventoryReagents = action.payload;
      }
    },
    setInventoryReagent: (state, action: PayloadAction<ResourceList | null>) => {
      state.inventoryReagent = action.payload;
    },
    setSpectralData: (state, action: PayloadAction<ResourceList | null>) => {
      state.spectralData = action.payload;
    },
    setNormalizedSpectralData: (state, action: PayloadAction<NormalizedSpectralMatrix>) => {
      state.normalizedSpectralMatrix = action.payload;
    },
    setReagentEvents: (state, action: PayloadAction<ResourceList>) => {
      state.reagentEvents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setReagents,
  setReagent,
  setInventoryReagents,
  setInventoryReagent,
  setSpectralData,
  setNormalizedSpectralData,
  setReagentEvents,
} = reagentsSlice.actions;

// Actions
export {
  setReagents,
  setReagent,
  setInventoryReagents,
  setInventoryReagent,
  setSpectralData,
  setNormalizedSpectralData,
  setReagentEvents,
  requestReagents,
  requestReagent,
  requestInventoryReagents,
  requestInventoryReagent,
  postReagent,
  patchReagent,
  deleteReagent,
  postInventoryReagent,
  patchInventoryReagent,
  deleteInventoryReagent,
  requestSpectralData,
  requestReagentEvents,
  exportReagents,
  addReagentToWishlist,
};

// Selectors
export const getReagents = (state: RootState) => state.reagents.reagents;
export const getReagent = (state: RootState) => state.reagents.reagent;
export const getInventoryReagents = (state: RootState) => state.reagents.inventoryReagents;
export const getInventoryReagent = (state: RootState) => state.reagents.inventoryReagent;
export const getSpectralData = (state: RootState) => state.reagents.spectralData;
export const getNormalizedSpectralData = (state: RootState) => state.reagents.normalizedSpectralMatrix;
export const getReagentEvents = (state: RootState) => state.reagents.reagentEvents;

export default reagentsSlice.reducer;
