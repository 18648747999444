import type { PrimaryButtonProps } from 'components/Button/PrimaryButton';
import PrimaryButton from 'components/Button/PrimaryButton';
import ToolTip from 'components/ToolTip';
import { usePermissions } from 'hooks/usePermissions';
import { useTooltip } from 'hooks/useTooltip';

export interface NavHeaderButtonProps extends PrimaryButtonProps {
  id?: string,
  component?: React.ReactNode,
  tooltip?: string,
  permissions?: string[],
  permissionType?: 'organization' | 'group',
  groupID?: number,
  overridePermissionRequirements?: boolean,
}

const NavHeaderButton = ({ id, component, tooltip, ...rest }: NavHeaderButtonProps) => {
  const { callTooltip, closeTooltip } = useTooltip();

  const { permissions, permissionType, onClick, groupID, ...buttonProps } = rest;

  const { locked, permissionClick } = usePermissions<typeof onClick>({
    permissions,
    permissionType,
    clickHandler: onClick,
    groupID,
  });

  if (component) {
    return component;
  }

  return tooltip ? (
    <PrimaryButton
      key={id}
      id={id}
      style={{ marginLeft: '10px', width: '125px' }}
      data-testid={`nav-button-${id}`}
      onMouseOver={(e) => callTooltip(<ToolTip message={tooltip} child={e} key={id} />)}
      onFocus={(e) => callTooltip(<ToolTip message={tooltip} child={e} key={id} />)}
      onBlur={closeTooltip}
      onMouseLeave={closeTooltip}
      locked={locked}
      onClick={permissionClick}
      {...buttonProps}
    />
  ) : (
    <PrimaryButton
      key={id}
      id={id}
      style={{ marginLeft: '10px', width: '125px' }}
      data-testid={`nav-button-${id}`}
      locked={locked}
      onClick={permissionClick}
      {...buttonProps}
    />
  );
};

export default NavHeaderButton;
