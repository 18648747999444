import { createSlice, createAction } from '@reduxjs/toolkit';
import { resetStore } from 'rdx/modules/app/slice';

const initialState = {
  links: null,
  linkPayload: null,
};

const requestLinkedResources = createAction('requestLinkedResources');
const createLinkedResources = createAction('createLinkedResources');
const deleteLinkedResources = createAction('deleteLinkedResources');

export const linkedResourcesSlice = createSlice({
  name: 'linkedResources',
  initialState,
  reducers: {
    setLinkedResources: (state, action) => {
      if (action.payload.meta.pagination.page > 1) {
        return {
          ...state,
          links: state.mergeWith(action.payload),
        };
      }
      return {
        ...state,
        links: action.payload,
      };
    },
    startNewLink: (state, action) => ({
      ...state,
      linkPayload: action.payload,
    }),
    endNewLink: (state) => ({ ...state, linkPayload: null }),
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setLinkedResources,
  startNewLink,
  endNewLink,
} = linkedResourcesSlice.actions;

// Actions
export {
  requestLinkedResources,
  createLinkedResources,
  deleteLinkedResources,
  setLinkedResources,
  startNewLink,
  endNewLink,
};

// Selectors
export const getLinkedResources = (state) => state.linkedResources.links;
export const getResourceLinkPayload = (state) => state.linkedResources.linkPayload;

// Reducer
export default linkedResourcesSlice.reducer;
