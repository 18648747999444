import { ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import ClickableDiv from 'components/ClickableDiv/index';

interface TextProps extends React.ComponentPropsWithoutRef<'label'> {
  textWidth?: string,
  textTransform?: string,
  font?: string,
  fontSize?: string,
  alignLeft?: boolean,
  disabled?: boolean,
}

export type SwitchProps = {
  text?: ReactNode | ReactNode[],
  textPosition?: string,
  textWidth?: string | null,
  value: boolean,
  onChange: (value: boolean) => void,
  disabled?: boolean,
  fontSize?: string,
  font?: string,
  textProps?: TextProps,
  className?: string,
  readonly?: boolean,
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick' | 'onChange'>;

const Switch = ({
  text,
  textPosition = 'right',
  fontSize = '13px',
  font,
  textProps,
  textWidth = '30px',
  value = false,
  className,
  onChange,
  disabled = false,
  readonly,
  ...inputProps
}: SwitchProps) => {
  const [onText, offText] = useMemo(() => {
    if (Array.isArray(text)) {
      return [text[0], text[1]];
    }
    return [text, text];
  }, [text]);

  const handleToggle = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e?.stopPropagation) {
      e.stopPropagation();
    }
    if (!disabled) {
      onChange(!value);
    }
  };

  const displayText = value ? (onText ?? '') : (offText ?? '');
  return (
    <SwitchContainer className={className ?? ''}>
      {textPosition === 'left' && (
        <SwitchText alignLeft {...{ value, disabled, textWidth, font, fontSize, readonly, ...textProps }}>
          {displayText}
        </SwitchText>
      )}
      <Toggle
        onClick={handleToggle}
        {...{ value, disabled, readonly }}
        {...inputProps}
      >
        <ToggleButton onClick={handleToggle} {...{ value, disabled }} />
      </Toggle>
      {textPosition === 'right' && (
        <SwitchText onClick={handleToggle} {...{ value, disabled, textWidth, font, fontSize, readonly, ...textProps }}>
          {displayText}
        </SwitchText>
      )}
    </SwitchContainer>
  );
};

export default Switch;

const switchHeight = '21px';
const switchWidth = '42px';

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Toggle = styled(ClickableDiv).attrs<{ value: boolean, readonly?: boolean }>(({ value }) => ({
  role: 'checkbox',
  'aria-checked': value,
}))<{ value: boolean, readonly?: boolean }>`
  position: relative;
  height: ${switchHeight};
  width: ${switchWidth};
  background-color: ${({ theme }) => theme.colors.grey90};
  border-radius: ${switchHeight};
  transition: background-color 300ms;
  outline: none;

  ${({ value }) => value && css`
    background-color: ${({ theme }) => theme.colors.primary};
  `};
  ${({ readonly }) => readonly && css`
    pointer-events: none;
  `}
`;

const ToggleButton = styled.div<{ value: boolean }>`
  position: absolute;
  left: 0px;
  height: ${switchHeight};
  width: ${switchHeight};
  border: 1px solid ${({ theme }) => theme.colors.grey96};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  box-shadow: 1px 1px 15px 1px rgba(200, 200, 200, 0.7);
  transition: left 300ms;

  ${({ value }) => value && css`
    left: calc(calc(${switchWidth} - ${switchHeight}) + 1px);
  `};
`;

type SwitchTextProps = {
  textWidth: string | null,
  textTransform?: string,
  font?: string,
  fontSize: string,
  alignLeft?: boolean,
  disabled?: boolean,
  readonly?: boolean,
};

const SwitchText = styled.label<SwitchTextProps>`
  color: ${({ theme }) => (theme.colors.primary)};
  cursor: pointer;
  font-size: 13px;
  width: ${({ textWidth }) => textWidth};
  text-transform: ${({ textTransform }) => textTransform ?? 'uppercase'};
  ${({ font }) => font && css`font: ${font}`};
  font-size: ${({ fontSize }) => fontSize};
  ${({ alignLeft }) => (alignLeft
    ? css`
      text-align: right;
      margin-right: 10px;
    `
    : css`
      margin-left: 10px;
    `
  )}
  ${({ readonly }) => readonly && css`
    pointer-events: none;
  `}
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.8;
    filter: grayscale(80%);
  `}
`;
