import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setReleaseNotes } from 'rdx/modules/systemDocs/slice';
import { getSessionLinks } from 'rdx/modules/auth/slice';

function* getReleaseNotes({ payload }) {
  const sessionLinks = yield select(getSessionLinks);
  const link = sessionLinks.release_notes;
  const { query } = payload;
  const { success, data, error } = yield* makeRequest.link(link, {}, { ...query, pageSize: 1 });
  if (success && data) {
    yield put(setReleaseNotes(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getReleaseNotes;
