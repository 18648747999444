import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { addChannel } from '../slice';

function* getChannelDetail({ payload: link }) {
  const { success, data, error } = yield makeRequest.link(link);
  if (success && data) {
    yield put(addChannel(data));
  }
  if (error) {
    yield* putErrorActions({ error, target: 'messages' });
  }
  return null;
}

export default getChannelDetail;
