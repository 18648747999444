/* eslint-disable no-unused-vars */
const checkSupport = () => {
  try {
    // test proxies
    const objWithStuff = {
      attributes: {
        name: 'proxy',
      },
      included: {
        athing: 'blah',
      },
    };
    const proxy = new Proxy(objWithStuff, {
      get: (obj, prop) => {
        if (obj.attributes[prop]) {
          return obj.attributes[prop];
        }
        return undefined;
      },
    });
    const theName = proxy.name;

    // test string.matchAll exists or has been polyfilled
    const str = 'aabc';
    const globalRegex = /[ac]/g;

    const test = [...str.matchAll(globalRegex)];
  } catch (e) {
    return false;
  }
  return true;
};

export default checkSupport;
