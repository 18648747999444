import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import { requestGatedFeatureBlurb, setGatedFeatureBlurb } from 'rdx/modules/gatedFeatureBlurbs/slice';
import requestSaga from 'rdx/api-utils/requestSaga';

function* watchGatedFeatureBlurbsSagas() {
  yield trackRequests(takeLatest, requestGatedFeatureBlurb.type, (rdxAction) => requestSaga({ rdxAction, putData: setGatedFeatureBlurb }));
}

export default watchGatedFeatureBlurbsSagas;
