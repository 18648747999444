import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import requestProjectsSaga from 'rdx/modules/projects/sagas/requestProjects';
import requestProjectsByGroupSaga from 'rdx/modules/projects/sagas/requestProjectsByGroup';
import createProjectSaga from 'rdx/modules/projects/sagas/createProject';
import updateProjectSaga from 'rdx/modules/projects/sagas/updateProject';
import deleteProjectSaga from 'rdx/modules/projects/sagas/deleteProject';
import createStudySaga from 'rdx/modules/projects/sagas/createStudy';
import updateStudySaga from 'rdx/modules/projects/sagas/updateStudy';
import deleteStudySaga from 'rdx/modules/projects/sagas/deleteStudy';
import requestMoreProjectsSaga from 'rdx/modules/projects/sagas/requestMoreProjects';
import { requestProjects, requestProjectsByGroup, createProject, updateProject, deleteProject, createProjectStudy, updateProjectStudy, deleteProjectStudy, requestMoreProjects } from '../slice';

function* watchProjectsSagas() {
  yield trackRequests(takeEvery, requestProjects.type, requestProjectsSaga);
  yield trackRequests(takeEvery, requestProjectsByGroup.type, requestProjectsByGroupSaga);
  yield trackRequests(takeEvery, createProject.type, createProjectSaga);
  yield trackRequests(takeEvery, updateProject.type, updateProjectSaga);
  yield trackRequests(takeEvery, deleteProject.type, deleteProjectSaga);
  yield trackRequests(takeEvery, createProjectStudy.type, createStudySaga);
  yield trackRequests(takeEvery, updateProjectStudy.type, updateStudySaga);
  yield trackRequests(takeEvery, deleteProjectStudy.type, deleteStudySaga);
  yield trackRequests(takeEvery, requestMoreProjects.type, requestMoreProjectsSaga);
}

export default watchProjectsSagas;
