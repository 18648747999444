import styled from 'styled-components';
import globals from 'styles/globals';

const { fonts, colors } = globals;

// eslint-disable-next-line import/prefer-default-export
export const BackText = styled.span`
  outline: none;
  background: none;
  border: none;
  flex: 1;
  font: ${fonts.flag};
  color: ${colors.primary};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
