import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* deleteCytometer({ payload }) {
  const { success, data, error } = yield* makeRequest.action(payload.action, payload.values);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Cytometer Deleted' }));
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteCytometer;
