/* eslint-disable import/prefer-default-export */
import { useEffect, useMemo, useRef } from 'react';

export const useEventListener = (eventName, handler, element) => {
  const ourElement = useMemo(() => {
    if (element === null) {
      return null;
    }
    return element ?? window;
  }, [element]);
  const handlerRef = useRef();

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = ourElement && ourElement.addEventListener;
    if (!isSupported) return;

    const listener = (event) => handlerRef.current(event);
    ourElement.addEventListener(eventName, listener);
    return () => { // eslint-disable-line consistent-return
      ourElement.removeEventListener(eventName, listener);
    };
  }, [eventName, element, ourElement]);
};
