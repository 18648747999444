import { takeEvery, takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import searchViabilityDyesSaga from 'rdx/modules/system/sagas/searchViabilityDyes';
import requestAntigenDataSaga from 'rdx/modules/system/sagas/requestAntigenData';
import getSpeciesSaga from 'rdx/modules/system/sagas/getSpecies';
import requestSaga from 'rdx/api-utils/requestSaga';
import { createSubset, deleteSubset, requestAntigenData, requestBDImmuneSubsets, requestCustomImmuneSubsets, requestImmuneSubsets, requestImmuneSubsetTags, requestSearchSubsets, requestSessionImmuneSubsets, requestSpecies, searchAntigens, searchViabilityDyes, setBDImmuneSubsets, setCustomImmuneSubsets, setImmuneSubsets, setImmuneSubsetTags, setSearchAntigens, setSearchSubsets, updateSubset } from 'rdx/modules/system/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* watchSystemSagas() {
  yield trackRequests(takeLatest, searchAntigens.type, (rdxAction) => requestSaga({ rdxAction, putData: setSearchAntigens }));
  yield trackRequests(takeLatest, searchViabilityDyes.type, searchViabilityDyesSaga);
  yield trackRequests<PayloadActionWithGenericPayload & { payload: { append: boolean }}>(takeEvery, requestAntigenData.type, requestAntigenDataSaga);
  yield trackRequests(takeEvery, requestSpecies.type, getSpeciesSaga);
  yield trackRequests(takeLatest, requestImmuneSubsets.type, (rdxAction) => requestSaga({ rdxAction, putData: setImmuneSubsets }));
  yield trackRequests(takeLatest, requestSessionImmuneSubsets.type, (rdxAction) => requestSaga({ rdxAction, putData: setImmuneSubsets }));
  yield trackRequests(takeLatest, requestCustomImmuneSubsets.type, (rdxAction) => requestSaga({ rdxAction, putData: setCustomImmuneSubsets }));
  yield trackRequests(takeLatest, requestBDImmuneSubsets.type, (rdxAction) => requestSaga({ rdxAction, putData: setBDImmuneSubsets }));
  yield trackRequests(takeLatest, requestSearchSubsets.type, (rdxAction) => requestSaga({ rdxAction, putData: setSearchSubsets }));
  yield trackRequests(takeEvery, createSubset.type, (rdxAction) => requestSaga({ rdxAction, message: `Created Custom Population ${rdxAction?.payload?.params?.name ?? ''}` }));
  yield trackRequests(takeEvery, updateSubset.type, (rdxAction) => requestSaga({ rdxAction, message: `Updated Custom Population ${rdxAction?.payload?.params?.name ?? ''}` }));
  yield trackRequests(takeEvery, deleteSubset.type, (rdxAction) => requestSaga({ rdxAction, message: 'Deleted Custom Population' }));
  yield trackRequests(takeEvery, requestImmuneSubsetTags.type, (rdxAction) => requestSaga({ rdxAction, putData: setImmuneSubsetTags }));
}

export default watchSystemSagas;
