import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';

type DrawerPayload = {
  component: React.FC | null,
  urlInfo?: Record<string, string>,
};

type DrawerState = DrawerPayload & {
  isOpen: boolean,
};

type DrawersSliceState = {
  primary: DrawerState,
  secondary: DrawerState
};

const initialState: DrawersSliceState = {
  primary: {
    isOpen: false,
    component: null,
  },
  secondary: {
    isOpen: false,
    component: null,
  },
};

export const drawersSlice = createSlice({
  name: 'drawers',
  initialState,
  reducers: {
    callDrawer: (state, action: PayloadAction<DrawerPayload>) => {
      state.primary.component = action.payload.component;
      state.primary.urlInfo = action.payload.urlInfo;
      state.primary.isOpen = true;
    },
    setDrawer: (state, action: PayloadAction<DrawerPayload>) => {
      state.primary.component = action.payload.component;
      state.primary.urlInfo = action.payload.urlInfo;
    },
    openDrawer: (state) => {
      if (state.primary.component) {
        state.primary.isOpen = true;
      } else if (import.meta.env.DEV) {
        console.warn('Attempted to open a drawer with no component set.');
      }
    },
    closeDrawer: (state) => {
      state.primary.isOpen = false;
    },
    closeAndUnsetDrawer: (state) => {
      state.primary.isOpen = false;
      state.primary.component = null;
    },
    callSecondaryDrawer: (state, action: PayloadAction<DrawerPayload>) => {
      state.secondary.component = action.payload.component;
      state.secondary.urlInfo = action.payload.urlInfo;
      state.secondary.isOpen = true;
    },
    setSecondaryDrawer: (state, action: PayloadAction<DrawerPayload>) => {
      state.secondary.component = action.payload.component;
      state.secondary.urlInfo = action.payload.urlInfo;
    },
    openSecondaryDrawer: (state) => {
      if (state.secondary.component) {
        state.secondary.isOpen = true;
      } else if (import.meta.env.DEV) {
        console.warn('Attempted to open a drawer with no component set.');
      }
    },
    closeSecondaryDrawer: (state) => {
      state.secondary.isOpen = false;
    },
    closeAndUnsetSecondaryDrawer: (state) => {
      state.secondary.isOpen = false;
      state.secondary.component = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  callDrawer,
  setDrawer,
  openDrawer,
  closeDrawer,
  closeAndUnsetDrawer,
  callSecondaryDrawer,
  setSecondaryDrawer,
  openSecondaryDrawer,
  closeSecondaryDrawer,
  closeAndUnsetSecondaryDrawer,
} = drawersSlice.actions;

// Actions
export {
  callDrawer,
  setDrawer,
  openDrawer,
  closeDrawer,
  closeAndUnsetDrawer,
  callSecondaryDrawer,
  setSecondaryDrawer,
  openSecondaryDrawer,
  closeSecondaryDrawer,
  closeAndUnsetSecondaryDrawer,
};

// Selectors
export const getDrawer = (state: RootState) => state.drawers.primary.component;
export const getDrawerUrlInfo = (state: RootState) => state.drawers.primary.urlInfo;
export const getDrawerIsOpen = (state: RootState) => state.drawers.primary.isOpen;
export const getSecondaryDrawer = (state: RootState) => state.drawers.secondary.component;
export const getSecondaryDrawerUrlInfo = (state: RootState) => state.drawers.secondary.urlInfo;
export const getSecondaryDrawerIsOpen = (state: RootState) => state.drawers.secondary.isOpen;

// Reducer
export default drawersSlice.reducer;
