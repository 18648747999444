import { takeEvery } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';

import { requestChorusExperiments, setChorusExperiments } from 'rdx/modules/chorusExperiments/slice';

function* watchChorusExperimentsSagas() {
  yield trackRequests(takeEvery, requestChorusExperiments.type, (rdxAction) => requestSaga({ rdxAction, putData: setChorusExperiments }));
}

export default watchChorusExperimentsSagas;
