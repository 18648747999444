import { put } from 'redux-saga/effects';
import { LOGIN_FORM, RESET_PW } from 'rdx/modules/messages/constants';
import { removeAuthToken } from 'lib/utils/authHelpers';
import { resetStore } from 'rdx/modules/app/slice';
import { newErrorEvent, newApiConfirmEvent } from 'rdx/modules/messages/slice';
import { ErrorActionPayload } from 'types/redux-types';

export function getErrorActions({ error, target, message, jsonApiAction, rdxAction, onSuccess, persist }: ErrorActionPayload) {
  if (error.warning === 'confirm') {
    return [
      newApiConfirmEvent({ error, jsonApiAction, rdxAction, onSuccess }),
    ];
  }
  if (error.meta?.account) {
    return [];
  }
  if (target === LOGIN_FORM) {
    const newErr = { ...error, target };
    if (message) newErr.text = message;
    return [newErrorEvent(newErr)];
  }
  if (target === RESET_PW  && error.text === 'Expired token') {
    removeAuthToken();
    return [
      newErrorEvent({text: "Your password reset link has expired. Please revisit 'Forgot Password' to generate a fresh link.", persist}),
    ];
  }
  if (error.text === 'Expired token' || error.text === 'Invalid credentials') {
    removeAuthToken();
    return [
      resetStore(),
      newErrorEvent(error),
    ];
  }
  const newErr = { ...error, target, persist };
  if (message) newErr.text = message;
  return [newErrorEvent(newErr)];
}

export function* putErrorActions(payload: ErrorActionPayload) {
  const { error, target, message, rdxAction, onSuccess, jsonApiAction, persist } = payload;
  const errActions = getErrorActions({ error, target, message, jsonApiAction, rdxAction, onSuccess, persist });
  for (const errAction of errActions) {
    yield put(errAction);

  }
}

export default putErrorActions;
