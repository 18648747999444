import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';

type TooltipSliceState = {
  isOpen: boolean,
  component: React.ReactElement | null,
};

const initialState: TooltipSliceState = {
  isOpen: false,
  component: null,
};

export const tooltipSlice = createSlice({
  name: 'tooltip',
  initialState,
  reducers: {
    callTooltip: (state, action: PayloadAction<React.ReactElement | null>) => {
      state.component = action.payload;
      state.isOpen = true;
    },
    closeTooltip: (state) => {
      state.isOpen = false;
      state.component = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  callTooltip,
  closeTooltip,
} = tooltipSlice.actions;

// Actions
export {
  callTooltip,
  closeTooltip,
};

// Selectors
export const getTooltip = (state: RootState) => state.tooltip.component;
export const getTooltipIsOpen = (state: RootState) => state.tooltip.isOpen;

// Reducer
export default tooltipSlice.reducer;
