import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const TechSupport = forwardRef<SVGSVGElement, IconProps>(({
  ...svgProps
}, ref) => (
  <svg ref={ref} {...svgProps} id="tech-support-icon" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m487 192.8h-39.267v-17.133c0-97.964-95.56-175.667-191.733-175.667-96.001 0-191.733 77.526-191.733 175.667v17.133h-39.267c-13.785 0-25 11.215-25 25v108.533c0 13.785 11.215 25 25 25h39.267v31.491c0 53.509 43.533 97.042 97.043 97.042h39.523v7.134c0 13.785 11.215 25 25 25h60.334c13.785 0 25-11.215 25-25v-44.267c0-13.785-11.215-25-25-25h-60.334c-13.785 0-25 11.215-25 25v7.133h-39.523c-36.968 0-67.043-30.075-67.043-67.042v-7.391h39.267c13.785 0 25-11.215 25-25v-156.733c0-13.785-11.215-25-25-25h-39.05c4.503-73.448 79.457-138.7 161.516-138.7s157.013 65.252 161.517 138.7h-39.05c-13.785 0-25 11.215-25 25v156.733c0 13.785 11.215 25 25 25h44.267c13.483 0 24.501-10.731 24.977-24.101h39.289c13.785 0 25-11.215 25-25v-108.532c0-13.785-11.215-25-25-25z" /></g></svg>
));

export default createIcon(TechSupport);
