/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

export function useResizeListener(callback) {
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = debounce((e) => {
      const size = {
        width: e.target.innerWidth,
        height: e.target.innerHeight,
      };
      if (callback) {
        callback(size);
      }
      setWindowSize(size);
    }, 50, { leading: true, trailing: true });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
  }, [callback]);

  return windowSize;
}
