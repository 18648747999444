import { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import globals from 'styles/globals';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';
import { Formik, Form, Field } from 'formik';
import FormikInput from 'components/Formik/FormikInput/index';
import ErrorMessage from 'components/Formik/ErrorMessage';
import Link from 'components/Link';
import TechSupport from 'components/TechSupport';
import { getSession, requestLogin } from 'rdx/modules/auth/slice';
import { getLatestLoginMessageEvt } from 'rdx/modules/messages/slice';
import { useMixpanel } from 'hooks/useMixpanel';
import { FieldProps } from 'hooks/useForm/types';
import PrimaryButton from 'components/Button/PrimaryButton';
import { useLoading } from 'hooks/useLoading';

const AuthSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your account email address'),
  password: Yup.string()
    .required('Please enter your password'),
});

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const loginMessage = useAppSelector(getLatestLoginMessageEvt);
  const session = useAppSelector(getSession);
  const mixpanel = useMixpanel();
  const loading = useLoading({ watchRequests: [requestLogin.type]})

  const [rememberMe] = useState(false);

  const signupAction = session?.getAction?.('signup');
  const forgotPassAction = session?.getAction?.('forgot_password');
  /*
   Empty string variable used here to prevent false positives in
   Fortify Security Code Scan for empty password variables.
  */
  const emptyString = '';
  return (
    <FormContainer>
      <Formik
        initialValues={{
          email: emptyString,
          password: emptyString,
        }}
        validationSchema={AuthSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(requestLogin({
            rememberMe,
            params: {
              ...values,
            },
            onSuccess: () => {
              mixpanel?.track('Login', {
                email: values.email,
              });
            },
          }));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form name="login">
            <FieldContainer>
              <Field name="email">
                {({ field }: { field: FieldProps<string> }) => (
                  <FormikInput data-testid="email-input" autoComplete="email" type="text" {...field} backgroundColor={colors.white} height="40px" placeholder="Email" />
                )}
              </Field>
              <ErrorMessageContainer>
                <ErrorMessage name="email" heightAdjust="19px" />
              </ErrorMessageContainer>
            </FieldContainer>
            <FieldContainer>
              <Field name="password">
                {({ field }: { field: FieldProps<string> }) => (
                  <FormikInput data-testid="password-input" type="password" autoComplete="current-password" backgroundColor={colors.white} height="40px" placeholder="Password" {...field} />
                )}
              </Field>
              <ErrorMessageContainer>
                <ErrorMessage name="password" heightAdjust="19px" />
              </ErrorMessageContainer>
              {forgotPassAction && (
                <ForgotPass to="/forgot-password">
                  Forgot Password?
                </ForgotPass>
              )}
            </FieldContainer>
            {/* TODO: Implement "remember me" checkbox by uncommenting below */}
            {/* <Checkbox
              value={rememberMe}
              onChange={() => {
                setRememberMe((prev) => !prev);
              }}
              text="Remember me for 1 hour"
              style={{ fontSize: '13px' }}
            /> */}
            <SubmitButtonContainer>
              <PrimaryButton
                loading={loading}
                disabled={(!isValid || isSubmitting)}
                text="sign in"
                type="submit"
                data-testid="signIn"
                style={{ width: '100%' }}
              />
            </SubmitButtonContainer>
            {signupAction && (
              <SignInHelpText>
                <STechSupport login />
                <SignInLinkText to="/sign-up">Create Account</SignInLinkText>
              </SignInHelpText>
            )}
          </Form>
        )}
      </Formik>
      <FormError>{loginMessage?.text ? loginMessage.text : ''}</FormError>
    </FormContainer>
  );
};

export default LoginForm;

const { colors, fontSizes, fonts } = globals;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  position: relative;
  color: ${colors.fontDark};
`;

const STechSupport = styled(TechSupport)`
`;

const ForgotPass = styled(Link)`
  position: absolute;
  top: 39px;
  right: 0;
  color: ${colors.primary};
  font-size: ${fontSizes.small};
  margin-left: 7px;
  margin-top: 7px;
  display: flex;
  justify-content: center;
`;

const ErrorMessageContainer = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${colors.red};
  font: ${fonts.label};
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
`;

const SignInHelpText = styled.div`
  font-size: ${fontSizes.small};
  color: ${colors.linkGrey};
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
`;

const SignInLinkText = styled(Link)`
  color: ${colors.primary};
`;

const FormError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  text-align: center;
  color: ${colors.red};
  font: ${fonts.label};
  margin: 20px 0;
`;
