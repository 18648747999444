import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { requestLogout } from 'rdx/modules/auth/slice';
import { revokeConsent } from 'rdx/modules/users/slice';

function* revokeConsentSaga({ payload: { action, onSuccess, onError } }) {
  const { success, error } = yield* makeRequest.action(action);
  if (success) {
    yield put(requestLogout());
    onSuccess?.();
  } else {
    onError?.();
    yield* putErrorActions({ error, jsonApiAction: action, rdxAction: revokeConsent });
  }
  return null;
}

export default revokeConsentSaga;
