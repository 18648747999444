import type { FieldOption } from 'types/json-api-types';

export const getFieldOptions = (props: FieldOption[] | FieldOptionsList | Record<string, unknown> = []) => {
  if (Array.isArray(props)) {
    return new FieldOptionsList(props);
  }
  return props;
};

export class FieldOptionsList extends Array<FieldOption> {
  constructor(props: FieldOption[] | FieldOptionsList = []) {
    const options = props?.map ? props.map((option) => {
      if (typeof option === 'string') {
        return option;
      }
      if ('value' in option && 'label' in option) {
        const { value, label } = option;
        return {
          value,
          label: label || value,
        };
      }
      return option;
    }) : [];

    super(...options);
  }

  getByValue = (value: string) => this.find((option) => {
    if (typeof option === 'string') {
      return option === value;
    }
    return option.value === value;
  });

  getByLabel = (label: string) => this.find((option) => {
    if (typeof option === 'string') {
      return option === label;
    }
    return option.label === label;
  });

  getValues = () => Array(...this).map((option) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.value;
  });

  getLabels = () => Array(...this).map((option) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.label;
  });
}
