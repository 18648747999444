import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const PopOut = forwardRef((props, ref) => (
  <svg viewBox="0 0 24 24" ref={ref} {...props}>
    <path d="M5,5.70710678 L5,10.5 C5,10.7761424 4.77614237,11 4.5,11 C4.22385763,11 4,10.7761424 4,10.5 L4,4.5 C4,4.22385763 4.22385763,4 4.5,4 L10.5,4 C10.7761424,4 11,4.22385763 11,4.5 C11,4.77614237 10.7761424,5 10.5,5 L5.70710678,5 L14.8535534,14.1464466 C15.0488155,14.3417088 15.0488155,14.6582912 14.8535534,14.8535534 C14.6582912,15.0488155 14.3417088,15.0488155 14.1464466,14.8535534 L5,5.70710678 Z M19,8 L11.5,8 C11.2238576,8 11,7.77614237 11,7.5 C11,7.22385763 11.2238576,7 11.5,7 L19.5,7 C19.7761424,7 20,7.22385763 20,7.5 L20,19.5 C20,19.7761424 19.7761424,20 19.5,20 L7.5,20 C7.22385763,20 7,19.7761424 7,19.5 L7,11.5 C7,11.2238576 7.22385763,11 7.5,11 C7.77614237,11 8,11.2238576 8,11.5 L8,19 L19,19 L19,8 Z" />
  </svg>
));

export default createIcon(PopOut);
