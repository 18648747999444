/* eslint-disable import/prefer-default-export */
import { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedSearch } from 'hooks/useDebouncedSearch';
import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';
import LoadMoreDropdownOption from 'components/LoadMoreDropdownOption';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { getOrgUsersRaw, requestOrgUsers } from 'rdx/modules/users/slice';

export function useOrganizationUsersOptions({ skip = false }) {
  const [ownerSearch, setOwnerSearch] = useState('');
  const searchRef = useRef(ownerSearch);
  const currentOrg = useSelector(getCurrentOrganization);
  const orgUsers = useUnwrappedSelector(getOrgUsersRaw);

  const [queryPage, setQueryPage] = useState(1);

  const organization_id = useMemo(() => currentOrg?.slug, [currentOrg]);

  const { requestResource: fetchOrgUsers, loading } = useDebouncedSearch({
    request: requestOrgUsers,
    organization_id,
    query: { page: queryPage ?? 1 },
  });

  useEffect(() => {
    if (searchRef.current !== ownerSearch) {
      setQueryPage(1);
      searchRef.current = ownerSearch;
    }
    if (!skip) {
      fetchOrgUsers(ownerSearch);
    }
  }, [ownerSearch, fetchOrgUsers, skip]);

  const userDropdownOptions = useMemo(() => {
    const options = Array.isArray(orgUsers) ? orgUsers.map(
      ({ attributes, included }) => ({
        value: attributes.user_id,
        id: attributes.user_id,
        uuid: included?.[0].attributes.uuid,
        display: `${included?.[0].attributes.first_name} ${included?.[0].attributes.last_name}`,
        attributes: { ...attributes, ...included?.[0].attributes },
      })
    ) : [];
    const lastPage = orgUsers?.meta?.pagination?.page === orgUsers?.meta?.pagination?.pageCount;
    if (orgUsers?.length && !lastPage) {
      options.push({
        value: 'load-more',
        display: '',
        render: () => (
          <LoadMoreDropdownOption
            onClick={(e) => {
              e.stopPropagation();
              setQueryPage((p) => p + 1);
            }}
          >
            <p>Load More</p>
          </LoadMoreDropdownOption>
        ),
      });
    }
    return options;
  }, [orgUsers]);

  return { userDropdownOptions, loading, setOwnerSearch };
}
