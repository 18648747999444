import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';
import bdrcLogo from 'assets/bd_research_cloud.png';
import { useModal } from 'hooks/useModal';

import BaseModal from 'containers/Modals/BaseModal';
import MarkdownHtml from 'components/MarkdownHtml';
import { getPrivacyPolicy, requestPrivacyPolicy } from 'rdx/modules/systemDocs/slice';
import PolicyTranslationsLink from 'components/PolicyTranslationsLink';

const PrivacyModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { closeModal } = useModal();
  const policy = useSelector(getPrivacyPolicy);

  useEffect(() => {
    dispatch(requestPrivacyPolicy());
  }, [dispatch]);

  const handlePrivacyClick = () => {
    closeModal();
    history.push('/privacy');
  };

  const sanitizedPolicy = sanitizeHtml(policy?.data?.attributes?.content_html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: { img: ['src'] },
    allowedSchemes: ['data', 'https'],
  });

  return (
    <BaseModal
      dimensions={{
        width: '600px',
        height: '420px',
      }}
      noFooter
    >
      <Logo src={bdrcLogo} />
      <Text>
        <PolicyTitle>
          {policy?.data?.attributes?.title}
        </PolicyTitle>
        <MarkdownHtml
          dangerouslySetInnerHTML={{ __html: sanitizedPolicy }}
          overflowY="auto"
          height="245px"
        />
        <Link onClick={handlePrivacyClick}>See full Statement</Link>
        <PolicyTranslationLinkContainer>
          <PolicyTranslationsLink />
        </PolicyTranslationLinkContainer>
      </Text>
    </BaseModal>
  );
};

export default PrivacyModal;

const Logo = styled.img`
  width: 200px;
`;

const Text = styled.div`
  margin-top: 15px;
`;

const PolicyTitle = styled.h1`
  margin-top: 10px;
`;

const Link = styled.div`
  margin-top: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    text-decoration: underline;
    color: ${({ locked, theme }) => (locked ? theme.colors.warning : theme.colors.primaryDark)};
  }
`;

const PolicyTranslationLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 25px;
  margin-bottom: 20px;
  font-size: 11px;
`;
