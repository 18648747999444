import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const Chain = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg version="1.1" width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
    <path d="M21.5 4.090l-1.59-1.59c-0.618-0.614-1.47-0.993-2.41-0.993s-1.792 0.379-2.41 0.994l-3.59 3.59c-0.616 0.617-0.998 1.469-0.998 2.41s0.381 1.793 0.998 2.41l0.090 0.090-0.59 0.59-0.090-0.090c-0.618-0.614-1.47-0.993-2.41-0.993s-1.792 0.379-2.41 0.994l-3.59 3.59c-0.616 0.617-0.998 1.469-0.998 2.41s0.381 1.793 0.998 2.41l1.59 1.59c0.618 0.614 1.47 0.993 2.41 0.993s1.792-0.379 2.41-0.994l3.59-3.59c0.616-0.617 0.998-1.469 0.998-2.41s-0.381-1.793-0.998-2.41l-0.090-0.090 0.59-0.59 0.090 0.090c0.618 0.614 1.47 0.993 2.41 0.993s1.792-0.379 2.41-0.994l3.59-3.59c0.616-0.617 0.998-1.469 0.998-2.41s-0.381-1.793-0.998-2.41l0 0zM11.090 14.5c0.257 0.255 0.416 0.609 0.416 1s-0.159 0.745-0.416 1l-3.59 3.59c-0.255 0.257-0.609 0.416-1 0.416s-0.745-0.159-1-0.416l-1.59-1.59c-0.257-0.255-0.416-0.609-0.416-1s0.159-0.745 0.416-1l3.59-3.59c0.255-0.257 0.609-0.416 1-0.416s0.745 0.159 1 0.416l0.090 0.090-2.5 2.5 1.41 1.41 2.5-2.5zM20.090 7.5l-3.59 3.59c-0.255 0.257-0.609 0.416-1 0.416s-0.745-0.159-1-0.416l-0.090-0.090 2.5-2.5-1.41-1.41-2.5 2.5-0.090-0.090c-0.257-0.255-0.416-0.609-0.416-1s0.159-0.745 0.416-1l3.59-3.59c0.255-0.257 0.609-0.416 1-0.416s0.745 0.159 1 0.416l1.59 1.59c0.257 0.255 0.416 0.609 0.416 1s-0.159 0.745-0.416 1l-0 0z"></path>
  </svg>
));

Chain.displayName = 'Chain';

const ChainIcon = createIcon(Chain);

export default ChainIcon;
