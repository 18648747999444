import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import type { UnwrappedResourceList } from 'types/json-api-types';
import { resetStore } from '../app/slice';

type StudiesPayloadState = {
  projectId: string;
  data: ResourceList;
};

type StudiesState = StudiesPayloadState | Record<string, unknown>;

const initialState: StudiesState = {};

const requestProjectStudies = createAction('requestProjectStudies');
const studyReport = createAction('studyReport');

export const studiesSlice = createSlice({
  name: 'projectStudies',
  initialState,
  reducers: {
    setProjectStudies: (state, action: PayloadAction<StudiesPayloadState>) => { state[action.payload.projectId] = action.payload; },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const { setProjectStudies } = studiesSlice.actions;

// Actions
export {
  requestProjectStudies,
  studyReport,
  setProjectStudies,
};

// Selectors
export const getProjectStudies = (state: RootState) => {
  const { projectStudies } = state;
  const unwrappedStudies: UnwrappedResourceList | Record<string, unknown> = {};
  Object.entries(projectStudies).forEach(([projectId, studiesList]) => {
    const studies = studiesList as StudiesPayloadState;
    unwrappedStudies[projectId] = studies?.data?.unwrap();
  });
  return unwrappedStudies;
};

// Reducer
export default studiesSlice.reducer;
