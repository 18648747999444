import { put, select } from 'redux-saga/effects';
import { find } from 'lodash';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestOrganizationGroups } from 'rdx/modules/organization/slice';
import { getSessionActions } from 'rdx/modules/auth/slice';
import { requestGroups } from '../slice';

function* createGroup({ payload }) {
  const sessionActions = yield select(getSessionActions);
  const action = find(sessionActions, { name: 'create_group' });

  const { success, data, error } = yield* makeRequest.action(action, payload);
  if (success && data) {
    yield put(requestGroups());
    yield put(requestOrganizationGroups());
    yield put(newMessageEvent({ text: 'Group Created' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default createGroup;
