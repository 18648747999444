import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { BarcodeScanAttributes } from 'types/barcodeScans';
import { GenericActionPayload } from 'types/redux-types';

type BarcodeScansState = {
  list: ResourceList<BarcodeScanAttributes>,
  detail: Resource<BarcodeScanAttributes>,
};

const initialState: BarcodeScansState = {
  list: new ResourceList(),
  detail: new Resource(),
};

const barcodeScansSlice = createSlice({
  name: 'barcodeScans',
  initialState,
  reducers: {
    setBarcodeScans: (state, action: PayloadAction<ResourceList<BarcodeScanAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.list = state.list.mergeWith(action.payload);
        return;
      }
      state.list = action.payload;
    },
    setBarcodeScan: (state, action: PayloadAction<Resource<BarcodeScanAttributes>>) => {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const requestBarcodeScans = createAction<GenericActionPayload>('barcodeScans/requestBarcodeScans');
const requestBarcodeScan = createAction<GenericActionPayload>('barcodeScans/requestBarcodeScan');
const createBarcodeScan = createAction<GenericActionPayload<{ code: string }>>('barcodeScans/createBarcodeScan');
const deleteBarcodeScan = createAction<GenericActionPayload>('barcodeScans/deleteBarcodeScan');

const {
  setBarcodeScans,
  setBarcodeScan,
} = barcodeScansSlice.actions;

export {
  setBarcodeScans,
  setBarcodeScan,
  requestBarcodeScans,
  requestBarcodeScan,
  createBarcodeScan,
  deleteBarcodeScan,
};

export const getBarcodeScans = (state: RootState) => state.barcodeScans.list;
export const getBarcodeScan = (state: RootState) => state.barcodeScans.detail;

export default barcodeScansSlice.reducer;
