import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const FolderIcon = forwardRef((props, ref) => (
  <svg ref={ref} {...props} viewBox="0 0 60 53">
    <path d="m57 8h-29v-5c0-1.7-1.4-3-3-3h-22c-1.6 0-3 1.4-3 3v14 2 31c0 1.7 1.4 3 3 3h54c1.7 0 3-1.4 3-3v-31-2-6c0-1.6-1.4-3-3-3zm1 42c0 .6-.5 1-1 1h-54c-.6 0-1-.5-1-1v-31h56zm-56-33v-14c0-.6.5-1 1-1h22c.6 0 1 .5 1 1v7h31c.6 0 1 .5 1 1v6z" />
  </svg>
));

export default createIcon(FolderIcon);
