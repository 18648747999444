import OverlayPortal from 'components/OverlayPortal/index';
import SideDrawer, { drawerTypes } from 'components/SideDrawer/index';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useVerifyClose } from 'hooks/useVerifyClose/index';
import { getModal, getSecondaryModal } from 'rdx/modules/modals/slice';
import { closeAndUnsetDrawer, closeAndUnsetSecondaryDrawer, getDrawer, getDrawerIsOpen, getDrawerUrlInfo, getSecondaryDrawer, getSecondaryDrawerIsOpen } from 'rdx/modules/drawers/slice';
import { getTooltip } from 'rdx/modules/tooltip/slice';
import { useHistory, useLocation } from 'react-router-dom';
import useUnpublishedCytoDrawerConfirm from 'hooks/useUnpublishedCytoDrawerConfirm/index';
import { useAutoOpenCloseDrawers } from 'hooks/useAutoOpenCloseDrawers/index';

const GlobalUIOverlay = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const { verifyClose } = useVerifyClose();

  const primaryModal = useAppSelector(getModal);
  const secondaryModal = useAppSelector(getSecondaryModal);
  const primaryDrawer = useAppSelector(getDrawer);
  const primaryDrawerIsOpen = useAppSelector(getDrawerIsOpen);
  const primaryDrawerUrlInfo = useAppSelector(getDrawerUrlInfo);
  const secondaryDrawer = useAppSelector(getSecondaryDrawer);
  const secondaryDrawerIsOpen = useAppSelector(getSecondaryDrawerIsOpen);
  const tooltip = useAppSelector(getTooltip);

  useAutoOpenCloseDrawers();

  const closeDrawerAndCleanPath = () => {
    const params = new URLSearchParams(location.search);
    params.delete('drawer');
    params.delete('drawer-tab');
    Object.keys((primaryDrawerUrlInfo?.data || {})).forEach((key) => {
      params.delete(key);
    });
    dispatch(closeAndUnsetDrawer());
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const onClose = useUnpublishedCytoDrawerConfirm({ onConfirm: closeDrawerAndCleanPath });

  const onSecondaryClose = () => {
    dispatch(closeAndUnsetSecondaryDrawer());
  };

  return (
    <OverlayPortal>
      {primaryModal}
      {secondaryModal}
      {tooltip}
      <SideDrawer
        drawerType={drawerTypes.primary}
        open={primaryDrawerIsOpen}
        handleCloseDrawer={() => verifyClose(onClose)}
      >
        {primaryDrawer}
      </SideDrawer>
      <SideDrawer
        drawerType={drawerTypes.secondary}
        open={secondaryDrawerIsOpen}
        handleCloseDrawer={
          () => verifyClose(onSecondaryClose, true)
        }
      >
        {secondaryDrawer}
      </SideDrawer>
    </OverlayPortal>
  );
};

export default GlobalUIOverlay;
