import { forwardRef } from 'react';
import createIcon from '../createIcon';

const FillVerticalSerpentine = forwardRef((props, ref) => (
  <svg viewBox="0 0 130 130" ref={ref} {...props}>
    <path
      d="
        M 31,10 V 120
        H 59 h 12
        V 22 h 16
        V 110
        h -6 l 12,20 l 12,-20 h -6
        V 10 H 59
        V 108 h -16
        V 10 h -12
      "
    />
  </svg>
));

export default createIcon(FillVerticalSerpentine);
