import { takeEvery, takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import deleteBatchFilesSaga from 'rdx/modules/fileExplorer/sagas/deleteBatchFiles';
import downloadBatchFilesSaga from 'rdx/modules/fileExplorer/sagas/downloadBatchFiles';
import deleteFileSaga from 'rdx/modules/fileExplorer/sagas/deleteFile';
import requestSaga from 'rdx/api-utils/requestSaga';
import shareFileSaga from 'rdx/modules/fileExplorer/sagas/shareFile';
import fcsBatchFilesSaga from 'rdx/modules/fileExplorer/sagas/fcsBatchFiles';
import { deleteBatchFiles, deleteFile, deleteFileSharing, downloadBatchFiles, downloadFile, downloadZip, emailFile, fcsBatchFiles, lockFile, moveBatchFiles, previewFile, processCvw, requestFilesByName, requestFilesContent, requestFileSharings, requestFileVersions, requestFoldersContent, requestSubfolders, requestListViewFiles, requestMoreFilesContent, requestResourceCytometers, requestResourceFiles, requestResourceFolders, requestResourceProjects, requestSharedFilesOrgs, requestSingleFile, restoreFile, setFileExplorerCytometers, setFileExplorerProjects, setFiles, setFilesByName, setFileSharings, setFileVersions, setFolders, setSubfolders, setListViewFiles, setSharedFilesOrganizations, setSingleFile, shareFile, unlockFile, updateFile, renameFilesFolder } from 'rdx/modules/fileExplorer/slice';

const TEXT = {
  zip: 'Zip generation in progress',
  update: 'File Updated',
  email: 'File has been sent to your email',
  share: 'File has been shared',
  lock: 'File has been locked for the next 4 hours',
  unlock: 'File has been unlocked',
};

function* watchFileExplorerSagas() {
  yield trackRequests(takeEvery, requestSharedFilesOrgs.type, (rdxAction) => requestSaga({ rdxAction, putData: setSharedFilesOrganizations }));
  yield trackRequests(takeEvery, requestListViewFiles.type, (rdxAction) => requestSaga({ rdxAction, putData: setListViewFiles }));
  yield trackRequests(takeEvery, requestFilesContent.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, requestMoreFilesContent.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, requestFoldersContent.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, downloadZip.type, downloadBatchFilesSaga);
  yield trackRequests(takeEvery, downloadFile.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, previewFile.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, updateFile.type, (rdxAction) => requestSaga({ rdxAction, message: TEXT.update }));
  yield trackRequests(takeEvery, emailFile.type, (rdxAction) => requestSaga({ rdxAction, message: TEXT.email }));
  yield trackRequests(takeEvery, lockFile.type, (rdxAction) => requestSaga({ rdxAction, message: TEXT.lock }));
  yield trackRequests(takeEvery, unlockFile.type, (rdxAction) => requestSaga({ rdxAction, message: TEXT.unlock }));
  yield trackRequests(takeEvery, shareFile.type, shareFileSaga);
  yield trackRequests(takeEvery, requestFilesByName.type, (rdxAction) => requestSaga({ rdxAction, putData: setFilesByName }));
  yield trackRequests(takeEvery, renameFilesFolder.type, (rdxAction) => requestSaga({ rdxAction, message: "Folder renamed." }));
  yield trackRequests(takeEvery, requestSingleFile.type, (rdxAction) => requestSaga({ rdxAction, putData: setSingleFile }));
  yield trackRequests(takeEvery, restoreFile.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, deleteBatchFiles.type, deleteBatchFilesSaga);
  yield trackRequests(takeEvery, deleteFile.type, deleteFileSaga);
  yield trackRequests(takeEvery, moveBatchFiles.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, downloadBatchFiles.type, downloadBatchFilesSaga);
  yield trackRequests(takeEvery, fcsBatchFiles.type, fcsBatchFilesSaga);
  yield trackRequests(takeEvery, requestFileSharings.type, (rdxAction) => requestSaga({ rdxAction, putData: setFileSharings }));
  yield trackRequests(takeEvery, deleteFileSharing.type, (rdxAction) => requestSaga({ rdxAction, message: 'Shared File Access Revoked' }));
  yield trackRequests(takeEvery, processCvw.type, (rdxAction) => requestSaga({ rdxAction, message: 'Image extraction in progress' }));

  // new
  yield trackRequests(takeLatest, requestResourceProjects, (rdxAction) => requestSaga({ rdxAction, putData: setFileExplorerProjects }));
  yield trackRequests(takeLatest, requestResourceCytometers, (rdxAction) => requestSaga({ rdxAction, putData: setFileExplorerCytometers }));
  yield trackRequests(takeLatest, requestResourceFolders, (rdxAction) => requestSaga({ rdxAction, putData: setFolders }));
  yield trackRequests(takeLatest, requestSubfolders, (rdxAction) => requestSaga({ rdxAction, putData: setSubfolders }));
  yield trackRequests(takeLatest, requestResourceFiles, (rdxAction) => requestSaga({ rdxAction, putData: setFiles }));
  yield trackRequests(takeLatest, requestFileVersions, (rdxAction) => requestSaga({ rdxAction, putData: setFileVersions }));
}

export default watchFileExplorerSagas;

export function downloadPresignedFileFromUrl(presignedUrl) {
  const link = document.createElement('a');
  link.href = presignedUrl;
  link.setAttribute('download', true);
  document.body.appendChild(link);

  if (window.Cypress) {
    return;
  }
  link.click();
}

export function downloadPresignedFile(res) {
  const { presignedUrl, filename } = res?.data?.data ?? {};
  if (presignedUrl) {
    const link = document.createElement('a');
    link.href = presignedUrl;
    link.download = filename;
    link.setAttribute('download', true);
    document.body.appendChild(link);

    if (window.Cypress) {
      return;
    }
    link.click();
  }
}
