import { SyntheticEvent, useMemo } from 'react';

import { getGroupScopes, getIsGroupOwner } from 'rdx/modules/groups/slice';
import { getOrganizationScopes, getIsOrgOwner } from 'rdx/modules/organization/slice';

import { useModal } from 'hooks/useModal';
import { useAppSelector } from 'hooks/reduxHooks';

import PermissionsRequiredModal from 'containers/Modals/PermissionsRequiredModal';

type UsePermissionsParams<P> = {
  permissions?: string[],
  permissionType?: 'organization' | 'group',
  groupID?: number,
  overridePermissionRequirements?: boolean,
  clickHandler: P,
};

export const PERMISSION_TYPES = {
  ORG: 'organization',
  GROUP: 'group',
} as const;

export function usePermissions<P>({ permissions, permissionType = 'organization', groupID, overridePermissionRequirements, clickHandler }: UsePermissionsParams<P>) {
  const orgScopes = useAppSelector(getOrganizationScopes);
  const isOrgOwner = useAppSelector(getIsOrgOwner);
  const groupScopes = useAppSelector(getGroupScopes);
  const isGroupOwner = useAppSelector(getIsGroupOwner);
  const { callModal } = useModal();

  const { locked, permissionClick } = useMemo(() => {
    if (overridePermissionRequirements) {
      return {
        locked: false,
        permissionClick: clickHandler,
      };
    }
    let scopeType = Object.values(PERMISSION_TYPES).find((l) => l === permissionType);
    // eslint-disable-next-line no-nested-ternary
    const requiredScopes = permissions ? (Array.isArray(permissions) ? [...permissions] : [permissions]) : [];
    let userScopes: string[] = [];
    let missingPermissions: string[] = [...requiredScopes];
    switch (scopeType) {
      case PERMISSION_TYPES.GROUP:
        if (isGroupOwner) {
          return {
            locked: false,
            permissionClick: clickHandler,
          };
        }
        userScopes = Array.isArray(groupScopes) ? groupScopes : [];
        missingPermissions = requiredScopes.reduce((missing: string[], scope: string) => {
          if (userScopes.includes(scope)) { return missing; }
          return [...missing, scope];
        }, []);
        break;
      case PERMISSION_TYPES.ORG:
        if (isOrgOwner) {
          return {
            locked: false,
            permissionClick: clickHandler,
          };
        }
        userScopes = Array.isArray(orgScopes) ? orgScopes : [];
        missingPermissions = requiredScopes.reduce((missing: string[], scope: string) => {
          if (userScopes.includes(scope)) { return missing; }
          return [...missing, scope];
        }, []);
        break;
      default:
        console.warn(`usePermissions hook was provided with invalid permissionType: ${permissionType}\nValid options are: ${Object.values(PERMISSION_TYPES).join(' | ')}\ndefaulting to ${PERMISSION_TYPES.ORG}`);
        scopeType = PERMISSION_TYPES.ORG;
        if (isOrgOwner) {
          return {
            locked: false,
            permissionClick: clickHandler,
          };
        }
        userScopes = Array.isArray(orgScopes) ? orgScopes : [];
        missingPermissions = requiredScopes.reduce((missing: string[], scope: string) => {
          if (userScopes.includes(scope)) { return missing; }
          return [...missing, scope];
        }, []);
        break;
    }

    const isMissingScopes = !!missingPermissions.length;

    const openPermissionsModal = (e: SyntheticEvent) => {
      if (e?.preventDefault) {
        e.preventDefault();
      }
      if (e?.stopPropagation) {
        e.stopPropagation();
      }
      callModal(
        <PermissionsRequiredModal
          key={`default-${missingPermissions.join('-')}-${scopeType}-permissions-required-modal`}
          missingPermissions={missingPermissions}
          permissionType={scopeType}
          groupID={groupID}
        />,
      );
    };

    return {
      locked: isMissingScopes,
      permissionClick: isMissingScopes ? openPermissionsModal : clickHandler,
    };
  }, [callModal, clickHandler, groupID, groupScopes, isGroupOwner, isOrgOwner, orgScopes, overridePermissionRequirements, permissionType, permissions]);

  return { locked, permissionClick };
}
