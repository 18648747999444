import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import type { RootState } from 'index';

const initialState = {
  privacyPolicy: new ResourceList(),
  EULA: new ResourceList(),
  termsOfUse: new ResourceList(),
  releaseNotes: new ResourceList(),
  FAQ: new ResourceList(),
  trademarks: new ResourceList(),
};

const requestPrivacyPolicy = createAction('systemDocs/requestPrivacyPolicy');
const requestEULA = createAction('systemDocs/requestEULA');
const requestTermsOfUse = createAction('systemDocs/requestTermsOfUse');
const requestReleaseNotes = createAction('systemDocs/requestReleaseNotes');
const requestFAQ = createAction('systemDocs/requestFAQ');
const requestTrademarks = createAction('systemDocs/requestTrademarks');

const systemDocs = createSlice({
  name: 'systemDocs',
  initialState,
  reducers: {
    setPrivacyPolicy: (state, action: PayloadAction<ResourceList>) => {
      state.privacyPolicy = action.payload;
    },
    setEULA: (state, action: PayloadAction<ResourceList>) => {
      state.EULA = action.payload;
    },
    setTermsOfUse: (state, action: PayloadAction<ResourceList>) => {
      state.termsOfUse = action.payload;
    },
    setReleaseNotes: (state, action: PayloadAction<ResourceList>) => {
      state.releaseNotes = action.payload;
    },
    setFAQ: (state, action: PayloadAction<ResourceList>) => {
      state.FAQ = action.payload;
    },
    setTrademarks: (state, action: PayloadAction<ResourceList>) => {
      state.trademarks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setEULA,
  setFAQ,
  setPrivacyPolicy,
  setReleaseNotes,
  setTermsOfUse,
  setTrademarks,
} = systemDocs.actions;

export {
  // Reducer Actions
  setEULA,
  setFAQ,
  setPrivacyPolicy,
  setReleaseNotes,
  setTermsOfUse,
  setTrademarks,

  // Saga Actions
  requestPrivacyPolicy,
  requestEULA,
  requestFAQ,
  requestReleaseNotes,
  requestTermsOfUse,
  requestTrademarks,
};

export const getPrivacyPolicy = (state: RootState) => state.systemDocs.privacyPolicy;
export const getEULA = (state: RootState) => state.systemDocs.EULA;
export const getReleaseNotes = (state: RootState) => state.systemDocs.releaseNotes;
export const getFAQ = (state: RootState) => state.systemDocs.FAQ;
export const getTrademarks = (state: RootState) => state.systemDocs.trademarks;
export const getTermsOfUse = (state: RootState) => state.systemDocs.termsOfUse;

export default systemDocs.reducer;
