import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { put } from 'redux-saga/effects';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* deleteGroupUser({ payload: { resource, actionName, onSuccess } }) {
  const action = resource.getAction(actionName);
  const { success, data, error } = yield* makeRequest.action(action);
  if (success && data) {
    yield put(newMessageEvent({ text: 'User Removed From Group' }));
    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteGroupUser;
