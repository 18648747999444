const COLORS = {
  BLUES: {
    DARKEST: '#001C3B',
    DARK: '#023970',
    NORMAL: '#004593',
    LIGHT: '#315DAF',
    LIGHTER: '#839ECF',
    LIGHTEST: '#EAEFF7',
  },
  GRAYS: {
    DARKEST: '#1A1A1A',
    DARK: '#333333',
    NORMAL: '#808080',
    LIGHT: '#B3B3B3',
    LIGHTER: '#CCCCCC',
    LIGHTEST: '#E5E5E5',
  },
  BACKGROUNDS: {
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    OFFWHITE: '#F7F7F7',
  },
  NOTIFICATIONS: {
    PRIMARY: {
      DARK: '#007FD1',
      NORMAL: '#009BFF',
      LIGHT: '#14ABFF',
    },
    SUCCESS: {
      DARK: '#367200',
      NORMAL: '#4CA000',
      LIGHT: '#39B54A',
    },
    WARNING: {
      DARK: '#C97818',
      NORMAL: '#F7931E',
      LIGHT: '#FFB032',
    },
    DANGER: {
      DARK: '#A30013',
      NORMAL: '#D10018',
      LIGHT: '#FF0000',
    },
  },
} as const;

export { COLORS };
