import { useMemo } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import ResourceList from 'lib/jsonApi/ResourceList';
import type { RootState } from 'index';
import Resource from 'lib/jsonApi/Resource';
import type { DefaultAttributes, UnwrappedResourceList } from 'types/json-api-types';

export const useUnwrappedSelector = <T = DefaultAttributes,>(selector: (state: RootState) => unknown, returnObject = false): Resource<T> | UnwrappedResourceList<T> | Record<string, never> => {
  const rawResource = useAppSelector(selector);

  const unwrappedResourceList = useMemo(() => {
    if (rawResource instanceof ResourceList) {
      return rawResource.unwrap();
    }
    return (returnObject ? {} : []);
  }, [rawResource, returnObject]);

  return unwrappedResourceList;
};
