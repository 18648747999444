import { useEffect, useRef, forwardRef, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

const OverlayPortal = forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
  const containerEl = useRef(document.createElement('div'));

  useEffect(() => {
    containerEl.current.style.position = 'fixed';
    containerEl.current.style.top = '0';
    containerEl.current.style.left = '0';
    containerEl.current.style.zIndex = '999';
    document.body.appendChild(containerEl.current);
    if (ref) {
      if (typeof ref === 'function') {
        ref(containerEl.current);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = containerEl.current;
      }
    }
    return () => {
      if (containerEl.current && document.body.contains(containerEl.current)) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        document.body.removeChild(containerEl.current);
        if (ref && 'current' in ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current = null;
        }
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return ReactDOM.createPortal(children, containerEl.current);
});

OverlayPortal.displayName = 'OverlayPortal';

export default OverlayPortal;
