const BUTTON_SIZES = {
  DEFAULT: {
    height: '40px',
    width: '120px',
    fontSize: '14px',
  },
  WIDE: {
    height: '40px',
    width: '165px',
    fontSize: '14px',
  },
} as const;

export default BUTTON_SIZES;
