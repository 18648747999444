import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';

function* createExperimentSaga({ payload: { action, params, onSuccess } }) {
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (success && data) {
    const currentOrgSlug = yield select(getCurrentOrgSlug);
    const experiment = data.unwrap();
    let routeTo = `/${currentOrgSlug}/experiments`;
    if (experiment.id) {
      routeTo = `/${currentOrgSlug}/experiments/${experiment.id}/${experiment.plate_editor ? 'plate' : 'labels'}`;
    }
    yield put(newMessageEvent({ text: 'Experiment Created' }));
    onSuccess?.({ routeTo });
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default createExperimentSaga;
