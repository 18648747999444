import { forwardRef } from 'react';
import PropTypes from 'prop-types';
// import customPropTypes from 'lib/customPropTypes';
// import styled from 'styled-components';
import globals from 'styles/globals';

import DeprecatedButton, { ButtonPropTypes } from '../index';

const DeprecatedDangerButton = ({ forwardedRef, baseStyle, hoverStyle, disabledStyle, ...buttonProps }) => {
  const primaryBaseStyle = {
    fontColor: globals.colors.white,
    bgColor: globals.colors.danger,
    borderColor: globals.colors.danger,
    ...baseStyle,
  };

  const primaryHoverStyle = {
    bgColor: globals.colors.dangerHover,
    borderColor: globals.colors.dangerHover,
    ...hoverStyle,
  };

  const primaryDisabledStyle = {
    bgColor: globals.colors.dangerDisabled,
    borderColor: globals.colors.dangerDisabled,
    ...disabledStyle,
  };

  const computedProps = {
    baseStyle: primaryBaseStyle,
    hoverStyle: primaryHoverStyle,
    disabledStyle: primaryDisabledStyle,
    spinnerInnerColor: globals.colors.dangerDisabled,
    spinnerOuterColor: globals.colors.mediumGrey,
    ...buttonProps,
  };

  return <DeprecatedButton ref={forwardedRef} {...computedProps} />;
};

DeprecatedDangerButton.propTypes = {
  baseStyle: ButtonPropTypes.style,
  hoverStyle: ButtonPropTypes.style,
  disabledStyle: ButtonPropTypes.style,
  // eslint-disable-next-line react/forbid-prop-types
  forwardedRef: PropTypes.any,
};

DeprecatedDangerButton.defaultProps = {
  baseStyle: {},
  hoverStyle: {},
  disabledStyle: {},
  forwardedRef: undefined,
};

export default forwardRef((props, ref) => <DeprecatedDangerButton {...props} forwardedRef={ref} />);
