import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  height: 30px;
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
  font: ${({ theme }) => theme.fonts.label};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
