import { forwardRef } from 'react';

import createIcon, { IconProps } from '../createIcon';

const LockUnlocked = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg ref={ref} {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M 17.937 7.373 C 17.937 4.059 15.314 2 12 2 C 8.686 2 6 4.686 6 8 L 6 10 L 5 10 C 4.448 10 4 10.448 4 11 L 4 21 C 4 21.552 4.448 22 5 22 L 19 22 C 19.552 22 20 21.552 20 21 L 20 11 C 20 10.448 19.552 10 19 10 L 17.002 10.012 L 8 10 L 8 8 C 8 5.791 9.791 4 12 4 C 14.209 4 16 5.174 16 7.383 M 13 16.11 L 13 18.5 L 11 18.5 L 11 16.11 C 10.693 15.834 10.5 15.436 10.5 14.992 C 10.5 14.164 11.172 13.492 12 13.492 C 12.828 13.492 13.5 14.164 13.5 14.992 C 13.5 15.436 13.307 15.834 13.001 16.109 L 13 16.11 Z"/>
  </svg>
));

LockUnlocked.displayName = 'LockUnlockedIcon';

const CreatedIcon = createIcon(LockUnlocked);

export default CreatedIcon;
