import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { appendAntigenData, replaceAntigenData } from 'rdx/modules/system/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* requestAntigenData({ payload }: PayloadActionWithGenericPayload & { payload: { append: boolean } }) {
  const { action, params, append } = payload;
  const { success, data, error } = yield makeRequest.action(action, params);
  if (success && data) {
    if (append) {
      yield put(appendAntigenData(data.unwrap()));
    } else {
      yield put(replaceAntigenData(data.unwrap()));
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestAntigenData;
