import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* deleteFile(rdxAction) {
  const { action, params, query, onSuccess } = rdxAction.payload;
  const { success, error } = yield* makeRequest.action(action, params, query);
  if (success) {
    onSuccess?.();
    yield put(newMessageEvent({ text: 'File Deleted' }));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteFile;
