import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import type { GenericActionPayload } from 'types/redux-types';
import { WishlistAttributes } from 'types/wishlists';

interface WishlistsSliceState {
  list: ResourceList<WishlistAttributes>;
  forSelect: ResourceList<WishlistAttributes>;
  detail: Resource<WishlistAttributes>;
}

const initialState: WishlistsSliceState = {
  list: new ResourceList<WishlistAttributes>(),
  forSelect: new ResourceList<WishlistAttributes>(),
  detail: new Resource<WishlistAttributes>(),
};

type CreateWishlistParams = {
  name: string,
  project_id: number,
};

type RemoveReagentsParams = {
  catalog_numbers: string[],
};

type MoveReagentsParams = {
  catalog_numbers: string[],
  destination_wishlist_id: number,
};

const requestWishlist = createAction<GenericActionPayload>('wishlists/requestWishlist');
const requestWishlists = createAction<GenericActionPayload>('wishlists/requestWishlists');
const requestWishlistsForSelect = createAction<GenericActionPayload>('wishlists/requestWishlistsForSelect');
const createWishlist = createAction<GenericActionPayload<CreateWishlistParams>>('wishlists/createWishlist');
const moveReagents = createAction<GenericActionPayload<MoveReagentsParams>>('wishlists/moveReagents');
const removeReagents = createAction<GenericActionPayload<RemoveReagentsParams>>('wishlists/removeReagents');
const deleteWishlist = createAction<GenericActionPayload>('wishlists/deleteWishlist');
const updateWishlist = createAction<GenericActionPayload>('wishlists/updateWishlist');

const wishlistsSlice = createSlice({
  name: 'wishlists',
  initialState,
  reducers: {
    setWishlist: (state, action: PayloadAction<ResourceList<WishlistAttributes>>) => {
      state.detail = action.payload.unwrap() as Resource<WishlistAttributes>;
    },
    setWishlists: (state, action: PayloadAction<ResourceList<WishlistAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.list = state.list?.mergeWith(action.payload) || state.list;
        return;
      }
      state.list = action.payload;
    },
    setWishlistsForSelect: (state, action: PayloadAction<ResourceList<WishlistAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.forSelect = state.forSelect?.mergeWith(action.payload) || state.forSelect;
        return;
      }
      state.forSelect = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setWishlist,
  setWishlists,
  setWishlistsForSelect,
} = wishlistsSlice.actions;

export {
  // Reducer Actions
  setWishlist,
  setWishlists,
  setWishlistsForSelect,

  // Saga Actions
  requestWishlist,
  requestWishlists,
  requestWishlistsForSelect,
  deleteWishlist,
  updateWishlist,
  createWishlist,
  moveReagents,
  removeReagents,
};

export const getWishlist = (state: RootState) => state.wishlists.detail;
export const getWishlists = (state: RootState) => state.wishlists.list;
export const getWishlistsForSelect = (state: RootState) => state.wishlists.forSelect;

export default wishlistsSlice.reducer;
