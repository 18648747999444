import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionLinks } from 'rdx/modules/auth/slice';
import { getCurrentOrganization, setOrganizationGroups } from '../slice';

function* getOrganizationGroups({ payload }) {
  const organizationID = payload?.organization_id || (yield select(getCurrentOrganization))?.attributes?.slug;
  const sessionLinks = yield select(getSessionLinks);
  const link = sessionLinks.organization_groups;
  const { success, data, error } = yield* makeRequest.link(link, { organizationID });
  if (success && data) {
    yield put(setOrganizationGroups(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getOrganizationGroups;
