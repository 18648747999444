import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import createIcon, { IconProps } from '../createIcon';

const Pin = forwardRef<SVGSVGElement, IconProps & { pinned?: boolean, pinnedOrg?: boolean }>(({
  pinned,
  pinnedOrg,
  ...svgProps
}, ref) => (
  <svg ref={ref} {...svgProps} viewBox="0 0 430 430">
    <TransformGroup pinned={pinned} pinnedOrg={pinnedOrg}>
      <polygon
        points={`261.954,241.518
        345.998,152.215
        265.165,172.048
        135.835,52.944
        135.835,0
        0,138.958
        61.431,135.654
        171.387,271.298
        158.461,344.086
        239.29,261.385
        ${pinned ? '' : '430.118,430.118'}`}
      />
    </TransformGroup>
  </svg>
));

Pin.displayName = 'Pin';

const PinIcon = createIcon(Pin);

export default PinIcon;

const TransformGroup = styled.g<{ pinned?: boolean, pinnedOrg?: boolean }>`
  transform-origin: 50% 50%;
  transform: scaleX(-1);

  ${({ pinned }) => pinned && css`
    transform: translateY(70px) rotateZ(-45deg) scaleX(-1);
  `}
  ${({ pinnedOrg }) => pinnedOrg && css`
    transform: translateY(70px) rotateZ(-45deg) scaleX(-1);
  `}
  transition: transform 80ms;
  transition-timing-function: linear;
`;
