import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';

import requestLicenseQuoteURLSaga from './requestLicenseQuoteURL';
import { requestLicenses, setLicenses, requestLicenseQuoteURL } from '../slice';

function* watchLicensesSagas() {
  yield trackRequests(takeEvery, requestLicenses.type, (rdxAction) => requestSaga({ rdxAction, putData: setLicenses }));
  yield trackRequests(takeEvery, requestLicenseQuoteURL.type, requestLicenseQuoteURLSaga);
}

export default watchLicensesSagas;
