import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import type { PayloadActionWithGenericPayload } from 'types/redux-types';
import { setSmartGroups, setMoreSmartGroups, setOrgStorageSmartGroups, setMoreOrgStorageSmartGroups } from '../slice';

type SmartGroupsPayloadAction<T> = Partial<T> & { payload: { isOrgStorage?: boolean } };

function* requestSmartGroups({ payload: { action, params, onSuccess, onError, isOrgStorage } }: SmartGroupsPayloadAction<PayloadActionWithGenericPayload>) {
  const { success, error, data } = yield makeRequest.action(action, params);
  if (success) {
    if (onSuccess) {
      onSuccess(data);
    }
    if (isOrgStorage) {
      if (data?.meta?.pagination?.page > 1) {
        yield put(setMoreOrgStorageSmartGroups(data));
      } else {
        yield put(setOrgStorageSmartGroups(data));
      }
    } else if (data?.meta?.pagination?.page > 1) {
      yield put(setMoreSmartGroups(data));
    } else {
      yield put(setSmartGroups(data));
    }
    return null;
  }
  if (error) {
    if (onError) {
      onError(error);
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestSmartGroups;
