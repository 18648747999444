import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const Connection = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg ref={ref} {...props} width="24" height="24" viewBox="0 0 24 24">
    <path d="M17 6.59l-1.41 1.41 5 5-5 5 1.41 1.41 6.41-6.41-6.41-6.41z"></path>
    <path d="M8.41 8l-1.41-1.41-6.41 6.41 6.41 6.41 1.41-1.41-5-5 5-5z"></path>
    <path d="M9.5 13c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5z"></path>
    <path d="M13.5 13c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5z"></path>
    <path d="M17.5 13c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5z"></path>
  </svg>
));

Connection.displayName = 'Connection';

const ConnectionIcon = createIcon(Connection);

export default ConnectionIcon;