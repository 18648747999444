import styled from 'styled-components';
import PrimaryButton, { PrimaryButtonProps } from 'components/Button/PrimaryButton';
import { useLoading } from 'hooks/useLoading';
import type { Pagination } from 'types/json-api-types';
import type Action from 'lib/jsonApi/Action';

type Props = {
  pagination?: Pagination,
  requestMore?: (payload: any) => void,
  type?: string,
  action?: Action,
  params?: Record<string, any>,
  className?: string,
  buttonProps?: PrimaryButtonProps,
}

const LoadMoreResults = ({ pagination, requestMore, type, action, params, className, buttonProps }: Props) => {
  const loading = useLoading({ watchRequests: [type ?? ''] });

  if (!pagination) {
    return null;
  }
  
  const lastPage = pagination.page >= pagination.pageCount;
  const handleFetch = () => {
    if (action) {
      const withPage = { ...params, page: pagination.page + 1 };
      requestMore?.({ action, params: withPage });
    } else if (params) {
      const withPage = { ...params, page: pagination.page + 1 };
      requestMore?.(withPage);
    } else {
      requestMore?.({ page: pagination.page + 1 });
    }
  };
  return (
    <Wrapper hidden={lastPage} className={className}>
      <PrimaryButton
        text="Load More"
        onClick={handleFetch}
        loading={loading}
        {...buttonProps}
      />
    </Wrapper>
  );
};

export default LoadMoreResults;

const Wrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: row;
`;
