export const iconSizeNames = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  MDLG: 'md-lg',
  LG: 'lg',
  EXLG: 'exlg',
} as const;

export const iconSizeDimensions = {
  [iconSizeNames.XS]: '14px',
  [iconSizeNames.SM]: '18px',
  [iconSizeNames.MD]: '22px',
  [iconSizeNames.MDLG]: '26px',
  [iconSizeNames.LG]: '30px',
  [iconSizeNames.EXLG]: '50px',
} as const;
