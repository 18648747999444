import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { requestOrganizationProfile, getCurrentOrganization } from 'rdx/modules/organization/slice';
import { requestSession } from 'rdx/modules/auth/slice';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* updateUser({ payload: { action, values, onSuccess, message } }) {
  const { success, data, error } = yield* makeRequest.action(action, values);
  if (success && data) {
    const currentOrg = yield select(getCurrentOrganization);
    if (currentOrg?.is_sandbox) {
      yield put(requestOrganizationProfile({ organizationID: currentOrg.attributes?.slug }));
    }
    yield put(requestSession());
    const isSingleKeyEvent = Object.keys(values).length === 1;
    const isPinnedTabEvent = isSingleKeyEvent && Object.prototype.hasOwnProperty.call(values, 'pinned_tab');
    // const isTutorialSettingsEvent = isSingleKeyEvent && Object.prototype.hasOwnProperty.call(values, 'completed_tutorials');
    if (isPinnedTabEvent) {
      if (values?.pinned_tab === '') {
        yield put(newMessageEvent({ text: 'Tab Unpinned' }));
      } else {
        yield put(newMessageEvent({ text: 'Tab Pinned' }));
      }
    } else {
      yield put(newMessageEvent({ text: message || 'Profile Updated' }));
    }
    if (onSuccess) onSuccess();
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default updateUser;
