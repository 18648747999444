/* eslint-disable max-len */
import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';
import { createWishlist, deleteWishlist, moveReagents, removeReagents, requestWishlist, requestWishlists, requestWishlistsForSelect, setWishlist, setWishlists, setWishlistsForSelect, updateWishlist } from 'rdx/modules/wishlists/slice';

function* watchWishlistsSagas() {
  yield trackRequests(takeLatest, requestWishlist.type, (rdxAction) => requestSaga({ rdxAction, putData: setWishlist }));
  yield trackRequests(takeLatest, requestWishlists.type, (rdxAction) => requestSaga({ rdxAction, putData: setWishlists }));
  yield trackRequests(takeLatest, requestWishlistsForSelect.type, (rdxAction) => requestSaga({ rdxAction, putData: setWishlistsForSelect }));
  yield trackRequests(takeLatest, createWishlist.type, (rdxAction) => requestSaga({ rdxAction, message: 'Wishlist Created' }));
  yield trackRequests(takeLatest, updateWishlist.type, (rdxAction) => requestSaga({ rdxAction, message: 'Wishlist Updated' }));
  yield trackRequests(takeLatest, deleteWishlist.type, (rdxAction) => requestSaga({ rdxAction, message: 'Wishlist Deleted' }));
  yield trackRequests(takeLatest, moveReagents.type, (rdxAction) => requestSaga({ rdxAction, message: 'Reagents Moved' }));
  yield trackRequests(takeLatest, removeReagents.type, (rdxAction) => requestSaga({ rdxAction, message: 'Reagents Removed' }));
}

export default watchWishlistsSagas;
