import { FIELD_DISPATCH_TYPES, type FieldActions } from 'hooks/useForm/reducer';
import type { FormField, Field, FormState } from 'hooks/useForm/types';
import { ValidationError } from 'yup';

export type FormFields<FormValues> = {
  [fieldName in keyof FormValues]: FormField<FormValues[fieldName]>
}

export type Fields<FormValues> = {
  [fieldName in keyof FormValues]: Field<FormValues[fieldName]>
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type BuildFieldBagArgs<FormValues, FormBagType> = {
  fields: FormFields<FormValues>,
  formState: FormState<FormValues>,
  dispatch: React.Dispatch<FieldActions<FormValues>>,
  formBag: FormBagType
};

export function buildFieldBag<FormValues, FormBagType>({ fields, formState, dispatch, formBag }: BuildFieldBagArgs<FormValues, FormBagType>) {
  const fieldBag: Fields<FormValues> = {} as Fields<FormValues>;
  const entries = Object.entries(fields) as Entries<typeof fields>;
  entries.forEach(([name, fieldOptions]) => {
    const fieldName = name;
    // Field state
    const { value, errors, touched, visited } = formState?.[fieldName] ?? {};

    // Computed props
    const isValid = errors.length === 0;
    const validateOn = {
      ...(fieldOptions.validateOn ?? {}),
    };

    // Build callbacks
    const validate = async (currentValue = formState?.[fieldName].value) => {
      let newErrors: string[] = [];
      if (fieldOptions.validate) {
        const data = { fields: fieldBag, form: formBag };
        const err = fieldOptions.validate(currentValue, data);
        if (err) {
          if (Array.isArray(err)) {
            newErrors = err;
          } else {
            newErrors = [err];
          }
        }
      }
      if (fieldOptions.schema?.validate) {
        try {
          await fieldOptions.schema.validate(currentValue, {
            abortEarly: false,
          });
        } catch (e) {
          if (e instanceof ValidationError) {
            e.errors.forEach((error) => {
              newErrors.push(error);
            });
          }
        }
      }
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_ERRORS,
        payload: { field: fieldName, errors: newErrors },
      });
      return !newErrors.length;
    };

    const onChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_TOUCHED,
        payload: { field: fieldName, touched: true },
      });
      if (e === undefined) {
        setValue(fieldOptions.initialValue);
      } else if (fieldOptions.onChange) {
        const data = { fields: fieldBag, form: formBag };
        fieldOptions.onChange(e, data);
      } else {
        let newValue;
        // Use target.value if e is an Event (or similar) object, otherwise assume e itself is the new value
        if (e && Object.prototype.hasOwnProperty.call(e, 'target')) {
          newValue = e.target.value;
        } else {
          newValue = e;
        }
        setValue(newValue as FormValues[keyof FormValues]);
      }
    };

    const onBlur = (e?: React.FocusEvent<HTMLInputElement, Element>) => {
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_TOUCHED,
        payload: { field: fieldName, touched: true },
      });
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_VISITED,
        payload: { field: fieldName, visited: true },
      });
      if (fieldOptions.onBlur) {
        const data = { fields: fieldBag, form: formBag };
        fieldOptions.onBlur(e, data);
      }
      if (validateOn.blur !== false) {
        void validate();
      }
    };

    const setValue = (newValue: FormValues[typeof fieldName]) => {
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_VALUE,
        payload: { field: fieldName, value: newValue },
      });
      if (validateOn.change !== false) {
        void validate(newValue);
      }
    };

    const reset = () => {
      dispatch({
        type: FIELD_DISPATCH_TYPES.RESET,
        payload: { field: fieldName, initialValue: fieldOptions.initialValue },
      });
    };

    const setTouched = (isTouched: boolean) => {
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_TOUCHED,
        payload: { field: fieldName, touched: isTouched },
      });
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_VISITED,
        payload: { field: fieldName, visited: isTouched },
      });
    };

    // These props go on the DOM element
    const props = { name, value, onChange, onBlur };
    fieldBag[fieldName] = {
      props,
      errors,
      value,
      isValid,
      validate,
      touched,
      visited,
      setValue,
      setTouched,
      reset,
    };
  });
  return fieldBag;
}
