import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { ConfirmInviteParams, getSession } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Resource from 'lib/jsonApi/Resource';
import { DefaultAttributes } from 'types/json-api-types';

function* confirmInvite({ payload }: PayloadActionWithGenericPayload<ConfirmInviteParams>) {
  const { onSuccess } = payload;
  if (payload.params) {
    const { token: code, password, first_name, last_name, postal_code, country_code } = payload.params;
    const session: Resource<DefaultAttributes> | null = yield select(getSession);
    const action = session?.getAction('invite_confirm');
    const params = { code, password, first_name, last_name, postal_code, country_code };
    const { success, error } = yield makeRequest.action(action, params);
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    } else if (error) {
      yield* putErrorActions({ error });
    }
  }
  return null;
}

export default confirmInvite;
