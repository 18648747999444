import styled, { css } from 'styled-components';

export const NavLink = styled.a<{ noPointer?: boolean, maxWidth?: string }>`
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  ${({ noPointer }) => !noPointer && css`
    cursor: pointer;
  `}
  min-height: 48px;
  max-height: 100%;
  max-width: 150px;
  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `};
  height: 100%;
  width: 100%;

  color: ${({ theme }) => theme.colors.textDark};

  &:hover {
    text-decoration: none;
  }

  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey70}`};
  &.active {
    ${({ theme }) => (
    css`
        border: 1px solid ${theme.colors.grey70};
        border-top: 3px solid ${theme.colors.primary};
        border-bottom: none;
        color: ${theme.colors.primary};
      `)}
  }
`;

export const navTabUnderlineCSS = css`
  &:before {
    content: '';
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
