import { useRef } from 'react';
import { useForceRender } from 'hooks/useForceRender';

export function useCountdown(seconds: number) {
  const active = useRef(false);
  const timeLeft = useRef(seconds);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const forceRender = useForceRender();

  const nextTick = () => {
    if (active.current) {
      timer.current = setTimeout(() => {
        timeLeft.current -= 1;
        forceRender();
        if (timeLeft.current > 0) {
          nextTick();
        }
      }, 1000);
    }
  };

  const start = () => {
    if (!active.current) {
      active.current = true;
      forceRender();
      nextTick();
    }
  };

  const stop = () => {
    active.current = false;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = null;
    forceRender();
  };

  const reset = () => {
    active.current = false;
    timeLeft.current = seconds;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = null;
    forceRender();
  };

  return {
    value: timeLeft.current,
    active: active.current,
    start,
    stop,
    reset,
  };
}
