import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { GatedFeatureBlurbAttributes, GatedFeatureBlurbSlug } from 'types/gatedFeatureBlurbs';
import { GenericActionPayload } from 'types/redux-types';

const initialState: {
  current: Resource<GatedFeatureBlurbAttributes> | null,
} = {
  current: null,
};

export type RequestGatedFeatureBlurbParams = {
  slug: GatedFeatureBlurbSlug
}

const requestGatedFeatureBlurb = createAction<GenericActionPayload<RequestGatedFeatureBlurbParams>>('gatedFeatureBlurbs/requestGatedFeatureBlurb');

const gatedFeatureBlurbs = createSlice({
  name: 'gated-feature-blurb',
  initialState,
  reducers: {
    setGatedFeatureBlurb: (state, action: PayloadAction<ResourceList<GatedFeatureBlurbAttributes> | null>) => {
      const unwrapped = action.payload?.unwrap();
      if (unwrapped instanceof Resource) {
        state.current = unwrapped;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setGatedFeatureBlurb,
} = gatedFeatureBlurbs.actions;

export {
  setGatedFeatureBlurb,
  requestGatedFeatureBlurb,
};

export const getGatedFeatureBlurb = (state: RootState) => state.gatedFeatureBlurbs.current;

export default gatedFeatureBlurbs.reducer;
