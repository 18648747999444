import type { ActionParams, WithFieldParams } from 'types/json-api-types';
import Field from './Field';

export default class Action {
  name: string;

  url: string;

  method: string;

  fields: Field[];

  constructor(props: ActionParams | Action) {
    const { name = '', url = '', method = '', fields = [] } = props;
    this.name = name;
    this.url = url;
    this.method = method;
    this.fields = fields.map((field) => new Field(field));
  }

  get readableName() {
    return `${this.method} ${this.name} (${this.url})`;
  }

  get valid() {
    return this.fields && this.fields.every((field) => field.valid);
  }

  field = (fieldName: string) => this.fields.find((a) => a.name === fieldName);

  fieldValue = (fieldName: string) => this.field(fieldName)?.value;

  withField = ({ name, type = 'string', value, required = true }: WithFieldParams): Action => {
    const newField = new Field({ name, type, value, required });
    return new Action({ ...this, fields: [...this.fields, newField] });
  };

  withValues = (values = {}) => {
    const fields = this.fields.map((field) => (
      Object.prototype.hasOwnProperty.call(values, field.name) ? field.withValue(values[field.name as keyof typeof values]) : field
    ));
    return new Action({ ...this, fields });
  };

  withoutFields = (fields: string[]): Action => new Action({ ...this, fields: this.fields.filter((field) => !fields.includes(field.name)) });
}
