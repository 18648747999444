import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setSpecies } from 'rdx/modules/system/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* getSpecies({ payload }: PayloadActionWithGenericPayload) {
  const action = payload?.action;
  const { success, data, error } = yield makeRequest.action(action, payload.params);
  if (success && data) {
    yield put(setSpecies(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getSpecies;
