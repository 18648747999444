import React, { useMemo, useRef, useEffect, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getSession, getSignInRequirements, getSignInWarnings, getSystemSettings } from 'rdx/modules/auth/slice';
import { getIsSetForSession, setIsMobile, setSetForSession } from 'rdx/modules/app/slice';

import { usePrevious } from 'hooks/usePrevious';
import useOrganizationFileLimitWarning from 'hooks/useOrganizationFileLimitWarning';
import { useApiVersionRefresh } from 'hooks/useApiVersionRefresh';
import { useDeviceBreakpoints } from 'hooks/useDeviceBreakpoints';

import Main from 'containers/Main';
import Auth from 'containers/Auth';
import FlashMessages from 'containers/FlashMessages';
import APIConfirm from 'containers/APIConfirm';
import AuthGuard from 'components/AuthGuard';
import InfoRequirements from 'components/InfoRequirements';
import SystemDocument, { SYSTEM_DOCS_CATEGORIES } from 'components/SystemDocument';
import ThirdParty from 'components/SystemDocument/ThirdParty';
import GlobalUIOverlay from 'containers/GlobalUIProvider/GlobalUIOverlay';

import { OrganizationAttributes } from 'types/organizations';
import Loading from 'components/Loading';

const Mobile = React.lazy(() => import('containers/Mobile'));

const RootRouter = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const session = useAppSelector(getSession);
  const systemSettings = useAppSelector(getSystemSettings);
  const signInRequirements = useAppSelector(getSignInRequirements);
  const signInWarnings = useAppSelector(getSignInWarnings);
  const setForSession = useAppSelector(getIsSetForSession);

  const { isMobile } = useDeviceBreakpoints();

  useEffect(() => {
    if (!setForSession) {
      dispatch(setIsMobile(isMobile));
      dispatch(setSetForSession(true));
    }
  }, [dispatch, isMobile, setForSession]);

  // in the future if we support localization, this is where we change the default
  // value for new injected tooltip text
  // const [locale] = useState('en');
  useOrganizationFileLimitWarning();

  // Reloads page when new API version is detected.
  useApiVersionRefresh();

  const gaRef = useRef<boolean>(false);

  const bd_login_key = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('bd_login_key');
  }, [location.search]);

  const flashMessage = () => {
    let text = 'Could not find resource';
    if (prevLocation) {
      text += ` at ${prevLocation.pathname}${prevLocation.search}`;
    }
    dispatch(newMessageEvent({
      text,
      persist: true,
    }));
  };

  useEffect(() => {
    if (systemSettings?.google_analytics_code && !gaRef.current) {
      ReactGA.initialize(systemSettings.google_analytics_code);
      gaRef.current = true;
    }
  }, [systemSettings, systemSettings?.google_analytics_code]);

  // Remove Org Slug from pathname reported to GA
  
  useEffect(() => {
    if (window.ONETRUST_PERFORMANCE && gaRef.current) {
      const orgSlugs: string[] = [];
      if (session) {
        const organizations = session.getRel<OrganizationAttributes>('organizations');
        if (Array.isArray(organizations)) {
          organizations.forEach((resource) => {
            orgSlugs.push(`/${resource.attributes?.slug}`);
          });
        }
      }
      let path = location.pathname;
      if (orgSlugs.length) {
        const orgSlugRegExp = new RegExp(orgSlugs.join('|'), 'gi');
        path = path.replace(orgSlugRegExp, '');
      }
      const gaPage = path + location.search + location.hash;
      ReactGA.send({ hitType: 'pageview', page: gaPage });
    }
  }, [location.hash, location.pathname, location.search, session]);

  return (
    <>
      <Switch>
        {bd_login_key && (
          <Route exact path="/">
            <Auth />
          </Route>
        )}
        <Route exact path="/(tech-support/users/:uuid|login|sign-up|invite|forgot-password|reset-password)">
          <Auth />
        </Route>
        <Route exact path="/not-found">
          <Redirect to="/" />
          {location.pathname === '/not-found' && void setTimeout(() => {
            flashMessage();
          }, 1000)}
        </Route>
        <Route exact path="/privacy">
          <SystemDocument category={SYSTEM_DOCS_CATEGORIES.PRIVACY} />
        </Route>
        <Route exact path="/eula">
          <SystemDocument category={SYSTEM_DOCS_CATEGORIES.EULA} />
        </Route>
        <Route exact path="/use">
          <SystemDocument category={SYSTEM_DOCS_CATEGORIES.TERMS} />
        </Route>
        <Route exact path="/releasenotes">
          <SystemDocument category={SYSTEM_DOCS_CATEGORIES.RELEASE_NOTES} />
        </Route>
        <Route exact path="/trademarks">
          <SystemDocument category={SYSTEM_DOCS_CATEGORIES.TRADEMARKS} />
        </Route>
        <AuthGuard>
          {!!signInRequirements?.length && !!signInWarnings?.length && <Redirect to="/inforeq" />}
          <Switch>
            <Route exact path="/inforeq">
              <InfoRequirements />
            </Route>
            <Route exact path="/faq">
              <SystemDocument category={SYSTEM_DOCS_CATEGORIES.FAQ} />
            </Route>
            <Route exact path="/thirdparty">
              <ThirdParty />
            </Route>
            <Route path="/mobile-scanner">
              <Suspense fallback={<Loading forceLoading />}>
                <Mobile />
              </Suspense>
            </Route>
            <Route>
              <Main />
            </Route>
          </Switch>
        </AuthGuard>
        <Redirect to="/not-found" />
      </Switch>
      <FlashMessages />
      <APIConfirm />
      <GlobalUIOverlay />
    </>
  );
};

export default RootRouter;
