import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';
import { useModal } from 'hooks/useModal';
import bdrcLogo from 'assets/bd_research_cloud.png';
import MarkdownHtml from 'components/MarkdownHtml';
import { getTrademarks, requestTrademarks } from 'rdx/modules/systemDocs/slice';
import BaseModal from '../BaseModal';

const TrademarksModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const trademarks = useSelector(getTrademarks);

  const { closeModal } = useModal();

  useEffect(() => {
    dispatch(requestTrademarks());
  }, [dispatch]);

  const handleTrademarksClick = () => {
    closeModal();
    history.push('/trademarks');
  };

  const sanitizedTrademarks = sanitizeHtml(trademarks?.data?.attributes?.content_html, {});

  return (
    <BaseModal
      dimensions={{ width: '600px', height: '500px' }}
      noFooter
    >
      <Logo src={bdrcLogo} />
      <Text>
        <PolicyTitle>{trademarks?.data?.attributes?.title}</PolicyTitle>
        <MarkdownHtml
          dangerouslySetInnerHTML={{ __html: sanitizedTrademarks }}
          overflowY="auto"
          height="245px"
        />
        <Link onClick={handleTrademarksClick}>See full Statement</Link>
      </Text>
    </BaseModal>

  );
};

export default TrademarksModal;

const Logo = styled.img`
  width: 200px;
`;

const Text = styled.div`
  margin-top: 15px;
`;

const PolicyTitle = styled.h1`
  margin-top: 10px;
`;

const Link = styled.div`
  margin-top: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    text-decoration: underline;
    color: ${({ locked, theme }) => (locked ? theme.colors.warning : theme.colors.primaryDark)};
  }
`;
