import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const Refresh = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg {...props} ref={ref} width="24" height="24" viewBox="0 0 24 24">
    <path d="M6.12 10.35l-1.12 1.090c0.264-3.649 3.29-6.509 6.983-6.509 1.967 0 3.744 0.811 5.016 2.117l0.002 0.002 1.41-1.41c-1.634-1.663-3.908-2.694-6.422-2.694-4.81 0-8.739 3.774-8.988 8.522l-0.001 0.022-1.12-1.14-1.41 1.42 3.53 3.53 3.53-3.53z"></path>
    <path d="M23.53 12.23l-3.53-3.53-3.53 3.53 1.41 1.41 1.12-1.080c-0.264 3.649-3.29 6.509-6.983 6.509-1.967 0-3.744-0.811-5.016-2.117l-0.002-0.002-1.36 1.41c1.63 1.635 3.884 2.646 6.374 2.646 4.793 0 8.711-3.747 8.985-8.472l0.001-0.024 1.14 1.14z"></path>
  </svg>
));

Refresh.displayName = 'Refresh';

const RefreshIcon = createIcon(Refresh);

export default RefreshIcon;
