import { forwardRef } from 'react';
import { css } from 'styled-components';

import Button, { ButtonElementProps, ButtonProps } from '../index';

const solidCSS = {
  base: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.danger};
    border-color: ${({ theme }) => theme.colors.danger};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.colors.dangerHover};
    border-color: ${({ theme }) => theme.colors.dangerHover};
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.colors.dangerDisabled};
    border-color: ${({ theme }) => theme.colors.dangerDisabled};
    opacity: 0.5;
  `,
};

const hollowCSS = {
  base: css`
    color: ${({ theme }) => theme.colors.danger};
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.danger};
  `,
  hover: css`
    color: ${({ theme }) => theme.colors.dangerHover};
    border-color: ${({ theme }) => theme.colors.dangerHover};
    background-color: rgba(0, 0, 0, 0.05);
  `,
  disabled: css`
    color: ${({ theme }) => theme.colors.dangerDisabled};
    border-color: ${({ theme }) => theme.colors.dangerDisabled};
    opacity: 0.5;
  `,
};

export interface DangerButtonProps extends ButtonProps, ButtonElementProps {
  hollow?: boolean;
}

const DangerButton = forwardRef<HTMLButtonElement, DangerButtonProps>(({ hollow, ...buttonProps }, ref) => {
  const CSS = hollow ? hollowCSS : solidCSS;
  return (
    <Button
      ref={ref}
      baseCSS={CSS.base}
      hoverCSS={CSS.hover}
      disabledCSS={CSS.disabled}
      {...buttonProps}
    />
  );
});

export default DangerButton;
