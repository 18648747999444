import { createAction, createSlice } from '@reduxjs/toolkit';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import type { GenericActionPayload } from 'types/redux-types';
import { ProcedureAttributes } from 'types/procedures';

const initialState = {
  list: new ResourceList<ProcedureAttributes>(),
};

// API Request/Saga Actions
const requestProcedures = createAction<GenericActionPayload>('requestProcedures');
const requestProcedureDetail = createAction<GenericActionPayload>('requestProcedureDetail');
const patchProcedure = createAction('patchProcedure');
const deleteProcedure = createAction('deleteProcedure');
const postProcedure = createAction<GenericActionPayload & { values: Record<string, any>, no_flag?: boolean }>('postProcedure');
const repositionSteps = createAction('repositionSteps');

const proceduresSlice = createSlice({
  name: 'procedures',
  initialState,
  reducers: {
    setProcedures: (state, action: PayloadAction<ResourceList<ProcedureAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.list = state.list.mergeWith(action.payload);
      } else {
        state.list = action.payload;
      }
    },
    setProcedureDetail: (state, action: PayloadAction<ResourceList<ProcedureAttributes>>) => {
      state.list = state.list.withResource(action.payload.unwrap());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

// Reducer Actions
const {
  setProcedures,
  setProcedureDetail,
} = proceduresSlice.actions;

export {
  // Reducer Actions
  setProcedures,
  setProcedureDetail,

  // Saga/API Actions
  requestProcedures,
  requestProcedureDetail,
  patchProcedure,
  deleteProcedure,
  postProcedure,
  repositionSteps,
};

// Selectors
export const getProcedures = (state: RootState) => state.procedures.list;

export const getProcedureIDs = (state: RootState) => {
  const proceduresList = state.procedures.list.unwrap();
  if (Array.isArray(proceduresList) && proceduresList.length) {
    return proceduresList.sort((a, b) => {
      if (a.attributes.position && b.attributes.position) {
        return Number(a.attributes.position) - Number(b.attributes.position);
      }
      return 0;
    }).map((p) => p.id);
  }
  return [];
};

export const getProcedureByID = (procedureId: string | number | null) => (state: RootState) => {
  if (!procedureId) {
    return undefined;
  }
  const unwrappedProcedures = state.procedures.list.unwrap();
  if (Array.isArray(unwrappedProcedures)) {
    return unwrappedProcedures.find((procedure) => String(procedure.id) === String(procedureId));
  }
  return undefined;
};

export const getProcedureInvolvedUsersByID = (procedureId: string) => (state: RootState) => {
  const unwrappedProcedures = state.procedures.list.unwrap();
  if (Array.isArray(unwrappedProcedures)) {
    const involvedUserIDs = unwrappedProcedures.find?.((p) => String(p.id) === String(procedureId))?.getRel?.('involved_users');
    return involvedUserIDs ?? [];
  }
  return [];
};

export default proceduresSlice.reducer;
