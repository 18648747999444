import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import globals from 'styles/globals';

const { colors, fonts } = globals;

const StatusFlag = styled.div`
  height: 24px;
  width: 50px;
  padding: 10px;
  color: ${({ color, done }) => (done ? color : colors.white)};
  font: ${fonts.flag};
  background-color: ${({ color, done }) => (done ? 'transparent' : color)};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  z-index: 1;
  
  ${({ done }) => !done && css`
    &::before {
      content: "";
      width: 0;
      height: 0;
      left: 100%;
      position: absolute;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid ${(props) => (props.color ? props.color : 'transparent')};
    }
  `}

  ${({ simple }) => simple && css`
    background-color: transparent;
    color: ${({ color }) => (color || colors.fontDark)};

    &::before {
      border: none;
    }
  `}
`;

StatusFlag.propTypes = {
  color: PropTypes.string,
  simple: PropTypes.bool,
};

StatusFlag.defaultProps = {
  color: colors.black,
  simple: false,
};

export default StatusFlag;
