import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import requestSaga from 'rdx/api-utils/requestSaga';
import { requestFcsData, setFcsData } from 'rdx/modules/fcsData/slice';

function* watchfcsDataSagas() {
  yield trackRequests(takeEvery, requestFcsData.type, (rdxAction) => requestSaga({ rdxAction, putData: setFcsData }));
}

export default watchfcsDataSagas;
