import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';
import {
  requestPanelSharings,
  requestPanelsSharedWithCurrentUser,
  setPanelSharings,
  setPanelsSharedWithCurrentUser,
  sharePanel,
  deletePanelSharing,
  clonePanelSharing,
} from 'rdx/modules/panelSharings/slice';

function* watchPanelSharingsSagas() {
  yield trackRequests(takeLatest, requestPanelSharings.type, (rdxAction) => requestSaga({ rdxAction, putData: setPanelSharings }));
  yield trackRequests(takeLatest, requestPanelsSharedWithCurrentUser.type, (rdxAction) => requestSaga({ rdxAction, putData: setPanelsSharedWithCurrentUser }));
  yield trackRequests(takeLatest, sharePanel.type, (rdxAction) => requestSaga({ rdxAction, message: rdxAction.payload.isEdit ? 'Shared panel expiration updated' : 'Panel shared' }));
  yield trackRequests(takeLatest, deletePanelSharing.type, (rdxAction) => requestSaga({ rdxAction, message: 'Shared panel removed' }));
  yield trackRequests(takeLatest, clonePanelSharing.type, (rdxAction) => requestSaga({ rdxAction, message: 'Shared panel cloned'}));
}

export default watchPanelSharingsSagas;
