/* eslint-disable import/prefer-default-export */
import { useLocation } from 'react-router-dom';

export function useLocationParsed() {
  const location = useLocation();
  const pathname = location?.pathname;

  if (!pathname) return [];

  const keys = [];
  const parts = pathname.split('/');

  parts.forEach((part) => {
    if (!part) return;
    const asInt = parseInt(part);
    if (!Number.isNaN(asInt) && keys.length) {
      keys[keys.length - 1].id = part;
    } else {
      keys.push({ key: part });
    }
  });

  return keys;
}
