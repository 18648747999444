import { useState } from 'react';
import styled from 'styled-components';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import Input from 'components/Form/Input/index';

const ArrayStringInput = ({ options, onChange }: { options: string[], onChange?: (newArray: string[]) => void }) => {
  const [itemInput, setItemInput] = useState('');

  const renderOptions = () => {
    if (options) {
      return options.map((o) => (
        <Option key={o}>
          <OptionChip onClick={() => removeOption(o)}>x </OptionChip>{o}
        </Option>
      ));
    }
    return null;
  };

  const removeOption = (option: string) => {
    onChange?.(options.filter((o) => o !== option));
  };

  const addItem = () => {
    const arraySort = [...options, itemInput];
    arraySort.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
    onChange?.(arraySort);
  };

  return (
    <div>
      <AddItemContainer>
        <Input
          placeholder="Add a New Item"
          value={itemInput}
          onChange={(e) => { setItemInput(e.target.value); }}
        />
        <PrimaryButton onClick={addItem} text="Add Item" type="button" />
      </AddItemContainer>
      {renderOptions()}
    </div>
  );
};

export default ArrayStringInput;

const AddItemContainer = styled.div`
  width: 330px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const Option = styled.div`
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 10px;
  width: 80px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
`;

const OptionChip = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;
