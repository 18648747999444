import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';

function* addToBDBCart({ payload }) {
  const { action, params } = payload;
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (success && data) {
    const url = data?.data?.attributes?.hybrisAddToCartUrl;
    const a = document.createElement('a');

    a.id = 'bdbLink';
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    if (window.Cypress) return;
    a.click();
  } else {
    yield* putErrorActions({ error });
  }
}

export default addToBDBCart;
