import { put, select } from 'redux-saga/effects';
import { find } from 'lodash';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setProjects } from 'rdx/modules/projects/slice';
import { getSessionAction } from 'rdx/modules/auth/slice';

function* requestProjectsByGroup({ payload }) {
  const sessionActions = yield select(getSessionAction);
  const action = find(sessionActions, { name: 'group_projects' });
  const { onSuccess, onError } = payload;
  const { success, data, error } = yield* makeRequest.action(action, payload);
  if (success && data) {
    if (onSuccess) {
      onSuccess();
    }
    yield put(setProjects(data));
  } else {
    if (onError) {
      onSuccess();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestProjectsByGroup;
