import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import globals from 'styles/globals';

import ContextualDropdown, { type ContextualDropdownItem } from 'components/ContextualDropdown';
import { HamburgerIcon } from 'components/Icons';
import type { Tab } from 'components/Tabs';

type HamburgerTab = Tab & { children?: Tab[] };

type Props = {
  tabs?: HamburgerTab[],
};

const NavHamburger = ({ tabs }: Props) => {
  const history = useHistory();

  const dropdownItems: ContextualDropdownItem<undefined>[] = [];

  tabs?.forEach((tab) => {
    if (tab.id === 'PREV') {
      dropdownItems.push({
        name: 'Back',
        action: () => history.push('/'),
      });
      return;
    }
    if (tab.children) {
      dropdownItems.push({
        name: tab.id,
        isHeader: true,
      });
      tab.children.forEach((tabChild) => {
        const { link } = tabChild;
        if (link) {
          dropdownItems.push({
            name: tabChild.text ?? '',
            action: () => history.push(link),
          });
        }
      });
    } else {
      const { link } = tab;
      if (link) {
        dropdownItems.push({
          name: tab.text ?? '',
          action: () => history.push(link),
        });
      }
    }
  });

  return (
    <ContextualDropdown
      items={dropdownItems}
      isMainNav
    >
      <Bun>
        <HamburgerIcon size={28} />
      </Bun>
    </ContextualDropdown>
  );
};
export default NavHamburger;

const Bun = styled.div`
  margin-bottom: 6px;

  &:hover {
    cursor: pointer;

    ${HamburgerIcon} {
      fill: ${globals.colors.primary};
    }
  }
`;
