import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { put } from 'redux-saga/effects';
import getGroupUsers from './getGroupUsers';

function* postInvite({ payload: { action, params: { group_id, ...params }, onSuccess } }) {
  const { messageType } = params;
  const { success, error } = yield* makeRequest.action(action, { group_id, ...params });
  if (success) {
    yield getGroupUsers({ payload: { group_id, is_active: '1' } });
    yield getGroupUsers({ payload: { group_id, is_active: '0' } });
    if (messageType === 'emailSend') {
      yield put(newMessageEvent({ text: 'Invite Resent to User' }));
    } else {
      yield put(newMessageEvent({ text: 'User Added To Group' }));
    }
    onSuccess?.();
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default postInvite;
