import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';
import bdrcLogo from 'assets/bd_research_cloud.png';
import { useHistory } from 'react-router-dom';
import { useModal } from 'hooks/useModal';

import BaseModal from 'containers/Modals/BaseModal';
import MarkdownHtml from 'components/MarkdownHtml';
import { getEULA, getTermsOfUse, requestEULA, requestTermsOfUse } from 'rdx/modules/systemDocs/slice';
import PolicyTranslationsLink from 'components/PolicyTranslationsLink';

const TermsModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { closeModal } = useModal();
  const eula = useSelector(getEULA);
  const terms = useSelector(getTermsOfUse);

  useEffect(() => {
    dispatch(requestEULA());
    dispatch(requestTermsOfUse());
  }, [dispatch]);

  const handleTermsClick = () => {
    closeModal();
    history.push('/use');
  };

  const handleEulaClick = () => {
    closeModal();
    history.push('/eula');
  };

  const sanitizedTerms = sanitizeHtml(terms?.data?.attributes?.content_html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: { img: ['src'] },
    allowedSchemes: ['data', 'https'],
  });

  const sanitizedEula = sanitizeHtml(eula?.data?.attributes?.content_html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: { img: ['src'] },
    allowedSchemes: ['data', 'https'],
  });

  return (
    <BaseModal
      dimensions={{
        width: '600px',
        height: '500px',
      }}
      noFooter
    >
      <Logo src={bdrcLogo} />
      <Text>
        <Container>
          <PolicyTitle>
            {terms?.data?.attributes?.title}
          </PolicyTitle>
          <MarkdownHtml
            dangerouslySetInnerHTML={{ __html: sanitizedTerms }}
            overflowY="auto"
            height="110px"
          />
          <Link onClick={handleTermsClick}>See full Terms of Use</Link>
        </Container>
        <Container>
          <PolicyTitle>
            {eula?.data?.attributes?.title}
          </PolicyTitle>
          <MarkdownHtml
            dangerouslySetInnerHTML={{ __html: sanitizedEula }}
            overflowY="auto"
            height="110px"
          />
          <Link onClick={handleEulaClick}>See full EULA</Link>
        </Container>
        <PolicyTranslationLinkContainer>
          <PolicyTranslationsLink />
        </PolicyTranslationLinkContainer>
      </Text>
    </BaseModal>
  );
};

export default TermsModal;

const Logo = styled.img`
  width: 200px;
`;

const Text = styled.div`
  margin-top: 15px;
`;

const PolicyTitle = styled.h1`
  margin-top: 10px;
`;

const Container = styled.div`
  margin-bottom: 20px;
`;

const Link = styled.div`
  margin-top: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    text-decoration: underline;
    color: ${({ locked, theme }) => (locked ? theme.colors.warning : theme.colors.primaryDark)};
  }
`;

const PolicyTranslationLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 25px;
  margin-bottom: 20px;
  font-size: 11px;
`;
