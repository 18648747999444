import { takeEvery, takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import postLabSaga from 'rdx/modules/labs/sagas/postLab';
import deleteLabSaga from 'rdx/modules/labs/sagas/deleteLab';
import getPublicLabsSaga from 'rdx/modules/labs/sagas/getPublicLabs';
import { deleteOrganizationLab, postOrganizationLab, requestPublicLabs } from 'rdx/modules/labs/slice';

function* watchLabsSagas() {
  yield trackRequests(takeLatest, requestPublicLabs.type, getPublicLabsSaga);
  yield trackRequests(takeEvery, postOrganizationLab.type, postLabSaga);
  yield trackRequests(takeEvery, deleteOrganizationLab.type, deleteLabSaga);
}

export default watchLabsSagas;
