import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setReagentEvents } from 'rdx/modules/reagents/slice';

function* requestReagentEvents({ payload }) {
  const { link, query } = payload;
  const { success, error, data } = yield* makeRequest.link(link, {}, query);

  if (error) {
    yield* putErrorActions({ error });
  }
  if (success && data) {
    yield put(setReagentEvents(data));
  }
  return null;
}

export default requestReagentEvents;
