import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const CircleDotIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg ref={ref} {...props} viewBox="0 0 512 512">
    <path d="M160 256c0-53.9 42.1-96 96-96 53 0 96 42.1 96 96 0 53-43 96-96 96-53.9 0-96-43-96-96zm352 0c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256zM256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z" />
  </svg>
));

CircleDotIcon.displayName = 'CircleDotIcon';

const ConnectionIcon = createIcon(CircleDotIcon);

export default ConnectionIcon;