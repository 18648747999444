import { ReactNode } from "react";
import styled, { css } from "styled-components"

export type BadgeType = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'alert';

type Props = {
  type?: BadgeType,
  text: ReactNode,
  width?: string,
  className?: string,
}

const Badge = ({ type = 'primary', text, width, className }: Props) => {
  return (
    <Container type={type} width={width} className={className ? className : ''}>
      {text}
    </Container>
  )
}

export default Badge

const primaryCSS = css`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`

const secondaryCSS = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
`

const tertiaryCSS = css`
  background-color: ${({ theme }) => theme.bdColors.grayLightest};
  border: 1px solid ${({ theme }) => theme.bdColors.grayLightest};
  color: ${({ theme }) => theme.bdColors.grayDarker};
`

const successCSS = css`
  background-color: ${({ theme }) => theme.bdColors.alertSuccess};
  border: 1px solid ${({ theme }) => theme.bdColors.alertSuccess};
  color: ${({ theme }) => theme.colors.white};
`

const warningCSS = css`
  background-color: ${({ theme }) => theme.bdColors.alertWarning};
  border: 1px solid ${({ theme }) => theme.bdColors.alertWarning};
  color: ${({ theme }) => theme.bdColors.grayDarker};
`

const alertCSS = css`
  background-color: ${({ theme }) => theme.bdColors.alertDanger};
  border: 1px solid ${({ theme }) => theme.bdColors.alertDanger};
  color: ${({ theme }) => theme.colors.white};
`

const Container = styled.div<{ type: BadgeType, width?: string }>`
  border-radius: 2px;
  font-weight: 900;
  padding: 7px 15px;
  display: flex;
  max-height: 30px;
  align-items: center;
  justify-content: center;

  ${({ width }) => width && css`
    width: ${width};
  `}

  ${({ type }) => {
    switch (type) {
      case 'primary':
        return primaryCSS;
      case 'secondary':
        return secondaryCSS;
      case 'tertiary':
        return tertiaryCSS;
      case 'success':
        return successCSS;
      case 'warning':
        return warningCSS;
      case 'alert':
        return alertCSS;
      default:
        return primaryCSS;
    }
  }}
`;