import { put } from 'redux-saga/effects';

import jsonApiRequest from 'rdx/api-utils/jsonApiRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { HandleResponseParams, RequestSagaParams } from 'types/api-helpers';

function* handleResponse({ rdxAction, response, message, putData, onSuccess, onError }: HandleResponseParams) {
  const { error, success, data } = response;
  if (error) {
    if (onError) {
      onError({ error, rdxAction });
    }
    yield* putErrorActions({ error, rdxAction, onSuccess });
  }
  if (success) {
    if (putData) {
      if (rdxAction.payload.merge) {
        yield put(putData({ data, merge: true }));
      } else {
        yield put(putData(data));
      }
    }
    if (message) {
      yield put(newMessageEvent({ text: message }));
    }
    if (onSuccess) {
      onSuccess({ data, rdxAction });
    }
  }
}

function* requestSaga({ rdxAction, message, putData }: RequestSagaParams) {
  const { action, link, params, query, onSuccess, onError } = rdxAction.payload;
  const response = yield* jsonApiRequest({ action, link, params, query, type: rdxAction.type });
  yield* handleResponse({ rdxAction, response, message, putData, onSuccess, onError });
  return response;
}

export default requestSaga;
