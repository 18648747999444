import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';
import ResourceList from 'lib/jsonApi/ResourceList';
import Resource from 'lib/jsonApi/Resource';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import { createBarcodeScan, deleteBarcodeScan, requestBarcodeScans, requestBarcodeScan, setBarcodeScans, setBarcodeScan } from '../slice';
import { BarcodeScanAttributes } from 'types/barcodeScans';

function* watchBarcodeScansSagas() {
  yield trackRequests(takeLatest, requestBarcodeScans.type, (rdxAction: PayloadActionWithGenericPayload) => requestSaga({ rdxAction, putData: setBarcodeScans }));
  yield trackRequests(takeLatest, requestBarcodeScan.type, (rdxAction: PayloadActionWithGenericPayload) => requestSaga({
    rdxAction,
    putData: (data: ResourceList) => {
      const scan = data.unwrap() as Resource<BarcodeScanAttributes>;
      return setBarcodeScan(scan);
    },
  }));
  yield trackRequests(takeLatest, createBarcodeScan.type, (rdxAction: PayloadActionWithGenericPayload) => requestSaga({ rdxAction, message: 'Scan successful' }));
  yield trackRequests(takeLatest, deleteBarcodeScan.type, (rdxAction: PayloadActionWithGenericPayload) => requestSaga({ rdxAction, message: 'Scan deleted' }));
}

export default watchBarcodeScansSagas;
