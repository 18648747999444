import { takeEvery, takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getReagentsSaga from 'rdx/modules/reagents/sagas/getReagents';
import postReagentSaga from 'rdx/modules/reagents/sagas/postReagent';
import patchReagentSaga from 'rdx/modules/reagents/sagas/patchReagent';
import deleteReagentSaga from 'rdx/modules/reagents/sagas/deleteReagent';
import requestSpectralDataSaga from 'rdx/modules/reagents/sagas/requestSpectralData';
import requestReagentEventsSaga from 'rdx/modules/reagents/sagas/requestReagentEvents';
import getInventoryReagentsSaga from 'rdx/modules/reagents/sagas/getInventoryReagents';
import patchInventoryReagentSaga from 'rdx/modules/reagents/sagas/patchInventoryReagent';
import postInventoryReagentSaga from 'rdx/modules/reagents/sagas/postInventoryReagent';
import deleteInventoryReagentSaga from 'rdx/modules/reagents/sagas/deleteInventoryReagent';
import requestSaga from 'rdx/api-utils/requestSaga';
import { addReagentToWishlist, deleteInventoryReagent, deleteReagent, exportReagents, patchInventoryReagent, patchReagent, postInventoryReagent, postReagent, requestInventoryReagent, requestInventoryReagents, requestReagent, requestReagentEvents, requestReagents, requestSpectralData, setInventoryReagent, setReagent } from 'rdx/modules/reagents/slice';

function* watchReagentsSagas() {
  yield trackRequests(takeLatest, requestReagents.type, getReagentsSaga);
  yield trackRequests(takeLatest, requestReagent.type, (rdxAction) => requestSaga({ rdxAction, putData: setReagent }));
  yield trackRequests(takeLatest, requestInventoryReagents.type, getInventoryReagentsSaga);
  yield trackRequests(takeLatest, requestInventoryReagent.type, (rdxAction) => requestSaga({ rdxAction, putData: setInventoryReagent }));
  yield trackRequests(takeEvery, postReagent.type, postReagentSaga);
  yield trackRequests(takeEvery, patchReagent.type, patchReagentSaga);
  yield trackRequests(takeEvery, deleteReagent.type, deleteReagentSaga);
  yield trackRequests(takeEvery, postInventoryReagent.type, postInventoryReagentSaga);
  yield trackRequests(takeEvery, patchInventoryReagent.type, patchInventoryReagentSaga);
  yield trackRequests(takeEvery, deleteInventoryReagent.type, deleteInventoryReagentSaga);
  yield trackRequests(takeEvery, requestSpectralData.type, requestSpectralDataSaga);
  yield trackRequests(takeLatest, requestReagentEvents.type, requestReagentEventsSaga);
  yield trackRequests(takeLatest, exportReagents.type, (rdxAction) => requestSaga({ rdxAction, message: 'Downloading Reagents' }));
  yield trackRequests(takeLatest, addReagentToWishlist.type, (rdxAction) => {
    const reagent = `${rdxAction.payload.params.fluorochrome}-${rdxAction.payload.params.antigen}`;
    return requestSaga({ rdxAction, message: `${reagent} has been added to your default wishlist.` });
  });
}

export default watchReagentsSagas;
