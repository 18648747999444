export const getInitial = (str = '') => {
  if (str && typeof str[0] === 'string') return str[0].toUpperCase();
  return '';
};

export const getInitials = (firstName = '', lastName = '') => getInitial(firstName) + getInitial(lastName);

const getUserInitials = (user) => {
  if (user && user.attributes) {
    return getInitials(user.attributes.first_name, user.attributes.last_name);
  }
  return '';
};

export default getUserInitials;
