import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { setProcedureDetail } from 'rdx/modules/procedures/slice';
import { postStep } from 'rdx/modules/steps/slice';

function* postProcedure({ payload }) {
  const { action, values, no_flag, onSuccess } = payload;
  const { success, data, error } = yield* makeRequest.action(action, values);

  if (success && data) {
    if (!no_flag) {
      yield put(newMessageEvent({ text: 'Procedure Created' }));
    }
    yield put(setProcedureDetail(data));
    const createStepAction = data?.getAction?.('create_step');
    const stepParams = createStepAction?.fields?.reduce((vals, field) => ({ ...vals, [field.name]: field.value }), {});
    yield put(postStep({
      action: createStepAction,
      params: {
        ...stepParams,
        position: 0,
      },
      no_flag,
      onSuccess: (stepData) => {
        // TODO: had to comment this out due to an issue with the onetrust popup
        // We can re-enable this once the issue w/ onetrust popup is fixed.
        // setTimeout(() => {
        //   const element = document.getElementById('create-procedure-button');
        //   if (element?.scrollIntoView) {
        //     element.scrollIntoView({ behavior: 'smooth' });
        //   }
        // }, 500);
        if (onSuccess) {
          onSuccess(data, stepData);
        }
      },
    }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default postProcedure;
