import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* smartGroupsAction({ payload: { action, params, onSuccess, onError, flashMessage, putAction } }) {
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (success) {
    if (onSuccess) {
      onSuccess(data);
    }
    if (putAction) {
      yield put(putAction(data));
    }
    if (flashMessage) {
      yield put(newMessageEvent({ text: flashMessage }));
    }
    return null;
  }
  if (error) {
    if (onError) {
      onError(error);
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default smartGroupsAction;
