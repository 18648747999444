export const KB = 1000;
export const MB = KB * KB;
export const GB = MB * KB;
export const TB = GB * KB;

export default function convertByteString(amount?: number | string) {
  const intAmount = typeof amount === 'string' ? parseInt(amount) : amount;
  if (amount === undefined || Number.isNaN(amount) || typeof intAmount !== 'number') {
    throw new Error(`convertByteString couldn't convert ${amount} to a number`);
  }
  let stringAmount = intAmount / TB;
  if (stringAmount > 1) {
    return `${Number.isInteger(stringAmount) ? stringAmount : stringAmount.toFixed(2)} TB`;
  }
  stringAmount *= KB;
  if (stringAmount > 1) {
    return `${Number.isInteger(stringAmount) ? stringAmount : stringAmount.toFixed(2)} GB`;
  }
  stringAmount *= KB;
  if (stringAmount > 1) {
    return `${Number.isInteger(stringAmount) ? stringAmount : stringAmount.toFixed(2)} MB`;
  }
  stringAmount *= KB;
  if ((stringAmount) > 1) {
    return `${Number.isInteger(stringAmount) ? stringAmount : stringAmount.toFixed(2)} KB`;
  }
  return `${intAmount} Bytes`;
}
