import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* createStudy({ payload: { action, params } }) {
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    yield put(newMessageEvent({ text: 'Study Created' }));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default createStudy;
