import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { setGeneralUsers } from 'rdx/modules/users/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Action from 'lib/jsonApi/Action';

function* requestUsers({ payload }: PayloadActionWithGenericPayload) {
  const action: Action | undefined = yield select(getSessionAction('users'));
  const { query, onSuccess } = payload;
  const { success, data, error } = yield makeRequest.action(action, { ...query });
  if (success && data) {
    onSuccess?.();
    yield put(setGeneralUsers(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestUsers;
