import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const FileIcon = forwardRef((props, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 489.53 426.29"
  >
    <path d="M426.34,95.44c6.08,0.4,11.94,0.29,17.62,1.25c20.3,3.41,35.14,20.54,35.15,41.12c0.05,77.82-0.06,155.65-0.3,233.47
      c-0.07,21-13.14,37.49-32.73,42.29c-1.91,0.47-3.94,0.6-5.91,0.6c-135.89,0.03-271.79,0.03-407.68,0.02
      c-13.72,0-28.07-12.58-31.37-27.65c-0.74-3.38-1.06-6.91-1.06-10.38C0,265.57-0.01,154.98,0.02,44.38
      C0.02,18.47,18.39,0.1,44.3,0.04C68.75-0.02,93.2,0.08,117.65,0c9.86-0.03,18.11,3.43,25.03,10.41
      c12.3,12.4,24.78,24.62,36.93,37.16c3.89,4.02,8.07,5.69,13.65,5.68c62.54-0.12,125.08-0.1,187.62-0.06
      c22.09,0.02,39.01,12.65,43.88,32.67C425.51,88.92,425.8,92.08,426.34,95.44z M72.91,394.24c14.01,0,28.97,0,43.92,0
      c52.56,0,105.12,0,157.69,0c53.39,0,106.79,0.01,160.18-0.01c11.49,0,21.67-8.89,22.54-19.74c0.48-5.94,0.88-11.9,0.88-17.85
      c0.02-72.01-0.03-144.02-0.09-216.03c-0.01-14.61-9.03-23.54-23.73-23.58c-11.8-0.03-23.6-0.01-35.39-0.01
      c-99.63,0-199.27,0.14-298.9-0.16c-14.18-0.04-25.67,8.17-25.58,25.43c0.42,73.67,0.24,147.35,0.11,221.03
      C74.52,373.34,73.51,383.37,72.91,394.24z M404.77,95.83c-0.41-2.45-0.53-4.28-1.03-5.99c-2.88-9.82-10.8-15.21-22.45-15.21
      c-62.38-0.02-124.75-0.11-187.13,0.08c-12.07,0.04-21.86-3.78-30.21-12.59c-11.44-12.07-23.5-23.55-35.1-35.46
      c-3.76-3.86-7.92-5.54-13.29-5.51c-23.29,0.15-46.57,0.06-69.86,0.08c-15.74,0.01-24.49,8.69-24.49,24.31
      c-0.01,109.6-0.02,219.2,0.01,328.8c0,2.48,0.16,5.01,0.71,7.41c1.58,6.89,8,12.05,14.7,12.08c7.54,0.03,13.96-4.39,15.8-11.32
      c0.71-2.68,0.71-5.59,0.71-8.4c0.04-77.5,0.01-155.01,0.05-232.51c0.01-27.88,18.02-45.77,45.94-45.77c99.47,0,198.94,0,298.41,0
      C399.68,95.83,401.82,95.83,404.77,95.83z"
    />
  </svg>
));

export default createIcon(FileIcon);
