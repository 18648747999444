import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import createExperimentSaga from 'rdx/modules/experiments/sagas/createExperimentSaga';
import updateExperimentSaga from 'rdx/modules/experiments/sagas/updateExperimentSaga';
import requestExperimentSaga from 'rdx/modules/experiments/sagas/requestExperimentSaga';
import requestExperimentsSaga from 'rdx/modules/experiments/sagas/requestExperimentsSaga';
import requestPanelMatchingSaga from 'rdx/modules/experiments/sagas/requestPanelMatchingSaga';
import downloadPlatePdfSaga from 'rdx/modules/experiments/sagas/downloadPlatePdfSaga';
import requestSaga from 'rdx/api-utils/requestSaga';
import { cloneExperiment, createExperiment, deleteExperiment, downloadPlatePdf, requestExperiment, requestExperiments, requestPanelMatching, updateExperiment, parseTemplateFile } from 'rdx/modules/experiments/slice';

function* watchDivaExportSagas() {
  yield trackRequests(takeEvery, createExperiment.type, createExperimentSaga);
  yield trackRequests(takeEvery, updateExperiment.type, updateExperimentSaga);
  yield trackRequests(takeEvery, requestExperiments.type, requestExperimentsSaga);
  yield trackRequests(takeEvery, requestExperiment.type, requestExperimentSaga);
  yield trackRequests(takeEvery, deleteExperiment.type, (rdxAction) => requestSaga({ rdxAction, message: 'Experiment Deleted' }));
  yield trackRequests(takeEvery, cloneExperiment.type, (rdxAction) => requestSaga({ rdxAction, message: 'Experiment Cloned' }));
  yield trackRequests(takeEvery, requestPanelMatching.type, requestPanelMatchingSaga);
  yield trackRequests(takeEvery, downloadPlatePdf.type, downloadPlatePdfSaga);
  yield trackRequests(takeEvery, parseTemplateFile.type, (rdxAction) => requestSaga({ rdxAction }));
}

export default watchDivaExportSagas;
