import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font: ${({ theme }) => theme.fonts.body};
    text-rendering: geometricPrecision;
    height: 100vh;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.textDark};
  }

  #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
    font-weight: normal;
  }

  h1 {
    font: ${({ theme }) => theme.fonts.h1};
  }

  h2 {
    font: ${({ theme }) => theme.fonts.h2};
  }

  h3 {
    font: ${({ theme }) => theme.fonts.h3};
  }

  h4 {
    font: ${({ theme }) => theme.fonts.h4};
  }

  h5 {
    font: ${({ theme }) => theme.fonts.h5};
  }

  h4, h5, h6 {
    font-family: ${({ theme }) => theme.fonts.header};
  }

  input,
  textarea,
  p {
    font: ${({ theme }) => theme.fonts.body};
  }

  div {
    box-sizing: border-box;
    position: relative;
  }

  a {
    color: inherit;
    text-decoration: inherit;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default GlobalStyle;
