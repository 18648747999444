import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';
import { requestLinkedResources, setLinkedResources, deleteLinkedResources, createLinkedResources } from './slice';

function* watchLinkedResourcesSagas() {
  yield trackRequests(takeLatest, requestLinkedResources, (rdxAction) => requestSaga({ rdxAction, putData: setLinkedResources }));
  yield trackRequests(takeLatest, deleteLinkedResources, (rdxAction) => requestSaga({ rdxAction, message: 'Resource Link Deleted' }));
  yield trackRequests(takeLatest, createLinkedResources, (rdxAction) => requestSaga({ rdxAction, message: 'Resource Link Created' }));
}

export default watchLinkedResourcesSagas;
