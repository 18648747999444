import styled from 'styled-components';
import globals from 'styles/globals';

const { colors, animations } = globals;

const MinusIcon = styled.button`
  position: relative;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: 50%;
  background-color: transparent;
  transition: all ${animations.transitionFast} linear;
  border: ${({ color }) => {
    if (color) {
      return `1px solid ${color}`;
    }
    return `1px solid ${colors.fontLight}`;
  }
}};
  cursor: pointer;

  &::after {
    transition: all ${animations.transitionFast} linear;
    content: '';
    position: absolute;
    background-color: ${({ color }) => color || colors.fontLight};
  }

  &::after {
    top: 50%;
    left: 5px;
    right: 5px;
    height: 1px;
    transform: translateY(-50%);
  }
  &:hover {
    border-color: ${colors.primary};
    &::before, &::after {
    background: ${colors.primary};
  }
  }
`;

export default MinusIcon;
