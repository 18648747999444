import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getWorkflow, getWorkflowRaw } from 'rdx/modules/workflow/slice';

function* saveWorkflowAsStudy({ payload: { action, params, onSuccess } }) {
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (!success || !data) {
    yield* putErrorActions({ error });
  }
  yield put(newMessageEvent({ text: 'Workflow Saved as Study' }));
  const workflow = yield select(getWorkflowRaw);
  yield put(getWorkflow(workflow.links.self));
  onSuccess?.();
  return null;
}

export default saveWorkflowAsStudy;
