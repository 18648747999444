import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { setCurrentCytometer } from 'rdx/modules/cytometers/slice';

function* handleOnline({ payload }) {
  const { action, onSuccess, text, values } = payload;
  const { success, data, error } = yield* makeRequest.action(action, values);
  if (success && data) {
    if (onSuccess) {
      onSuccess();
    }
    yield put(setCurrentCytometer(data.unwrap()));
    yield put(newMessageEvent({ text }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default handleOnline;
