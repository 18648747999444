import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setCytometers, setMoreCytometers } from 'rdx/modules/cytometers/slice';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { getSessionAction } from 'rdx/modules/auth/slice';

function* getOrgCytometers({ payload: { query, onSuccess, onError, params = {} } }) {
  const action = yield select(getSessionAction('organization_cytometers'));

  const orgSlug = yield select(getCurrentOrgSlug);
  const { success, data, error } = yield* makeRequest.link(
    action.url,
    { ...params, organization_id: params.organization_id || orgSlug },
    query,
  );
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
    const page = query?.page;
    if (page > 1) {
      yield put(setMoreCytometers(data));
    } else {
      yield put(setCytometers(data));
    }
  } else if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default getOrgCytometers;
