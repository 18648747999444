import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestGroups } from '../slice';

function* deleteGroup({ payload }) {
  const { action, onSuccess } = payload;
  // TODO (Rane): Api isn't sending this action over at all
  const { success, error } = yield* makeRequest.action(action);
  if (success) {
    yield put(requestGroups());
    yield put(newMessageEvent({ text: 'Group Deleted' }));
    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteGroup;
