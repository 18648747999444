import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setCurrentCytometerRssReports } from 'rdx/modules/cytometers/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* requestCytometerRssReportsSaga({ payload: { query, onSuccess, onError, params = {} } }: PayloadActionWithGenericPayload) {
  const link: string = yield select((state) => state.cytometers.currentCytometer.links?.rss_reports);

  const { success, data, error } = yield makeRequest.link(
    link,
    { ...params },
    query,
  );
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
    if (data) {
      yield put(setCurrentCytometerRssReports(data));
    }
  } else if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestCytometerRssReportsSaga;
