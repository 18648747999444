import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import Resource from 'lib/jsonApi/Resource';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { updateGroupUsers } from 'rdx/modules/users/slice';

// TODO: replace with universal saga
function* updateUser({ payload: { action, params } }) {
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (success) {
    // TODO: talk with sasha, this response format is weird
    const updatedUser = new Resource({ ...data.data, meta: data.meta, links: data.links });
    yield put(updateGroupUsers({ resource: updatedUser, included: data.included }));
    yield put(newMessageEvent({ text: 'Group User Permissions Updated' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default updateUser;
