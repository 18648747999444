import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { convertByteString } from 'lib/utils/string';
import Loading from 'components/Loading';
import { useModal } from 'hooks/useModal';
import { BackText } from 'containers/Modals/OrgProfileModal/components';
import { getOrgStorageSmartGroups, requestSmartGroups, setMoreOrgStorageSmartGroups, setOrgStorageSmartGroups } from 'rdx/modules/groups/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { getSessionActions } from 'rdx/modules/auth/slice';
import { getUser } from 'rdx/modules/users/slice';
import { openChatFor } from 'rdx/modules/chat/slice';

const watchRequests = [requestSmartGroups.type, setOrgStorageSmartGroups.type, setMoreOrgStorageSmartGroups.type];

const OrganizationStorageDetails = ({ onCancel, licenseLimit }) => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionActions = useSelector(getSessionActions);
  const orgGroupAction = React.useMemo(() => sessionActions?.find((a) => a.name === 'organization_groups'), [sessionActions]);
  const currentOrg = useSelector(getCurrentOrganization);
  const user = useSelector(getUser);
  const groups = useSelector(getOrgStorageSmartGroups);
  const openChat = React.useCallback((uuid) => {
    dispatch(openChatFor({ userUUIDs: [uuid] }));
    closeModal();
  }, [closeModal, dispatch]);

  const navigateAndCloseModal = React.useCallback((groupId) => {
    history.push(`/${currentOrg?.attributes?.slug}/groups?tab=open&drawer=group-details&group_id=${groupId}`);
    closeModal();
  }, [closeModal, currentOrg?.attributes?.slug, history]);

  React.useEffect(() => {
    dispatch(requestSmartGroups({
      action: orgGroupAction,
      params: {
        organization_id: currentOrg?.id,
      },
      query: {
        sorting: 'total_size-',
      },
      isOrgStorage: true,
    }));
  }, [currentOrg?.id, dispatch, orgGroupAction]);

  const rows = React.useMemo(() => groups
    ?.unwrap()
    .map((group) => (
      <Row key={group.uuid}>
        <Cell><AppLink role="button" onClick={() => navigateAndCloseModal(group.id)}>{group.name}</AppLink></Cell>
        <Cell>{group?.files_count}</Cell>
        <Cell>{convertByteString(group?.total_size)}</Cell>
        <Cell>{getProportion({ size: group?.total_size, licenseLimit })}</Cell>
        <Cell>{String(group.owner.id) === String(user.id) ? 'You' : (
          <AppLink role="button" onClick={() => openChat(group.owner.uuid)}>
            {`${group.owner.first_name} ${group.owner.last_name}`}
          </AppLink>
        )}
        </Cell>
      </Row>
    ))
    ?? [],
  [groups, licenseLimit, navigateAndCloseModal, openChat, user.id]);

  return (
    <Container>
      <BackText onClick={() => onCancel()} data-testid="license-back">BACK TO MY LICENSE INFORMATION</BackText>
      <h2 style={{ margin: '1rem 0' }}>Storage By Group</h2>
      <Loading watchRequests={watchRequests}>
        <StorageTable>
          <thead>
            <Row>
              <Header>
                Group Name
              </Header>
              <Header>
                Files
              </Header>
              <Header>
                Total Size
              </Header>
              <Header>
                Proportion of Limit
              </Header>
              <Header>
                Owner
              </Header>
            </Row>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </StorageTable>
      </Loading>
    </Container>
  );
};

OrganizationStorageDetails.propTypes = {
  onCancel: PropTypes.func,
  licenseLimit: PropTypes.number,
};

OrganizationStorageDetails.defaultProps = {
  onCancel: () => {},
  licenseLimit: 0,
};

export default OrganizationStorageDetails;

const Container = styled.div`
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const StorageTable = styled.table``;

const Row = styled.tr`
  padding: 0.25rem 0;
`;

const Header = styled.th`
  text-align: left;
`;

const Cell = styled.td``;

function getProportion({ size = 0, licenseLimit }) {
  const numerator = parseInt(size);
  const denominator = parseInt(licenseLimit);
  if (Number.isNaN(numerator) || Number.isNaN(denominator)) {
    console.error(`cannot calculate proportion of give numerator: ${size} and denominator: ${licenseLimit}`);
    return 'bad data';
  }
  const proportion = ((numerator / denominator) * 100);
  if (numerator !== 0 && proportion.toFixed(2) === '0.00') {
    return '<1 %';
  }
  return `${proportion.toFixed(2)} %`;
}

const AppLink = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    text-decoration: underline;
  }
`;
