import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getProjectStudiesSaga from 'rdx/modules/studies/sagas/getProjectStudies';
import studyReportSaga from 'rdx/modules/studies/sagas/studyReport';
import { requestProjectStudies, studyReport } from '../slice';

function* watchStudiesSagas() {
  yield trackRequests(takeEvery, requestProjectStudies.type, getProjectStudiesSaga);
  yield trackRequests(takeEvery, studyReport.type, studyReportSaga);
}

export default watchStudiesSagas;
