import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction, setInviteStatus } from 'rdx/modules/auth/slice';
import Action from 'lib/jsonApi/Action';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* requestInviteStatus({ payload }: PayloadActionWithGenericPayload & { payload: { token: string } }) {
  const action: Action | undefined = yield select(getSessionAction('invite_status'));
  const { data, success, error } = yield makeRequest.action(action, { code: payload.token });
  if (success && data) {
    yield put(setInviteStatus(data.unwrap().attributes.status));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestInviteStatus;
