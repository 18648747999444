import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { GenericActionPayload } from 'types/redux-types';

export type FlowGptQueryAttributes = {
  uuid: string;
  req: string;
  resp: string;
  user_id: number;
  created_at: string;
};

const initialState: ResourceList<FlowGptQueryAttributes> = new ResourceList();

const requestFlowGptQueries = createAction<GenericActionPayload>('flowGptQueries/requestFlowGptQueries');
const createFlowGptQuery = createAction<GenericActionPayload>('flowGptQueries/createFlowGptQuery');

export const flowGptQueriesSlice = createSlice({
  name: 'flowGptQueries',
  initialState,
  reducers: {
    setFlowGptQueries: (state, action: PayloadAction<ResourceList<FlowGptQueryAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        return state?.mergeWith(action.payload) || state;
      }
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setFlowGptQueries,
} = flowGptQueriesSlice.actions;

// Actions
export {
  requestFlowGptQueries,
  setFlowGptQueries,
  createFlowGptQuery,
};

// Selectors
export const getFlowGptQueries = (state: RootState) => state.flowGptQueries?.unwrap();

// Reducer
export default flowGptQueriesSlice.reducer;
