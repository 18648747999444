import type { FieldParams } from 'types/json-api-types';
import { getFieldOptions } from './FieldOptions';

export default class Field {
  name: string;

  type: string;

  nulls: boolean;

  value: any;

  schema: any;

  required: boolean;

  readonly: boolean;

  options: ReturnType<typeof getFieldOptions>;

  constructor(props: FieldParams) {
    const { name, type, value, schema, required, options = [], nulls, readonly } = props;
    this.name = name;
    this.type = type;
    this.nulls = !!nulls;
    this.value = this.enforceType(value, type);
    this.schema = schema;
    this.required = !!required;
    this.options = getFieldOptions(options);
    this.readonly = !!readonly;
  }

  get valid() {
    if (this.required && !this.value) return false;
    if (
      this.required
      && typeof this.value === 'string'
      && Array.isArray(this.options)
      && !this.options.getByValue(this.value)
    ) return false;
    return true;
  }

  get hasOptions() {
    return !!(this.options?.length);
  }

  withValue = (value: unknown) => new Field({ ...this, value: this.enforceType(value, this.type) });

  enforceType = (value: unknown, type: string) => {
    if (this.nulls) return value;
    switch (type) {
      case 'bool':
        return !!value;
      default:
        return value;
    }
  };
}
