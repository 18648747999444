import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import customPropTypes from 'lib/customPropTypes';
import Timeout from 'components/Timeout';
import { useInterval } from 'hooks/useInterval';
import { getCurrentSessionRequest } from 'rdx/modules/activeRequests/slice';
import { getSessionLoaded, requestSession } from 'rdx/modules/auth/slice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

const SessionConnect = ({ timeout, refreshInterval, children }) => {
  const dispatch = useAppDispatch();
  const sessionLoaded = useAppSelector(getSessionLoaded);
  const currentSessionRequest = useAppSelector(getCurrentSessionRequest);
  const [timedOut, setTimedOut] = useState(false);

  function pollSession() {
    if (!currentSessionRequest) {
      dispatch(requestSession());
    }
  }

  useInterval(pollSession, sessionLoaded ? null : refreshInterval);
  useInterval(() => setTimedOut(true), sessionLoaded ? null : timeout);

  useEffect(() => {
    if (timedOut && sessionLoaded) {
      setTimedOut(false);
    }
  }, [sessionLoaded, timedOut]);

  if (sessionLoaded) {
    return children;
  }
  return <Timeout timedOut={timedOut} />;
};

SessionConnect.propTypes = {
  timeout: PropTypes.number,
  refreshInterval: PropTypes.number,
  children: customPropTypes.children,
};

SessionConnect.defaultProps = {
  timeout: 10000,
  refreshInterval: 1000,
  children: undefined,
};

export default SessionConnect;
