/* eslint-disable max-len */
import { takeEvery, takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getOrgCytometers from 'rdx/modules/cytometers/sagas/getOrgCytometers';
import getSearchCytometers from 'rdx/modules/cytometers/sagas/getSearchCytometers';
import getCytometer from 'rdx/modules/cytometers/sagas/getCytometer';
import patchCytometerSaga from 'rdx/modules/cytometers/sagas/patchCytometer';
import deleteCytometerProfileSaga from 'rdx/modules/cytometers/sagas/deleteCytometerProfile';
import duplicateCytometerProfileSaga from 'rdx/modules/cytometers/sagas/duplicateCytometerProfile';
import deleteCytometerSaga from 'rdx/modules/cytometers/sagas/deleteCytometer';
import setDefaultProfile from 'rdx/modules/cytometers/sagas/setDefaultProfile';
import patchPublishState from 'rdx/modules/cytometers/sagas/patchPublishState';
import unpublishState from 'rdx/modules/cytometers/sagas/unpublishState';
import cloneCytometerSaga from 'rdx/modules/cytometers/sagas/cloneCytometer';
import unlinkCytometerSaga from 'rdx/modules/cytometers/sagas/unlinkCytometer';
import handleOnline from 'rdx/modules/cytometers/sagas/handleOnline';
import downloadCytometerProfilePdfSaga from 'rdx/modules/cytometers/sagas/downloadCytometerProfilePdf';
import requestCytometerSettingsSaga from 'rdx/modules/cytometers/sagas/requestCytometerSettings';
import updateCytometerSettingsSaga from 'rdx/modules/cytometers/sagas/updateCytometerSettings';
import requestDashboardCytometersSaga from 'rdx/modules/cytometers/sagas/requestDashboardCytometersSaga';
import requestSaga from 'rdx/api-utils/requestSaga';
import getPublicCytometers from 'rdx/modules/cytometers/sagas/getPublicCytometers';
import linkPublicCytometer from 'rdx/modules/cytometers/sagas/linkPublicCytometer';
import { cloneCytometer, deleteCytometer, deleteCytometerProfile, downloadCytometerProfilePdf, linkCytometer, patchCytometer, patchCytometerOnlineStatus, patchCytometerSpectralOff, patchCytometerSpectralOn, publishCytometer, requestCurrentCytometer, requestCytometerSettings, requestCytometerVendors, requestOrgCytometers, requestPublicCytometers, requestSearchCytometers, setCytometerVendors, setDefaultCytometerProfile, unlinkCytometer, unpubilshCytometer, updateCytometerSettings, requestDashboardCytometers, requestCytometerRssReports, requestCytometerRSSData, setCytometerRSSData, requestCytometerLaserValuesMeta, setCurrentCytometerLaserValuesMeta, duplicateCytometerProfile } from 'rdx/modules/cytometers/slice';
import requestCytometerRssReportsSaga from './requestCytometerRssReportsSaga';

function* watchCytometersSagas() {
  yield trackRequests(takeLatest, requestOrgCytometers.type, getOrgCytometers);
  yield trackRequests(takeEvery, requestCurrentCytometer.type, getCytometer);
  yield trackRequests(takeEvery, patchCytometer.type, patchCytometerSaga);
  yield trackRequests(takeEvery, deleteCytometerProfile.type, deleteCytometerProfileSaga);
  yield trackRequests(takeEvery, duplicateCytometerProfile.type, duplicateCytometerProfileSaga);
  yield trackRequests(takeEvery, deleteCytometer.type, deleteCytometerSaga);
  yield trackRequests(takeEvery, setDefaultCytometerProfile.type, setDefaultProfile);
  yield trackRequests(takeLatest, requestSearchCytometers.type, getSearchCytometers);
  yield trackRequests(takeEvery, publishCytometer.type, patchPublishState);
  yield trackRequests(takeEvery, unpubilshCytometer.type, unpublishState);
  yield trackRequests(takeEvery, cloneCytometer.type, cloneCytometerSaga);
  yield trackRequests(takeEvery, unlinkCytometer.type, unlinkCytometerSaga);
  yield trackRequests(takeEvery, patchCytometerOnlineStatus.type, handleOnline);
  yield trackRequests(takeEvery, requestCytometerSettings.type, requestCytometerSettingsSaga);
  yield trackRequests(takeEvery, updateCytometerSettings.type, updateCytometerSettingsSaga);
  yield trackRequests(takeEvery, requestCytometerVendors.type, (rdxAction) => requestSaga({ rdxAction, putData: setCytometerVendors }));
  yield trackRequests(takeEvery, requestDashboardCytometers.type, requestDashboardCytometersSaga);
  yield trackRequests(takeEvery, downloadCytometerProfilePdf.type, downloadCytometerProfilePdfSaga);
  yield trackRequests(takeEvery, requestCytometerRSSData.type, (rdxAction) => requestSaga({ rdxAction, putData: setCytometerRSSData }));
  yield trackRequests(takeEvery, patchCytometerSpectralOn.type, (rdxAction) => requestSaga({ rdxAction, message: 'Spectral Activated' }));
  yield trackRequests(takeEvery, patchCytometerSpectralOff.type, (rdxAction) => requestSaga({ rdxAction, message: 'Spectral Deactivated' }));
  yield trackRequests(takeEvery, requestPublicCytometers.type, getPublicCytometers);
  yield trackRequests(takeEvery, linkCytometer.type, linkPublicCytometer);
  yield trackRequests(takeEvery, requestCytometerRssReports.type, requestCytometerRssReportsSaga);
  yield trackRequests(takeEvery, requestCytometerLaserValuesMeta.type, (rdxAction) => requestSaga({ rdxAction, putData: setCurrentCytometerLaserValuesMeta }));
}

export default watchCytometersSagas;
