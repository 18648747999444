import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setProjectStudies } from '../slice';

function* getProjectStudies({ payload }) {
  const { link, query, projectId, params, onSuccess, onError } = payload;
  const { success, data, error } = yield* makeRequest.link(link, params, query);
  if (!success || error) {
    yield* putErrorActions({ error });
  }

  if (success && data) {
    if (onSuccess) {
      onSuccess(data);
    }
    yield put(setProjectStudies({ projectId, data }));
  } else if (onError) {
    onError();
  }
  return null;
}

export default getProjectStudies;
