import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* strikeStepNote({ payload: { action, noteID, onSuccess } }) {
  const { success, data, error } = yield* makeRequest.action(action, { noteID });
  if (!success || !data) {
    yield* putErrorActions({ error });
  } else {
    onSuccess?.();
    yield put(newMessageEvent({ text: 'Strikethrough recorded' }));
  }
  return null;
}

export default strikeStepNote;
