export default function downloadUrl(url) {
  if (url && !document.hidden) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', true);
    if (window.Cypress) {
      return;
    }
    link.click();
  }
}
