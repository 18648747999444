import { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from 'countries-list';

import { ChevronDown, ChevronUp } from 'components/Icons';
import { useForm } from 'hooks/useForm';
import { generateIssue } from 'rdx/modules/techSupport/slice';
import { useModal } from 'hooks/useModal';
import { useLoading } from 'hooks/useLoading';
import TextArea from 'components/Form/TextArea/index';
import Dropdown from 'components/Dropdown/index';
import Input from 'components/Form/Input/index';
import FormField from 'components/Form/FormField/index';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { getUser } from 'rdx/modules/users/slice';

const GenerateIssueSection = () => {
  const dispatch = useDispatch();
  const { closeModal, setModal } = useModal();

  const [isExpanded, setIsExpanded] = useState(true);
  const user = useSelector(getUser);
  const generateIssueAction = useSelector(getSessionAction('generate_issue'));
  const isLoading = useLoading({ watchRequests: [generateIssue.type] });

  const { form, fields } = useForm({
    name: {
      initialValue: user.id ? `${user.first_name} ${user.last_name}` : '',
      schema: Yup.string().required('Name is required'),
    },
    subject: {
      initialValue: '',
      schema: Yup.string().required('Subject is required'),
    },
    email: {
      initialValue: user.email ?? '',
      schema: Yup.string()
        .email('Must be a valid email address')
        .required('Email is required'),
    },
    country_code: {
      initialValue: user.country_code ?? 'US',
      schema: Yup.string().required('Country is required'),
    },
    comment: {
      initialValue: '',
      schema: Yup.string().required('Comment is required'),
    },
  }, {
    onSubmit: (values) => {
      dispatch(generateIssue({
        action: generateIssueAction,
        params: { ...values },
        onSuccess: () => {
          closeModal();
          setModal(null);
        },
      }));
    },
  });

  const countryOptions = Object.entries(countries).map(([countryCode, { name }]) => ({
    value: countryCode,
    display: name,
  }));

  return (
    <>
      <TitleContainer onClick={() => setIsExpanded((prev) => !prev)}>
        <Title>I have a suggestion</Title>
        {isExpanded ? (
          <ChevronDown data-testid="collapse-issue-section" />
        ) : (
          <ChevronUp data-testid="expand-issue-section" />
        )}
      </TitleContainer>
      {isExpanded && (
        <IssueForm {...form.props}>
          <FormField
            compact
            label="name"
            component={Input}
            field={fields.name}
          />
          <FormField
            compact
            label="email"
            component={Input}
            field={fields.email}
          />
          <FormField
            compact
            label="country"
            component={Dropdown}
            inputProps={{ filter: true, options: countryOptions }}
            field={fields.country_code}
          />
          <FormField
            compact
            label="subject"
            component={Input}
            field={fields.subject}
          />
          <FormField
            compact
            label="comment box"
            component={TextArea}
            field={fields.comment}
            inputProps={{ height: '90px' }}
          />
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              text="submit"
              disabled={!form.isValid || form.isSubmitting}
              loading={isLoading}
            />
          </ButtonContainer>
        </IssueForm>
      )}
    </>

  );
};

export default GenerateIssueSection;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.h2`
  font-weight: bold;
`;

const IssueForm = styled.form`
  padding: 25px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
