import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { usePrevious } from 'hooks/usePrevious';
import { getApiVersion } from 'rdx/modules/auth/slice';

export const useApiVersionRefresh = () => {
  const dispatch = useDispatch();
  const apiVersion = useSelector(getApiVersion);
  const prevVersion = usePrevious(apiVersion);

  useEffect(() => {
    if (apiVersion && prevVersion && apiVersion !== prevVersion) {
      dispatch(newMessageEvent({ text: 'New Application Version Detected, Page will Reload' }));
      setTimeout(() => {
        document.location.reload();
      }, 1000);
    }
  }, [apiVersion, dispatch, prevVersion]);
};
