import { useCallback, useMemo, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useModal } from 'hooks/useModal';
import ContextualDropdown, { ContextualDropdownItem } from 'components/ContextualDropdown';
import ProfileModal from 'containers/Modals/ProfileModal';
import OrgProfileModal from 'containers/Modals/OrgProfileModal';
import CreateOrgModal from 'components/CreateOrgModal';
import { getOrganizations, getCurrentOrganization, getCurrentOrganizationLicense } from 'rdx/modules/organization/slice';

import globals from 'styles/globals';
import { getSessionAction, getSessionLink, requestLogout } from 'rdx/modules/auth/slice';
import { useHistory } from 'react-router-dom';
import { getUser } from 'rdx/modules/users/slice';
import OrgPin from '../OrgPin';
import { useMixpanel } from 'hooks/useMixpanel';
import { NotificationBellIcon } from 'components/Icons';
import NotificationCenter from 'components/NotificationBar/NotificationCenter';
import { getNotificationEvents, requestNotificationEvents } from 'rdx/modules/notificationEvents/slice';
import Badge from 'components/Badge';

let fetchedNotifications = false;

const NotificationBar = () => {
  const mixpanel = useMixpanel();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { callModal, closeModal, setModal } = useModal();

  const [showNotifications, setShowNotifications] = useState(false)

  const notificationEvents = useAppSelector(getNotificationEvents)?.unwrap();
  const user = useAppSelector(getUser);
  const createOrgAction = useAppSelector(getSessionAction('create_organization'));
  const organizations = useAppSelector(getOrganizations);
  const currentOrganization = useAppSelector(getCurrentOrganization);
  const license = useAppSelector(getCurrentOrganizationLicense);
  const eventNoticesLink = useAppSelector(getSessionLink('event_notices'));

  const isTrialLicense = license?.attributes?.pn === 'FJBDRC-PREM-TRIAL';

  const unreadNotificationsCount = Array.isArray(notificationEvents) ? notificationEvents.filter((event) => {
    return !event.attributes.viewed_at
  }).length : 0;

  const fetchNotificationEvents = useCallback(() => {
    if (eventNoticesLink) {
      dispatch(requestNotificationEvents({
        link: eventNoticesLink,
      }))
    }
  }, [dispatch, eventNoticesLink]);

  useEffect(() => {
    if (!fetchedNotifications) {
      fetchNotificationEvents();
      fetchedNotifications = true;
    }
  }, [fetchNotificationEvents]);

  const userActions = useMemo(() => {
    const handleCancel = () => {
      closeModal();
      setModal(null);
    };

    const newUserActions = [{
      name: 'Sign Out',
      action: () => {
        mixpanel?.track('Clicked Sign Out')
        dispatch(requestLogout({ onSuccess: () => {
          mixpanel?.track('Logout');
          mixpanel?.reset();
          history.push('/login');
        } }));
      },
    }];

    newUserActions.push(
      {
        name: 'My Profile',
        action: () => {
          callModal(
            <ProfileModal
              key={uuid()}
              onCancel={handleCancel}
            />
          );
        },
      }
    );

    if (currentOrganization?.id) {
      newUserActions.push(
        {
          name: 'Organization Profile',
          action: () => {
            callModal(
              <OrgProfileModal
                key={uuid()}
              />
            );
          },
        }
      );
    }

    if (createOrgAction) {
      newUserActions.push(
        {
          name: 'Create an Organization',
          action: () => {
            callModal(
              <CreateOrgModal key={uuid()} />
            );
          },
        },
      );
    }

    return newUserActions;
  }, [callModal, closeModal, createOrgAction, currentOrganization?.id, dispatch, history, mixpanel, setModal]);

  const handleSelectOrg = (item: ContextualDropdownItem<string>) => {
    const pinned = user?.attributes?.settings?.pinned_tab;
    history.push(`/${item.value}/${pinned ?? 'home'}`);
  };

  const items = useMemo(() => organizations.map((org) => ({
    name: org?.attributes?.name,
    value: org?.attributes?.slug,
  })), [organizations]);

  return (
    <Container>
      {isTrialLicense && (
        <p style={{ color: globals.colors.primaryLight, fontStyle: 'italic', marginRight: '30px' }}>Premium Free Trial Active</p>
      )}
      <NotificationClickableArea onClick={() => setShowNotifications((p) => !p)}>
        <NotificationBellIcon
          size="md"
          style={{ marginRight: '15px' }}
        />
        {unreadNotificationsCount > 0 && (
          <Badge
            type="alert"
            text={unreadNotificationsCount}
            width="10px"
            className="unread-notifications-badge"
          />
        )}
      </NotificationClickableArea>
      <NotificationCenter isOpen={showNotifications} setIsOpen={setShowNotifications}/>
      <OrgPin
        currentOrg={currentOrganization}
      />
      <OrgDropdown
        id="org-select-dropdown"
        items={items}
        color={colors.fontLight}
        display={currentOrganization?.attributes?.name || 'loading..'}
        isOptionSelect
        handleSelectItem={handleSelectOrg}
      />
      <UserDropdown
        id="user-dropdown"
        items={userActions}
        color={colors.fontLight}
        display={user ? `${user.attributes.first_name} ${user.attributes.last_name}` : 'Loading...'}
      />
    </Container>
  );
};

export default NotificationBar;

const { colors } = globals;

export const UserDropdown = styled(ContextualDropdown)`
  margin: 0 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NotificationClickableArea = styled.div`
  .unread-notifications-badge {
    position: absolute;
    transform: scale(0.8);
    border-radius: 100px;
    top: -5px;
    left: 10px;
    padding: 1px 10px;
  }

  :hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.primary};
      transition: ${({ theme }) => `fill ${theme.animations.transitionFast}`};
    }
  }
`;

const OrgDropdown = styled(ContextualDropdown<string>)`
  margin: 0 24px 0 10px;
`;
