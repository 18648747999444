import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* patchProcedure({ payload }) {
  const { action, values, onSuccess } = payload;
  const { success, data, error } = yield* makeRequest.action(action, values);
  if (success && data) {
    if (onSuccess) {
      onSuccess();
    }
    yield put(newMessageEvent({ text: 'Procedure Updated' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default patchProcedure;
