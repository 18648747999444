import styled, { css } from 'styled-components';
import globals from 'styles/globals';

export const InputContainer = styled.div<{ width: string }>`
  display: inline-flex;
  position: relative;
  width: ${({ width }) => width};
`;

export const RemoveIcon = styled.div.attrs(() => ({
  children: '+',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30px;
  color: white;
  transform: rotateZ(45deg);
  cursor: pointer;
`;

export const InputElement = styled.div<{
  fontSize?: string,
  width?: string,
  backgroundColor?: string,
  rounded?: boolean,
  borderless?: boolean,
  borderColor?: string,
  searchIcon?: boolean,
  disabled?: boolean,
}>`
  ${({ fontSize }) => fontSize && css`font-size: ${fontSize}`};
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: ${({ width }) => (width ?? '50px')};
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
  border-radius: ${(props) => (props.rounded ? 17.5 : 2)}px;
  border: ${({ borderless, borderColor }) => (borderless ? '' : `1px solid ${borderColor}`)};
  outline: none;
  z-index: 2;

  input {
    position: relative;
    height: ${({ borderless }) => (borderless ? '40px' : '38px')};
    flex: 1;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 9px ${({ rounded }) => (rounded ? 20 : 12)}px;
    padding-left: ${({ rounded, searchIcon }) => (12 + (rounded ? 10 : 0) + (searchIcon ? 20 : 0))}px;
    background-color: transparent;

    &::placeholder {
      color: ${globals.colors.darkerGrey};
    }
    &::-ms-clear {
      display: none;
    }
  }

  ${({ disabled }) => disabled
    && css`
      background-color: ${globals.colors.darkGrey};
    `}
`;

export const IconWrapperLeading = styled.div<{ rounded?: boolean }>`
  z-index: 2;
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: ${(props) => (props.rounded ? 40 : 30)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapperTrailing = styled.div<{ rounded?: boolean }>`
  z-index: 2;
  position: absolute;
  right: 3px;
  top: 0;
  bottom: 0;
  width: ${(props) => (props.rounded ? 40 : 30)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;