import { useState, useEffect } from 'react';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import bdLogo from 'assets/bd_research_cloud.png';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import Checkbox from 'components/Formik/Checkbox';
import MarkdownHtml from 'components/MarkdownHtml';
import InfoReqButton from 'components/InfoRequirements/InfoReqButton';
import { getEULA, getTermsOfUse, requestEULA, requestTermsOfUse } from 'rdx/modules/systemDocs/slice';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { updateUser } from 'rdx/modules/users/slice';
import PolicyTranslationsLink from 'components/PolicyTranslationsLink';

const UserAgreement = ({ requirement, incrementStep, currentStep, totalSteps }) => {
  const dispatch = useAppDispatch();
  const eula = useAppSelector(getEULA);
  const termsOfUse = useAppSelector(getTermsOfUse);
  const updateProfileAction = useAppSelector(getSessionAction('update_profile_sign_in_requirements'));

  let document = '';

  if (requirement === 'eula_ver') {
    document = eula;
  }

  if (requirement === 'terms_of_use_ver') {
    document = termsOfUse;
  }

  useEffect(() => {
    if (requirement === 'eula_ver') {
      dispatch(requestEULA());
    } else if (requirement === 'terms_of_use_ver') {
      dispatch(requestTermsOfUse());
    }
  }, [dispatch, requirement]);

  const [checked, setChecked] = useState(false);

  const handleMarcomUpdate = () => {
    dispatch(updateUser({
      action: updateProfileAction,
      values: {
        marcom_opt_in: checked,
      },
      onSuccess: () => {
        setChecked(false);
        incrementStep();
      },
    }));
  };
  const handleEngLangReqUpdate = () => {
    dispatch(updateUser({
      action: updateProfileAction.withoutFields(['marcom_opt_in']),
      values: {
        eng_lang_opt_in: checked,
      },
      onSuccess: () => {
        setChecked(false);
        incrementStep();
      },
    }));
  };

  const handleUpdateUser = () => {
    if (requirement === 'marcom_opt_in_req_at') {
      handleMarcomUpdate();
    } else if (requirement === 'eng_lang_req_at') {
      handleEngLangReqUpdate();
    } else {
      const action = updateProfileAction.withoutFields(['marcom_opt_in']);
      dispatch(updateUser({
        action,
        values: {
          [requirement]: document?.data?.attributes?.version,
        },
        onSuccess: () => {
          setChecked(false);
          incrementStep();
        },
      }));
    }
  };

  const content = document?.data?.attributes?.content_html || `Error: ${document?.data?.attributes?.category} loaded with no content`;
  const opts = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: { img: ['src'] },
    allowedSchemes: ['data', 'https'],
  };

  const html = sanitizeHtml(content, opts);

  const renderMarcomContent = () => (
    <>
      <MarcomTitle>Marketing Communication</MarcomTitle>
      <Text>
        <p>BD is committed to keeping your personal data protected and secure. More information on how we protect your personal data can be found in our privacy statement <a href="https://bdresearchcloud.com/privacy" target="_blank" rel="noreferrer">BD Research Cloud</a></p>
        <p>Keep in touch with us:</p>
      </Text>
      <CheckboxContainer>
        <SCheckbox
          data-testid="agreement-checkbox"
          value={checked}
          onChange={() => setChecked((prev) => !prev)}
        />
        <Labels>
          <Label>I am 18 or older and have seen the updated policies described above.</Label>
          <Label>
            Yes, I&rsquo;d like to receive occasional information by email about solutions and services related to this topic. I know I can unsubscribe from BD news at any time.
          </Label>
        </Labels>
      </CheckboxContainer>
      <PolicyTranslationLinkContainer>
        <PolicyTranslationsLink />
      </PolicyTranslationLinkContainer>
    </>
  );

  const renderEngLangReq = () => (
    <>
      <MarcomTitle>English Language Requirement</MarcomTitle>
      <Text>
        <p>The Site is administered by BD in and from the United States. This Site and its contents are provided in English only.  I agree to conduct any transaction on this website in English.</p>
      </Text>
      <Text>
        <p>
          Le site est administré par BD à l’intérieur et à partir des États-Unis. Le site et son contenu ne sont fournis qu’en anglais. Je consens à effectuer toutes les transactions sur ce site Web en anglais.
        </p>
      </Text>
      <CheckboxContainer>
        <SCheckbox
          data-testid="agreement-checkbox"
          value={checked}
          onChange={() => setChecked((prev) => !prev)}
        />
        <Labels>
          <Label>I am 18 or older and have seen the updated policies described above.</Label>
          <Label>
            I agree
          </Label>
        </Labels>
      </CheckboxContainer>
    </>
  );

  const renderContent = () => {
    if (requirement === 'marcom_opt_in_req_at') {
      return renderMarcomContent();
    } if (requirement === 'eng_lang_req_at') {
      return renderEngLangReq();
    }
    return (
      <>
        <Text>
          {!!document?.data?.attributes?.title && <Title>{document?.data?.attributes?.title}</Title>}
          <MarkdownHtml dangerouslySetInnerHTML={{ __html: html }} />
        </Text>
        <CheckboxContainer>
          <SCheckbox
            data-testid="agreement-checkbox"
            value={checked}
            onChange={() => setChecked((prev) => !prev)}
          />
          <Labels>
            <Label>I am 18 or older and have seen the updated policies described above.</Label>
            <Label>
              I have read and agreed to the{' '}
              {(requirement === 'eula_ver') && 'end-user license agreement shown above.'}
              {(requirement === 'terms_of_use_ver') && 'terms of use and acceptable use policy shown above.'}
            </Label>
          </Labels>
        </CheckboxContainer>
        <PolicyTranslationLinkContainer>
          <PolicyTranslationsLink />
        </PolicyTranslationLinkContainer>
      </>
    );
  };

  return (
    <>
      <Container>
        <CropImage>
          <BDLogo src={bdLogo} alt="BD Research Cloud" />
        </CropImage>
        {renderContent()}
      </Container>
      <InfoReqButton
        disabled={(requirement !== 'marcom_opt_in_req_at') && (!checked || !updateProfileAction)}
        onClick={() => handleUpdateUser()}
        currentStep={currentStep}
        totalSteps={totalSteps}
      />
      {(!checked && requirement !== 'marcom_opt_in_req_at') && (
        <WarningText>
          Scroll to the bottom of policy and check box
        </WarningText>
      )}
    </>
  );
};

export default UserAgreement;

const Title = styled.div`
  font-weight: 500;
  font-size: 16pt;
  display: flex;
  justify-content: flex-end;
`;
const MarcomTitle = styled.div`
  font-weight: 500;
  font-size: 16pt;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
`;

const BDLogo = styled.img`
  transform: scale(.3) translateX(-1000px);
`;

const CropImage = styled.div`
  height: 75px;
  width: 125px;
  overflow: hidden;
  margin-left: -25px;
`;

const Container = styled.div`
  height: 490px;
  max-width: 700px;
  overflow: auto;
  border: 1px solid lightgrey;
`;

const SCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: center;
  margin: 15px 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px;
  padding: 10px;
  gap: 20px;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.div`
  text-transform: uppercase;
`;

const Text = styled.div`
  margin: 40px 15px 15px 15px;
`;

const WarningText = styled.div`
  width: 100%;
  font: 400 9pt Roboto,sans-serif;
  text-align: right;
`;

const PolicyTranslationLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 25px;
  margin-bottom: 20px;
  font-size: 11px;
`;
