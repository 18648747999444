import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const Upload = forwardRef((props, ref) => (
  <svg ref={ref} {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M15 13.91l1.41-1.41-4.41-4.41-4.41 4.41 1.41 1.41 2-2v11.090h2v-11.090l2 2z" />
    <path d="M17 2h-10c-1.657 0-3 1.343-3 3v0 10c0 1.657 1.343 3 3 3v0h3v-2h-3c-0.552 0-1-0.448-1-1v0-10c0-0.552 0.448-1 1-1v0h10c0.552 0 1 0.448 1 1v0 10c0 0.552-0.448 1-1 1v0h-3v2h3c1.657 0 3-1.343 3-3v0-10c0-1.657-1.343-3-3-3v0z" />
  </svg>
));

export default createIcon(Upload);
