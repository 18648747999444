import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const ControlsIcon = forwardRef((props, ref) => (
  <svg ref={ref} {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M17.79 4c-0.396-0.889-1.272-1.497-2.29-1.497s-1.894 0.608-2.284 1.481l-0.006 0.016h-10.21v2h10.21c0.396 0.889 1.272 1.497 2.29 1.497s1.894-0.608 2.284-1.481l0.006-0.016h3.21v-2z" />
    <path d="M8.5 8.5c-1.018 0.001-1.894 0.61-2.284 1.484l-0.006 0.016h-3.21v2h3.21c0.396 0.889 1.272 1.497 2.29 1.497s1.894-0.608 2.284-1.481l0.006-0.016h10.21v-2h-10.21c-0.396-0.89-1.272-1.499-2.29-1.5h-0z" />
    <path d="M12.5 14.5c-1.018 0.001-1.894 0.61-2.284 1.484l-0.006 0.016h-7.21v2h7.19c0.396 0.889 1.272 1.497 2.29 1.497s1.894-0.608 2.284-1.481l0.006-0.016h6.23v-2h-6.21c-0.396-0.89-1.272-1.499-2.29-1.5h-0z" />
  </svg>
));

export default createIcon(ControlsIcon);
