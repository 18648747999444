import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestGroups } from '../slice';

function* updateGroup({ payload: { action, params } }) {
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    yield put(requestGroups());
    yield put(newMessageEvent({ text: 'Group Updated' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default updateGroup;
