import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';

type ProjectsState = ResourceList;

const initialState: ProjectsState = new ResourceList();

const requestProjects = createAction('requestProjects');
const requestProjectsByGroup = createAction('requestProjectsByGroup');
const createProject = createAction('createProject');
const updateProject = createAction('updateProject');
const deleteProject = createAction('deleteProject');
const createProjectStudy = createAction('createProjectStudy');
const updateProjectStudy = createAction('updateProjectStudy');
const deleteProjectStudy = createAction('deleteProjectStudy');
const requestMoreProjects = createAction('requestMoreProjects');

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (_, action: PayloadAction<ResourceList>) => action.payload,
    setMoreProjects: (state, action: PayloadAction<ResourceList>) => state.mergeWith(action.payload),
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const { setProjects, setMoreProjects } = projectsSlice.actions;

// Actions
export {
  requestProjects,
  requestProjectsByGroup,
  createProject,
  updateProject,
  deleteProject,
  createProjectStudy,
  updateProjectStudy,
  deleteProjectStudy,
  requestMoreProjects,
  setProjects,
  setMoreProjects,
};

// Selectors
export const getProjects = (state: RootState) => state.projects;

// Reducer
export default projectsSlice.reducer;
