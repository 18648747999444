import { put } from 'redux-saga/effects';
import { GroupWorkflowsParams, OrgWorkflowsParams, WorkflowsQueryParams, getGroupWorkflows, requestOrgWorkflows } from '../slice';
import type { PayloadActionWithGenericPayload } from 'types/redux-types';

function* requestDashboardWorkflowsSaga({ payload }: PayloadActionWithGenericPayload<OrgWorkflowsParams & GroupWorkflowsParams & WorkflowsQueryParams>) {
  const { params } = payload;
  if (params && params.group_id) {
    yield put(getGroupWorkflows(payload));
    return null;
  }
  if (params && params.organization_id) {
    yield put(requestOrgWorkflows(payload));
    return null;
  }
  return null;
}

export default requestDashboardWorkflowsSaga;
