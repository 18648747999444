import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import { setHotspotMatrix } from '../slice';

function* requestHotspotData({ payload: { query, action } }: PayloadActionWithGenericPayload) {
  const { success, data, error } = yield makeRequest.action(action, { ...query });
  if (success && data) {
    yield put(setHotspotMatrix(data));
  } else if (error) {
    yield* putErrorActions({ error });
  }

  return null;
}

export default requestHotspotData;
