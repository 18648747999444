import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { PinIcon } from 'components/Icons';
import ToolTip from 'components/ToolTip';
import { useTooltip } from 'hooks/useTooltip';
import { getSessionActions } from 'rdx/modules/auth/slice';
import { getUser, updateUser } from 'rdx/modules/users/slice';

const OrgPin = ({ currentOrg }) => {
  const user = useSelector(getUser);
  const sessionActions = useSelector(getSessionActions) || [];
  const dispatch = useDispatch();
  const { callTooltip, closeTooltip } = useTooltip();
  const updateProfileAction = user.getAction('update') || sessionActions.find((a) => a.name === 'update_profile');
  const orgId = currentOrg?.attributes?.uuid;
  const pinnedOrg = orgId === user.settings?.default_org_uuid;

  const handlePin = () => {
    dispatch(updateUser({
      action: updateProfileAction,
      values: {
        default_org_uuid: pinnedOrg ? '' : orgId,
      },
    }));
  };

  if (!orgId || !updateProfileAction) {
    return null;
  }
  return (
    <PinContainer
      pinnedOrg={pinnedOrg}
      data-testid="org-pin"
      onMouseOver={(e) => callTooltip(<ToolTip message={pinnedOrg ? 'Unselect as your\ndefault organization' : 'Make this your\ndefault organization'} child={e} />)}
      onFocus={(e) => callTooltip(<ToolTip message={pinnedOrg ? 'Unselect as your\ndefault organization' : 'Make this your\ndefault organization'} child={e} />)}
      onBlur={closeTooltip}
      onMouseLeave={closeTooltip}
    >
      <PinIcon size="18px" onClick={handlePin} pinnedOrg={pinnedOrg} />
    </PinContainer>
  );
};

OrgPin.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentOrg: PropTypes.object,
};

OrgPin.defaultProps = {};

export default OrgPin;

const PinContainer = styled.div`
  display: flex;
  align-items: center;
`;
