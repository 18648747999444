import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionLinks } from 'rdx/modules/auth/slice';
import { getCurrentOrganization, setOrganizationSettings } from '../slice';

function* getOrganizationSettings() {
  const organizationID = (yield select(getCurrentOrganization))?.attributes?.slug;
  const sessionLinks = yield select(getSessionLinks);
  const link = sessionLinks.organization_settings;

  const { success, data, error } = yield* makeRequest.link(link, { organizationID });
  if (success && data) {
    yield put(setOrganizationSettings(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getOrganizationSettings;
