import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setPrivacyPolicy } from 'rdx/modules/systemDocs/slice';
import { getSessionLinks } from 'rdx/modules/auth/slice';

function* getPrivacyPolicy() {
  const sessionLinks = yield select(getSessionLinks);
  const link = sessionLinks.privacy_policy;

  const { success, data, error } = yield* makeRequest.link(link);
  if (success && data) {
    yield put(setPrivacyPolicy(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getPrivacyPolicy;
