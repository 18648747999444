import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { setGroupUser } from 'rdx/modules/users/slice';

function* getGroupUser({ payload }) {
  const action = yield select(getSessionAction('group_user'));
  const { success, data, error } = yield* makeRequest.action(action, payload);
  if (success && data) {
    yield put(setGroupUser(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getGroupUser;
