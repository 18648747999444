import { forwardRef } from 'react';

import createIcon, { IconProps } from '../createIcon';

const SortingAsc = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg ref={ref} {...props} width="200pt" height="200pt" viewBox="300 40 100 460">
    <g>
      <path d="m152.4 354.69-26.398 26.391 61.473 61.453c7.293 7.293 19.125 7.293 26.398 0l61.465-61.453-26.398-26.414-29.602 29.605v-272.27h-37.336v272.27z" />
      <path d="m312.66 149.34h93.336v37.336h-93.336z" />
      <path d="m312.66 224h149.34v37.336h-149.34z" />
      <path d="m312.66 298.66h205.34v37.336h-205.34z" />
      <path d="m312.66 373.34h261.34v37.336h-261.34z" />
    </g>
  </svg>
));

const SortingDesc = forwardRef<SVGSVGElement, IconProps>(({ color, size, ...props }, ref) => (
  <svg ref={ref} {...props} width="200pt" height="200pt" viewBox="300 40 100 460">
    <g>
      <path d="m152.4 354.69-26.398 26.391 61.473 61.453c7.293 7.293 19.125 7.293 26.398 0l61.465-61.453-26.398-26.414-29.602 29.605v-272.27h-37.336v272.27z" />
      <path d="m312.66 373.34h93.336v37.328h-93.336z" />
      <path d="m312.66 298.66h149.34v37.336h-149.34z" />
      <path d="m312.66 224h205.34v37.336h-205.34z" />
      <path d="m312.66 149.34h261.34v37.336h-261.34z" />
    </g>
  </svg>
));

const Sorting = forwardRef<SVGSVGElement, IconProps>(({ desc, ...props }, ref) => (
  desc ? <SortingDesc ref={ref} {...props} /> : <SortingAsc ref={ref} {...props} />
));

export default createIcon(Sorting);
