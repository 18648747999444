import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import globals from 'styles/globals';
import customPropTypes from 'lib/customPropTypes';
import getUserInitials from 'lib/utils/getUserInitials';
import { get } from 'lodash';
import Resource from '../../lib/jsonApi/Resource';

const UserAvatar = ({ user, size, showName, isActive, showInfo, noDot, highlighted, truncate, testid, id, flex }) => {
  const imageUrl = get(user, 'attributes.profile_image_url', '');
  const userInitials = imageUrl ? '' : getUserInitials(user) || '';
  const fullNameString = (user?.attributes?.first_name && user?.attributes?.last_name)
    ? `${user?.attributes?.first_name} ${user?.attributes?.last_name}`
    : '';
  const fullName = (truncate && fullNameString?.length)
    ? `${fullNameString?.substring(0, 17)}${(fullNameString?.length < 18) ? '' : '...'}`
    : fullNameString;
  const orgUserStatus = user?.attributes?.org_user_unconfirmed ? '(not in organization)' : '';
  const email = truncate
    ? `${user?.attributes?.email?.substring(0, 17)}${(user?.attributes?.email?.length < 18) ? '' : '...'}`
    : `${user?.attributes?.email}`;
  return (
    <AvatarContainer flex={flex} data-testid={testid} id={id}>
      {noDot
        ? null
        : (
          <AvatarImg url={imageUrl} size={size} {...{ isActive }}>
            <Initials>{userInitials}</Initials>
          </AvatarImg>
        )}
      {showInfo && (
        <UserInfoContainer>
          {fullName?.length
            ? (
              <UserInfo>
                <PrimaryText highlighted={highlighted}>
                  {fullName}
                  <OrgUserStatus> {orgUserStatus}</OrgUserStatus>
                </PrimaryText>
                <SubText highlighted={highlighted} title={`${user?.attributes?.email}`}>
                  {email}
                </SubText>
              </UserInfo>
            )
            : (
              <PrimaryText highlighted={highlighted} title={`${user?.attributes?.email}`}>
                {user?.attributes?.email || ''}
              </PrimaryText>
            )}
        </UserInfoContainer>
      )}
      {showName && (
        <UserInfo>
          <UserName {...{ isActive, highlighted }}>
            {fullName}
            <OrgUserStatus> {orgUserStatus}</OrgUserStatus>
          </UserName>
          <UserName {...{ isActive, highlighted }} title={`${user?.attributes?.email}`}>
            {email || ''}
          </UserName>
        </UserInfo>
      )}
    </AvatarContainer>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.oneOfType([
    customPropTypes.resource,
    PropTypes.string, // TODO (Rane): Do we actually want this?
    PropTypes.shape({
      attributes: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        profile_image_url: PropTypes.string,
      }),
    }),
  ]),
  size: PropTypes.string,
  showName: PropTypes.bool,
  showInfo: PropTypes.bool,
  isActive: PropTypes.bool,
  truncate: PropTypes.bool,
  noDot: PropTypes.bool,
  highlighted: PropTypes.bool,
  testid: PropTypes.string,
  id: PropTypes.string,
  flex: PropTypes.string,
};

UserAvatar.defaultProps = {
  user: new Resource({ type: 'users', attributes: {} }),
  size: '25px',
  showName: false,
  showInfo: false,
  isActive: false,
  truncate: false,
  noDot: false,
  highlighted: false,
  testid: 'user-avatar',
  id: '',
  flex: '',
};

export default UserAvatar;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 146px;
  flex: ${({ flex }) => flex || 1};
  & > * {
    flex: 0 0 auto;
  }
`;
const AvatarImg = styled.div`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  margin-right: 5px;
  background-image: url(${(props) => props.url});
  background-color: ${({ isActive }) => (isActive ? globals.colors.black : globals.colors.darkerGrey)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Initials = styled.span`
  color: ${globals.colors.white};
  font: ${globals.fonts.label};
`;

const UserName = styled.div`
  color: ${({ isActive }) => (isActive ? globals.colors.fontDark : globals.colors.fontLight)};
  font-weight: ${({ isActive }) => (isActive ? 500 : 'inherit')};
  font-size: 12px;
  ${({ highlighted }) => highlighted && css`
    color: ${({ theme }) => theme.colors.white};
  `};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 85%;
`;

const OrgUserStatus = styled.span`
  font-size: 11px;
`;

const UserInfoContainer = styled.div`

`;

export const PrimaryText = styled.div`
  color: ${globals.colors.fontDark};
  font-size: 15px;
  ${({ highlighted }) => highlighted && css`
    color: ${({ theme }) => theme.colors.white};
  `};
`;

export const SubText = styled.div`
  color: ${globals.colors.fontLight};
  font-size: 12px;
  ${({ highlighted }) => highlighted && css`
    color: ${({ theme }) => theme.colors.white};
  `};
`;

const UserInfo = styled.div`
  text-align: left;
  width: 100%;
`;
