import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';
import Resource from 'lib/jsonApi/Resource';

type LicensesState = {
  licenses: Resource[];
  licenseQuoteURL: string;
};

const initialState: LicensesState = {
  licenses: [],
  licenseQuoteURL: '',
};

const requestLicenses = createAction('requestLicenses');
const requestLicenseQuoteURL = createAction('requestLicenseQuoteURL');

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    setLicenses: (state, action: PayloadAction<Resource[]>) => {
      state.licenses = action.payload;
    },
    setLicenseQuoteURL: (state, action: PayloadAction<string>) => {
      state.licenseQuoteURL = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setLicenses,
  setLicenseQuoteURL,
} = licensesSlice.actions;

export {
  requestLicenses,
  requestLicenseQuoteURL,
  setLicenses,
  setLicenseQuoteURL,
};

export const getLicenses = (state: RootState) => state.licenses.licenses;
export const getLicenseQuoteURL = (state: RootState) => state.licenses.licenseQuoteURL;

export default licensesSlice.reducer;
