import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import requestSaga from 'rdx/api-utils/requestSaga';
import { requestWspData, setWspData } from 'rdx/modules/wspData/slice';

function* watchWspDataSagas() {
  yield trackRequests(takeEvery, requestWspData.type, (rdxAction) => requestSaga({ rdxAction, putData: setWspData }));
}

export default watchWspDataSagas;
