/* eslint-disable import/prefer-default-export */
import { useMediaQuery } from 'react-responsive';

export const BREAKPOINTS = {
  mobile: 576,
  smallDevice: 768,
  tablet: 992,
  largeDevice: 1200,
};

export const useDeviceBreakpoints = () => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.mobile });
  const isSmallDevice = useMediaQuery({ minWidth: BREAKPOINTS.mobile + 1, maxWidth: BREAKPOINTS.smallDevice });
  const isTablet = useMediaQuery({ minWidth: BREAKPOINTS.smallDevice + 1, maxWidth: BREAKPOINTS.tablet });
  const isLargeDevice = useMediaQuery({ minWidth: BREAKPOINTS.tablet + 1, maxWidth: BREAKPOINTS.largeDevice });
  const isExtraLargeDevice = useMediaQuery({ minWidth: BREAKPOINTS.largeDevice + 1 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isRetina = useMediaQuery({ minResolution: '2dppx' });

  return { isMobile, isSmallDevice, isTablet, isLargeDevice, isExtraLargeDevice, isPortrait, isRetina };
};
