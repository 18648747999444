/* eslint-disable max-len */
import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';
import { requestPanelReagents, requestHybrisCartUrl, setPanelReagents, requestPanelCytometerMatching, setPanelCytometerMatching } from './slice';

function* watchPanelDetailsSagas() {
  yield trackRequests(takeLatest, requestPanelReagents, (rdxAction) => requestSaga({ rdxAction, putData: setPanelReagents }));
  yield trackRequests(takeLatest, requestPanelCytometerMatching, (rdxAction) => requestSaga({ rdxAction, putData: setPanelCytometerMatching }));
  yield trackRequests(takeLatest, requestHybrisCartUrl, (rdxAction) => requestSaga({ rdxAction }));
}

export default watchPanelDetailsSagas;
