import { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { ChevronDown, ChevronUp } from 'components/Icons';
import TechSupportEmailLink from 'containers/Modals/TechSupportModal/TechSupportEmailLink';
import { getSession } from 'rdx/modules/auth/slice';

const BugSection = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { bdb_lang } = useSelector(getSession);

  const techSupportLink = `https://www.bdbiosciences.com/${bdb_lang || 'en-us'}/support`;

  return (
    <>
      <TitleContainer onClick={() => setIsExpanded((prev) => !prev)}>
        <Title>Something isn&apos;t working</Title>
        {isExpanded ? (
          <ChevronDown data-testid="collapse-bug-section" />
        ) : (
          <ChevronUp data-testid="expand-bug-section" />
        )}
      </TitleContainer>
      {isExpanded && (
        <List>
          <li>Technical support for BD Research Cloud or FlowJo software, please email <TechSupportEmailLink /> or call (800) 366-6045</li>
          <li>Technical support for BD Biosciences Instruments and Reagents, <a target="_blank" rel="noopener noreferrer" href={techSupportLink}>click here</a></li>
        </List>
      )}
    </>

  );
};

export default BugSection;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.h2`
  font-weight: bold;
`;

const List = styled.ul`
  margin-block-start: 5px;
  margin-block-end: 5px;
  padding-inline-start: 30px;

  li {
    margin-bottom: 5px;
  }
`;
