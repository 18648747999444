import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import getTerms from 'rdx/modules/systemDocs/sagas/getTerms';
import getPrivacyPolicy from 'rdx/modules/systemDocs/sagas/getPrivacyPolicy';
import getEULA from 'rdx/modules/systemDocs/sagas/getEULA';
import getFaq from 'rdx/modules/systemDocs/sagas/getFaq';
import getTrademarks from 'rdx/modules/systemDocs/sagas/getTrademarks';
import getReleaseNotes from 'rdx/modules/systemDocs/sagas/getReleaseNotes';
import { requestEULA, requestFAQ, requestPrivacyPolicy, requestReleaseNotes, requestTermsOfUse, requestTrademarks } from 'rdx/modules/systemDocs/slice';

function* watchSystemDocSagas() {
  yield trackRequests(takeLatest, requestPrivacyPolicy.type, getPrivacyPolicy);
  yield trackRequests(takeLatest, requestTermsOfUse.type, getTerms);
  yield trackRequests(takeLatest, requestEULA.type, getEULA);
  yield trackRequests(takeLatest, requestFAQ.type, getFaq);
  yield trackRequests(takeLatest, requestTrademarks.type, getTrademarks);
  yield trackRequests(takeLatest, requestReleaseNotes.type, getReleaseNotes);
}

export default watchSystemDocSagas;
