import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import { removeAuthToken } from 'lib/utils/authHelpers';
import { resetStore } from 'rdx/modules/app/slice';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Action from 'lib/jsonApi/Action';

function* logout({ payload }: PayloadActionWithGenericPayload) {
  const logoutAction: Action | undefined = yield select(getSessionAction('logout'));
  yield makeRequest.action(logoutAction);
  removeAuthToken();
  yield put(resetStore());
  payload?.onSuccess?.();
}

export default logout;
