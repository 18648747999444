import { put, select } from 'redux-saga/effects';
import { find } from 'lodash';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setCurrentGroup } from 'rdx/modules/groups/slice';
import { getSessionActions } from 'rdx/modules/auth/slice';

function* getGroupById({ payload: { id } }) {
  const sessionActions = yield select(getSessionActions);
  const action = find(sessionActions, { name: 'groups' });
  const link = `${action.url}/${id}`;

  const { success, data, error } = yield* makeRequest.link(link);
  if (success && data) {
    yield put(setCurrentGroup(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getGroupById;
