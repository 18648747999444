import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setPublicCytometers } from 'rdx/modules/cytometers/slice';

function* getPublicCytometers({ payload }) {
  const { link, params, query, onSuccess, onError } = payload;
  const { success, data, error } = yield* makeRequest.link(link, params, query);
  if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  if (success && data) {
    yield put(setPublicCytometers(data));
    if (onSuccess) {
      onSuccess();
    }
  }
  return null;
}

export default getPublicCytometers;
