import { useState } from 'react';
import * as Yup from 'yup';
import { useAppDispatch } from 'hooks/reduxHooks';
import { useForm } from 'hooks/useForm';
import FormikInput from 'components/Formik/FormikInput/index';
import PrimaryButton from 'components/Button/PrimaryButton';
import {
  Container,
  HeaderContainer,
  Header,
  Subheader,
  Form,
  FormBody,
  InputGroup,
  Label,
  Errors,
  SubmitButtonContainer,
  InfoText,
  LinkText,
  ConfirmationSubText,
  ConfirmationText,
  ConfirmationTextContainer,
} from 'containers/Auth/components';
import { createAccount } from 'rdx/modules/auth/slice';
import { useMixpanel } from 'hooks/useMixpanel';

const SignUp = () => {
  const mixpanel = useMixpanel();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const { form, fields } = useForm<{ email: string }>({
    email: {
      initialValue: '',
      schema: Yup.string()
        .email('Must be a valid email address')
        .required('Email is required'),
    },
  }, {
    onSubmit: ({ email }) => {
      dispatch(createAccount({
        params: { email },
        onSuccess: () => {
          mixpanel?.track("Started Sign Up", { email: email });
        },
      }));
      form.setSubmitting(false);
      setSubmitted(true);
    },
  });

  return (
    <Container isSignUp>
      {!submitted && (
        <>
          <HeaderContainer>
            <Header data-testid="signUp">
              Create an account
            </Header>
            <Subheader>
              Enter your email below.  You will then receive an email with instructions to set your name and password.
            </Subheader>
          </HeaderContainer>
          <Form {...form.props}>
            <FormBody>
              <InputGroup>
                <Label>Email</Label>
                <FormikInput data-testid="signup-input" {...fields.email.props} />
                <Errors errors={fields.email.visited ? fields.email.errors : []} />
              </InputGroup>
            </FormBody>
            <SubmitButtonContainer>
              <PrimaryButton
                disabled={(!form.isValid || form.isSubmitting)}
                text="Sign Up"
                type="submit"
                data-testid="signup-button"
              />
              <InfoText>
                Already have an account? <LinkText to="/login">Sign in here.</LinkText>
              </InfoText>
            </SubmitButtonContainer>
          </Form>
        </>
      )}
      <ConfirmationTextContainer show={submitted}>
        <Header>
          Success!
        </Header>
        <ConfirmationText>
          An email with instructions on how to complete registration should
          arrive at the address you provided within a few minutes if an account has not been previously registered.
        </ConfirmationText>
        <ConfirmationSubText>
          Don&apos;t see it? Be sure that BD Research Cloud is whitelisted
          in your email client or check your spam folder.
        </ConfirmationSubText>
      </ConfirmationTextContainer>
    </Container>
  );
};

export default SignUp;
