import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { setGroupUsers, setPendingGroupUsers, setUnconfirmedGroupUsers } from 'rdx/modules/users/slice';

function* getGroupUsers({ payload }) {
  const action = yield select(getSessionAction('group_users'));
  const { query } = payload;
  const { success, data, error } = yield* makeRequest.action(action, { ...payload, ...query });
  if (success && data) {
    if (payload.confirmed === '0' || query?.confirmed === '0') {
      yield put(setUnconfirmedGroupUsers(data));
    } else if (payload.is_active === '1' || query?.is_active === '1') {
      yield put(setGroupUsers(data));
    } else {
      yield put(setPendingGroupUsers(data));
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getGroupUsers;
