import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { put } from 'redux-saga/effects';

import {  newMessageEvent } from 'rdx/modules/messages/slice';

function* fcsBatchFiles({ payload }) {
  const { action, params, onSuccess } = payload;
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (success) { 
    const filesCount = data.unwrap().attributes.files_count
    const actionText = () => {
      switch (action.name) {
        case 'bulk_fcs_titration':
          return `Image generation in progress. ${filesCount} fcs file${filesCount > 1 ? 's' : ''} ${filesCount > 1 ? 'were' : 'was'} used.`
        case 'bulk_fcs_to_wsp':
          return `${filesCount} fcs file${filesCount > 1 ? 's' : ''} ${filesCount > 1 ? 'were' : 'was'} used to generate a WSP file.`
        default: 
          return `${filesCount} fcs file${filesCount > 1 ? 's' : ''} ${filesCount > 1 ? 'were' : 'was'} used.`
      }}
    yield put(newMessageEvent({ text: `${actionText()}`, persist: action.name === 'bulk_fcs_titration' ? true : false }));
    onSuccess?.();
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default fcsBatchFiles;
