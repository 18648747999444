import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { PanelSharingAttributes } from 'types/panelSharings';
import { GenericActionPayload } from 'types/redux-types';

type RequestPanelSharingsQuery = {
  pageSize?: number,
  page?: number,
}

export type UpdatePanelParams = {
  expire_in_number: number,
  expire_in_type: 'hours' | 'days',
  email?: string,
}

export type SharePanelParams = UpdatePanelParams & {
  email: string,
}

export type CloneSharedPanelParams = {
  name: string,
  organizationID: string,
}

const initialState: {
  list: ResourceList<PanelSharingAttributes> | null,
  sharedWithCurrentUser: ResourceList<PanelSharingAttributes> | null
} = {
  list: null,
  sharedWithCurrentUser: null,
};

const panelSharingsSlice = createSlice({
  name: 'panelSharings',
  initialState,
  reducers: {
    setPanelSharings: (state, action: PayloadAction<ResourceList<PanelSharingAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.list = state.list?.mergeWith(action.payload) ?? state.list;
        return;
      }
      state.list = action.payload;
    },
    setPanelsSharedWithCurrentUser: (state, action: PayloadAction<ResourceList<PanelSharingAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.sharedWithCurrentUser = state.list?.mergeWith(action.payload) ?? state.list;
        return;
      }
      state.sharedWithCurrentUser = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const requestPanelSharings = createAction<GenericActionPayload<Record<string, any>, RequestPanelSharingsQuery>>('panelSharings/requestPanelSharings');
const requestPanelsSharedWithCurrentUser = createAction<GenericActionPayload>('panelSharings/requestPanelsSharedWithCurrentUser')
const deletePanelSharing = createAction<GenericActionPayload>('panelSharings/deletePanelSharing')
const sharePanel = createAction<GenericActionPayload<SharePanelParams | UpdatePanelParams>>('panelSharings/sharePanel');
const clonePanelSharing = createAction<GenericActionPayload>('panelSharings/clonePanelSharing');

const {
  setPanelSharings,
  setPanelsSharedWithCurrentUser,
} = panelSharingsSlice.actions;

export {
  setPanelSharings,
  setPanelsSharedWithCurrentUser,
  requestPanelSharings,
  requestPanelsSharedWithCurrentUser,
  deletePanelSharing,
  sharePanel,
  clonePanelSharing,
};

export const getPanelSharings = (state: RootState) => state.panelSharings.list;
export const getPanelsSharedWithCurrentUser = (state: RootState) => state.panelSharings.sharedWithCurrentUser;
export const getPanelsSharedWithCurrentUserByPanelId = (panelId: string) => (state: RootState) => {
  const panelSharing = state.panelSharings.sharedWithCurrentUser?.unwrap?.();
  if (Array.isArray(panelSharing)) {
    return panelSharing.find((sharing) =>  String(sharing.attributes.panel_id) === panelId)
  }
};

export default panelSharingsSlice.reducer;
