import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'rdx/modules/app/slice';
import type { RootState } from 'index';
import { NavHeaderTab } from 'components/NavHeader';

export interface NavButton {
  id?: string;
  component?: React.ReactElement;
  tooltip?: string;
  text?: string;
  onClick?: () => void;
  permissionType?: 'organization' | 'group';
  permissions?: string[];
}


interface NavSliceState {
  buttons: NavButton[];
  tabs: {
    active: string | null;
    list: NavHeaderTab[];
  }
}

const initialState: NavSliceState = {
  buttons: [],
  tabs: {
    active: null,
    list: [],
  },
};

const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setNavButtons: (state, action: PayloadAction<NavButton[]>) => {
      state.buttons = action.payload;
    },
    setNavTabs: (state, action: PayloadAction<NavHeaderTab[]>) => {
      state.tabs.list = action.payload;
    },
    setActiveNavTab: (state, action: PayloadAction<string | null>) => {
      state.tabs.active = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

export const {
  setNavButtons,
  setNavTabs,
  setActiveNavTab,
} = navSlice.actions;

export const getNavButtons = (state: RootState) => state.nav.buttons;
export const getNavTabs = (state: RootState) => state.nav.tabs.list;
export const getActiveNavTab = (state: RootState) => state.nav.tabs.active;

export default navSlice.reducer;
