import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getCurrentGroup } from 'rdx/modules/groups/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';

function* createWorkflowFromTemplate({ payload }) {
  const { action, values, selectedGroup, onSuccess, panelAsBase } = payload;
  const currentGroup = yield select(getCurrentGroup);
  const groupID = selectedGroup || currentGroup?.id;
  const url = action.url.replace('{group_id}', groupID);
  const params = {
    ...values,
    procedure_template_slugs: panelAsBase ? ['bd_panel_maker'] : [],
  };

  const { success, data, error } = yield* makeRequest.post(url, params);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Workflow Created' }));
    const workflowId = data.data.id;
    const navProcedureId = data.data.attributes.nav_procedure_id;
    const navStepId = data.data.attributes.nav_step_id;
    const currentOrg = yield select(getCurrentOrganization);
    const routeOption = panelAsBase ? 'step' : 'details';
    let routeTo = `/${currentOrg.attributes.slug}/groups/${groupID}/workflows/${workflowId}/${routeOption}`;
    if (navProcedureId && navStepId) {
      routeTo = `${routeTo}?${panelAsBase ? '' : 'drawer=edit-step&'}workflow_status=active&stepId=${navStepId}&procedureId=${navProcedureId}`;
    }
    onSuccess?.({ routeTo });
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default createWorkflowFromTemplate;
