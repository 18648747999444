import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setSearchViabilityDyes } from 'rdx/modules/system/slice';

function* searchViabilityDyes({ payload }) {
  const { action } = payload;
  const params = {
    ...payload.params,
    category: 'viability_dye',
  };
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (success && data) {
    yield put(setSearchViabilityDyes(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default searchViabilityDyes;
