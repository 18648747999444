import React from 'react';
import styled from 'styled-components';
import globals from 'styles/globals';

import {
  ErrorMessage as FormikErrorMessage,
} from 'formik';

const { colors } = globals;

const ErrorMessage = (props) => (
  <FormikErrorMessage
    {...props}
    render={(msg) => (
      <StyledError>
        {msg}
        <ValidationBar barHeight={props.barHeight} heightAdjust={props.heightAdjust} />
      </StyledError>
    )}
  />
);

const StyledError = styled.span`
  position: relative;
  color: ${colors.errorText};
`;
const ValidationBar = styled.div`
  height: ${(props) => props.barHeight || '40px'};
  width: 2px;
  background-color: ${colors.errorText};
  position: absolute;
  bottom: ${(props) => props.heightAdjust || '18px'};
  left: -8px;
`;

export default ErrorMessage;
