import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const PanelIcon = forwardRef((props, ref) => (
  <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M6.5 5.70157V24.5858C6.5 24.7268 6.44586 24.8053 6.39593 24.8388C6.01943 25.0913 5.22324 25.5 4 25.5C2.77676 25.5 1.98057 25.0913 1.60406 24.8388C1.55414 24.8053 1.5 24.7268 1.5 24.5858V5.70157C1.54116 5.71272 1.58407 5.72391 1.62873 5.73507C2.16717 5.86968 2.95788 6 4 6C5.04212 6 5.83283 5.86968 6.37127 5.73507C6.41593 5.72391 6.45884 5.71272 6.5 5.70157Z" stroke="#AEAEAE" />
    <path d="M7 7.80005C7 7.80005 6 8.5 4 8.5C2 8.5 1 7.80005 1 7.80005" stroke="#AEAEAE" strokeLinecap="round" />
    <path d="M7.4001 6.6001C7.4001 6.6001 5.94296 7.2002 4.0001 7.2002C2.05724 7.2002 0.600098 6.6001 0.600098 6.6001" stroke="#AEAEAE" strokeLinecap="round" />
    <path d="M4 0.5C5.06049 0.5 5.9983 0.662043 6.65287 0.907504C6.98199 1.03093 7.21361 1.16583 7.35396 1.29026C7.49489 1.41521 7.5 1.48723 7.5 1.5V4.807C7.5 5.01932 7.37158 5.18391 7.20042 5.23436C6.46637 5.45071 5.22472 5.75 4 5.75C2.77528 5.75 1.53363 5.45071 0.79958 5.23436C0.628423 5.18391 0.5 5.01932 0.5 4.807V1.5C0.5 1.48723 0.505111 1.41521 0.646043 1.29026C0.786393 1.16583 1.01801 1.03093 1.34713 0.907504C2.0017 0.662043 2.93951 0.5 4 0.5Z" stroke="#AEAEAE" />
    <path d="M1.3999 5.5V2.5H1.5999V5.5H1.3999Z" fill="#AEAEAE" />
    <path d="M1.8999 5.5V2.5H2.0999V5.5H1.8999Z" fill="#AEAEAE" />
    <path d="M2.3999 5.5V2.5H2.5999V5.5H2.3999Z" fill="#AEAEAE" />
    <path d="M2.8999 5.5V2.5H3.0999V5.5H2.8999Z" fill="#AEAEAE" />
    <path d="M3.3999 5.5V2.5H3.5999V5.5H3.3999Z" fill="#AEAEAE" />
    <path d="M3.8999 2.5V5.5H4.0999V2.5H3.8999Z" fill="#AEAEAE" />
    <path d="M4.3999 2.5V5.5H4.5999V2.5H4.3999Z" fill="#AEAEAE" />
    <path d="M4.8999 5.5V2.5H5.0999V5.5H4.8999Z" fill="#AEAEAE" />
    <path d="M5.3999 5.5V2.5H5.5999V5.5H5.3999Z" fill="#AEAEAE" />
    <path d="M5.8999 2.5V5.5H6.0999V2.5H5.8999Z" fill="#AEAEAE" />
    <path d="M6.8999 5.5V2.5H7.0999V5.5H6.8999Z" fill="#AEAEAE" />
    <path d="M6.3999 2.5V5.5H6.5999V2.5H6.3999Z" fill="#AEAEAE" />
    <path d="M7.5 1.5C7.5 1.51277 7.49489 1.58479 7.35396 1.70974C7.21361 1.83417 6.98199 1.96907 6.65287 2.0925C5.9983 2.33796 5.06049 2.5 4 2.5C2.93951 2.5 2.0017 2.33796 1.34713 2.0925C1.01801 1.96907 0.786393 1.83417 0.646043 1.70974C0.505111 1.58479 0.5 1.51277 0.5 1.5C0.5 1.48723 0.505111 1.41521 0.646043 1.29026C0.786393 1.16583 1.01801 1.03093 1.34713 0.907504C2.0017 0.662043 2.93951 0.5 4 0.5C5.06049 0.5 5.9983 0.662043 6.65287 0.907504C6.98199 1.03093 7.21361 1.16583 7.35396 1.29026C7.49489 1.41521 7.5 1.48723 7.5 1.5Z" stroke="#AEAEAE" />
    <path d="M4 11.4C3 11.4 2 11 2 11V12.5C2 12.5 2.66667 13 4 13C5.33333 13 6 12.5 6 12.5V11C6 11 5 11.4 4 11.4Z" fill="#B469FF" />
    <path d="M4 19.4C3 19.4 2 19 2 19V24.5C2 24.5 2.66667 25 4 25C5.33333 25 6 24.5 6 24.5V19C6 19 5 19.4 4 19.4Z" fill="#B469FF" />
    <path d="M16.5 5.70157V24.5858C16.5 24.7268 16.4459 24.8053 16.3959 24.8388C16.0194 25.0913 15.2232 25.5 14 25.5C12.7768 25.5 11.9806 25.0913 11.6041 24.8388C11.5541 24.8053 11.5 24.7268 11.5 24.5858V5.70157C11.5412 5.71272 11.5841 5.72391 11.6287 5.73507C12.1672 5.86968 12.9579 6 14 6C15.0421 6 15.8328 5.86968 16.3713 5.73507C16.4159 5.72391 16.4588 5.71272 16.5 5.70157Z" stroke="#AEAEAE" />
    <path d="M17 7.80005C17 7.80005 16 8.5 14 8.5C12 8.5 11 7.80005 11 7.80005" stroke="#AEAEAE" strokeLinecap="round" />
    <path d="M17.4001 6.6001C17.4001 6.6001 15.943 7.2002 14.0001 7.2002C12.0572 7.2002 10.6001 6.6001 10.6001 6.6001" stroke="#AEAEAE" strokeLinecap="round" />
    <path d="M14 0.5C15.0605 0.5 15.9983 0.662043 16.6529 0.907504C16.982 1.03093 17.2136 1.16583 17.354 1.29026C17.4949 1.41521 17.5 1.48723 17.5 1.5V4.807C17.5 5.01932 17.3716 5.18391 17.2004 5.23436C16.4664 5.45071 15.2247 5.75 14 5.75C12.7753 5.75 11.5336 5.45071 10.7996 5.23436C10.6284 5.18391 10.5 5.01932 10.5 4.807V1.5C10.5 1.48723 10.5051 1.41521 10.646 1.29026C10.7864 1.16583 11.018 1.03093 11.3471 0.907504C12.0017 0.662043 12.9395 0.5 14 0.5Z" stroke="#AEAEAE" />
    <path d="M11.3999 5.5V2.5H11.5999V5.5H11.3999Z" fill="#AEAEAE" />
    <path d="M11.8999 5.5V2.5H12.0999V5.5H11.8999Z" fill="#AEAEAE" />
    <path d="M12.3999 5.5V2.5H12.5999V5.5H12.3999Z" fill="#AEAEAE" />
    <path d="M12.8999 5.5V2.5H13.0999V5.5H12.8999Z" fill="#AEAEAE" />
    <path d="M13.3999 5.5V2.5H13.5999V5.5H13.3999Z" fill="#AEAEAE" />
    <path d="M13.8999 2.5V5.5H14.0999V2.5H13.8999Z" fill="#AEAEAE" />
    <path d="M14.3999 2.5V5.5H14.5999V2.5H14.3999Z" fill="#AEAEAE" />
    <path d="M14.8999 5.5V2.5H15.0999V5.5H14.8999Z" fill="#AEAEAE" />
    <path d="M15.3999 5.5V2.5H15.5999V5.5H15.3999Z" fill="#AEAEAE" />
    <path d="M15.8999 2.5V5.5H16.0999V2.5H15.8999Z" fill="#AEAEAE" />
    <path d="M16.8999 5.5V2.5H17.0999V5.5H16.8999Z" fill="#AEAEAE" />
    <path d="M16.3999 2.5V5.5H16.5999V2.5H16.3999Z" fill="#AEAEAE" />
    <path d="M17.5 1.5C17.5 1.51277 17.4949 1.58479 17.354 1.70974C17.2136 1.83417 16.982 1.96907 16.6529 2.0925C15.9983 2.33796 15.0605 2.5 14 2.5C12.9395 2.5 12.0017 2.33796 11.3471 2.0925C11.018 1.96907 10.7864 1.83417 10.646 1.70974C10.5051 1.58479 10.5 1.51277 10.5 1.5C10.5 1.48723 10.5051 1.41521 10.646 1.29026C10.7864 1.16583 11.018 1.03093 11.3471 0.907504C12.0017 0.662043 12.9395 0.5 14 0.5C15.0605 0.5 15.9983 0.662043 16.6529 0.907504C16.982 1.03093 17.2136 1.16583 17.354 1.29026C17.4949 1.41521 17.5 1.48723 17.5 1.5Z" stroke="#AEAEAE" />
    <path d="M14 11.4C13 11.4 12 11 12 11V12.5C12 12.5 12.6667 13 14 13C15.3333 13 16 12.5 16 12.5V11C16 11 15 11.4 14 11.4Z" fill="#8EA9DB" />
    <path d="M14 19.4C13 19.4 12 19 12 19V24.5C12 24.5 12.6667 25 14 25C15.3333 25 16 24.5 16 24.5V19C16 19 15 19.4 14 19.4Z" fill="#8EA9DB" />
    <path d="M26.5 5.70157V24.5858C26.5 24.7268 26.4459 24.8053 26.3959 24.8388C26.0194 25.0913 25.2232 25.5 24 25.5C22.7768 25.5 21.9806 25.0913 21.6041 24.8388C21.5541 24.8053 21.5 24.7268 21.5 24.5858V5.70157C21.5412 5.71272 21.5841 5.72391 21.6287 5.73507C22.1672 5.86968 22.9579 6 24 6C25.0421 6 25.8328 5.86968 26.3713 5.73507C26.4159 5.72391 26.4588 5.71272 26.5 5.70157Z" stroke="#AEAEAE" />
    <path d="M27 7.80005C27 7.80005 26 8.5 24 8.5C22 8.5 21 7.80005 21 7.80005" stroke="#AEAEAE" strokeLinecap="round" />
    <path d="M27.4001 6.6001C27.4001 6.6001 25.943 7.2002 24.0001 7.2002C22.0572 7.2002 20.6001 6.6001 20.6001 6.6001" stroke="#AEAEAE" strokeLinecap="round" />
    <path d="M24 0.5C25.0605 0.5 25.9983 0.662043 26.6529 0.907504C26.982 1.03093 27.2136 1.16583 27.354 1.29026C27.4949 1.41521 27.5 1.48723 27.5 1.5V4.807C27.5 5.01932 27.3716 5.18391 27.2004 5.23436C26.4664 5.45071 25.2247 5.75 24 5.75C22.7753 5.75 21.5336 5.45071 20.7996 5.23436C20.6284 5.18391 20.5 5.01932 20.5 4.807V1.5C20.5 1.48723 20.5051 1.41521 20.646 1.29026C20.7864 1.16583 21.018 1.03093 21.3471 0.907504C22.0017 0.662043 22.9395 0.5 24 0.5Z" stroke="#AEAEAE" />
    <path d="M21.3999 5.5V2.5H21.5999V5.5H21.3999Z" fill="#AEAEAE" />
    <path d="M21.8999 5.5V2.5H22.0999V5.5H21.8999Z" fill="#AEAEAE" />
    <path d="M22.3999 5.5V2.5H22.5999V5.5H22.3999Z" fill="#AEAEAE" />
    <path d="M22.8999 5.5V2.5H23.0999V5.5H22.8999Z" fill="#AEAEAE" />
    <path d="M23.3999 5.5V2.5H23.5999V5.5H23.3999Z" fill="#AEAEAE" />
    <path d="M23.8999 2.5V5.5H24.0999V2.5H23.8999Z" fill="#AEAEAE" />
    <path d="M24.3999 2.5V5.5H24.5999V2.5H24.3999Z" fill="#AEAEAE" />
    <path d="M24.8999 5.5V2.5H25.0999V5.5H24.8999Z" fill="#AEAEAE" />
    <path d="M25.3999 5.5V2.5H25.5999V5.5H25.3999Z" fill="#AEAEAE" />
    <path d="M25.8999 2.5V5.5H26.0999V2.5H25.8999Z" fill="#AEAEAE" />
    <path d="M26.8999 5.5V2.5H27.0999V5.5H26.8999Z" fill="#AEAEAE" />
    <path d="M26.3999 2.5V5.5H26.5999V2.5H26.3999Z" fill="#AEAEAE" />
    <path d="M27.5 1.5C27.5 1.51277 27.4949 1.58479 27.354 1.70974C27.2136 1.83417 26.982 1.96907 26.6529 2.0925C25.9983 2.33796 25.0605 2.5 24 2.5C22.9395 2.5 22.0017 2.33796 21.3471 2.0925C21.018 1.96907 20.7864 1.83417 20.646 1.70974C20.5051 1.58479 20.5 1.51277 20.5 1.5C20.5 1.48723 20.5051 1.41521 20.646 1.29026C20.7864 1.16583 21.018 1.03093 21.3471 0.907504C22.0017 0.662043 22.9395 0.5 24 0.5C25.0605 0.5 25.9983 0.662043 26.6529 0.907504C26.982 1.03093 27.2136 1.16583 27.354 1.29026C27.4949 1.41521 27.5 1.48723 27.5 1.5Z" stroke="#AEAEAE" />
    <path d="M24 11.4C23 11.4 22 11 22 11V12.5C22 12.5 22.6667 13 24 13C25.3333 13 26 12.5 26 12.5V11C26 11 25 11.4 24 11.4Z" fill="#C6E0B4" />
    <path d="M24 19.4C23 19.4 22 19 22 19V24.5C22 24.5 22.6667 25 24 25C25.3333 25 26 24.5 26 24.5V19C26 19 25 19.4 24 19.4Z" fill="#C6E0B4" />
    <path d="M36.5 5.70157V24.5858C36.5 24.7268 36.4459 24.8053 36.3959 24.8388C36.0194 25.0913 35.2232 25.5 34 25.5C32.7768 25.5 31.9806 25.0913 31.6041 24.8388C31.5541 24.8053 31.5 24.7268 31.5 24.5858V5.70157C31.5412 5.71272 31.5841 5.72391 31.6287 5.73507C32.1672 5.86968 32.9579 6 34 6C35.0421 6 35.8328 5.86968 36.3713 5.73507C36.4159 5.72391 36.4588 5.71272 36.5 5.70157Z" stroke="#AEAEAE" />
    <path d="M37 7.80005C37 7.80005 36 8.5 34 8.5C32 8.5 31 7.80005 31 7.80005" stroke="#AEAEAE" strokeLinecap="round" />
    <path d="M37.4001 6.6001C37.4001 6.6001 35.943 7.2002 34.0001 7.2002C32.0572 7.2002 30.6001 6.6001 30.6001 6.6001" stroke="#AEAEAE" strokeLinecap="round" />
    <path d="M34 0.5C35.0605 0.5 35.9983 0.662043 36.6529 0.907504C36.982 1.03093 37.2136 1.16583 37.354 1.29026C37.4949 1.41521 37.5 1.48723 37.5 1.5V4.807C37.5 5.01932 37.3716 5.18391 37.2004 5.23436C36.4664 5.45071 35.2247 5.75 34 5.75C32.7753 5.75 31.5336 5.45071 30.7996 5.23436C30.6284 5.18391 30.5 5.01932 30.5 4.807V1.5C30.5 1.48723 30.5051 1.41521 30.646 1.29026C30.7864 1.16583 31.018 1.03093 31.3471 0.907504C32.0017 0.662043 32.9395 0.5 34 0.5Z" stroke="#AEAEAE" />
    <path d="M31.3999 5.5V2.5H31.5999V5.5H31.3999Z" fill="#AEAEAE" />
    <path d="M31.8999 5.5V2.5H32.0999V5.5H31.8999Z" fill="#AEAEAE" />
    <path d="M32.3999 5.5V2.5H32.5999V5.5H32.3999Z" fill="#AEAEAE" />
    <path d="M32.8999 5.5V2.5H33.0999V5.5H32.8999Z" fill="#AEAEAE" />
    <path d="M33.3999 5.5V2.5H33.5999V5.5H33.3999Z" fill="#AEAEAE" />
    <path d="M33.8999 2.5V5.5H34.0999V2.5H33.8999Z" fill="#AEAEAE" />
    <path d="M34.3999 2.5V5.5H34.5999V2.5H34.3999Z" fill="#AEAEAE" />
    <path d="M34.8999 5.5V2.5H35.0999V5.5H34.8999Z" fill="#AEAEAE" />
    <path d="M35.3999 5.5V2.5H35.5999V5.5H35.3999Z" fill="#AEAEAE" />
    <path d="M35.8999 2.5V5.5H36.0999V2.5H35.8999Z" fill="#AEAEAE" />
    <path d="M36.8999 5.5V2.5H37.0999V5.5H36.8999Z" fill="#AEAEAE" />
    <path d="M36.3999 2.5V5.5H36.5999V2.5H36.3999Z" fill="#AEAEAE" />
    <path d="M37.5 1.5C37.5 1.51277 37.4949 1.58479 37.354 1.70974C37.2136 1.83417 36.982 1.96907 36.6529 2.0925C35.9983 2.33796 35.0605 2.5 34 2.5C32.9395 2.5 32.0017 2.33796 31.3471 2.0925C31.018 1.96907 30.7864 1.83417 30.646 1.70974C30.5051 1.58479 30.5 1.51277 30.5 1.5C30.5 1.48723 30.5051 1.41521 30.646 1.29026C30.7864 1.16583 31.018 1.03093 31.3471 0.907504C32.0017 0.662043 32.9395 0.5 34 0.5C35.0605 0.5 35.9983 0.662043 36.6529 0.907504C36.982 1.03093 37.2136 1.16583 37.354 1.29026C37.4949 1.41521 37.5 1.48723 37.5 1.5Z" stroke="#AEAEAE" />
    <path d="M34 11.4C33 11.4 32 11 32 11V12.5C32 12.5 32.6667 13 34 13C35.3333 13 36 12.5 36 12.5V11C36 11 35 11.4 34 11.4Z" fill="#FF8F8F" />
    <path d="M34 19.4C33 19.4 32 19 32 19V24.5C32 24.5 32.6667 25 34 25C35.3333 25 36 24.5 36 24.5V19C36 19 35 19.4 34 19.4Z" fill="#FF8F8F" />
  </svg>
));

export default createIcon(PanelIcon);
