import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionLinks } from 'rdx/modules/auth/slice';
import { getCurrentOrganization, setOrganizationLabs } from '../slice';

function* getOrganizationLabs() {
  const organizationID = (yield select(getCurrentOrganization))?.attributes?.slug;
  const sessionLinks = yield select(getSessionLinks);
  const link = sessionLinks.organization_labs;

  const { success, data, error } = yield* makeRequest.link(link, { organizationID });
  if (success && data) {
    yield put(setOrganizationLabs(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getOrganizationLabs;
