import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setFluorochromes } from 'rdx/modules/fluorochromes/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import type { FormattedResponse } from 'types/json-api-types';
import ResourceList from 'lib/jsonApi/ResourceList';
import { FluorochromeAttributes } from 'types/fluorochromes';

function* getFluorochromes({ payload }: PayloadActionWithGenericPayload) {
  const { action, params, link, onSuccess, onError, query } = payload;
  let response: FormattedResponse | null = null;
  if (action) {
    response = yield makeRequest.action(action, params, query);
  } else if (link) {
    response = yield makeRequest.link(link, {}, query);
  }

  if (response) {
    const { success, error, data } = response;
    if (error) {
      if (onError) {
        onError();
      }
      yield* putErrorActions({ error });
    }
    if (success && data) {
      if (onSuccess) {
        onSuccess({ data });
      }
      if (data instanceof ResourceList) {
        yield put(setFluorochromes(data as unknown as ResourceList<FluorochromeAttributes>));
      }
    }
  }
  return null;
}

export default getFluorochromes;
