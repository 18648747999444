import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';

const initialState: ResourceList = new ResourceList();

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setTutorial: (_, action: PayloadAction<ResourceList>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const requestTutorial = createAction('tutorial/requestTutorial');

const {
  setTutorial,
} = tutorialSlice.actions;

export {
  setTutorial,
  requestTutorial,
};

export const getTutorial = (state: RootState) => state.tutorial;

export default tutorialSlice.reducer;
