import { useState, useRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Downshift, { Callback, ControllerStateAndHelpers, DownshiftState, GetItemPropsOptions, StateChangeOptions } from 'downshift';
import fuzzysort from 'fuzzysort';
import { throttle } from 'lodash';
import ResizeObserver from 'react-resize-observer';

import newlinesToHtml from 'lib/utils/newlinesToHtml';

import { XIcon } from 'components/Icons/index';
import OverlayPortal from 'components/OverlayPortal/index';

const COMBOBOX_TYPE = {
  STANDARD: 'standard',
  COMPACT_WITH_BORDER: 'compact_with_border',
};

const FUZZY_SORT_OPTIONS = {
  threshold: -8000,
  allowTypo: true,
  keys: ['display', 'subText'],
};

const filterAlgorithm = <T,>(filterText: string | null, options: ComboboxOption<T>[]) => {
  let results = options;
  if (filterText) {
    results = fuzzysort
      .go(filterText, options, FUZZY_SORT_OPTIONS)
      .map((r) => r.obj);
  }
  return results;
};

const STATE = {
  OPEN: 'OPEN',
  OPENING: 'OPENING',
  CLOSED: 'CLOSED',
  CLOSING: 'CLOSING',
} as const;

export type ComboboxOption<T> = {
  value: T | null,
  display: ReactNode,
  subText?: string,
  data?: any,
  action?: () => void,
  render?: (args?: { highlighted?: boolean, option?: ComboboxOption<T> }) => ReactNode | undefined,
}

export interface ComboboxProps<T> {
  options: ComboboxOption<T>[],
  initialValue?: ComboboxOption<T>,
  onChange: (value: T | null, data?: any) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void; 
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onInputChange?: (newValue?: string | null) => void;
  placeholder?: string;
  loading?: boolean;
  width?: string;
  backgroundColor?: string;
  borderColor?: string;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  selectItem?: ComboboxOption<T> | null;
  searchOnly?: boolean;
  autoHighlightOption?: boolean;
  onKeyDown?: (stateAndHelpers: ControllerStateAndHelpers<ComboboxOption<T>>) => void;
  getHighlightedIndex?: (index: number | null) => void;
  disabled?: boolean;
  customEmptyMessage?: string;
  autoClear?: boolean;
  noClearReset?: boolean;
  label?: ReactNode;
  onEnter?: (value?: T | null) => void;
  type?: ComboboxType
}

const Combobox = <T,>({
  options = [],
  initialValue,
  onChange,
  onFocus,
  onBlur,
  onInputChange,
  placeholder,
  loading,
  width = '230px',
  backgroundColor = 'transparent',
  borderColor,
  className,
  style,
  id = 'combobox',
  selectItem,
  searchOnly,
  autoHighlightOption,
  onKeyDown,
  getHighlightedIndex,
  disabled,
  customEmptyMessage,
  autoClear = true,
  noClearReset,
  label,
  onEnter,
  type = 'standard',
}: ComboboxProps<T>) => {
  const [expansionState, setExpansionState] = useState<keyof typeof STATE>(STATE.CLOSED);
  const containerRef = useRef<HTMLDivElement | null>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const portalRef = useRef<HTMLDivElement | null>();
  const downshiftPropsRef = useRef<{ closeMenu?:(cb?: Callback | undefined) => void, isOpen?: boolean }>({});
  const [expandTop, setExpandTop] = useState(false);

  // === Dropdown state handling === //
  const open = () => {
    // added 40 too the height 300 to account for bottom nav bar height
    if (containerRef.current) {
      if ((window.innerHeight - 340 - containerRef.current.getBoundingClientRect().bottom) < 0
        && expansionState !== STATE.OPEN) {
        setExpandTop(true);
        setExpansionState(STATE.OPENING);
      } else if (expansionState !== STATE.OPEN) {
        setExpandTop(false);
        setExpansionState(STATE.OPENING);
      }
    }
  };

  const close = () => {
    setExpansionState(STATE.CLOSING);
  };

  type ResetType = (otherStateToSet?: Partial<StateChangeOptions<ComboboxOption<T>>> | undefined, cb?: Callback | undefined) => void

  const handleTransitionEnd = ({ selectedItem, reset }: {
    selectedItem: ComboboxOption<T> | null,
    reset: ResetType
  }) => {
    if (expansionState === STATE.CLOSING) {
      setExpansionState(STATE.CLOSED);
      if (selectedItem === null && !noClearReset) {
        reset();
      }
    } else if (expansionState === STATE.OPENING) {
      setExpansionState(STATE.OPEN);
    }
  };

  const handleClear = (e: Event | undefined, { clearSelection }: { clearSelection: (cb?: Callback | undefined) => void }) => {
    e?.stopPropagation();
    clearSelection();
  };

  const handleInputClicked = ({ openMenu }: { openMenu: (cb?: Callback | undefined) => void }) => {
    openMenu();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // === Portal stuff === //
  const updatePortal = () => {
    if (containerRef?.current && portalRef?.current) {
      const { bottom, left, width: containerWidth } = containerRef.current.getBoundingClientRect();
      portalRef.current.style.width = `${containerWidth}px`;
      portalRef.current.style.top = `${bottom}px`;
      portalRef.current.style.left = `${left}px`;
    }
  };

  const handleContainerReflow = throttle(() => {
    if (expansionState !== STATE.CLOSED) {
      updatePortal();
    }
    if (downshiftPropsRef.current.isOpen && downshiftPropsRef.current.closeMenu) {
      downshiftPropsRef.current.closeMenu();
    }
  }, 10, { leading: true, trailing: true });

  // === Downshift change handlers === //
  // (selectedItem: ComboboxOption<T> | null, stateAndHelpers: ControllerStateAndHelpers<ComboboxOption<T>>
  const handleChange = (selectedItem: ComboboxOption<T> | null) => {
    if (onChange) {
      if (selectedItem === null) {
        onChange(null);
      } else {
        onChange(selectedItem?.value, selectedItem?.data);
      }
    }
  };

  const handleSelect = (selectedItem: ComboboxOption<T> | null) => {
    if (selectedItem?.action) {
      selectedItem.action();
    }
  };

  const handleDownshiftStateChange = (options: StateChangeOptions<ComboboxOption<T>>) => {
    if ('isOpen' in options) {
      handleOpenChanged(options.isOpen);
    }
  };

  const handleOpenChanged = (isOpening?: boolean) => {
    updatePortal();
    if (isOpening) {
      open();
    } else {
      close();
    }
  };

  // === Downshift state handling === //
  const downshiftStateReducer = (state: DownshiftState<ComboboxOption<T>>, changes: StateChangeOptions<ComboboxOption<T>>) => {
    let newChanges = {...changes};

    // Clear selectedItem when text field fully erased
    if (newChanges.inputValue === '') {
      newChanges = {
        ...newChanges,
        selectedItem: null,
      };
    }

    // Combine with handleTransitionEnd to wait until fully closed before clearing text field (prevents weird animation)
    const isClosing = state.isOpen && !newChanges.isOpen;
    const isClearing = state.inputValue && newChanges.inputValue === '';
    if (isClosing && isClearing) {
      newChanges = {
        ...newChanges,
        inputValue: state.inputValue,
      };
    }

    // If option is an action type, don't actually select it
    const isAction = newChanges.selectedItem?.action;
    if (isAction) {
      newChanges = {
        ...newChanges,
        inputValue: '',
        selectedItem: null,
      };
    }

    return newChanges;
  };

  const handleOnInputValueChange = (value: string, stateAndHelpers: ControllerStateAndHelpers<ComboboxOption<T>>) => {
    onKeyDown?.(stateAndHelpers);
    const { selectedItem, clearSelection } = stateAndHelpers;
    if (selectedItem && selectedItem.display !== value) {
      if (onInputChange) {
        onInputChange(value);
      }
      if (autoClear) {
        if (clearSelection) {
          clearSelection();
        }
        if (onChange) {
          onChange(null);
        }
      }
    } else if (
      (selectedItem?.display !== value)
      && onInputChange
      // NOTE: "type" does exist on stateAndHelpers, but it is not in the typings.
      // @ts-ignore 
      && stateAndHelpers.type !== Downshift.stateChangeTypes.unknown
      // @ts-ignore
      && stateAndHelpers.type !== Downshift.stateChangeTypes.mouseUp
    ) {
      onInputChange(value);
    }
  };

  // === Render === //
  const renderItems = (
    {
      getItemProps,
      inputValue,
      highlightedIndex,
    }: {
      getItemProps: (options: GetItemPropsOptions<ComboboxOption<T>>) => any
      inputValue: string | null,
      highlightedIndex: number | null,
    }
  ) => {
    getHighlightedIndex?.(highlightedIndex);
    // If onInputChange is set, assume options filtering will be done by controlling component
    const filteredOptions = onInputChange ? options : filterAlgorithm(inputValue, options);
    if (loading) {
      return <NoOptionsMessage key="loading">Loading...</NoOptionsMessage>;
    }
    if (inputValue?.length === 0 && searchOnly) {
      return <NoOptionsMessage key="enter_search_query">Enter Search Query.</NoOptionsMessage>;
    }
    if (filteredOptions.length === 0) {
      return <NoOptionsMessage key="no_results_found">{customEmptyMessage ?? 'No results found.'}</NoOptionsMessage>;
    }
    return filteredOptions.map((option, index) => {
      const highlighted = highlightedIndex
        ? highlightedIndex === index : (autoHighlightOption ?? highlightedIndex) === index;
      const props = {
        index,
        item: option,
        highlighted,
      };
      const itemProps = getItemProps(props);
      if (option.render) {
        return (
          <OptionCustomRenderContainer key={String(option.value) || String(index)} {...itemProps}>
            {option.render({ highlighted, option })}
          </OptionCustomRenderContainer>
        );
      }
      return (
        <Option key={String(option.value) || String(index)} {...itemProps}>
          <OptionText highlighted={highlighted} type={type}>{option.display}</OptionText>
          {option.subText && (
            <SubText highlighted={highlighted}>{newlinesToHtml(option.subText)}</SubText>
          )}
        </Option>
      );
    });
  };

  const visible = expansionState !== STATE.CLOSED;
  const expand = expansionState === STATE.OPEN || expansionState === STATE.OPENING;
  return (
    <Downshift<ComboboxOption<T>>
      onChange={handleChange}
      onSelect={handleSelect}
      onStateChange={handleDownshiftStateChange}
      stateReducer={downshiftStateReducer}
      itemToString={(item) => (item?.display && typeof item.display === 'string') ? item.display : ''}
      defaultHighlightedIndex={0}
      initialSelectedItem={initialValue}
      inputId={`${id}-input`}
      menuId={`${id}-menu`}
      getItemId={(index) => `${id}-option-${index}`}
      selectedItem={selectItem}
      onInputValueChange={handleOnInputValueChange}
    >
      {({
        getRootProps,
        // getLabelProps, // Use this if we add a label prop for aria compatibility
        getInputProps,
        getMenuProps,
        getItemProps,
        inputValue,
        highlightedIndex,
        selectedItem,
        isOpen,
        openMenu,
        closeMenu,
        reset,
        clearSelection,
      }) => {
        // Store props for access in upper scope
        downshiftPropsRef.current = { closeMenu, isOpen };
        const inputProps = getInputProps({
          ref: inputRef,
          placeholder,
          onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              onEnter?.(selectedItem?.value);
              event.preventDefault();
            }
          },
        })
        return (
          <Container
            {...getRootProps()}
            width={width}
            onBlur={onBlur}
            className={className}
            style={style}
            disabled={disabled}
            ref={containerRef}
          >
            <ResizeObserver onReflow={handleContainerReflow} />
            {label && <Label>{label}</Label>}
            <InputContainer
              expanded={visible}
              changeBg={expand}
              onClick={() => { handleInputClicked({ openMenu }); }}
              {...{ borderColor, backgroundColor, expandTop, type }}
            >
              <Input
                {...inputProps}
                disabled={disabled}
                as={undefined}
                onBlur={onBlur}
                onFocus={onFocus}
                type={type}
              />
              <ClearButton
                data-testid="clear-icon"
                id={id ? `${id}-clear-button` : undefined}
                show={!!selectedItem || inputValue}
                onClick={(e: Event) => { handleClear(e, { clearSelection }); }}
                onMouseDown={(e: Event) => { e.preventDefault(); }}
              />
            </InputContainer>
            <OverlayPortal ref={(ref) => {
              if (ref && !portalRef.current) {
                portalRef.current = ref;
                updatePortal();
              }
            }}>
              <ExpandingContainer
                expand={expand}
                visible={visible}
                onTransitionEnd={() => { handleTransitionEnd({ selectedItem, reset }); }}
                borderColor={borderColor}
                backgroundColor={backgroundColor}
                expandTop={expandTop}
                {...getMenuProps()}
              >
                <OptionList>
                  {renderItems({ getItemProps, inputValue, highlightedIndex })}
                </OptionList>
              </ExpandingContainer>
            </OverlayPortal>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default Combobox;

const BORDER_RADIUS = '2px';
const MAX_OPTIONS_HEIGHT = '300px';

type ComboboxType = 'standard' | 'compact_with_border';

const styles = {
  [COMBOBOX_TYPE.STANDARD]: {
    height: '40px',
    fontSize: '15px',
    iconSize: '18px',
    border: true,
  },
  [COMBOBOX_TYPE.COMPACT_WITH_BORDER]: {
    height: '30px',
    fontSize: '13px',
    iconSize: '14px',
    border: true,
  },
};

const Container = styled.div<{ width: string, disabled?: boolean }>`
  position: relative;
  width: ${({ width }) => width};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
`;

const InputContainer = styled.div<{
  expandTop?: boolean,
  expanded?: boolean,
  changeBg?: boolean,
  backgroundColor?: string,
  borderColor?: string
  type: ComboboxType
}>`
  position: relative;
  width: 100%;
  height: ${({ type }) => type ? styles[type].height : '40px'};
  padding-left: 5px;
  padding-right: 25px;
  background-color: ${({ backgroundColor, changeBg, theme }) => (changeBg ? theme.colors.white : backgroundColor)};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${({ borderColor, theme }) => borderColor ?? theme.colors.grey70};
  transition: background-color 250ms linear;
  cursor: text;

  ${Container}:focus-within & {
    border-width: 2px;
    padding-left: 4px;
  }

  ${({ expanded }) => expanded && css<{ expandTop?: boolean }>`
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: ${({ expandTop }) => (expandTop ? 'auto' : 'none')};
    border-top: ${({ expandTop }) => (expandTop ? 'none' : 'auto')};
  `}
`;

const Input = styled.input<{ type: ComboboxType, disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.textDark};
  background-color: transparent;
  font-size: ${({ type }) => type ? styles[type].fontSize : '15px'};
  width: 100%;
  height: 100%;
  padding: 5px;
  border: none;
  outline: none;
  opacity: ${({ disabled }) => disabled ? 0.6 : 1};

  &::placeholder {
    color: ${({ theme }) => theme.colors.textMed};
    font-style: italic;
  }
`;

const ClearButton = styled(({ show, ...props }) => <XIcon {...props} />)`
  box-sizing: content-box;
  position: absolute;
  right: -1px;
  top: -1px;
  flex: 0 0 auto;
  min-width: 10px;
  max-width: 10px;
  height: calc(100% + 2px);
  padding: 0px 10px;
  cursor: pointer;
  ${({ show }) => !show && css`
    display: none;
  `}
`;

const ExpandingContainer = styled.div<{ expandTop?: boolean, expand?: boolean, visible?: boolean, borderColor?: string }>`
  position: absolute;
  z-index: 99;
  top: ${({ expandTop }) => (expandTop ? 'auto' : '100%')};
  bottom: ${({ expandTop }) => (expandTop ? '40px' : 'auto')};
  left: 0px;
  right: 0px;
  height: auto;
  max-height: ${({ expand }) => (expand ? MAX_OPTIONS_HEIGHT : '0px')};
  border: ${({ visible, borderColor, theme }) => (visible ? `1px solid ${borderColor ?? theme.colors.grey70}` : 'none')};
  border-top: ${({ expandTop }) => (expandTop ? 'auto' : 'none')};
  border-bottom: ${({ expandTop }) => (expandTop ? 'none' : 'auto')};
  border-bottom-left-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
  background-color: ${({ theme }) => theme.colors.white};
  overflow: auto;
  cursor: pointer;
  transition: max-height 200ms ease-out;

  ${Container}:focus-within & {
    border-width: 2px;
  }
`;

const OptionList = styled.div``;

const Option = styled.div<{ highlighted?: boolean }>`
  background-color: ${({ highlighted, theme }) => (highlighted ? theme.colors.primary : theme.colors.white)};
  padding: 10px 5px;
`;

export const OptionText = styled.div<{ highlighted?: boolean, type: ComboboxType }>`
  font-size: ${({ type }) => type ? styles[type].fontSize : '15px'};
  color: ${({ highlighted, color, theme }) => (highlighted ? theme.colors.textLight : color)};
`;

const SubText = styled.div<{ highlighted?: boolean }>`
  font-size: 13px;
  color: ${({ highlighted, theme }) => (highlighted ? theme.colors.grey90 : theme.colors.textMed)};
  margin-top: 5px;
`;

const NoOptionsMessage = styled(Option)`
  color: ${({ theme }) => theme.colors.textDark};
  background-color: ${({ theme }) => theme.colors.grey93};
  font-style: italic;
  cursor: auto;
`;

const OptionCustomRenderContainer = styled(Option)`
  padding: 0px;
`;

const Label = styled.div<{ color?: string }>`
  color: ${({ color, theme }) => color ?? theme.colors.textDark};
  padding-bottom: 5px;
  padding-left: 3px;
`;
