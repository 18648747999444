import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const ExpandIcon = forwardRef((props, ref) => (
  <svg x="0px" y="0px" viewBox="0 0 210 210" ref={ref} {...props}>
    <path
      d="M34.774,152.509l26.441,26.442l19.93,19.931L3.724,210l11.119-77.422L34.774,152.509z M175.226,152.509l-30.445-30.441l-26.44,26.439l30.441,30.444l-19.93,19.931L206.276,210l-11.119-77.422L175.226,152.509z M65.22,122.067l26.439,26.439l-30.443,30.444l-26.441-26.442L65.22,122.067z M61.216,31.049l30.443,30.444L65.22,87.933 L34.774,57.491L14.843,77.422L3.724,0l77.422,11.118L61.216,31.049z M118.341,61.493l30.441-30.444l-19.93-19.931L206.276,0 l-11.119,77.422l-19.932-19.931L144.78,87.933L118.341,61.493z"
    />
  </svg>
));

export default createIcon(ExpandIcon);
