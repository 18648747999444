import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import styled from "styled-components"

import { useAppSelector } from 'hooks/reduxHooks'
import { getCurrentOrgSlug, getOrganizations } from 'rdx/modules/organization/slice'

import { CircleDotIcon } from 'components/Icons'

import type Resource from 'lib/jsonApi/Resource'
import type { NotificationEventsAttributes, ReadyZipDownloadMeta } from 'types/notificationEvents'
import { downloadPresignedFileFromUrl } from 'rdx/modules/fileExplorer/sagas'

type NotificationProps = {
  notification: Resource<NotificationEventsAttributes>,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Notification = ({ notification, setIsOpen }: NotificationProps) => {
  const history = useHistory();
  const organizations = useAppSelector(getOrganizations)
  const notificationOrgId = notification?.attributes?.event_meta?.organization_id?.toString()
  const currentOrgSlug = useAppSelector(getCurrentOrgSlug)
  const notificationOrgSlug = organizations.find((org) => org?.id === notificationOrgId)?.attributes?.slug

  const isRead = !!notification.attributes.viewed_at;

  const [notificationOnClick, linkCopy] = useMemo(() => {
    switch (notification.attributes.category) {
      case 'ORG_JOIN_REQUEST':
        return [
          () => {
            history.push(`/${notificationOrgSlug}/users?tab=awaiting`)
            setIsOpen(false);
          },
          'Visit Users Page',
        ]
      case 'READY_ZIP_DOWNLOAD': {
        const meta = notification.attributes.event_meta as ReadyZipDownloadMeta;
        return [
          meta?.presigned_url ? () => {
            downloadPresignedFileFromUrl(meta.presigned_url)
          } : null,
          'Download Zip',
        ]
      }
      case 'WORKFLOW_STEP_ASSIGNED': 
        return [
          () => {
            history.push(`/${notificationOrgSlug}/workflows?tab=active`)
            setIsOpen(false);
          },
          'Visit Workflow Page',
        ]
        case 'PANEL_SHARED': 
        return [
          () => {
            history.push(`/${currentOrgSlug}/panels?tab=shared`)
            setIsOpen(false);
          },
          'Visit Shared Panel Page',
        ]
      default:
        return [null, null];
    }
  }, [notificationOrgSlug, history, notification.attributes.category, notification.attributes.event_meta, setIsOpen])

  return (
    <NotificationCard key={notification.id} onClick={notificationOnClick ? notificationOnClick : undefined}>
      {isRead ? (
        <CircleDotIcon size="lg" color={'fontLight'} />
      ) : (
        <Animation>
          <CircleDotIcon size="lg" color={'green'} />
        </Animation>
      )}
      <div>
        <p>{notification.attributes.description}</p>
        <NotificationDetails>
          <NotificationDate>{moment(notification.attributes.created_at).format('lll')}</NotificationDate>
          {linkCopy && (
            <a>
              {linkCopy}
            </a>
          )}
        </NotificationDetails>
      </div>
    </NotificationCard>
  )
}

export default Notification;

const NotificationCard = styled.div`
  padding: 15px;
  display: flex;
  gap: 20px;
  align-items: center;

  :hover {
    background-color: ${({ theme }) => theme.colors.grey96};
    cursor: pointer;

    a {
      text-decoration: underline;
    }
  }
`

const Animation = styled.div`
  animation: ${({ theme }) => theme.animations.blink} 2s linear infinite;
`

const NotificationDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  font-size: 12px;
`;

const NotificationDate = styled.span`
  color: grey;
`;