import Action from 'lib/jsonApi/Action';
import { ActionParams, Pagination } from 'types/json-api-types';

export type FileParentData = {
  type: string,
  id: number,
  label: string,
  total_size?: number,
  files_count?: number,
  workflows_count?: number,
  is_archived?: boolean,
  direct?: boolean,
  path?: string[],
}

export default class ResourceMeta {
  actions?: Action[];

  pagination?: Pagination;

  parentDataType?: string;

  parentData?: FileParentData[]

  constructor(props: { actions?: ActionParams[] } = {}) {
    const { actions, ...rest } = props;
    Object.assign(this, rest);
    if (actions) {
      this.actions = actions.map((action: ActionParams) => new Action(action));
    }
  }

  getAction = (name: string) => {
    if (!this.actions) return undefined;
    return this.actions.find((a) => a.name === name);
  };
}
