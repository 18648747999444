import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import styled, { css, keyframes } from 'styled-components';
import globals from 'styles/globals';
import customPropTypes from 'lib/customPropTypes';
import { multiplyStyleString } from 'lib/utils/styles/doMathOnStyleString';
import { messageTypes } from 'rdx/modules/messages/constants';
import { CheckmarkIcon, ErrorOctagon, XIcon } from 'components/Icons';
import { BREAKPOINTS } from 'hooks/useDeviceBreakpoints';

class FlashMessages extends React.Component {
  hoverActive = false;

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      statuses: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { latestMessage } = this.props;
    if (latestMessage?.id !== prevProps.latestMessage?.id && latestMessage?.id) {
      this.addItem(latestMessage);
      if (latestMessage.text === 'Link Canceled') {
        this.filterItems((i) => !i.type === messageTypes.LINKING);
      }
    }
  }

  onAnimationEnd = (animName, item) => {
    const closeName = close.getName();
    const openName = open.getName();
    if (animName === closeName) this.removeItem(item);
    else if (animName === openName) {
      if (this.hoverActive) this.setItemStatus(item, 'hovering');
      else this.setItemStatus(item, 'opened');
    }
  }

  setItemStatus(item, status) {
    const { statuses } = this.state;
    const newStatuses = { ...statuses, [item.id]: status };
    this.setState({ statuses: newStatuses });
  }

  setAllItemStatuses(status, error) {
    if (status === 'hovering') {
      this.hoverActive = true;
    } else {
      this.hoverActive = false;
    }
    const { statuses } = this.state;
    const newStatuses = {};
    const keys = Object.keys(statuses);
    for (let i = 0; i < keys.length; i += 1) {
      const oldStatus = statuses[keys[i]];
      // if clicked or opening, prevent status change
      if (!error && oldStatus !== 'clicked' && oldStatus !== 'opening') newStatuses[keys[i]] = status;
      else newStatuses[keys[i]] = oldStatus;
    }
    this.setState({ statuses: newStatuses });
  }

  addItem(item) {
    const { items, statuses } = this.state;
    const newStatuses = { ...statuses, [item.id]: 'opening' };
    const newItems = items.concat(item);
    this.setState({ items: newItems, statuses: newStatuses });
  }

  removeItem(item) {
    const { items, statuses } = this.state;
    const index = items.findIndex((i) => i.id === item.id);
    if (index >= 0) {
      const newItems = items.slice(0);
      newItems.splice(index, 1);
      const newStatuses = { ...statuses };
      delete newStatuses[item.id];
      this.setState({ items: newItems, statuses: newStatuses });
    }
  }

  filterItems(conditionFn) {
    if (typeof conditionFn === 'function') {
      this.setState((prev) => ({ items: prev.items.filter(conditionFn) }));
    }
  }

  handleClick(item) {
    if (item.function) {
      item.function();
      if (item.link) {
        setTimeout(() => this.props.history.push(item.link.href, item.link.state), 300);
      }
    } else {
      this.props.history.push(item.link.href, item.link.state);
    }
    this.setItemStatus(item, 'clicked');
  }

  clearAll() {
    const newStatuses = {};
    this.state.items.forEach(({ id }) => {
      newStatuses[id] = 'clicked';
    });
    this.setState({ statuses: newStatuses });
  }

  renderItems(isMobile) {
    const { items, statuses } = this.state;
    return items.map((item) => {
      const { type, persist } = item;
      const status = statuses[item.id];
      const error = type === messageTypes.ERROR;
      return (
        <FlashItem
          key={item.id}
          status={status}
          error={error}
          id={`flash-message-${item.id}`}
          data-testid="flash-message"
          onMouseEnter={() => this.setAllItemStatuses('hovering', error)}
          onMouseLeave={() => this.setAllItemStatuses('opened', error)}
          role="presentation"
          onAnimationEnd={({ nativeEvent }) => this.onAnimationEnd(nativeEvent.animationName, item, error)}
          persist={persist}
          isMobile={isMobile}
        >
          {
            type === messageTypes.ERROR
              ? <ErrorIcon color={colors.danger} size="md" />
              : <StyledIcon color={colors.success} size="lg" />
          }
          <ContentSection>
            <ItemText>{item.text}</ItemText>
            {item.link && (
              <ItemLink>
                {item.link.text ? (
                  <span
                    onClick={() => this.handleClick(item)}
                    onKeyDown={() => this.handleClick(item)}
                    role="button"
                    tabIndex={0}
                  >
                    {item.link.text}
                  </span>
                ) : (
                  <a href={item.link} rel="noopener noreferrer" target="_blank">{item.link}</a>
                )}
              </ItemLink>
            )}
            <TimeStamp>{moment(item.timestamp).format('LLL')}</TimeStamp>
          </ContentSection>
          {items.length > 1
          && Object.values(statuses)?.every((s) => ['hovering', 'opened'].includes(s))
          && (
            <ClearAll onClick={() => this.clearAll()}>
              Clear All
            </ClearAll>
          )}
          {type !== messageTypes.LINKING && (
            <CloseIcon role="button" data-testid="close-icon" onClick={(e) => { e.stopPropagation(); this.setItemStatus(item, 'clicked'); }} color="fontDark" size="xs" />
          )}
        </FlashItem>
      );
    });
  }

  render() {
    const { items } = this.state;
    if (items.length < 1) return null;

    return (
      <MediaQuery maxWidth={BREAKPOINTS.mobile}>
        {(matches) => (
          <FlashContainer isMobile={matches}>
            {this.renderItems(matches)}
          </FlashContainer>
        )}
      </MediaQuery>
    );
  }
}

FlashMessages.propTypes = {
  latestMessage: customPropTypes.message,
  history: customPropTypes.history,
};

FlashMessages.defaultProps = {
  latestMessage: {},
};

const mapStateToProps = (state) => ({
  latestMessage: state.messages.latestMessageEvent?.target === undefined ? state.messages.latestMessageEvent : {},
});

export default withRouter(connect(mapStateToProps)(FlashMessages));

const { colors } = globals;

const height = '75px';
const animationDuration = 0.5;
const margin = '25px';
const waitDuration = 1;
const errorWaitDuration = 10;
const delay = (persist, error) => {
  if (persist) {
    return '9999s';
  } if (error) {
    return `${animationDuration + errorWaitDuration}s`;
  }
  return `${animationDuration + waitDuration}s`;
};

const open = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: ${({ isMobile }) => (isMobile ? css`translateY(85px);` : css`translateY(-${height});`)}
  }
`;

const close = keyframes`
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
`;

const FlashContainer = styled.div`
  position: absolute;
  ${({ isMobile }) => (isMobile ? css`top: 0;` : css`bottom: 0;`)}
  right: 0;
  left: 0;
  z-index: 9999;
`;

const ItemText = styled.div`
  max-width: 290px;
  margin: 0 15px;
  word-wrap: break-word;
  margin-bottom: 2px;
`;

const TimeStamp = styled(ItemText)`
  font: ${({ theme }) => theme.fonts.label};
  color: ${({ theme }) => theme.colors.fontLight};
  position: absolute;
  opacity: 1;
  transition: all 400ms;
  bottom: -18px;
`;

const FlashItem = styled.div`
  ${open}
  ${close}
  pointer-events: all;
  position: absolute;
  display: flex;
  height: auto;
  min-height: ${height};
  max-height: auto;
  width: 100%;
  ${({ isMobile }) => (!isMobile ? css`
    width: 400px;
    margin-left: ${margin};
    margin-bottom: 60px;
    bottom: -${height};
    transform: translateY(-${height});
  ` : css`
    bottom: 0;
    transform: translateY(85px);
  `)}
  padding: ${margin};
  font-size: 1.1em;
  color: ${colors.fontDark};
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.25);
  background-color: ${colors.white};
  border: 1px solid ${colors.darkGrey};

  ${({ status }) => status === 'opening' && css`
    animation: ${open} ${animationDuration}s forwards;
    animation-timing-function: ease-out;
  `}

  ${({ status }) => status === 'hovering' && css`
    animation: none;
  `}

  ${({ status, persist, error }) => status === 'opened' && css`
    animation: ${close} ${animationDuration}s forwards ${() => (delay(persist, error))};
    animation-timing-function: ease-in;
  `}

  ${({ status }) => status === 'clicked' && css`
    animation: ${close} ${animationDuration}s forwards;
    animation-timing-function: ease-in;
  `}

  :not(:hover) {
    max-height: ${multiplyStyleString(height, 2)};
    ${ItemText} {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    ${TimeStamp} {
      opacity: 0;
    }
  }
`;

const ErrorIcon = styled(ErrorOctagon)`
`;

const StyledIcon = styled(CheckmarkIcon)`
`;

const CloseIcon = styled(XIcon)`
  z-index: 2;
`;

const ClearAll = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) => theme.fonts.flag};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ItemLink = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) => theme.fonts.body};
  margin: 0px 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 2px;

  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
