import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { GenericActionPayload } from 'types/redux-types';
import type { Link } from 'types/json-api-types';
import { resetStore } from '../app/slice';
import { PROCEDURE_TEMPLATE_SLUGS } from 'lib/constants';
import { WorkflowAttributes } from 'types/workflows';

type WorkflowState = {
  workflow: ResourceList<WorkflowAttributes> | null;
  workflows: ResourceList;
  workflowNotes: ResourceList;
  workflowFiles: ResourceList;
};

const initialState: WorkflowState = {
  workflow: null,
  workflows: new ResourceList(),
  workflowNotes: new ResourceList(),
  workflowFiles: new ResourceList(),
};

export type WorkflowsQueryParams = {
  pageSize?: number,
  page?: number,
  sorting?: string,
  status?: string,
  search?: string,
  project_id?: number,
  study_id?: number,
  created_by?: number,
  assigned_to?: number,
  has_study?: boolean,
}

export type OrgWorkflowsParams = {
  organization_id?: string,
}

export type GroupWorkflowsParams = {
  group_id?: string,
}

type GetWorkflowByParamsParams = {
  groupID?: string,
  workflowID?: string
};

export type CreateWorkflowParams = {
  name: string,
  project_id: string,
  description?: string,
  enable_gps?: boolean,
  enable_lab_notes?: boolean,
  enable_uploads?: boolean,
  procedure_template_id?: number,
  procedure_template_slugs?: typeof PROCEDURE_TEMPLATE_SLUGS[keyof typeof PROCEDURE_TEMPLATE_SLUGS][]
  procedure_id?: number,
  workflow_template_id?: number,
  workflow_id?: number,
  study_id?: number,
  group_id?: string,
}

const getWorkflow = createAction<{ link: Link }>('getWorkflow');
const getWorkflowByParams = createAction<GenericActionPayload<GetWorkflowByParamsParams>>('getWorkflowByParams');
const requestDashboardWorkflows = createAction<GenericActionPayload<OrgWorkflowsParams & GroupWorkflowsParams & WorkflowsQueryParams>>('workflows/requestDashboardWorkflows');
const requestOrgWorkflows = createAction<GenericActionPayload<OrgWorkflowsParams & WorkflowsQueryParams>>('workflows/requestOrgWorkflows');
const getGroupWorkflows = createAction<GenericActionPayload<GroupWorkflowsParams & WorkflowsQueryParams>>('getGroupWorkflows');
const createCustomWorkflow = createAction<GenericActionPayload<CreateWorkflowParams>>('workflows/createCustomWorkflow');
const createPredefinedWorkflow = createAction<GenericActionPayload<CreateWorkflowParams>>('workflows/createPredefinedWorkflow');
const repositionProcedures = createAction('repositionProcedures');
const publishWorkflow = createAction('publishWorkflow');
const unpublishWorkflow = createAction('unpublishWorkflow');
const removeWorkflowFromStudy = createAction('removeWorkflowFromStudy');
const patchWorkflow = createAction('patchWorkflow');
const duplicateWorkflow = createAction('duplicateWorkflow');
const deleteWorkflow = createAction('deleteWorkflow');
const archiveWorkflow = createAction<GenericActionPayload>('archiveWorkflow');
const unarchiveWorkflow = createAction<GenericActionPayload & { isActive?: boolean }>('unarchiveWorkflow');
const requestWorkflowNotes = createAction('requestWorkflowNotes');
const requestWorkflowFiles = createAction('requestWorkflowFiles');
const getMoreWorkflows = createAction('getMoreWorkflows');
const downloadWorkflowReportPdf = createAction('downloadWorkflowReportPdf');
const generateFavaFiles = createAction<GenericActionPayload>('worklows/generateFavaFiles')

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setWorkflow: (state, action: PayloadAction<ResourceList<WorkflowAttributes> | null>) => {
      state.workflow = action.payload;
    },
    setWorkflows: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.workflows = state.workflows.mergeWith(action.payload);
      } else {
        state.workflows = action.payload;
      }
    },
    setWorkflowNotes: (state, action: PayloadAction<ResourceList>) => { state.workflowNotes = action.payload; },
    setWorkflowFiles: (state, action: PayloadAction<ResourceList>) => { state.workflowFiles = action.payload; },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const { setWorkflow, setWorkflows, setWorkflowNotes, setWorkflowFiles } = workflowSlice.actions;

// Actions
export {
  getWorkflow,
  getWorkflowByParams,
  requestDashboardWorkflows,
  requestOrgWorkflows,
  getGroupWorkflows,
  createCustomWorkflow,
  createPredefinedWorkflow,
  repositionProcedures,
  publishWorkflow,
  unpublishWorkflow,
  removeWorkflowFromStudy,
  patchWorkflow,
  duplicateWorkflow,
  deleteWorkflow,
  archiveWorkflow,
  unarchiveWorkflow,
  requestWorkflowNotes,
  requestWorkflowFiles,
  getMoreWorkflows,
  downloadWorkflowReportPdf,
  setWorkflow,
  setWorkflows,
  setWorkflowNotes,
  setWorkflowFiles,
  generateFavaFiles,
};

// Selectors
export const getWorkflowRaw = (state: RootState) => state.workflow.workflow;
export const getWorkflowsRaw = (state: RootState) => state.workflow.workflows;
export const getCreateWorkflowAction = (state: RootState) => state.workflow?.workflows?.getAction?.('create');
export const getWorkflowNotesRaw = (state: RootState) => state.workflow.workflowNotes;
export const getWorkflowFilesRaw = (state: RootState) => state.workflow.workflowFiles;

// Reducer
export default workflowSlice.reducer;
