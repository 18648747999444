import { all, put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import { newMessageEvent, newErrorEvent } from 'rdx/modules/messages/slice';
import { requestOrganizationGroups } from '../slice';

function* requestJoinOrganizationGroups({ payload: { action, params } }) {
  const { groupIds } = params;

  const responses = yield all(
    groupIds.map((group_id) => makeRequest.action(action, { group_id })),
  );
  const errors = [];
  responses.forEach(({ error }) => {
    if (error) {
      errors.push(error);
    }
  });
  if (errors.length >= 1) {
    yield put(newErrorEvent({ text: errors[0].text }));
  } else {
    const text = groupIds.length > 1 ? 'Requests to join groups have been sent' : 'Request to join group has been sent';
    yield put(newMessageEvent({ text }));
    yield put(requestOrganizationGroups());
  }
  return null;
}

export default requestJoinOrganizationGroups;
