import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { WritableDraft } from 'immer/dist/internal.js';
import type { RootState } from 'index';
import { GenericActionPayload } from 'types/redux-types';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { setCurrentOrganization } from 'rdx/modules/organization/slice';
import { FileAttributes, FileExplorerCytometerResourceAttributes, FileExplorerProjectResourceAttributes, FolderAttributes } from 'types/fileExplorer';

export function getFolderIdentifier(res: FileOrFolderResource, parentId: string) {
  if (res.uuid) {
    return res.uuid;
  }
  return parentId + res.id;
}

export type ProcedureStepFile = {
  id: number | string,
  filename: string,
};

export type BatchFolder = {
  folderPath: string[];
  onlyFiles: boolean;
  sourceCytometer?: number;
  sourceProject?: number;
  sourceWorkflow?: number;
  numberOfFiles?: number;
};

interface FileExplorerSliceState {
  projects: ResourceList<FileExplorerProjectResourceAttributes>;
  cytometers: ResourceList<FileExplorerCytometerResourceAttributes>;
  files: ResourceList<FileAttributes>;
  folders: ResourceList<FolderAttributes>;
  fileVersions: ResourceList;
  sharedFilesOrganizations: ResourceList<{ name: string }>;
  listViewFiles: ResourceList;
  foldersContent: Record<string, { files: ResourceList | null, folders: ResourceList | null }>,
  filesByName: ResourceList;
  singleFile: ResourceList | null;
  batchFiles: Resource[];
  procedureStepFiles: ProcedureStepFile[];
  experimentFile: Resource | null;
  batchFolder: BatchFolder | null;
  fileSharings: ResourceList;
  subfolders: ResourceList;
}

type FileOrFolderResource = { uuid?: string, id: string };

const initialState: FileExplorerSliceState = {
  projects: new ResourceList(),
  cytometers: new ResourceList(),
  files: new ResourceList(),
  folders: new ResourceList(),
  fileVersions: new ResourceList(),
  sharedFilesOrganizations: new ResourceList(),
  listViewFiles: new ResourceList(),
  foldersContent: {},
  filesByName: new ResourceList(),
  singleFile: null,
  batchFiles: [],
  procedureStepFiles: [],
  experimentFile: null,
  batchFolder: null,
  fileSharings: new ResourceList(),
  subfolders: new ResourceList(),
};

export type FileLabel = {
 id: number,
 label: string 
}

export type FcsBatchParams = {
  name: string,
  source_step_id?: number,
  file_ids?: number[],
  destination_folder?: string[],
  project_id?: number,
  workflow_id?: number,
  cytometer_id?: number,
  file_labels?: FileLabel[],
  image_label?: string
};

type RequestFileExplorerResourceParams = {
  organizationID: string,
}

type RequestFileExplorerResourceQuery = {
  content_type: 'projects' | 'cytometers',
  explorer: boolean,
}

export type RenameFilesFolderParams = {
  source_folder: string[],
  new_folder_name: string,
  source_workflow_id?: number,
  source_cytometer_id?: number,
  source_project_id?: number,
}

const requestResourceProjects = createAction<GenericActionPayload<RequestFileExplorerResourceParams, RequestFileExplorerResourceQuery>>('fileExplorer/requestResourceProjects');
const requestResourceCytometers = createAction<GenericActionPayload<RequestFileExplorerResourceParams, RequestFileExplorerResourceQuery>>('fileExplorer/requestResourceCytometers');
const requestResourceFiles = createAction<GenericActionPayload>('fileExplorer/requestResourceFiles');
const requestResourceFolders = createAction<GenericActionPayload>('fileExplorer/requestResourceFolders');
const requestSubfolders = createAction('fileExplorer/requestSubfolders');
const requestFileVersions = createAction('fileExplorer/requestFileVersions');
const requestSharedFilesOrgs = createAction<GenericActionPayload>('fileExplorer/requestSharedFilesOrgs');
const requestListViewFiles = createAction('fileExplorer/requestListViewFiles');
const requestFilesContent = createAction('fileExplorer/requestFilesContent');
const requestMoreFilesContent = createAction('fileExplorer/requestMoreFilesContent');
const requestFoldersContent = createAction('fileExplorer/requestFoldersContent');
const requestFilesByName = createAction('fileExplorer/requestFilesByName');
const requestSingleFile = createAction('fileExplorer/requestSingleFile');
const requestFileSharings = createAction('fileExplorer/requestFileSharings');
const deleteFileSharing = createAction('fileExplorer/deleteFileSharing');
const downloadZip = createAction<GenericActionPayload>('fileExplorer/downloadZip');
const updateFile = createAction('fileExplorer/updateFile');
const downloadFile = createAction<GenericActionPayload>('fileExplorer/downloadFile');
const previewFile = createAction<GenericActionPayload>('fileExplorer/previewFile');
const emailFile = createAction<GenericActionPayload>('fileExplorer/emailFile');
const lockFile = createAction<GenericActionPayload>('fileExplorer/lockFile');
const unlockFile = createAction<GenericActionPayload>('fileExplorer/unlockFile');
const shareFile = createAction<GenericActionPayload>('fileExplorer/shareFile');
const deleteFile = createAction<GenericActionPayload>('fileExplorer/deleteFile');
const restoreFile = createAction('fileExplorer/restoreFile');
const deleteBatchFiles = createAction('fileExplorer/deleteBatchFiles');
const moveBatchFiles = createAction('fileExplorer/moveBatchFiles');
const downloadBatchFiles = createAction('fileExplorer/downloadBatchFiles');
const fcsBatchFiles = createAction<GenericActionPayload<FcsBatchParams>>('fileExplorer/fcsBatchFiles');
const processCvw = createAction('fileExplorer/processCvw');
const renameFilesFolder = createAction<GenericActionPayload<RenameFilesFolderParams>>('fileExplorer/renameFilesFolder');

const setDataWithPagination = (state: WritableDraft<FileExplorerSliceState>, action: PayloadAction<ResourceList>, field: keyof Omit<FileExplorerSliceState, 'projects' | 'cytometers' | 'files' | 'folders' | 'foldersContent' | 'singleFile' | 'batchFiles' | 'batchFolder' | 'procedureStepFiles' | 'experimentFile' | 'sharedFilesOrganizations'>) => {
  if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
    state[field] = state[field].mergeWith(action.payload);
  } else {
    state[field] = action.payload;
  }
};

export const fileExplorerSlice = createSlice({
  name: 'fileExplorer',
  initialState,
  reducers: {
    setFileExplorerProjects: (state, action: PayloadAction<ResourceList<FileExplorerProjectResourceAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.projects = state.projects.mergeWith(action.payload);
      } else {
        state.projects = action.payload;
      }
    },
    setFileExplorerCytometers: (state, action: PayloadAction<ResourceList<FileExplorerCytometerResourceAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.cytometers = state.cytometers.mergeWith(action.payload);
      } else {
        state.cytometers = action.payload;
      }
    },
    setFolders: (state, action: PayloadAction<ResourceList<FolderAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.folders = state.folders.mergeWith(action.payload);
      } else {
        state.folders = action.payload;
      }
    },
    setFiles: (state, action: PayloadAction<ResourceList<FileAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.files = state.files.mergeWith(action.payload);
      } else {
        state.files = action.payload;
      }
    },
    setListViewFiles: (state, action: PayloadAction<ResourceList>) => setDataWithPagination(state, action, 'listViewFiles'),
    setFileVersions: (state, action: PayloadAction<ResourceList>) => {
      state.fileVersions = action.payload;
    },
    setSharedFilesOrganizations: (state, action: PayloadAction<ResourceList<{ name: string }>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.sharedFilesOrganizations = state.sharedFilesOrganizations.mergeWith(action.payload);
      } else {
        state.sharedFilesOrganizations = action.payload;
      }
    },
    setSubfolders: (state, action: PayloadAction<ResourceList>) => {
      state.subfolders = action.payload;
    },
    setFilesContent: (state, action: PayloadAction<{ data: ResourceList | null, id: string }>) => {
      const currentContent = state.foldersContent[action.payload.id];
      currentContent.files = action.payload.data;
    },
    setMoreFilesContent: (state, action: PayloadAction<{ data: ResourceList, id: string }>) => {
      const currentContent = state.foldersContent[action.payload.id];
      if (currentContent.files !== null) {
        currentContent.files = currentContent.files.mergeWith(action.payload.data);
      } else {
        currentContent.files = action.payload.data;
      }
    },
    setFoldersContent: (state, action: PayloadAction<{ data: ResourceList | null, id: string }>) => {
      const currentContent = state.foldersContent[action.payload.id];
      currentContent.folders = action.payload.data;
    },
    setMoreFoldersContent: (state, action: PayloadAction<{ data: ResourceList, id: string }>) => {
      const currentContent = state.foldersContent[action.payload.id];
      if (currentContent.folders !== null) {
        currentContent.folders = currentContent.folders.mergeWith(action.payload.data);
      } else {
        currentContent.folders = action.payload.data;
      }
    },
    setFilesByName: (state, action: PayloadAction<ResourceList>) => setDataWithPagination(state, action, 'filesByName'),
    setExperimentFile: (state, action: PayloadAction<Resource>) => {
      state.experimentFile = action.payload;
    },
    unsetExperimentFile: (state) => {
      state.experimentFile = null;
    },
    setSingleFile: (state, action: PayloadAction<ResourceList>) => {
      state.singleFile = action.payload;
    },
    setBatchFiles: (state, action: PayloadAction<Resource[]>) => {
      state.batchFiles = [...state.batchFiles, ...action.payload];
    },
    setBatchFile: (state, action: PayloadAction<Resource>) => {
      state.batchFiles = [...state.batchFiles, action.payload];
    },

    setBatchFolder: (state, action: PayloadAction<BatchFolder>) => {
      state.batchFolder = action.payload;
    },
    removeBatchFiles: (state, action: PayloadAction<string[]>) => {
      state.batchFiles = state.batchFiles.filter((f) => !action.payload.includes(f.id));
    },
    removeBatchFile: (state, action: PayloadAction<string>) => {
      state.batchFiles = state.batchFiles.filter((f) => f.id !== action.payload);
    },
    removeBatchFolder: (state) => {
      state.batchFolder = null;
    },
    resetBatchFiles: (state) => {
      state.batchFiles = initialState.batchFiles;
      state.batchFolder = null;
    },
    setProcedureStepFiles: (state, action: PayloadAction<ProcedureStepFile[]>) => {
      state.procedureStepFiles = [...state.procedureStepFiles, ...action.payload];
    },
    setProcedureStepFile: (state, action: PayloadAction<ProcedureStepFile>) => {
      state.procedureStepFiles = [...state.procedureStepFiles, action.payload];
    },
    removeProcedureStepFileById: (state, action: PayloadAction<number | string>) => {
      state.procedureStepFiles = state.procedureStepFiles.filter((f) => f.id !== action.payload);
    },
    removeProcedureStepFilesById: (state, action: PayloadAction<(number | string)[]>) => {
      state.procedureStepFiles = state.procedureStepFiles.filter((f) => !action.payload.includes(f.id));
    },
    resetProcedureStepFiles: (state) => {
      state.procedureStepFiles = initialState.procedureStepFiles;
    },
    setFileSharings: (state, action: PayloadAction<ResourceList>) => {
      state.fileSharings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
    builder.addCase(setCurrentOrganization.type, (state) => {
      state.batchFiles = initialState.batchFiles;
      state.batchFolder = initialState.batchFolder;
    });
  },
});

const {
  setFileExplorerProjects,
  setFileExplorerCytometers,
  setFolders,
  setSubfolders,
  setFiles,
  setFileVersions,
  setSharedFilesOrganizations,
  setListViewFiles,
  setFilesContent,
  setMoreFilesContent,
  setFoldersContent,
  setMoreFoldersContent,
  setFilesByName,
  setSingleFile,
  setBatchFiles,
  setBatchFile,
  setBatchFolder,
  setFileSharings,
  removeBatchFiles,
  removeBatchFile,
  removeBatchFolder,
  resetBatchFiles,
  setProcedureStepFile,
  setProcedureStepFiles,
  setExperimentFile,
  unsetExperimentFile,
  removeProcedureStepFileById,
  resetProcedureStepFiles,
  removeProcedureStepFilesById,
} = fileExplorerSlice.actions;

// Actions
export {
  setFileExplorerProjects,
  setFileExplorerCytometers,
  setFolders,
  setSubfolders,
  setFiles,
  setFileVersions,
  setListViewFiles,
  setSharedFilesOrganizations,
  setFilesContent,
  setMoreFilesContent,
  setFoldersContent,
  setFilesByName,
  setSingleFile,
  setBatchFiles,
  setBatchFile,
  setBatchFolder,
  setFileSharings,
  setExperimentFile,
  unsetExperimentFile,
  removeBatchFiles,
  removeBatchFile,
  removeBatchFolder,
  resetBatchFiles,
  setProcedureStepFile,
  setProcedureStepFiles,
  removeProcedureStepFileById,
  resetProcedureStepFiles,
  removeProcedureStepFilesById,
  setMoreFoldersContent,
  requestResourceProjects,
  requestResourceCytometers,
  requestResourceFiles,
  requestResourceFolders,
  requestSubfolders,
  requestFileVersions,
  requestSharedFilesOrgs,
  requestListViewFiles,
  requestFilesByName,
  requestFilesContent,
  requestFoldersContent,
  requestMoreFilesContent,
  requestSingleFile,
  requestFileSharings,
  deleteFileSharing,
  downloadZip,
  updateFile,
  downloadFile,
  previewFile,
  emailFile,
  lockFile,
  unlockFile,
  shareFile,
  deleteFile,
  restoreFile,
  deleteBatchFiles,
  moveBatchFiles,
  downloadBatchFiles,
  fcsBatchFiles,
  processCvw,
  renameFilesFolder,
};

// Selectors
export const getFileExplorerProjects = (state: RootState) => state.fileExplorer.projects;
export const getFileExplorerCytometers = (state: RootState) => state.fileExplorer.cytometers;
export const getFolders = (state: RootState) => state.fileExplorer.folders;
export const getSubfolders = (state: RootState) => state.fileExplorer.subfolders;
export const getFiles = (state: RootState) => state.fileExplorer.files;
export const getFilesByName = (state: RootState) => state.fileExplorer.filesByName;
export const getSingleFile = (state: RootState) => state.fileExplorer.singleFile;
export const getFileVersions = (state: RootState) => state.fileExplorer.fileVersions;
export const getSharedFilesOrganizations = (state: RootState) => state.fileExplorer.sharedFilesOrganizations;
export const getListViewFiles = (state: RootState) => state.fileExplorer.listViewFiles;
export const getFolderContent = ({ resource, parentId }: { resource: FileOrFolderResource, parentId: string }) => (state: RootState) => state.fileExplorer.foldersContent[getFolderIdentifier(resource, parentId)];
export const getBatchFiles = (state: RootState) => state.fileExplorer.batchFiles;
export const getBatchFolder = (state: RootState) => state.fileExplorer.batchFolder;
export const getFileSharings = (state: RootState) => state.fileExplorer.fileSharings;
export const getProcedureStepFiles = (state: RootState) => state.fileExplorer.procedureStepFiles;
export const getExperimentFile = (state: RootState) => state.fileExplorer.experimentFile;

// Reducer
export default fileExplorerSlice.reducer;
