import { useState, useCallback, useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';
import { useModal } from 'hooks/useModal';
import { useTimer } from 'hooks/useTimer';
import ConfirmModal from 'containers/Modals/ConfirmModal';
import { getApiConfirmEvents, clearApiConfirmEvent } from 'rdx/modules/messages/slice';

const APIConfirm = () => {
  const { callModal, modalIsOpen } = useModal();
  const dispatch = useAppDispatch();
  const timer1 = useTimer();

  const apiConfirmEvents = useAppSelector(getApiConfirmEvents);
  const [canOpen, setCanOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const event = useMemo(() => apiConfirmEvents[0], [apiConfirmEvents]);

  const onCancel = useCallback(() => {
    dispatch(clearApiConfirmEvent({ id: event?.id }));
    setIsOpen(false);
  }, [dispatch, event?.id]);

  const onConfirm = useCallback((updatedParams) => {
    dispatch(clearApiConfirmEvent({ id: event?.id }));
    setIsOpen(false);
    const { jsonApiAction, rdxAction } = event;
    const confirmAction = cloneDeep(event?.confirmAction);
    if (jsonApiAction) {
      const newAction = jsonApiAction.withField({ name: 'confirm', value: true });
      dispatch(rdxAction({ action: newAction, onSuccess: event?.onSuccess }));
    } else {
      const payload = {
        ...confirmAction.payload,
        confirm: true,
      };
      const params = {
        ...payload.params,
        ...updatedParams,
        confirm: true,
      };
      const values = {
        ...payload.values,
        ...updatedParams,
        confirm: true,
      };
      const newPayload = { ...payload, params, values, onSuccess: event?.onSuccess };
      const newConfirmAction = { ...confirmAction, payload: newPayload };
      dispatch(newConfirmAction);
    }
  }, [dispatch, event]);

  useEffect(() => {
    if (!modalIsOpen) {
      timer1.start(() => setCanOpen(true), 500);
    } else if (canOpen) {
      setCanOpen(false);
      timer1.stop();
    }
  }, [canOpen, modalIsOpen, timer1]);

  const callNextEventModal = useCallback(() => {
    const message = `WARNING: ${event.text} Would you like to continue?`;
    callModal(
      <ConfirmModal
        key={event.id}
        message={message}
        dangerous
        onConfirm={onConfirm}
        onCancel={onCancel}
        onSuccess={event?.onSuccess}
      />
    );
    setIsOpen(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, onCancel, onConfirm]);

  useEffect(() => {
    if (event && canOpen && !isOpen) {
      callNextEventModal();
    }
  }, [callNextEventModal, canOpen, event, isOpen]);
  return null;
};

export default APIConfirm;
