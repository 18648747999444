import makeRequest from 'rdx/utils/makeRequest';
import { put } from 'redux-saga/effects';
import downloadUrl from 'lib/utils/downloadUrl';
import { newErrorEvent } from 'rdx/modules/messages/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* downloadWorkflowReportPdf({ payload: { action, params } }: PayloadActionWithGenericPayload) {
  const { error, success, data } = yield makeRequest.action(action, params);
  if (success && data) {
    downloadUrl(data?.unwrap()?.presigned_pdf_url);
  }
  if (error) {
    yield put(newErrorEvent({ text: error?.text || 'Error downloading workflow report PDF' }));
  }
  return null;
}

export default downloadWorkflowReportPdf;
