import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { ComponentPropsWithoutRef } from 'react';

const hasProtocolRegex = /^[a-z]+:\/\//i;

type Props = {
  to?: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  unformatted?: boolean,
} & Omit<ComponentPropsWithoutRef<'button'> & ComponentPropsWithoutRef<'a'>, 'onClick'>

const Link = ({ to, onClick, unformatted, ...otherProps }: Props) => {
  if (onClick) {
    return <LinkButton type="button" onClick={onClick} {...otherProps } />;
  }
  if (to && hasProtocolRegex.test(to)) {
    return <a href={to} {...otherProps} />;
  }
  return to ? (
    <RouterLink to={to} {...otherProps} />
  ) : null;
};

export default Link;

const LinkButton = styled.button`
  display: inline-block;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  border: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  font-size: inherit;
  font: ${({ theme }) => theme.fonts.body};
`;
