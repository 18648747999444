import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setProjects, setMoreProjects } from 'rdx/modules/projects/slice';

function* requestProjects({ payload }) {
  const { link, onSuccess, onError, query } = payload;
  const { success, data, error } = yield* makeRequest.link(link, null, query);
  if (success && data) {
    if (data?.meta?.pagination?.page > 1) {
      yield put(setMoreProjects(data));
    } else {
      yield put(setProjects(data));
    }
    if (onSuccess) {
      onSuccess();
    }
  } else {
    if (onError) {
      onSuccess();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestProjects;
