import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

const VIEWBOX_SIZE = 1000;
const GUTTER_SIZE = 60;
const ANIM_DURATION = 1.3;
const ANIM_SCALE = 0.6;
const ANIM_OPACITY = 0.7;

const LoadingSquares = ({ size, divisions }) => {
  const theme = useContext(ThemeContext);
  const cellWidth = VIEWBOX_SIZE / divisions;
  const innerWidth = cellWidth - GUTTER_SIZE;

  const renderSquares = () => {
    const content = [];
    for (let x = 0; x < divisions; x++) {
      for (let y = 0; y < divisions; y++) {
        const xPos = x * cellWidth;
        const yPos = y * cellWidth;
        const width = innerWidth;
        const animOffset = (ANIM_SCALE * cellWidth) / 2;
        const keyTimes = '0; 0.2; 0.3; 0.5; 1';
        const begin = `${(((x + y) * 0.15) % ANIM_DURATION) - ANIM_DURATION}s`;
        const dur = `${ANIM_DURATION}s`;
        content.push(
          <g
            key={`cell-${x}-${y}`}
            style={{ fill: theme.colors.primary }}
          >
            <rect x={xPos} y={yPos} width={width} height={width}>
              <animate attributeName="x" values={`${xPos};${xPos + animOffset / 2};${xPos + animOffset / 2};${xPos};${xPos}`} keyTimes={keyTimes} begin={begin} dur={dur} repeatCount="indefinite" />
              <animate attributeName="y" values={`${yPos};${yPos + animOffset / 2};${yPos + animOffset / 2};${yPos};${yPos}`} keyTimes={keyTimes} begin={begin} dur={dur} repeatCount="indefinite" />
              <animate attributeName="width" values={`${width};${width - animOffset};${width - animOffset};${width};${width}`} keyTimes={keyTimes} begin={begin} dur={dur} repeatCount="indefinite" />
              <animate attributeName="height" values={`${width};${width - animOffset};${width - animOffset};${width};${width}`} keyTimes={keyTimes} begin={begin} dur={dur} repeatCount="indefinite" />
              <animate attributeName="opacity" values={`1;${ANIM_OPACITY};${ANIM_OPACITY};1;1`} keyTimes={keyTimes} begin={begin} dur={dur} repeatCount="indefinite" />
            </rect>
          </g>
        );
      }
    }
    return content;
  };
  return (
    <svg height={size} width={size} viewBox={`0 0 ${VIEWBOX_SIZE} ${VIEWBOX_SIZE}`}>
      {renderSquares()}
    </svg>
  );
};

LoadingSquares.propTypes = {
  size: PropTypes.number,
  divisions: PropTypes.number,
};

LoadingSquares.defaultProps = {
  size: 150,
  divisions: 5,
};

export default LoadingSquares;
