import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const HamburgerIcon = forwardRef((props, ref) => (
  <svg ref={ref} {...props} viewBox="0 0 24 24">
    <g>
      <path d="M24,3c0-0.6-0.4-1-1-1H1C0.4,2,0,2.4,0,3v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V3z" />
      <path d="M24,11c0-0.6-0.4-1-1-1H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V11z" />
      <path d="M24,19c0-0.6-0.4-1-1-1H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V19z" />
    </g>
  </svg>
));

export default createIcon(HamburgerIcon);
