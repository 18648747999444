import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setCurrentGroup } from 'rdx/modules/groups/slice';
import { requestProcedures } from 'rdx/modules/procedures/slice';
import { setWorkflow } from '../slice';

function* getWorkflow({ payload }) {
  const { success, data, error } = yield* makeRequest.get(payload);
  if (success && data) {
    yield put(setWorkflow(data));
    yield put(setCurrentGroup(data.unwrap().getRel('group')));
    yield put(requestProcedures({ link: data.links.procedures }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getWorkflow;
