import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const Duplicate = forwardRef((props, ref) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
    <path d="M19.7 6l-3.7-3.7v3.7z" />
    <path d="M15 2h-7v17h12v-12h-5z" />
    <path d="M7 20v-15h-3v17h13v-2z" />
  </svg>
));

export default createIcon(Duplicate);
