import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';
import ResourceList from 'lib/jsonApi/ResourceList';

const initialState = new ResourceList();

const requestFcsData = createAction('requestFcsData');

const fcsDataSlice = createSlice({
  name: 'fcsData',
  initialState,
  reducers: {
    setFcsData: (_, action: PayloadAction<ResourceList>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setFcsData,
} = fcsDataSlice.actions;

export {
  setFcsData,
  requestFcsData,
};

export const getFcsData = (state: RootState) => state.fcsData;

export default fcsDataSlice.reducer;
