import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setSpectrumViewerImagingData } from 'rdx/modules/spectrumViewer/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* requestSpectrumViewerImagingData({ payload: { query, action } }: PayloadActionWithGenericPayload) {
  const { success, data, error } = yield makeRequest.action(action, { ...query, for_imaging: true });
  if (success && data) {
    yield put(setSpectrumViewerImagingData(data));
  } else if (error) {
    yield* putErrorActions({ error });
  }

  return null;
}

export default requestSpectrumViewerImagingData;
