import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';
import { requestMarkNotificationEventsRead, requestNotificationEvents, setNotificationEvents } from './slice';

function* watchNotificationEventsSagas() {
  yield trackRequests(takeLatest, requestNotificationEvents.type, (rdxAction) => requestSaga({ rdxAction, putData: setNotificationEvents }));
  yield trackRequests(takeLatest, requestMarkNotificationEventsRead.type, (rdxAction) => requestSaga({ rdxAction }));
}

export default watchNotificationEventsSagas;
