import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getWorkflowRaw, getWorkflow } from 'rdx/modules/workflow/slice';

function* pauseTrial({ payload }) {
  const { success, data, error } = yield* makeRequest.patch(payload.link);
  if (success && data) {
    yield payload.requestStep();
    yield put(newMessageEvent({ text: 'Step Paused' }));
    const workflow = yield select(getWorkflowRaw);
    yield put(getWorkflow(workflow.links.self));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default pauseTrial;
