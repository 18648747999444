import { createSlice, createAction } from '@reduxjs/toolkit';
import { resetStore } from 'rdx/modules/app/slice';

const initialState = {
  panelReagents: [],
  cytometerMatching: [],
  panelRequestedFlag: false,
};

const requestPanelReagents = createAction('requestPanelReagents');
const requestHybrisCartUrl = createAction('requestHybrisCartUrl');
const requestPanelCytometerMatching = createAction('requestPanelCytometerMatching');

export const panelReagentsSlice = createSlice({
  name: 'panelDetails',
  initialState,
  reducers: {
    setPanelReagents: (state, action) => ({
      ...state,
      panelReagents: action.payload,
    }),
    setPanelCytometerMatching: (state, action) => ({
      ...state,
      cytometerMatching: action.payload,
    }),
    setPanelRequestedFlag: (state, action) => ({
      ...state,
      panelRequestedFlag: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setPanelReagents,
  setPanelCytometerMatching,
  setPanelRequestedFlag,
} = panelReagentsSlice.actions;

// Actions
export {
  requestPanelReagents,
  requestHybrisCartUrl,
  requestPanelCytometerMatching,
  setPanelReagents,
  setPanelCytometerMatching,
  setPanelRequestedFlag,
};

// Selectors
export const getPanelReagents = (state) => state.panelDetails.panelReagents;
export const getPanelCytometerMatching = (state) => state.panelDetails.cytometerMatching;
export const getPanelRequestedFlag = (state) => state.panelDetails.panelRequestedFlag;

// Reducer
export default panelReagentsSlice.reducer;
