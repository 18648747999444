import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setCurrentOrganization } from '../slice';

function* updateOrganizationProfile({ payload: { action, values } }) {
  const { success, data, error } = yield* makeRequest.action(action, values);
  if (success && data) {
    yield put(setCurrentOrganization(data.unwrap()));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default updateOrganizationProfile;
