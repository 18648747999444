import React from 'react';
import styled from 'styled-components';
import globals from 'styles/globals';
import UserAvatar from 'components/UserAvatar';
import { XIcon } from 'components/Icons';

const ChannelList = ({
  changeChannel,
  user,
  currentChannel,
  channels,
  messages,
  unreadMessagesFlag,
  handleHideChannel,
}) => (
  <Channels>
    <UserGroups>Open Channels</UserGroups>
    {channels?.map((channel) => (
      <React.Fragment key={`${channel.attributes.uuid}-container`}>
        {(channel.relationships.users.data.length > 2)
          ? (
            <UserInList
              type="button"
              key={channel.attributes.uuid}
              onClick={() => changeChannel(channel)}
            >
              {(channel?.id === currentChannel?.id) ? <UserNameSelected /> : null}
              <ChannelName isActive={(channel?.id === currentChannel?.id)}>
                <ChannelDot isActive={channel?.id === currentChannel?.id}>
                  +{channel.included.length - 1}
                </ChannelDot>
                <ChannelTitle isActive={(channel?.id === currentChannel?.id)}>
                  {channel.attributes.name
                    ? channel.attributes.name
                    : 'Chat with ...'}
                </ChannelTitle>
              </ChannelName>
              <UnreadMessageCount
                unread={messages[channel.uuid]?.unread || unreadMessagesFlag.includes(channel?.id)}
              >
                {messages[channel.uuid]?.unread
                  ? messages[channel.uuid]?.unread
                  : '!'}
              </UnreadMessageCount>
              {(channel?.id === currentChannel?.id) && <SXIcon size={10} onClick={() => handleHideChannel()} data-testid="hide-channel" />}
            </UserInList>
          )
          : channel.relationships.users.data.map((channelUser) => (
            channel.included.map((allUser) => {
              if (
                channelUser.id === allUser.id
                && allUser.id !== user.id
                && allUser.attributes?.first_name
              ) {
                return (
                  <UserInList
                    type="button"
                    key={channel.attributes.uuid}
                    onClick={() => changeChannel(channel)}
                  >
                    {(channel?.id === currentChannel?.id) ? <UserNameSelected /> : null}
                    <UserAvatar
                      user={allUser}
                      showName
                      isActive={(channel?.id === currentChannel?.id)}
                      truncate
                    />
                    <UnreadMessageCount
                      unread={messages[channel.uuid]?.unread || unreadMessagesFlag.includes(channel?.id)}
                    >
                      {messages[channel.uuid]?.unread
                        ? messages[channel.uuid]?.unread
                        : '!'}
                    </UnreadMessageCount>
                    {(channel?.id === currentChannel?.id)
                      ? <SXIcon size={10} onClick={() => handleHideChannel()} /> : null}
                  </UserInList>
                );
              }
              return null;
            })
          ))}
      </React.Fragment>
    ))}

  </Channels>
);

const Channels = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const SXIcon = styled(XIcon)`
  transform: translateX(-20px);
`;

const UserGroups = styled.span`
  margin: 10px;
  font-weight: bold;
  color: ${globals.colors.black};
`;

const UserNameSelected = styled.div`
  position: absolute;
  left: -15px;
  width: 6px;
  height: 26px;
  background-color: orange;
`;

const UserInList = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  color: ${globals.colors.black};
  width: 100%;
  border: none;
  padding: 5px;
  margin-left: 15px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const ChannelName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 146px;
  flex: 1;
`;

const ChannelTitle = styled.div`
  color: ${({ isActive }) => (isActive ? globals.colors.fontDark : globals.colors.fontLight)};
  font-weight: ${({ isActive }) => (isActive ? 500 : 'inherit')};
  max-width: 120px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ChannelDot = styled.div`
  font-size: 9pt;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  min-width: 25px;
  border-radius: 50%;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.black : theme.colors.darkerGrey)};
  margin-right: 6px;
  color: white;
`;

const UnreadMessageCount = styled.div`
  display: ${({ unread }) => ((unread > 0) ? 'auto' : 'none')};
  position: absolute;
  right: 15px;
  top: 10px;
  height: 16px;
  width: 18px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font: ${({ theme }) => theme.fonts.label};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 600;
  border-radius: 5px;
  padding-top: 1px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export default ChannelList;
