import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* cloneCytometer({ payload }) {
  const { cytometerID, cytometerProfileID } = payload;
  const { success, data, error } = yield* makeRequest.action(payload.action, { cytometerID, cytometerProfileID });
  if (success && data) {
    if (payload.onSuccess) {
      payload.onSuccess(data?.data?.id);
    }
    yield put(newMessageEvent({ text: 'Cytometer Added' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default cloneCytometer;
