import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { PANEL_CATEGORIES } from 'lib/constants';
import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { UnwrappedResourceList } from 'types/json-api-types';
import { PanelAttributes, PanelCategories, PanelStain, PanelStainAttributes, StainingSheetAttributes } from 'types/panels';
import { GenericActionPayload } from 'types/redux-types';

type AvailabilityData = Record<string, Record<string, Record<string, never>>>;

type PanelsState = { [key in PanelCategories]: ResourceList<PanelAttributes> | null } & {
  allPanels: ResourceList<PanelAttributes> | null
  details: Resource<PanelAttributes> | null,
  catalogAvailability: AvailabilityData | null,
  bdAvailability: AvailabilityData | null,
  selectedPanelCategory: PanelCategories,
  stainingSheet: Resource<StainingSheetAttributes> | null,
  panelStains: UnwrappedResourceList<PanelStainAttributes> | null,
};

const initialState: PanelsState = {
  [PANEL_CATEGORIES.CUSTOM]: null,
  [PANEL_CATEGORIES.OMIP]: null,
  [PANEL_CATEGORIES.BD]: null,
  allPanels: null,
  details: null,
  catalogAvailability: null,
  bdAvailability: null,
  stainingSheet: null,
  selectedPanelCategory: PANEL_CATEGORIES.CUSTOM,
  panelStains: null,
};

export type RequestPanelsParams = {
  organizationID: string,
}

export type RequestPanelsQuery = {
  pageSize?: number,
  search?: string,
  created_by?: number,
  'antigens[]'?: string[],
  clone?: string,
  page?: number,
  category?: typeof PANEL_CATEGORIES[keyof typeof PANEL_CATEGORIES]
}

const requestPanels = createAction<GenericActionPayload<RequestPanelsParams, RequestPanelsQuery>>('panels/requestPanels');
const requestCustomPanels = createAction<GenericActionPayload<RequestPanelsParams, RequestPanelsQuery>>('panels/requestCustomPanels');
const requestOMIPPanels = createAction<GenericActionPayload<RequestPanelsParams, RequestPanelsQuery>>('panels/requestOMIPPanels');
const requestBDPanels = createAction<GenericActionPayload<RequestPanelsParams, RequestPanelsQuery>>('panels/requestBDPanels');
const requestPanelDetail = createAction<GenericActionPayload<{ panelID: string }>>('panels/requestPanelDetail');
const requestPanelCatalogAvailability = createAction('panels/requestPanelCatalogAvailability');
const requestPanelbdAvailability = createAction('panels/requestPanelbdAvailability');
const requestStainingSheet = createAction<GenericActionPayload>('panels/requestStainingSheet');
const updateStainingSheet = createAction<GenericActionPayload>('panels/updateStainingSheet');
const requestPanelStains = createAction<GenericActionPayload>('panels/requestPanelStains');
const createPanelStain = createAction<GenericActionPayload<PanelStain>>('panels/createPanelStain');
const updatePanelStain = createAction<GenericActionPayload<PanelStain>>('panels/updatePanelStain');
const deletePanelStain = createAction<GenericActionPayload>('panels/deletePanelStain');
const deletePanel = createAction('panels/deletePanel');

export const panelsSlice = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    setAllPanels: (state, action: PayloadAction<ResourceList<PanelAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.allPanels = state.allPanels?.mergeWith(action.payload) ?? state.allPanels;
        return;
      }
      state.allPanels = action.payload;
    },
    setCustomPanels: (state, action: PayloadAction<ResourceList<PanelAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state[PANEL_CATEGORIES.CUSTOM] = state[PANEL_CATEGORIES.CUSTOM]?.mergeWith(action.payload) ?? state[PANEL_CATEGORIES.CUSTOM];
        return;
      }
      state[PANEL_CATEGORIES.CUSTOM] = action.payload;
    },
    setOMIPPanels: (state, action: PayloadAction<ResourceList<PanelAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state[PANEL_CATEGORIES.OMIP] = state[PANEL_CATEGORIES.OMIP]?.mergeWith(action.payload) ?? state[PANEL_CATEGORIES.OMIP];
        return;
      }
      state[PANEL_CATEGORIES.OMIP] = action.payload;
    },
    setBDPanels: (state, action: PayloadAction<ResourceList<PanelAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state[PANEL_CATEGORIES.BD] = state[PANEL_CATEGORIES.BD]?.mergeWith(action.payload) ?? state[PANEL_CATEGORIES.BD];
        return;
      }
      state[PANEL_CATEGORIES.BD] = action.payload;
    },
    setPanelDetails: (state, action: PayloadAction<ResourceList<PanelAttributes>>) => {
      const unwrapped = action.payload?.unwrap();
      if ('id' in unwrapped) {
        state.details = unwrapped;
      }
    },
    setPanelCatalogAvailability: (state, action: PayloadAction<ResourceList>) => {
      state.catalogAvailability = action.payload?.unwrap()?.attributes?.availability_data as AvailabilityData;
    },
    setPanelbdAvailability: (state, action: PayloadAction<ResourceList>) => {
      if (!(Object.keys(action.payload).length === 0)) {
        state.bdAvailability = action.payload?.unwrap()?.attributes?.bd_availability_data as AvailabilityData;
      }
    },
    setPanelStains: (state, action: PayloadAction<ResourceList<PanelStainAttributes> | null>) => {
      const panelStains = action.payload?.unwrap?.() as UnwrappedResourceList<PanelStainAttributes> | undefined;
      if (panelStains) {
        state.panelStains = panelStains;
      } else {
        state.panelStains = null;
      }
    },
    setStainingSheet: (state, action: PayloadAction<ResourceList<StainingSheetAttributes> | null>) => {
      const stainingSheet = action.payload?.unwrap?.() as Resource<StainingSheetAttributes> | undefined;
      if (stainingSheet) {
        state.stainingSheet = stainingSheet;
      } else {
        state.stainingSheet = null;
      }
    },
    setSelectedPanelCategory: (state, action: PayloadAction<PanelCategories>) => {
      state.selectedPanelCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setAllPanels,
  setCustomPanels,
  setOMIPPanels,
  setBDPanels,
  setPanelDetails,
  setSelectedPanelCategory,
  setPanelCatalogAvailability,
  setPanelbdAvailability,
  setStainingSheet,
  setPanelStains,
} = panelsSlice.actions;

// Actions
export {
  requestPanels,
  requestPanelDetail,
  requestCustomPanels,
  requestOMIPPanels,
  requestBDPanels,
  requestPanelCatalogAvailability,
  requestPanelbdAvailability,
  requestStainingSheet,
  updateStainingSheet,
  requestPanelStains,
  createPanelStain,
  updatePanelStain,
  deletePanelStain,
  setAllPanels,
  setCustomPanels,
  setOMIPPanels,
  setBDPanels,
  setPanelDetails,
  setPanelCatalogAvailability,
  setPanelbdAvailability,
  setStainingSheet,
  setPanelStains,
  deletePanel,
  setSelectedPanelCategory,
};

// Selectors
export const getPanels = (state: RootState) => state.panels.allPanels;
export const getCustomPanels = (state: RootState) => state.panels[PANEL_CATEGORIES.CUSTOM];
export const getOMIPPanels = (state: RootState) => state.panels[PANEL_CATEGORIES.OMIP];
export const getBDPanels = (state: RootState) => state.panels[PANEL_CATEGORIES.BD];
export const getPanelDetails = (state: RootState) => state.panels.details;
export const getSelectedPanelCategory = (state: RootState) => state.panels.selectedPanelCategory;
export const getPanelCatalogAvailability = (state: RootState) => state.panels.catalogAvailability;
export const getPanelbdAvailability = (state: RootState) => state.panels.bdAvailability;
export const getStainingSheet = (state: RootState) => state.panels.stainingSheet;
export const getPanelStains = (state: RootState) => state.panels.panelStains;

// Reducer
export default panelsSlice.reducer;
