import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { useMixpanel } from 'hooks/useMixpanel';

const useMixpanelPageview = (page: string) => {
  const mixpanel = useMixpanel();
  const org = useAppSelector(getCurrentOrganization);
  const orgName = useMemo(() => org.attributes.name, [org.attributes.name]);

  useEffect(() => {
    let tracked = false;
    if (!tracked) {
      mixpanel?.track_pageview({ page, organization: orgName });
      tracked = true;
    }
    return () => {
      tracked = false;
    }
  }, [mixpanel, orgName, page]);
}

export { useMixpanelPageview };