import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import Action from 'lib/jsonApi/Action';
import Resource from 'lib/jsonApi/Resource';
import type ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { CytometerSettings } from 'types/cytometers';
import { ExperimentAttributes, ExperimentPlate, ExperimentPlateSize, ExperimentSpecimen, ExperimentWorksheet, PanelMatchingAttributes } from 'types/experiments';
import { GenericActionPayload } from 'types/redux-types';

export type RequestExperimentsQuery = Partial<{
  pageSize: number,
  page: number,
  sorting: string,
  search: string,
  created_by: number,
  project_id: number,
  group_id: number,
}>

export type CreateExperimentParams = {
  name: string,
  project_id: number,
  cytometer_profile_id: number,
  software_version: string,
  plate_editor?: boolean,
  plate_size?: ExperimentPlateSize,
  worksheets?: ExperimentWorksheet[],
  plates?: ExperimentPlate[],
  specimens?: ExperimentSpecimen[],
}

export type UpdateExperimentParams = {
  name?: string,
  project_id?: number
  cytometer_profile_id?: number
  software_version?: string,
  plate_editor?: boolean,
  hide_unused_params?: boolean,
  plates?: ExperimentPlate[],
  specimens?: ExperimentSpecimen[],
  worksheets?: ExperimentWorksheet,
  cytometer_settings?: (CytometerSettings & { visible: boolean })[],
  parameters_choices?: Record<string, Record<string, string>>,
  chorus_plate_data?: Record<any, any>,
};

const requestExperiments = createAction<GenericActionPayload<{ organizationID: string }, RequestExperimentsQuery>>('experiments/requestExperiments');
const requestExperiment = createAction<GenericActionPayload<{ organizationID: string, experimentID: string }>>('experiments/requestExperiment');
const cloneExperiment = createAction<GenericActionPayload>('experiments/cloneExperiment');
const createExperiment = createAction<GenericActionPayload<CreateExperimentParams>>('experiments/createExperiment');
const updateExperiment = createAction<GenericActionPayload<UpdateExperimentParams>>('experiments/updateExperiment');
const deleteExperiment = createAction<GenericActionPayload & { action: Action }>('experiments/deleteExperiment');
const requestPanelMatching = createAction<GenericActionPayload>('experiments/requestPanelMatching');
const downloadPlatePdf = createAction<GenericActionPayload>('experiments/downloadPlatePdf');
const parseTemplateFile = createAction<GenericActionPayload>('experiments/parseTemplateFile');

interface ExperimentsState {
  experiments: ResourceList<ExperimentAttributes> | null,
  experiment: Resource<ExperimentAttributes> | null,
  panelMatching: Resource<PanelMatchingAttributes> | null,
}

const initialState: ExperimentsState = {
  experiments: null,
  experiment: null,
  panelMatching: null,
};

export const experimentsSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    setExperiments: (state, action: PayloadAction<ResourceList<ExperimentAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.experiments = state.experiments?.mergeWith(action.payload) ?? state.experiments;
      } else {
        state.experiments = action.payload;
      }
    },
    setExperiment: (state, action: PayloadAction<ResourceList<ExperimentAttributes> | null>) => {
      if (action.payload === null) {
        state.experiment === null;
        return;
      }
      const unwrapped = action.payload?.unwrap();
      if (!Array.isArray(unwrapped)) {
        state.experiment = unwrapped ?? null;
      }
    },
    setPanelMatching: (state, action: PayloadAction<ResourceList<PanelMatchingAttributes> | null>) => {
      if (action.payload === null) {
        state.panelMatching = null;
        return;
      }
      const unwrapped = action.payload.unwrap();
      if (unwrapped instanceof Resource) {
        state.panelMatching = unwrapped;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setExperiments,
  setExperiment,
  setPanelMatching,
} = experimentsSlice.actions;

// Selectors
export const getExperiments = (state: RootState) => state.experiments.experiments;
export const getExperiment = (state: RootState) => state.experiments.experiment;
export const getPanelMatching = (state: RootState) => state.experiments.panelMatching;

// Actions
export {
  setExperiments,
  setExperiment,
  setPanelMatching,
  requestExperiments,
  requestExperiment,
  cloneExperiment,
  createExperiment,
  updateExperiment,
  deleteExperiment,
  requestPanelMatching,
  downloadPlatePdf,
  parseTemplateFile,
};

export default experimentsSlice.reducer;
