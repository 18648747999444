import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { requestSession } from 'rdx/modules/auth/slice';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* marcomOptInSaga({ payload: { action, onSuccess, onError } }) {
  const { success, error, data } = yield* makeRequest.action(action);
  if (success && data) {
    yield put(requestSession());
    yield put(newMessageEvent({ text: 'Marketing Communication preference updated' }));
  }
  if (success) {
    onSuccess?.();
  } else {
    onError?.();
    yield* putErrorActions({ error });
  }
}

export default marcomOptInSaga;
