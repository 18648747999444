import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const ErrorOctagon = forwardRef((props, ref) => (
  <svg ref={ref} {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M16 2h-8l-6 6v8l6 6h8l6-6v-8l-6-6zM11 7h2v6h-2v-6zM12 17c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5 1.5 0.7 1.5 1.5c0 0.8-0.7 1.5-1.5 1.5z" />
  </svg>
));

export default createIcon(ErrorOctagon);
