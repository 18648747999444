import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getOrgUsers from 'rdx/modules/users/sagas/getOrgUsers';
import getGroupUsers from 'rdx/modules/users/sagas/getGroupUsers';
import getGroupUser from 'rdx/modules/users/sagas/getGroupUser';
import postInviteSaga from 'rdx/modules/users/sagas/postInvite';
import inviteOrgUserSaga from 'rdx/modules/users/sagas/inviteOrgUser';
import deleteGroupUserSaga from 'rdx/modules/users/sagas/deleteGroupUser';
import patchGroupUserSaga from 'rdx/modules/users/sagas/patchGroupUser';
import confirmGroupUserSaga from 'rdx/modules/users/sagas/confirmGroupUser';
import confirmOrgUserSaga from 'rdx/modules/users/sagas/confirmOrgUser';
import patchOrgUserSaga from 'rdx/modules/users/sagas/patchOrgUser';
import deleteOrgUserSaga from 'rdx/modules/users/sagas/deleteOrgUser';
import getOrgUser from 'rdx/modules/users/sagas/getOrgUser';
import revokeConsentSaga from 'rdx/modules/users/sagas/revokeConsent';
import requestUsersSaga from 'rdx/modules/users/sagas/requestUsers';
import updateUserSaga from 'rdx/modules/users/sagas/updateUser';
import updateUserTutorialsSaga from 'rdx/modules/users/sagas/updateUserTutorials';
import getUser from 'rdx/modules/users/sagas/getUser';
import getUserPermissions from 'rdx/modules/users/sagas/getUserPermissions';
import getPermissionUsersSaga from 'rdx/modules/users/sagas/getPermissionUsers';
import { confirmGroupUser, confirmOrgUser, deleteGroupUser, deleteOrgUser, inviteOrgUser, marcomOptIn, patchGroupUser, patchOrgUser, postInvite, requestGroupUser, requestGroupUsers, requestOrgUser, requestOrgUsers, requestPermissionUsers, requestUser, requestUserPermissions, requestUsers, revokeConsent, updateUser, updateUserTutorials } from 'rdx/modules/users/slice';
import marcomOptInSaga from './marcomOptIn';

function* watchUsersSagas() {
  yield trackRequests(takeEvery, updateUser.type, updateUserSaga);
  yield trackRequests(takeEvery, updateUserTutorials.type, updateUserTutorialsSaga);
  yield trackRequests(takeEvery, requestUser.type, getUser);
  yield trackRequests(takeEvery, requestUserPermissions.type, getUserPermissions);
  yield trackRequests(takeEvery, requestOrgUsers.type, getOrgUsers);
  yield trackRequests(takeEvery, requestGroupUsers.type, getGroupUsers);
  yield trackRequests(takeEvery, requestGroupUser.type, getGroupUser);
  yield trackRequests(takeEvery, postInvite.type, postInviteSaga);
  yield trackRequests(takeEvery, inviteOrgUser.type, inviteOrgUserSaga);
  yield trackRequests(takeEvery, deleteGroupUser.type, deleteGroupUserSaga);
  yield trackRequests(takeEvery, patchGroupUser.type, patchGroupUserSaga);
  yield trackRequests(takeEvery, confirmGroupUser.type, confirmGroupUserSaga);
  yield trackRequests(takeEvery, confirmOrgUser.type, confirmOrgUserSaga);
  yield trackRequests(takeEvery, patchOrgUser.type, patchOrgUserSaga);
  yield trackRequests(takeEvery, deleteOrgUser.type, deleteOrgUserSaga);
  yield trackRequests(takeEvery, requestOrgUser.type, getOrgUser);
  yield trackRequests(takeEvery, revokeConsent.type, revokeConsentSaga);
  yield trackRequests(takeEvery, requestUsers.type, requestUsersSaga);
  yield trackRequests(takeEvery, requestPermissionUsers.type, getPermissionUsersSaga);
  yield trackRequests(takeEvery, marcomOptIn.type, marcomOptInSaga);
}

export default watchUsersSagas;
