import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useModal } from 'hooks/useModal';
import bdrcLogo from 'assets/bd_research_cloud.png';

import BaseModal from 'containers/Modals/BaseModal';
import { getApiVersion } from 'rdx/modules/auth/slice';

const AboutModal = () => {
  const history = useHistory();
  const { closeModal } = useModal();
  const apiVersion = useSelector(getApiVersion);
  const handleLicenseClick = () => {
    const presignedUrl = '/thirdparty.txt';
    const a = document.createElement('a');
    a.href = presignedUrl;
    a.target = '_self';
    const fileName = presignedUrl.split('/').pop();
    a.download = fileName;
    document.body.appendChild(a);
    if (window.Cypress) {
      return null;
    }
    a.click();
    return null;
  };
  const handleReleaseNotesClick = () => {
    closeModal();
    history.push('/releasenotes');
  };

  return (
    <BaseModal
      dimensions={{
        width: '400px',
        height: '300px',
      }}
      confirm={{
        render: () => {},
      }}
      cancel={{
        render: () => {},
      }}
    >
      <Logo src={bdrcLogo} />
      <Text>
        ©Becton, Dickinson and Company 2024
      </Text>
      <Text data-testid="software-version-display">
        {apiVersion && `v${apiVersion}`}
      </Text>
      <Text>
        For Research Use Only. Not for use in diagnostic or therapeutic procedures.
      </Text>
      <Text>
        BD Research Cloud includes open source software. <Link onClick={handleLicenseClick}>License Information</Link>
      </Text>
      <Text>
        <Link onClick={handleReleaseNotesClick}>Release Notes</Link>
      </Text>
    </BaseModal>
  );
};

export default AboutModal;

const Logo = styled.img`
  width: 200px;
`;

const Text = styled.div`
  margin-top: 15px;
`;

const Link = styled.span`
cursor: pointer;
color: ${({ theme }) => theme.colors.primary};
&:hover {
  text-decoration: underline;
  color: ${({ locked, theme }) => (locked ? theme.colors.warning : theme.colors.primaryDark)};
}
`;
