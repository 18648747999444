import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import downloadCsvContent from 'lib/utils/downloadCsvContent';
import { useDispatch, useSelector } from 'react-redux';
import { humanizeString } from 'lib/utils/string';
import { useLoading } from 'hooks/useLoading';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import { getCurrentOrganization, fetchCSV } from 'rdx/modules/organization/slice';

const watchRequests = [fetchCSV.type];

const REPORTS = {
  activity_log: 'Download a record of all organizational resource activity for the last 6 months.',
};

const OrganizationReports = () => {
  const dispatch = useDispatch();

  const org = useSelector(getCurrentOrganization);

  const reportDownloading = useLoading({ watchRequests });

  const downloadReport = React.useCallback((action) => {
    dispatch(fetchCSV({
      action,
      onSuccess: ({ data }) => {
        const filename = `${org.name.replace(/\s+/g, '_')}_${action.name}_${moment().format(moment.HTML5_FMT.DATETIME_LOCAL)}_.csv`.replace(/-/g, '_');
        downloadCsvContent(filename, data);
      },
    }));
  }, [dispatch, org.name]);

  const reportItems = React.useMemo(() => {
    const jsx = [];
    Object.entries(REPORTS).forEach(([name, description]) => {
      const action = org.getAction(name);
      const actionID = name.replace(/_/g, '-');
      if (action) {
        jsx.push(
          <ReportItem id={`${actionID}-row`} key={actionID}>
            <Explanation>
              <h2 id={`${actionID}-title`}>{humanizeString(name)}</h2>
              <p id={`${actionID}-description`}><small>{description}</small></p>
            </Explanation>
            <PrimaryButton
              id={`${actionID}-download-button`}
              text="download CSV"
              loading={reportDownloading}
              onClick={() => downloadReport(action)}
            />
          </ReportItem>
        );
      }
    });
    return jsx;
  }, [downloadReport, org, reportDownloading]);

  return (
    <Wrapper>
      {reportItems}
    </Wrapper>
  );
};

export default OrganizationReports;

const ReportItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Explanation = styled.div`
  max-width: 50%;
`;

const Wrapper = styled.ul`
  padding: 1rem 2rem;
`;
