const PANEL_CATEGORIES = {
  CUSTOM: 'custom',
  OMIP: 'omip',
  BD: 'bd',
} as const;

const POPULATION_CATEGORIES = {
  CUSTOM: 'custom',
  BD: 'bd',
} as const;

const STAINING_SHEET_BSB = {
  563794: { volume: 50, name: 'Brilliant Stain Buffer' },
  566349: { volume: 50, name: 'Brilliant Stain Buffer' },
  659611: { volume: 50, name: 'Brilliant Stain Buffer' },
  566385: { volume: 10, name: 'Brilliant Stain Buffer Plus' },
  568264: { volume: 10, name: 'Brilliant Stain Buffer Plus' },
} as const;

const STAINING_SHEET_REAGENT_CATEGORIES = {
  antigen: 'antigen',
  viabilityDye: 'viability_dye',
  fluorescentBead: 'fluorescent_bead',
  additionalReagent: 'addon',
  buffer: 'buffer',
  brilliantStainBuffer: 'brilliant_stain_buffer',
  defaultBuffer: 'default_buffer',
} as const;

const WORKFLOW_TYPES = {
  PANEL: 'panel',
  CUSTOM: 'custom',
  TITRATION: 'titration',
  FLOW_AI: 'flowai',
  PEACOQC: 'peacoqc',
  SEQUENCING: 'sequencing',
  FAVA: 'fava',
} as const;

const PROCEDURE_TEMPLATE_SLUGS = {
  PEACOQC: 'bd_analysis_peacoqc',
  FLOW_AI: 'bd_analysis_flow_ai',
  FILE_SELECTOR: 'bd_analysis_file_selector',
  TITRATION: 'bd_analysis_fcs_titration',
  SEQUENCING: 'bd_sequencing',
  FAVA: 'bd_fava',
  PANEL_MAKER: 'bd_panel_maker',
} as const;

const PREDEFINED_WORKFLOWS = {
  FLOW_AI: {
    title: 'FlowAI',
    runOrCreate: 'Create',
  },
  PEACOCQ: {
    title: 'PeacoQC',
    runOrCreate: 'Create',
  },
  BD_RHAPSODY_PIPELINE: {
    title: 'BD Rhapsody Pipeline',
    runOrCreate: 'Run',
  },
  FAVA: {
    title: 'FAVA',
    runOrCreate: 'Run',
  },
  TITRATION: {
    title: 'Titration',
    runOrCreate: 'Create',
  },
} as const;

const GATED_FEATURE_BLURB_SLUGS = {
  REAGENTS_INVENTORY_TAB: 'gfb-inventory-tab',
  REAGENTS_INVENTORY_TAB_INDIVIDUAL: 'gfb-inventory-tab-individual',
  SHARE_FILES_OUTSIDE_ORG: 'gfb-share-files-outside-org',
  SHARE_FILES_OUTSIDE_ORG_INDIVIDUAL: 'gfb-share-files-outside-org-individual',
  SHARE_PANELS: 'gfb-share-panels',
  SHARE_PANELS_INDIVIDUAL: 'gfb-share-panels-individual',
  STAINING_SHEETS_INVENTORY_UPDATES: 'gfb-staining-sheets-inventory-updates',
  STAINING_SHEETS_INVENTORY_UPDATES_INDIVIDUAL: 'gfb-staining-sheets-inventory-updates-individual',
  CYTOMETER_DASHBOARD_TAB: 'gfb-cytometer-dashboard-tab',
  CYTOMETER_DASHBOARD_TAB_INDIVIDUAL: 'gfb-cytometer-dashboard-tab-individual',
} as const;

const ORG_PERMISSIONS = {
  MANAGE_USERS: {
    label: 'Manage organizational level permissions',
    value: 'manage_users',
    tooltip: "When this permission is on, the user can approve invites to the organizations for other users and change other users’ permissions.",
  },
  CYTOMETERS: {
    label: 'Manage cytometers inventory',
    value: 'cytometers',
    tooltip: "This permission allows users to create new cytometer configurations for the organization.",
  },
  REAGENTS: {
    label: 'Manage custom reagents and inventory',
    value: 'reagents',
    tooltip: "This permission allows users to create and edit custom reagents, and allows users with a Premium License to manage the reagent inventory.",
  },
  WORKFLOWS_REVIEW: {
    label: 'View workflows outside group',
    value: 'workflows_review',
    tooltip: "When this permission is on, the user can view all the workflows in an organization. When off, the user can only view workflows associated with the groups they are members of.",
  },
  FILES: {
    label: 'View and edit files outside of the group',
    value: 'files',
    tooltip: "When this permission is on, the user can view and edit all the files in an organization. When off, the user can only view and edit files associated with the groups they are members of.",
  },
  DELETE_ORGANIZATION_FILES: {
    label: 'Delete files within organization',
    value: 'delete_org_files',
    tooltip: "When this permission is on, the user can delete any files they can view and edit. When off, the user can only delete files they own.",
  },
  STAINING_SHEETS: {
    label: 'Manage panel staining sheets',
    value: 'staining_sheets',
    tooltip: "When this permission is on, the user can manage custom panel staining sheets within organization.",
  },
} as const;

const GROUP_PERMISSIONS= {
  MANAGE_USERS: {
    label: 'Manage group users',
    value: 'manage_users',
    tooltip: "When this permission is on, the user can approve invites to the group for other users and change other users’ permissions within this group.",
  },
  PROJECTS: {
    label: 'Manage group projects',
    value: 'projects',
    tooltip: 'When this permission is on, the user can create and update projects within this group',
  },
  STUDIES: {
    label: 'Manage group studies',
    value: 'studies',
    tooltip: 'When this permission is on, the user can create and update studies within this group',
  },
} as const

const RSS_REPORT_STATUSES = {
  UNKNOWN: 'unknown',
  FAIL: 'fail',
  WARNING: 'warning',
  PASS: 'pass',
} as const;

const RSS_REPORT_CATEGORIES = {
  UNKNOWN: 'unknown',
  PC: 'pc',
  BL: 'bl',
} as const;

export {
  GATED_FEATURE_BLURB_SLUGS,
  PANEL_CATEGORIES,
  POPULATION_CATEGORIES,
  STAINING_SHEET_BSB,
  STAINING_SHEET_REAGENT_CATEGORIES,
  WORKFLOW_TYPES,
  PROCEDURE_TEMPLATE_SLUGS,
  PREDEFINED_WORKFLOWS,
  ORG_PERMISSIONS,
  GROUP_PERMISSIONS,
  RSS_REPORT_STATUSES,
  RSS_REPORT_CATEGORIES,
};
