import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* saveWorkflowAsTemplate({ payload: { action, values } }) {
  const { success, data, error } = yield* makeRequest.action(action, values);
  if (!success || !data) {
    yield* putErrorActions({ error });
  }

  yield put(newMessageEvent({ text: 'Workflow Saved as Template' }));

  return null;
}

export default saveWorkflowAsTemplate;
