/* eslint-disable import/prefer-default-export */
import { useRef, useEffect, useCallback } from 'react';

export const useTimer = () => {
  const timer = useRef(null);

  useEffect(() => () => {
    clearTimeout(timer.current);
  }, []);

  const start = useCallback((callback, timeout) => {
    if (!timer.current) {
      const thisTimerId = timer.current;
      timer.current = setTimeout(() => {
        callback();
        clearTimeout(thisTimerId);
        // Race conditions are 💩
        if (timer.current === thisTimerId) {
          timer.current = null;
        }
      }, timeout);
    }
  }, []);

  const stop = useCallback(() => {
    clearTimeout(timer.current);
    timer.current = null;
  }, []);

  const forceStart = useCallback((callback, timeout) => {
    stop();
    start(callback, timeout);
  }, [start, stop]);

  return { start, stop, forceStart };
};
