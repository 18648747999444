import { put, select } from 'redux-saga/effects';
import { find } from 'lodash';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionActions } from 'rdx/modules/auth/slice';
import { getCurrentOrganization, setMoreOrganizationGroups } from '../slice';

function* getMoreOrganizationGroups({ payload }) {
  const organizationID = payload?.organization_id || (yield select(getCurrentOrganization))?.attributes?.slug;
  const sessionActions = yield select(getSessionActions);
  const action = find(sessionActions, { name: 'organization_groups' });
  const { success, data, error } = yield* makeRequest.action(
    action,
    { organization_id: organizationID, page: payload.page },
  );
  if (success && data) {
    yield put(setMoreOrganizationGroups(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getMoreOrganizationGroups;
