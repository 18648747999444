import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* deleteWorkflow({ payload }) {
  const { link, onSuccess } = payload;
  const { success, data, error } = yield* makeRequest.delete(link);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Workflow Deleted' }));
    onSuccess?.();
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteWorkflow;
