import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import type { Field, FieldProps } from 'hooks/useForm/types';

type FormFieldProps<Value, InputComponentProps> = {
  label?: React.ReactNode,
  field: Field<Value>,
  component?: React.ComponentType<any>,
  inputProps?: Partial<InputComponentProps>,
  render?: (props: FieldProps<Value>) => JSX.Element,
  compact?: boolean,
  className?: string,
  style?: React.CSSProperties,
};

const FormField = <Value, InputComponentProps>({
  label,
  field,
  component,
  inputProps,
  render,
  compact,
  className,
  style,
}: FormFieldProps<Value, InputComponentProps>) => {
  const renderedInput = useMemo(() => {
    if (render) {
      return render(field.props);
    }
    if (component) {
      const Input = component;
      return (
        <Input width="100%" {...field.props} {...inputProps} />
      );
    }
    return null;
  }, [component, field.props, inputProps, render]);

  const renderedErrors = useMemo(() => field.errors.map((error, i) => (
    <Error key={`${error}-${i}`} id={`${field.props.name}-error`}>
      * {error}
    </Error>
  )), [field.errors, field.props.name]);

  return (
    <Container {...{ compact, className, style }}>
      <Label {...{ compact }}>
        {label}
      </Label>
      <InputContainer>
        {renderedInput}
        {field.visited && field.errors.length > 0 && (
          <ErrorBar />
        )}
      </InputContainer>
      {field.visited && (
        <Errors compact={compact}>
          {renderedErrors}
        </Errors>
      )}
    </Container>
  );
};

export default FormField;

const Container = styled.div<{ compact?: boolean }>`
  &:not(:last-child) {
    margin-bottom: ${({ compact }) => (compact ? '15px' : '25px')};
  }
`;

const Label = styled.div<{ compact?: boolean }>`
  ${({ compact }) => (compact
    ? css`
      font-size: 12px;
      text-transform: uppercase;
    `
    : css`
      font-size: 18px;
      margin-bottom: 8px;
    `)}
`;

const InputContainer = styled.div`
  position: relative;
`;

const ErrorBar = styled.div`
  position: absolute;
  top: 0px;
  bottom: 1px;
  left: -10px;
  width: 2px;
  background-color: ${({ theme }) => theme.colors.danger};
`;

const Errors = styled.div<{ compact?: boolean }>`
  font-size: ${({ compact }) => (compact ? '13px' : '14px')};
  color: ${({ theme }) => theme.colors.danger};
  text-transform: uppercase;
`;

const Error = styled.div`
  margin-top: 3px;
`;
