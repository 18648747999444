import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setPermissionUsers } from 'rdx/modules/users/slice';

function* getPermissions({ payload: { action, params, onSuccess, onError } }) {
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (success) {
    onSuccess(data);
    yield put(setPermissionUsers(data));
    return null;
  }
  if (error) {
    if (onError) {
      onError(error);
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default getPermissions;
