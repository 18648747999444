import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { select, put } from 'redux-saga/effects';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { requestOrgUsers } from 'rdx/modules/users/slice';

function* confirmOrgUser({ payload: { action, params } }) {
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    const organization_id = yield select(getCurrentOrgSlug);
    yield put(requestOrgUsers({ organization_id, is_active: '1' }));
    yield put(requestOrgUsers({ organization_id, confirmed: '0' }));
    yield put(requestOrgUsers({ organization_id, is_active: '0' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default confirmOrgUser;
