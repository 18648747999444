import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { subtractFromStyleString } from 'lib/utils/styles/doMathOnStyleString';
import globals from 'styles/globals';

import HollowButton from 'components/DeprecatedButton/HollowButton/index';
import { BtnSize } from 'components/DeprecatedButton/index';
import SwitchTable from 'components/Formik/SwitchTable';
import Dropdown from 'components/Dropdown/index';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { getUserPermissions, requestUserPermissions } from 'rdx/modules/users/slice';

const { layout } = globals;

const PermissionsTab = ({ onCancel }) => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(getUserPermissions);
  const currentOrg = useSelector(getCurrentOrganization);

  const [activeOrg, setActiveOrg] = useState();
  const [activeGroup, setActiveGroup] = useState();

  const dropdownItems = useMemo(() => userPermissions?.data?.map((p) => (
    { value: p?.attributes?.organization_id, display: p?.attributes?.organization_name }
  )), [userPermissions]);

  const selectedPermission = useMemo(() => userPermissions?.data?.find((up) => (
    parseInt(up.id) === activeOrg
  )), [activeOrg, userPermissions]);

  const dropdownItemsGroup = useMemo(() => selectedPermission?.attributes?.groups?.map((g) => (
    { value: g.group_id, display: g.group_name }
  )), [selectedPermission]);

  const selectedGroup = useMemo(() => selectedPermission?.groups?.find((g) => (
    parseInt(g.group_id) === activeGroup
  )), [activeGroup, selectedPermission]);

  const scopes = useMemo(() => Object.entries(selectedPermission?.organization_scope || {}), [selectedPermission]);
  const isOrgAdmin = selectedPermission?.attributes?.is_org_owner;
  const isGroupAdmin = selectedGroup?.is_group_owner;
  const groupScopes = useMemo(() => Object.entries(selectedGroup?.group_scope || {}), [selectedGroup]);

  useEffect(() => {
    const currentOrgOption = dropdownItems?.find(({ value }) => String(value) === currentOrg?.id);
    if (currentOrgOption) {
      setActiveOrg(currentOrgOption?.value);
    } else {
      setActiveOrg(dropdownItems?.[0]?.value);
    }
  }, [currentOrg?.id, dropdownItems]);

  useEffect(() => {
    setActiveGroup(dropdownItemsGroup?.[0]?.value);
  }, [dropdownItemsGroup]);

  useEffect(() => {
    dispatch(requestUserPermissions());
  }, [dispatch]);

  const [scopeSwitchList, initialScopeValues] = useMemo(() => {
    const initialValues = {};
    const switchList = {};
    if (Array.isArray(scopes)) {
      scopes.forEach((scope) => {
        const { label, value } = scope?.[1];
        const scopeName = scope?.[0];
        initialValues[scopeName] = isOrgAdmin ? true : value;
        switchList[label] = {
          id: scopeName,
          text: label,
          disabled: true,
          tooltip: isOrgAdmin ? 'Permissions are always on for Org owners' : '',
        };
      });
    }
    return [Object.values(switchList), initialValues];
  }, [isOrgAdmin, scopes]);

  const [groupScopeSwitchList, initialGroupScopeValues] = useMemo(() => {
    const initialValues = {};
    const switchList = {};
    groupScopes.forEach((scope) => {
      const { label, value } = scope?.[1];
      const scopeName = scope?.[0];
      initialValues[scopeName] = isGroupAdmin ? true : value;
      switchList[label] = {
        id: scopeName,
        text: label,
        disabled: true,
        tooltip: isGroupAdmin ? 'Permissions are always on for Group owners' : '',
      };
    });
    return [Object.values(switchList), initialValues];
  }, [isGroupAdmin, groupScopes]);

  return (
    <SummaryContainer>
      <HorizontalContainer>
        <TableContainer>
          <Title>Organization Permissions</Title>
          <DropdownContainer>
            <Dropdown
              options={dropdownItems}
              value={activeOrg}
              onChange={(value) => setActiveOrg(value)}
            />
          </DropdownContainer>
          <SwitchTable
            switches={scopeSwitchList}
            values={initialScopeValues}
          />
          {isOrgAdmin && (
            <OwnerMessage>You are the Org Owner</OwnerMessage>
          )}
        </TableContainer>
        <TableContainer>
          <Title>Group Permissions</Title>
          <DropdownContainer>
            <Dropdown
              options={dropdownItemsGroup}
              value={activeGroup}
              onChange={(value) => setActiveGroup(value)}
              disabled={!selectedPermission}
              placeholder={dropdownItemsGroup?.length ? 'Select a group' : 'No groups found'}
            />
          </DropdownContainer>
          {!!scopes?.length && (
            <SwitchTable
              switches={groupScopeSwitchList}
              values={initialGroupScopeValues}
            />
          )}
          {isGroupAdmin && (
            <OwnerMessage>You are the Group Owner</OwnerMessage>
          )}
        </TableContainer>
      </HorizontalContainer>
      <ButtonContainer>
        <HollowButton
          size={BtnSize.NARROW}
          text="Cancel"
          onClick={onCancel}
          baseStyle={{ marginRight: '10px' }}
        />
      </ButtonContainer>
    </SummaryContainer>
  );
};

PermissionsTab.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default PermissionsTab;

const drawerMinus = subtractFromStyleString(layout.drawerPadding, 30);

const SummaryContainer = styled.div`
  flex: 1;
  padding: ${drawerMinus} ${layout.drawerPadding};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
`;

const OwnerMessage = styled.div`
  margin: 5px;
  color: ${({ theme }) => theme.colors.blue};
`;

const FormRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  justify-content: flex-start;
`;

const ButtonContainer = styled(FormRow)`
  width: 100%;
  justify-content: flex-end;
  margin: 30px 0;
`;

const DropdownContainer = styled.div`
  margin: 10px 0;
`;

const HorizontalContainer = styled.div`
  display: flex;
`;
