import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setLicenseQuoteURL } from '../slice';

function* requestLicenseQuoteURL({ payload }) {
  const { action, params } = payload;
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (error) {
    yield* putErrorActions({ error });
  }
  if (success && data) {
    const { data: { attributes: { url } } } = data;
    yield put(setLicenseQuoteURL(url));
  }
  return null;
}

export default requestLicenseQuoteURL;
