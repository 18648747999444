import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styled, { css } from 'styled-components';
import globals from 'styles/globals';
import { Routes, RouteNames } from 'containers/Main/constants';
import Link from 'components/Link';
import { useLocationParsed } from 'hooks/useLocationParsed';
import { useURLParam } from 'hooks/useURLParam';
import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';
import { getExperiment } from 'rdx/modules/experiments/slice';
import { getSingleFile } from 'rdx/modules/fileExplorer/slice';
import { getWorkflowRaw } from 'rdx/modules/workflow/slice';
import { getProcedureByID } from 'rdx/modules/procedures/slice';
import { getCurrentCytometer } from 'rdx/modules/cytometers/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { getActiveNavTab } from 'rdx/modules/nav/slice';
import { getPanelDetails } from 'rdx/modules/panels/slice';

const BreadCrumb = ({ orgSlug }) => {
  const parsedLocation = useLocationParsed();
  const urlParams = new URLSearchParams(window.location.search);
  const stepId = urlParams.get('stepId');
  const procedureId = urlParams.get('procedureId');
  const organization = useSelector(getCurrentOrganization, shallowEqual);
  const workflowRaw = useSelector(getWorkflowRaw);
  const workflow = useMemo(() => workflowRaw?.unwrap(), [workflowRaw]);
  const currentCytometer = useSelector(getCurrentCytometer)
  const currentPanel = useSelector(getPanelDetails)
  const experiment = useSelector(getExperiment);
  const file = useUnwrappedSelector(getSingleFile, true);
  const procedure = useSelector(getProcedureByID(procedureId));
  const activeTab = useSelector(getActiveNavTab)
  const currentTab = useURLParam('tab');
  const workflowTab = useURLParam('workflow_status');
  const cytometerTab = useURLParam('cytometer_tab')
  const panelTab = useURLParam('panel_tab')
  const tab = useMemo(() => currentTab || workflowTab || cytometerTab || panelTab, [currentTab, workflowTab, cytometerTab, cytometerTab]);

  const items = useMemo(() => {
    const result = [];

    const mainRouteId = Object.entries(Routes)
      .find(([, key]) => parsedLocation.find((item) => {
        const isWorkflowGroup = item.key === 'group' && item.id != null;
        return !isWorkflowGroup && item.key === key;
      }))
      ?.[0];
    const mainRouteKey = Routes[mainRouteId];
    result.push({
      text: `${organization?.name}`,
      link: `/${orgSlug}`,
      testId: 'org-breadcrumb',
    });

    parsedLocation.forEach(({ key, id: resourceId }) => {
      if (key === orgSlug) {
        return;
      }

      if (key === mainRouteKey) {
        const tabPart = tab ? `?tab=${tab}` : '';
        result.push({
          text: RouteNames[mainRouteId],
          link: `/${orgSlug}/${mainRouteKey}${tabPart}`,
          testId: `${mainRouteKey}-breadcrumb`,
        });
      }

      if (resourceId != null) {
        if (key === 'groups' && workflow) {
          const group = workflow?.getRel('group');
          result.push({
            text: group?.name,
            link: `/${orgSlug}/groups?tab=open&drawer=group-details&group_id=${resourceId}`,
            testId: 'group-breadcrumb',
          });
        }

        if (key === 'experiments' && experiment) {
          result.push({
            text: experiment?.name,
          });
        }

        if (key === 'file-explorer' && file?.id) {
          const addSubfolders = (link) => {
            if (file?.folders?.length) {
              let path = '';
              file.folders.forEach((folder) => {
                if (path) {
                  path = `${path}/${folder}`;
                } else {
                  path = folder;
                }
                result.push({
                  text: folder,
                  link: `${link}&path=${path}`,
                  unformatted: true,
                });
              });
            }
          };

          const fileProject = file?.getRel?.('project');
          const fileCytometer = file?.getRel?.('cytometer');
          const fileWorkflow = file?.getRel?.('workflow');
          const fileOrganization = file?.getRel?.('organization');

          if (fileOrganization && (fileOrganization.id !== organization?.id)) {
            result.push({
              text: `Shared from ${fileOrganization.name}`,
              link: `/${orgSlug}/file-explorer?orgID=${fileOrganization.id}`,
            });
          } else {
            if (fileProject) {
              result.push({
                text: fileProject.name,
                link: `/${orgSlug}/file-explorer?projectID=${fileProject.id}`,
              });
            }
            if (fileProject && !fileWorkflow) {
              const link = `/${orgSlug}/file-explorer?projectID=${fileProject.id}&direct=true`;
              result.push({
                text: 'Project Files',
                link,
              });
              addSubfolders(link);
            }
            if (fileProject && fileWorkflow && file.group_id) {
              const link = `/${orgSlug}/file-explorer?projectID=${fileProject.id}&workflowID=${fileWorkflow.id}&groupID=${file.group_id}`;
              result.push({
                text: fileWorkflow?.name,
                link,
              });
              addSubfolders(link);
            }
            if (fileCytometer) {
              const link = `/${orgSlug}/file-explorer?cytometerID=${fileCytometer.id}`;
              result.push({
                text: fileCytometer?.name,
                link,
              });
              addSubfolders(link);
            }
          }

          result.push({
            text: file?.filename,
            unformatted: true,
          });
        }
      }

      if (key === 'workflows' && resourceId && workflow) {
        const group = workflow?.getRel('group');
        const link = `/${orgSlug}/groups/${group?.id}/workflows/${workflow.id}/details`;
        result.push({
          text: workflow?.name,
          link,
        });
      }
      if (key === 'step' && stepId && procedure?.relationships?.steps?.data) {
        const idArray = procedure?.relationships?.steps?.data?.map((s) => s.id);
        const stepNumber = idArray?.indexOf(stepId) + 1;
        result.push({
          text: `Step ${stepNumber}`,
        });
      }
      if (key === 'cytometers' && currentCytometer && activeTab !== 'cytometers'){
        const link = `${orgSlug}/cytometers/${currentCytometer.id}/configuration`;
        result.push({
          text: `${currentCytometer.attributes.name || '...'}`,
          link,
        })
      }
      if (key === 'panels' && currentPanel && activeTab !== 'panels'){
        const link = `${orgSlug}/cytometers/${currentPanel.id}/details`;
        result.push({
          text: `${currentPanel.attributes.name}`,
          link
        })
      }
    });

    return result;
  }, [organization?.name, organization?.id, orgSlug, parsedLocation, workflow, stepId, procedure?.relationships?.steps?.data, currentCytometer, activeTab, tab, experiment, file]);

  /* eslint-disable react/no-array-index-key */
  const renderedItems = useMemo(() => {
    const result = [];
    items.forEach(({ text, link, testId, unformatted }, index) => {
      if (index > 0) {
        result.push(<Divider key={`${index}:divider`} />);
      }

      if (index === items.length - 1) {
        result.push(
          <Text key={`${index}:${link}`} unformatted={unformatted} data-testid={testId} bold>{text}</Text>
        );
      } else if (link) {
        result.push(
          <StyledLink key={`${index}:${link}`} unformatted={unformatted} data-testid={testId} to={link} title={text}>{text}</StyledLink>
        );
      } else {
        result.push(
          <Text key={`${index}:${link}`} unformatted={unformatted} data-testid={testId}>{text}</Text>
        );
      }
    });
    return result;
  }, [items]);
  /* eslint-enable react/no-array-index-key */

  return (
    <Container data-testid="breadcrumb">
      {renderedItems}
    </Container>
  );
};

export default BreadCrumb;

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 30px;
  left: ${globals.layout.mainContainerPadding};
`;

const Text = styled.h3`
  font-size: 13px;

  ${({ unformatted }) => !unformatted && css`
    text-transform: capitalize;
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  `}
`;

const StyledLink = styled(Link)`
  font-size: 13px;
  ${({ unformatted }) => !unformatted && css`
    text-transform: capitalize;
  `}
  text-decoration: underline;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

const Divider = styled.div`
  height: 5px;
  width: 5px;
  border-right: 1px solid ${globals.fonts.fontDark};
  border-bottom: 1px solid ${globals.fonts.fontDark};
  transform: rotate(-45deg);
  margin: 0px 7px;
`;
