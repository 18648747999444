import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setMessages } from '../slice';

function* getMessages({ payload: { link, channelUUID, params, query } }) {
  const { success, data, error } = yield makeRequest.link(link, params, query);
  if (success && data) {
    yield put(setMessages({ data, channelUUID }));
  }
  if (error) {
    yield* putErrorActions({ error, target: 'messages' });
  }
  return null;
}

export default getMessages;
