/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { callSecondaryModal, closeAndUnsetSecondaryModal, closeSecondaryModal, getSecondaryModalIsOpen, openSecondaryModal, setSecondaryModal } from 'rdx/modules/modals/slice';

export const useSecondaryModal = () => {
  const dispatch = useAppDispatch();
  const secondaryModalIsOpen = useAppSelector(getSecondaryModalIsOpen);

  const dispatchCallModal = useCallback((component) => dispatch(callSecondaryModal(component)), [dispatch]);
  const dispatchSetModal = useCallback((component) => dispatch(setSecondaryModal(component)), [dispatch]);
  const dispatchOpenModal = useCallback(() => dispatch(openSecondaryModal()), [dispatch]);
  const dispatchCloseModal = useCallback(() => dispatch(closeSecondaryModal()), [dispatch]);
  const dispatchCloseAndUnsetModal = useCallback(() => dispatch(closeAndUnsetSecondaryModal()), [dispatch]);

  return {
    callSecondaryModal: dispatchCallModal,
    setSecondaryModal: dispatchSetModal,
    openSecondaryModal: dispatchOpenModal,
    closeSecondaryModal: dispatchCloseModal,
    closeAndUnsetSecondaryModal: dispatchCloseAndUnsetModal,
    secondaryModalIsOpen,
  };
};
