import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setProcedures } from 'rdx/modules/procedures/slice';
import { setSteps } from 'rdx/modules/steps/slice';

function* getProcedures({ payload }) {
  const { action, link, query, onSuccess, onError } = payload;
  let success;
  let data;
  let error;
  if (link) {
    ({ success, data, error } = yield* makeRequest.link(link));
  }
  if (action) {
    const params = {
      ...payload.params,
      ...query,
    };
    ({ success, data, error } = yield* makeRequest.action(action, params));
  }
  if (success && data) {
    yield put(setProcedures(data));
    const steps = data.getRelArray?.('steps');
    if (steps) {
      yield put(setSteps(steps));
    }
    if (onSuccess) {
      onSuccess({ data });
    }
  } else {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default getProcedures;
