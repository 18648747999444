import { get, castArray, unionWith, flatMap } from 'lodash';
import Resource from 'lib/jsonApi/Resource';
import ResourceMeta from 'lib/jsonApi/ResourceMeta';
import { DefaultAttributes, Links, ResourceObject, ResourceObjects, UnwrappedResourceList } from 'types/json-api-types';
import { ResourceListParams } from 'types/api-helpers';

export default class ResourceList<Attributes = DefaultAttributes> extends Array {
  data: Resource<Attributes> | Resource<Attributes>[];

  links?: Links;

  meta: ResourceMeta;

  included: ResourceObjects;

  constructor(props: ResourceListParams<Attributes> = {}) {
    super();
    const { data = [], included = [], links = {}, meta = {} } = props;
    this.data = Array.isArray(data) ? data.map((d) => new Resource<Attributes>(d)) : new Resource<Attributes>(data);
    this.included = included.map((inc) => new Resource(inc));
    this.links = links;
    this.meta = new ResourceMeta(meta);
  }

  getRelArray = (type: string) => this.included
    .filter((item) => item.type === type)
    .map((resource: Resource | ResourceObject) => {
      if (resource.relationships) {
        const resources = flatMap(Object.values(resource.relationships), (rel) => rel.data);
        const included = this.included.filter((incRes) => resources.find((res) => res.id === incRes.id && res.type === incRes.type));
        return new Resource(resource, included);
      }
      return new Resource(resource);
    });

  unwrap = () => {
    if (!Array.isArray(this.data)) {
      let newResource: Resource<Attributes>;
      if (this.data.relationships) {
        const includedResources = flatMap(Object.values(this.data.relationships), (rel) => rel.data);
        const included = this.included.filter((incRes) => includedResources.find((res) => res.id === incRes.id && res.type === incRes.type));
        newResource = new Resource<Attributes>(this.data, included);
      } else {
        newResource = new Resource<Attributes>(this.data);
      }

      if (!newResource.getAction) {
        newResource.getAction = (name) => get(newResource, 'meta.getAction', () => undefined)(name);
      }

      newResource.meta = this.meta;
      newResource.links = this.links ?? {};
      newResource.included = this.included;

      return newResource;
    }

    const newResourceList: UnwrappedResourceList<Attributes> = this.data.map((resource) => {
      if (resource.relationships) {
        // console.log('resource.relationships :>> ', resource.relationships);
        const includedIds = flatMap(Object.values(resource.relationships), (rel) => rel.data);
        const included = this.included.filter((incRes) => {
          return includedIds.find((res) => {
            return res.id === incRes.id && res.type === incRes.type
          })
        });
        const includedWithIncluded = included.map((i) => {
          const includedIds = flatMap(Object.values(i.relationships ?? {}), (rel) => rel.data);
          const inc = this.included.filter((incRes) => {
            return includedIds.find((res) => {
              return res.id === incRes.id && res.type === incRes.type
            })
          });
          // eslint-disable-next-line no-param-reassign
          i.included = inc;
          return i;
        })
        return new Resource<Attributes>(resource, includedWithIncluded);
      }
      return new Resource<Attributes>(resource);
    });

    newResourceList.meta = this.meta;
    newResourceList.links = this.links;
    newResourceList.included = this.included;
    newResourceList.getAction = (name: string) => get(newResourceList, 'meta.getAction', () => null)(name);

    return newResourceList;
  };

  mergeWith = (newResList: ResourceList<Attributes> | Resource<Attributes>[], byID = false) => {
    let newResListData;
    let newResListIncluded;
    let newResListLinks;
    let newResListMeta;
    if (newResList instanceof ResourceList) {
      newResListData = newResList.data;
      newResListIncluded = newResList.included;
      newResListLinks = newResList.links;
      newResListMeta = newResList.meta;
    }
    const data = unionWith(
      castArray(this.data),
      castArray(newResListData),
      (a, b) => a.id === b.id && a.type === b.type,
    );
    if (byID) {
      data.sort((a, b) => a.id.localeCompare(b.id, 'en', { numeric: true }));
    }
    const included = unionWith(newResListIncluded, this.included, (a, b) => a.id === b.id && a.type === b.type);
    const links = { ...(this.links ?? {}), ...(newResListLinks ?? {}) };
    const meta = new ResourceMeta({ ...this.meta, ...newResListMeta });
    return new ResourceList<Attributes>({ ...this, data, included, links, meta });
  };

  withResource = (newResource: ResourceList<Attributes> | Resource<Attributes> | UnwrappedResourceList<Attributes>, newIncluded?: ResourceObjects, newMeta?: ResourceMeta, altCompare = ''): ResourceList<Attributes> => {
    const data = newResource instanceof ResourceList ? newResource.data : newResource;
    const included = newResource.included ?? newIncluded;
    const meta = newResource.meta ?? newMeta;

    const newResourceList = new ResourceList<Attributes>({ data, included: included ?? [], meta: meta ?? new ResourceMeta() });

    const without = this.withoutResource(newResource, altCompare);
    const merged = without.mergeWith(newResourceList, true);
    return merged;
  };

  withoutResource = (resource: Resource<Attributes> | ResourceList<Attributes> | UnwrappedResourceList<Attributes>, altCompare: string) => {
    if (Array.isArray(this.data) && resource instanceof Resource) {
      const data = this.data.filter((res) => (altCompare
        ? res[altCompare as keyof typeof res] !== resource[altCompare as keyof typeof resource]
        : String(res.id) !== String(resource.id)));
      return new ResourceList<Attributes>({ ...this, data });
    }
    return this;
  };

  includesRes = (resource: ResourceObject) => Array.isArray(this.data) && !!this.data.find((res) => res.id === resource.id);

  getAction = (name: string) => get(this, 'meta.getAction', () => null)(name);
}
