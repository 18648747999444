import { forwardRef } from 'react';
import PropTypes from 'prop-types';
// import customPropTypes from 'lib/customPropTypes';
// import styled from 'styled-components';
import globals from 'styles/globals';

import DeprecatedButton, { ButtonPropTypes } from '../index';

const HollowButton = ({ forwardedRef, baseStyle, hoverStyle, disabledStyle, ...buttonProps }) => {
  const hollowBaseStyle = {
    fontColor: globals.colors.primary,
    bgColor: 'transparent',
    borderColor: globals.colors.primary,
    ...baseStyle,
  };

  const hollowHoverStyle = {
    fontColor: globals.colors.primaryHover,
    borderColor: globals.colors.primaryHover,
    ...hoverStyle,
  };

  const hollowDisabledStyle = {
    fontColor: globals.colors.primaryDisabled,
    borderColor: globals.colors.primaryDisabled,
    ...disabledStyle,
  };

  const computedProps = {
    baseStyle: hollowBaseStyle,
    hoverStyle: hollowHoverStyle,
    disabledStyle: hollowDisabledStyle,
    spinnerInnerColor: globals.colors.primary,
    spinnerOuterColor: globals.colors.primary,
    ...buttonProps,
  };

  return <DeprecatedButton ref={forwardedRef} {...computedProps} />;
};

HollowButton.propTypes = {
  baseStyle: ButtonPropTypes.style,
  hoverStyle: ButtonPropTypes.style,
  disabledStyle: ButtonPropTypes.style,
  // eslint-disable-next-line react/forbid-prop-types
  forwardedRef: PropTypes.any,
};

HollowButton.defaultProps = {
  baseStyle: {},
  hoverStyle: {},
  disabledStyle: {},
  forwardedRef: undefined,
};

export default forwardRef((props, ref) => <HollowButton {...props} forwardedRef={ref} />);
