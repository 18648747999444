import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const CaretDown = forwardRef((props, ref) => (
  <svg width="21px" height="21px" viewBox="0 0 20 20" ref={ref} {...props}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" transform="translate(2.3, 1) scale(1.2)">
      <path d="m3.5 1.5c-.42139382 0-1.08806048 0-2 0-.55228475 0-1 .44771525-1 1v11c0 .5522848.44771525 1 1 1h10c.5522847 0 1-.4477152 1-1v-11c0-.55228475-.4477153-1-1-1-.8888889 0-1.55555556 0-2 0" />
      <path d="m4.5.5h4c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1h-4c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1z" />
      <path d="m5.5 5.5h5" />
      <path d="m5.5 8.5h5" />
      <path d="m5.5 11.5h5" />
      <path d="m2.5 5.5h1" />
      <path d="m2.5 8.5h1" />
      <path d="m2.5 11.5h1" />
    </g>
  </svg>
));

export default createIcon(CaretDown);
