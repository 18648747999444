import { useState } from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import Input from 'components/Form/Input/index';
import InfoReqButton from 'components/InfoRequirements/InfoReqButton';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { updateUser } from 'rdx/modules/users/slice';

const RequiredCode = ({ requirement, incrementStep, currentStep, totalSteps }) => {
  const dispatch = useAppDispatch();
  const [enteredCode, setEnteredCode] = useState('');

  const updateProfileAction = useAppSelector(getSessionAction('update_profile_sign_in_requirements'));

  let message = '';
  let instruction = '';

  if (requirement === 'postal_code') {
    message = 'Your postal code at work is required to determine available catalogues.';
    instruction = 'Enter Postal Code (Work)';
  }

  if (requirement === 'signup_code') {
    message = 'A signup code is required for this account\'s selected country.';
    instruction = 'Enter Signup Code';
  }

  const handleUpdateUser = () => {
    dispatch(updateUser({
      action: updateProfileAction,
      values: {
        [requirement]: enteredCode,
      },
      onSuccess: () => {
        setEnteredCode('');
        incrementStep();
      },
    }));
  };

  return (
    <>
      <Container>
        {message}
        <InputContainer>
          <div>{instruction}</div>
          <Input
            placeholder="Signup Code"
            value={enteredCode}
            onChange={(e) => setEnteredCode(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter') handleUpdateUser(); }}
          />
        </InputContainer>
      </Container>
      <InfoReqButton
        disabled={!enteredCode?.length || !updateProfileAction}
        onClick={() => handleUpdateUser()}
        currentStep={currentStep}
        totalSteps={totalSteps}
      />
    </>
  );
};

export default RequiredCode;

const Container = styled.div`
  height: 490px;
  max-width: 700px;
  overflow: auto;
  border: 1px solid lightgrey;
  padding: 25px;
`;

const InputContainer = styled.div`
  display: flex;
  margin: 30px;
  align-items: center;
  flex-direction: column;
`;
