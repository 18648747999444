import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setTrademarks } from 'rdx/modules/systemDocs/slice';
import { getSessionLinks } from 'rdx/modules/auth/slice';

function* getTrademarks() {
  const sessionLinks = yield select(getSessionLinks);
  const link = sessionLinks.trademarks;
  const { success, data, error } = yield* makeRequest.link(link);
  if (success && data) {
    yield put(setTrademarks(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getTrademarks;
