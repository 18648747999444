import { useEffect, useCallback, useState, useMemo } from 'react';
import Nes from '@hapi/nes/lib/client';

type Props = {
  token: string,
  subscriptions: Record<string, Nes.Client.Handler>
  webSocketUrl: string
}

export function useNesClient({ token = '', subscriptions = {}, webSocketUrl = '' }: Props) {
  const [nes, setNes] = useState<Nes.Client>();
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState<any>(null);
  const [willReconnect, setWillReconnect] = useState(false);

  const opts = useMemo(() => ({
    auth: { headers: { authorization: `Bearer ${token}` } },
    delay: 100,
    timeout: 5000,
  }), [token]);

  const start = useCallback(async () => {
    setConnecting(true);
    const client = nes ?? new Nes.Client(webSocketUrl.replace('http', 'ws'));
    try {
      // set callbacks, connect and then store the nesClient in state
      client.onDisconnect = (tryAgain) => {
        setConnected(false);
        setConnecting(tryAgain);
        setWillReconnect(tryAgain);
        if (!tryAgain) {
          setNes(undefined);
        }
      };
      client.onConnect = () => { setConnected(true); setConnecting(false); };
      client.onError = (err) => { setError(err); };
      setNes(client);
      await client.connect(opts);
    } catch (err) {
      setError(err);
    }
  }, [nes, opts, webSocketUrl]);

  useEffect(() => {
    if (webSocketUrl && token && !connected && !connecting && !willReconnect && !error) {
      void start();
    } else if (!token && nes) {
      try {
        nes.disconnect();
        setConnected(false);
        setConnecting(false);
        setWillReconnect(false);
        setNes(undefined);
      } catch (e) {
        setError(e);
      }
    }
  }, [connected, connecting, error, nes, opts, start, token, webSocketUrl, willReconnect]);

  // subscribe and update subscriptions on change
  useEffect(() => {
    const doSubscribe = async () => {
      if (nes && connected) {
        const subKeys = Object.keys(subscriptions);
        for (const key of subKeys) {
          if (key && typeof subscriptions[key] === 'function' && key !== 'undefined') {
            await nes.unsubscribe(key);
            await nes.subscribe(key, subscriptions[key]);
          }
        }
      }
    }

    void doSubscribe();
  }, [connected, nes, subscriptions]);

  // nes.request will reject with an error for any message returned with a statuscode >400
  // todo: we may want to log out the user if we get an auth expired rejection, but for now
  // it will just get logged so it doesn't throw all the way to airbrake and crash the app
  const emitMessage = useCallback(async (message: any) => {
    if (connected) {
      try {
        await nes?.request(message);
      } catch (e) {
        console.warn(e);
        setError(e);
      }
    }
  }, [connected, nes]);

  const socketId = useMemo(() => nes?.id || null, [nes?.id]);

  return { connected, socketId, emitMessage, error };
}

export default useNesClient;
