import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';

import { subtractFromStyleString } from 'lib/utils/styles/doMathOnStyleString';
import { humanizeString } from 'lib/utils/string';
import globals from 'styles/globals';

import DeprecatedPrimaryButton from 'components/DeprecatedButton/DeprecatedPrimaryButton/index';
import HollowButton from 'components/DeprecatedButton/HollowButton/index';
import { BtnSize } from 'components/DeprecatedButton/index';
import SwitchTable from 'components/Formik/SwitchTable';
import Dropdown from 'components/Dropdown/index';
import { updateUser } from 'rdx/modules/users/slice';

const { layout } = globals;

const NON_CONFORMING_FIELDS = [
  {
    field: 'non_owner_organization_user_left',
    key: 'organization',
  },
  {
    field: 'non_owner_group_user_left',
    key: 'group',
  },
];

const NotificationSettingsTab = ({ user, doSubmit, onCancel, action }) => {
  const dropdownItems = [
    { value: 'workflow', display: 'Workflow' },
    { value: 'group', display: 'Group' },
    { value: 'organization', display: 'Organization' },
    { value: 'chat', display: 'Chat' },
  ];
  const [activeSet, setActiveSet] = useState(dropdownItems[0].value);

  const getNotificationOptions = (prefix) => {
    const arr = [];
    if (action) {
      action.fields.forEach((field) => {
        if (field.name.includes(prefix)) {
          arr.push({ id: `${field.name}`, text: humanizeString(field.name) });
        }
      });
    }
    return arr;
  };

  // remove certain fields that arent meant to be options
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const notificationOptions = useMemo(() => getNotificationOptions(`${activeSet}`).filter((o) => o.id !== 'default_group_id'), [activeSet]);

  const getInitialValues = () => {
    const initVals = {
      organization: {},
      group: {},
      workflow: {},
      chat: {},
    };
    if (action) {
      action.fields.filter((field) => Object.keys(initVals).includes(field.name.split('_')[0])).forEach((field) => {
        const suffix = field.name.split('_')[0];
        if (typeof user.notification_settings[field.name] === 'boolean') {
          initVals[suffix][field.name] = user.notification_settings[field.name];
        } else {
          initVals[suffix][field.name] = field.value;
        }
      });

      const getInitValsForField = (fieldName, key) => {
        const field = action.fields.find((f) => f.name === fieldName);
        if (typeof user.notification_settings[field.name] === 'boolean') {
          initVals[key][field.name] = user.notification_settings[field.name];
        } else {
          initVals[key][field.name] = field.value;
        }
      };

      NON_CONFORMING_FIELDS.forEach(({ field, key }) => getInitValsForField(field, key));
    }
    return initVals;
  };

  const transformValues = (vals) => {
    const flattenedVals = {};
    Object.keys(vals).forEach((key) => {
      Object.keys(vals[key]).forEach((k) => {
        flattenedVals[k] = vals[key][k];
      });
    });
    return flattenedVals;
  };
  return (
    <SummaryContainer>
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize
        onSubmit={(values, actions) => {
          doSubmit(transformValues(values));
          setTimeout(() => {
            actions.setSubmitting(false);
            actions.resetForm();
          }, 500);
        }}
      >
        {(formikProps) => (
          <StyledForm name="user-profile">
            <FormRow>
              <Title>My Notifications</Title>
            </FormRow>
            <FormRow>
              <DropdownContainer>
                <Dropdown
                  options={dropdownItems}
                  value={activeSet}
                  onChange={(value) => setActiveSet(value)}
                />
              </DropdownContainer>
            </FormRow>
            <TableContainer>
              <Field name={`${activeSet}`}>
                {({ field: { value } }) => (
                  <SwitchTable
                    switches={notificationOptions}
                    values={value}
                    onChange={(newValues) => { formikProps.setFieldValue(`${activeSet}`, newValues); }}
                  />
                )}
              </Field>
            </TableContainer>
            <ButtonContainer>
              <HollowButton
                size={BtnSize.NARROW}
                text="Cancel"
                onClick={onCancel}
                baseStyle={{ marginRight: '10px' }}
              />
              <DeprecatedPrimaryButton
                size={BtnSize.NARROW}
                text="Update"
                type="submit"
                disabled={(formikProps.isSubmitting || !formikProps.isValid)}
                rdxTypes={[updateUser.type]}
              />
            </ButtonContainer>
          </StyledForm>
        )}
      </Formik>
    </SummaryContainer>
  );
};

export default NotificationSettingsTab;

const drawerMinus = subtractFromStyleString(layout.drawerPadding, 30);

const SummaryContainer = styled.div`
  flex: 1;
  padding: ${drawerMinus} ${layout.drawerPadding};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  flex: 1;  
`;

const FormRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled(FormRow)`
  width: 100%;
  justify-content: flex-end;
  margin: 30px 0;
`;

const DropdownContainer = styled.div`
  flex: 1;
  margin: 10px;
`;

const StyledForm = styled(Form)`
  height: 100%;
  position: relative;
`;
