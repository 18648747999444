import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { GenericActionPayload, PayloadActionWithGenericPayload } from 'types/redux-types';
import Resource from 'lib/jsonApi/Resource';

interface DuplicateWorkflowActionPayload extends PayloadActionWithGenericPayload {
  payload: GenericActionPayload & { group_id?: string | number }
}

function* duplicateWorkflow({ payload: { action, params, group_id, onSuccess } }: DuplicateWorkflowActionPayload) {
  const payload = { ...params, group_id };
  const { success, data, error } = yield makeRequest.action(action, payload);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Workflow Duplicated' }));

    const currentOrg: Resource = yield select(getCurrentOrganization);
    onSuccess?.({ routeTo: `/${currentOrg.attributes.slug}/groups/${group_id}/workflows/${data.data.id}/details` });
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default duplicateWorkflow;
