/* eslint-disable @typescript-eslint/no-explicit-any */
import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { insertStepReport, setStepReport } from 'rdx/modules/steps/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* requestGeneratePanel({ payload: { action, params, onSuccess, onError } }: PayloadActionWithGenericPayload) {
  const { error, success, data } = yield makeRequest.action(action, params);
  if (success && data) {
    const report = data.unwrap().withAttrs({ progress: 0, body: '' });
    yield put(insertStepReport(data));
    yield put(setStepReport({ channelID: report.channel_id, report }));
    if (onSuccess) {
      onSuccess();
    }
  }
  if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestGeneratePanel;
