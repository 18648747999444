import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { setCurrentCytometer, setCytometerSettings } from 'rdx/modules/cytometers/slice';

function* updateCytometerSettings({ payload }) {
  const { action, params } = payload;
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Settings Saved' }));
    yield put(setCurrentCytometer(data.unwrap()));
    const settings = data?.unwrap().cytometer?.getRel('cytometer_profiles')?.find((prof) => String(prof.id) === params.cytometerProfileID)?.attributes?.settings;
    yield put(setCytometerSettings(settings || []));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default updateCytometerSettings;
