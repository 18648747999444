import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';
import { closeAndUnsetDrawer, closeAndUnsetSecondaryDrawer, closeDrawer, closeSecondaryDrawer } from 'rdx/modules/drawers/slice';
import { closeAndUnsetModal, closeAndUnsetSecondaryModal, closeModal, closeSecondaryModal } from 'rdx/modules/modals/slice';

type FormSliceState = {
  primaryTouched: boolean,
  secondaryTouched: boolean,
  changed: boolean,
};

const initialState: FormSliceState = {
  primaryTouched: false,
  secondaryTouched: false,
  changed: false,
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    formTouched: (state, action: PayloadAction<boolean>) => {
      state.primaryTouched = action.payload;
    },
    formSecondaryTouched: (state, action: PayloadAction<boolean>) => {
      state.secondaryTouched = action.payload;
    },
    formChanged: (state, action: PayloadAction<boolean>) => {
      state.changed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
    builder.addCase(closeModal.type, () => initialState);
    builder.addCase(closeAndUnsetModal.type, () => initialState);
    builder.addCase(closeSecondaryModal.type, () => initialState);
    builder.addCase(closeAndUnsetSecondaryModal.type, () => initialState);
    builder.addCase(closeDrawer.type, () => initialState);
    builder.addCase(closeAndUnsetDrawer.type, () => initialState);
    builder.addCase(closeSecondaryDrawer.type, () => initialState);
    builder.addCase(closeAndUnsetSecondaryDrawer.type, () => initialState);
  },
});

const {
  formTouched,
  formSecondaryTouched,
  formChanged,
} = formSlice.actions;

// Actions
export {
  formTouched,
  formSecondaryTouched,
  formChanged,
};

// Selectors
export const getFormTouched = (state: RootState) => state.form.primaryTouched;
export const getFormSecondaryTouched = (state: RootState) => state.form.secondaryTouched;
export const getFormChanged = (state: RootState) => state.form.changed;

// Reducer
export default formSlice.reducer;
