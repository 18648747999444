import styled, { keyframes, css } from 'styled-components';
import globals from 'styles/globals';
import arcPath from 'lib/utils/svg/arcPath';

const VIEWBOX_SIZE = 500;
const STROKE_WIDTH = 13;
const ARC_LENGTH = 120;
// const R_PADDING = 30;

const CENTER = VIEWBOX_SIZE / 2;
const INNER_SIZE = VIEWBOX_SIZE - STROKE_WIDTH;
const R1 = INNER_SIZE / 2;
// const R2 = R1 - STROKE_WIDTH - R_PADDING;

type Props = {
  size?: string | number,
  spinnerOuterColor?: string,
  text?: string,
};

const LoadingCircle = ({ size = '70px', spinnerOuterColor, text }: Props) => (
  <SpinnerContainer>
    <svg height={size} width={size} viewBox={`0 0 ${VIEWBOX_SIZE} ${VIEWBOX_SIZE}`}>
      <g strokeWidth={STROKE_WIDTH} strokeLinecap="round" fill="none">
        <Spin stroke={spinnerOuterColor || globals.colors.orange}>
          <path d={arcPath(CENTER, CENTER, R1, ARC_LENGTH, 0)} />
          {/* <path d={arcPath(CENTER, CENTER, R1, ARC_LENGTH, 180)} /> */}
        </Spin>
        <SpinReverse stroke={spinnerOuterColor || globals.colors.orange}>
          {/* <path d={arcPath(CENTER, CENTER, R2, 130, 0)} /> */}
          {/* <path d={arcPath(CENTER, CENTER, R2, 130, 180)} /> */}
        </SpinReverse>
      </g>
    </svg>
    {text && (
      <p>{text}</p>
    )}
  </SpinnerContainer>
);

export default LoadingCircle;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const rotateCSS = css`
  animation: ${rotate} 900ms linear infinite;
  transform-origin: 50% 50%;
`;

const Spin = styled.g`
  ${rotateCSS}
`;

const SpinReverse = styled.g`
  ${rotateCSS}
  animation-direction: reverse;
`;
