import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const Cell = forwardRef((props, ref) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="720.000000pt"
    height="720.000000pt"
    viewBox="0 0 720.000000 720.000000"
    preserveAspectRatio="xMidYMid meet"
    ref={ref}
    {...props}
  >
    <g
      transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path d="M3350 7114 c-19 -2 -87 -9 -150 -15 -288 -27 -638 -113 -931 -229
-818 -325 -1494 -959 -1868 -1752 -166 -352 -267 -698 -318 -1093 -25 -196
-25 -624 1 -820 105 -815 442 -1512 1006 -2075 561 -562 1262 -902 2075 -1007
196 -25 624 -25 820 0 711 92 1365 381 1885 836 369 322 665 712 866 1137 170
360 276 733 325 1139 18 144 18 605 1 745 -76 615 -266 1130 -599 1617 -454
666 -1138 1160 -1918 1384 -194 56 -338 86 -560 115 -105 14 -561 27 -635 18z
m620 -179 c615 -78 1135 -290 1638 -666 147 -111 448 -400 559 -539 365 -456
610 -996 702 -1545 38 -225 46 -327 46 -575 -1 -270 -15 -419 -66 -671 -105
-525 -335 -1013 -682 -1446 -82 -104 -300 -326 -412 -423 -516 -443 -1150
-715 -1840 -792 -154 -17 -536 -17 -690 0 -649 72 -1235 313 -1755 720 -151
119 -440 415 -559 574 -373 499 -591 1037 -668 1648 -21 169 -24 575 -5 740
71 613 286 1164 643 1648 208 280 493 557 779 754 505 348 1032 531 1700 592
96 9 491 -3 610 -19z"
      />
      <path d="M3380 6664 c-19 -2 -84 -9 -145 -15 -500 -50 -1028 -250 -1435 -542
-999 -717 -1469 -1914 -1224 -3117 89 -435 298 -890 568 -1235 305 -391 629
-656 1061 -870 335 -166 607 -249 985 -301 174 -25 576 -25 750 -1 557 78
1028 268 1453 589 675 508 1090 1224 1204 2075 23 177 23 569 0 746 -72 536
-267 1021 -579 1438 -198 265 -372 439 -637 637 -416 311 -909 510 -1432 577
-117 15 -494 28 -569 19z m561 -178 c595 -83 1111 -323 1572 -731 246 -218
499 -556 652 -871 143 -292 229 -584 277 -934 16 -124 16 -536 0 -660 -60
-438 -184 -806 -386 -1144 -150 -250 -337 -484 -520 -651 -466 -424 -1011
-675 -1641 -756 -149 -20 -541 -17 -695 5 -419 58 -790 188 -1140 401 -611
370 -1077 978 -1274 1663 -80 277 -110 495 -110 802 0 247 9 348 50 562 134
696 533 1333 1099 1753 263 196 488 316 785 419 249 87 448 129 765 160 82 8
464 -4 566 -18z"
      />
      <path d="M2154 5926 c-38 -38 -44 -77 -19 -126 19 -37 43 -50 95 -50 108 0
140 149 43 199 -42 21 -83 13 -119 -23z"
      />
      <path d="M4762 5774 c-27 -19 -29 -64 -4 -87 58 -53 137 28 82 83 -24 24 -48
25 -78 4z"
      />
      <path d="M3526 5741 c-25 -28 -17 -72 17 -91 24 -13 30 -13 55 0 53 30 33 110
-28 110 -15 0 -35 -9 -44 -19z"
      />
      <path d="M3862 5654 c-27 -19 -52 -65 -52 -96 0 -13 9 -37 20 -55 42 -70 148
-64 184 11 22 46 14 85 -24 126 -22 23 -37 30 -67 30 -21 0 -49 -7 -61 -16z"
      />
      <path d="M3053 5405 c-63 -27 -82 -111 -37 -164 65 -77 189 -31 186 69 -1 43
-27 79 -67 96 -41 17 -42 17 -82 -1z"
      />
      <path d="M1525 5215 c-30 -29 -31 -45 -4 -79 25 -31 61 -34 89 -6 28 28 25 64
-6 89 -34 27 -50 26 -79 -4z"
      />
      <path d="M2525 5217 c-20 -32 -10 -75 21 -89 55 -25 107 26 82 80 -19 39 -80
44 -103 9z"
      />
      <path d="M5578 5204 c-31 -17 -58 -61 -58 -97 0 -13 10 -39 23 -58 43 -63 131
-63 174 0 27 40 29 71 8 111 -28 54 -94 74 -147 44z"
      />
      <path d="M4987 5162 c-21 -23 -22 -51 -1 -80 31 -45 104 -17 104 40 0 51 -69
78 -103 40z"
      />
      <path d="M4162 5098 c-7 -7 -12 -27 -12 -45 0 -43 38 -71 78 -57 63 22 46 114
-21 114 -18 0 -38 -5 -45 -12z"
      />
      <path d="M1984 4936 c-38 -19 -122 -60 -188 -91 -121 -57 -261 -140 -307 -182
-14 -13 -29 -23 -33 -23 -4 0 -66 -31 -137 -70 -138 -75 -286 -171 -334 -216
-16 -15 -50 -43 -75 -62 -60 -46 -69 -60 -69 -107 -1 -42 19 -75 56 -94 53
-27 115 -3 215 84 75 66 313 219 327 210 20 -12 181 -325 181 -351 0 -9 -25
-43 -55 -76 -159 -176 -286 -439 -332 -688 -25 -140 -23 -382 6 -517 42 -199
131 -402 247 -563 76 -106 218 -250 329 -333 180 -135 475 -260 715 -303 117
-21 400 -24 515 -6 104 17 274 64 372 103 174 69 382 197 494 306 l37 35 28
-39 c16 -21 33 -41 38 -45 6 -4 58 -51 118 -105 l108 -98 -68 -65 c-37 -36
-88 -88 -114 -115 -25 -28 -65 -66 -87 -86 -35 -31 -41 -41 -41 -76 0 -50 27
-87 76 -103 61 -20 114 16 324 221 101 100 196 197 211 215 14 18 46 49 70 69
214 172 239 198 239 255 0 65 -45 110 -108 110 -36 -1 -61 -16 -119 -71 -26
-25 -68 -60 -93 -79 -25 -19 -61 -50 -81 -68 -55 -51 -73 -43 -227 108 l-133
129 56 83 c270 398 319 907 130 1347 -183 428 -574 753 -1061 881 -291 76
-648 62 -944 -37 -127 -42 -289 -121 -393 -192 l-78 -52 -23 53 c-14 29 -49
98 -80 153 -31 55 -56 105 -56 110 0 18 123 93 257 156 272 128 303 150 303
210 0 106 -92 137 -216 75z"
      />
      <path d="M3299 4911 c-23 -24 -29 -38 -29 -73 1 -100 106 -144 176 -74 38 38
44 77 19 126 -19 37 -43 50 -95 50 -33 0 -48 -6 -71 -29z"
      />
      <path d="M4256 4669 c-34 -27 -32 -64 5 -92 24 -17 30 -18 55 -6 40 19 47 79
12 103 -30 21 -40 20 -72 -5z"
      />
      <path d="M5670 4493 c-76 -24 -88 -47 -101 -195 -5 -68 -17 -137 -25 -155 -8
-17 -14 -35 -14 -41 0 -16 -57 -83 -174 -202 -172 -176 -198 -212 -240 -323
-41 -110 -70 -275 -63 -353 3 -28 20 -88 38 -135 30 -78 40 -92 134 -185 133
-133 142 -138 269 -132 176 9 301 80 339 193 21 60 21 69 1 110 -19 40 -67 66
-109 58 -37 -7 -81 -46 -90 -80 -8 -32 -75 -62 -140 -63 -49 0 -52 2 -116 68
-43 43 -74 86 -88 121 -22 52 -22 59 -11 149 7 56 24 128 43 176 29 77 37 87
182 236 232 238 259 287 282 512 13 134 13 150 -2 181 -20 43 -77 73 -115 60z"
      />
      <path d="M4950 4301 c-36 -36 -46 -73 -30 -112 31 -73 114 -92 172 -40 28 25
33 36 33 76 0 40 -5 51 -33 76 -45 40 -102 41 -142 0z"
      />
      <path d="M6160 3779 c-65 -26 -91 -83 -66 -143 32 -77 136 -87 183 -17 27 40
29 61 8 106 -23 47 -80 72 -125 54z"
      />
      <path d="M934 3205 c-25 -26 -26 -52 -2 -82 18 -22 75 -16 92 9 22 31 20 45
-9 73 -31 32 -50 31 -81 0z"
      />
      <path d="M4623 2799 c-44 -13 -73 -53 -73 -101 0 -32 7 -47 34 -74 49 -49 114
-45 158 10 22 27 23 94 2 125 -13 19 -74 54 -86 50 -2 0 -17 -5 -35 -10z"
      />
      <path d="M5357 2302 c-10 -10 -17 -28 -17 -40 0 -27 33 -62 58 -62 31 0 62 29
62 59 0 54 -68 82 -103 43z"
      />
      <path d="M1467 1902 c-52 -57 26 -139 86 -91 33 25 34 58 4 87 -28 27 -68 28
-90 4z"
      />
      <path d="M5058 1649 c-23 -13 -24 -72 -2 -94 25 -25 75 -16 94 18 13 24 13 30
0 55 -17 30 -61 40 -92 21z"
      />
      <path d="M2283 1515 c-32 -23 -63 -115 -63 -188 0 -89 27 -131 110 -174 l65
-34 215 7 215 7 60 -31 c76 -38 131 -95 139 -144 7 -37 36 -68 64 -68 7 0 24
11 38 25 22 23 24 30 18 73 -3 26 -12 58 -20 72 -23 45 -107 117 -184 156
l-75 39 -231 -3 -231 -3 -32 26 c-37 32 -40 69 -11 134 11 24 20 51 20 60 0
45 -59 73 -97 46z"
      />
      <path d="M4358 1211 c-28 -25 -33 -36 -33 -76 0 -40 5 -51 33 -76 58 -52 141
-33 172 40 16 39 6 76 -30 112 -40 41 -97 40 -142 0z"
      />
      <path d="M3503 1180 c-104 -63 -28 -226 89 -191 42 13 78 58 78 99 0 56 -56
112 -112 112 -13 0 -37 -9 -55 -20z"
      />
    </g>
  </svg>
));

export default createIcon(Cell);
