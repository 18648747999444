/* eslint-disable import/prefer-default-export */
import { useModal } from 'hooks/useModal';
import { useSelector } from 'react-redux';
import { useSecondaryDrawer } from 'hooks/useSecondaryDrawer';

import ConfirmModal from 'containers/Modals/ConfirmModal';
import { getFormChanged, getFormSecondaryTouched, getFormTouched } from 'rdx/modules/form/slice';
import { useAppSelector } from 'hooks/reduxHooks';
import { getIsMobile } from 'rdx/modules/app/slice';

export function useVerifyClose() {
  const isMobile = useAppSelector(getIsMobile);
  const { callModal } = useModal();
  const { secondaryDrawerIsOpen } = useSecondaryDrawer();
  const formTouched = useSelector(getFormTouched);
  const formChanged = useSelector(getFormChanged);
  const formSecondaryTouched = useSelector(getFormSecondaryTouched);
  const verifyClose = (closeFunction, isSecondary = false) => {
    const touched = secondaryDrawerIsOpen || isSecondary ? formSecondaryTouched : formTouched;
    const changed = formChanged;
    const confirm = () => {
      closeFunction();
    };
    if (touched && changed) {
      const kName = 'verify-close-modal';
      callModal(
        <ConfirmModal
          key={kName}
          message="Form has unsaved changes, are you sure you want to close?"
          onConfirm={confirm}
          height={isMobile ? '220px' : '185px'}
          yesNo
        />
      );
    } else {
      closeFunction();
    }
  };
  return {
    verifyClose,
  };
}
