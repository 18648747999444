/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePrevious } from 'hooks/usePrevious';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { closeAndUnsetDrawer, closeAndUnsetSecondaryDrawer, getDrawerIsOpen, getDrawerUrlInfo } from 'rdx/modules/drawers/slice';

export function useAutoOpenCloseDrawers() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const drawerIsOpen = useAppSelector(getDrawerIsOpen);
  const drawerUrlInfo = useAppSelector(getDrawerUrlInfo);

  const drawerPrevOpen = usePrevious(drawerIsOpen);
  const prevUrlInfo = usePrevious(drawerUrlInfo);

  useEffect(() => {
    const isOpening = !drawerPrevOpen && drawerIsOpen;
    const isClosing = drawerPrevOpen && !drawerIsOpen;
    if (isOpening) {
      if (drawerUrlInfo) {
        const { id, data } = drawerUrlInfo;
        const url = new URL(window.location.href);
        const currentDrawerId = url.searchParams.get('drawer');
        if (!currentDrawerId && id) {
          url.searchParams.set('drawer', id);
          Object.entries((data || {})).forEach(([key, value]) => {
            url.searchParams.set(key, value);
          });
          history.push(`${url.pathname}${url.search}${url.hash}`);
        }
      }
    } else if (isClosing) {
      if (prevUrlInfo) {
        const prevData = prevUrlInfo.data;
        const url = new URL(window.location.href);
        const currentDrawerId = url.searchParams.get('drawer');
        if (currentDrawerId) {
          url.searchParams.delete('drawer');
          url.searchParams.delete('drawer-tab');
          Object.keys((prevData || {})).forEach((key) => {
            url.searchParams.delete(key);
          });
          history.push(`${url.pathname}${url.search}${url.hash}`);
        }
      }
    }
  }, [location, history, drawerPrevOpen, drawerIsOpen, drawerUrlInfo, prevUrlInfo]);

  const [locationKeys, setLocationKeys] = useState([]);

  const closeDrawersOnBrowserNav = () => {
    const url = new URL(window.location.href);
    const drawerParam = url.searchParams.get('drawer');

    if (!drawerParam) {
      dispatch(closeAndUnsetDrawer());
    }
    dispatch(closeAndUnsetSecondaryDrawer());
  };

  // Credit to Nicolas Keller: https://stackoverflow.com/a/60125216
  useEffect(() => history.listen((loc) => {
    if (history.action === 'PUSH') {
      setLocationKeys([loc.key]);
    }

    if (history.action === 'POP') {
      if (locationKeys[1] === loc.key) {
        // eslint-disable-next-line no-unused-vars
        setLocationKeys(([_, ...keys]) => keys);

        // Handle forward event
        closeDrawersOnBrowserNav();
      } else {
        setLocationKeys((keys) => [loc.key, ...keys]);

        // Handle back event
        closeDrawersOnBrowserNav();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [locationKeys]);
}
