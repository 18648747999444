import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import globals from 'styles/globals';
import BaseTable from 'components/ZebraTable/baseTable';

type Row = {
  component?: ReactNode,
  left?: ReactNode,
  right?: ReactNode,
  key?: string,
  backgroundColor?: string,
}

type Props = {
  rows: Row[],
  width?: string,
  header?: ReactNode,
  multiHeader?: Record<string, [string, string | undefined]>,
  tableName?: string,
  narrow?: boolean,
  panel?: boolean,
}

const ZebraTable = ({ rows = [], width = '100%', header, multiHeader, tableName = 'default-table-name', narrow, panel }: Props) => {
  const renderRows = () => rows.map(({ component, left, right, key, backgroundColor }, i) => (
    <TableItem data-test={tableName} key={key ?? i} backgroundColor={backgroundColor} narrow={narrow} panel={panel}>{component ?? (<>{left}{right}</>)}</TableItem>
  ));

  return (
    <BaseTable {...{ renderRows, width, header, multiHeader }} />
  );
};

export default ZebraTable;

const TableItem = styled.div<{ backgroundColor?: string, panel?: boolean, narrow?: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ backgroundColor }) => backgroundColor ?? globals.colors.white};

  ${({ panel, narrow }) => (panel
    ? css`
      padding: ${narrow ? '10px 20px' : '20px'};
      margin-bottom: 5px;
    `
    : css`
      align-items: center;
      padding: 0 15px;
      height: 45px;

      &:nth-child(2n) {
        background-color: ${globals.colors.mediumGrey};
      }
    `
  )}
`;
