import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { GenericActionPayload } from 'types/redux-types';
import { SpectrumViewerData } from 'types/spectrum-data';

export type SimilarityMatrix = Record<string, Record<string, number>>;
export type NormalizedSpectralMatrix = Record<string, Record<string, number>>;
export type HotspotMatrix = {
  hotspot: {
    fluorochromes: string[],
    matrix: ((number | null)[])[]
  }
};

type SpectrumViewerState = {
  spectrumViewerData: SpectrumViewerData | null,
  similarityMatrix: SimilarityMatrix | null,
  normalizedSpectralMatrix: NormalizedSpectralMatrix | null,
  spectrumViewerImagingData: SpectrumViewerData | null,
  hotspotMatrix: HotspotMatrix | null,
};

const initialState: SpectrumViewerState = {
  spectrumViewerData: null,
  similarityMatrix: null,
  normalizedSpectralMatrix: null,
  spectrumViewerImagingData: null,
  hotspotMatrix: null,
};

const requestSpectrumViewerData = createAction<GenericActionPayload>('requestSpectrumViewerData');
const requestSpectrumViewerImagingData = createAction<GenericActionPayload>('requestSpectrumViewerImagingData');
const requestGeneratePanel = createAction<GenericActionPayload>('requestGeneratePanel');
const requestPanelsCsvReport = createAction('requestPanelsCsvReport');
const requestPanelsJsonReport = createAction('requestPanelsJsonReport');
const requestPanelAssistance = createAction('requestPanelAssistance');
const requestCytometerMeta = createAction<GenericActionPayload>('requestCytometerMeta');
const requestHotspotData = createAction<GenericActionPayload<undefined, { fluorochromes:(string|null)[]}>>('requestHotspotData');

export const spectrumViewerSlice = createSlice({
  name: 'spectrumViewer',
  initialState,
  reducers: {
    setSpectrumViewerData: (state, action: PayloadAction<ResourceList | null>) => {
      if (action.payload === null) {
        state.spectrumViewerData = null;
        return;
      }
      if ('attributes' in action.payload.data && action.payload.data.attributes) {
        state.spectrumViewerData = action.payload.data.attributes;
      }
    },
    setSpectrumViewerImagingData: (state, action: PayloadAction<ResourceList | null>) => {
      if (action.payload === null) {
        state.spectrumViewerImagingData = null;
        return;
      }
      if ('attributes' in action.payload.data && action.payload.data.attributes) {
        state.spectrumViewerImagingData = action.payload.data.attributes;
      }
    },
    setHotspotMatrix: (state, action: PayloadAction<ResourceList | null>) => {
      if (action.payload === null) {
        state.hotspotMatrix= null;
        return;
      }
      if ('attributes' in action.payload.data && action.payload.data.attributes) {
        state.hotspotMatrix = action.payload.data.attributes as HotspotMatrix;
      }
    },
    setCytometerMeta: (state, action: PayloadAction<ResourceList | null>) => {
      if (action.payload === null) {
        state.similarityMatrix = null;
        state.normalizedSpectralMatrix = null;
        return;
      }
      if ('attributes' in action.payload.data && action.payload.data.attributes) {
        if ('similarity_matrix_measured' in action.payload.data.attributes && action.payload.data.attributes.similarity_matrix_measured) {
          state.similarityMatrix = action.payload.data.attributes.similarity_matrix_measured as SimilarityMatrix;
        } else {
          state.similarityMatrix = null;
        }
        if ('normalized_spectral_matrix_measured' in action.payload.data.attributes && action.payload.data.attributes.normalized_spectral_matrix_measured) {
          state.normalizedSpectralMatrix = action.payload.data.attributes.normalized_spectral_matrix_measured as NormalizedSpectralMatrix;
        } else {
          state.normalizedSpectralMatrix = null;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setSpectrumViewerData,
  setCytometerMeta,
  setSpectrumViewerImagingData,
  setHotspotMatrix,
} = spectrumViewerSlice.actions;

// Actions
export {
  setSpectrumViewerData,
  setCytometerMeta,
  setSpectrumViewerImagingData,
  setHotspotMatrix,
  requestSpectrumViewerData,
  requestSpectrumViewerImagingData,
  requestGeneratePanel,
  requestPanelsCsvReport,
  requestPanelsJsonReport,
  requestPanelAssistance,
  requestCytometerMeta,
  requestHotspotData,
};

// Selectors
export const getSpectrumViewerData = (state: RootState) => state.spectrumViewer.spectrumViewerData;
export const getSpectrumViewerImagingData = (state: RootState) => state.spectrumViewer.spectrumViewerImagingData;
export const getSimilarityMatrix = (state: RootState) => state.spectrumViewer.similarityMatrix;
export const getNormalizedSpectralMatrix = (state: RootState) => state.spectrumViewer.normalizedSpectralMatrix;
export const getHotspotMatrix = (state: RootState) => state.spectrumViewer.hotspotMatrix;

// Reducer
export default spectrumViewerSlice.reducer;
