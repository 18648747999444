import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestOrganizationLabs } from 'rdx/modules/organization/slice';

function* deleteLab({ payload }) {
  const { success, data, error } = yield* makeRequest.action(payload.action);
  if (success && data) {
    yield put(requestOrganizationLabs());
    yield put(newMessageEvent({ text: 'Lab Deleted' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteLab;
