import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setMoreProjects } from 'rdx/modules/projects/slice';
import { getSessionLinks } from 'rdx/modules/auth/slice';

function* requestMoreProjects({ payload }) {
  const { link, query } = payload;
  const { organization_projects } = yield select(getSessionLinks);
  if (link || organization_projects) {
    const { success, data, error } = yield* makeRequest.link(link || organization_projects, {}, query);
    if (success && data) {
      yield put(setMoreProjects(data));
    } else {
      yield* putErrorActions({ error });
    }
  }
  return null;
}

export default requestMoreProjects;
