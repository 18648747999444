import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { SearchIcon, XIcon } from 'components/Icons';
import { InputContainer, InputElement, IconWrapperLeading, IconWrapperTrailing } from './components';
import globals from 'styles/globals';

export type FormikInputProps = {
  rounded?: boolean,
  width?: string,
  searchIcon?: boolean,
  disabled?: boolean,
  className?: string,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  backgroundColor?: string,
  borderless?: boolean,
  borderColor?: string,
  maxLength?: number,
  clearIcon?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | { target: { value: string }}) => void,
} & Omit<ComponentPropsWithoutRef<'input'>, 'onChange'>

const FormikInput = forwardRef<HTMLInputElement, FormikInputProps>((props, ref) => {
  const {
    rounded,
    width = '100%',
    searchIcon,
    disabled,
    className,
    onKeyDown,
    backgroundColor,
    borderless,
    value,
    borderColor = globals.colors.darkerGrey,
    maxLength,
    clearIcon,
    ...inputProps
  } = props;

  return (
    <InputContainer {...{ width, className }}>
      <InputElement {...{ rounded, searchIcon, disabled, borderColor, borderless, backgroundColor, value }}>
        <input
          {...inputProps}
          {...{ value, disabled }}
          style={{ width }}
          onKeyDown={(e) => onKeyDown?.(e)}
          ref={ref}
          maxLength={maxLength}
        />
      </InputElement>
      {searchIcon && (
        <IconWrapperLeading {...{ rounded }}>
          <SearchIcon />
        </IconWrapperLeading>
      )}
      {clearIcon && (
        <IconWrapperTrailing {...{ rounded }}>
          <XIcon size={10} onClick={(e: Event) => { e.stopPropagation(); inputProps.onChange?.({ target: { value: '' } }); }} />
        </IconWrapperTrailing>
      )}
    </InputContainer>
  );
});

FormikInput.displayName = 'FormikInput';

export default FormikInput;
