import React from 'react';

export default function newlinesToHtml(str: string | React.ReactNode) {
  if (typeof str !== 'string') {
    return str;
  }
  const lines = str.split('\n');
  const html = [];
  for (let i = 0; i < lines.length; i++) {
    html.push(lines[i]);
    if (i < lines.length - 1) {
      html.push(<br />);
    }
  }
  return html;
}
