import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { callSecondaryDrawer, closeSecondaryDrawer, getSecondaryDrawerIsOpen, openSecondaryDrawer, setSecondaryDrawer } from 'rdx/modules/drawers/slice';

export const useSecondaryDrawer = () => {
  const dispatch = useAppDispatch();
  const drawerIsOpen = useAppSelector(getSecondaryDrawerIsOpen);

  const dispatchCallDrawer = useCallback((component, urlInfo) => dispatch(callSecondaryDrawer({ component, urlInfo })), [dispatch]);
  const dispatchSetDrawer = useCallback((component, urlInfo) => dispatch(setSecondaryDrawer({ component, urlInfo })), [dispatch]);
  const dispatchOpenDrawer = useCallback(() => dispatch(openSecondaryDrawer()), [dispatch]);
  const dispatchCloseDrawer = useCallback(() => dispatch(closeSecondaryDrawer()), [dispatch]);

  return {
    callSecondaryDrawer: dispatchCallDrawer,
    setSecondaryDrawer: dispatchSetDrawer,
    openSecondaryDrawer: dispatchOpenDrawer,
    closeSecondaryDrawer: dispatchCloseDrawer,
    secondaryDrawerIsOpen: drawerIsOpen,
  };
};

export const useSecondaryDrawerDirectory = (dir) => {
  const directory = useMemo(() => (dir ? { ...dir } : {}), [dir]);
  const { setSecondaryDrawer: setDirectoryDrawer, openSecondaryDrawer: openDirectoryDrawer } = useSecondaryDrawer();

  const callSecondaryDirectoryDrawer = (id, keyList = {}) => {
    const drawer = directory[id];

    const data = drawer.mapData ? drawer.mapData(keyList) : {};
    if (data) {
      const component = drawer.render(data, drawer.dependencies);
      const urlInfo = {
        id,
        data: keyList,
      };
      setDirectoryDrawer(component, urlInfo);
      openDirectoryDrawer();
    }
  };

  // Return function to set the active drawer by id
  return callSecondaryDirectoryDrawer;
};
