import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const Paste = forwardRef((props, ref) => (
  <svg ref={ref} {...props} viewBox="0 0 16 16">
    <path d="M13 4h-3v-4h-10v14h6v2h10v-9l-3-3zM3 1h4v1h-4v-1zM15 15h-8v-10h5v3h3v7zM13 7v-2l2 2h-2z" />
  </svg>
));

export default createIcon(Paste);
