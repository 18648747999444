import { put, select } from 'redux-saga/effects';

import Action from 'lib/jsonApi/Action';
import ResourceList from 'lib/jsonApi/ResourceList';
import Resource from 'lib/jsonApi/Resource';
import { parseApiURL } from 'rdx/api-utils/jsonApiRequest';
import { getAuthToken, setAuthToken } from 'lib/utils/authHelpers';
import { getFieldOptions } from 'lib/jsonApi/FieldOptions';
import { getApiVersion, setApiVersion } from 'rdx/modules/auth/slice';
import { FormattedError, FormattedResponse, JsonApiError, Link, ResourceObject } from 'types/json-api-types';
import { ResourceListParams } from 'types/api-helpers';

const API_URL = parseApiURL() ?? import.meta.env.VITE_API_ENDPOINT;
const devBuild = import.meta.env.DEV;

export const CONNECTION_ERROR = 'CONN_ERR';
const CONNECTION_ERROR_MESSAGE = 'There was an error connecting with the server';
const CONTENT_TYPES = {
  JSON: 'application/json',
  CSV: 'text/csv',
};

function searchParamIsTruthy(value: unknown) {
  if (Array.isArray(value)) {
    return !!value.length;
  }
  const val = value ?? null;
  return val !== null;
}

function getHeaders(): HeadersInit {
  const baseHeaders: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/vnd.api+json',
  };
  const authToken = getAuthToken();
  if (authToken) return { ...baseHeaders, Authorization: authToken };
  return baseHeaders;
}

const cleanParams = (params: Record<string, string>, action: Action) =>
  Object.entries(params).reduce((cleanedInput, [key, value]) => {
    const field = action.field(key);
    const nullValue = value === undefined || value === null;
    if (field && !field.nulls && nullValue) {
      console.warn(
        `Parameter ${key} not included in action request ${
          action.readableName
        } null values are not allowed for ${field.name}`,
      );
      return cleanedInput;
    }
    return { ...cleanedInput, [key]: value };
  }, {});

const invalidOptions = ({ options, value }: { options: ReturnType<typeof getFieldOptions>, value: unknown }) => {
  if (Array.isArray(value) && Array.isArray(options)) {
    const validValues = options.map((o) => {
      if (typeof o !== 'string') {
        return o.value;
      }
      return o;
    });
    return !value.map((o) => (typeof value === 'object' ? o.value : o)).reduce((prev: boolean, cur) => {
      if (!prev) {
        return prev;
      }
      return validValues.includes(cur);
    }, true);
  }

  if (typeof value === 'string' && Array.isArray(options)) {
    return !options.map((o) => {
      if (typeof o !== 'string') {
        return o.value;
      }
      return o;
    }).includes(value);
  }

  return false;
};

const validateFields = (action: Action) => {
  action.fields.forEach((field) => {
    const { value, required, hasOptions, options, name } = field;
    if (required && !value) {
      console.warn(
        `Missing required value "${value}" on field "${name}" of action:\n${action.readableName}`,
      );
    }
    if (
      value
        && hasOptions
        && invalidOptions({ options, value })
    ) {
      console.warn(
        `Invalid value "${value}" on field "${name}" of action:\n${
          action.readableName
        }\nValid options are ${JSON.stringify(options)}`,
      );
    }
  });
};

export const formatJsonApiResponse = (response: JsonApiError | ResourceObject | ResourceListParams): FormattedResponse => {
  if (typeof response === 'boolean') {
    return { success: true, error: null, data: response };
  }
  if ('error' in response) {
    const error: FormattedError = {
      text: response.message,
      statusCode: response.statusCode,
      warning: response.warning,
      error: response.error,
      link: response.link,
      meta: response.meta,
    };
    return { success: false, error, data: null };
  }
  if ('data' in response) {
    return { success: true, error: null, data: new ResourceList(response) };
  }
  if ('attributes' in response) {
    return { success: true, error: null, data: new Resource(response) };
  }
  return { success: true, error: null, data: response };
};

export const formatCsvApiResponse = (response: JsonApiError | string): FormattedResponse => {
  if (typeof response === 'string') {
    return { success: true, error: null, data: response };
  }
  const error = {
    text: response.message,
    statusCode: response.statusCode,
    warning: response.warning,
    error: response.error,
  };
  return { success: false, error, data: null };
};

const assembleActionUrl = (action: Action, params: Record<string, string> = {}) => {
  const urlParamStrings = (action.url.match(/{(.+?)}/g) ?? []).map((param) => param.replace(/{*}*/g, ''));
  // Assemble object of values which need to be embedded in the url
  const urlParams = urlParamStrings.reduce((returnParams, fieldName) => {
    const value = action.fieldValue(fieldName) || params[fieldName];
    if (value == null) {
      console.error(
        `Missing required url value on field "${fieldName}" of action ${action.readableName}`,
      );
    }
    return { ...returnParams, [fieldName]: value };
  }, {});

  // Assemble actual url string
  const url = Object.keys(urlParams).reduce(
    (newUrl, param) => newUrl.replace(`{${param}}`, urlParams[param as keyof typeof urlParams]),
    action.url,
  );
  return { url, urlParams };
};

const checkExtraParams = (action: Action, inputParams: Record<string, string> = {}, usedParams: Record<string, string> = {}) => {
  const extraParams = Object.keys(inputParams).filter(
    (paramKey) => !Object.keys(usedParams).includes(paramKey),
  );
  if (extraParams.length) {
    console.warn(
      `Params "${extraParams.join('" "')}" not included in request ${
        action.readableName
      } because they are not valid for this action`,
    );
  }
};

const assembleActionBody = (action: Action) => {
  const data = action.fields.reduce((params, field) => {
    const nullValue = field.value === undefined || field.value === null;
    if ((!field.nulls && nullValue)
      || field.readonly) {
      // included read only in stripped fields
      // console.warn(`Parameter ${field.name} not included in action request ${action.readableName} null values are not allowed for ${field.name}`);
      return { ...params };
    }
    return { ...params, [field.name]: action.fieldValue(field.name) };
  }, {});
  return JSON.stringify(data);
};

const assembleLinkUrl = (urlTemplate: string, params: Record<string, string>, query: Record<string, string | string[]>) => {
  const baseUrl = (urlTemplate.match(/{(.+?)}/g) ?? [])
    .map((param) => param.replace(/{*}*/g, ''))
    .reduce((newUrl, param) => {
      const value = params[param];
      if (value == null) {
        console.error(`Missing required url param "${param}" in request link`);
      }
      return newUrl.replace(`{${param}}`, value);
    }, urlTemplate);

  // URL Query
  const queryEntries = Object.entries(query);

  if (queryEntries.length) {
    const url: URL = new URL(baseUrl);
    queryEntries.forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          url.searchParams.append(key, v);
        });
      } else if (searchParamIsTruthy(value)) {
        url.searchParams.set(key, value);
      }
    });
    return url.toString();
  }

  return baseUrl.toString();
};

export const formatServerConnectionError = (e: unknown) => {
  // some sort of connection error
  if (devBuild) console.warn(e);
  const error = {
    text: CONNECTION_ERROR_MESSAGE,
    statusCode: CONNECTION_ERROR,
  };
  return { success: false, data: null, error };
};

export function* executeRequest(url: string, options: RequestInit) {
  // Compose request
  const headers = getHeaders();
  const request = new Request(url, { ...options, headers });
  // Make request
  try {
    const res: Response = yield fetch(request);

    if (request.url.includes(API_URL)) {
      const newApiVersion = res.headers.get('api-version');
      const currentApiVersion: string = yield select(getApiVersion);
      if (newApiVersion !== currentApiVersion) {
        yield put(setApiVersion(newApiVersion));
      }
    }
    if (request.url.includes(API_URL)) {
      const refreshToken = res.headers.get('x-refresh-token');
      if (
        refreshToken
        && refreshToken !== request.headers.get('authorization')
      ) {
        setAuthToken(refreshToken);
      }
    }
    const responseType = res.headers.get('Content-type')?.split(';')[0];
    if (res.status === 204) {
      return formatJsonApiResponse({});
    }

    switch (responseType) {
      case CONTENT_TYPES.JSON: {
        const resData: JsonApiError | ResourceObject | ResourceListParams = yield res.json();
        return formatJsonApiResponse(resData);
      }
      case CONTENT_TYPES.CSV: {
        const resData: JsonApiError | string = yield res.text();
        return formatCsvApiResponse(resData);
      }
      default: {
        const resData: JsonApiError | ResourceObject | ResourceListParams = yield res.json();
        console.warn(
          `Non-204 Response from ${res.url} lacks Content-type header, parsing as JSON`,
        );
        return formatJsonApiResponse(resData);
      }
    }
  } catch (e) {
    return formatServerConnectionError(e);
  }
}

function executeError(errorText: string) {
  console.error(errorText);
  const error = { text: errorText };
  return { success: false, error, data: null };
}

// Compose Request Elements ( url: String, options: Object )
const composeActionReq = (inputAction?: Action | null, input: Record<string, any> = {}, query: Record<string, any> = {}) => {
  if (!inputAction) {
    if (devBuild) {
      return executeError('Attempted to make an API action request with an undefined action');
    }
  } else {
    const params = cleanParams(input, inputAction);
    const searchParam = !!input['search[]'];
    const action = new Action(inputAction).withValues(params);

    if (devBuild && !action.valid) validateFields(action);
    const { url: baseUrl, urlParams } = assembleActionUrl(action, params);
    const dataParams = action.fields.reduce((newParams, field) => {
      const isUrlParam = Object.prototype.hasOwnProperty.call(
        urlParams,
        field.name,
      );
      const nullValue = field.value === null
      || field.value === undefined
      || (field.name === 'search[]' && Array.isArray(field.value) && field.value?.length === 0);

      if (isUrlParam || (!field.nulls && nullValue)) {
        return newParams;
      }
      return { ...newParams, [field.name]: field.value };
    }, {});
    if (devBuild) checkExtraParams(action, params, { ...dataParams, ...urlParams });

    let url: string;
    let options;
    if (['GET', 'HEAD'].includes(action.method)) {
      if (searchParam) {
        const urlNewParams = new URLSearchParams();
        Object.entries(dataParams).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => {
              urlNewParams.append(key, v);
            });
          } else {
            urlNewParams.append(key, value?.toString?.() ?? String(value));
          }
        });
        const stringifiedParams = urlNewParams.toString();
        url = `${baseUrl}?${stringifiedParams}`;
      } else {
        const tempUrl = new URL(baseUrl);
        Object.entries(dataParams).forEach(([key, value]) => {
          const convertedVal = value?.toString?.() ?? String(value);
          if (searchParamIsTruthy(convertedVal)) {
            tempUrl.searchParams.set(key, convertedVal);
          }
        });
        url = tempUrl.toString();
      }

      options = { method: action.method };
    } else {
      if (query) {
        const tempUrl = new URL(baseUrl);
        Object.entries(query).forEach(([key, value]) => {
          const convertedVal = value?.toString?.() || String(value);
          if (searchParamIsTruthy(convertedVal)) {
            tempUrl.searchParams.set(key, convertedVal);
          }
        });
        url = tempUrl.toString();
      } else {
        url = `${baseUrl}`;
      }
      const body = assembleActionBody(action);
      options = { body, method: action.method };
    }
    return executeRequest(url, options);
  }
  return null;
};

export const composeLinkReq = (link: Link = '', params: Record<string, string> = {}, query: Record<string, string | string[]> = {}) => {
  if ((!link || typeof link !== 'string') && devBuild) {
    return executeError('Attempted to make a request with an undefined or incorrectly formatted link');
  }
  if (typeof link === 'string') {
    const linkUrl = link.startsWith('http') ? link : `${API_URL}${link}`;
    const options = { method: 'GET' };
    const url = assembleLinkUrl(linkUrl, params, query);
    return executeRequest(url, options);
  }
  if (devBuild) {
    return executeError('Attempted to make a request with an undefined or incorrectly formatted link');
  }
  return {};
};

// To be deprecated
const composeRequestManager = (method: string) => (route: string, params: Record<string, string> = {}) => {
  const url = route && !route.startsWith('http') ? API_URL + route : route;
  if (['GET', 'DELETE'].includes(method)) {
    return executeRequest(url, { method });
  }
  const options = { body: JSON.stringify(params), method };

  return executeRequest(url, options);
};

// Exported object
const makeRequest = {
  action: composeActionReq,
  link: composeLinkReq,

  // To be deprecated
  get: composeRequestManager('GET'),
  delete: composeRequestManager('DELETE'),
  patch: composeRequestManager('PATCH'),
  post: composeRequestManager('POST'),
};

export default makeRequest;
