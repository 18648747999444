// eslint-disable-next-line max-classes-per-file
import ResourceMeta from 'lib/jsonApi/ResourceMeta';
import { castArray, get } from 'lodash';
import type { DefaultAttributes, ResourceObject, Relationships, Links, ResourceObjects } from 'types/json-api-types';

type RelationshipData = {
  id: string,
  type: string,
};

export class Relationship {
  data: RelationshipData | RelationshipData[];

  constructor(props: Relationship | { data: RelationshipData | RelationshipData[] }) {
    // Props is sometimes a Relationship object
    if (props instanceof Relationship) {
      this.data = props.data;
    } else {
      this.data = props.data ? props.data : [];
    }
  }
}

export default class Resource<Attributes = DefaultAttributes> implements ResourceObject<Attributes | DefaultAttributes> {
  type: string;

  id: string;

  attributes: Attributes;

  relationships?: Relationships;

  links: Links;

  meta?: ResourceMeta;

  included?: ResourceObjects;

  // eslint-disable-next-line @typescript-eslint/default-param-last
  constructor(props: ResourceObject<Attributes> | Resource<Attributes> = { id: '', type: '' }, externalIncluded: ResourceObjects = []) {
    this.id = props.id;
    this.type = props.type;
    if ('attributes' in props && props.attributes !== undefined) {
      this.attributes = props.attributes;
    } else {
      this.attributes = {} as Attributes;
    }
    if ('relationships' in props && props.relationships !== undefined) {
      const propsRels = props.relationships;
      this.relationships = Object.keys(propsRels).reduce(
        (rels, relKey) => ({ ...rels, [relKey]: new Relationship(propsRels[relKey]) }),
        {},
      );
    }
    if ('links' in props && props.links !== undefined) {
      this.links = props.links;
    } else {
      this.links = {};
    }
    if ('meta' in props) {
      this.meta = new ResourceMeta(props.meta);
    }
    if (Array.isArray(externalIncluded) && externalIncluded.length) {
      this.included = this.newEmbeddedRes(externalIncluded);
    } else if ('included' in props && props.included !== undefined) {
      const propsIncluded = props.included;
      this.included = this.newEmbeddedRes(propsIncluded);
    }

    // Create Proxy to hook into property accesses to allow shorthand grabbing attributes and relations
    // try catch block is to help browsers that don't implement Proxy.  Without it the browser will crash,
    // with error caught the unsupported browser component will render
    try {
      return new Proxy(this, {
        get: (obj, prop) => {
          // toJSON is called A LOT in the background (probably by React)
          if (prop in obj || prop === 'toJSON') {
            return obj[prop as keyof typeof obj];
          }
          if (this.attributes?.[prop as keyof typeof this.attributes]) {
            return this.attributes[prop as keyof typeof this.attributes];
          }
          if (this.relationships?.[prop as keyof typeof this.relationships]) {
            return this.getRel(prop.toString());
          }
          return undefined;
        },
      });
    } catch (e) {
      return this;
    }
  }

  getRel = <T = DefaultAttributes>(relName: string) => {
    const relation = this.relationships?.[relName];
    if (!relation) {
      return undefined;
    }
    const { included } = this;
    const relationshipData = relation.data;
    if (included) {
      if (Array.isArray(relationshipData)) {
        const relatedResources: ResourceObject<T>[] = [];
        relationshipData.forEach((relRes) => {
          const resource = included?.find((inc) => relRes.id === inc.id && relRes.type === inc.type);
          if (resource) {
            relatedResources.push(resource as ResourceObject<T>);
          }
        });
        return relatedResources;
      }
      const relatedResource = included.find((inc: ResourceObject) => relationshipData.id === inc.id && relationshipData.type === inc.type) as Resource<T>;
      return relatedResource;
    }
    if (Array.isArray(relationshipData)) {
      return [];
    }
    return undefined;
  };

  getAction = (name: string) => get(this, 'meta.getAction', () => undefined)(name);

  withAttrs = <T>(newAttrs: T) => {
    const attributes = { ...this.attributes, ...newAttrs };
    return new Resource<Attributes & T>({ ...this, attributes });
  };

  newEmbeddedRes = (included?: ResourceObjects) => {
    if (!included) {
      return undefined;
    }
    return included.map((resource: Resource | ResourceObject) => {
      const newIncluded: ResourceObject[] = [];

      if (resource.relationships) {
        Object.values(resource.relationships).forEach((relationship) => {
          castArray(relationship.data).forEach((relResource) => {
            const newInclude = included.find((inc: ResourceObject) => relResource.id === inc.id && relResource.type === inc.type);
            if (newInclude) {
              newIncluded.push(newInclude);
            }
          });
        });
      }

      return new Resource(resource, newIncluded);
    });
  };
}
