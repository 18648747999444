import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ResourceList from 'lib/jsonApi/ResourceList';
import Resource from 'lib/jsonApi/Resource';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';
import { UnwrappedResourceList } from 'types/json-api-types';
import { CytometerAttributes, CytometerLaserValuesAttributes, CytometerLaserValuesMetaAttributes, CytometerRssReportAttributes } from 'types/cytometers';
import { GenericActionPayload } from 'types/redux-types';
import { RSS_REPORT_CATEGORIES, RSS_REPORT_STATUSES } from 'lib/constants';

type Setting = {
  parameter: string,
  voltage: number,
  log: boolean,
  a: boolean,
  h: boolean,
  w: boolean,
};

interface CytometerSliceState {
  orgCytometers: ResourceList<CytometerAttributes>;
  searchCytometers: ResourceList;
  currentCytometer: Resource;
  cytometerVendors: ResourceList;
  cytometerRSSData: Record<string, { data: Resource<CytometerLaserValuesAttributes>[], rowCount: number } | undefined>;
  cytometerSettings: Setting[];
  publicCytometers: ResourceList;
  dashboardCytometers: ResourceList<CytometerAttributes>
  currentCytometerRssReports: ResourceList<CytometerRssReportAttributes>;
  currentCytometerLaserValuesMeta: Resource<CytometerLaserValuesMetaAttributes>
}

const initialState: CytometerSliceState = {
  orgCytometers: new ResourceList(),
  searchCytometers: new ResourceList(),
  currentCytometer: new Resource(),
  cytometerVendors: new ResourceList(),
  cytometerRSSData: {},
  cytometerSettings: [],
  publicCytometers: new ResourceList(),
  dashboardCytometers: new ResourceList(),
  currentCytometerRssReports: new ResourceList(),
  currentCytometerLaserValuesMeta: new Resource(),
};

export type RequestCytometerRSSDataQuery = {
  start_date?: string | null,
  end_date?: string | null,
  category?: string,
  report_category?: string,
  report_configuration_name?: string,
  report_bead_lot_id?: string,
  fetch?: boolean,
}

export type RequestCytometerRssReportsQuery = {
  pageSize?: number,
  page?: number,
  report_at_from?: string,
  report_at_to?: string,
  status?: typeof RSS_REPORT_STATUSES[keyof typeof RSS_REPORT_STATUSES],
  category?: typeof RSS_REPORT_CATEGORIES[keyof typeof RSS_REPORT_CATEGORIES] | 'all',
  configuration_name?: string,
  bead_lot_id?: string,
}

// API Request Actions
const requestOrgCytometers = createAction<GenericActionPayload>('requestOrgCytometers');
const requestSearchCytometers = createAction('requestSearchCytometers');
const requestCurrentCytometer = createAction<GenericActionPayload>('requestCurrentCytometer');
const requestCytometerVendors = createAction('requestCytometerVendors');
const patchCytometer = createAction<GenericActionPayload>('patchCytometer');
const deleteCytometer = createAction('deleteCytometer');
const deleteCytometerProfile = createAction('deleteCytometerProfile');
const setDefaultCytometerProfile = createAction('setDefaultCytometerProfile');
const duplicateCytometerProfile = createAction('duplicateCytometerProfile');
const publishCytometer = createAction('publishCytometer');
const unpubilshCytometer = createAction('unpubilshCytometer');
const cloneCytometer = createAction('cloneCytometer');
const linkCytometer = createAction('linkCytometer');
const unlinkCytometer = createAction('unlinkCytometer');
const patchCytometerOnlineStatus = createAction('patchCytometerOnlineStatus');
const downloadCytometerProfilePdf = createAction('downloadCytometerProfilePdf');
const requestCytometerRSSData = createAction<GenericActionPayload<undefined, RequestCytometerRSSDataQuery>>('cytometers/requestCytometerRSSData');
const requestCytometerSettings = createAction('requestCytometerSettings');
const patchCytometerSpectralOn = createAction('patchCytometerSpectralOn');
const patchCytometerSpectralOff = createAction('patchCytometerSpectralOff');
const updateCytometerSettings = createAction('updateCytometerSettings');
const requestPublicCytometers = createAction('requestPublicCytometers');
const requestDashboardCytometers = createAction<GenericActionPayload<Record<string, any>, { dashboard: boolean }>>('requestDashboardCytometers');
const requestCytometerRssReports = createAction<GenericActionPayload<undefined, RequestCytometerRssReportsQuery>>('requestCytometerRssReports');
const requestCytometerLaserValuesMeta = createAction<GenericActionPayload>('cytometers/requestCytometerLaserValuesMeta');

const cytometersSlice = createSlice({
  name: 'cytometers',
  initialState,
  reducers: {
    setCytometers: (state, action: PayloadAction<ResourceList<CytometerAttributes>>) => {
      state.orgCytometers = action.payload;
    },
    setMoreCytometers: (state, action: PayloadAction<ResourceList<CytometerAttributes>>) => {
      state.orgCytometers = state.orgCytometers.mergeWith(action.payload);
    },
    updateCytometersList: (state, action: PayloadAction<Resource<CytometerAttributes> | UnwrappedResourceList<CytometerAttributes>>) => {
      state.orgCytometers = state.orgCytometers.withResource(action.payload);
    },
    setSearchCytometers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.searchCytometers = state.searchCytometers.mergeWith(action.payload);
      } else {
        state.searchCytometers = action.payload;
      }
    },
    setCurrentCytometer: (state, action: PayloadAction<Resource>) => {
      state.currentCytometer = action.payload;
    },
    setCytometerVendors: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.cytometerVendors = state.cytometerVendors.mergeWith(action.payload);
      } else {
        state.cytometerVendors = action.payload;
      }
    },
    setCytometerRSSData: (state, action: PayloadAction<ResourceList<CytometerLaserValuesAttributes>>) => {
      if (Array.isArray(action.payload.data) && action.payload.data.length === 0) {
        state.cytometerRSSData = {}
        return;
      }
      (Array.isArray(action.payload.data) ? action.payload.data : []).forEach((d) => {
        state.cytometerRSSData[d.attributes.category] = { data: [...(state.cytometerRSSData?.[d.attributes.category]?.data ?? []), d], rowCount: action.payload.meta.pagination?.rowCount ?? 0}
      });
    },
    setCytometerSettings: (state, action: PayloadAction<Setting[]>) => {
      state.cytometerSettings = action.payload;
    },
    setPublicCytometers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.publicCytometers = state.publicCytometers.mergeWith(action.payload);
      } else {
        state.publicCytometers = action.payload;
      }
    },
    setDashboardCytometers: (state, action: PayloadAction<ResourceList<CytometerAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.dashboardCytometers = state.dashboardCytometers.mergeWith(action.payload);
      } else {
        state.dashboardCytometers = action.payload;
      }
    },
    setCurrentCytometerRssReports: (state, action: PayloadAction<ResourceList<CytometerRssReportAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.currentCytometerRssReports = state.currentCytometerRssReports.mergeWith(action.payload);
      } else {
        state.currentCytometerRssReports = action.payload;
      }
    },
    setCurrentCytometerLaserValuesMeta: (state, action: PayloadAction<ResourceList<CytometerLaserValuesMetaAttributes>>) => {
      const resource = action.payload.unwrap() as Resource<CytometerLaserValuesMetaAttributes>;
      state.currentCytometerLaserValuesMeta = resource;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setCytometers,
  setMoreCytometers,
  updateCytometersList,
  setSearchCytometers,
  setCurrentCytometer,
  setCytometerVendors,
  setCytometerRSSData,
  setCytometerSettings,
  setPublicCytometers,
  setDashboardCytometers,
  setCurrentCytometerRssReports,
  setCurrentCytometerLaserValuesMeta,
} = cytometersSlice.actions;

export {
  // Reducer Actions
  setCytometers,
  setMoreCytometers,
  updateCytometersList,
  setSearchCytometers,
  setCurrentCytometer,
  setCytometerVendors,
  setCytometerRSSData,
  setCytometerSettings,
  setPublicCytometers,
  setDashboardCytometers,
  setCurrentCytometerRssReports,
  setCurrentCytometerLaserValuesMeta,

  // API Requests
  requestOrgCytometers,
  requestSearchCytometers,
  requestCurrentCytometer,
  patchCytometer,
  deleteCytometer,
  deleteCytometerProfile,
  setDefaultCytometerProfile,
  duplicateCytometerProfile,
  publishCytometer,
  unpubilshCytometer,
  cloneCytometer,
  linkCytometer,
  unlinkCytometer,
  patchCytometerOnlineStatus,
  requestCytometerVendors,
  downloadCytometerProfilePdf,
  requestCytometerRSSData,
  requestCytometerSettings,
  patchCytometerSpectralOn,
  patchCytometerSpectralOff,
  updateCytometerSettings,
  requestPublicCytometers,
  requestDashboardCytometers,
  requestCytometerRssReports,
  requestCytometerLaserValuesMeta,
};

// Selectors
export const getOrgCytometers = (state: RootState) => state.cytometers.orgCytometers;
export const getSearchCytometers = (state: RootState) => state.cytometers.searchCytometers;
export const getCurrentCytometer = (state: RootState) => state.cytometers.currentCytometer;
export const getCytometerVendors = (state: RootState) => state.cytometers.cytometerVendors;
export const getCytometerRSSData = (state: RootState) => state.cytometers.cytometerRSSData;
export const getCytometerRSSDataForCategory = (category: string) => (state: RootState) => state.cytometers.cytometerRSSData[category];
export const getCytometerSettings = (state: RootState) => state.cytometers.cytometerSettings;
export const getPublicCytometers = (state: RootState) => state.cytometers.publicCytometers;
export const getDashboardCytometers = (state: RootState) => state.cytometers.dashboardCytometers;
export const getCurrentCytometerRssReports = (state: RootState) => state.cytometers.currentCytometerRssReports;
export const getCurrentCytometerLaserValuesMeta = (state: RootState) => state.cytometers.currentCytometerLaserValuesMeta;

export default cytometersSlice.reducer;
