import React, { useState, useEffect, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import globals from 'styles/globals';
import { addToStyleString } from 'lib/utils/styles/doMathOnStyleString';

import { useClientDimensions } from 'hooks/useScreenDimensions';
import { XIcon } from 'components/Icons';
import { useAppSelector } from 'hooks/reduxHooks';
import { getIsMobile } from 'rdx/modules/app/slice';
import { useLoading } from 'hooks/useLoading';
import DangerButton from 'components/Button/DangerButton';
import PrimaryButton from 'components/Button/PrimaryButton';

export const MODAL_DIMENSIONS_CUTOFF_PERCENTAGE = 0.8;

type EditableStyles = {
  backgroundColor?: string,
  padding?: string
}

export type ModalProps = React.PropsWithChildren<{
  open: boolean,
  dimensions?: { width: string, height?: string },
  confirm?: {
    render?: () => ReactNode,
    text?: string,
    handler?: () => void,
    disabled?: boolean,
  },
  cancel?: {
    render?: () => ReactNode,
    text?: string,
    handler?: () => void,
  },
  onClose: () => void,
  className?: string,
  dangerous?: boolean,
  styles?: EditableStyles,
  preventClose?: boolean,
  forceClick?: boolean,
  rdxTypes?: string[],
  allowScrollingX?: boolean,
  allowScrollingY?: boolean,
  fullscreen?: boolean,
  alert?: boolean,
  id?: string,
  noFooter?: boolean,
  modalHeader?: ReactNode,
  showXIcon?: boolean,
  noMaxHeight?: boolean,
}>

const Modal = ({
  open,
  dimensions = { width: '600px', height: '400px'},
  confirm,
  cancel,
  onClose,
  children,
  className,
  dangerous,
  styles,
  preventClose,
  forceClick,
  rdxTypes,
  allowScrollingX,
  allowScrollingY,
  fullscreen,
  alert,
  id,
  noFooter,
  modalHeader,
  showXIcon,
  noMaxHeight,
}: ModalProps) => {
  const [asyncOpen, setAsyncOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const clientDimensions = useClientDimensions();
  const isMobile = useAppSelector(getIsMobile);
  const safeDimensions = React.useMemo(() => {
    const widthCutoff = `${Math.round(clientDimensions.width * MODAL_DIMENSIONS_CUTOFF_PERCENTAGE)}px`;
    const heightCutoff = `${Math.round(clientDimensions.height * MODAL_DIMENSIONS_CUTOFF_PERCENTAGE)}px`;
    return {
      width: widthCutoff,
      height: heightCutoff,
    };
  }, [clientDimensions.height, clientDimensions.width]);

  useEffect(() => {
    setTimeout(() => {
      if (!open) {
        setClosing(true);
      }
      setAsyncOpen(open);
    }, 0);
  }, [open]);

  const handleButtonClick = (handler?: () => void) => {
    if (handler) {
      handler();
    }

    if (!preventClose) {
      onClose();
    }
  };

  const handleTransitionEnd = () => {
    if (!open) {
      setClosing(false);
    }
  };

  const loading = useLoading({ watchRequests: rdxTypes })

  const renderConfirm = () => {
    if (!confirm) {
      return null;
    }
    const { render, text, handler, disabled } = confirm;
    if (render) {
      return render();
    }

    if (dangerous ?? alert) {
      return (
        <DangerButton
          loading={loading}
          data-testid="dangerous-confirm-button"
          text={text ?? 'confirm'}
          onClick={() => handleButtonClick(handler)}
          disabled={disabled}
        />
      );
    }
    return (
      <PrimaryButton
        loading={loading}
        data-testid="modal-confirm-button"
        text={text ?? 'OK'}
        onClick={() => handleButtonClick(handler)}
        disabled={disabled}
      />
    );
  };

  const renderCancel = () => {
    if (!cancel) {
      return null;
    }
    const { render, text, handler } = cancel;
    if (render) {
      return render();
    }
    return (
      <PrimaryButton
        hollow
        text={text ?? 'Cancel'}
        data-testid="modal-cancel-button"
        onClick={() => handleButtonClick(handler)}
      />
    );
  };

  return (
    <ModalContainer
      {...{ asyncOpen, closing }}
    >
      <Overlay
        {...{ asyncOpen }}
        onClick={(dangerous ?? forceClick) ? undefined : () => onClose()}
        onTransitionEnd={handleTransitionEnd}
      />
      <ModalPopup {...{ fullscreen, clientDimensions, dimensions, safeDimensions, className, asyncOpen, styles, id, noMaxHeight }}>
        {modalHeader}
        {showXIcon && <CloseIcon onClick={onClose} />}
        <ModalInner {...{ allowScrollingX, allowScrollingY }}>
          <ModalBody>
            {children}
          </ModalBody>
          {!noFooter && (
            <ModalFooter isMobile={isMobile}>
              {cancel && renderCancel()}
              {confirm && renderConfirm()}
            </ModalFooter>
          )}
        </ModalInner>
      </ModalPopup>
    </ModalContainer>
  );
};

export default Modal;

const { colors, zIndices } = globals;
const MODAL_TRANSITION_TIME = '200ms';
const SIZE_TRANSITION_TIME = addToStyleString(MODAL_TRANSITION_TIME, 150);

const ModalContainer = styled.div<{ asyncOpen?: boolean, closing?: boolean }>`
  position: fixed;
  z-index: ${zIndices.modal};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ asyncOpen }) => (asyncOpen ? 'all' : 'none')};
  visibility: ${({ closing, asyncOpen }) => ((!closing && !asyncOpen) ? 'hidden' : 'visible')};
`;

const Overlay = styled.div<{ asyncOpen?: boolean }>`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.black};
  opacity: ${({ asyncOpen }) => (asyncOpen ? 0.2 : 0)};
  transition: opacity ${MODAL_TRANSITION_TIME};
`;

const ModalPopup = styled.div<{
  asyncOpen?: boolean,
  fullscreen?: boolean,
  clientDimensions: { width: number, height: number },
  dimensions: { width: string, height?: string },
  safeDimensions: { width: string, height: string },
  styles?: EditableStyles,
  noMaxHeight?: boolean,
  }>`
  display: flex;
  flex-direction: column;
  height: ${({ fullscreen, clientDimensions, dimensions }) => (fullscreen ? `${clientDimensions.height}px` : dimensions.height)};
  width: ${({ fullscreen, clientDimensions, dimensions }) => (fullscreen ? `${clientDimensions.width}px` : dimensions.width)};
  max-height: ${({ fullscreen, clientDimensions, safeDimensions, noMaxHeight }) => ((fullscreen || noMaxHeight) ? `${clientDimensions.height - 40}px` : safeDimensions.height)};
  max-width: ${({ fullscreen, clientDimensions, safeDimensions }) => (fullscreen ? `${clientDimensions.width}px` : safeDimensions.width)};
  background-color: ${({ styles }) => (styles?.backgroundColor ? styles.backgroundColor : colors.white)};
  padding: ${({ styles }) => (styles?.padding ? styles.padding : '40px')};
  border-radius: 3px;
  box-shadow: 2px 2px 20px -1px ${colors.fontDark};
  opacity: ${({ asyncOpen }) => (asyncOpen ? 1 : 0)};
  transform: translateY(${({ asyncOpen }) => (asyncOpen ? '0px' : '-50px')});
  transition: opacity ${MODAL_TRANSITION_TIME}, transform ${MODAL_TRANSITION_TIME}, height ${SIZE_TRANSITION_TIME}, width ${SIZE_TRANSITION_TIME};
  overflow: visible;

  ${({ fullscreen }) => fullscreen && css`
    margin-bottom: 40px;
  `}
`;

const ModalInner = styled.div<{ allowScrollingX?: boolean, allowScrollingY?: boolean }>`
  overflow-x: ${({ allowScrollingX }) => (allowScrollingX ? 'auto' : 'visible')};
  overflow-y: ${({ allowScrollingY }) => (allowScrollingY ? 'auto' : 'visible')};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalBody = styled.div`
  flex: 1 1 auto;
`;

const ModalFooter = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  flex-wrap: wrap;

  ${({ isMobile }) => (isMobile ? css`
    justify-content: center;
    gap: 10px;
  ` : css`
    justify-content: flex-end;
    gap: 15px;
  `)}
`;

const CloseIcon = styled(XIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
`;
