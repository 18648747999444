import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const ThreeDotVertical = forwardRef((props, ref) => (
  <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
    <path d="M14 5c0 1.381-1.119 2.5-2.5 2.5s-2.5-1.119-2.5-2.5c0-1.381 1.119-2.5 2.5-2.5s2.5 1.119 2.5 2.5z" />
    <path d="M14 12c0 1.381-1.119 2.5-2.5 2.5s-2.5-1.119-2.5-2.5c0-1.381 1.119-2.5 2.5-2.5s2.5 1.119 2.5 2.5z" />
    <path d="M14 19c0 1.381-1.119 2.5-2.5 2.5s-2.5-1.119-2.5-2.5c0-1.381 1.119-2.5 2.5-2.5s2.5 1.119 2.5 2.5z" />
  </svg>
));

export default createIcon(ThreeDotVertical);
