import { createAction, createSlice } from '@reduxjs/toolkit';
import ResourceList from 'lib/jsonApi/ResourceList';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';

const initialState = {
  public: new ResourceList(),
};

const requestPublicLabs = createAction('requestPublicLabs');
const postOrganizationLab = createAction('postOrganizationLab');
const deleteOrganizationLab = createAction('deleteOrganizationLab');

const labsSlice = createSlice({
  name: 'labs',
  initialState,
  reducers: {
    setPublicLabs: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.public = state.public.mergeWith(action.payload);
      } else {
        state.public = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setPublicLabs,
} = labsSlice.actions;

export {
  setPublicLabs,
  requestPublicLabs,
  postOrganizationLab,
  deleteOrganizationLab,
};

export const getPublicLabs = (state: RootState) => state.labs.public;

export default labsSlice.reducer;
