import { useState } from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'lib/customPropTypes';
import styled, { css } from 'styled-components';

import globals from 'styles/globals';
import { useAppSelector } from 'hooks/reduxHooks';
import { getIsMobile } from 'rdx/modules/app/slice';

export const drawerTypes = {
  primary: 'primary',
  secondary: 'secondary',
};

const SideDrawer = (props) => {
  const {
    open, handleCloseDrawer, children, drawerType,
  } = props;
  const [endStateOffScreen, setEndStateOffScreen] = useState(!open);

  const isMobile = useAppSelector(getIsMobile);

  const handleTransitionEnd = () => {
    setEndStateOffScreen(!open);
  };

  return (
    <SideDrawerContainer drawerType={drawerType} open={open} endStateOffScreen={endStateOffScreen}>
      {!isMobile && (
        <Whitespace
          onClick={() => handleCloseDrawer()}
          role="button"
          tabIndex="0"
          {...{ open }}
        />
      )}
      <Content
        {...{ open, drawerType, endStateOffScreen, isMobile }}
        onTransitionEnd={handleTransitionEnd}
      >
        {children}
      </Content>
    </SideDrawerContainer>
  );
};

SideDrawer.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
  children: CustomPropTypes.children,
  drawerType: PropTypes.oneOf([drawerTypes.primary, drawerTypes.secondary]),
};

SideDrawer.defaultProps = {
  className: '',
  open: false,
  handleCloseDrawer: () => {},
  children: undefined,
  drawerType: drawerTypes.primary,
};

export default SideDrawer;

const { colors, layout, animations, zIndices } = globals;

const getDrawerWidth = ({ drawerType }) => {
  switch (drawerType) {
  case drawerTypes.primary: return layout.drawerWidth;
  case drawerTypes.secondary: return layout.secondaryDrawerWidth;
  default: return layout.drawerWidth;
  }
};

const getDrawerZ = ({ drawerType }) => {
  switch (drawerType) {
  case drawerTypes.primary: return zIndices.drawer;
  case drawerTypes.secondary: return zIndices.secondaryDrawer;
  default: return zIndices.drawer;
  }
};

const getDrawerMinWidth = ({ drawerType }) => {
  switch (drawerType) {
  case drawerTypes.primary: return layout.drawerMinWidth;
  case drawerTypes.secondary: return layout.secondaryDrawerMinWidth;
  default: return layout.drawerMinWidth;
  }
};

const getDrawerMaxWidth = ({ drawerType }) => {
  switch (drawerType) {
  case drawerTypes.primary: return layout.drawerMaxWidth;
  case drawerTypes.secondary: return layout.secondaryDrawerMaxWidth;
  default: return layout.drawerMinWidth;
  }
};

const SideDrawerContainer = styled.div`
  position: fixed;
  z-index: ${getDrawerZ};
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  visibility: ${({ open, endStateOffScreen }) => (!open && endStateOffScreen && 'hidden')};
  /* height: ${({ open, endStateOffScreen }) => (open && !endStateOffScreen ? '100%' : '0')}; */
  /* transition: height ${animations.transitionSlow} cubic-bezier(0.4, 0.0, 1, 1); */
`;

export const Content = styled(({ drawerType, endStateOffScreen, isMobile, ...props }) => <div {...props} />)`
    transform: translateX(100%);
    height: 100%;

    ${({ isMobile }) => !isMobile && css`
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(${getDrawerWidth});
    height: calc(100vh - 40px);
    width: ${getDrawerWidth};
    min-width: ${getDrawerMinWidth};
    max-width: ${getDrawerMaxWidth};
    overflow: hidden;
  `}

  background-color: ${colors.lightGrey};
  transition: transform ${animations.transitionSlow} cubic-bezier(0.4, 0.0, 1, 1);

  ${({ open }) => open && css`
    transform: translateX(0);
    transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
  `}

  ${({ open, endStateOffScreen }) => (!open && endStateOffScreen) && css`
    visibility: hidden;
  `}

  ${({ open, endStateOffScreen }) => (open && !endStateOffScreen) && css`
    transform: none;
  `}
`;

const Whitespace = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity ${animations.transitionSlow} cubic-bezier(0.4, 0.0, 1, 1);

  ${({ open }) => open && css`
    opacity: 1;
    pointer-events: auto;
    transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
  `}
`;
