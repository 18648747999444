import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* studyReport({ payload: { action } }) {
  const { success, data, error } = yield* makeRequest.action(action);
  if (success && data) {
    const presignedUrl = data?.data?.attributes?.presigned_pdf_url;
    if (presignedUrl) {
      const a = document.createElement('a');
      a.href = presignedUrl;
      a.target = '_self';
      const fileName = presignedUrl.split('/').pop();
      a.download = fileName;
      document.body.appendChild(a);
      if (window.Cypress) {
        return null;
      }
      a.click();
      yield put(newMessageEvent({ text: 'Export incoming' }));
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default studyReport;
