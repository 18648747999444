import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { find } from 'lodash';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { setProcedureTemplates, setCustomProcedureTemplates, setBdProcedureTemplates } from '../slice';

function* requestProcedureTemplates({ payload }) {
  const sessionActions = yield select(getSessionAction);
  const action = find(sessionActions, { name: 'procedure_templates' });

  const { success, data, error } = yield* makeRequest.action(action, payload);
  if (success && data) {
    if (payload.category === 'custom') {
      yield put(setCustomProcedureTemplates(data));
    } else if (payload.category === 'bd') {
      yield put(setBdProcedureTemplates(data));
    } else {
      yield put(setProcedureTemplates(data));
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestProcedureTemplates;
