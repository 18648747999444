import { forwardRef } from 'react';

import createIcon from '../createIcon';

const Barcode = forwardRef<SVGSVGElement>((props, ref) => (
  <svg viewBox="0 0 100 100" ref={ref} {...props}>
    <g>
      <path d="M77 30h7v40h-7zm-16 0h7v40h-7zm-29 0h7v40h-7zm-16 0h7v40h-7zm55 0h3v40h-3zm-17 0h3v40h-3zm-12 0h3v40h-3zm-16 0h3v40h-3zm22 0h3v40h-3z" />
      <path d="M27 19H7a2 2 0 0 0-2 2v20a2 2 0 0 0 4 0V23h18a2 2 0 0 0 0-4Zm0 58H9V59a2 2 0 0 0-4 0v20a2 2 0 0 0 2 2h20a2 2 0 0 0 0-4Zm66-58H73a2 2 0 0 0 0 4h18v18a2 2 0 0 0 4 0V21a2 2 0 0 0-2-2Zm0 38a2 2 0 0 0-2 2v18H73a2 2 0 0 0 0 4h20a2 2 0 0 0 2-2V59a2 2 0 0 0-2-2Z" />
    </g>
  </svg>

));

export default createIcon(Barcode);
