import { useEffect, memo } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children }: { children?: React.ReactNode }) => {
  const el = document.createElement('div');

  useEffect(() => {
    document.body.appendChild(el);

    return () => {
      if (el && document.body.contains(el)) {
        document.body.removeChild(el);
      }
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
};

const PortalComponent = memo(Portal);

export default PortalComponent;