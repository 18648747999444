import { useEffect, useState, useCallback, useRef } from 'react'
import styled, { css } from "styled-components";

import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getSessionLink } from "rdx/modules/auth/slice";
import { getNotificationEvents, requestMarkNotificationEventsRead, requestNotificationEvents } from "rdx/modules/notificationEvents/slice";

import { useSocketMessages } from 'hooks/useSocketMessages';
import { useLoading } from 'hooks/useLoading';
import { usePrevious } from 'hooks/usePrevious';

import Notification from "components/NotificationBar/Notification";
import LoadingCircle from 'components/Loading/LoadingCircle';
import LoadMoreResults from 'components/LoadMoreResults';

const MAX_HEIGHT = '400px';
const CLOSED_HEIGHT = '0px';

const NotificationCenter = ({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const dispatch = useAppDispatch();

  const eventNoticesLink = useAppSelector(getSessionLink('event_notices'));
  const eventNoticesReadLink = useAppSelector(getSessionLink('event_notices_read'));

  const notificationEvents = useAppSelector(getNotificationEvents)?.unwrap();

  const loading = useLoading({ watchRequests: [requestNotificationEvents.type] });

  const ref = useRef<HTMLDivElement | null>(null);

  const markNotificationsRead = useCallback(() => {
    if (eventNoticesReadLink) {
      dispatch(requestMarkNotificationEventsRead({
        link: eventNoticesReadLink,
      }))
    }
  }, [dispatch, eventNoticesReadLink]);

  const fetchNotificationEvents = useCallback(({ page = 1, scroll = false } = {}) => {
    if (eventNoticesLink) {
      dispatch(requestNotificationEvents({
        link: eventNoticesLink,
        query: { page },
        onSuccess: () => {
          if (isOpen) {
            markNotificationsRead()
            if (scroll) {
              setTimeout(() => ref?.current?.scrollIntoView({ behavior: 'instant' }), 1);
            }
          }
        },
      }))
    }
  }, [dispatch, eventNoticesLink, isOpen, markNotificationsRead]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateHandler = useCallback((response: any) => {    
    switch (response?.data?.type) {
      case 'event_notice_messages': {
        fetchNotificationEvents()
        break;
      }
      default:
        break;
    }
  }, [fetchNotificationEvents])
  
  useSocketMessages(updateHandler);

  const prevOpen = usePrevious(isOpen);

  useEffect(() => {
    if (!prevOpen && isOpen) {
      fetchNotificationEvents();
    }
  }, [fetchNotificationEvents, isOpen, prevOpen])

  const [containerHeight, setContainerHeight] = useState(isOpen ? MAX_HEIGHT : CLOSED_HEIGHT);

  useEffect(() => {
    if (isOpen) {
      setContainerHeight(MAX_HEIGHT);
    } else {
      setContainerHeight(CLOSED_HEIGHT);
    }
  }, [isOpen]);

  const unreadCount = Array.isArray(notificationEvents) ? notificationEvents.filter((event) => {
    return !event.attributes.viewed_at
  }).length : 0;

  return (
    <>
      <Overlay isOpen={isOpen} onClick={() => {
        setIsOpen(false)
        fetchNotificationEvents()
      }}/>
      <NotificationsContainer isOpen={isOpen} height={containerHeight}>
        {isOpen && (
          <>
            <h2>Notifications{!!unreadCount && <span>{" "}({unreadCount} unread)</span>}</h2>
            <Divider />
            {loading && (
              <LoadingCircle />
            )}
            {!loading && Array.isArray(notificationEvents) && !notificationEvents.length && (
              <p>All clear!</p>
            )}
            {!loading && Array.isArray(notificationEvents) && notificationEvents.map((notificationEvent) => {
              return (
                <Notification key={notificationEvent.id} notification={notificationEvent} setIsOpen={setIsOpen}/>
              )
            })}
            {!!notificationEvents.meta?.pagination && notificationEvents.meta.pagination.pageCount > notificationEvents.meta.pagination.page && (
              <ButtonContainer>
                <LoadMoreResults
                  pagination={notificationEvents.meta.pagination}
                  type={requestNotificationEvents.type}
                  requestMore={({ page }) => fetchNotificationEvents({ page, scroll: true })}
                  buttonProps={{
                    size: 'small',
                  }}
                />
              </ButtonContainer>
            )}
            <div ref={ref} />
          </>
        )}
      </NotificationsContainer>
    </>
  )
}

export default NotificationCenter;

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 899;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'}
`;

const NotificationsContainer = styled.div<{ isOpen: boolean, height: string }>`
  position: absolute;
  top: 40px;
  right: 0px;
  height: fit-content;
  max-height: ${({ height }) => height};
  width: 450px;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ isOpen }) => isOpen && css`
    padding: 20px;
    border: 2px solid ${({ theme }) => theme.colors.darkGrey};
  `}
  overflow: auto;
  box-shadow: #c7c5c7 0px 2px 7px 1px;
  border-radius: 5px;
  z-index: ${({ theme }) => theme.zIndices.portal};
  transition: max-height 0.4s ease-out;
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.darkGrey};
  width: 100%;
  margin: 15px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;