import styled from 'styled-components';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import BUTTON_SIZES from 'styles/buttonSizes';
import { useForm } from 'hooks/useForm';
import FormField from 'components/Form/FormField/index';
import Input, { InputProps } from 'components/Form/Input/index';
import { confirmInviteExisting, requestLogin } from 'rdx/modules/auth/slice';
import PrimaryButton from 'components/Button/PrimaryButton';
import { useLoading } from 'hooks/useLoading';

type ConfirmExistingFormProps = {
  onSubmitSuccess: () => void,
  token: string,
};

type FormValues = {
  email: string,
  password: string,
};

const ConfirmExistingForm = ({ onSubmitSuccess, token }: ConfirmExistingFormProps) => {
  const dispatch = useDispatch();
  const handleSubmit = (values: FormValues) => {
    dispatch(confirmInviteExisting({
      params: {
        ...values,
        token,
      },
      onSuccess: () => dispatch(requestLogin({ ...values, onSuccess: onSubmitSuccess })),
    }));
  };

  const { form, fields } = useForm<{ email: string, password: string }>({
    email: {
      initialValue: '',
      schema: Yup.string().required('Please enter your account email address'),
    },
    password: {
      initialValue: '',
      type: 'password',
      schema: Yup.string().required('Please enter your password'),
    },
  }, {
    onSubmit: handleSubmit,
  });

  const loading = useLoading({ watchRequests: [requestLogin.type] });

  return (
    <FormContainer>
      <form {...form.props}>
        <FormField<string, InputProps>
          label="Email *"
          field={fields.email}
          component={Input}
          inputProps={{
            placeholder: 'Email',
          }}
        />
        <FormField<string, InputProps>
          label="Password *"
          field={fields.password}
          component={Input}
          inputProps={{
            placeholder: 'Password',
            type: 'password',
          }}
        />
        <ButtonContainer>
          <PrimaryButton
            text="Sign in"
            type="submit"
            loading={loading}
            disabled={(!fields.password.value || !fields.email.value)}
            {...BUTTON_SIZES.DEFAULT}
          />
        </ButtonContainer>
      </form>
    </FormContainer>
  );
};

export default ConfirmExistingForm;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
