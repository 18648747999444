import styled from 'styled-components';
import globals from 'styles/globals';
import { useHistory } from 'react-router-dom';

import bdLogo from 'assets/bd-logo-black.svg';
import DarkButton from 'components/DeprecatedButton/DarkButton/index';
import { useAppSelector } from 'hooks/reduxHooks';
import { getSessionLink, getSSOUrl } from 'rdx/modules/auth/slice';
import { getIsMobile } from 'rdx/modules/app/slice';
import LoginForm from './LoginForm';
import { useMixpanelPageview } from 'hooks/useMixpanelPageview';

const Login = () => {
  const history = useHistory();
  const isMobile = useAppSelector(getIsMobile);
  useMixpanelPageview('Login');

  const bdSsoUrlLink = useAppSelector(getSessionLink('bd_sso_url'));
  const ssoUrl = useAppSelector(getSSOUrl);

  const handlePrivacyClick = () => {
    history.push('/privacy');
  };

  const handleTermsClick = () => {
    history.push('/use');
  };

  return (
    <div>
      <SignInSection>
        <HeaderContainer>
          <Header isMobile={isMobile}>
            Sign In with your FlowJo Portal ID
          </Header>
        </HeaderContainer>
        <LoginForm />
      </SignInSection>
      <Horizontal>
        <PrivacyLink onClick={handlePrivacyClick}>View Privacy Statement</PrivacyLink>
        <PrivacyLink onClick={handleTermsClick}>Terms of Use</PrivacyLink>
      </Horizontal>
      {!!bdSsoUrlLink && (
        <SSOSection>
          <BDButtonLogo src={bdLogo} />
          {/* @ts-ignore */}
          <SSOButton href={ssoUrl}>
            Use BD Single Sign On
          </SSOButton>
        </SSOSection>
      )}
    </div>
  );
};

export default Login;

const { colors, fonts, fontSizes } = globals;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Horizontal = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PrivacyLink = styled.div`
  font-size: ${fontSizes.small};
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`;

const Header = styled.div<{ isMobile?: boolean }>`
  font: ${fonts.h2};
  font-weight: bold;
  color: ${colors.fontDark};
  margin: 20px 0 10px 0;

  ${({ isMobile }) => isMobile && 'text-align: center;'}
`;

const SignInSection = styled.div`
  grid-area: section-left;
`;

const SSOSection = styled.div`
  grid-area: section-right;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
`;

const SSOButton = styled(DarkButton)`
  width: auto;
  padding: 0px 15px;
`;

const BDButtonLogo = styled.img`
  position: relative;
  top: -1px;
  height: 20px;
  margin-right: 16px;
`;
