/* credit https://stackoverflow.com/questions/37095233/downloading-and-saving-data-with-fetch-from-authenticated-rest#answer-58795243 */
export default function downloadCsvContent(filename: string, text: string) {
  const blobObject = new Blob([text], { type: 'text/csv' });
  // Everything else (except old IE).
  // Create a dummy anchor (with a download attribute) to click.
  const anchor = document.createElement('a');
  anchor.download = filename;
  if (window.URL.createObjectURL) {
    // Everything else new.
    anchor.href = window.URL.createObjectURL(blobObject);
  } else {
    // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
    // Load up the data into the URI for "download."
    anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`;
  }

  // if we are in cypress don't bother actually clicking
  if (window.Cypress) { return; }
  // Now, click it.
  if (document.createEvent) {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    anchor.dispatchEvent(event);
  } else {
    anchor.click();
  }
}
