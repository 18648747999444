import { takeLatest } from 'redux-saga/effects';

import trackRequests from 'rdx/utils/trackRequests';
import { confirmInvite, confirmInviteExisting, createAccount, forgotPassword, requestInviteStatus, requestLogin, requestLogout, requestSession, resetPassword } from 'rdx/modules/auth/slice';
import login from './login';
import logout from './logout';
import getSession from './getSession';
import createAccountSaga from './createAccount';
import forgotPasswordSaga from './forgotPassword';
import resetPasswordSaga from './resetPassword';
import confirmInviteSaga from './confirmInvite';
import confirmInviteExistingSaga from './confirmInviteExisting';
import requestInviteStatusSaga from './requestInviteStatus';

function* watchAuthSagas() {
  yield trackRequests(takeLatest, requestSession.type, getSession);
  yield trackRequests(takeLatest, requestLogin.type, login);
  yield trackRequests(takeLatest, requestLogout.type, logout);
  yield trackRequests(takeLatest, createAccount.type, createAccountSaga);
  yield trackRequests(takeLatest, forgotPassword.type, forgotPasswordSaga);
  yield trackRequests(takeLatest, resetPassword.type, resetPasswordSaga);
  yield trackRequests(takeLatest, confirmInvite.type, confirmInviteSaga);
  yield trackRequests(takeLatest, confirmInviteExisting.type, confirmInviteExistingSaga);
  yield trackRequests(takeLatest, requestInviteStatus.type, requestInviteStatusSaga);
}

export default watchAuthSagas;
