import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { unionWith } from 'lodash';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';
import { requestSession } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

type ActiveRequestsState = PayloadActionWithGenericPayload[];

const initialState: ActiveRequestsState = [];

export const activeRequestsSlice = createSlice({
  name: 'activeRequests',
  initialState,
  reducers: {
    addActiveRequest: (state, action: PayloadAction<PayloadActionWithGenericPayload>) => unionWith(state, [action.payload], (a, b) => a.type === b.type),
    removeActiveRequest: (state, action: PayloadAction<PayloadActionWithGenericPayload>) => state.filter((ar) => ar.type !== action.payload.type),
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  addActiveRequest,
  removeActiveRequest,
} = activeRequestsSlice.actions;

// Actions
export {
  addActiveRequest,
  removeActiveRequest,
};

// Selectors
export const getActiveRequests = (state: RootState) => state.activeRequests;
export const getCurrentSessionRequest = (state: RootState) => state.activeRequests.find((req) => req.type === requestSession.type);
export const hasActiveRequestsByType = (requestTypes: string[]) => (state: RootState) => state.activeRequests.some((req) => requestTypes.includes(req.type));

// Reducer
export default activeRequestsSlice.reducer;
