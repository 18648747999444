import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'index';

const resetStore = createAction('app/resetStore');

const initialState = {
  isMobile: false,
  setForSession: false,
  preferredCameraId: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setSetForSession: (state, action: PayloadAction<boolean>) => {
      state.setForSession = action.payload;
    },
    setPreferredCameraId: (state, action: PayloadAction<string>) => {
      state.preferredCameraId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setIsMobile,
  setSetForSession,
  setPreferredCameraId,
} = appSlice.actions;

export {
  setIsMobile,
  setSetForSession,
  setPreferredCameraId,
  resetStore,
};

export const getIsMobile = (state: RootState) => state.app.isMobile;
export const getIsSetForSession = (state: RootState) => state.app.setForSession;
export const getPreferredCameraId = (state: RootState) => state.app.preferredCameraId;

export default appSlice.reducer;
