/* eslint-disable max-len */
import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';
import { requestBDPanels, requestCustomPanels, requestOMIPPanels, requestPanelDetail, setBDPanels, setCustomPanels, setOMIPPanels, deletePanel, setPanelDetails, requestPanelCatalogAvailability, requestPanelbdAvailability, setPanelCatalogAvailability, setPanelbdAvailability, requestStainingSheet, setStainingSheet, updateStainingSheet, requestPanelStains, setPanelStains, createPanelStain, deletePanelStain, updatePanelStain, requestPanels, setAllPanels } from './slice';

function* watchPanelsSagas() {
  yield trackRequests(takeLatest, requestPanels.type, (rdxAction) => requestSaga({ rdxAction, putData: setAllPanels }));
  yield trackRequests(takeLatest, requestCustomPanels.type, (rdxAction) => requestSaga({ rdxAction, putData: setCustomPanels }));
  yield trackRequests(takeLatest, requestOMIPPanels.type, (rdxAction) => requestSaga({ rdxAction, putData: setOMIPPanels }));
  yield trackRequests(takeLatest, requestBDPanels.type, (rdxAction) => requestSaga({ rdxAction, putData: setBDPanels }));
  yield trackRequests(takeLatest, deletePanel.type, (rdxAction) => requestSaga({ rdxAction, message: 'Panel Deleted' }));
  yield trackRequests(takeLatest, requestPanelDetail.type, (rdxAction) => requestSaga({ rdxAction, putData: setPanelDetails }));
  yield trackRequests(takeLatest, requestPanelCatalogAvailability.type, (rdxAction) => requestSaga({ rdxAction, putData: setPanelCatalogAvailability }));
  yield trackRequests(takeLatest, requestPanelbdAvailability.type, (rdxAction) => requestSaga({ rdxAction, putData: setPanelbdAvailability }));
  yield trackRequests(takeLatest, requestStainingSheet.type, (rdxAction) => requestSaga({ rdxAction, putData: setStainingSheet }));
  yield trackRequests(takeLatest, updateStainingSheet.type, (rdxAction) => requestSaga({ rdxAction, message: 'Staining Sheet Updated' }));
  yield trackRequests(takeLatest, requestPanelStains.type, (rdxAction) => requestSaga({ rdxAction, putData: setPanelStains }));
  yield trackRequests(takeLatest, createPanelStain.type, (rdxAction) => requestSaga({ rdxAction, message: 'Panel Stain Created' }));
  yield trackRequests(takeLatest, updatePanelStain.type, (rdxAction) => requestSaga({ rdxAction, message: 'Panel Stain Updated' }));
  yield trackRequests(takeLatest, deletePanelStain.type, (rdxAction) => requestSaga({ rdxAction, message: 'Panel Stain Deleted' }));
}

export default watchPanelsSagas;
