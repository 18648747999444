import { forwardRef } from 'react';

import createIcon, { IconProps } from '../createIcon';

const ScriptIcon = forwardRef<SVGSVGElement, IconProps>(({
  ...svgProps
}, ref) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} {...svgProps}>
    <path d="M13.13 19.88c-0.096-0.281-0.151-0.604-0.151-0.94s0.055-0.659 0.157-0.961l-0.006 0.021h-11.62c-0.318 0.411-0.509 0.933-0.51 1.5v0c0 1.381 1.119 2.5 2.5 2.5v0h12.010c-0.090 0-0.18 0-0.27 0-1.032-0.288-1.827-1.086-2.105-2.099l-0.005-0.021z" />
    <path d="M18 4.5c0-0 0-0 0-0.001 0-1.204 0.851-2.209 1.984-2.447l0.016-0.003c-0.149-0.032-0.32-0.050-0.495-0.050-0.002 0-0.003 0-0.005 0h-12c-1.933 0-3.5 1.567-3.5 3.5v0 11.5h11l-0.61 0.8c-0.25 0.33-0.401 0.748-0.401 1.201 0 0.916 0.616 1.689 1.457 1.925l0.014 0.003c0.154 0.043 0.331 0.067 0.514 0.067 0.468 0 0.899-0.161 1.24-0.431l-0.004 0.003c0.476-0.365 0.783-0.931 0.79-1.569v-14.501zM15 13h-8v-1h8zM15 11h-8v-1h8zM15 9h-8v-1h8zM15 7h-8v-1h8z" />
    <path d="M20.5 3c-0.828 0-1.5 0.672-1.5 1.5v0 1.5h3v-1.5c0-0.828-0.672-1.5-1.5-1.5v0z" />
  </svg>
));

export default createIcon(ScriptIcon);
