import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Action from 'lib/jsonApi/Action';

function* createAccount({ payload }: PayloadActionWithGenericPayload<{ email: string }>) {
  const { params, onSuccess } = payload;
  const action: Action | undefined = yield select(getSessionAction('signup'));
  const { success, error } = yield makeRequest.action(action, params);
  if (success) {
    onSuccess?.();
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default createAccount;
