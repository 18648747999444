import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setPublicLabs } from 'rdx/modules/labs/slice';

function* getPublicLabs({ payload: { link = '', params = {}, query = {}, onSuccess = null, onError = null } }) {
  const { success, error, data } = yield* makeRequest.link(link, params, query);
  if (success && data) {
    yield put(setPublicLabs(data));
    if (onSuccess) {
      onSuccess();
    }
  }
  if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default getPublicLabs;
