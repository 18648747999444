import { forwardRef } from 'react';
import createIcon from '../createIcon';

const FillVertical = forwardRef((props, ref) => (
  <svg viewBox="0 0 130 130" ref={ref} {...props}>
    <path
      d="
        M 31,10 V 110
        h -6 l 12,20 l 12,-20 h -6
        V 10 h -12
        M 59,10 V 110
        h -6 l 12,20 l 12,-20 h -6
        V 10 h -12
        M 87,10 V 110
        h -6 l 12,20 l 12,-20 h -6
        V 10 h -12
      "
    />
  </svg>
));

export default createIcon(FillVertical);
