import NavHeader from 'components/NavHeader';
import { useAppSelector } from 'hooks/reduxHooks';
import { NavButton, getActiveNavTab, getNavButtons, getNavTabs } from 'rdx/modules/nav/slice';
import { Tab } from 'components/Tabs';

const MainNavBar = ({ defaultTabs }: { defaultTabs?: Tab[] }) => {
  const navButtons = useAppSelector(getNavButtons);
  const navTabsList = useAppSelector(getNavTabs);
  const activeNavTab = useAppSelector(getActiveNavTab);
  const defaultButtons: NavButton[] = [];

  const buttons = (navButtons?.length) ? navButtons : defaultButtons;
  const tabs = (navTabsList?.length) ? navTabsList : defaultTabs;

  return (
    <NavHeader
      tabs={tabs}
      buttons={buttons}
      isMainNav
      initialTabId={activeNavTab ?? undefined}
      currentTab={activeNavTab ?? undefined}
    />
  );
};

export default MainNavBar;
