import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const DetailsIcon = forwardRef((props, ref) => (
  <svg height="401pt" viewBox="0 0 100.000000 100.000000" ref={ref} {...props}>
    <g
      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path d="M70 520 l0 -480 253 2 c244 3 252 4 252 23 0 19 -8 20 -227 23 l-228
      2 0 435 0 435 175 0 175 0 0 -140 0 -140 140 0 139 0 3 -77 c3 -70 5 -78 23
      -78 18 0 20 8 23 94 l3 95 -143 143 -143 143 -222 0 -223 0 0 -480z m545 300
      l90 -90 -93 0 -92 0 0 90 c0 50 1 90 3 90 1 0 43 -40 92 -90z"
      />
      <path d="M619 462 c-94 -49 -133 -162 -90 -257 49 -108 178 -153 283 -99 l31
      16 58 -58 c56 -55 60 -57 76 -41 16 16 14 20 -41 76 l-58 58 16 31 c54 105 9
      234 -99 283 -58 27 -113 24 -176 -9z m185 -56 c20 -15 41 -44 52 -71 14 -39
      15 -52 5 -87 -16 -53 -56 -93 -109 -109 -35 -10 -48 -9 -87 5 -102 40 -136
      156 -68 237 54 64 142 75 207 25z"
      />
    </g>
  </svg>
));

export default createIcon(DetailsIcon);
