/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useMemo, forwardRef, ComponentPropsWithoutRef, ComponentPropsWithRef } from 'react';
import styled, { DefaultTheme, FlattenInterpolation, ThemeProps, css } from 'styled-components';
import Spinner from 'components/Loading/Spinner/index';
import titleCase from 'lib/utils/string/titleCase';
import { Lock } from '../Icons';

export const BUTTON_SIZE = {
  SMALL: 'small',
  DEFAULT: 'default',
  LARGE: 'large',
};

const sizeProps = {
  [BUTTON_SIZE.SMALL]: {
    height: '35px',
    width: '100px',
    fontSize: '12px',
  },
  [BUTTON_SIZE.DEFAULT]: {
    height: '40px',
    width: '120px',
    fontSize: '13px',
  },
  [BUTTON_SIZE.LARGE]: {
    height: '50px',
    width: '140px',
    fontSize: '14px',
  },
};

const defaultBaseCSS = css`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
`;

const defaultHoverCSS = css`
  filter: brightness(1.1);
`;

const defaultActiveCSS = css`
  filter: brightness(0.9);
`;

const defaultDisabledCSS = css`
  opacity: 0.5;
  filter: brightness(0.9);
`;

interface BaseButtonProps {
  text?: string,
  loading?: boolean,
  size?: 'small' | 'default' | 'large' | {
    height?: string,
    width?: string,
    fontSize?: string,
  },
  baseCSS?: FlattenInterpolation<ThemeProps<DefaultTheme>>,
  hoverCSS?: FlattenInterpolation<ThemeProps<DefaultTheme>>,
  activeCSS?: FlattenInterpolation<ThemeProps<DefaultTheme>>,
  disabledCSS?: FlattenInterpolation<ThemeProps<DefaultTheme>>,
  locked?: boolean
}

export interface ButtonProps extends ComponentPropsWithoutRef<'button'>, BaseButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLInputElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>) => void;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  text = '',
  disabled,
  loading ,
  size = 'default',
  type = 'button',
  baseCSS = defaultBaseCSS,
  hoverCSS = defaultHoverCSS,
  activeCSS = defaultActiveCSS,
  disabledCSS = defaultDisabledCSS,
  children,
  locked,
  ...buttonProps
}, ref) => {
  const { width, height, fontSize } = useMemo(() => {
    let computedSize = sizeProps[BUTTON_SIZE.DEFAULT];
    if (typeof size === 'object') {
      computedSize = {
        ...computedSize,
        ...size,
      };
    } else if (typeof size === 'string') {
      if (sizeProps[size]) {
        computedSize = sizeProps[size];
      }
    }
    return computedSize;
  }, [size]);

  const titleCaseText = titleCase(text);

  const renderInner = () => {
    if (loading && !disabled) {
      return (
        <ButtonContent>
          <div style={{ marginRight: '0.6em' }}>{titleCaseText}</div>
          <Spinner size={20} />
        </ButtonContent>
      );
    }
    if (children) {
      return (
        <ButtonContent>
          {children}
          {locked && <ButtonLock data-testid="locked-button" style={{ marginLeft: '0.4em' }} size="1em" />}
        </ButtonContent>
      );
    }
    return titleCaseText;
  };

  return (
    <ButtonElement
      ref={ref}
      {...{ baseCSS, hoverCSS, activeCSS, disabledCSS, width, height, fontSize, loading, disabled, type }}
      {...buttonProps}
    >
      {renderInner()}
      {locked && <ButtonLock data-testid="locked-button" style={{ marginLeft: '0.4em' }} size="1em" />}
    </ButtonElement>
  );
});

Button.displayName = 'Button';

export default Button;

export interface ButtonElementProps extends BaseButtonProps, ComponentPropsWithRef<'button'> {
  lockedCSS?: FlattenInterpolation<ThemeProps<DefaultTheme>>,
  width?: string,
  height?: string,
  fontSize?: string,
  onClick?: (e: React .MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLInputElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>) => void;
}

const RawButtonElement = forwardRef<HTMLButtonElement, ButtonElementProps>(({
  baseCSS,
  hoverCSS,
  activeCSS,
  disabledCSS,
  lockedCSS,
  width,
  height,
  fontSize,
  loading,
  disabled,
  type,
  ...buttonProps
}, ref) =>
  (
    <button type={type === 'submit' ? 'submit' : 'button'} ref={ref} disabled={disabled || loading} {...buttonProps} />
  ));

RawButtonElement.displayName = "RawButtonElement";

const ButtonElement = styled(RawButtonElement)`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  font-size: ${({ fontSize }) => fontSize};
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 0px;
  cursor: pointer;
  letter-spacing: 1px;
  letter-spacing: 0.4pt;
  white-space: nowrap;
  pointer-events: ${({ disabled, loading }) => ((disabled || loading) ? 'none' : 'auto')};
  opacity: ${({ disabled, loading }) => ((disabled || loading) ? '0.5' : '1')};
  transition: all 200ms ease;

  ${({ baseCSS }) => baseCSS}
  ${({ disabled, loading, disabledCSS }) => ((disabled || loading) ? disabledCSS : '')}
  ${({ locked, lockedCSS }) => (locked ? lockedCSS : '')}

  &:hover {
    ${({ hoverCSS }) => hoverCSS}
  }

  &:active {
    ${({ activeCSS }) => activeCSS}
  }

  &:focus {
    border: 1.5px solid grey;
  }

`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLock = styled(Lock)`
  fill: ${({ theme }) => theme.colors.warning};
`;
