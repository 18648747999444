import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Action from 'lib/jsonApi/Action';
import { setWorkflows, GroupWorkflowsParams, WorkflowsQueryParams } from '../slice';

function* getGroupWorkflows({ payload: { params, onSuccess, onError } }: PayloadActionWithGenericPayload<GroupWorkflowsParams & WorkflowsQueryParams>) {
  const action: Action = yield select(getSessionAction('group_workflows'));

  const { success, data, error } = yield makeRequest.action(action, params);
  if (success && data) {
    if (onSuccess) {
      onSuccess({ data });
    }
    yield put(setWorkflows(data));
  } else if (error) {
    if (onError) {
      onError({ error });
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default getGroupWorkflows;
