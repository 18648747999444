import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const StrikeThroughIcon = forwardRef((props, ref) => (
  <svg ref={ref} {...props} viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd">
    <path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm-6.5 10h13v1h-13v-1z" />
  </svg>
));

export default createIcon(StrikeThroughIcon);
