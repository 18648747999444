import { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';

import BaseModal from 'containers/Modals/BaseModal';
import globals from 'styles/globals';
import Input from 'components/Form/Input';
import PrimaryButton from 'components/Button/PrimaryButton';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { createFlowGptQuery, getFlowGptQueries, requestFlowGptQueries } from 'rdx/modules/flowGptQueries/slice';
import { useLoading } from 'hooks/useLoading';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';

const FlowGptModal = () => {
  const dispatch = useAppDispatch();
  const currentOrg = useAppSelector(getCurrentOrganization);
  const flowGptQueriesLink = currentOrg?.links?.flowgpt_queries;
  const flowGptQueries = useAppSelector(getFlowGptQueries);

  const requestLoading = useLoading({ watchRequests: [createFlowGptQuery.type] });

  const createFlowGptAction = flowGptQueries?.getAction?.('create');

  const [userQuestion, setUserQuestion] = useState('');

  const fetchQueries = useCallback(() => {
    if (flowGptQueriesLink) {
      dispatch(requestFlowGptQueries({
        link: flowGptQueriesLink,
        query: {
          sorting: 'created_at+',
        },
        onSuccess: () => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }),
      }));
    }
  }, [dispatch, flowGptQueriesLink]);

  const handleSendQuery = () => {
    if (createFlowGptAction) {
      dispatch(createFlowGptQuery({
        action: createFlowGptAction,
        params: {
          req: userQuestion,
        },
        onSuccess: () => {
          fetchQueries();
          setUserQuestion('');
        },
        onError: () => {
          setUserQuestion('');
        },
      }));
    }
  };

  useEffect(() => {
    fetchQueries();
  }, [fetchQueries]);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  return (
    <BaseModal dimensions={{ height: '600px', width: '700px' }} showXIcon noFooter>
      <Container>
        <h1>Questions? Ask away!</h1>
        <MessagesContainer id="flow-gpt-messages-container">
          {Array.isArray(flowGptQueries) && flowGptQueries.map((query) => (
            <Fragment key={query.id}>
              <Message isUser>{query.attributes.req}</Message>
              <Message>{query.attributes.resp || 'Oops! I had some trouble answering your question. Please try again.'}</Message>
            </Fragment>
          ))}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <InputContainer>
          <Input
            value={userQuestion}
            onChange={(e) => setUserQuestion(e.target.value)}
            placeholder="Write a message"
            width="500px"
            onKeyDown={(e): void => {
              if (e.code === 'Enter' && userQuestion && createFlowGptAction) {
                handleSendQuery();
              }
            }}
          />
          <PrimaryButton
            onClick={handleSendQuery}
            text="send"
            width="100px"
            tabIndex={0}
            disabled={!requestLoading && (!userQuestion || !createFlowGptAction)}
            loading={requestLoading}
          />
        </InputContainer>
      </Container>
    </BaseModal>
  );
};

export default FlowGptModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  max-height: 400px;
  overflow: auto;
  padding: 5px;
`;

const Message = styled.p<{ isUser?: boolean }>`
  font-size: 14px;
  max-width: 300px;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  color: white;
  background-color: ${globals.colors.primary};

  ${({ isUser }) => isUser && css`
    text-align: right;
    background-color: ${globals.colors.fontDark};
    align-self: flex-end;
  `}
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;
