import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionAction, getSignInRequirements } from 'rdx/modules/auth/slice';
import { setCurrentOrganization, getCurrentOrgSlug } from '../slice';
import Action from 'lib/jsonApi/Action';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* getOrganizationProfile({ payload }: PayloadActionWithGenericPayload & {
  payload: {
    organizationID: string,
    onError: ({ currentOrgSlug }: { currentOrgSlug?: string }) => void
  }
}) {
  if (payload) {
    const { organizationID, onSuccess, onError } = payload;
    const action: Action | undefined = yield select(getSessionAction('organization_profile'));
    if (action) {
      const { success, data, error } = yield makeRequest.action(action, { organization_id: organizationID });
      if (success && data) {
        yield put(setCurrentOrganization(data.unwrap()));
        onSuccess?.();
      } else {
        const currentOrgSlug: string | undefined = yield select(getCurrentOrgSlug);
        const signInRequirements: string[] | undefined = yield select(getSignInRequirements);

        const isForbiddenError = error.statusCode === 403;
        const hasRequirements = signInRequirements?.length;

        if (error.statusCode > 399 && error.statusCode < 500) {
          if (isForbiddenError && !hasRequirements) {
            onError?.({ currentOrgSlug });
          }
        }

        const message = isForbiddenError && !hasRequirements
          ? 'You don\'t have access to requested resource. You\'ve been redirected.'
          : '';

        yield* putErrorActions({ error, message });
      }
    }
  }
  return null;
}

export default getOrganizationProfile;
