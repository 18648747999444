/**
 * Replaces underscores '_' with spaces
 * and capitalizes the first letter of each new word then
 * returns as a single string
 * @param {string} string e.g. default_disk_quota
 * @returns {string} e.g. Default Disk Quota
 */
export default function humanizeString(string?: string) {
  if (typeof string === 'string') {
    return string.split('_').map((one) => one.charAt(0).toUpperCase() + one.slice(1)).join(' ');
  }
  return '';
}
