import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';

type WebsocketSliceState = {
  id: string | null
};

const initialState: WebsocketSliceState = {
  id: null,
};

export const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setWebsocketId: (state, action: PayloadAction<string | number | null>) => {
      if (action.payload === null) {
        state.id = action.payload;
      } else {
        state.id = String(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setWebsocketId,
} = websocketSlice.actions;

// Actions
export {
  setWebsocketId,
};

// Selectors
export const getWebsocketId = (state: RootState) => state.websocket.id;

// Reducer
export default websocketSlice.reducer;
