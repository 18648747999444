function b64DecodeUnicode(str: string) {
  return decodeURIComponent(atob(str).replace(/(.)/g, function (_, p: string) {
    let code = p.charCodeAt(0).toString(16).toUpperCase();
    if (code.length < 2) {
      code = '0' + code;
    }
    return '%' + code;
  }));
}

function base64UrlDecode(str: string) {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw "Illegal base64url string!";
  }
  
  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return atob(output);
  }
}

function jwtDecode(token: string) {
  if (typeof token !== 'string') {
    throw Error('Invalid token specified');
  }

  try {
    return JSON.parse(base64UrlDecode(token.split('.')[1]));
  } catch (e) {
    if (e instanceof Error) {
      throw Error('Invalid token specified: ' + e.message);
    }
  }
}

export { jwtDecode };