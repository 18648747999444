import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* patchOrgUser({ payload: { action, params, onSuccess } }) {
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    yield put(newMessageEvent({ text: 'User Permissions Updated' }));
    onSuccess?.();
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default patchOrgUser;
