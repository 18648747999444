import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionLinks } from 'rdx/modules/auth/slice';
import { setUserPermissions } from 'rdx/modules/users/slice';

function* getUserPermissions() {
  const { profile_permissions } = yield select(getSessionLinks);
  const { success, data, error } = yield* makeRequest.link(profile_permissions);
  if (success) {
    yield put(setUserPermissions(data));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getUserPermissions;
