import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setPanelMatching } from 'rdx/modules/experiments/slice';

function* requestPanelMatchingSaga({ payload: { action, query = {} } }) {
  const { success, error, data } = yield* makeRequest.action(action, query);
  if (success && data) {
    yield put(setPanelMatching(data));
  }
  if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestPanelMatchingSaga;
