import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* deleteWorkflowTemplate({ payload }) {
  const { action } = payload;
  const { success, data, error } = yield* makeRequest.action(action);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Workflow Template Deleted' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteWorkflowTemplate;
