import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* updateExperimentSaga({ payload: { action, params, onSuccess } }) {
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Experiment Updated' }));
    onSuccess?.();
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default updateExperimentSaga;
