import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setDashboardCytometers } from 'rdx/modules/cytometers/slice';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Action from 'lib/jsonApi/Action';

function* requestDashboardCytometersSaga({ payload: { query, onSuccess, onError, params = {} } }: PayloadActionWithGenericPayload) {
  const action: Action = yield select(getSessionAction('organization_cytometers'));
  const orgSlug: string = yield select(getCurrentOrgSlug);

  const { success, data, error } = yield makeRequest.link(
    action.url,
    { ...params, organization_id: orgSlug },
    query,
  );
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
    if (data) {
      yield put(setDashboardCytometers(data));
    }
  } else if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestDashboardCytometersSaga;
