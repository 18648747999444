import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { put, select } from 'redux-saga/effects';

import {  newMessageEvent } from 'rdx/modules/messages/slice';
import { getWebsocketId } from 'rdx/modules/websocket/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* downloadBatchFiles({ payload }: PayloadActionWithGenericPayload) {
  const { action, params: initParams, onSuccess } = payload;
  const socketId: string | null | undefined = yield select(getWebsocketId);
  const params = { ...initParams };
  if (socketId) {
    params.socket_id = socketId
  }
  const { success, error } = yield makeRequest.action(action, params);
  if (success) { 
    yield put(newMessageEvent({ text: 'Zip generation in progress' }));
    onSuccess?.();
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default downloadBatchFiles;
