import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import { setWorkflow, getWorkflow } from '../slice';

function* patchWorkflow({ payload: { action, params, onSuccess } }: PayloadActionWithGenericPayload) {
  const { success, data, error } = yield makeRequest.action(action, params);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Workflow Saved' }));
    yield put(setWorkflow(null));
    if (onSuccess) {
      onSuccess();
    } else {
      yield put(getWorkflow(data?.unwrap()?.links?.self));
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default patchWorkflow;
