import { forwardRef } from 'react';

import createIcon from '../createIcon';

const EditIcon = forwardRef((props, ref) => (
  <svg data-testid="edit-icon-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
    <path d="M3.88 16.17l4 4 9.27-9.31-4-4-9.27 9.31z" />
    <path d="M13.86 6.15l4 4 1.29-1.29-4-4-1.29 1.29z" />
    <path d="M20.91 4.26l-1.17-1.17c-0.362-0.362-0.862-0.587-1.415-0.587s-1.053 0.224-1.415 0.587l-0 0-1.050 1.060 4 4 1.060-1.060c0.359-0.361 0.582-0.86 0.582-1.41 0-0.555-0.226-1.058-0.591-1.42l-0-0z" />
    <path d="M1.47 22.59l5.56-1.86-3.71-3.7-1.85 5.56z" />
  </svg>
));

export default createIcon(EditIcon);
