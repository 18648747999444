import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* linkPublicCytometer({ payload }) {
  const { action, params, onSuccess, onError } = payload;
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  if (success && data) {
    yield put(newMessageEvent({ text: 'Cytometer Successfully Linked' }));
    if (onSuccess) {
      onSuccess();
    }
  }
  return null;
}

export default linkPublicCytometer;
