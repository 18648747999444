import { useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import customPropTypes from 'lib/customPropTypes';
import UserAvatar from 'components/UserAvatar';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { usePrevious } from 'hooks/usePrevious';
import { useInterval } from 'hooks/useInterval';
import { getMessagesByCurrentChannel, getMessagesPaginationByCurrentChannel, requestMessages } from 'rdx/modules/chat/slice';

const PAGINATION_SIZE = 20;

const EST_MESSAGE_HEIGHT = 50;

const LOADING_MESSAGE_HEIGHT = 60;

const Messages = ({ currentChannel, errorState }) => {
  const dispatch = useDispatch();
  const messages = useSelector(getMessagesByCurrentChannel(currentChannel));
  const pagination = useSelector(getMessagesPaginationByCurrentChannel(currentChannel));

  /*
    handle auto scrolling to bottom
    on a new message scroll to bottom
    if this is the first load go immediately (auto)
    else use smooth behavior
  */
  const latestMessageRef = useRef(null);
  const prevMessageRef = useRef(null);
  const prevLastMessageID = usePrevious(messages?.sort(createdSort)[messages.length - 1]?.id);

  const scrollToBottom = useCallback(() => {
    const lastMessageID = messages?.sort(createdSort)[messages.length - 1]?.id;
    const newMessage = lastMessageID !== prevLastMessageID;
    const firstRef = latestMessageRef.current && prevMessageRef.current === null;
    if (latestMessageRef.current && newMessage) {
      prevMessageRef.current = latestMessageRef.current;
      return latestMessageRef.current.scrollIntoView({
        behavior: firstRef ? 'auto' : 'smooth',
        block: 'end',
        inline: 'start',
      });
    }
    return null;
  }, [messages, prevLastMessageID]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  /*
    handle infinite upward scroll to fetch
    paginated messages, debounced to
    only fire once per so often to prevent
    ui lag
  */
  const scrollContainerRef = useRef(null);

  const fetchMore = debounce(({ link, query, channelUUID }) => {
    dispatch(requestMessages({ link, query, channelUUID }));
  }, 150, { leading: true, trailing: false });

  const fetchMoreMessages = useCallback((e) => {
    const isTarget = e.target === scrollContainerRef.current;
    const nearTop = e.target.scrollTop < PAGINATION_SIZE * EST_MESSAGE_HEIGHT;
    const hasMore = pagination?.page < pagination?.pageCount;
    if (isTarget && hasMore && nearTop) {
      fetchMore({
        link: currentChannel.links.messages,
        query: {
          page: pagination.page + 1,
        },
        channelUUID: currentChannel.uuid,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannel.links.messages, currentChannel.uuid, pagination.page, pagination?.pageCount]);

  useEffect(() => {
    const { current } = scrollContainerRef;
    if (current?.addEventListener) {
      current.addEventListener('scroll', fetchMoreMessages);
    }
    return () => {
      if (current?.removeEventListener) {
        current.removeEventListener('scroll', fetchMoreMessages);
      }
    };
  }, [fetchMoreMessages]);

  /*
  if somehow the loader is on screen for
  approaching a second scroll it off to trigger
  load
  */

  const loadingRef = useRef(null);
  const firstMessageRef = useRef(null);

  const hideLoader = useCallback(() => {
    const { current: load } = loadingRef;
    const { current: first } = firstMessageRef;
    const { current: scroll } = scrollContainerRef;
    if (load && first && scroll?.scrollTop < LOADING_MESSAGE_HEIGHT) {
      firstMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }, []);

  useInterval(hideLoader, 750);

  /*
    build messages jsx
    either no messages message or list
    if error state append to bottom of list
  */
  const messagesJSX = useMemo(() => {
    const jsx = [];
    const msgs = messages?.sort(createdSort);
    if (messages.length === 0) {
      const kName = 'no-messages';
      jsx.push(<NoMessages key={kName}>There are no messages on this channel.</NoMessages>);
    } else {
      const hasMore = pagination?.page < pagination?.pageCount;
      if (hasMore) {
        const kName = 'loading-more';
        jsx.push(
          <HasMore ref={loadingRef} key={kName}>
            <p><em>...loading...</em></p>
          </HasMore>
        );
      }
      msgs.forEach((message, index) => {
        const latestMessage = index === (messages.length - 1);
        const firstMessage = index === 0;
        let ref;
        if (firstMessage) {
          ref = firstMessageRef;
        } else if (latestMessage) {
          ref = latestMessageRef;
        }
        const user = currentChannel.users.find((u) => u.id === message.user.id);
        jsx.push(
          <MessageBlock
            key={message.id}
            data-testid="chatMessage"
          >
            <UserBlock>
              <UserAvatar
                user={user}
                showName
                isActive
              />
              <Time>{moment(message.created_at).fromNow()}</Time>
            </UserBlock>
            <Message ref={ref}>
              {message.body}
            </Message>
          </MessageBlock>
        );
      });
    }
    if (errorState) {
      const kName = 'error-message';
      jsx.push(<NoMessages key={kName}>Could not receive messages</NoMessages>);
    }
    return jsx;
  }, [currentChannel.users, errorState, messages, pagination]);

  return (
    <MessagesWrapper ref={scrollContainerRef}>
      {messagesJSX}
    </MessagesWrapper>
  );
};

Messages.propTypes = {
  currentChannel: customPropTypes.resource,
  errorState: PropTypes.oneOf([null, PropTypes.object, PropTypes.bool]),
};

Messages.defaultProps = {
  currentChannel: {},
  errorState: null,
};

export default Messages;

const MessagesWrapper = styled.div`
  overflow: auto;
`;
const NoMessages = styled.div`
  padding: 10px;
  font-size: 10pt;
  color: grey;
`;

const MessageBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  font-size: 10pt;
`;
const UserBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Message = styled.span`
  margin: 5px 0 5px 30px;
  white-space: pre-line;
`;

const Time = styled.span`
  margin-left: 6px;
  font-size: 8pt;
  font-weight: light;
  color: grey;
`;

const HasMore = styled.div`
  width: 100%;
  height: ${LOADING_MESSAGE_HEIGHT}px;
  display: flex;
  padding: 0 0.25rem;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.fontLight};
`;

function createdSort(a, b) {
  const first = new Date(a.created_at);
  const second = new Date(b.created_at);
  return first - second;
}
