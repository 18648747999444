import { forwardRef } from 'react';
import createIcon from '../createIcon';

const Minus = forwardRef((props, ref) => (
  <svg viewBox="0 0 491.86 491.86" ref={ref} {...props}>
    <g>
      <path
        d="M459.313,229.648c0,22.201-17.992,40.199-40.205,40.199H40.181c-11.094,0-21.14-4.498-28.416-11.774   C4.495,250.808,0,240.76,0,229.66c-0.006-22.204,17.992-40.199,40.202-40.193h378.936   C441.333,189.472,459.308,207.456,459.313,229.648z"
      />
    </g>
  </svg>
));

export default createIcon(Minus);
