const toRadians = (degrees) => degrees * (Math.PI / 180);

const polarToCartesian = (centerX, centerY, radius, angle) => {
  const rads = toRadians(angle);
  return {
    x: centerX + (radius * Math.cos(rads)),
    y: centerY + (radius * Math.sin(rads)),
  };
};

const arcPath = (x, y, radius, angle, offsetAngle = 0) => {
  if (angle >= 360) {
    // eslint-disable-next-line no-param-reassign
    angle = 359.99999;
  }
  const start = polarToCartesian(x, y, radius, offsetAngle);
  const end = polarToCartesian(x, y, radius, angle + offsetAngle);
  const largeAngle = angle >= 180 ? '1' : '0';
  return `
    M ${start.x} ${start.y}
    A ${radius} ${radius} 0 ${largeAngle} 1 ${end.x} ${end.y}
  `;
};

export default arcPath;
