import PropTypes from 'prop-types';

import BaseModal from 'containers/Modals/BaseModal';

const ConfirmModal = ({
  onConfirm,
  dangerous,
  alert,
  yesNo,
  rdxTypes,
  forceClick,
  width,
  height,
  isSecondary,
}) => {
  const confirmText = dangerous ? 'confirm' : 'OK';
  return (
    <BaseModal
      dimensions={{
        width,
        height,
      }}
      confirm={{
        text: yesNo ? 'Yes' : confirmText,
        handler: onConfirm,
      }}
      cancel={{ render: () => null }}
      dangerous={dangerous}
      alert={alert}
      forceClick={forceClick}
      rdxTypes={rdxTypes}
      isSecondary={isSecondary}
    >
      The postal code in your profile does not match your country code, either update your postal code in your profile within BD RC or contact flowjoaccountservices@bd.com to update your country code.
    </BaseModal>
  );
};

ConfirmModal.propTypes = {
  onConfirm: PropTypes.func,
  dangerous: PropTypes.bool,
  forceClick: PropTypes.bool,
  yesNo: PropTypes.bool,
  rdxTypes: PropTypes.arrayOf(PropTypes.string),
  alert: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  isSecondary: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  onConfirm: () => { },
  dangerous: false,
  forceClick: true,
  yesNo: false,
  rdxTypes: [],
  alert: false,
  width: '600px',
  height: '250px',
  isSecondary: false,
};

export default ConfirmModal;
