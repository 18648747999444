import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';

type ModalState = {
  isOpen: boolean,
  component: React.FC | null,
};

type ModalsSliceState = {
  primary: ModalState,
  secondary: ModalState
};

const initialState: ModalsSliceState = {
  primary: {
    isOpen: false,
    component: null,
  },
  secondary: {
    isOpen: false,
    component: null,
  },
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    callModal: (state, action: PayloadAction<React.FC | null>) => {
      state.primary.component = action.payload;
      state.primary.isOpen = true;
    },
    setModal: (state, action: PayloadAction<React.FC | null>) => {
      state.primary.component = action.payload;
    },
    openModal: (state) => {
      if (state.primary.component) {
        state.primary.isOpen = true;
      } else if (import.meta.env.DEV) {
        console.warn('Attempted to open a modal with no component set.');
      }
    },
    closeModal: (state) => {
      state.primary.isOpen = false;
    },
    closeAndUnsetModal: (state) => {
      state.primary.isOpen = false;
      state.primary.component = null;
    },
    callSecondaryModal: (state, action: PayloadAction<React.FC | null>) => {
      state.secondary.component = action.payload;
      state.secondary.isOpen = true;
    },
    setSecondaryModal: (state, action: PayloadAction<React.FC | null>) => {
      state.secondary.component = action.payload;
    },
    openSecondaryModal: (state) => {
      if (state.secondary.component) {
        state.secondary.isOpen = true;
      } else if (import.meta.env.DEV) {
        console.warn('Attempted to open a modal with no component set.');
      }
    },
    closeSecondaryModal: (state) => {
      state.secondary.isOpen = false;
    },
    closeAndUnsetSecondaryModal: (state) => {
      state.secondary.isOpen = false;
      state.secondary.component = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  callModal,
  setModal,
  openModal,
  closeModal,
  closeAndUnsetModal,
  callSecondaryModal,
  setSecondaryModal,
  openSecondaryModal,
  closeSecondaryModal,
  closeAndUnsetSecondaryModal,
} = modalsSlice.actions;

// Actions
export {
  callModal,
  setModal,
  openModal,
  closeModal,
  closeAndUnsetModal,
  callSecondaryModal,
  setSecondaryModal,
  openSecondaryModal,
  closeSecondaryModal,
  closeAndUnsetSecondaryModal,
};

// Selectors
export const getModal = (state: RootState) => state.modals.primary.component;
export const getModalIsOpen = (state: RootState) => state.modals.primary.isOpen;
export const getSecondaryModal = (state: RootState) => state.modals.secondary.component;
export const getSecondaryModalIsOpen = (state: RootState) => state.modals.secondary.isOpen;

// Reducer
export default modalsSlice.reducer;
