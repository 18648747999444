import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { requestSession } from 'rdx/modules/auth/slice';

function* updateUserTutorials({ payload: { action, values, onSuccess } }) {
  const { success, data, error } = yield* makeRequest.action(action, values);
  if (success && data) {
    yield put(requestSession());
    if (onSuccess) onSuccess();
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default updateUserTutorials;
