import { takeEvery, takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import requestSaga from 'rdx/api-utils/requestSaga';

import getWorkflowSaga from 'rdx/modules/workflow/sagas/getWorkflow';
import requestOrgWorkflowsSaga from 'rdx/modules/workflow/sagas/requestOrgWorkflowsSaga';
import getGroupWorkflowsSaga from 'rdx/modules/workflow/sagas/getGroupWorkflows';
import requestDashboardWorkflowsSaga from 'rdx/modules/workflow/sagas/requestDashboardWorkflowsSaga';
import getWorkflowNotesSaga from 'rdx/modules/workflow/sagas/getWorkflowNotes';
import createCustomWorkflowSaga from 'rdx/modules/workflow/sagas/createCustomWorkflowSaga';
import createPredefinedWorkflowSaga from 'rdx/modules/workflow/sagas/createPredefinedWorkflowSaga';
import patchWorkflowSaga from 'rdx/modules/workflow/sagas/patchWorkflow';
import duplicateWorkflowSaga from 'rdx/modules/workflow/sagas/duplicateWorkflow';
import deleteWorkflowSaga from 'rdx/modules/workflow/sagas/deleteWorkflow';
import archiveWorkflowSaga from 'rdx/modules/workflow/sagas/archiveWorkflow';
import unarchiveWorkflowSaga from 'rdx/modules/workflow/sagas/unarchiveWorkflow';
import downloadWorkflowReportPdfSaga from 'rdx/modules/workflow/sagas/downloadWorkflowReportPdf';
import { archiveWorkflow, createCustomWorkflow, deleteWorkflow, downloadWorkflowReportPdf, duplicateWorkflow, requestDashboardWorkflows, getGroupWorkflows, requestOrgWorkflows, getWorkflow, getWorkflowByParams, patchWorkflow, publishWorkflow, removeWorkflowFromStudy, repositionProcedures, requestWorkflowNotes, setWorkflow, unarchiveWorkflow, unpublishWorkflow, createPredefinedWorkflow, generateFavaFiles } from '../slice';

function* watchWorkflowSagas() {
  yield trackRequests(takeEvery, getWorkflow.type, getWorkflowSaga);
  yield trackRequests(takeLatest, getWorkflowByParams.type, (rdxAction) => requestSaga({
    rdxAction,
    putData: setWorkflow,
  }));
  yield trackRequests(takeLatest, generateFavaFiles.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeLatest, requestOrgWorkflows.type, requestOrgWorkflowsSaga);
  yield trackRequests(takeLatest, getGroupWorkflows.type, getGroupWorkflowsSaga);
  yield trackRequests(takeEvery, requestDashboardWorkflows.type, requestDashboardWorkflowsSaga);
  yield trackRequests(takeEvery, requestWorkflowNotes.type, getWorkflowNotesSaga);
  yield trackRequests(takeEvery, createCustomWorkflow.type, createCustomWorkflowSaga);
  yield trackRequests(takeEvery, createPredefinedWorkflow.type, createPredefinedWorkflowSaga);
  yield trackRequests(takeEvery, repositionProcedures.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, publishWorkflow.type, (rdxAction) => requestSaga({ rdxAction, message: 'Workflow Published', putData: setWorkflow }));
  yield trackRequests(takeEvery, unpublishWorkflow.type, (rdxAction) => requestSaga({ rdxAction, message: 'Workflow Unpublished', putData: setWorkflow }));
  yield trackRequests(takeEvery, removeWorkflowFromStudy.type, (rdxAction) => requestSaga({ rdxAction, message: 'Workflow removed from Study', putData: setWorkflow }));
  yield trackRequests(takeEvery, patchWorkflow.type, patchWorkflowSaga);
  yield trackRequests(takeEvery, duplicateWorkflow.type, duplicateWorkflowSaga);
  yield trackRequests(takeEvery, deleteWorkflow.type, deleteWorkflowSaga);
  yield trackRequests(takeEvery, archiveWorkflow.type, archiveWorkflowSaga);
  yield trackRequests(takeEvery, unarchiveWorkflow.type, unarchiveWorkflowSaga);
  yield trackRequests(takeEvery, downloadWorkflowReportPdf.type, downloadWorkflowReportPdfSaga);
}

export default watchWorkflowSagas;
