import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const Plate = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg {...props} ref={ref} width="25" height="24" viewBox="0 0 25 24">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.41824 4.7998H21.6484L22.5999 5.75128V19.1998H7.43371L4.22787 17.2763L3.3999 16.4483V7.47869L7.41824 4.7998ZM7.78157 5.9998L4.5999 8.12091V15.829L7.74154 17.3998H20.7999V5.9998H7.78157Z"/>
    <circle cx="8.08" cy="8.2802" r="0.6" strokeWidth="0.96"/>
    <circle cx="11.4399" cy="8.28001" r="0.6" strokeWidth="0.96"/>
    <circle cx="14.8" cy="8.28001" r="0.6" strokeWidth="0.96"/>
    <circle cx="18.1601" cy="8.28001" r="0.6" strokeWidth="0.96"/>
    <circle cx="18.1601" cy="15.0001" r="0.6" strokeWidth="0.96"/>
    <circle cx="18.1601" cy="11.6398" r="0.6" strokeWidth="0.96"/>
    <circle cx="14.8" cy="11.6398" r="0.6" strokeWidth="0.96"/>
    <circle cx="11.4399" cy="11.6398" r="0.6" strokeWidth="0.96"/>
    <circle cx="8.08" cy="11.6398" r="0.6" strokeWidth="0.96"/>
    <circle cx="8.08" cy="15.0001" r="0.6" strokeWidth="0.96"/>
    <circle cx="11.4399" cy="15.0001" r="0.6" strokeWidth="0.96"/>
    <circle cx="14.8" cy="15.0001" r="0.6" strokeWidth="0.96"/>
    <path d="M3.97143 21V21.6H4.57143H5.42857H6.02857V21V19.0286H8H8.6V18.4286V17.5714V16.9714H8H6.02857V15V14.4H5.42857H4.57143H3.97143V15V16.9714H2H1.4V17.5714V18.4286V19.0286H2H3.97143V21Z" stroke="#f7f7f7" strokeWidth="1.2"/>
  </svg>
));

Plate.displayName = 'Plate';

const RefreshIcon = createIcon(Plate);

export default RefreshIcon;
