import { useEffect, useMemo, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';
import MainNavBar from 'containers/Main/MainNavBar';
import MarkdownHtml from 'components/MarkdownHtml';
import { getAuthToken } from 'lib/utils/authHelpers';
import Pagination from 'components/Pagination/index';
import { getEULA, getFAQ, getPrivacyPolicy, getReleaseNotes, getTermsOfUse, getTrademarks, requestEULA, requestFAQ, requestPrivacyPolicy, requestReleaseNotes, requestTermsOfUse, requestTrademarks } from 'rdx/modules/systemDocs/slice';
import PolicyTranslationsLink from 'components/PolicyTranslationsLink';

export const SYSTEM_DOCS_CATEGORIES = {
  PRIVACY: 'policy',
  EULA: 'eula',
  TERMS: 'terms_of_use',
  RELEASE_NOTES: 'release_notes',
  FAQ: 'faq',
  TRADEMARKS: 'trademarks',
};

const SystemDocument = ({ category }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, onQueryChange] = useState({ page: 1 });

  const [selector, action] = useMemo(() => {
    switch (category) {
      case SYSTEM_DOCS_CATEGORIES.PRIVACY:
        return [getPrivacyPolicy, requestPrivacyPolicy];
      case SYSTEM_DOCS_CATEGORIES.EULA:
        return [getEULA, requestEULA];
      case SYSTEM_DOCS_CATEGORIES.TERMS:
        return [getTermsOfUse, requestTermsOfUse];
      case SYSTEM_DOCS_CATEGORIES.RELEASE_NOTES:
        return [getReleaseNotes, requestReleaseNotes];
      case SYSTEM_DOCS_CATEGORIES.FAQ:
        return [getFAQ, requestFAQ];
      case SYSTEM_DOCS_CATEGORIES.TRADEMARKS:
        return [getTrademarks, requestTrademarks];
      default:
        return 'The document category is invalid.';
    }
  }, [category]);

  const isReleaseNotes = useMemo(() => category === SYSTEM_DOCS_CATEGORIES.RELEASE_NOTES, [category]);
  const showPolicyTranslationLink = useMemo(() => {
    if (category === SYSTEM_DOCS_CATEGORIES.EULA || category === SYSTEM_DOCS_CATEGORIES.PRIVACY || category === SYSTEM_DOCS_CATEGORIES.TERMS) {
      return true;
    }
    return false;
  }, [category]);

  const authToken = getAuthToken();
  const resource = useUnwrappedSelector(selector, true);
  const document = isReleaseNotes ? resource[0] : resource;

  useEffect(() => {
    if (isReleaseNotes) {
      dispatch(action({ query }));
    } else {
      dispatch(action());
    }
  }, [action, dispatch, isReleaseNotes, query]);

  const content = document?.content_html || `Error: ${document?.title} loaded with no content`;

  const sanitizeOptions = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: { img: ['src'] },
    allowedSchemes: ['data', 'https'],
  };

  if (category === SYSTEM_DOCS_CATEGORIES.FAQ) {
    sanitizeOptions.allowedAttributes = { ...sanitizeOptions.allowedAttributes, a: ['href'], h1: ['id'] };
  }

  const sanitizedContent = sanitizeHtml(content, sanitizeOptions);

  return (
    <>
      {authToken && <MainNavBar />}
      <Container>
        <DocumentContainer>
          {isReleaseNotes && (
            <Pagination
              pagination={resource?.meta?.pagination ?? {}}
              onPageChange={(newPage) => onQueryChange({ page: newPage })}
            />
          )}
          <Title>{document?.title}</Title>
          <MarkdownHtml dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        </DocumentContainer>
        <Links>
          {showPolicyTranslationLink && (
            <LoginLink>
              <PolicyTranslationsLink />
            </LoginLink>
          )}
          <LoginLink
            onClick={() => history.push(authToken ? '/' : '/login')}
          >
            Back to {authToken ? 'Home' : 'Login'}
          </LoginLink>
        </Links>
      </Container>
    </>
  );
};

export default SystemDocument;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Links = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 600px;
  margin-top: 20px;
`;

export const DocumentContainer = styled.div`
  width: 600px;
  height: 65vh;
  padding: 25px 15px;
  margin-top: 100px;
  overflow: auto;
  border: 1px solid lightgrey;
`;

export const LoginLink = styled.a`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const Title = styled.h2`
  margin-bottom: 15px;
`;
