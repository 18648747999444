import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { ResetPasswordParams, getSessionAction } from 'rdx/modules/auth/slice';
import Action from 'lib/jsonApi/Action';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import { RESET_PW } from 'rdx/modules/messages/constants';

function* resetPassword({ payload }: PayloadActionWithGenericPayload<ResetPasswordParams>) {
  const { onSuccess } = payload;
  const action: Action | undefined = yield select(getSessionAction('reset_password'))
  const { success, error } = yield makeRequest.action(action, payload.params);
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
  } else if (error) {
    yield* putErrorActions({ error, target: RESET_PW });
  }
  return null;
}

export default resetPassword;
