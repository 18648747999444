import { put, select } from 'redux-saga/effects';
import { get } from 'lodash';
import makeRequest from 'rdx/utils/makeRequest.ts';
import putErrorActions from 'rdx/utils/putErrorActions';
import { LOGIN_FORM } from 'rdx/modules/messages/constants';
import { setAuthToken } from 'lib/utils/authHelpers';
import { RequestLoginParams, getSessionAction, requestSession, setUnusedLicensesModalSeen } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Action from 'lib/jsonApi/Action';

function* login({ payload }: PayloadActionWithGenericPayload<RequestLoginParams> & { payload: { rememberMe?: boolean }}) {
  const { rememberMe, onSuccess, params } = payload;
  if (rememberMe) {
    localStorage.setItem('rememberMe', 'true');
  }
  const loginAction: Action | undefined = yield select(getSessionAction('login'));
  const { success, data, error } = yield makeRequest.action(loginAction, params);
  if (success && data) {
    const token = get(data, 'data.attributes.token');
    setAuthToken(token);
    yield put(setUnusedLicensesModalSeen(false));
    yield put(requestSession());
    onSuccess?.();
  } else {
    yield* putErrorActions({ error, target: LOGIN_FORM });
  }
  return null;
}

export default login;
