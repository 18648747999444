import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setReagents } from 'rdx/modules/reagents/slice';

function* getReagents({ payload }) {
  const { action, params } = payload;
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (error) {
    yield* putErrorActions({ error });
  }
  if (success && data) {
    yield put(setReagents(data));
  }
  return null;
}

export default getReagents;
