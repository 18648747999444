import makeRequest from 'rdx/utils/makeRequest';
import { put } from 'redux-saga/effects';
import downloadUrl from 'lib/utils/downloadUrl';
import { newErrorEvent } from 'rdx/modules/messages/slice';

function* downloadCytometerProfilePdf({ payload: { action, params } }) {
  const { error, success, data } = yield* makeRequest.action(action, params);
  if (success && data) {
    downloadUrl(data?.unwrap()?.presigned_pdf_url);
  }
  if (error) {
    yield put(newErrorEvent({ text: 'Error downloading cytometer profile PDF' }));
  }
  return null;
}

export default downloadCytometerProfilePdf;
