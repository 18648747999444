import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';
import ResourceList from 'lib/jsonApi/ResourceList';

type WspDataState = ResourceList | null;

const initialState: WspDataState = null as WspDataState;

const requestWspData = createAction('requestWspData');

export const wspDataSlice = createSlice({
  name: 'wspData',
  initialState,
  reducers: {
    setWspData: (_, action: PayloadAction<WspDataState>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setWspData,
} = wspDataSlice.actions;

// Actions
export {
  setWspData,
  requestWspData,
};

// Selectors
export const getWspData = (state: RootState) => state.wspData;

// Reducer
export default wspDataSlice.reducer;
