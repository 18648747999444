import { forwardRef } from 'react';
import createIcon from '../createIcon';

const Power = forwardRef((props, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path d="M256.026,0c-24.816,0-45.004,20.188-45.004,45.004v181.016c0,24.816,20.188,45.004,45.004,45.004
        s45.004-20.188,45.004-45.004V45.004C301.03,20.188,280.842,0,256.026,0z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M406.625,118.959c-18.939-17.083-46.502-15.14-63.041,1.873c-16.632,17.109-17.917,46.086,3.153,65.296
      c33.44,30.395,50.343,76.459,42.336,122.928c-10.868,63.067-65.717,112.767-133.05,112.915
      c-68.971,0.152-121.809-50.77-132.708-110.617c-8.497-46.747,7.179-93.553,41.972-125.197c21.01-19.127,19.913-48.232,3.234-65.36
      c-16.567-17.013-44.295-18.851-63.4-1.56c-52.909,47.923-80.527,118.769-72.843,190.58C44.496,423.995,140.9,512,256.553,512
      c114.326,0,207.934-88.216,222.368-194.743C488.985,243.027,461.957,168.899,406.625,118.959z"
        />
      </g>
    </g>
  </svg>
));

export default createIcon(Power);
