import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getTrialsSaga from 'rdx/modules/trials/sagas/getTrials';
import pauseTrialSaga from 'rdx/modules/trials/sagas/pauseTrial';
import saveTrialSaga from 'rdx/modules/trials/sagas/saveTrial';
import resumeTrialSaga from 'rdx/modules/trials/sagas/resumeTrial';
import divaExportSaga from 'rdx/modules/trials/sagas/divaExport';
import shoppingListSaga from 'rdx/modules/trials/sagas/shoppingList';
import addToBDBCartSaga from 'rdx/modules/trials/sagas/addToBDBCart';
import requestSaga from 'rdx/api-utils/requestSaga';
import addToBDBShoppingListSaga from './addToBDBShoppingList';
import { abandonTrial, completeTrial, divaExport, pauseTrial, postTrial, redoTrial, requestTrials, resumeTrial, saveTrial, updateTrial, shoppingList, addToBDBCart, fetchTrials, setTrials, addToBDBShoppingList, processSequencingFiles } from '../slice';

function* watchTrialsSagas() {
  yield trackRequests(takeEvery, requestTrials.type, getTrialsSaga);
  yield trackRequests(takeEvery, postTrial.type, (rdxAction) => requestSaga({ rdxAction, message: 'Step Started', putData: updateTrial }));
  yield trackRequests(takeEvery, pauseTrial.type, pauseTrialSaga);
  yield trackRequests(takeEvery, redoTrial.type, (rdxAction) => requestSaga({ rdxAction, message: 'Step Reset', putData: updateTrial }));
  yield trackRequests(takeEvery, saveTrial.type, saveTrialSaga);
  yield trackRequests(takeEvery, completeTrial.type, (rdxAction) => requestSaga({ rdxAction, message: 'Step Completed', putData: updateTrial }));
  yield trackRequests(takeEvery, abandonTrial.type, (rdxAction) => requestSaga({ rdxAction, message: 'Procedure Reset', putData: updateTrial }));
  yield trackRequests(takeEvery, resumeTrial.type, resumeTrialSaga);
  yield trackRequests(takeEvery, divaExport.type, divaExportSaga);
  yield trackRequests(takeEvery, shoppingList.type, shoppingListSaga);
  yield trackRequests(takeEvery, addToBDBCart.type, addToBDBCartSaga);
  yield trackRequests(takeEvery, addToBDBShoppingList.type, addToBDBShoppingListSaga);
  yield trackRequests(takeEvery, fetchTrials.type, (rdxAction) => requestSaga({
    rdxAction,
    putData: setTrials,
  }));
  yield trackRequests(takeEvery, processSequencingFiles.type, (rdxAction) => requestSaga({
    rdxAction,
    message: 'Processing Sequencing Files: this action is a long running task and may take a while to complete.',
  }));
}

export default watchTrialsSagas;
