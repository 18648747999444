import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestSession } from 'rdx/modules/auth/slice';

function* attachLicenseToOrganization({ payload: { action, params, onSuccess } }) {
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    yield put(requestSession());
    yield put(newMessageEvent({ text: 'License successfully applied to organization' }));
    onSuccess?.();
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default attachLicenseToOrganization;
