function doMathOnString(value: string, func: (x: number) => number) {
  const number = parseFloat(value);
  const array = value.split(/\d/);
  const units = array[array.length - 1];

  const newNumber = func(number);

  return `${newNumber}${units}`;
}

function addToStyleString(string: string, number: number) {
  return doMathOnString(string, (x) => (x + number));
}

function subtractFromStyleString(string: string, number: number) {
  return doMathOnString(string, (x) => (x - number));
}

function multiplyStyleString(string: string, number: number) {
  return doMathOnString(string, (x) => (x * number));
}

function divideStyleString(string: string, number: number) {
  return doMathOnString(string, (x) => (x / number));
}

function valueOrMax(string: string, number: number) {
  return doMathOnString(string, (x) => ((x >= number) ? number : x));
}

export {
  doMathOnString, addToStyleString, subtractFromStyleString, multiplyStyleString, divideStyleString, valueOrMax,
};
