import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getOrganizationGroupsSaga from 'rdx/modules/organization/sagas/getOrganizationGroups';
import requestJoinOrganizationGroupsSaga from 'rdx/modules/organization/sagas/requestJoinOrganizationGroups';
import getOrganizationProjectsSaga from 'rdx/modules/organization/sagas/getOrganizationProjects';
import getOrganizationWorkflowsSaga from 'rdx/modules/organization/sagas/getOrganizationWorkflows';
import getOrganizationLabsSaga from 'rdx/modules/organization/sagas/getOrganizationLabs';
import getOrganizationProfileSaga from 'rdx/modules/organization/sagas/getOrganizationProfile';
import updateOrganizationProfileSaga from 'rdx/modules/organization/sagas/updateOrganizationProfile';
import getMoreOrganizationGroupsSaga from 'rdx/modules/organization/sagas/getMoreOrganizationGroups';
import getOrganizationSettingsSaga from 'rdx/modules/organization/sagas/getOrganizationSettings';
import attachLicenseToOrganizationSaga from 'rdx/modules/organization/sagas/attachLicenseToOrganization';
import surrenderOrgSaga from 'rdx/modules/organization/sagas/surrenderOrg';
import requestSaga from 'rdx/api-utils/requestSaga';
import { requestOrganizationGroups, requestJoinOrganizationGroup, requestOrganizationProjects, requestOrganizationWorkflows, requestOrganizationLabs, requestOrganizationProfile, updateOrganizationProfile, requestMoreOrganizationGroups, surrenderOrganization, createOrganization, postSeed, fetchCSV, attachLicenseToOrganization, requestOrganizationSettings, updateOrganizationSettings, createTrialLicenseForOrg } from '../slice';

function* watchOrganizationSagas() {
  yield trackRequests(takeEvery, requestOrganizationGroups.type, getOrganizationGroupsSaga);
  yield trackRequests(takeEvery, requestJoinOrganizationGroup.type, requestJoinOrganizationGroupsSaga);
  yield trackRequests(takeEvery, requestOrganizationProjects.type, getOrganizationProjectsSaga);
  yield trackRequests(takeEvery, requestOrganizationWorkflows.type, getOrganizationWorkflowsSaga);
  yield trackRequests(takeEvery, requestOrganizationLabs.type, getOrganizationLabsSaga);
  yield trackRequests(takeEvery, requestOrganizationProfile.type, getOrganizationProfileSaga);
  yield trackRequests(takeEvery, updateOrganizationProfile.type, updateOrganizationProfileSaga);
  yield trackRequests(takeEvery, requestMoreOrganizationGroups.type, getMoreOrganizationGroupsSaga);
  yield trackRequests(takeEvery, surrenderOrganization.type, surrenderOrgSaga);
  yield trackRequests(takeEvery, createOrganization.type, (rdxAction) => requestSaga({ rdxAction, message: `Created Organization ${rdxAction.payload.params.name}` }));
  yield trackRequests(takeEvery, postSeed.type, (rdxAction) => requestSaga({ rdxAction, message: 'Resources Created' }));
  yield trackRequests(takeEvery, fetchCSV.type, (rdxAction) => requestSaga({ rdxAction, message: 'Report Downloading' }));
  yield trackRequests(takeEvery, attachLicenseToOrganization.type, attachLicenseToOrganizationSaga);
  yield trackRequests(takeEvery, requestOrganizationSettings.type, getOrganizationSettingsSaga);
  yield trackRequests(takeEvery, updateOrganizationSettings.type, (rdxAction) => requestSaga({ rdxAction }));
  yield trackRequests(takeEvery, createTrialLicenseForOrg.type, (rdxAction) => requestSaga({ rdxAction }));
}

export default watchOrganizationSagas;
