import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const NotificationBell = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg ref={ref} {...props} width="24" height="24" viewBox="0 0 24 24">
    <path d="M21.71 20l-0.85-0.85c-1.149-1.14-1.86-2.72-1.86-4.465 0-0.005 0-0.010 0-0.015v0.001-2.67c0-0.002 0-0.004 0-0.005 0-2.776-1.616-5.174-3.958-6.306l-0.042-0.018c0-1.657-1.343-3-3-3s-3 1.343-3 3v0c-2.384 1.15-4 3.549-4 6.325 0 0.002 0 0.004 0 0.006v-0 2.67c0 0.007 0 0.016 0 0.025 0 1.741-0.707 3.316-1.85 4.455l-0 0-0.86 0.85h6.89c0.431 1.163 1.53 1.976 2.82 1.976s2.389-0.814 2.813-1.956l0.007-0.021z"></path>
  </svg>
));

NotificationBell.displayName = 'NotificationBell';

const NotificationBellIcon = createIcon(NotificationBell);

export default NotificationBellIcon;