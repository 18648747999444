import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import globals from 'styles/globals';

import convertByteString, { GB } from 'lib/utils/string/convertByteString';
import getLicense from 'lib/license';
import { useModal } from 'hooks/useModal';
import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';

import UnusedLicensesModal from 'containers/Modals/UnusedLicensesModal';
import { useOrganizationUsersOptions } from 'hooks/useOrganizationUsersOptions';
import { BtnSize } from 'components/DeprecatedButton/index';
import HollowButton from 'components/DeprecatedButton/HollowButton/index';
import DeprecatedDangerButton from 'components/DeprecatedButton/DeprecatedDangerButton/index';
import { useLicenses } from 'components/CreateOrgModal/useLicenses';
import SelectLicenseTable from 'components/CreateOrgModal/SelectLicenseTable';
import Combobox from 'components/Combobox/index';
import { surrenderOrganization, getCurrentOrganization, getIsOrgOwner, getOrganizationScopes } from 'rdx/modules/organization/slice';
import { getOrgUsersRaw, getUser } from 'rdx/modules/users/slice';
import OrganizationStorageDetails from './OrganizationStorageDetails';

const { fonts, colors } = globals;

const LicenseTab = ({ storageView, setStorageView, licenseView, setLicenseView }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setModal, closeModal, callModal } = useModal();

  const organization = useSelector(getCurrentOrganization);
  const updateAction = useMemo(() => organization?.getAction('update'), [organization]);
  const viewOnly = !updateAction;
  const user = useSelector(getUser);
  const users = useUnwrappedSelector(getOrgUsersRaw);
  const licenses = useLicenses();

  const [surrenderOrg, setSurrenderOrg] = useState(false);
  const [confirm, setConfirm] = useState('');
  const [selectedUser, setSelectedUser] = useState('');

  const isOrgOwner = useSelector(getIsOrgOwner);
  const currentUserOrgScopes = useSelector(getOrganizationScopes);

  const [
    unusedLicenses,
    attachAction,
    surrenderAction,
    orgLicenses,
    orgMostExpensiveLicense,
    totalConfirmedUsers,
    totalPendingUsers,
    storageLimit,
    seatsLimit,
    activeWorkflows,
  ] = useMemo(() => [
    organization?.getRel('unused_licenses'),
    organization?.getAction('attach_license'),
    organization?.getAction('change_owner'),
    organization?.licenses,
    getLicense(organization?.licenses),
    organization?.total_confirmed_users,
    organization?.total_pending_users,
    organization?.storage_limit,
    organization?.seats_limit,
    organization?.active_workflows,
  ], [organization]);

  const isTrial = orgMostExpensiveLicense?.attributes?.pn?.includes('TRIAL');
  const expiresAt = orgMostExpensiveLicense?.attributes?.expires_at;
  const startedOn = moment(orgMostExpensiveLicense?.attributes?.created_at).format('LLL');
  const expiresIn = moment(expiresAt).fromNow();

  const {
    userDropdownOptions,
    setOwnerSearch,
  } = useOrganizationUsersOptions({});

  const workflows = (orgLicenses)
    ? Math.max(...orgLicenses?.map((l) => l?.attributes?.meta?.max_workflows) ?? [])
    : orgMostExpensiveLicense?.attributes?.meta?.max_workflows;

  // show storage details
  if (storageView) {
    return (
      <OrganizationStorageDetails
        licenseLimit={storageLimit * GB}
        onCancel={() => setStorageView(false)}
      />
    );
  }

  if (confirm) {
    const transferTo = users.find((u) => u.attributes.user_id === selectedUser)?.included?.[0];
    const fullName = `${transferTo?.attributes?.first_name} ${transferTo?.attributes?.last_name} - ${transferTo?.attributes?.email}`;
    const confirmMsg = `Are you sure you wish to make ${fullName} the administrator for ${organization.name}?  This action is irreversible.`;

    const transferOwnership = () => {
      dispatch(surrenderOrganization({
        action: surrenderAction,
        params: { new_owner_id: selectedUser },
        onSuccess: ({ routeTo }) => {
          setModal(null);
          closeModal();
          history.push(routeTo);
        },
      }));
    };

    return (
      <VerticalFlexContainer>
        <ConfirmTitle>Warning:</ConfirmTitle>
        <ConfirmMessage>{confirmMsg}</ConfirmMessage>
        <ButtonContainer>
          <DeprecatedDangerButton
            data-testid="transfer-confirm"
            text="confirm"
            onClick={() => transferOwnership()}
          />
          <HollowButton
            text="cancel"
            onClick={() => setConfirm(false)}
          />
        </ButtonContainer>
      </VerticalFlexContainer>
    );
  }

  if (surrenderOrg) {
    return (
      <VerticalFlexContainer>
        <Title>Change Organization Administrator</Title>
        <Subtitle>
          Groups owned by the current administrator will also be transferred to the new administrator.
        </Subtitle>
        <HorizontalContainerWithMargin>
          <Combobox
            width="300px"
            placeholder="Select another organization user"
            options={userDropdownOptions}
            value={selectedUser}
            onChange={(value) => {
              setSelectedUser(value);
            }}
            handleInputChange={setOwnerSearch}
          />
        </HorizontalContainerWithMargin>
        <ButtonContainer>
          <DeprecatedDangerButton
            data-testid="transfer-submit"
            text="transfer"
            onClick={() => setConfirm(true)}
            disabled={!selectedUser}
          />
          <HollowButton
            data-testid="transfer-cancel"
            text="cancel"
            onClick={() => setSurrenderOrg(false)}
            style={{ marginLeft: '10px' }}
          />
        </ButtonContainer>
      </VerticalFlexContainer>
    );
  }

  if (licenseView) {
    return (
      <VerticalFlexContainer>
        <SelectLicenseTable
          currentLicenses={orgLicenses}
          currentBestLicense={orgMostExpensiveLicense}
          onCancel={() => setLicenseView(false)}
        />
      </VerticalFlexContainer>
    );
  }

  // no license yet (new organization)
  if (!orgMostExpensiveLicense) {
    return (
      <VerticalFlexContainer>
        <Title>License Information</Title>
        <HorizontalContainerWithMargin>
          <Left>
            <Label>TYPE</Label>
            <Values>NONE</Values>
          </Left>
          <Right>
            <HollowButton
              onClick={() => setLicenseView(true)}
              size={BtnSize.NARROW}
              text="purchase"
              disabled={!licenses}
            />
          </Right>
        </HorizontalContainerWithMargin>
      </VerticalFlexContainer>
    );
  }

  const diskUsage = organization?.total_size ? convertByteString(organization?.total_size) : 'unavailable';

  // license details render
  return (
    <VerticalFlexContainer>
      <Title>License Information</Title>
      <HorizontalContainerWithMargin>
        <Left>
          <Label>{orgLicenses?.length > 1 ? 'TYPES' : 'TYPE'}</Label>
          {orgLicenses.map((license) => {
            return (
              <Values key={license.id}>{`${license.attributes.name}`}</Values>
            )
          })}
        </Left>
        {!viewOnly && (
          <Right>
            <HollowButton
              data-testid="upgrade-button"
              onClick={() => setLicenseView(true)}
              size={BtnSize.NARROW}
              text="upgrade"
            />
          </Right>
        )}
      </HorizontalContainerWithMargin>
      {isTrial && (
        <HorizontalContainerWithMargin>
          <Left>
            <Label>TRIAL STARTED ON</Label>
            <Values>{startedOn} (Expires {expiresIn})</Values>
            {totalPendingUsers > 0 && (
              <SmallValues>{`${totalPendingUsers} out of ${totalConfirmedUsers} are pending`}</SmallValues>
            )}
          </Left>
        </HorizontalContainerWithMargin>
      )}
      {!!seatsLimit && (
        <HorizontalContainerWithMargin>
          <Left>
            <Label>OCCUPIED SEATS</Label>
            <Values>{`${totalConfirmedUsers} out of ${seatsLimit} Occupied`}</Values>
            {totalPendingUsers > 0 && (
              <SmallValues>{`${totalPendingUsers} out of ${totalConfirmedUsers} are pending`}</SmallValues>
            )}
          </Left>
        </HorizontalContainerWithMargin>
      )}
      {!!workflows && (
        <HorizontalContainerWithMargin>
          <Left>
            <Label>ACTIVE WORKFLOWS</Label>
            <Values>{`${activeWorkflows} out of ${workflows === 1000000 ? 'Unlimited' : workflows}`}</Values>
          </Left>
        </HorizontalContainerWithMargin>
      )}
      {!!storageLimit && (
        <HorizontalContainerWithMargin>
          <Left>
            <Label>USED STORAGE</Label>
            <Values>{`${diskUsage} out of ${storageLimit} GB`}</Values>
          </Left>
          {(currentUserOrgScopes?.includes('files') || isOrgOwner) && (
            <Right>
              <HollowButton
                onClick={() => { setStorageView(true); }}
                size={BtnSize.NARROW}
                text="details"
              />
            </Right>
          )}
        </HorizontalContainerWithMargin>
      )}
      {surrenderAction && (
        <HorizontalContainerWithMargin>
          <Left>
            <Label>ORGANIZATION ADMIN</Label>
            <Values>{user.email}</Values>
          </Left>
          {!viewOnly && (
            <Right>
              <HollowButton
                data-testid="transfer-button"
                onClick={() => setSurrenderOrg(true)}
                size={BtnSize.NARROW}
                text="transfer"
              />
            </Right>
          )}
        </HorizontalContainerWithMargin>
      )}
      {!!unusedLicenses?.length && attachAction && (
        <HorizontalContainerWithMargin>
          <Left>
            <Label>UNAPPLIED LICENSES</Label>
            <Values>You have{unusedLicenses?.length > 1 ? ` ${unusedLicenses?.length} unapplied licenses.` : ' an unapplied license.'}</Values>
          </Left>
          <Right>
            <HollowButton
              data-testid="transfer-button"
              onClick={() => callModal(<UnusedLicensesModal key={uuid()} />)}
              size={BtnSize.NARROW}
              text="Apply"
            />
          </Right>
        </HorizontalContainerWithMargin>
      )}
    </VerticalFlexContainer>
  );
};

LicenseTab.propTypes = {
  storageView: PropTypes.bool.isRequired,
  setStorageView: PropTypes.func.isRequired,
  licenseView: PropTypes.bool.isRequired,
  setLicenseView: PropTypes.func.isRequired,
};

export default LicenseTab;

const VerticalFlexContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const HorizontalContainerWithMargin = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
`;

const Title = styled.h1`
  flex: 1;
`;

const Subtitle = styled.p`
  flex: 1;
  margin: 10px 0;
  font-weight: 300;
`;

const ConfirmTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.errorText};
`;

const ConfirmMessage = styled.p`
  color: ${({ theme }) => theme.colors.errorText};
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Label = styled.p`
  font: ${fonts.flag};
  padding: 5px 0;
  font-weight: bolder;
  color: ${({ highlighted }) => (highlighted ? colors.red : colors.fontDark)};
`;

const Values = styled.p`
  font-weight: 300;
  color: ${({ highlighted }) => (highlighted ? colors.red : colors.fontMedium)};
`;
const SmallValues = styled.p`
  font-weight: 300;
  color: ${({ highlighted }) => (highlighted ? colors.red : colors.fontMedium)};
  font-size: 11px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 190px;
  display: flex;
  justify-content: flex-end;
`;
