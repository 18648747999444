import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setExperiment } from 'rdx/modules/experiments/slice';

function* requestExperimentSaga({ payload: { link, params = {}, query = {}, onSuccess } }) {
  const { success, error, data } = yield* makeRequest.link(link, params, query);
  if (success && data) {
    yield put(setExperiment(data));
    onSuccess?.();
  }
  if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestExperimentSaga;
