import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import globals from 'styles/globals';

import BaseModal from 'containers/Modals/BaseModal';
import Loading from 'components/Loading';
import NavHeader from 'components/NavHeader';
import { requestOrganizationProfile, updateOrganizationProfile, getCurrentOrganization } from 'rdx/modules/organization/slice';
import SummaryTab from './SummaryTab';
import LicenseTab from './LicenseTab';
import ReportsTab from './ReportsTab';
import SettingsTab from './SettingsTab';
import { useModal } from 'hooks/useModal';

const { colors } = globals;

const OrgProfileModal = () => {
  const dispatch = useDispatch();
  const { closeModal, setModal } = useModal();

  const onCancel = () => {
    closeModal();
    setModal(null);
  };

  const organization = useSelector(getCurrentOrganization);
  const [storageView, setStorageView] = useState(false);
  const [licenseView, setLicenseView] = useState(false);
  const updateAction = useMemo(() => organization?.getAction('update'), [organization]);
  const viewOnly = !updateAction;

  const tabs = [
    { text: 'SUMMARY', id: 'organization-summary' },
    { text: 'LICENSE INFORMATION', id: 'organization-license-tab' },
    ...(viewOnly ? [] : [{ text: 'REPORTS', id: 'organization-reports' }]),
    { text: 'SETTINGS', id: 'organization-settings' },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    setStorageView(false);
    setLicenseView(false);
  };

  const getDimensions = () => {
    switch (activeTab) {
      case 'organization-summary':
        return { width: '700px', height: '630px' };
      case 'organization-license-tab':
        if (storageView) return { width: '1100px', height: '700px' };
        if (licenseView) return { width: '1100px', height: '700px' };
        return { width: '700px', height: '630px' };
      case 'organization-reports':
        return { width: '700px', height: '350px' };
      case 'organization-settings':
        return { width: '700px', height: '475px' };
      default:
        return { width: '700px', height: '400px' };
    }
  };

  useEffect(() => {
    if (organization.attributes.slug) {
      dispatch(requestOrganizationProfile({ organizationID: organization.attributes.slug }));
    }
  }, [dispatch, organization.attributes.slug]);

  return (
    <BaseModal
      dimensions={getDimensions()}
      styles={{
        padding: '0px',
      }}
      noFooter
      allowScrollingX
      allowScrollingY
    >
      <Wrapper>
        <NavHeader
          title={organization.name}
          showLogo
          tabs={tabs}
          onTabChange={handleTabChange}
          isModal
          initialTabId={tabs[0].id}
          onClose={onCancel}
        />
        <Content>
          <Loading watchRequests={[requestOrganizationProfile.type, updateOrganizationProfile.type]}>
            {(() => {
              switch (activeTab) {
                case 'organization-summary':
                  return <SummaryTab />;
                case 'organization-license-tab':
                  return (
                    <LicenseTab
                      storageView={storageView}
                      setStorageView={setStorageView}
                      licenseView={licenseView}
                      setLicenseView={setLicenseView}
                    />
                  );
                case 'organization-reports':
                  return <ReportsTab />;
                case 'organization-settings':
                  return <SettingsTab />;
                default:
                  return null;
              }
            })()}
          </Loading>
        </Content>
      </Wrapper>
    </BaseModal>
  );
};

export default OrgProfileModal;

const Wrapper = styled.div`
  background-color: ${colors.lightGrey};
  width: 100%;
  height: 100%;
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 155px);
  max-height: calc(100% - 135px);
  overflow: hidden;
`;
