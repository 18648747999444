import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const ClockIcon = forwardRef((props, ref) => (
  <svg
    ref={ref}
    viewBox="0 0 50 50"
    width="400px"
    fill="white"
    stroke="black"
    strokeWidth="3"
    strokeLinecap="round"
    {...props}
  >
    <circle cx="25" cy="25" r="23" />
    <line x1="0" y1="0" x2="9" y2="0" strokeWidth="3" transform="translate(25, 25) rotate(45)" />
    <line x1="0" y1="0" x2="13" y2="0" strokeWidth="3" transform="translate(25, 25) rotate(-90)" />
  </svg>
));

export default createIcon(ClockIcon);
