import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import requestSaga from 'rdx/api-utils/requestSaga';
import { requestFlowGptQueries, setFlowGptQueries, createFlowGptQuery } from 'rdx/modules/flowGptQueries/slice';

function* watchFlowGptQueriesSagas() {
  yield trackRequests(takeLatest, requestFlowGptQueries.type, (rdxAction) => requestSaga({ rdxAction, putData: setFlowGptQueries }));
  yield trackRequests(takeLatest, createFlowGptQuery.type, (rdxAction) => requestSaga({ rdxAction }));
}

export default watchFlowGptQueriesSagas;
