import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import globals from 'styles/globals';

import BaseModal from 'containers/Modals/BaseModal';
import NavHeader from 'components/NavHeader';
import { getUser, requestUser, updateUser } from 'rdx/modules/users/slice';
import ProfileTab from './ProfileTab';
import NotificationSettingsTab from './NotificationSettingsTab';
import PermissionsTab from './PermissionsTab';

const myProfile = { text: 'MY PROFILE', id: 'my-profile' };
const notificationSettings = { text: 'NOTIFICATION SETTINGS', id: 'notification-settings' };
const permissions = { text: 'PERMISSIONS', id: 'permissions' };

const ProfileModal = ({ onCancel }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const tabs = [myProfile, notificationSettings, permissions];
  const isOptedIn = !!user.attributes.marcom_opt_in_at;
  const isCountryUS = user.attributes.country_code === 'US';
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const updateAction = user.getAction('update');
  const revokeAction = user.getAction('revoke_consent');
  const optInAction = user?.getAction?.('marcom_opt_in');

  useEffect(() => {
    dispatch(requestUser());
  }, [dispatch]);

  const handleTabChange = (tabId) => setActiveTab(tabId);

  const getDimensions = () => {
    switch (activeTab) {
    case 'my-profile':
      return { width: '700px', height: '700px' };
    case 'notification-settings':
      return { width: '700px', height: '700px' };
    case 'permissions':
      return { width: '900px', height: '700px' };
    case 'tech-support':
      return { width: '700px', height: '630px' };
    default:
      return { width: '60px', height: '60px' };
    }
  };

  const getTitle = () => {
    if (user && user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}'s Profile`;
    }
    return 'User Profile';
  };

  const doSubmit = (values) => {
    dispatch(updateUser({ action: updateAction, values, onSuccess: onCancel }));
  };

  const renderBody = () => {
    switch (activeTab) {
    case 'my-profile':
      return <ProfileTab {...{ doSubmit, user, onCancel, revokeAction, optInAction, isOptedIn, isCountryUS }} />;
    case 'notification-settings':
      return <NotificationSettingsTab {...{ doSubmit, user, onCancel, action: updateAction }} />;
    case 'permissions':
      return <PermissionsTab {...{ onCancel }} />;
    default:
      return null;
    }
  };

  return (
    <BaseModal
      dimensions={getDimensions()}
      styles={{
        padding: '0px',
      }}
      noFooter
      allowScrollingX
      allowScrollingY
    >
      <ModalWithHeaderWrapper>
        <NavHeader
          title={getTitle()}
          tabs={tabs}
          onTabChange={handleTabChange}
          isModal
          onClose={onCancel}
        />
        {renderBody()}
      </ModalWithHeaderWrapper>
    </BaseModal>
  );
};

ProfileModal.propTypes = {
  onCancel: PropTypes.func,
};

ProfileModal.defaultProps = {
  onCancel: () => { },
};

export default ProfileModal;

const { colors } = globals;

const ModalWithHeaderWrapper = styled.div`
  background-color: ${colors.lightGrey};
  flex: 1 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
`;
