import { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import globals from 'styles/globals';
import { subtractFromStyleString } from 'lib/utils/styles/doMathOnStyleString';

import PrimaryButton from 'components/Button/PrimaryButton';
import { useLoading } from 'hooks/useLoading';
import FormikInput from 'components/Formik/FormikInput/index';
import Dropdown from 'components/Dropdown/index';
import { updateOrganizationProfile, getCurrentOrganization } from 'rdx/modules/organization/slice';

const { fonts, colors, layout } = globals;

const SummaryTab = () => {
  const dispatch = useDispatch();
  const organization = useSelector(getCurrentOrganization);
  const updateAction = useMemo(() => organization?.getAction('update'), [organization]);
  const viewOnly = !updateAction;
  const countryOptions = updateAction?.field('country_code').options.map(({ value, label }) => ({
    value,
    display: label,
  })) || [];

  const isLoading = useLoading({ watchRequests: [updateOrganizationProfile.type] });

  const sortedCountries = countryOptions.sort((a, b) => a.display.localeCompare(b.display)).sort((a, b) => {
    if (a.value === 'US') {
      return -1;
    } if (b.value === 'US') {
      return 1;
    } if (a > b) {
      return -1;
    } return 1;
  });

  const initialValues = useMemo(() => ({
    address: organization?.address || '',
    address2: organization?.address2 || '',
    city: organization?.city || '',
    state: organization?.state || '',
    zip: organization?.zip || '',
    country_code: organization?.country_code || '',
  }), [organization]);

  return (
    <SummaryContainer>
      {viewOnly ? (
        <>
          <div>
            <Title>Organization Info</Title>
          </div>
          <div>
            <Label>ADDRESS LINE 1</Label>
            <div>{organization?.address || '---'}</div>
          </div>
          <div>
            <Label>ADDRESS LINE 2</Label>
            <div>{organization?.address2 || '---'}</div>
          </div>
          <div>
            <Label>City</Label>
            <div>{organization?.city || '---'}</div>
          </div>
          <div>
            <Label>State</Label>
            <div>{organization?.state || '---'}</div>
          </div>
          <div>
            <Label>Zip Code</Label>
            <div>{organization?.zip || '---'}</div>
          </div>
          <div>
            <Label>Country Code</Label>
            <div>{organization?.country_code || '---'}</div>
          </div>
        </>
      ) : (
        <Formik
          validationSchema={Yup.object().shape({
            address: Yup.string(),
            address2: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zip: Yup.string(),
            country_code: Yup.string(),
          })}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, formikActions) => {
            dispatch(updateOrganizationProfile({ action: updateAction, values }));
            formikActions.setSubmitting(false);
            formikActions.resetForm();
          }}
        >
          {(formikProps) => (
            <Form>
              <FormRow>
                <Title>Organization Info</Title>
              </FormRow>
              <FormRow>
                <InputWrapper>
                  <Label>ADDRESS LINE 1</Label>
                  <Field name="address">
                    {({ field }) => (
                      <FormikInput
                        data-testid="org-profile-address-1"
                        {...field}
                        borderless
                        backgroundColor={colors.white}
                        type="text"
                        placeholder=""
                        disabled={formikProps.isSubmitting}
                      />
                    )}
                  </Field>
                </InputWrapper>
              </FormRow>
              <FormRow>
                <InputWrapper>
                  <Label>ADDRESS LINE 2</Label>
                  <Field name="address2">
                    {({ field }) => (
                      <FormikInput
                        data-testid="org-profile-address-2"
                        {...field}
                        borderless
                        backgroundColor={colors.white}
                        type="text"
                        placeholder=""
                        disabled={formikProps.isSubmitting}
                      />
                    )}
                  </Field>
                </InputWrapper>
              </FormRow>
              <FormRow>
                <InputWrapper>
                  <Label>CITY</Label>
                  <Field name="city">
                    {({ field }) => (
                      <FormikInput
                        data-testid="org-profile-city"
                        {...field}
                        borderless
                        backgroundColor={colors.white}
                        type="text"
                        placeholder=""
                        disabled={formikProps.isSubmitting}
                      />
                    )}
                  </Field>
                </InputWrapper>
                <MiddleWrapper>
                  <Label>STATE</Label>
                  <Field name="state">
                    {({ field }) => (
                      <FormikInput
                        data-testid="org-profile-state"
                        {...field}
                        borderless
                        backgroundColor={colors.white}
                        type="text"
                        placeholder=""
                        disabled={formikProps.isSubmitting}
                      />
                    )}
                  </Field>
                </MiddleWrapper>
                <RightWrapper>
                  <Label>POSTAL CODE</Label>
                  <Field name="zip">
                    {({ field }) => (
                      <FormikInput
                        data-testid="org-profile-zip"
                        {...field}
                        borderless
                        backgroundColor={colors.white}
                        type="text"
                        placeholder=""
                        disabled={formikProps.isSubmitting}
                      />
                    )}
                  </Field>
                </RightWrapper>
              </FormRow>
              <FormRow>
                <MiddleWrapper>
                  <Label>COUNTRY</Label>
                  <Field name="country_code">
                    {({ field }) => (
                      <Dropdown
                        filter
                        data-testid="org-profile-country"
                        backgroundColor={colors.white}
                        type="borderless"
                        placeholder=""
                        disabled={formikProps.isSubmitting}
                        options={sortedCountries}
                        {...field}
                        onChange={(val) => formikProps?.getFieldHelpers(field.name)?.setValue(val)}
                      />
                    )}
                  </Field>
                </MiddleWrapper>
              </FormRow>
              <ButtonContainer>
                <PrimaryButton
                  data-testid="org-profile-submit"
                  loading={isLoading}
                  text="Update"
                  type="submit"
                  disabled={isEqual(formikProps?.initialValues, formikProps?.values)}
                />
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      )}
    </SummaryContainer>
  );
};

export default SummaryTab;

const drawerMinus = subtractFromStyleString(layout.drawerPadding, 30);

const SummaryContainer = styled.div`
  flex: 1;
  padding: ${drawerMinus} ${layout.drawerPadding};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  flex: 1;
`;

const Label = styled.p`
  font: ${fonts.flag};
  padding: 5px 0;
  font-weight: bolder;
  color: ${colors.fontDark};
`;

const FormRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const InputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const MiddleWrapper = styled(InputWrapper)`
  margin: 0 5px;
  width: 100px;
`;

const RightWrapper = styled(InputWrapper)`
  width: 120px;
`;

const ButtonContainer = styled(FormRow)`
  justify-content: flex-end;
  margin: 15px 0;
`;
