import { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import { useForm } from 'hooks/useForm';
import { useModal } from 'hooks/useModal';

import BaseModal from 'containers/Modals/BaseModal';
import CreateOrgModal from 'components/CreateOrgModal';
import { LinkText } from 'containers/Auth/components';
import { ButtonContainer } from 'containers/Auth/ConfirmInvite/ConfirmExistingForm';
import Dropdown from 'components/Dropdown/index';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import { attachLicenseToOrganization, getCurrentOrganization } from 'rdx/modules/organization/slice';

const UnusedLicensesModal = () => {
  const { closeModal, callModal } = useModal();
  const dispatch = useDispatch();

  const currentOrganization = useSelector(getCurrentOrganization);
  const unusedLicenses = currentOrganization.getRel('unused_licenses');
  const attachLicenseAction = currentOrganization.getAction('attach_license');

  const unusedLicensesOptions = useMemo(() => unusedLicenses.map(({
    id,
    name,
    attributes: { meta: { seats, is_seat_based: isSeatBased } },
  }) => ({
    value: id,
    display: `${name}${seats && isSeatBased ? ` (${seats} seats)` : ''}`,
  })), [unusedLicenses]);

  const { form, fields } = useForm(
    {
      license: {
        initialValue: '',
        schema: Yup.string().required('License is required'),
      },
    }, {
      onSubmit: ({ license }) => {
        dispatch(attachLicenseToOrganization({
          action: attachLicenseAction,
          params: { license_id: license },
          onSuccess: () => closeModal(),
        }));
      },
    }
  );

  const handleCreateOrg = () => {
    closeModal();
    callModal(<CreateOrgModal key={uuid()} />);
  };

  return (
    <BaseModal
      noFooter
      dimensions={{
        width: '38rem',
        height: '22rem',
      }}
    >
      <Header>You have{unusedLicenses?.length > 1 ? ` ${unusedLicenses?.length} unapplied licenses` : ' an unapplied license'}</Header>
      <form {...form.props}>
        <FieldContainer>
          <Dropdown
            label="License"
            options={unusedLicensesOptions}
            placeholder="Select a license to apply"
            {...fields.license.props}
          />
        </FieldContainer>
        <FieldContainer>
          <p>
            The selected license will be applied to the current organization:
            <strong>{' '}{currentOrganization.attributes.name}</strong>.
            To apply this license to a different organization, visit the License Information
            tab of that organization&apos;s profile.
          </p>
        </FieldContainer>
        <ButtonContainer>
          <PrimaryButton
            hollow
            text="Cancel"
            onClick={() => closeModal()}
            style={{ marginRight: '10px' }}
          />
          <PrimaryButton
            type="submit"
            text="Apply License"
            disabled={!form.isValid || !attachLicenseAction}
          />
        </ButtonContainer>
        <LinkTextContainer>
          <LinkText onClick={handleCreateOrg}>Create an organization</LinkText>
        </LinkTextContainer>
      </form>
    </BaseModal>
  );
};

export default UnusedLicensesModal;

const Header = styled.h1`
  margin-bottom: 25px;
`;

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;

const LinkTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
