import { forwardRef } from 'react';
import createIcon, { IconProps } from '../createIcon';

const WarningCircle = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg viewBox="0 0 100 100" ref={ref} {...props}>
    <g>
      <path d="M85.4,14.6C75.6,4.9,62.8,0,50,0C37.2,0,24.4,4.9,14.6,14.6c-19.5,19.5-19.5,51.2,0,70.7C24.4,95.1,37.2,100,50,100   c12.8,0,25.6-4.9,35.4-14.6C104.9,65.8,104.9,34.2,85.4,14.6z M79.2,79.2C71.4,86.9,61,91.2,50,91.2c-11,0-21.4-4.3-29.2-12.1 c-16.1-16.1-16.1-42.2, 0-58.3C28.6, 13.1, 39, 8.8, 50, 8.8c11, 0, 21.4, 4.3, 29.1, 12.1C87, 28.6, 91.2, 39, 91.2, 50C91.2, 61, 87, 71.4, 79.2, 79.2z" />
      <path d="M50,65.9c-2.3,0-4,0.6-5.2,1.7c-1.2,1.1-1.8,2.8-1.8,5c0,2.1,0.6,3.8,1.8,4.9c1.2,1.2,2.9,1.8,5.1,1.8c2.2,0,3.8-0.6,5-1.8   c1.2-1.2,1.8-2.8,1.8-4.9c0-2.1-0.6-3.8-1.8-5C53.9,66.5,52.2,65.9,50,65.9z" />
      <polygon points="45.3, 59.1 54.9, 59.1 56.9, 20.7 43.3, 20.7" />
    </g>
  </svg>
));

export default createIcon(WarningCircle);
