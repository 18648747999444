import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setCurrentChannel, requestChannels } from '../slice';

function* hideChannel({ payload }) {
  const { action } = payload;
  const { success, data, error } = yield makeRequest.action(action);
  if (success && data) {
    yield put(setCurrentChannel(null));
    yield put(requestChannels());
  }
  if (error) {
    yield* putErrorActions({ error, target: 'messages' });
  }
  return null;
}

export default hideChannel;
