import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const StudyIcon = forwardRef((props, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 489.53 426.29"
  >
    <g>
      <path d="M489.4,117.55c0-22.25,0.33-44.01-0.09-65.76c-0.48-24.55-18.2-45.08-42.35-49.95c-3.51-0.71-7.06-1.22-10.59-1.83
        c-0.33,0-0.67,0-1,0c-2.79,0.25-5.58,0.73-8.37,0.73C332.04,0.77,237.07,0.76,142.1,0.8c-4.15,0-8.37-0.03-12.42,0.69
        c-26.7,4.7-44.55,26.23-44.57,53.78c-0.05,86.13-0.02,172.25-0.02,258.38c0,1.95,0,3.89,0,6.41c-28.53,0-56.54,0-84.76,0
        c-0.13,1.34-0.28,2.14-0.28,2.93c0.02,17.46-0.21,34.92,0.15,52.37c0.33,16.06,7.32,29.13,19.7,39.22
        c10.49,8.56,22.72,11.73,36.18,11.72c95.13-0.12,190.27-0.05,285.4-0.09c6.14,0,12.39,0.29,18.39-0.74
        c26.74-4.6,44.53-26.19,44.55-53.77c0.06-82.47,0.02-164.94,0.02-247.4c0-2.09,0-4.18,0-6.75
        C433.14,117.55,461.16,117.55,489.4,117.55z M302.96,405.05c-82.31,0-164.62,0.01-246.93,0c-21.58,0-34.79-13.21-34.8-34.77
        c-0.01-9.46,0-18.91,0-28.72c92.14,0,184,0,276.45,0c1.51,21.39-4.07,43.74,11.07,63.49C306.1,405.05,304.53,405.05,302.96,405.05z
        M383.17,59.22c0.16,103.23,0.12,206.46,0,309.68c-0.01,5.07-0.52,10.41-2.14,15.17c-4.82,14.18-19.95,23-34.31,20.69
        c-15.49-2.49-26.9-14.9-27.23-30.4c-0.34-15.95-0.11-31.92-0.13-47.88c0-1.96,0-3.91,0-6.4c-71.15,0-141.74,0-212.82,0
        c-0.11-2.28-0.26-4.05-0.26-5.81c-0.01-85.95-0.02-171.9,0-257.85c0.01-20.91,13.49-34.48,34.2-34.48
        c82.65-0.01,165.3-0.01,247.95-0.01c1.45,0,2.89,0,5.36,0C385.31,33.79,383.15,46.05,383.17,59.22z M404.44,96.31
        c0-2.33,0-4.44,0-6.54c0-11.97-0.1-23.94,0.03-35.91c0.2-17.91,14.5-32,32.16-31.89c17.42,0.11,31.46,14.17,31.64,31.91
        c0.14,13.47,0.02,26.94,0.01,40.4c0,0.49-0.13,0.98-0.28,2.03C446.81,96.31,425.93,96.31,404.44,96.31z"
      />
      <path d="M179.78,92.13c-9.94-10.01-19.96-20.11-29.77-30c-4.77,4.7-9.59,9.45-13.61,13.42c15.02,15.07,30.18,30.27,44.95,45.08
        c18.1-18.08,36.65-36.6,55.06-54.99c-4.76-4.82-9.51-9.64-15.03-15.25C207.82,63.98,194.16,77.7,179.78,92.13z"
      />
      <path d="M179.55,187.9c-9.89-9.95-19.91-20.03-29.72-29.89c-4.69,4.61-9.53,9.35-13.94,13.69c15.03,15.07,30.12,30.21,44.88,45.02
        c18.4-18.39,37.03-37.01,55.36-55.32c-4.46-4.57-9.26-9.48-14.97-15.33C207.64,159.66,193.99,173.38,179.55,187.9z"
      />
      <path d="M350.54,75.72c-28.28,0-56.08,0-83.92,0c0,6.94,0,13.48,0,20.2c28.18,0,55.98,0,83.92,0
        C350.54,88.94,350.54,82.29,350.54,75.72z"
      />
      <path d="M266.69,191.63c28.25,0,56.04,0,84.07,0c0-6.79,0-13.2,0-20.06c-28.4,0-56.31,0-84.07,0
        C266.69,178.59,266.69,185.13,266.69,191.63z"
      />
    </g>
  </svg>
));

export default createIcon(StudyIcon);
