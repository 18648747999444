import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { updateTrial } from '../slice';

function* saveTrial({ payload: { action, params, onSuccess, noMessage } }) {
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (success && data) {
    if (onSuccess) {
      onSuccess({ data });
    }
    if (!noMessage) {
      yield put(newMessageEvent({ text: 'Step Saved' }));
    }
    yield put(updateTrial(data.unwrap()));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default saveTrial;
