import { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useResizeListener } from 'hooks/useResizeListener';
import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';
import ResourceList from 'lib/jsonApi/ResourceList';
import { usePrevious } from 'hooks/usePrevious';
import DeprecatedPrimaryButton from 'components/DeprecatedButton/DeprecatedPrimaryButton/index';
import Checkbox from 'components/Formik/Checkbox';
import Loading from 'components/Loading';
import { ScriptIcon } from 'components/Icons';
import WistiaPlayer from 'components/WistiaPlayer';
import Link from 'components/Link';
import MarkdownHtml from 'components/MarkdownHtml';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { getTutorial, requestTutorial, setTutorial } from 'rdx/modules/tutorials/slice';
import { getCompletedTutorials, getDisableTutorials, getUserId, updateUserTutorials } from 'rdx/modules/users/slice';
import globals from '../../styles/globals';

const { layout } = globals;

const Tutorials = ({ width, height }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const dimensions = useResizeListener();

  const userLoaded = useSelector(getUserId);
  const completedTutorials = useSelector(getCompletedTutorials);
  const hasDisabledAll = useSelector(getDisableTutorials);
  const tutorial = useUnwrappedSelector(getTutorial, true);
  const tutorialAction = useSelector(getSessionAction('tutorials'));
  const updateUserTutorialsAction = useSelector(getSessionAction('update_profile_tutorials'));

  const [closed, setClosed] = useState(true);
  const [dismissed, setDismissed] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [disableAll, setDisableAll] = useState(false);

  const containerControls = useAnimation();
  const questionControls = useAnimation();
  const popupControls = useAnimation();
  const contentControls = useAnimation();
  const rotatorControls = useAnimation();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [matchedRoute, forceTutorial] = useMemo(() => {
    const tutorialParam = queryParams.get('tutorial');
    const showTutorial = Boolean(queryParams.get('showTutorial'));

    return [
      tutorialAction?.field?.('slug')?.options?.getByValue?.(tutorialParam),
      showTutorial,
    ];
  }, [queryParams, tutorialAction]);

  const prevMatchedRoute = usePrevious(matchedRoute);
  const hasSeen = useMemo(
    () => completedTutorials?.some((a) => a === matchedRoute), [completedTutorials, matchedRoute]
  );

  const { containerVariants, questionVariants, popupVariants, contentVariants, rotatorVariants } = useMemo(() => {
    const maxWidth = Math.min(dimensions?.width - 100, width);
    const maxHeight = Math.min(dimensions?.height - 100, height);
    return ({
      containerVariants: {
        docked: {
          left: 0,
          bottom: 0,
          height: 53,
          width: 53,
          backgroundColor: 'rgba(0,0,0,0)',
          transition: {
            duration: 0.1,
          },
        },
        centered: {
          left: 0,
          bottom: 0,
          height: '100vh',
          width: '100vw',
          transition: {
            duration: 0,
          },
        },
      },
      questionVariants: {
        docked: {
          left: layout.mainContainerPadding,
          bottom: -6,
          scale: 0.5,
        },
        centered: {
          left: (dimensions?.width / 2) - 27,
          bottom: (dimensions?.height / 2) - 27,
          scale: 1,
          transition: {
            duration: 0.3,
          },
        },
        centeredBig: {
          left: (dimensions?.width / 2) - 27,
          bottom: (dimensions?.height / 2) - 27,
          scale: 2,
          transition: {
            duration: 0.1,
          },
        },
        removed: {
          left: (dimensions?.width / 2) - 27,
          bottom: (dimensions?.height / 2) - 27,
          scale: 0,
          zIndex: -1,
          transition: {
            duration: 0.5,
          },
        },
      },
      popupVariants: {
        hidden: {
          left: 15,
          bottom: 15,
          width: 0,
          height: 0,
          zIndex: -1,
          opacity: 0,
          borderRadius: '50%',
          transition: {
            duration: 0.3,
          },
        },
        hiddenCenter: {
          opacity: 0,
          scale: 0,
          left: (dimensions?.width / 2) - (maxWidth / 2),
          bottom: (dimensions?.height / 2) - (maxHeight / 2),
          borderRadius: '50%',
          transition: {
            duration: 0.3,
          },
        },
        visible: {
          width: maxWidth,
          height: maxHeight,
          borderRadius: 1,
          opacity: 1,
          scale: 1,
          left: (dimensions?.width / 2) - (maxWidth / 2),
          bottom: (dimensions?.height / 2) - (maxHeight / 2),
          transition: {
            duration: 0.5,
          },
        },
      },
      contentVariants: {
        hidden: {
          opacity: 0,
          scale: 0,
          borderRadius: '50%',
          transition: {
            duration: 0.3,
          },
        },
        visible: {
          opacity: 1,
          scale: 1,
          borderRadius: 1,
          transition: {
            duration: 0.1,
          },
        },
      },
      rotatorVariants: {
        hiddenStart: {
          strokeWidth: 0,
          strokeDashoffset: 282,
          transform: 'rotate(-80deg)',
          transition: {
            duration: 0.2,
          },
        },
        visibleStart: {
          strokeWidth: 5,
          strokeDashoffset: 282,
          transition: {
            duration: 0.2,
          },
        },
        spin: {
          strokeWidth: 6,
          transform: 'rotate(100deg)',
          strokeDashoffset: 150,
          transition: {
            duration: 0.3,
          },
        },
        visibleEnd: {
          strokeWidth: 5,
          transform: 'rotate(270deg)',
          strokeDashoffset: 282,
          transition: {
            duration: 0.3,
          },
        },
        hiddenEnd: {
          strokeWidth: 0,
          transform: 'rotate(280deg)',
          strokeDashoffset: 282,
          transition: {
            duration: 0.2,
          },
        },
      },
    });
  }, [dimensions.height, dimensions?.width, height, width]);

  const changedTutorial = useCallback(
    async () => {
      setIsRotating(true);
      await rotatorControls.start('visibleStart');
      await rotatorControls.start('spin');
      await rotatorControls.start('visibleEnd');
      await rotatorControls.start('hiddenEnd');
      await rotatorControls.start('hiddenStart');
      setIsRotating(false);
    },
    [rotatorControls],
  );

  useEffect(() => {
    if (matchedRoute && matchedRoute !== prevMatchedRoute && !isRotating) {
      changedTutorial();
      setDismissed(false)
    }
  }, [changedTutorial, isRotating, matchedRoute, prevMatchedRoute]);

  const startSequence = useCallback(async () => {
    setClosed(false);
    dispatch(requestTutorial({ action: tutorialAction, params: { slug: matchedRoute } }));
    containerControls.start('centered');
    questionControls.start('removed');
    popupControls.start('visible');
    containerControls.start({
      backgroundColor: 'rgba(0,0,0,0.6)',
      transition: {
        duration: 0.1,
      },
    });
    await contentControls.start('visible');
  },
  [containerControls, contentControls, dispatch, matchedRoute, popupControls, questionControls, tutorialAction]);

  const stopSequence = useCallback(async () => {
    dispatch(setTutorial(new ResourceList()));
    questionControls.start('centered');
    contentControls.start('hidden');
    await popupControls.start('hiddenCenter');
    popupControls.start('hidden');
    await questionControls.start('centeredBig');
    await containerControls.start({
      backgroundColor: 'rgba(0,0,0,0)',
      transition: {
        duration: 0.1,
      },
    });
    containerControls.start('docked');
    await questionControls.start('centered');
    await questionControls.start('docked');
    setClosed(true);
  }, [containerControls, contentControls, dispatch, popupControls, questionControls]);

  useEffect(() => {
    const permissionToView = (forceTutorial || (!hasSeen && !hasDisabledAll));
    if (closed && matchedRoute && userLoaded && !dismissed && permissionToView) {
      setTimeout(() => {
        startSequence();
      }, 1000);
    }
  }, [closed, dismissed, hasSeen, matchedRoute, startSequence, userLoaded, hasDisabledAll, forceTutorial]);

  const handleClose = useCallback(() => {
    if (!hasSeen && matchedRoute) {
      dispatch(updateUserTutorials({
        action: updateUserTutorialsAction,
        values: {
          completed_tutorials: [matchedRoute],
          disable_tutorials: disableAll,
        },
      }));
    }
    if (queryParams.has('showTutorial')) {
      queryParams.delete('showTutorial');
      history.replace({
        search: queryParams.toString(),
      });
    }
    setDismissed(true)
    stopSequence();  
  }, [disableAll, dispatch, hasSeen, history, matchedRoute, queryParams, stopSequence, updateUserTutorialsAction]);

  if (!matchedRoute || !userLoaded) {
    return null;
  }

  const sanitizedContent = sanitizeHtml(
    tutorial?.attributes?.content_html || 'Error: tutorial loaded with no content',
    {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
      allowedAttributes: { img: ['src'], a: ['href', 'target'] },
      allowedSchemes: ['data', 'https'],
    }
  );

  return (
    <TutorialContainer
      initial="docked"
      animate={containerControls}
      variants={containerVariants}
      onClick={() => { if (!closed) { setDismissed(true); } stopSequence(); }}
    >
      <QuestionMark
        onClick={(e) => { e.stopPropagation(); startSequence(); }}
        initial="docked"
        animate={questionControls}
        variants={questionVariants}
        data-testid="tutorials-question-mark"
      >
        <Svg width="55px" height="55px" viewBox="0 0 100 100">
          <Rotator
            cx="50"
            cy="50"
            r="47"
            fill="none"
            strokeWidth="8"
            strokeLinecap="round"
            strokeDashoffset={283}
            initial="hiddenStart"
            animate={rotatorControls}
            variants={rotatorVariants}
          />
        </Svg>
        <SScriptIcon size={33} clickable />
      </QuestionMark>
      <TutorialPopup
        initial="hidden"
        animate={popupControls}
        variants={popupVariants}
        onClick={(e) => e.stopPropagation()}
      >
        <ContentContainer
          initial="hidden"
          animate={contentControls}
          variants={contentVariants}
          // transition={}
        >
          <Loading forceLoading={!tutorial?.attributes && !closed}>
            <Title>{tutorial?.attributes?.title || matchedRoute}</Title>
            <ScrollContainer>
              {!!tutorial?.attributes?.wistia_media_id && (
                <WistiaPlayerContainer>
                  <WistiaPlayer wistiaMediaId={tutorial?.attributes?.wistia_media_id} />
                </WistiaPlayerContainer>
              )}
              {!!tutorial?.attributes?.video_url && !tutorial?.attributes?.wistia_media_id && (
                <Video controls>
                  <source src={tutorial?.attributes?.video_url} />
                </Video>
              )}
              <Content>
                {/* eslint-disable-next-line react/no-danger */}
                <MarkdownHtml dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
              </Content>
            </ScrollContainer>
            <FooterContainer>
              <StartContainer>
                <Link to="/faq">Frequently Asked Questions</Link>
              </StartContainer>
              <EndContainer>
                <SCheckbox
                  value={disableAll}
                  onChange={() => setDisableAll(!disableAll)}
                />
                <DisableText>Disable All Tutorials</DisableText>
                <DeprecatedPrimaryButton text="Ok, got it!" onClick={() => handleClose()} data-testid="tutorials-confirm-button" />
              </EndContainer>
            </FooterContainer>
          </Loading>
        </ContentContainer>
      </TutorialPopup>
    </TutorialContainer>
  );
};

Tutorials.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Tutorials.defaultProps = {
  width: 800,
  height: 700,
};

export default Tutorials;

const TutorialContainer = styled(motion.div)`
  position: fixed;
  left: ${layout.mainContainerPadding};
  background-color: ${({ theme }) => theme.colors.charcoal};
  z-index: ${({ theme }) => theme.zIndices.modal - 100};
`;

const QuestionMark = styled(motion.button)`
  position: fixed;
  left: ${layout.mainContainerPadding};
  color: ${({ theme }) => theme.colors.white};
  font-size: 30px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: none;
  background-color: ${({ theme }) => theme.colors.charcoal};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    font-weight: bold;
  }
`;

const TutorialPopup = styled(motion.div)`
  position: fixed;
  display: flex;
  box-shadow: 2px 2px 20px -1px ${({ theme }) => theme.colors.fontDark};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.charcoal};
`;

const ContentContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  display: flex;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 100%;
`;

const Rotator = styled(motion.circle)`
`;

const Title = styled.h1`
  padding: 20px 50px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.charcoal};
`;

const Content = styled.div`
  flex: 1 0 auto;
  padding: 0 50px;
  width: 700px;
`;

const FooterContainer = styled.div`
  display: flex;
  padding: 20px 50px;
`;

const StartContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const EndContainer = styled.div`
  display: flex;
`;

const ScrollContainer = styled.div`
  flex: 2 2 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const videoContainerCSS = `
  align-self: center;
  width: 700px;
  padding: 20px 0;
`;

const Video = styled.video`${videoContainerCSS}`;

const WistiaPlayerContainer = styled.div`${videoContainerCSS}`;

const Svg = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  stroke-dasharray: 283;
  stroke: ${({ theme }) => theme.colors.primary};
`;

const SCheckbox = styled(Checkbox)`
  transform: scale(.7);
`;

const DisableText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 6px;
`;

const SScriptIcon = styled(ScriptIcon)`
  transform: translate(-1px, 2px);
`;
