import { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'hooks/useForm';

import FormikInput from 'components/Formik/FormikInput/index';
import PrimaryButton from 'components/Button/PrimaryButton';
import { useAppDispatch } from 'hooks/reduxHooks';
import { forgotPassword } from 'rdx/modules/auth/slice';
import {
  Container,
  HeaderContainer,
  Header,
  Subheader,
  Form,
  FormBody,
  InputGroup,
  Errors,
  SubmitButtonContainer,
  InfoText,
  LinkText,
  ConfirmationSubText,
  ConfirmationText,
  ConfirmationTextContainer,
} from '../components';

const ForgotPass = () => {
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const { form, fields } = useForm({
    email: {
      initialValue: '',
      schema: Yup.string()
        .email('Must be a valid email address')
        .required('Email is required'),
    },
  }, {
    onSubmit: ({ email }) => {
      dispatch(forgotPassword({ params: { email }, onSuccess: onSubmitSuccess }));
    },
  });

  const { email } = fields;

  const onSubmitSuccess = () => {
    form.setSubmitting(false);
    setSubmitted(true);
  };

  return (
    <Container>
      <HeaderContainer>
        <Header data-testid="forgotPassword">
          Forgot Password?
        </Header>
        <Subheader>
          Enter your email below and we&apos;ll send you a link to reset your password
        </Subheader>
      </HeaderContainer>
      <Form {...form.props}>
        <FormBody>
          <InputGroup>
            <FormikInput {...email.props} disabled={submitted} placeholder="Email" />
            <Errors errors={email.visited ? email.errors : []} />
          </InputGroup>
        </FormBody>
        <SubmitButtonContainer>
          <PrimaryButton
            disabled={(!form.isValid || form.isSubmitting || submitted)}
            text="Send"
            type="submit"
            data-testid="reset-password-button"
          />
          <InfoText>
            Already know your password? <LinkText to="/login">Sign in here.</LinkText>
          </InfoText>
        </SubmitButtonContainer>
      </Form>
      <ConfirmationTextContainer show={submitted}>
        <Header>
          Success!
        </Header>
        <ConfirmationText>
          An email with instructions on how to reset your password should
          arrive at the address you provided within a few minutes if an account has been activated.
        </ConfirmationText>
        <ConfirmationSubText>
          Don&apos;t see it? Be sure that BD Research Cloud is whitelisted
          in your email client or check your spam folder.
        </ConfirmationSubText>
      </ConfirmationTextContainer>
    </Container>
  );
};

export default ForgotPass;
