import { forwardRef } from 'react';
import { css } from 'styled-components';

import Button, { ButtonElementProps, ButtonProps } from '../index';

const solidCSS = {
  base: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.colors.primaryHover};
    border-color: ${({ theme }) => theme.colors.primaryHover};
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
    border-color: ${({ theme }) => theme.colors.primaryDisabled};
    opacity: 0.5;
  `,
};

const warningCSS = {
  base: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.warning};
    border-color: ${({ theme }) => theme.colors.warning};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.colors.warningDark};
    border-color: ${({ theme }) => theme.colors.warningDark};
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.colors.warningLight};
    border-color: ${({ theme }) => theme.colors.warningLight};
    opacity: 0.5;
  `,
};

const hollowCSS = {
  base: css`
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.primary};
  `,
  hover: css`
    color: ${({ theme }) => theme.colors.primaryHover};
    border-color: ${({ theme }) => theme.colors.primaryHover};
    background-color: rgba(0, 0, 0, 0.05);
  `,
  disabled: css`
    color: ${({ theme }) => theme.colors.primaryDisabled};
    border-color: ${({ theme }) => theme.colors.primaryDisabled};
    opacity: 0.5;
  `,
};

export interface PrimaryButtonProps extends ButtonProps, ButtonElementProps {
  hollow?: boolean;
  warning?: boolean;
}

const PrimaryButton = forwardRef<HTMLButtonElement, PrimaryButtonProps>(({ hollow, warning, ...buttonProps }, ref) => {
  let CSS = solidCSS;
  if (warning) {
    CSS = warningCSS;
  }
  if (hollow) {
    CSS = hollowCSS;
  }
  return (
    <Button
      ref={ref}
      baseCSS={CSS.base}
      hoverCSS={CSS.hover}
      disabledCSS={CSS.disabled}
      {...buttonProps}
    />
  );
});

PrimaryButton.displayName = 'PrimaryButton';

export default PrimaryButton;
