import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { requestProcedures } from 'rdx/modules/procedures/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { CreateWorkflowParams, getWorkflowsRaw } from '../slice';
import ResourceList from 'lib/jsonApi/ResourceList';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Resource from 'lib/jsonApi/Resource';

// procedure_template_id: 'bd_panel_maker',

function* createPredefinedWorkflowSaga({ payload }: PayloadActionWithGenericPayload<CreateWorkflowParams>) {
  const { onSuccess, params, message } = payload;
  const workflows: ResourceList = yield select(getWorkflowsRaw);
  const action = workflows.getAction('create');
  const groupId = params?.group_id

  const { success, data, error } = yield makeRequest.action(action, params);
  if (success && data) {
    yield put(newMessageEvent({ text: message ?? 'Workflow Created' }));
    yield put(requestProcedures({ link: data?.links?.procedures }));
    const currentOrg: Resource = yield select(getCurrentOrganization);
    if (currentOrg.attributes.slug && groupId && data.data.id) {
      onSuccess?.({ routeTo: `/${currentOrg.attributes.slug}/groups/${groupId}/workflows/${data.data.id}/step?stepId=first&procedureId=first` });
    }
  } else if (error) {
    const persist = error.text.includes('maximum number of active workflows');
    yield* putErrorActions({ error, persist });
  }
  return null;
}

export default createPredefinedWorkflowSaga;
