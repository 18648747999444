import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import * as Yup from 'yup';
import { humanizeString } from 'lib/utils/string';

import { useForm } from 'hooks/useForm';
import ArrayStringInput from 'components/ActionForm/ArrayStringInput';
import Dropdown from 'components/Dropdown/index';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import Input from 'components/Form/Input/index';
import FormField from 'components/Form/FormField/index';
import Switch from 'components/Form/Switch/index';
import TextArea from 'components/Form/TextArea/index';
import Action from 'lib/jsonApi/Action';
import Field from 'lib/jsonApi/Field';
import { Field as UseFormField } from 'hooks/useForm/types';

type YupSchemaType = 'string' | 'number' | 'boolean' | 'bool' | 'date';

const DEFAULT_OPTS = {
  hiddenFields: [],
  inputProps: {
    backgroundColor: 'rgba(255,2555,255, 0)',
  },
  wrap: false,
  cancelButton: {
    text: 'cancel',
  },
};

const DEFAULT_INITIAL_VALUES = {
  text: '',
  number: '',
  bool: false,
  array: [],
  date: '',
} as const;

const spacesAndUnderScores = new RegExp(/_|\s/g);

type ActionFormFieldOptions = Record<string, {
    style?: React.CSSProperties,
    schema?: Yup.AnySchema,
    initialValue?: unknown,
    validate?: (value: unknown, { fields }: { fields: Record<string, UseFormField<typeof value>> }) => string | null,
    inputProps?: any,
    options?: any[],
  }>;

type ActionFormOptions = ActionFormFieldOptions & {
  cancelButton?: { text: string },
  hiddenFields?: string[],
  inputProps?: any,
  wrap?: boolean,
};

type ActionFormProps = {
  action: Action,
  onSubmit: (values: Record<string, any>) => void,
  id?: string,
  onCancel?: () => void,
  options: ActionFormOptions,
  name?: string,
  wrap?: boolean,
  loading?: boolean,
  isInvite?: boolean,
};

const ActionForm = ({
  id,
  action,
  onSubmit,
  onCancel,
  options,
  name,
  wrap,
  loading,
  isInvite,
}: ActionFormProps) => {
  const safeID = useMemo(() => id ?? name?.replace?.(spacesAndUnderScores, '') ?? `${action?.name}-form`.replace?.(spacesAndUnderScores, '-'), [action, id, name]);

  const [displayedFields, parsedFormOpts, safeOptions] = useMemo(() => {
    const opts = { ...DEFAULT_OPTS, ...options };
    return action.fields.reduce(([fields, bag], field) => {
      if (opts.hiddenFields.includes(field.name)) {
        return [fields, bag, opts];
      }
      const newFields = [...fields, field];
      const newBag = {
        ...bag,
        [field.name]: buildItem({ field, opts }),
      };
      return [newFields, newBag, opts];
    }, [[] as Field[], {}, opts]);
  }, [action.fields, options]);

  const { form, fields } = useForm(parsedFormOpts, { onSubmit });

  const getFieldComponentProps = useCallback((field: Field) => {
    return getComponentProps({ field, safeOptions });
  }, [safeOptions])

  return (
    <form id={safeID} {...form.props} name={name ?? `${action?.name}-form`.replace?.(spacesAndUnderScores, '-')}>
      <FieldsWrapper wrap={wrap}>
        {displayedFields.map((field) => (
          <FormField
            key={field.name}
            field={fields[field.name]}
            {...{
              ...getFieldComponentProps(field),
            }}
          />
        ))}
      </FieldsWrapper>
      <Buttons isInvite={isInvite}>
        {!!onCancel && (
          <PrimaryButton
            hollow
            text={safeOptions.cancelButton.text}
            onClick={onCancel}
            loading={loading}
          />
        )}
        <PrimaryButton
          type="submit"
          text="submit"
          disabled={!form.isValid || form.isSubmitting}
          loading={loading}
          data-testid="submit-action-form-button"
        />
      </Buttons>
    </form>
  );
};

export default ActionForm;

function buildItem({ field, opts }: { field: Field, opts: ActionFormOptions }) {
  const safeName = `${humanizeString(field.name)}`;
  let schema = opts[field.name]?.schema ?? null;
  let initialValue = opts[field.name]?.initialValue ?? field.value ?? null;
  const validate = opts[field.name]?.validate ?? null;
  if ((schema !== null && (initialValue ?? (field.nulls && initialValue === null))) || validate) {
    return { schema, initialValue, validate };
  }
  initialValue = initialValue ?? getInitValue(field);
  switch (true) {
    case field.type === 'text':
      schema = field.required
        ? Yup.string().required(`${safeName} is required`)
        : field.nulls
          ? Yup.string().nullable()
          : Yup.string();
      break;
    case field.type === 'password':
      schema = Yup.string().min(8, `${safeName} must be at least 8 characters`).required(`${safeName} is required`);
      break;
    case field.type === 'json': {
      if (field.schema === 'string') {
        schema = Yup.array();
      } else {
        schema = field.required
          ? Yup[field.schema as YupSchemaType]().required(`${safeName} is required`)
          : field.nulls
            ? Yup[field.schema as YupSchemaType]().nullable()
            : Yup[field.schema as YupSchemaType]();
      }
      break;
    }
    case !!field.type:
      schema = field.required
        ? Yup[field.type as YupSchemaType]().required(`${safeName} is required`)
        : field.nulls
          ? Yup[field.type as YupSchemaType]().nullable()
          : Yup[field.type as YupSchemaType]();
      break;

    default:
      console.warn('Tried to get schema without a field type');
      break;
  }
  return { schema, initialValue };
}

function getInitValue(field: Field) {
  if (field.nulls) {
    return null;
  }
  return DEFAULT_INITIAL_VALUES[field.type as keyof typeof DEFAULT_INITIAL_VALUES];
}

function getComponentProps({ field, safeOptions }: { field: Field, safeOptions: Record<string, any> }) {
  const safeName = safeOptions[field.name]?.customName
    ? humanizeString(safeOptions[field.name]?.customName)
    : humanizeString(field.name);
  const label = safeOptions[field.name]?.label ?? `${safeName}${field.required ? ' *' : ''}`;
  let component = safeOptions[field.name]?.component ?? null;
  const inputProps = { ...safeOptions.inputProps, ...safeOptions[field.name]?.inputProps || {} };
  const style = { ...(safeOptions?.style || {}), ...(safeOptions?.[field.name]?.style || {}) };
  if (component) {
    return { label, component, inputProps };
  }
  switch (field.type) {
    case 'text':
      component = safeOptions[field.name]?.textArea ? TextArea : Input;
      inputProps.placeholder = inputProps.placeholder ?? `Enter ${safeName || field.type} here ...`;
      inputProps['data-testid'] = inputProps['data-testid'] ?? `input-${safeName || field.type}`;
      break;
    case 'password':
      component = Input;
      inputProps.placeholder = inputProps.placeholder ?? 'must be at least 8 characters ...';
      inputProps.type = field.type;
      inputProps['data-testid'] = inputProps['data-testid'] ?? `input-${safeName || field.type}`;
      break;
    case 'bool':
      component = Switch;
      break;
    case 'number':
      component = Input;
      break;
    case 'date':
      component = DateInput;
      break;
    case 'json':
      if (Array.isArray(field.schema)) {
        inputProps.options = field.value;
        component = ArrayStringInput;
      } else {
        component = Input;
        inputProps.type = field.schema;
      }
      break;
    default:
      component = Input;
      break;
  }
  if (field.options.length) {
    component = Dropdown;
    inputProps.options = safeOptions[field.name]?.options ?? field.options;
  }
  return { label, component, inputProps, style };
}

export const Buttons = styled.div<{ isInvite?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 15px;
  }
  margin: ${({ isInvite }) => (isInvite ? '0' : '20px 0 0 0')};
`;

export const FieldsWrapper = styled.div<{ wrap?: boolean }>`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: ${({ wrap }) => (wrap ? 'row' : 'column')};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'no-wrap')};
  justify-content: space-between;
`;

export const DateInput = styled(Input).attrs(
  () => ({
    type: 'date',
  }),
)``;
