import { forwardRef } from 'react';

import createIcon, { IconProps } from '../createIcon';

const DeleteIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg data-testid="delete-icon-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
    <path d="M20 4h-5v-0.5c0-0.828-0.672-1.5-1.5-1.5v0h-4c-0.828 0-1.5 0.672-1.5 1.5v0 0.5h-5v2h2v12.5c0 0.828 0.672 1.5 1.5 1.5v0h10c0.828 0 1.5-0.672 1.5-1.5v0-12.5h2zM9 3.5c0-0.276 0.224-0.5 0.5-0.5v0h4c0.276 0 0.5 0.224 0.5 0.5v0 0.5h-5zM9 17h-1v-9h1zM12 17h-1v-9h1zM15 17h-1v-9h1z"></path>
  </svg>
));

DeleteIcon.displayName = 'DeleteIcon';

const CreatedIcon = createIcon(DeleteIcon, true);

export default CreatedIcon;
