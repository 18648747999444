import { useEffect, useContext, useCallback } from 'react';
import MessagesContext from 'contexts/MessagesContext';

export function useSocketMessages(updateHandler?: (message: any) => void) {
  const messagesContext = useContext(MessagesContext);

  const handleMessage = useCallback((e: CustomEvent) => {
    const message = e.detail;
    if (updateHandler) {
      updateHandler(message);
    }
  }, [updateHandler]) as EventListener;

  useEffect(() => {
    window.addEventListener('bdrc-channel-message', handleMessage);
    return () => {
      window.removeEventListener('bdrc-channel-message', handleMessage);
    };
  }, [handleMessage]);

  return messagesContext;
}
