import { put, select } from 'redux-saga/effects';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import makeRequest from 'rdx/utils/makeRequest';
import { putErrorActions } from 'rdx/utils/putErrorActions';
import { requestReagents } from 'rdx/modules/reagents/slice';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import Action from 'lib/jsonApi/Action';

function* postReagent(rdxAction: PayloadActionWithGenericPayload & { payload: { import_type?: 'bd' | 'custom' } }) {
  const { action, onSuccess, import_type, params } = rdxAction.payload;
  const input = { ...params };
  const { success, error, data } = yield makeRequest.action(action, input);
  if (success && data) {
    yield put(newMessageEvent({ text: 'Reagent Added' }));
    const slug: string | undefined = yield select(getCurrentOrgSlug);
    const reagentAction: Action | undefined = yield select(getSessionAction('reagents'));
    yield put(requestReagents({
      action: reagentAction,
      params: {
        organization_id: slug,
        import_type,
        page: 1,
      },
    }));
    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield* putErrorActions({ error, rdxAction, onSuccess });
  }
  return null;
}

export default postReagent;
