import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setSpectralData, setNormalizedSpectralData } from 'rdx/modules/reagents/slice';

function* requestSpectralData({ payload }) {
  const { action, params } = payload;
  const { success, error, data } = yield* makeRequest.action(action, params);
  if (error) {
    yield* putErrorActions({ error });
  }
  if (success && data) {
    const normalizedSpectralMatrix = data?.data?.attributes?.normalizedSpectralMatrixMeasured;
    const spectralData = { ...data };
    delete spectralData.data.attributes.normalizedSpectralMatrix;
    yield put(setSpectralData(spectralData));
    yield put(setNormalizedSpectralData(normalizedSpectralMatrix));
  }
  return null;
}

export default requestSpectralData;
