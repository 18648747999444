import { put, select } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSessionLinks } from 'rdx/modules/auth/slice';
import { setWorkflowTemplates } from '../slice';

function* requestWorkflowTemplates() {
  const links = yield select(getSessionLinks);
  const { success, data, error } = yield* makeRequest.link(links.workflow_templates);
  if (success && data) {
    yield put(setWorkflowTemplates(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestWorkflowTemplates;
