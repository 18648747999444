import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import { getCurrentOrganization, setCurrentOrganization } from '../slice';

function* surrenderOrg({ payload: { action, params, onSuccess } }) {
  const { success, data, error } = yield* makeRequest.action(action, params);
  if (success && data) {
    const org = yield select(getCurrentOrganization);
    yield put(setCurrentOrganization(data.unwrap()));
    onSuccess({ routeTo: `/${org.attributes.slug}` });
    yield put(newMessageEvent({ text: 'Organization Administrator Updated' }));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default surrenderOrg;
