import React, { forwardRef } from 'react';
import createIcon from '../createIcon';

const Critical = forwardRef((props, ref) => (
  <svg viewBox="0 0 524.46 454.19" ref={ref} {...props}>
    <polygon fill="#EA0202" points="262.23,0 0,454.19 524.46,454.19 " />
  </svg>
));

export default createIcon(Critical);
