import { forwardRef } from 'react';

import createIcon, { IconProps } from '../createIcon';

const LockIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <svg ref={ref} {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M19 10h-1v-2c0-3.314-2.686-6-6-6s-6 2.686-6 6v0 2h-1c-0.552 0-1 0.448-1 1v0 10c0 0.552 0.448 1 1 1v0h14c0.552 0 1-0.448 1-1v0-10c0-0.552-0.448-1-1-1v0zM8 8c0-2.209 1.791-4 4-4s4 1.791 4 4v0 2h-8zM13 16.11v2.39h-2v-2.39c-0.307-0.276-0.5-0.674-0.5-1.118 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.444-0.193 0.842-0.499 1.117l-0.001 0.001z"></path>
  </svg>
));

LockIcon.displayName = 'LockIcon';

const CreatedIcon = createIcon(LockIcon);

export default CreatedIcon;

