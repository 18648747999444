import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useAppSelector } from "hooks/reduxHooks";
import { getSystemSettings } from "rdx/modules/auth/slice";

const useInitializeClarity = () => {
  const systemSettings = useAppSelector(getSystemSettings);

  useEffect(() => {
    const clarityCode = systemSettings?.microsoft_clarity_code;
    if (clarityCode && !clarity.hasStarted() && !!window.ONETRUST_PERFORMANCE) {
      clarity.init(clarityCode);
    }
  }, [systemSettings?.microsoft_clarity_code]);
}

export { useInitializeClarity };