import { useState, useReducer } from 'react';
import type { Field, FieldState, FormOptions, FormState } from 'hooks/useForm/types';
import { FIELD_DISPATCH_TYPES, fieldReducer, initializeFields } from 'hooks/useForm/reducer';
import { FormFields, buildFieldBag } from 'hooks/useForm/helpers';
// const schema = Yup.string().required('banana');
// type Person = Yup.InferType<typeof schema>;

let init = false;

export type Form<FormValues> = {
  props: {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    onReset: (e: React.FormEvent<HTMLFormElement>) => void;
  },
  values: FormValues,
  isValid: boolean,
  validate: () => Promise<boolean>,
  resetForm: () => void,
  touched: boolean,
  visited: boolean,
  submitted: boolean,
  changed: boolean,
  isSubmitting: boolean,
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
}

export function useFormBase<FormValues>(fields: FormFields<FormValues>, options: FormOptions<FormValues>) {
  // Field states
  const [formState, dispatch] = useReducer(fieldReducer<FormValues>, fields, initializeFields<FormValues>);

  // Form state
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  // Computed props
  const values = getFieldValues<FormValues>(formState);
  const fieldErrors = getFieldErrors<FormValues>(formState);
  const touched = getAnyFieldsTouched<FormValues>(formState);
  const visited = getAnyFieldsVisited<FormValues>(formState);
  const changed = getAnyFieldsChanged<FormValues>(formState);
  const isValid = fieldErrors.length === 0;

  // Build callbacks
  const validate = async () => {
    const fieldsValid = await Promise.all(
      (Object.keys(fields) as (keyof typeof fields)[]).map((name) => fieldBag[name].validate()),
    );
    return fieldsValid.every((fieldValid) => fieldValid === true);
  };

  const resetForm = () => {
    (Object.keys(fields) as (keyof typeof fields)[]).map((name) => fieldBag[name].reset());
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    (Object.keys(fields) as (keyof typeof fields)[]).forEach((name) => {
      const fieldName = name;
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_TOUCHED,
        payload: { field: fieldName, touched: true },
      });
      dispatch({
        type: FIELD_DISPATCH_TYPES.SET_VISITED,
        payload: { field: fieldName, visited: true },
      });
    });
    const validationPassed = await validate();
    if (options.scrollToErrors && !validationPassed) {
      type FormDataArray = [string, Field<FormValues[keyof FormValues]>];
      const entries: FormDataArray[] = Object.entries(fieldBag);
      entries.reverse().forEach((f) => {
        const field = f[1];
        if (field.errors?.length) {
          const element = document.getElementById(`${field.props.name}-error`);
          element?.scrollIntoView?.({ behavior: 'smooth' });
        }
      });
    }
    if (validationPassed && options.onSubmit) {
      setSubmitted(true);
      const data = { fields: fieldBag, form: formBag };
      options.onSubmit(values, data);
    }
  };

  const onReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: FIELD_DISPATCH_TYPES.INIT_ALL, payload: { fields } });
  };

  // These props go on the DOM element
  const props = { onSubmit, onReset };

  const formBag: Form<FormValues> = {
    props,
    values,
    isValid,
    validate,
    resetForm,
    touched,
    visited,
    submitted,
    isSubmitting,
    setSubmitting,
    changed,
  };

  const fieldBag = buildFieldBag<FormValues, typeof formBag>({
    fields,
    formState,
    dispatch,
    formBag,
  });

  if (!init && options.validateOn?.init !== false && isValid) {
    const fieldBagValues = Object.values(fieldBag) as Field<FormValues[keyof FormValues]>[];
    fieldBagValues.map((f) => f.validate());
    init = true;
  }

  return { form: formBag, fields: fieldBag };
}

function getFieldValues<FormValues>(formState: FormState<FormValues>) {
  type FormValue = FormValues[keyof FormValues];

  const values = {} as FormValues;
  const entries: [string, FieldState<FormValue>][] = Object.entries(formState);
  entries.forEach(([field, { value }]) => {
    values[field as keyof FormValues] = value;
  });
  return values;
}

function getFieldErrors<FormValues>(formState: FormState<FormValues>) {
  const errors: string[] = [];
  type FormValue = FormValues[keyof FormValues];
  const values: FieldState<FormValue>[] = Object.values(formState) ;

  values.forEach((field) => {
    field.errors.forEach((error) => {
      errors.push(error);
    });
  });
  return errors;
}

function getAnyFieldsTouched<FormValues>(formState: FormState<FormValues>) {
  type FormValue = FormValues[keyof FormValues];
  const values: FieldState<FormValue>[] = Object.values(formState) ;
  return values.some((field) => field.touched);
}

function getAnyFieldsVisited<FormValues>(formState: FormState<FormValues>) {
  type FormValue = FormValues[keyof FormValues];
  const values: FieldState<FormValue>[] = Object.values(formState) ;
  return values.some((field) => field.visited);
}

function getAnyFieldsChanged<FormValues>(formState: FormState<FormValues>) {
  type FormValue = FormValues[keyof FormValues];
  const values: FieldState<FormValue>[] = Object.values(formState) ;
  return values.some((field) => field.changed);
}
