import React, { useMemo, useCallback, useEffect } from 'react';
import Nes from '@hapi/nes/lib/client';

import MessagesContext from 'contexts/MessagesContext';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setWebsocketId } from 'rdx/modules/websocket/slice';
import { useNesClient } from 'hooks/useNesClient';
import { usePrevious } from 'hooks/usePrevious';
import { getAuthToken } from 'lib/utils/authHelpers';
import { getMessagesApiUrl, getMessagesSocket, getSignInRequirements, getSignInWarnings } from 'rdx/modules/auth/slice';

const MessagesConnect = ({ children }: React.PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const authToken = getAuthToken();
  const signInRequirements = useAppSelector(getSignInRequirements);
  const signInWarnings = useAppSelector(getSignInWarnings);
  const signInReq = [...signInRequirements, ...signInWarnings] ?? [];
  const messagesApiUrl = useAppSelector(getMessagesApiUrl);
  const messagesSocket = useAppSelector(getMessagesSocket);

  const messageHandler: Nes.Client.Handler = useCallback((message) => {
    const eventOptions = { detail: message, bubbles: true };
    const event = new CustomEvent('bdrc-channel-message', eventOptions);
    window.dispatchEvent(event);
  }, []);

  const subscriptions = useMemo(() => {
    if (messagesSocket) {
      return {
        [messagesSocket]: messageHandler,
      }
    }
    return null;
  }, [messagesSocket, messageHandler]);

  const { connected, socketId, emitMessage, error } = useNesClient({
    token: signInReq.length > 0 ? '' : authToken ?? '',
    subscriptions: subscriptions ?? {},
    webSocketUrl: messagesApiUrl ?? '',
  });

  const contextValue = useMemo(() => ({
    connected,
    socketId,
    emitMessage,
    error,
  }), [connected, socketId, emitMessage, error]);

  const prevSocketId = usePrevious(socketId);
  useEffect(() => {
    if (socketId !== prevSocketId) {
      dispatch(setWebsocketId(socketId));
    }
  }, [dispatch, prevSocketId, socketId]);

  return (
    <MessagesContext.Provider value={contextValue}>
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesConnect;
