import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import requestProcedureTemplatesSaga from 'rdx/modules/templates/sagas/requestProcedureTemplates';
import saveProcedureAsTemplateSaga from 'rdx/modules/templates/sagas/saveProcedureAsTemplate';
import requestWorkflowTemplatesSaga from 'rdx/modules/templates/sagas/requestWorkflowTemplates';
import saveWorkflowAsTemplateSaga from 'rdx/modules/templates/sagas/saveWorkflowAsTemplate';
import saveWorkflowAsStudySaga from 'rdx/modules/templates/sagas/saveWorkflowAsStudy';
import deleteWorkflowTemplateSaga from 'rdx/modules/templates/sagas/deleteWorkflowTemplate';
import createWorkflowFromTemplateSaga from 'rdx/modules/templates/sagas/createWorkflowFromTemplate';
import { requestProcedureTemplates, saveProcedureAsTemplate, requestWorkflowTemplates, saveWorkflowAsTemplate, saveWorkflowAsStudy, deleteWorkflowTemplate, createWorkflowFromTemplate } from 'rdx/modules/templates/slice';

function* watchTemplatesSagas() {
  yield trackRequests(takeEvery, requestProcedureTemplates.type, requestProcedureTemplatesSaga);
  yield trackRequests(takeEvery, saveProcedureAsTemplate.type, saveProcedureAsTemplateSaga);
  yield trackRequests(takeEvery, requestWorkflowTemplates.type, requestWorkflowTemplatesSaga);
  yield trackRequests(takeEvery, saveWorkflowAsTemplate.type, saveWorkflowAsTemplateSaga);
  yield trackRequests(takeEvery, saveWorkflowAsStudy.type, saveWorkflowAsStudySaga);
  yield trackRequests(takeEvery, deleteWorkflowTemplate.type, deleteWorkflowTemplateSaga);
  yield trackRequests(takeEvery, createWorkflowFromTemplate.type, createWorkflowFromTemplateSaga);
}

export default watchTemplatesSagas;
