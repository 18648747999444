import { put, select } from 'redux-saga/effects';
import { newMessageEvent } from 'rdx/modules/messages/slice';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { requestInventoryReagents } from 'rdx/modules/reagents/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';

function* deleteInventoryReagent({ payload }) {
  const { action, values, query, onSuccess } = payload;
  const { success, error, data } = yield* makeRequest.action(action, values);
  if (error) {
    yield* putErrorActions({ error });
  }
  if (success && data) {
    const org = yield select(getCurrentOrganization);
    const reagentAction = org?.getAction('inventory_reagents');
    const params = { page: 1 };
    if (query) {
      const { search, field, group_id, page, ...rest } = query;
      params.page = page ?? 1;
      params['search[]'] = Object.entries(rest).map(([k, v]) => (k === 'species' ? `specie:${v}` : `${k}:${v}`));
      if (search) {
        params['search[]'] = [...params?.['search[]'], `${field ? `${field === 'species' ? 'specie' : field}:` : ''}${search}`];
      }
      if (group_id) {
        params.group_id = group_id;
      }
    }
    yield put(requestInventoryReagents({
      action: reagentAction,
      params,
    }));
    yield put(newMessageEvent({ text: 'Inventory Reagent Deleted' }));
    onSuccess?.();
  }
  return null;
}

export default deleteInventoryReagent;
