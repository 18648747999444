import { forwardRef } from 'react';
import createIcon from '../createIcon';

const FillHorizontalSerpentine = forwardRef((props, ref) => (
  <svg viewBox="0 0 130 130" ref={ref} {...props}>
    <path
      d="
        M 10,31 H 120
        V 59 v 12
        H 22 v 16
        H 110
        v -6 l 20,12 l -20,12 v -6
        H 10 V 59
        H 108 v -16
        H 10 v -12
      "
    />
  </svg>
));

export default createIcon(FillHorizontalSerpentine);
