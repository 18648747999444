import { useEffect } from 'react';

declare global {
  interface Window {
    _wq: {
      id: string,
      options?: Record<string, any>,
      // onEmbedded?: (video: any) => void,
    }[];
  }
}

// Accepts an object of embed options. See Wistia docs:
// https://wistia.com/support/developers/embed-options#list-of-all-options
const WistiaPlayer = ({ wistiaMediaId, embedOptions = {} }: { wistiaMediaId: string, embedOptions?: Record<string, any> }) => {
  window._wq = window._wq || [];
  window._wq.push({
    id: wistiaMediaId,
    options: { ...embedOptions },
  });

  useEffect(() => {
    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }

    window._wq = window._wq || [];
    window._wq.push({
      id: wistiaMediaId,
      // onEmbedded: (video) => {
      //   setVideo(video);
      // },
    });
    return () => {
      // setVideo(null);
    };
  }, [wistiaMediaId]);

  return (
    <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <div className="wistia_responsive_wrapper" style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}>
        <div className={`wistia_embed wistia_async_${wistiaMediaId} videoFoam=true`} style={{ height: '100%', width: '100%' }}>&nbsp;</div>
      </div>
    </div>
  );
};

export default WistiaPlayer;
