import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setTrials } from '../slice';

function* getTrials({ payload }) {
  const url = payload;
  const { success, data, error } = yield* makeRequest.get(url);
  if (success && data) {
    yield put(setTrials(data));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default getTrials;
