import styled from 'styled-components';
import Tutorials from 'containers/Tutorials';
import Chat from 'containers/Chat';
import AboutModal from 'containers/Modals/AboutModal';
import PrivacyModal from 'containers/Modals/PrivacyModal';
import TermsModal from 'containers/Modals/TermsModal';
import TechSupport from 'components/TechSupport';
import TrademarksModal from 'containers/Modals/TrademarksModal';
import { useModal } from 'hooks/useModal';
import globals from 'styles/globals';
import { QuestionMarkIcon } from 'components/Icons';
import { useAppSelector } from 'hooks/reduxHooks';
import FlowGptModal from 'components/FlowGptModal';
import { getCurrentOrganizationBetaTesting } from 'rdx/modules/organization/slice';

const UtilityBar = () => {
  const betaTestingEnabled = useAppSelector(getCurrentOrganizationBetaTesting);

  const { callModal } = useModal();

  const handleOpenAboutModal = () => {
    const kName = 'about-modal';
    callModal(<AboutModal key={kName} />);
  };

  const handleOpenPrivacyModal = () => {
    const kName = 'privacy-modal';
    callModal(<PrivacyModal key={kName} />);
  };

  const handleOpenTermsModal = () => {
    const kName = 'terms-modal';
    callModal(<TermsModal key={kName} />);
  };

  const handleOpenTrademarksModal = () => {
    const kName = 'trademarks-modal';
    callModal(<TrademarksModal key={kName} />);
  };

  const openFlowGptModal = () => {
    callModal(<FlowGptModal />);
  };

  return (
    <Wrapper>
      <Content>
        <LeftContainer>
          <Tutorials />
          <TechSupport />
          {betaTestingEnabled && (
            <FlowButton>
              <QuestionMarkIcon onClick={openFlowGptModal} />
            </FlowButton>
          )}
        </LeftContainer>
        <Links>
          <Link onClick={handleOpenAboutModal} data-testid="about-link">
            About
          </Link>
          <Link onClick={handleOpenPrivacyModal}>
            Privacy Statement
          </Link>
          <Link onClick={handleOpenTermsModal}>
            Terms of Use
          </Link>
          <Link onClick={handleOpenTrademarksModal}>
            Trademarks
          </Link>
        </Links>
        <Chat />
      </Content>
    </Wrapper>
  );
};

export default UtilityBar;

const { layout } = globals;

const Wrapper = styled.div`
  z-index: 1001;
  position: fixed;
  bottom: 0;
  left: 0;
  height: ${layout.footerHeight};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.charcoal};
  padding-left: min(calc(50vw - 90px), ${layout.mainContainerPadding} + 460px);
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: -7px;
`;

const LeftContainer = styled.div`
  display: flex;
`;

const Link = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.fontLight};
  font-size: 8pt;
  text-transform: uppercase;
  &:hover {
    font-weight: 500;
  }
`;

const Links = styled.div`
  display: flex;
  position: fixed;
  bottom: 14px;
  justify-content: center;
  align-items: center;
  max-width: 1200px; //TODO: make this max-width for app dynamic (and also in other areas where we use this)
  ${Link}:not(:last-child) {
    margin-right: 25px;
  }
`;

const FlowButton = styled.div`
  position: fixed;
  bottom: -4px;
  left: calc(${layout.mainContainerPadding} + 40px);
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 8pt;
  text-transform: uppercase;
  cursor: pointer;
  transform-origin: 50% 50%;
  transform: scale(1.0);
  transition-timing-function: ease-out;
  transition: transform 200ms;
  user-select: none;
  margin-left: 55px;
  height: 52px;
`;
