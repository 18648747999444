import styled from 'styled-components';
import DeprecatedPrimaryButton from 'components/DeprecatedButton/DeprecatedPrimaryButton/index';

const InfoReqButton = ({ disabled, onClick, currentStep, totalSteps }) => (
  <ButtonContainer onlyStep={(totalSteps === 1)}>
    {(totalSteps > 1) && (
      <div>Step {currentStep} of {totalSteps}</div>
    )}
    <DeprecatedPrimaryButton
      data-testid="inforeq-submit"
      disabled={disabled}
      onClick={onClick}
      text={(totalSteps === 1 || currentStep === totalSteps) ? 'Submit' : 'Next Step'}
    />
  </ButtonContainer>
);

export default InfoReqButton;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ onlyStep }) => (onlyStep ? 'flex-end' : 'space-between')};
  margin: 15px 0px;
`;
