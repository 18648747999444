import { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useModal } from 'hooks/useModal';

import { ChevronDown, ChevronUp, ScriptIcon } from 'components/Icons';
import Link from 'components/Link';
import TechSupportEmailLink from 'containers/Modals/TechSupportModal/TechSupportEmailLink';
import { getAuthToken } from 'lib/utils/authHelpers';

const QuestionSection = () => {
  const history = useHistory();
  const { closeModal, setModal } = useModal();

  const authToken = getAuthToken();

  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <TitleContainer onClick={() => setIsExpanded((prev) => !prev)}>
        <Title>I need a bit more help</Title>
        {isExpanded ? (
          <ChevronDown data-testid="collapse-questions-section" />
        ) : (
          <ChevronUp data-testid="expand-questions-section" />
        )}
      </TitleContainer>
      {isExpanded && (
        <List>
          <li>BD RC has bite size training videos at{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.flowjo.com/learn/flowjo-university/bd-research-cloud">
              FlowJo University
            </a>
          </li>
          {authToken && (
            <>
              <li>
                <Link
                  data-testid="tech-support-faq-link"
                  to="/faq"
                  onClick={() => {
                    closeModal();
                    setModal(null);
                    history.push('/faq');
                  }}
                >Frequently Asked Questions
                </Link>
              </li>
              <li>
                Tutorials within BD RC are available by clicking the <ScriptIcon color="primary" size="22px" style={{ verticalAlign: 'bottom' }} /> in the footer. The content of the tutorial is dependent on the section you are currently in.
              </li>
            </>
          )}
          <li>Still have questions? Please email technical support:{' '}<TechSupportEmailLink /></li>
        </List>
      )}
    </>

  );
};

export default QuestionSection;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.h2`
  font-weight: bold;
`;

const List = styled.ul`
  margin-block-start: 5px;
  margin-block-end: 5px;
  padding-inline-start: 30px;

  li {
    margin-bottom: 5px;
  }
`;
