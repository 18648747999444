import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setCurrentCytometer } from 'rdx/modules/cytometers/slice';

function* getCytometer({ payload }) {
  const { cytometerId, action, link, onSuccess, onError } = payload;
  const params = {
    cytometerID: cytometerId,
  };
  const { success, data, error } = link
    ? yield* makeRequest.link(link)
    : yield* makeRequest.action(action, params);
  if (success && data) {
    yield put(setCurrentCytometer(data.unwrap()));
    if (onSuccess) {
      onSuccess(data.unwrap());
    }
  } else {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default getCytometer;
