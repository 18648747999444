import { put } from 'redux-saga/effects';
import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setSpectrumViewerData } from 'rdx/modules/spectrumViewer/slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';

function* requestSpectrumViewerData({ payload: { query, action } }: PayloadActionWithGenericPayload) {
  const { success, data, error } = yield makeRequest.action(action, { ...query });
  if (success && data) {
    yield put(setSpectrumViewerData(data));
  } else if (error) {
    yield* putErrorActions({ error });
  }

  return null;
}

export default requestSpectrumViewerData;
