import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { getSessionAction } from 'rdx/modules/auth/slice';
import { RequestGroupsQuery, setGroups } from '../slice';
import { PayloadActionWithGenericPayload } from 'types/redux-types';
import type Action from 'lib/jsonApi/Action';
import type Resource from 'lib/jsonApi/Resource';

function* getGroups({ payload }: PayloadActionWithGenericPayload<undefined, RequestGroupsQuery> & { payload: { organization_id: string }}) {
  const { organization_id, query } = payload;

  const action: Action | undefined = yield select(getSessionAction('groups'));

  let orgId = organization_id;
  if (!orgId) {
    const organization: Resource = yield select(getCurrentOrganization);
    orgId = organization.id.toString();
  }

  const { success, data, error } = yield makeRequest.action(action, { organization_id: orgId, confirmed: 1, ...query });
  if (success && data) {
    yield put(setGroups(data));
  } else if (error) {
    yield* putErrorActions({ error });
  }
}

export default getGroups;
