import styled from 'styled-components';

import BaseModal from 'containers/Modals/BaseModal';
import NavHeader from 'components/NavHeader';
import QuestionSection from 'containers/Modals/TechSupportModal/QuestionSection';
import BugSection from 'containers/Modals/TechSupportModal/BugSection';
import GenerateIssueSection from 'containers/Modals/TechSupportModal/GenerateIssueSection';

const TechSupportModal = () => (
  <BaseModal
    dimensions={{ width: '550px', height: 'fit-content' }}
    styles={{
      padding: '0px',
    }}
    noFooter
    allowScrollingX
    allowScrollingY
    modalHeader={(
      <NavHeader
        title="BD Research Cloud Technical Support"
        isModal
      />
    )}
  >
    <SummaryContainer>
      <QuestionSection />
      <BugSection />
      <GenerateIssueSection />
    </SummaryContainer>
  </BaseModal>
);

export default TechSupportModal;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  gap: 10px;
`;
