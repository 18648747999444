import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import postNoteSaga from 'rdx/modules/steps/sagas/postNote';
import strikeStepNoteSaga from 'rdx/modules/steps/sagas/strikeStepNote';
import getStepReports from 'rdx/modules/steps/sagas/getStepReports';
import requestStepNotesSaga from 'rdx/modules/steps/sagas/requestStepNotes';
import requestStepFilesSaga from 'rdx/modules/steps/sagas/requestStepFiles';
import submitLead from 'rdx/modules/steps/sagas/submitLead';
import downloadPanelSummarySaga from 'rdx/modules/steps/sagas/downloadPanelSummary';
import requestSaga from 'rdx/api-utils/requestSaga';
import { assignStep, deleteStep, downloadPanelSummary, patchStep, postStep, postStepNote, requestStep, requestStepFiles, requestStepNotes, requestStepReports, strikeStepNote, submitReagentLead, updateSteps } from 'rdx/modules/steps/slice';

function* watchStepsSagas() {
  yield trackRequests(takeEvery, patchStep.type, (rdxAction) => requestSaga({
    rdxAction,
    putData: updateSteps,
    message: rdxAction.no_flag ? undefined : 'Step Updated',
  }));
  yield trackRequests(takeEvery, postStep.type, (rdxAction) => requestSaga({
    rdxAction,
    putData: updateSteps,
    message: rdxAction.payload.no_flag ? undefined : 'Step Created',
  }));
  yield trackRequests(takeEvery, deleteStep.type, (rdxAction) => requestSaga({ rdxAction, message: 'Step Deleted' }));
  yield trackRequests(takeEvery, postStepNote.type, postNoteSaga);
  yield trackRequests(takeEvery, strikeStepNote.type, strikeStepNoteSaga);
  yield trackRequests(takeEvery, requestStepReports.type, getStepReports);
  yield trackRequests(takeEvery, requestStepNotes.type, requestStepNotesSaga);
  yield trackRequests(takeEvery, requestStepFiles.type, requestStepFilesSaga);
  yield trackRequests(takeEvery, submitReagentLead.type, submitLead);
  yield trackRequests(takeEvery, requestStep.type, (rdxAction) => requestSaga({ rdxAction, putData: updateSteps }));
  yield trackRequests(takeEvery, assignStep.type, (rdxAction) => requestSaga({ rdxAction, putData: updateSteps }));
  yield trackRequests(takeEvery, downloadPanelSummary.type, downloadPanelSummarySaga);
}

export default watchStepsSagas;
