import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import globals from 'styles/globals';
import { iconSizeDimensions, iconSizeNames } from './constants';

const { colors } = globals;

const addUnit = (num: string | number, unit = 'px') => (typeof num === 'number' ? num + unit : num);

const getSize = ({ size }: { size?: string | number }) => {
  if (!size) {
    return iconSizeDimensions.sm;
  }
  if (typeof size === 'number') {
    return addUnit(size);
  }
  if (typeof size === 'string') {
    if (Object.keys(iconSizeDimensions).includes(size)) {
      return iconSizeDimensions[size as keyof typeof iconSizeDimensions];
    }
    return size;
  }
  return iconSizeDimensions.sm;
};

const getColor = (colorStr?: string) => {
  if (!colorStr) {
    return colors.primary;
  }
  if (Object.keys(colors).includes(colorStr)) {
    return colors[colorStr as keyof typeof colors];
  }
  return colorStr;
};

const onKeyDown = (e: React.KeyboardEvent<SVGSVGElement>, onClick?: (e?: React.KeyboardEvent<SVGSVGElement> | React.MouseEvent<SVGSVGElement>) => void) => {
  if (e.key === 'Enter' || e.key === ' ') {
    onClick?.();
  }
};

export type IconProps = {
  color?: string,
  hoverColor?: string,
  size?: number | string,
  iconSpacing?: number | string,
  clickable?: boolean,
  locked?: boolean,
  disabled?: boolean,
  isMobile?: boolean,
  desc?: boolean,
  onClick?: (e?: React.KeyboardEvent<SVGSVGElement> | React.MouseEvent<SVGSVGElement>) => void,
} & Omit<React.SVGProps<SVGSVGElement>, 'ref'> & React.RefAttributes<SVGSVGElement>;

type IconSvg = React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<SVGSVGElement>>;

const createIcon = (IconSvg: IconSvg, isDelete = false) => {
  const ForwardedRefIcon = forwardRef<SVGSVGElement, IconProps>(({
    color,
    hoverColor,
    size,
    iconSpacing,
    clickable,
    locked,
    disabled,
    isMobile,
    ...props
  }, ref) => <IconSvg {...props} ref={ref} onKeyDown={(e) => onKeyDown(e, props.onClick)} />);

  ForwardedRefIcon.displayName = "Icon";

  const Icon = styled(ForwardedRefIcon)
    .attrs(({ onClick, clickable }) => ({
      role: ((onClick ?? clickable) ? 'button' : 'img'),
      tabIndex: ((onClick ?? clickable) ? '0' : '-1'),
    }))`
    outline: none;
    height: ${({ size }) => getSize({ size })};
    width: ${({ size }) => getSize({ size })};
    min-width: ${({ size }) => getSize({ size })};
    max-width: ${({ size }) => getSize({ size })};
    fill: ${({ color }) => getColor(color)};
    ${({ onClick, clickable, locked }) => {
    if (locked) {
      return css`
        transition: fill ${globals.animations.transitionFast};
        cursor: pointer;
        outline: none;
        &:hover,
        &:focus {
          fill: ${({ theme }) => getColor(theme.colors.warning)};
        }
      `;
    }
    if (onClick ?? clickable) {
      return css<{ hoverColor?: string }>`
        transition: fill ${globals.animations.transitionFast};
        cursor: pointer;
        outline: none;
        &:hover,
        &:focus {
          fill: ${({ hoverColor }) => getColor(hoverColor)};
        }
      `;
    }
    return '';
  }}
    ${({ iconSpacing }) => (iconSpacing && css`
      & + & {
        margin-left: ${addUnit(iconSpacing)};
      }
    `)}

    ${({ disabled }) => disabled && css`
      display: none;
      pointer-events: none;
    `}
  `;

  Icon.displayName = "Icon";

  Icon.defaultProps = {
    color: 'fontLight',
    hoverColor: isDelete ? 'danger' : 'primary',
    size: iconSizeNames.SM,
    iconSpacing: '10px',
    clickable: false,
    disabled: false,
    ...(IconSvg.defaultProps ?? {}),
  };

  return Icon;
};

export default createIcon;
