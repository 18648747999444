import React, { forwardRef } from 'react';

import createIcon from '../createIcon';

const WorkflowIcon = forwardRef((props, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 489.53 426.29"
  >
    <g>
      <path d="M76.44,77.05c48.08,0,95.65,0,144.68,0c-14.4,14.34-27.88,27.77-41.09,40.92c4.98,5.08,9.71,9.9,13.98,14.26
        c22.17-22.18,44.45-44.46,66.12-66.14c-21.82-21.82-44-44.01-66.09-66.1c-4.47,4.43-9.29,9.2-15.22,15.07
        c13.5,13.45,27.01,26.91,40.72,40.57c-54.77,0-109.42,0-164.17,0c0,49.74,0,99.15,0,148.35c7.13,0,13.85,0,21.06,0
        C76.44,161.4,76.44,119.25,76.44,77.05z"
      />
      <path d="M416.63,221.87c-14.46-14.54-27.87-28.04-41.07-41.31c-5,4.91-9.82,9.65-14.09,13.84c22.26,22.26,44.53,44.54,66.14,66.15
        c21.79-21.8,43.97-43.99,66.01-66.04c-4.39-4.42-9.17-9.24-14.49-14.6c-13.39,13.41-26.83,26.87-40.89,40.94
        c0-55.44,0-110.17,0-165.12c-49.83,0-99.12,0-148.68,0c0,6.84,0,13.43,0,20.9c42.32,0,84.71,0,127.08,0
        C416.63,125.06,416.63,172.62,416.63,221.87z"
      />
      <path d="M76.64,274.07c0.44-0.29,0.89-0.58,1.33-0.87c13.39,13.51,26.78,27.01,39.55,39.9c4.95-4.66,9.86-9.27,14.57-13.7
        c-22.26-22.25-44.47-44.44-65.94-65.9C44.1,255.55,21.85,277.81,0,299.67c4.25,4.34,8.98,9.18,14.5,14.82
        c13.34-13.37,26.76-26.83,40.41-40.5c0,54.74,0,109.38,0,164.44c49.99,0,99.29,0,148.69,0c0-7.01,0-13.73,0-21.13
        c-42.59,0-84.86,0-126.95,0C76.64,369.1,76.64,321.58,76.64,274.07z"
      />
      <path d="M416.83,417.2c-48.09,0-95.67,0-144.27,0c14.13-14.11,27.58-27.55,40.85-40.81c-5.2-5.17-10.01-9.94-14.52-14.42
        c-21.97,21.98-44.27,44.28-66.07,66.07c21.89,21.89,44.08,44.09,65.73,65.75c4.57-4.54,9.43-9.38,15.39-15.3
        c-13.1-13.05-26.54-26.44-40.18-40.03c54.69,0,109.46,0,164.1,0c0-49.79,0-99.09,0-148.45c-6.96,0-13.56,0-21.03,0
        C416.83,332.74,416.83,374.89,416.83,417.2z"
      />
    </g>
  </svg>
));

export default createIcon(WorkflowIcon);
