// eslint-disable-next-line import/prefer-default-export
export const ORG_SETTINGS = {
  ORG_REQUIRE_LOT_NUMBERS: {
    name: 'org-require-lot-numbers',
    label: 'Require lot numbers',
    tooltip: 'Require lot numbers for all new inventory reagents',
    action: 'upsert_org_require_lot_numbers',
    default: false,
  },
  ORG_REQUIRE_UNIQUE_WORKFLOW_NAMES: {
    name: 'org-require-unique-workflow-names',
    label: 'Require unique workflow names',
    tooltip: 'Require unique workflow names for all new workflows within a project',
    action: 'upsert_org_require_unique_workflow_names',
    default: false,
  },
  ORG_ALLOW_WORKFLOWS_UNPUBLISHED: {
    name: 'org-allow-workflows-unpublished',
    label: 'Allow workflows to be unpublished',
    tooltip: 'Allows workflows to be unpublished, saving progress',
    action: 'upsert_org_allow_workflows_unpublished',
    default: false,
  },
  ORG_ALLOW_COMPLETED_WORKFLOW_EDIT: {
    name: 'org-allow-completed-workflow-edit',
    label: 'Allow workflows to be edited after completion',
    tooltip: 'Allows workflows to be edited after they are completed, as long as they are not archived',
    action: 'upsert_org_allow_completed_workflow_edit',
    default: false,
  },
};
