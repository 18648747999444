import styled from 'styled-components';
import globals from 'styles/globals';

const { layout } = globals;

const MaxWidthContainer = styled.div`
  max-width: ${layout.maxContainerWidth};
  margin: 0 auto;
`;

export default MaxWidthContainer;
