import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { newMessageEvent } from 'rdx/modules/messages/slice';

function* postNote({ payload: { action, values, onSuccess } }) {
  const { success, data, error } = yield* makeRequest.action(action, values);
  if (success && data) {
    onSuccess?.();
  } else {
    yield* putErrorActions({ error });
  }

  yield put(newMessageEvent({ text: 'Lab Note Saved' }));

  return null;
}

export default postNote;
