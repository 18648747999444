import { Fragment } from 'react';
import styled from 'styled-components';
import globals from 'styles/globals';

const { colors } = globals;

const Errors = ({ errors, barHeight = '40px', heightAdjust = '0px', textHeightAdjust = '0px' }) => (errors || []).map((error, i) => (
  // eslint-disable-next-line
  <Fragment key={i}>
    <Error position={i} key={error} textHeightAdjust={textHeightAdjust}>{error}</Error>
    <ValidationBar barHeight={barHeight} heightAdjust={heightAdjust} />
  </Fragment>
));

const Error = styled.p`
  color: ${colors.errorText};
  white-space: nowrap;
  overflow: visible;
  position: absolute;
  transform: translateY(${(props) => props.textHeightAdjust});
  bottom: ${({ position }) => `-${(position * 14) + 18}px`};
`;

const ValidationBar = styled.div`
  height: ${(props) => props.barHeight || '40px'};
  width: 2px;
  background-color: ${colors.errorText};
  position: absolute;
  bottom: ${(props) => props.heightAdjust};
  left: -8px;
`;

export default Errors;
