import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'hooks/usePrevious';

import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { newErrorEvent } from 'rdx/modules/messages/slice';

const useOrganizationFileLimitWarning = () => {
  const dispatch = useDispatch();
  const organization = useSelector(getCurrentOrganization);
  const previousOrganization = usePrevious(organization);

  React.useEffect(() => {
    const newOrg = organization && organization?.id !== previousOrganization?.id;
    const text = organization?.storage_limit_alert ?? organization?.storage_limit_warning;
    const previousText = previousOrganization?.storage_limit_alert ?? previousOrganization?.storage_limit_warning;

    const newOrgWithWarning = newOrg && text;
    const sameOrgWithNewWarning = text && (previousText !== text);

    if (newOrgWithWarning || sameOrgWithNewWarning) {
      dispatch(newErrorEvent({ text }));
    }
  }, [dispatch, organization, previousOrganization]);
};

export default useOrganizationFileLimitWarning;
