import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { motion } from 'framer-motion';

const Spinner = ({ size }) => {
  const theme = useContext(ThemeContext);
  const fill = theme.colors.primaryDisabled;
  const borderWidth = size / 6;

  const spinTransition = {
    loop: Infinity,
    duration: 1,
    ease: 'linear',
  };

  return (
    <Container {...{ size }}>
      <SpinSpan animate={{ rotate: 360 }} transition={spinTransition} {...{ size, fill, borderWidth }} />
    </Container>
  );
};

Spinner.propTypes = {
  size: PropTypes.number,
};

Spinner.defaultProps = {
  size: 150,
};

export default Spinner;

const SpinSpan = styled(motion.span)`
  display: block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border: ${({ borderWidth }) => `${borderWidth}px solid #e9e9e9`};
  border-top: ${({ borderWidth }) => `${borderWidth}px solid transparent`};
  border-radius: 50%;
  position: absolute;
  boxSizing: border-box;
  top: 0;
  left: 0;
`;

const Container = styled(motion.span)`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;
