import { takeEvery } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getTutorials from 'rdx/modules/tutorials/sagas/getTutorials';
import { requestTutorial } from 'rdx/modules/tutorials/slice';

function* watchTutorialsSagas() {
  yield trackRequests(takeEvery, requestTutorial.type, getTutorials);
}

export default watchTutorialsSagas;
