import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { ResourceObject } from 'types/json-api-types';
import { GenericActionPayload } from 'types/redux-types';

type GroupsState = {
  groups: ResourceList;
  workflowGroups: ResourceList;
  currentGroup: ResourceList | null;
  pendingGroups: ResourceList;
  smartGroups: ResourceList | null;
  orgStorageSmartGroups: ResourceList | null;
};

const initialState: GroupsState = {
  groups: new ResourceList(),
  workflowGroups: new ResourceList(),
  currentGroup: null,
  pendingGroups: new ResourceList(),
  smartGroups: null,
  orgStorageSmartGroups: null,
};

type GroupPermissions = 'manage_users' | 'projects' | 'studies'

export type RequestGroupsQuery = {
  confirmed?: '1' | '0',
  scope?: GroupPermissions[],
  page?: number,
}

const requestGroups = createAction<GenericActionPayload<undefined, RequestGroupsQuery> & { organization_id: string }>('requestGroups');
const createGroup = createAction('createGroup');
const updateGroup = createAction('updateGroup');
const changeGroupOwner = createAction('changeGroupOwner');
const leaveGroup = createAction('leaveGroup');
const requestPendingGroups = createAction('requestPendingGroups');
const requestMoreGroups = createAction('requestMoreGroups');
const requestMorePendingGroups = createAction('requestMorePendingGroups');
const requestWorkflowGroups = createAction<GenericActionPayload<{ organization_id: string }>>('requestWorkflowGroups');
const requestCurrentGroupById = createAction('requestCurrentGroupById');
const deleteGroup = createAction('deleteGroup');
const requestSmartGroups = createAction<GenericActionPayload>('requestSmartGroups');
const smartGroupsActionRequest = createAction('smartGroupsActionRequest');
const removeSmartGroup = createAction('removeSmartGroup');
const addSmartGroup = createAction('addSmartGroup');

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<ResourceList>) => { state.groups = action.payload; },
    setMoreGroups: (state, action: PayloadAction<ResourceList>) => { state.groups.mergeWith(action.payload); },
    setWorkflowGroups: (state, action: PayloadAction<ResourceList>) => { state.workflowGroups = action.payload; },
    setMoreWorkflowGroups: (state, action: PayloadAction<ResourceList>) => { state.workflowGroups.mergeWith(action.payload); },
    setCurrentGroup: (state, action: PayloadAction<ResourceList>) => { state.currentGroup = action.payload; },
    setPendingGroups: (state, action: PayloadAction<ResourceList>) => { state.pendingGroups = action.payload; },
    setMorePendingGroups: (state, action: PayloadAction<ResourceList>) => { state.pendingGroups.mergeWith(action.payload); },
    setSmartGroups: (state, action: PayloadAction<ResourceList>) => { state.smartGroups = action.payload; },
    setMoreSmartGroups: (state, action: PayloadAction<ResourceList>) => { state.smartGroups = state.smartGroups?.mergeWith(action.payload) ?? null; },
    setOrgStorageSmartGroups: (state, action: PayloadAction<ResourceList>) => { state.orgStorageSmartGroups = action.payload; },
    setMoreOrgStorageSmartGroups: (state, action: PayloadAction<ResourceList>) => { state.orgStorageSmartGroups?.mergeWith(action.payload); },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const { setGroups, setMoreGroups, setWorkflowGroups, setMoreWorkflowGroups, setCurrentGroup, setPendingGroups, setMorePendingGroups, setSmartGroups, setMoreSmartGroups, setOrgStorageSmartGroups, setMoreOrgStorageSmartGroups } = groupsSlice.actions;

// Actions
export {
  requestGroups,
  createGroup,
  updateGroup,
  changeGroupOwner,
  leaveGroup,
  requestPendingGroups,
  requestMoreGroups,
  requestMorePendingGroups,
  requestWorkflowGroups,
  requestCurrentGroupById,
  deleteGroup,
  requestSmartGroups,
  smartGroupsActionRequest,
  removeSmartGroup,
  addSmartGroup,
  setGroups,
  setMoreGroups,
  setWorkflowGroups,
  setMoreWorkflowGroups,
  setCurrentGroup,
  setPendingGroups,
  setMorePendingGroups,
  setSmartGroups,
  setMoreSmartGroups,
  setOrgStorageSmartGroups,
  setMoreOrgStorageSmartGroups,
};

// Selectors
export const getCurrentGroup = (state: RootState) => state.groups.currentGroup;
export const getGroups = (state: RootState) => state.groups.groups;
// export const getSessionGroups = (state: RootState) => state.session.getRel('groups');
export const getPendingGroups = (state: RootState) => state.groups.pendingGroups;
export const getWorkflowGroups = (state: RootState) => state.groups.workflowGroups;
export const getGroupScopes = (state: RootState) => {
  const currentGroup = state?.groups?.currentGroup?.data as ResourceObject;
  return (currentGroup?.attributes?.current_user_scope);
};
export const getIsGroupOwner = (state: RootState) => {
  const currentGroup = state?.groups?.currentGroup?.data as ResourceObject;
  return (state.users.currentUser?.id && currentGroup?.attributes?.created_by && `${state.users.currentUser?.id}` === `${currentGroup?.attributes?.created_by}`);
};
export const getCurrentGroupID = (state: RootState) => {
  const currentGroup = state?.groups?.currentGroup?.data as ResourceObject;
  return (currentGroup?.attributes ? currentGroup?.id : currentGroup?.id);
};
export const getSmartGroups = (state: RootState) => state.groups.smartGroups;
export const getOrgStorageSmartGroups = (state: RootState) => state.groups.orgStorageSmartGroups;

// Reducer
export default groupsSlice.reducer;
