import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { setStepFiles } from 'rdx/modules/steps/slice';

function* requestStepFiles({ payload: { link, params = {}, query = {}, onSuccess, onError } }) {
  const { success, error, data } = yield* makeRequest.link(link, params, query);
  if (success && data) {
    if (onSuccess) {
      onSuccess(data);
    }
    yield put(setStepFiles(data));
  }
  if (error) {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestStepFiles;
