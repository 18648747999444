import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { put } from 'redux-saga/effects';
import { resetBatchFiles } from 'rdx/modules/fileExplorer/slice';
import { newErrorEvent } from 'rdx/modules/messages/slice';

function* deleteBatchFiles({ payload }) {
  const { action, params } = payload;
  const query = { all_versions: true };
  const { success, data, error } = yield* makeRequest.action(action, params, query);
  if (success) {
    yield put(resetBatchFiles());
    if (!!params?.file_ids?.length && (data?.data?.attributes.filesDeleted < params.file_ids.length)) {
      yield put(newErrorEvent({
        text: 'Some files were not removed due to insufficient permissions or file state restrictions. Please contact the file owners.',
      }));
    }
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}

export default deleteBatchFiles;
