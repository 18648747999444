import React from 'react';
import styled, { css } from 'styled-components';

interface ClickableDivProps extends React.ComponentPropsWithoutRef<'div'> {
  onClick: (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  tabIndex?: number,
  role?: React.AriaRole
}

const ClickableDiv = ({ onClick, ...props }: ClickableDivProps) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Click on enter or spacebar
    if ((e.key === 'Enter') || (e.key === ' ')) {
      e.preventDefault();
      onClick(e);
    }
  };
  return <Div onKeyDown={handleKeyDown} onClick={onClick} {...props} />;
};

export default ClickableDiv;

const Div = styled.div.attrs(({ tabIndex, role }) => ({
  tabIndex,
  role,
}))<{ disabled?: boolean }>`
  cursor: pointer;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.8;
    filter: grayscale(80%);
  `}
`;
