import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import { putErrorActions } from 'rdx/utils/putErrorActions';
import { setFAQ } from 'rdx/modules/systemDocs/slice';
import { getSessionLinks } from 'rdx/modules/auth/slice';

function* getFaq() {
  const sessionLinks = yield select(getSessionLinks);
  const link = sessionLinks.faq;
  const { success, data, error } = yield* makeRequest.link(link);
  if (success && data) {
    yield put(setFAQ(data));
  } else {
    yield putErrorActions({ error });
  }
  return null;
}

export default getFaq;
